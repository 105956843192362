import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  HiCheck,
  HiChevronDown,
  HiEye,
  HiRefresh,
  HiSearch,
} from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled, { css } from "styled-components";
import { MPaging } from "../../components/Common/PagingM";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { commonSelect } from "../../recoil/AtomConfig";
import { NormalReqApi } from "../../utils/ReqApi";
import {
  Wrap,
  Section,
  SortSection,
  SortItems,
  SortText,
  SearchSection,
  SearchBg,
  SearchBox,
  SearchType,
  SearchIconBox,
  Pop,
  ContestStatus,
  ContestThumbImg,
} from "../../components/Contest/Mobile/indexStyle";
const EP = process.env.REACT_APP_AWS_ENDPOINT;
const BlockChainMobildIndex = () => {
  const navi = useNavigate();
  const { type } = useParams();
  const [filter, setFilter] = useState({
    open: false,
    type: "",
    list: [],
  });
  const [curFilter, setCurFilter] = useState({
    category: [],
    target: [],
    host: [],
    totalReward: [],
  });
  const [searchState, setSearchState] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchType, setSearchType] = useState({
    open: false,
    type: "공모명",
    typeId: "title",
    keyword: undefined,
  });
  const [sortOpen, setSortOpen] = useState(false);
  const [sortState, setSortState] = useState({
    sortType: 0,
    label: "최신순",
  });

  const [common, setCommon] = useRecoilState(commonSelect);

  const getCommon = async () => {
    if (common.loading) {
      const res = await NormalReqApi("get", "/basic");
      if (res && res.statusCode === 200) {
        setCommon((prev) => {
          const temp = { ...prev };
          temp.loading = false;
          temp.category = [...res.data.category];
          temp.host = [...res.data.host];
          temp.totalReward = [...res.data.totalReward];
          temp.target = [...res.data.target];
          temp.region = [...res.data.region];
          return { ...temp };
        });
      }
    }
  };

  useEffect(() => {
    getCommon();
  }, []);

  const handleFilter = (fType) => {
    let list = [];
    const typeIndex = 1;

    if (fType === "category" || fType === "totalReward") {
      list = [...common[fType].filter((f) => f.type === typeIndex)];
    } else {
      list = [...common[fType]];
    }
    setFilter({
      open: true,
      type: fType,
      list: list,
    });
  };
  const handleUnFitler = () => {
    setFilter({
      open: false,
      type: "",
      list: [],
    });
  };

  const handleSelFilter = (type, code) => {
    const isExist = curFilter[type].filter((f) => f === code).length > 0;
    if (isExist) {
      switch (type) {
        case "category":
          setCurFilter({
            ...curFilter,
            category: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
        case "target":
          setCurFilter({
            ...curFilter,
            target: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
        case "host":
          setCurFilter({
            ...curFilter,
            host: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
        case "totalReward":
          setCurFilter({
            ...curFilter,
            totalReward: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
      }
    } else {
      switch (type) {
        case "category":
          setCurFilter({
            ...curFilter,
            category: [...curFilter[type], code],
          });
          break;
        case "target":
          setCurFilter({
            ...curFilter,
            target: [...curFilter[type], code],
          });
          break;
        case "host":
          setCurFilter({
            ...curFilter,
            host: [...curFilter[type], code],
          });
          break;
        case "totalReward":
          setCurFilter({
            ...curFilter,
            totalReward: [...curFilter[type], code],
          });
          break;
      }
    }
  };

  const [sc, setSc] = useState({
    page: 1,
    scale: 12,
  });
  const keywordRef = useRef(null);
  //   useEffect(() => {
  //     console.log(curFilter.category.flat(1).toString());
  //   }, [curFilter]);
  const { data, error, isLoading } = useQuery(
    ["contestM", type, curFilter, sc, searchType, sortState],
    async () =>
      await NormalReqApi(
        "get",
        `/contest?page=${sc.page}&contestType=${0}&type=${searchType.typeId}${
          searchType.keyword ? `&keyword=${searchType.keyword}` : ""
        }&sort=${sortState.sortType}&category=${curFilter.category
          .flat(1)
          .toString()}&target=${curFilter.target
          .flat(1)
          .toString()}&host=${curFilter.host
          .flat(1)
          .toString()}&totalReward=${curFilter.totalReward.flat(1).toString()}`
      ),
    { retry: false }
  );

  const changePage = (page) => {
    setSc({
      ...sc,
      page: page * 1,
    });
  };

  const calcCurrentStatus = (startDate, endDate) => {
    const nowDate = moment();
    const sDate = moment(startDate);
    const eDate = moment(endDate);

    const resultTemplate = {
      status: 0,
      text: "",
    };
    if (nowDate.isBefore(sDate)) {
      resultTemplate.status = 0;
      resultTemplate.text = "예정";
    } else if (nowDate.isAfter(sDate) && nowDate.isBefore(eDate)) {
      resultTemplate.status = 1;
      resultTemplate.text = "진행중";
    } else if (nowDate.isAfter(eDate)) {
      resultTemplate.status = 2;
      resultTemplate.text = "마감";
    } else {
      resultTemplate.status = 0;
      resultTemplate.text = "예정";
    }

    return (
      <ContestStatus status={resultTemplate.status}>
        {resultTemplate.text}
      </ContestStatus>
    );
  };

  const handleBgClose = (e) => {
    setSearchState(false);
  };
  const handleSTypeClose = () => {
    setSearchOpen(false);
  };
  const handleSearchTypeChange = ({ type, typeId }) => {
    setSearchType({
      ...searchType,
      type,
      typeId,
    });
  };
  const handleSearchToggle = () => {
    const keyword = keywordRef?.current?.value;
    if (keyword.length > 0) {
      setSearchType({
        ...searchType,
        keyword,
      });
    }
  };
  const handleRefresh = () => {
    setSearchType({
      ...searchType,
      keyword: "",
    });
  };
  const handleSortClose = () => {
    setSortOpen(false);
  };
  return (
    <Wrap>
      <div className={"dcMainTitle"}>
        <p className={"dcTitle"}>코인공모전</p>
      </div>
      {/* -- 필터 / 검색 영역 -- */}
      <Section role={"filter"} className={"customContestFilter"}>
        <ul>
          <li className={curFilter.category.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("category")}>공모분야</label>
          </li>
          <li className={curFilter.target.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("target")}>응모대상</label>
          </li>
          <li className={curFilter.host.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("host")}>주최사</label>
          </li>
          <li className={curFilter.totalReward.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("totalReward")}>시상내역</label>
          </li>
        </ul>
      </Section>

      <SearchBg active={searchState} onClick={handleBgClose} />
      <SearchSection onClick={() => setSearchState(true)}>
        <SearchBox>
          <SearchType onClick={() => setSearchOpen(true)}>
            <span>{searchType.type}</span>
            <HiChevronDown size={15} color={"white"} />
          </SearchType>
          <input type="text" ref={keywordRef} />
        </SearchBox>
        <SearchIconBox>
          <HiSearch size={20} color={"white"} onClick={handleSearchToggle} />
          <HiRefresh size={20} color={"white"} onClick={handleRefresh} />
        </SearchIconBox>
      </SearchSection>
      {/* -- 필터 / 검색 영역 끝-- */}
      {/* -- 정렬 영역 --  */}
      <SortSection>
        <SortItems onClick={() => setSortOpen(true)}>
          <SortText>{sortState.label}</SortText>
          <HiChevronDown size={15} color={"gray"} />
        </SortItems>
      </SortSection>
      {/* -- 정렬 영역 끝--  */}
      {/* -- 공모전 리스트 영역 --  */}
      <Section role={"contents"} className={"customContestItems"}>
        {isLoading ? (
          <Loading />
        ) : (
          <ul>
            {data.result.list.map((item, index) => (
              <li
                key={`contest_${type}_${item._id}_${index}`}
                onClick={() =>
                  navi(`/mDetail/${item._id}`, {
                    state: {
                      isOnChain: true,
                    },
                  })
                }
              >
                <ContestThumbImg src={EP + "w_350/" + item.thumbnail.path} />
                <div className={"customTitleArea"}>
                  <p className={"customCTitle"}>{item.title}</p>
                  <p className={"customCHost"}>{item.host.name}</p>
                </div>

                <div className={"customEtc"}>
                  <div className={"customViewCnt"}>
                    {calcCurrentStatus(item.startDate, item.endDate)}
                  </div>
                  <p className={"customCreatedAt"}>
                    ~ {moment(item.endDate).format("YYYY-MM-DD")}
                  </p>
                </div>
                <div
                  className={"customEtc"}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className={"customViewCnt"}>
                    <HiEye />
                    <span>{item.viewCnt}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Section>
      {/* -- 공모전 리스트 영역 끝--  */}
      {/* -- 공모전 페이징 영역 -- */}
      <Section role={"page"} className={"customCPage"}>
        {!isLoading && <MPaging {...data} toggle={changePage} />}
      </Section>
      {/* -- 공모전 페이징 영역 끝-- */}
      <Pop open={filter.open}>
        <div className={"customPopBg"} onClick={handleUnFitler}></div>
        <div className={"customPopBody"}>
          <div className={"customPopContents"}>
            <div className={"customPopBtns"}>
              <label></label>
              <span onClick={handleUnFitler}>x</span>
            </div>
            <ul className={"customPopItems"}>
              {filter.open &&
                filter.list.map((item, index) => (
                  <li key={`pop_filter_${filter.type}_${index}`}>
                    <span
                      className={"customPopItem"}
                      onClick={() => handleSelFilter(filter.type, item.code)}
                    >
                      {filter.type === "category" ? item.sub : item.text}
                    </span>
                    <span className={"customPopStatus"}>
                      {curFilter[filter.type].filter((f) => f === item.code)
                        .length > 0 && <HiCheck color={"green"} />}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Pop>
      <Pop open={searchOpen} type="search">
        <div className={"customPopBg"} onClick={handleSTypeClose}></div>
        <div className={"customPopBody"}>
          <div className={"customPopContents"}>
            <div className={"customPopBtns"}>
              <label></label>
              <span onClick={handleSTypeClose}>x</span>
            </div>
            <ul className={"customPopItems"}>
              {[
                { typeId: "title", label: "공모명" },
                { typeId: "host.name", label: "주최사명" },
              ].map((item, index) => (
                <li key={`pop_filter_${item.typeId}_${index}`}>
                  <span
                    className={"customPopItem"}
                    onClick={() =>
                      handleSearchTypeChange({
                        type: item.label,
                        typeId: item.typeId,
                      })
                    }
                  >
                    {item.label}
                  </span>
                  <span className={"customPopStatus"}>
                    {searchType.typeId === item.typeId && (
                      <HiCheck color={"green"} />
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Pop>
      <Pop open={sortOpen} type="search">
        <div className={"customPopBg"} onClick={handleSortClose}></div>
        <div className={"customPopBody"}>
          <div className={"customPopContents"}>
            <div className={"customPopBtns"}>
              <label></label>
              <span onClick={handleSortClose}>x</span>
            </div>
            <ul className={"customPopItems"}>
              {[
                { sortType: 0, label: "최신순" },
                { sortType: 1, label: "조회순" },
                { sortType: 2, label: "마감일순" },
              ].map((item, index) => (
                <li key={`pop_filter_${item.sortType}_${index}`}>
                  <span
                    className={"customPopItem"}
                    onClick={() =>
                      setSortState({
                        label: item.label,
                        sortType: item.sortType,
                      })
                    }
                  >
                    {item.label}
                  </span>
                  <span className={"customPopStatus"}>
                    {sortState.sortType === item.sortType && (
                      <HiCheck color={"green"} />
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Pop>
    </Wrap>
  );
};

export default BlockChainMobildIndex;
