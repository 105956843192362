import React from "react";
import styled from "styled-components";
import Logo from "../../assets/img/common/logo_footer.png";

const FooterWrap = styled.div`
  margin-top: 2em;
  background-color: #222530;
  height: 100%;

  padding: 1em 0.5em;

  .customCenter {
    font-size: 0.55em;
    color: white;
    .customInfo {
      color: #dbdbdb;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.25em 0;
      &.multiple {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .Tel {
          > span {
            &:first-child {
              margin-right: 0.5em;
            }
          }
        }
        .workTime {
          .runningTime {
            > span {
              &:first-child {
                margin-right: 0.5em;
              }
            }
          }
        }
      }
      &.Bank {
        display: block;
        > span {
          &:last-child {
            display: block;
          }
        }
      }
      &.workTime {
        > span {
          &:first-child {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
  .customCompany {
    padding: 0.5em 0;
    > img {
      width: 50%;
    }
    .companyInfo {
      padding: 0.5em 0;
      > p {
        font-size: 0.55em;
        color: gray;
      }
    }
  }
`;

const MobileFooter = () => {
  return (
    <FooterWrap>
      <div className={"customCenter"}>
        <h4>대티즌 고객센터</h4>
        <div className={"customInfo Bank"}>
          <span>입금계좌 안내</span>
          <span>기업은행 - 064-084329-01-016 (예금주 : (주)대티즌)</span>
        </div>
        <div className={"customInfo multiple"}>
          <div className={"Tel"}>
            <span>대표번호</span>
            <span>02-529-2515</span>
          </div>
          <div className={"workTime"}>
            <div className={"runningTime"}>
              <span>평일</span>
              <span>09:30 ~ 18:00</span>
            </div>
            <div className={"runningTime"}>
              <span>점심시간</span>
              <span>12:00 ~ 13:00</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"customCompany"}>
        <img src={Logo} />
        <div className={"companyInfo"}>
          <p>서울특별시 강남구 남부순환로 2736 지앤지빌딩 3층 (주)대티즌</p>
          <p>대표메일 : detizen@detizen.com</p>
          <p>대표자 : 한성일</p>
          <p>사업자 번호 : 220-87-05027</p>
          <p>copyrightⓒ 2005 Detizen Inc. All rights reserved.</p>
        </div>
      </div>
    </FooterWrap>
  );
};

export default MobileFooter;
