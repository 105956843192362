const convertDateToString = (date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}${month}${day}`;
};

export const createFileName = (name, dir) => {
  const split = name.split(".");
  const ext = split[split.length - 1];
  const formattedDate = convertDateToString(new Date());
  const t = new Date().getTime();
  const r = (Math.random() * 1000000).toString().replaceAll(".", "_");

  const fileName = `${dir}${formattedDate}_${t}_${r}.${ext}`;

  return fileName;
};
