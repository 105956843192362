import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../../recoil/AtomConfig";
import { Suspense } from "react";
import { Loading } from "../../../components/DContents/Mobile/MTemplate";
import {
  CurPointMobile,
  MyPointHistory,
  MyPointHistoryMobile,
} from "../../../components/User/UserPoint";
import { isMobile } from "react-device-detect";
import { MyPageHeader } from "../../../components/User/UserMobile";

const PointHistoryPage = () => {
  const location = useLocation();

  const onClickTransferPoint = () => {
    alert("준비중입니다");
  };

  if (isMobile) {
    return (
      <>
        <MyPageHeader>
          <div
            style={{
              padding: ".5em 0",
              color: "white",
              fontSize: ".7em",
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <p>내 포인트</p>
            <div>
              <Suspense fallback={<Loading />}>
                <CurPointMobile />
              </Suspense>
            </div>
          </div>
          <div style={{ padding: "0 1em" }}>
            <button
              type="button"
              style={{
                padding: ".5em",
                width: "100%",
                border: "none",
                borderRadius: "1em",

                color: "white",
                fontSize: ".75em",
                background:
                  "linear-gradient(130deg, #12c2e9, #c471ed, #f64f59)",
              }}
              onClick={onClickTransferPoint}
            >
              코인으로 전환하기
            </button>
          </div>
          <div style={{ background: "white", marginTop: "1em" }}>
            <p
              style={{
                padding: ".5em 1em",
                fontSize: ".7em",
                borderBottom: "1px solid #ebebeb",
              }}
            >
              포인트 내역
            </p>
            <Suspense
              fallback={
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              }
            >
              <MyPointHistoryMobile />
            </Suspense>
          </div>
        </MyPageHeader>
      </>
    );
  }

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
      </div>
      <div className={`sub_container`}>
        <div className={`contents mypage`}>
          <div style={{ padding: ".5em 0" }}>
            <p style={{ fontWeight: "bold" }}>포인트 히스토리</p>
          </div>
          <Suspense fallback={<Loading />}>
            <MyPointHistory />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default PointHistoryPage;
