import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DContentsSubMenu,
  DContentView,
  DContentViewInfo,
} from "../../components/DContents/Common/Element";
import { DepicSuspenseView } from "../../components/DContents/Depic/getdata";

const DepicView = () => {
  const navi = useNavigate();
  const { id } = useParams();

  const handleGoBack = () => {
    navi("/depic");
  };

  return (
    <div className={"container sub"}>
      <DContentsSubMenu current={"depic"} />
      <DContentView label={"대픽 영상"}>
        <div className={"board_view qna"}>
          <Suspense fallback={<div>test</div>}>
            <DepicSuspenseView id={id} handleGoBack={handleGoBack} />
          </Suspense>
        </div>
      </DContentView>
    </div>
  );
};

export default DepicView;
