import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bootpay } from "@bootpay/client-js";
import { NormalReqApi } from "../../../utils/ReqApi";

const APID = process.env.REACT_APP_APID;
const AUTHID = process.env.REACT_APP_AUTHID;

const FindId = () => {
  const navi = useNavigate();
  const phoneRef = useRef(null);

  const handleAuth = async () => {
    const phone = phoneRef.current.value;
    if (!phone || phone.length < 9) {
      alert("본인인증을 위해 휴대전화 번호를 기입해주시기 바랍니다.");
      return false;
    }

    try {
      const response = await Bootpay.requestAuthentication({
        application_id: APID,
        pg: "다날",
        order_name: "본인인증",
        authentication_id: AUTHID,
        user: {
          phone: phone,
        },
      });

      switch (response.event) {
        case "done":
          const { receipt_id, status } = response.data;
          if (status == 12) {
            const authResult = await NormalReqApi(
              "get",
              `/bp/findid/${receipt_id}?phone=${phone}`
            );
            // console.log(authResult);
            if (authResult.flag) {
              navi("/findidresult", {
                state: {
                  id: authResult.data.id,
                },
              });
            } else {
              alert("일치하는 정보가 없습니다");
              return false;
            }
            // if (!authResult.flag) {
            //   alert("이미 가입되어있는 사용자 입니다.");
            //   return false;
            // } else {
            //   const temp = {};
            //   temp.di = authResult.data.authenticate_data.di;
            //   temp.name = authResult.data.authenticate_data.name;
            //   temp.unique = authResult.data.authenticate_data.unique;
            //   // return { ...temp };

            //   navi("/signupnormal");
            // }
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e.message);
      switch (e.event) {
        case "cancel":
          // 사용자가 결제창을 닫을때 호출
          console.log(e.message);
          break;
        case "error":
          // 결제 승인 중 오류 발생시 호출
          console.log(e.error_code);
          break;
      }
    }
  };
  return (
    <div className={"container sub"}>
      {/* <div className={"subVisual mypage"}></div> */}
      <div className={`sub_container`} style={{ marginTop: "3.5em" }}>
        <div className={`contents mypage`} style={{ height: "100%" }}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>아이디 찾기</span>

            <small style={{ marginBottom: 0 }}>
              <Link to={`/findIdComp`}>기업회원 아이디 찾기</Link>
            </small>
          </h2>
          <div style={{ padding: "1em 0" }}>
            <p style={{ marginBottom: "1em", fontSize: "0.85em" }}>
              아이디 확인을 위해 가입시 기재하셨던 연락처를 기입 후 본인인증을
              진행해주시기 바랍니다.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "1em",
                padding: "1em 2em",
                backgroundColor: "#efefef",
              }}
            >
              <label
                htmlFor="userPhone"
                style={{ marginRight: ".5em", fontSize: ".75em" }}
              >
                휴대전화 번호
              </label>
              <input
                id="userPhone"
                ref={phoneRef}
                type="text"
                style={{
                  border: "none",
                  borderRadius: "10px",
                  padding: ".25em .5em",
                  fontSize: ".9em",
                }}
              ></input>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <button
                type="button"
                style={{
                  color: "white",
                  backgroundColor: "#263050",
                  border: "none",
                  display: "block",
                  marginBottom: "0.5em",
                  padding: "0.5em",
                  fontSize: "0.75em",
                  boxSize: "border-box",
                  cursor: "pointer",
                }}
                onClick={handleAuth}
              >
                본인 인증 (PASS)
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindId;
