import React from "react";
import {
  Host,
  ListDataLi,
  ListDataUl,
  RegDate,
  Status,
  SubInfoArea,
  SubInfoText,
  Title,
} from "./UserTemplate";
import Default from "../../assets/img/common/img_posterDefault_280.jpg";
import { HiClock, HiGlobe, HiPencil, HiUser } from "react-icons/hi";
import { ClientReqGet, ClientReqGet2 } from "../../utils/ClientAuth";
import { useQuery } from "react-query";
import { imgOnError } from "../../utils/EmptyImg";
import moment from "moment";
import { ParseCurrentState } from "../../utils/ParseDate";
import { Link, useNavigate } from "react-router-dom";

const END_POINT = process.env.REACT_APP_AWS_ENDPOINT + "w_280/";

const UserScrapTop3 = ({ id, account, toggle }) => {
  const navi = useNavigate();
  const { data, isLoading } = useQuery(
    ["scrap3", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/user/${id}/scrap/top3`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: false,
      cacheTime: 0,
      onSuccess: (e) => {
        console.log(e);
        if (e?.statusCode && e?.statusCode === 401) {
          navi("/signin");
        }
        if (e?.statusCode && e?.statusCode === 404) {
          navi("/signin");
        }
        if (e?.statusCode === 400) {
          navi("/signin");
        }
      },
      onError: (e) => {
        if (e?.statusCode && e?.statusCode === 404) {
          navi("/signin");
        }
      },
    }
  );

  const handleLink = (e) => {
    navi(`/contest/view/${e.type}/${e._id}`);
  };
  // console.log(data);
  return (
    <ListDataUl>
      {data.data?.res.scrap?.length > 0 &&
        data.data.res.scrap.map((item, index) => {
          const curDateStatus = ParseCurrentState(item.startDate, item.endDate);
          console.log(curDateStatus);
          return (
            <ListDataLi
              key={`u_s_${index}`}
              onClick={() => handleLink(item)}
              style={{ cursor: "pointer" }}
            >
              <div>
                <img
                  src={END_POINT + item.thumbnail.path}
                  onError={imgOnError}
                  style={{ width: "140px", borderRadius: "5px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  paddingLeft: "0.5em",
                }}
              >
                <div>
                  <Status type={curDateStatus.status}>
                    {curDateStatus.text}
                  </Status>
                  <Title>{item.title}</Title>
                  <Host>{item.host.name}</Host>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SubInfoArea>
                    <HiUser size={15} />
                    <SubInfoText>{item.target[0].text}</SubInfoText>
                  </SubInfoArea>

                  <SubInfoArea>
                    <HiClock size={15} />
                    <SubInfoText>
                      {moment(item.startDate).format("yyyy-MM-DD") +
                        " ~ " +
                        moment(item.endDate).format("yyyy-MM-DD")}
                    </SubInfoText>
                  </SubInfoArea>
                </div>
              </div>
            </ListDataLi>
          );
        })}
    </ListDataUl>
  );
};

export default UserScrapTop3;
