import React, { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logoimg from "../../assets/img/common/logo_w.png";
import LogoimgDark from "../../assets/img/common/logo.png";
import chStyle from "../../assets/css/header.module.scss";
import { HiDotsVertical } from "react-icons/hi";
import { useRecoilState } from "recoil";
import {
  accountSelect,
  bgColorSelect,
  pageSelect,
} from "../../recoil/AtomConfig";
import FixedMenu from "../../components/Header/FixedMenu";
import MainMenu from "../../components/Header/MainMenu";
import MainBtn from "../../components/Header/MenuBtn";
import MainGnb from "../../components/Header/MainGnb";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/Header/MobileHeader";
import Footer from "./Footer";

const Header = ({}) => {
  const navigator = useNavigate();
  const [state] = useRecoilState(accountSelect);
  const [page, setPage] = useRecoilState(pageSelect);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [bg] = useRecoilState(bgColorSelect);
  const location = useLocation();
  // console.log(location);

  const movePage = (pageUrl, pageType) => {
    navigator(pageUrl);
    setOpen(false);
    if (pageType) {
      handleChangePage(pageType);
    }
  };

  const handleChangePage = (page) => {
    setPage((prev) => {
      const prevObject = { ...prev };
      prevObject.curPage = page;

      return { ...prevObject };
    });
  };

  return (
    <>
      <div className="wrap main">
        <header className={`header main ${chStyle.mediaHeader}`}>
          <div
            className={`snb_wrap ${chStyle.snb_wrap} ${chStyle.indicator} ${
              hide ? chStyle.hide : chStyle.open
            }`}
          >
            <div className="snb_cont">
              <div className="sns_wrap">
                <div onClick={() => setHide(false)}>
                  <HiDotsVertical color={"white"} />
                </div>
              </div>
            </div>
          </div>

          <FixedMenu
            className={`snb_wrap ${chStyle.snb_wrap} ${
              hide ? chStyle.hide : chStyle.open
            }`}
            hide={hide}
            setHide={setHide}
          />

          {!isMobile ? (
            <div className={`header_wrap `} id={`${chStyle.header_wrap}`}>
              <Link to="/" onClick={() => handleChangePage("home")}>
                <div className="logo" style={{ cursor: "pointer" }}>
                  <img
                    src={bg.change ? LogoimgDark : Logoimg}
                    width={142}
                    height={40}
                  />
                </div>
              </Link>
              {/* mainMenu area */}
              <MainMenu
                className={`main_nav ${chStyle.main_nav} ${
                  bg.change ? "black" : ""
                }`}
                current={page.curPage}
                role={state.role}
                toggle={handleChangePage}
              />
              <div className={`ab_right ${chStyle.ab_right}`}>
                {/* search, login, gnbBtn */}
                <MainBtn state={state} open={open} setOpen={setOpen} />

                {/* gnbMenu */}
                <MainGnb chStyle={chStyle} movePage={movePage} open={open} />
              </div>
            </div>
          ) : (
            <MobileHeader
              pageChange={handleChangePage}
              LogoDark={LogoimgDark}
              bg={bg}
              LogoImg={Logoimg}
              chStyle={chStyle}
              movePage={movePage}
            />
          )}

          <a className="goTop off"></a>
        </header>

        <Outlet />
        {location.pathname !== "/signin" &&
          location.pathname !== "/signIn" &&
          location.pathname !== "/signup" &&
          location.pathname !== "/signupnormal" &&
          location.pathname !== "/signupcomp2" &&
          location.pathname !== "/signupcomp" && <Footer />}
      </div>
    </>
  );
};

export default Header;
