import React from "react";
import {
  CntAndDate,
  CntArea,
  ContentsWrap,
  CTitle,
  DCwrap,
  Section,
  SectionWrap,
  SMoreBtn,
  SSummary,
  STitle,
  SubText_P,
  ThumbItem,
  ThumbSlide,
} from "./Frame";
import Default280 from "../../../assets/img/common/img_posterDefault_280.jpg";
import { HiEye } from "react-icons/hi";
import { useQuery } from "react-query";
import { NormalReqApi } from "../../../utils/ReqApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EmptyPrev = () => {
  const { data, error } = useQuery(
    ["emptyPrev"],
    async () => await NormalReqApi("get", "/emptytime/top5"),
    { retry: true, suspense: true }
  );

  const navi = useNavigate();

  return (
    <Section>
      <SectionWrap>
        <STitle>공강시간</STitle>
        <SSummary>대티즌이 전하는 알아두면 도움이 되는 간추린 뉴스</SSummary>
        <SMoreBtn type="button" onClick={() => navi("/emptytime")}>
          더보기
        </SMoreBtn>
      </SectionWrap>
      <ContentsWrap>
        <ThumbSlide>
          {data.data.map((item, index) => (
            <ThumbItem key={`empty_thumb_${index}`}>
              <img
                src={
                  process.env.REACT_APP_AWS_ENDPOINT +
                  "w_280/" +
                  item.thumbnail.path
                }
                style={{
                  width: "280px",
                  height: "280px",
                  borderRadius: "5px",
                }}
              />
              <CTitle>{item.title}</CTitle>
              <CTitle>{item.des}</CTitle>
              <CntAndDate>
                <CntArea>
                  <HiEye color="#a0a0a0" />
                  <SubText_P style={{ marginLeft: "1em" }}>
                    {item.viewCnt}
                  </SubText_P>
                </CntArea>
                <SubText_P>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </SubText_P>
              </CntAndDate>
            </ThumbItem>
          ))}
        </ThumbSlide>
      </ContentsWrap>
    </Section>
  );
};

export default EmptyPrev;
