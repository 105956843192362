import React from "react";
import styled from "styled-components";

export const Section1Ul = styled.ul`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: auto 5em;
  &:last-child {
    margin-top: 3em;
    padding-top: 3em;
    border-top: 1px dashed gray;
  }
`;
export const Setion1Li = styled.li`
  width: calc((100% - 4em) / 3);
  padding-left: 2em;
  margin-left: 1em;
  padding-right: 2em;
  /* border-left: 1px dashed gray; */
  border-right: 1px dashed gray;
  &:last-child {
    border-right: unset;
    /* border-left: unset; */
  }
`;

export const Section2 = styled.div`
  margin-top: 120px;
`;

export const Section3 = styled.div`
  margin-top: 120px;
  margin-bottom: 320px;
  > ul {
    display: flex;
    justify-content: center;
    align-items: center;

    > li {
      margin-right: 1em;
      padding: 0.25em 1em;

      font-weight: 400;
      font-size: 24px;
      border-radius: 50px;
      background: #e4e4e4;
      color: #707070;
      &.active {
        background: #2a303b;
        color: white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .tabWrap {
    margin-top: 100px;

    text-align: center;
    > label {
      font-size: 30px;
      font-weight: normal;
    }
    .tabBody {
      padding: 50px 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      &.type2 {
      }

      > div {
        margin-right: 1em;
        > img {
          padding-bottom: 40px;
        }
        > p {
          font-size: 30px;
          font-weight: bold;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export const SectionHeader = styled.div`
  text-align: center;
`;

export const SectionSince = styled.label`
  font-size: 48px;
  font-weight: bold;
`;

export const SectionSubTitle = styled.p`
  font-size: 38px;
`;
export const SectionText = styled.p`
  font-size: 48px;
`;
export const SectionSpecial = styled.span`
  position: relative;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid red;
    border-radius: 5em;
    background-color: red;

    position: absolute;
    top: 0;
    left: 0.3em;
    color: red;
  }
`;

export const SectionBody = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const SectionBodyUl = styled.ul`
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const bWidth = 10;
const bHeight = 10;
export const SectionBodyLi = styled.li`
  display: block;
  margin-right: 5em;
  &:last-child {
    margin-right: 0;
  }
  > label {
    font-size: 42px;
  }
  .content {
    margin-top: 44px;
    /* border-left: 1px; */
    padding-left: 1em;
    border-left: 1px dashed #000000;

    .contentItem {
      padding-bottom: 1em;
      > label {
        position: relative;
        font-weight: bold;
        &:before {
          content: "";
          color: ${(props) => (props.type === "cont" ? "red" : "#FDD10F")};
          background: ${(props) => (props.type === "cont" ? "red" : "#FDD10F")};
          position: absolute;
          top: 0.5em;
          left: -1.23em;
          width: ${bWidth}px;
          height: ${bHeight}px;
          border-radius: 5em;
        }
      }
      > p {
        font-size: 22px;
        font-weight: 300;
      }
    }
  }
`;
