import moment from "moment";
import React, { useRef, useState } from "react";
import { HiEye } from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  ChgLiItems,
  ChgUl,
  CreateButton,
  Header,
} from "../../components/Challenge/MobileTemplate/Layout";
import { MPaging } from "../../components/Common/PagingM";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { NormalReqApi } from "../../utils/ReqApi";

const TeamplayMobile = () => {
  const [page, setPage] = useState(1);
  const [sc, setSc] = useState({
    keyword: "",
  });
  const keyRef = useRef(null);
  const navi = useNavigate();
  const { data, error, isLoading } = useQuery(
    ["teamplayList", page, sc],
    async () => await NormalReqApi("get", `/teamplay`),
    { retry: false }
  );
  const handlePage = (page) => {
    setPage(page * 1);
  };

  return (
    <Header
      label={"팀원모집"}
      subLabel={"프로젝트를 팀플레이로 진행하고 싶다면?"}
    >
      <CreateButton label={"글쓰기는 PC 버전으로 이용해주시기 바랍니다"} />
      <ChgUl>
        {isLoading ? (
          <Loading />
        ) : data.result.total === 0 ? (
          <li className={"cNoContents"}>등록된 게시물이 존재하지 않습니다.</li>
        ) : (
          data.result.list.map((item, index) => (
            <ChgLiItems
              key={`tp_${item._id}_${index}`}
              {...item}
              isPrivate={false}
              createdAt={moment(item.createdAt).format("YYYY-MM-DD")}
              linkTo={() => navi(`/teamplay/${item._id}`)}
            />
          ))
        )}
      </ChgUl>
      {!isLoading && <MPaging {...data} toggle={handlePage} />}
    </Header>
  );
};

export default TeamplayMobile;
