import React, { Suspense, useEffect, useRef, useState } from "react";
import { HiX } from "react-icons/hi";
import { useQuery } from "react-query";
import {
  HPHeader,
  HPMiddleSection,
  HPPop,
  HPPopBody,
  HPPopCard,
  HPPopCDesc,
  HPPopContents,
  HPPopCTitle,
  HPPopHeader,
  HPProItem,
  HPProItems,
  HPProNumber,
  HPProSub,
  HPProTitle,
  HPTitle,
  HPTitleSub,
  HPTopSection,
  PriceAmount,
  PriceItem,
  PricePeriod,
} from "../../components/HostCenter/Mobile/MenuList";
import { NormalReqApi } from "../../utils/ReqApi";
const ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT;
const ETC = process.env.REACT_APP_AWS_STORAGE_ETC;

const PromotionMobile = () => {
  return (
    <HPHeader current={"pro"}>
      <HPTopSection>
        <HPTitle>광고상품</HPTitle>
        <HPTitleSub>대티즌이 제공하는 최고의 광고효과를 경험하세요!</HPTitleSub>
      </HPTopSection>
      <HPMiddleSection>
        <Suspense fallback={<div></div>}>
          <PromotionContents />
        </Suspense>
      </HPMiddleSection>
    </HPHeader>
  );
};

const PromotionContents = () => {
  const { data, error, isLoading } = useQuery(
    ["promotion"],
    async () => await NormalReqApi("get", `/product`),
    { retry: true, Suspense: true }
  );

  const [open, setOpen] = useState(false);
  const [promotion, setPromotion] = useState(0);

  useEffect(() => {
    if (!isLoading && data) {
      console.log("done");
    }
  }, [isLoading]);

  const toggleOpen = (promotionId) => {
    setPromotion(data.data.filter((f) => f._id === promotionId)[0]);
    setOpen(true);
  };
  const closePop = (e) => {
    if (e.target === e.currentTarget) {
      setOpen(false);
    }
  };

  return (
    <HPProItems>
      {data?.data?.map((item, index) => (
        <HPProItem key={`promo_${index}`} onClick={() => toggleOpen(item._id)}>
          <HPProTitle>
            <HPProNumber>{index + 1}</HPProNumber>
            {item.title}
          </HPProTitle>
          <HPProSub>{item.des}</HPProSub>
          <img src={ENDPOINT + ETC + item.thumbImage.path} />
        </HPProItem>
      ))}
      <HPPop open={open} onClick={closePop}>
        <HPPopBody>
          <HPPopHeader>
            <HiX size={20} color={"black"} onClick={() => setOpen(false)} />
          </HPPopHeader>
          <PopContents {...promotion} />
        </HPPopBody>
      </HPPop>
    </HPProItems>
  );
};

const PopContents = ({
  id,
  title,
  des,
  thumbImage,
  period,
  backSize,
  thumbSize,
}) => {
  return (
    <HPPopContents>
      <HPPopCTitle>{title}</HPPopCTitle>
      <img src={ENDPOINT + ETC + thumbImage?.path} />
      <HPPopCard label={"특징"} mutiple={false} data={des} />
      <HPPopCard label={"기간 및 단가"} mutiple={true}>
        <ul>
          {period
            ?.filter((f) => f.isShow)
            .map((item, index) => (
              <PriceItem key={`promo_detail_${id}_${index}`}>
                <PricePeriod>{`${item.period}일`}</PricePeriod>
                <PriceAmount>{`${item.price.toLocaleString(
                  "ko-KR"
                )}`}</PriceAmount>
                <span>(vat 별도)</span>
              </PriceItem>
            ))}
        </ul>
      </HPPopCard>
      <HPPopCard label={"사이즈"} mutiple={true}>
        <ul>
          {thumbSize?.flag && (
            <PriceItem>
              <span>썸네일 사이즈 : </span>
              <PricePeriod>{`${thumbSize.width} x ${thumbSize.height}`}</PricePeriod>
            </PriceItem>
          )}
          {backSize?.flag && (
            <PriceItem>
              <span>배경 사이즈 : </span>
              <PricePeriod>{`${backSize.width} x ${backSize.height}`}</PricePeriod>
            </PriceItem>
          )}
        </ul>
      </HPPopCard>
    </HPPopContents>
  );
};

export default PromotionMobile;
