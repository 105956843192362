import React, { useEffect } from "react";
import {
  ChallengePrevList,
  ChallengeSection,
  ChallengeSubMenu,
  ChallengeWrap,
} from "../../components/Challenge/Common/Element";
import Chstyle from "../../assets/css/community.module.scss";
import { DContentDataList } from "../../components/DContents/Common/Element";
import { useRecoilState } from "recoil";
import { challengeSelect } from "../../recoil/AtomConfig";
import GetChallenge from "../../utils/getChallenge";

const NonDataList = ({ type }) => {
  return (
    <li>
      <a>
        <span className={"brd_title"}>등록된 {type} 존재하지 않습니다.</span>
      </a>
    </li>
  );
};

const Challenge = () => {
  const [chList, setChList] = useRecoilState(challengeSelect);
  useEffect(() => {
    const getCh = async () => {
      const res = await GetChallenge();

      setChList(() => {
        const pObject = { ...res };
        return { ...pObject };
      });
    };
    if (chList.loading) {
      getCh();
    }
  }, []);

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={""} />
      <ChallengeWrap label={"공모전 도전하기"}>
        <ChallengeSection
          className={`section boardList challenge ${Chstyle.section} ${Chstyle.boardList}`}
        >
          <DContentDataList
            target={`/teamplay`}
            className={`idx_board freeboard  ${Chstyle.idx_board} ${Chstyle.freeboard}`}
            label={"팀원모집"}
            subLabel="프로젝트를 팀플레이로 진행하고 싶다면?"
          >
            <ul className={"boardList index"}>
              {!chList.loading ? (
                !chList.team.loading && chList.team.data.length > 0 ? (
                  chList.team.data.map((item, index) => (
                    <ChallengePrevList
                      key={`t_${index}`}
                      target={`/teamplay/${item._id}`}
                      type={item.category === 0 ? "공모전" : "대외활동"}
                      {...item}
                    />
                  ))
                ) : (
                  <NonDataList type="팀원모집이" />
                )
              ) : null}
            </ul>
          </DContentDataList>

          <DContentDataList
            target={`/after`}
            className={`idx_board freeboard ${Chstyle.idx_board} ${Chstyle.freeboard}`}
            label={"활동후기"}
            subLabel="공모전·대외활동 수상후기 및 합격후기를 보고 싶다면?"
          >
            <ul className={"boardList index"}>
              {!chList.loading ? (
                !chList.after.loading && chList.after.data.length > 0 ? (
                  chList.after.data.map((item, index) => (
                    <ChallengePrevList
                      key={`a_${index}`}
                      target={`/after/${item._id}`}
                      // type={"공모전"}
                      {...item}
                    />
                  ))
                ) : (
                  <NonDataList type="활동후기가" />
                )
              ) : null}
            </ul>
          </DContentDataList>
        </ChallengeSection>
        <ChallengeSection
          className={`section boardList challenge ${Chstyle.section} ${Chstyle.boardList}`}
        >
          {/* <DContentDataList
            target={`/qna`}
            className={`idx_board freeboard ${Chstyle.idx_board} ${Chstyle.freeboard}`}
            label={"활동Q&A"}
            subLabel="공모전, 대외활동에 도전하고 싶은데 궁금한게 있다면?"
          >
            <ul className={"boardList index"}>
              {!chList.loading ? (
                !chList.qna.loading && chList.qna.data.length > 0 ? (
                  chList.qna.data.map((item, index) => (
                    <ChallengePrevList
                      key={`q_${index}`}
                      target={`/qna/${item._id}`}
                      type={item.category === 0 ? "공모전" : "대외활동"}
                      {...item}
                    />
                  ))
                ) : (
                  <NonDataList type="활동 Q&A가" />
                )
              ) : null}
            </ul>
          </DContentDataList> */}

          <DContentDataList
            target={`/freeboard`}
            className={`idx_board freeboard ${Chstyle.idx_board} ${Chstyle.freeboard}`}
            label={"자유게시판"}
            subLabel="익명으로 우리들만의 고민을 이야기해봐요!"
          >
            <ul className={"boardList index"}>
              {!chList.loading ? (
                !chList.free.loading && chList.free.data.length > 0 ? (
                  chList.free.data.map((item, index) => (
                    <ChallengePrevList
                      key={`f_${index}`}
                      target={`/freeboard/${item._id}`}
                      // type="공모전"
                      {...item}
                    />
                  ))
                ) : (
                  <NonDataList type="자유게시판이" />
                )
              ) : null}
            </ul>
          </DContentDataList>
        </ChallengeSection>
      </ChallengeWrap>
    </div>
  );
};

export default Challenge;
