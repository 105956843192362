import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ChallengeDataHead,
  ChallengeFile,
  ChallengeHTML,
  ChallengeSubHead,
  ChallengeSubMenu,
} from "../../components/Challenge/Common/Element";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientDelReq } from "../../utils/ClientAuth";
import {
  AuthGetAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";

const QnaView = () => {
  const navi = useNavigate();
  const { id } = useParams();
  const [account, setAccount] = useRecoilState(accountSelect);
  const [info, setInfo] = useState({
    loading: true,
    ownYn: false,
  });

  const toggle = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };

  const handleGoBack = () => {
    navi("/qna");
  };
  const handleEdit = () => {
    navi(`/challengeedit/q/${id}`);
  };

  const handleDel = async () => {
    const url = `/qna/${id}`;
    const delRes = await ClientDelReq(
      url,
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (delRes.statusCode === 200) {
      alert("성공적으로 삭제되었습니다.");
      navi("/qna");
    } else if (delRes.statusCode === 400) {
      alert("올바르지 않은 접근입니다. 잠시 후 다시 이용해주시기 바랍니다.");
      return false;
    }
  };

  const getQnaAuth = async (url, accessToken) => {
    const res = await AuthGetAxios(url, accessToken);
    if (res.status === 200) {
      setInfo({
        ...res.data.result.data,
        loading: false,
        ownYn: res.data.result.ownYn,
      });
    } else if (
      res.data.statusCode === 401 &&
      res.data.message === "토큰이 만료되었습니다."
    ) {
      const refreshToken = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const pObject = { ...prev };
        pObject.accessToken = refreshToken.data.data.accessToken;
        return { ...pObject };
      });

      return await ExpiredFilter(
        url,
        getQnaAuth,
        refreshToken.data.data.accessToken
      );
    }
  };

  useEffect(() => {
    const url = `/qna/view?id=${id}`;
    getQnaAuth(url, account.accessToken);
  }, [id]);

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={"qna"} />
      <ChallengeSubHead label={"활동Q&A"}>
        <div className={"board_view qna"}>
          <ul>
            <ChallengeDataHead
              title={info.title}
              linked={info.linkYn}
              linkedTitle={"제 15회 공모전"}
              category={info.category === 0 ? "공모전" : "대외활동"}
              author={info.author?.nickName}
              createdAt={info.createdAt}
              answerYn={info.answerYn}
            />
            <ChallengeHTML content={info.content} answer={info.answer} />
            <ChallengeFile attachment={info.attachment} />
            <div className={"answer_area"}>
              <div className={"btn_area board"}>
                {info.ownYn && (
                  <>
                    <a className={"btn"} onClick={handleEdit}>
                      수정
                    </a>
                    <a className={"btn"} onClick={handleDel}>
                      삭제
                    </a>
                  </>
                )}
                <a className={"btn"} onClick={handleGoBack}>
                  목록으로
                </a>
              </div>
            </div>
          </ul>
        </div>
      </ChallengeSubHead>
    </div>
  );
};

export default QnaView;
