import Default280 from "../../assets/img/common/img_posterDefault_280.jpg";
import Back1 from "../../assets/img/banner/back1.png";
import Back2 from "../../assets/img/banner/back2.jpeg";
export const TempListData = [
  {
    title: "test title1",
    contest: {
      _id: 1,
      host: "test Host",
      category: [{ text: "test category1" }],
      startDate: "2022-10-27",
      endDate: "2022-10-30",
      target: ["test"],
    },
    thumbnail: Default280,
    back: Back1,
  },
  {
    title: "test title2",
    contest: {
      _id: 2,
      host: "test Host",
      category: [{ text: "test category1" }],
      startDate: "2022-10-27",
      endDate: "2022-10-30",
      target: ["test"],
    },
    thumbnail: Default280,
    back: Back2,
  },
  {
    title: "test title3",
    contest: {
      _id: 3,
      host: "test Host",
      category: [{ text: "test category1" }],
      startDate: "2022-10-27",
      endDate: "2022-10-30",
      target: ["test"],
    },
    thumbnail: Default280,
    back: Back1,
  },
  {
    title: "test title4",
    contest: {
      _id: 4,
      hostName: "test Host",
      category: [{ text: "test category1" }],
      startDate: "2022-10-27",
      endDate: "2022-10-30",
      target: ["test"],
    },
    thumbnail: Default280,
    back: Back2,
  },
  {
    title: "test title5",
    contest: {
      _id: 5,
      hostName: "test Host",
      category: [{ text: "test category1" }],
      startDate: "2022-10-27",
      endDate: "2022-10-30",
      target: ["test"],
    },
    thumbnail: Default280,
    back: Back1,
  },
];
