import React, { useState } from "react";
import { SubContainer } from "../../components/HostCenter/Free/Template";
import { FreeEventSubVisual } from "../../components/HostCenter/SubVisual";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  FreeEventEditor,
  FreeEventInfo,
  FreeEventReq,
} from "../../components/HostCenter/Free/FreeForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import {
  AuthPostAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";
import { handleFileUpload } from "../../utils/FileController";
import { LoadingLabel } from "../../components/Common/SubmitLoading";

const inputSchema = yup.object({
  info: yup.object({
    title: yup.string().required("이벤트 명을 입력해주시기 바랍니다."),
    reward: yup.string().required("이벤트 혜택을 입력해주시기 바랍니다."),
    target: yup.string().required("참가자격을 입력해주시기 바랍니다."),
    dupliYn: yup
      .boolean()
      .oneOf([true, false], "중복접수항목을 선택해주시기 바랍니다."),
  }),
  date: yup.object({
    startDate: yup.string(),
    endDate: yup.string(),
    submitStartDate: yup.string(),
    submitEndDate: yup.string(),
  }),
  editor: yup.object({
    content: yup.string(),
  }),
  file: yup.object({
    thumb: yup
      .mixed()
      .test("fileSize", "파일을 선택해주시기 바랍니다.", (value) => {
        if (!value.length) return false;
        return value[0].size > 0;
      }),
  }),
  submitInfo: yup
    .array()
    .min(1, "접수선택 항목을 입력해주시기 바랍니다")
    .of(
      yup.object().shape({
        ordered: yup.number(),
        require: yup.boolean(),
        title: yup.string(),
        type: yup.number(),
        text: yup.string(),
      })
    ),
});

const FreeEvent = () => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [submitLoading, setSubmitLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(inputSchema) });

  const authReq = async (url, data, accessToken) => {
    const postRes = await AuthPostAxios(url, data, accessToken);
    const { data: postData } = postRes;
    if (
      postData.statusCode === 401 &&
      postData.message === "토큰이 만료되었습니다."
    ) {
      const rtkRes = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const pObject = { ...prev };
        pObject.accessToken = rtkRes.data.data.accessToken;
        return { ...pObject };
      });

      return await ExpiredFilter(
        url,
        authReq,
        data,
        rtkRes.data.data.accessToken
      );
    } else {
      return postData;
    }
  };

  const onSubmit = async (data) => {
    setSubmitLoading(true);

    const dataList = [
      { file: data.file.thumb[0], type: "thumb", objectTarget: "file.thumb" },
    ];
    const convertedData = await handleFileUpload(dataList, data);
    if (!convertedData) {
      alert(
        "파일을 업로드하는중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
      );
      return false;
    }

    const reqRes = await authReq(
      "reqEvent",
      { ...convertedData },
      account.accessToken
    );

    if (reqRes.statusCode === 200) {
      alert("성공적으로 저장되었습니다.");
      setSubmitLoading(false);
    } else {
      alert("저장중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다.");
      setSubmitLoading(false);
    }
  };

  return (
    <div className={"container sub"}>
      <FreeEventSubVisual />
      <SubContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"regist_area contest"}>
            <h2 className={"sub_title"}>
              <span>이벤트 정보</span>
            </h2>
            <FreeEventInfo
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <FreeEventReq setValue={setValue} errors={errors} />

            <FreeEventEditor setValue={setValue} watch={watch} />
            <div className="btn_area board">
              <button className="btn">저장</button>
            </div>
          </div>
        </form>
        {submitLoading && <LoadingLabel label={"loading ..."} />}
      </SubContainer>
    </div>
  );
};

export default FreeEvent;
