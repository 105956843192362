import React from "react";
import {
  HiOutlineExclamation,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import styled from "styled-components";

const Wrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: white;
  }
  .mark {
    margin-right: 1em;
  }
`;

const NotFound = () => {
  return (
    <Wrap>
      <HiOutlineExclamation size={50} color={"white"} className={"mark"} />
      <span>요청하신 페이지를 찾을 수 없습니다.</span>
    </Wrap>
  );
};

export default NotFound;
