import React from "react";
import styled from "styled-components";

export const UnivItems = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  border-top: 1px solid #efefef !important;
  > li {
    cursor: pointer;

    font-size: 1.2em !important;
    width: calc((100% - 6em) / 3);
    padding: 0.5em 1em !important;
    margin: 0.5em 1em;
    text-align: center;
    border: none !important;

    @media screen and (max-width: 766px) {
      width: 100%;
    }
    > a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const AOCBottom = styled.div`
  .infoMore {
    padding: 5em 8em;
    /* width: 100%; */
    font-size: 1.2em;
    font-weight: 300;
    background: #d8d8d8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 766px) {
      padding: 2em 1em;

      width: 100%;
    }
    > p {
      @media screen and (max-width: 766px) {
        font-size: 0.75em;
      }
      display: block;
      b {
        font-weight: 500;
      }
    }
    > a {
      display: block;
      background-color: #e7191f;
      margin-top: 1.5em;
      text-align: center;
      padding: 0.25em 2em;
      border-radius: 10px;
      color: white;
      box-shadow: 0px 2px 5px 0px gray;
      @media screen and (max-width: 766px) {
        font-size: 0.75em;
      }
    }
  }
  .infoDesk {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    background-color: black;
    color: white;
    font-size: 1.2em;
  }
`;
