import React, { useState } from "react";
import styled from "styled-components";
import Default280 from "../../../assets/img/common/img_posterDefault_280.jpg";
import { HiChevronDown, HiEye } from "react-icons/hi";
import {
  PopFilter,
  PopListData,
  PopListWrap,
} from "../../Contest/Mobile/Frame";
import { CategoryText, WDataWrap, WTitle, WView, WViewAndDate } from "./Frame";
import moment from "moment";

const FilterDiv = styled.div`
  margin: 0.5em 1em 2em 1em;
`;
const FilterList = styled.div`
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const FilterBtn = styled.button`
  white-space: nowrap;
  margin-right: 0.5em;
  border: none;
  border-radius: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: ${(props) =>
    props.type === "result" ? `#2b303b` : `#e4e4e4`};
  height: 30px;
  color: ${(props) => (props.type === "result" ? `white` : `#9d9d9d`)};
`;
const FilterText = styled.span`
  font-size: 0.65em;
  line-height: 30px;
  font-weight: 400;
  &:first-child {
    padding-right: 1em;
  }
`;

const FilterResultWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-x: scroll;
  padding-top: 1em;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const FilterOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
`;

const DataWrap = styled.div`
  margin-bottom: 3em;
`;
const ImgArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoTextArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const InfoViewAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  color: #a0a0a0;
  font-size: 0.5em;
`;
const InfoViewCnt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InfoViewCntText = styled.span`
  margin-left: 0.25em;
`;

const InfoTitle = styled.span`
  font-size: 0.75em;
  font-weight: 500;
  display: block;
  margin-top: 0.5em;
`;
const InfoSummary = styled.p`
  font-size: 0.65em;
  color: #a0a0a0;
  margin-top: 0.5em;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const FilterElement = () => {
  const [option, setOption] = useState({
    isOpen: false,
    init: true,
    type: undefined,
  });
  const togglePop = (type) => {
    setOption({ isOpen: !option.isOpen, init: false, type: type });
  };
  const closePop = () => {
    setOption({ isOpen: false, type: undefined });
  };
  return (
    <>
      <FilterDiv>
        <FilterList>
          <FilterBtn onClick={() => togglePop("st")}>
            <FilterText>정렬</FilterText>
            <FilterText>
              <HiChevronDown />
            </FilterText>
          </FilterBtn>
          <FilterBtn onClick={() => togglePop("sc")}>
            <FilterText>검색</FilterText>
            <FilterText>
              <HiChevronDown />
            </FilterText>
          </FilterBtn>
        </FilterList>
        <FilterResultWrap>
          <FilterBtn type="result">
            <FilterText>정렬</FilterText>
            <FilterText>x</FilterText>
          </FilterBtn>
          <FilterBtn type="result">
            <FilterText>검색</FilterText>
            <FilterText>x</FilterText>
          </FilterBtn>
        </FilterResultWrap>
      </FilterDiv>

      {option.isOpen && (
        <FilterOverlay role="overlay" onClick={closePop}></FilterOverlay>
      )}
      <PopFilter open={option.isOpen} init={option.init}>
        <PopListWrap>
          <PopListData>test</PopListData>
          <PopListData>test</PopListData>
          <PopListData>test</PopListData>
          <PopListData>test</PopListData>
          <PopListData>test</PopListData>
          <PopListData>test</PopListData>
          <PopListData>test</PopListData>
        </PopListWrap>
      </PopFilter>
    </>
  );
};

export const EmptyElement = ({ thumbnail, title, des, createdAt, viewCnt }) => {
  return (
    <DataWrap>
      <ImgArea>
        <img
          src={`${process.env.REACT_APP_AWS_ENDPOINT}w_280/${thumbnail.path}`}
          style={{
            width: "350px",
            height: "350px",
            borderRadius: "5px",
          }}
        />
      </ImgArea>
      <InfoTextArea>
        <InfoTitle>{title}</InfoTitle>
        <InfoSummary>{des}</InfoSummary>
        <InfoViewAndDate>
          <InfoViewCnt>
            <HiEye /> <InfoViewCntText>{viewCnt}</InfoViewCntText>
          </InfoViewCnt>
          <p>{moment(createdAt).format("YYYY-MM-DD")}</p>
        </InfoViewAndDate>
      </InfoTextArea>
    </DataWrap>
  );
};

export const WeeklyElement = ({ category, title, viewCnt, createdAt }) => {
  return (
    <WDataWrap>
      <CategoryText>{category === 1 ? "위클리" : "포커스"}</CategoryText>
      <WTitle>{title}</WTitle>
      <WViewAndDate>
        <WView>
          <HiEye />
          <span>{viewCnt}</span>{" "}
        </WView>
        {moment(createdAt).format("YYYY-MM-DD")}
      </WViewAndDate>
    </WDataWrap>
  );
};

export const DepicElement = ({ thumbnail, title, viewCnt, createdAt, des }) => {
  return (
    <DataWrap>
      <ImgArea>
        <img
          src={process.env.REACT_APP_AWS_ENDPOINT + "w_280/" + thumbnail.path}
          style={{
            width: "280px",
            height: "158px",
            borderRadius: "5px",
          }}
        />
      </ImgArea>
      <InfoTextArea>
        <InfoTitle>{title}</InfoTitle>
        <InfoSummary>{des}</InfoSummary>
        <InfoViewAndDate>
          <InfoViewCnt>
            <HiEye /> <InfoViewCntText>{viewCnt}</InfoViewCntText>
          </InfoViewCnt>
          <p>{moment(createdAt).format("YYYY-MM-DD")}</p>
        </InfoViewAndDate>
      </InfoTextArea>
    </DataWrap>
  );
};

export const EventElement = ({ thumbnail, title, viewCnt, createdAt, des }) => {
  return (
    <DataWrap>
      <ImgArea>
        <img
          src={process.env.REACT_APP_AWS_ENDPOINT + "w_380/" + thumbnail.path}
          style={{
            width: "380px",
            height: "421px",
            borderRadius: "5px",
          }}
        />
      </ImgArea>
      <InfoTextArea>
        <InfoTitle>{title}</InfoTitle>
        <InfoSummary>{des}</InfoSummary>
        <InfoViewAndDate>
          <InfoViewCnt>
            <HiEye /> <InfoViewCntText>{viewCnt}</InfoViewCntText>
          </InfoViewCnt>
          <p>{moment(createdAt).format("YYYY-MM-DD")}</p>
        </InfoViewAndDate>
      </InfoTextArea>
    </DataWrap>
  );
};
