import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  AfterViewComponent,
  ChallengeSubMenu,
} from "../../components/Challenge/Common/Element";
import ReplyComponent from "../../components/Reply/ReplyComponent";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientDelReq } from "../../utils/ClientAuth";
import {
  AuthGetAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";

const FreeBoardView = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const [info, setInfo] = useState({
    loading: true,
    ownYn: false,
  });

  const toggle = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };

  const handleGoBack = () => {
    navi("/freeboard");
  };
  const handleDel = async () => {
    const url = `/freeboard/${id}`;
    const delRes = await ClientDelReq(
      url,
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (delRes.statusCode === 200) {
      alert("성공적으로 삭제되었습니다.");
      navi("/freeboard");
    } else if (delRes.statusCode === 400) {
      alert("올바르지 않은 접근입니다. 잠시 후 다시 이용해주시기 바랍니다.");
      return false;
    }
  };

  const handleEdit = () => {
    navi(`/challengeedit/f/${id}`);
  };

  const getFBAuth = async (url, accessToken) => {
    const res = await AuthGetAxios(url, accessToken);
    if (res.data.statusCode === 200) {
      setInfo({
        ...res.data.result.data,
        loading: false,
        ownYn: res.data.result.ownYn,
      });
    } else if (
      res.data.statusCode === 401 &&
      res.data.message === "토큰이 만료되었습니다."
    ) {
      const refreshToken = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const pObject = { ...prev };
        pObject.accessToken = refreshToken.data.data.accessToken;
        return { ...pObject };
      });
      return await ExpiredFilter(
        url,
        getFBAuth,
        refreshToken.data.data.accessToken
      );
    }
  };

  useEffect(() => {
    const url = `/freeboard/view?id=${id}`;
    getFBAuth(url, account.accessToken);
  }, [id]);

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={"freeboard"} />
      <AfterViewComponent
        label={"자유게시판"}
        type="freeboard"
        title={info.title}
        author={info.author?.nickName}
        createdAt={info.createdAt}
        content={info.content}
        attachment={info.attachment}
      >
        <div className={"answer_area"}>
          <ReplyComponent id={id} type="freeboard" />
        </div>
        <div className={"answer_area"}>
          <div className={"btn_area board"}>
            {info.ownYn && (
              <>
                <a className={"btn"} onClick={handleEdit}>
                  수정
                </a>
                <a className={"btn"} onClick={handleDel}>
                  삭제
                </a>
              </>
            )}
            <a className={"btn"} onClick={handleGoBack}>
              목록으로
            </a>
          </div>
        </div>
      </AfterViewComponent>
    </div>
  );
};

export default FreeBoardView;
