import React from "react";
import styled from "styled-components";

export const DCwrap = styled.div`
  margin: 0 1em 0 1em;
`;
export const Section = styled.div`
  margin-bottom: 4em;
`;

export const SectionWrap = styled.div`
  margin: 0 0 1em;
  padding: 0 0 1.5em;
  color: #282c36;
  border-bottom: 1px solid #afaeae;
`;

export const STitle = styled.span`
  font-weight: 600;
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    bottom: 0.2em;
    right: -0.6em;
    width: 0.3em;
    height: 0.3em;
    border-radius: 50%;
    background-color: #e9360c;
  }
`;
export const SSummary = styled.small`
  display: block;
  font-size: 0.67em;
  font-weight: 400;
`;
export const SMoreBtn = styled.button`
  font-size: 0.67em;
  background: none;
  border: none;
  float: right;
`;

export const ContentsWrap = styled.div`
  overflow-x: scroll;
  padding-bottom: 0.5em;
`;

export const ThumbSlide = styled.div`
  display: inline-flex;
  justify-content: flex-start;
`;

export const ThumbItem = styled.div`
  width: 280px;
  margin-right: 1em;
`;

export const CTitle = styled.span`
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.75em;
`;
export const SubText_P = styled.span`
  /* display: block; */
  /* float: right; */
  /* font-size: 0.55em; */
  /* padding-top: 1em; */
`;
export const CntAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.5em;
  margin-top: 1em;
  color: #a0a0a0;
`;
export const CntArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// weekly
export const WeeklyLi = styled.li`
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #e9e9e9;
`;
export const CategoryText = styled.span`
  display: block;
  font-size: 0.5em;
`;
export const WTitle = styled.span`
  display: block;
  font-size: 0.75em;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
export const Wdate = styled.span`
  display: block;
  font-size: 0.5em;
  width: 100%;
  text-align: right;
  color: #a0a0a0;
  margin-top: 1em;
`;

export const WViewAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.5em;
  color: #a0a0a0;
  margin-top: 1em;
`;
export const WView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const WDataWrap = styled.div`
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #dfdfdf;
`;

// common
export const TotalText = styled.p`
  font-size: 0.6em;
  margin-bottom: 1em;
  color: #a0a0a0;
`;
