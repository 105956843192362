import axios from "axios";
import { useRecoilState } from "recoil";
import { accountSelect } from "../recoil/AtomConfig";

const API = process.env.REACT_APP_AWS_API;

export const AuthReqApi = async (type, url, data) => {
  const [account, setAccount] = useRecoilState(accountSelect);
  switch (type) {
    case "get":
      const res = await axios.get(API + url, {
        headers: {
          Authorization: `Barear ${account.accessToken}`,
        },
        validateStatus: function (status) {
          return status < 500;
        },
      });
      const { data: getData } = res;
      if (res.status === 401) {
        const refreshResult = await getRefreshToken(account.refreshToken);

        setAccount((prev) => {
          const prevObject = { ...prev };
          prevObject.accessToken = refreshResult.data.data.accessToken;
          return { ...prevObject };
        });
        // replay req
        AuthReqApi(type, url, data);
      } else {
        return getData;
      }
      break;
    case "post":
      const postRes = await axios.post(
        API + url,
        { ...data },
        {
          headers: {
            Authorization: `Barear ${account.accessToken}`,
          },
          validateStatus: function (status) {
            return status < 500;
          },
        }
      );
      const { data: postData } = postRes;
      if (res.status === 401) {
        const refreshPostResult = await getRefreshToken(account.refreshToken);
        setAccount((prev) => {
          const prevObject2 = { ...prev };
          prevObject2.accessToken = refreshPostResult.data.data.accessToken;
          return { ...prevObject2 };
        });

        AuthReqApi(type, url, data);
      } else {
        return postData;
      }
      break;
    case "patch":
      break;
    case "delete":
      break;
  }
};

export const LoginPostApi = async (url, data) => {
  url = url.split("")[0] === "/" ? url : "/" + url;
  const result = await axios.post(
    API + url,
    { ...data },
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  return result;
};

export const tempGet = async (url, data) => {
  const { data: getData } = await axios.get("http://192.168.0.144:3000" + url, {
    validateStatus: function (status) {
      return status < 500;
    },
  });
  return getData;
};

export const NormalReqApi = async (type, url, data) => {
  url = url.split("")[0] === "/" ? url : "/" + url;
  switch (type) {
    case "get":
      const { data: getData } = await axios.get(API + url, {
        validateStatus: function (status) {
          return status < 500;
        },
      });
      return getData;

    case "post":
      const { data: postData } = await axios.post(
        API + url,
        { ...data },
        {
          validateStatus: function (status) {
            return status < 500;
          },
        }
      );
      return postData;

    case "patch":
      break;
    case "delete":
      break;
  }
};

// export const OptionalReqApi = async (url,data) => {
//   const
// }

export const getRefreshToken = async (refreshToken) => {
  // console.log("ac 토큰 만료");
  return await axios.post(
    API + "/auth/refresh",
    {},
    {
      headers: {
        RefreshToken: `${refreshToken}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

export const ExpiredFilter = async (url, fun, data, token) => {
  if (Object.keys(data).length > 0) {
    const result = await fun(url, data, token);
    return result;
  } else {
    const result = await fun(url, token);
    return result;
  }
};

export const ExpiredFilter2 = async (url, fun, data, token, rToken, setFun) => {
  if (Object.keys(data).length > 0) {
    const result = await fun(url, data, token, rToken, setFun);
    return result;
  } else {
    const result = await fun(url, token, rToken, setFun);

    return result;
  }
};

export const AuthPostAxios = async (url, data, token) => {
  url = url.split("")[0] === "/" ? url : "/" + url;
  const postRes = await axios.post(
    API + url,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  return postRes;
};

export const AuthGetAxios = async (url, token) => {
  url = url.split("")[0] === "/" ? url : "/" + url;
  const res = await axios.get(
    API + url,
    token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );

  return res;
};
