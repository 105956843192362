import React, { useEffect, useState } from "react";
import HostCenterHeaderTabs from "../../components/HostCenter/HeaderTabs";
import { AgentSubVisual } from "../../components/HostCenter/SubVisual";
import promoStyle from "../../assets/css/promo.module.scss";
import { LabelInputElement } from "../../components/HostCenter/Free/FreeElement";
import {
  PromoBasicIndex,
  PromoManager,
} from "../../components/HostCenter/Promotion/PromoForm";
import { reqFormValid } from "../../components/HostCenter/Promotion/PromoValid";
import {
  AuthPostAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import {
  handleFileUpload,
  handleFileUploadList,
} from "../../utils/FileController";
import { ClientReqPost } from "../../utils/ClientAuth";

const AgentReq = () => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [sData, setSData] = useState({
    reqType: "a",
  });
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  useEffect(() => {
    // console.log(sData);
  }, [sData]);

  const authReq = async (url, data, accessToken) => {
    const postRes = await AuthPostAxios(url, data, accessToken);
    const { data: postData } = postRes;

    if (
      postData.statusCode === 401 &&
      postData.message === "토큰이 만료되었습니다."
    ) {
      const rtkRes = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const prevObject = { ...prev };
        prevObject.accessToken = rtkRes.data.data.accessToken;
        return { ...prevObject };
      });

      return await ExpiredFilter(
        url,
        authReq,
        data,
        rtkRes.data.data.accessToken
      );
    } else {
      return postData;
    }
  };

  const submitContest = async (data) => {
    const fResult = await handleFileUploadList(data.submitData.attachment);
    if (!fResult || fResult.length === 0) {
      alert("등록중 오류가 발생했습니다.");
      return false;
    }

    const template = {
      ...data.submitData,
      manager: { ...data.manager },
      attachment: fResult,
    };

    const result = await ClientReqPost(
      `/reqc`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );

    if (result.statusCode === 200 && result.flag) {
      alert("성공적으로 요청되었습니다.");
    }
  };
  const submitSupporters = async (data) => {
    const fResult = await handleFileUploadList(data.submitData.attachment);
    if (!fResult || fResult.length === 0) {
      alert("등록중 오류가 발생했습니다.");
      return false;
    }
    const template = {
      ...data.submitData,
      manager: { ...data.manager },
      attachment: fResult,
    };

    const result = await ClientReqPost(
      `/reqa`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.statusCode === 200 && result.flag) {
      alert("성공적으로 요청되었습니다.");
    }
  };

  const submitAdv = async (data) => {
    const fResult = await handleFileUploadList(data.submitData.attachment);
    if (!fResult || fResult.length === 0) {
      alert("등록중 오류가 발생했습니다.");
      return false;
    }
    const template = {
      ...data.submitData,
      manager: { ...data.manager },
      attachment: fResult,
      content: data.submitData.request,
    };

    const result = await ClientReqPost(
      `/reqad`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.statusCode === 200 && result.flag) {
      alert("성공적으로 요청되었습니다.");
    }
  };

  const handleSubmit = async (data) => {
    // console.log(sData);
    // return false;
    if (!account.accessToken || !account.id) {
      alert("로그인 후 이용할 수 있습니다.");
      return false;
    }
    const isValid = reqFormValid(sData);

    if (isValid) {
      if (sData.reqType === "b") {
        await submitContest(sData);
      } else if (sData.reqType === "c") {
        await submitSupporters(sData);
      } else if (sData.reqType === "a") {
        if (
          sData.submitData.period === 999 &&
          sData.submitData.price === 999 &&
          !sData.submitData.customPeriod
        ) {
          alert("기간을 입력해주시기 바랍니다");
          return false;
        }

        await submitAdv(sData);
      }
    }

    return false;
  };
  return (
    <div className={"container sub"}>
      <HostCenterHeaderTabs current={"agent"} />
      <AgentSubVisual />
      <div className={`sub_container ${promoStyle.sub_container}`}>
        <div
          className={`contents mypage ${promoStyle.contents} ${promoStyle.mypage}`}
        >
          <div
            className={`regist_area contest ${promoStyle.regist_area} ${promoStyle.contest} ${promoStyle.manager}`}
          >
            <PromoManager origin={sData} onChange={setSData} />
          </div>
          <div
            className={`regist_area contest ${promoStyle.regist_area} ${promoStyle.contest} ${promoStyle.req}`}
          >
            <h2 className={"sub_title"}>
              <span>문의사항</span>
            </h2>

            <PromoBasicIndex origin={sData} onChange={setSData} />
          </div>
          <div className="btn_area regist">
            <button
              type="button"
              className="btn regist"
              style={{ width: "100%" }}
              onClick={handleSubmit}
            >
              문의하기
            </button>
          </div>
        </div>
      </div>

      {/* <div className={} */}
    </div>
  );
};

export default AgentReq;
