import React from "react";

import HostCenterHeaderTabs from "../../components/HostCenter/HeaderTabs";
import HostCenterSection1 from "../../components/HostCenter/HSection1";
import HostCenterSection2 from "../../components/HostCenter/HSection2";
import HostCenterSection3 from "../../components/HostCenter/HSection3";

const HostCetner = () => {
  return (
    <div className={"container sub"}>
      <HostCenterHeaderTabs current={"main"} />
      <HostCenterSection1 />
      <HostCenterSection2 />
      <HostCenterSection3 />
    </div>
  );
};

export default HostCetner;
