import moment from "moment";
import React, { Suspense } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { ViewHeader } from "../../components/DContents/Mobile/MTemplate";
import { NormalReqApi } from "../../utils/ReqApi";

const EventSubmitREsultViewM = () => {
  const { id } = useParams();
  const route = useNavigate();

  const handleGoBack = () => {
    route(-1);
  };

  return (
    <ViewHeader label="이벤트 발표">
      <Suspense fallback={<Layout isData={false} />}>
        <Contents id={id} goBack={handleGoBack} />
      </Suspense>
    </ViewHeader>
  );
};

const Contents = ({ id, goBack }) => {
  const { data, error } = useQuery(
    ["eResultView", id],
    async () => await NormalReqApi("get", `/eventresult/view?id=${id}`),
    { retry: true, suspense: true }
  );

  return <Layout isData={true} goBack={goBack} {...data.data} />;
};

const Layout = ({ isData, goBack, title, createAt, viewCnt, content }) => {
  return (
    <Sections>
      <TopSection>
        <TopTitle isExist={isData}>{title}</TopTitle>
        <TopDate isExist={isData}>
          {moment(createAt).format("YYYY-MM-DD")}
        </TopDate>
      </TopSection>
      <MiddleSection>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </MiddleSection>
      <BottomSection>
        <BackButton type={"button"} onClick={goBack}>
          뒤로가기
        </BackButton>
      </BottomSection>
    </Sections>
  );
};

const blinkAM = keyframes`
0%{
    opacity: 0.5;
}
50%{
    opacity: 1;
}
100%{
    opacity: 0.5;
}
`;
const Sections = styled.div``;
const TopSection = styled.div`
  padding: 0.25em 0.5em;
`;
const MiddleSection = styled.div`
  background-color: white;
  margin: 0.5em 0;
  padding: 0.5em 0.5em;
  font-size: 0.55em;
`;
const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0;
`;

const TopTitle = styled.span`
  display: block;
  width: 100%;
  color: #efefefef;
  font-size: 0.75em;
  ${(props) =>
    !props.isExist &&
    css`
      padding: 10px;
      background-color: #4545459e;
      border-radius: 10px;
      animation: ${blinkAM} 2s linear infinite;
    `}
`;
const TopDate = styled(TopTitle)`
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.55em;
`;

const BackButton = styled.button`
  width: 100%;
  padding: 0.25em;
  font-size: 0.75em;
  border: none;
  color: #efefef;
  background-color: #494c56;
`;

export default EventSubmitREsultViewM;
