import React from "react";
import { useQuery } from "react-query";
import { NormalReqApi } from "../../utils/ReqApi";
import Chstyle from "../../assets/css/community.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";

const NoticeList = ({ type }) => {
  const { data, error } = useQuery(
    ["noticeList", type],
    async () => await NormalReqApi("get", `/notice/${type}`),
    { retry: false, suspense: true }
  );

  const render = (type, data) => {
    switch (type) {
      case "teamplay":
        return data.data.map((item, index) => (
          <li className={`td ${Chstyle.td}`} key={`n_i_${index}`}>
            {/* <span className={"w15"}>
              <a className="notice">공지</a>
            </span> */}
            <span className={`brd_title w70 ${Chstyle.brd_title}`}>
              <Link to={`/challenge/${type}/notice/${item._id}`}>
                {item.title}
              </Link>
            </span>
            <span className={`brd_name w15 ${Chstyle.brd_name}`}>대티즌</span>
            <span
              className={`brd_info brd_date w15 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
            >
              {moment(item.createdAt).format("yyyy-MM-DD HH:mm")}
            </span>
          </li>
        ));
      case "after":
        return data.data.map((item, index) => (
          <li className={`td ${Chstyle.td}`} key={`n_i_a_${index}`}>
            {/* <span className={"w15"}>
              <a className="notice">공지</a>
            </span> */}
            <span className={`brd_title w70 ${Chstyle.brd_title}`}>
              <Link to={`/challenge/${type}/notice/${item._id}`}>
                {item.title}
              </Link>
            </span>
            <span className={`brd_name w15 ${Chstyle.brd_name}`}>대티즌</span>
            <span
              className={`brd_info brd_date w15 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
            >
              {moment(item.createdAt).format("yyyy-MM-DD HH:mm")}
            </span>
          </li>
        ));
      case "qna":
        return data.data?.map((item, index) => (
          <li className={`td ${Chstyle.td}`} key={`n_i_q_${index}`}>
            <Link to={`/challenge/${type}/notice/${item._id}`}>
              {/* <span className={`brd_cate w10 ${Chstyle.brd_cate}`}>공지</span> */}
              <span className={`brd_title w70 ${Chstyle.brd_title}`}>
                {item.title}
              </span>
              <span className={`brd_name w10 ${Chstyle.brd_name}`}>대티즌</span>
              <span
                className={`brd_info brd_date w15 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
              >
                {moment(item.createdAt).format("yyyy-MM-DD HH:mm")}
              </span>
              <span
                className={`brd_answer w10 done ${Chstyle.brd_answer}`}
              ></span>
            </Link>
          </li>
        ));
      case "freeboard":
        return data.data.map((item, index) => (
          <li className={`td ${Chstyle.td}`} key={`n_i_f_${index}`}>
            {/* <span className={"w15"}>
              <a className="notice">공지</a>
            </span> */}
            <span className={`brd_title w70 ${Chstyle.brd_title}`}>
              <Link to={`/challenge/${type}/notice/${item._id}`}>
                {item.title}
              </Link>
            </span>
            {/* <span className={`brd_name w15 ${Chstyle.brd_name}`}>대티즌</span> */}
            <span
              className={`brd_info brd_date w30 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
            >
              {moment(item.createdAt).format("yyyy-MM-DD HH:mm")}
            </span>
          </li>
        ));
      case "normal":
        return <li></li>;
    }
  };

  return <>{render(type, data)}</>;
};

export default NoticeList;
