import React, { useEffect, useState } from "react";

import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
const RouteChangeTracker = () => {
  const location = useLocation();
  const [init, setInit] = useState(false);

  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA_ID);
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      ReactGA4.send({
        hitType: "pageview",
        path: location.pathname,
        location: location.pathname,
        title: location.pathname,
      });
    }
  }, [init, location]);
};
export default RouteChangeTracker;
