import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import custom from "../../assets/css/login.module.scss";
import { HiOutlineHome } from "react-icons/hi";
// img
import Logo from "../../assets/img/common/logo.png";
import { LoginHeader } from "../../components/Login/LoginHeader";
import axios from "axios";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { LoginPostApi, NormalReqApi } from "../../utils/ReqApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";

const SignIn = () => {
  const navi = useNavigate();
  const [state, setState] = useRecoilState(accountSelect);
  const [cookies, setCookie] = useCookies(["rtk"]);
  const handleClick = (e) => {
    e.preventDefault();

    handleReqLogin();
  };

  const handleReqLogin = async () => {
    const reqBody = {
      email: account.id,
      password: account.pwd,
    };
    const res = await LoginPostApi("/auth/login", reqBody);
    if (res.status === 200) {
      const { data } = res;
      // setCookie("rtk", data.data.refreshToken, {
      //   // httpOnly: true,
      //   // secure: true,
      //   path: "/",
      //   domain: ".detizen.com",
      //   // sameSite: "none",
      // });

      setState((prev) => {
        const prevObject = { ...prev };
        prevObject.id = data.data.id;
        prevObject.accessToken = data.data.accessToken;
        prevObject.role = data.data.userType === 2 ? "COMPANY" : "NORMAL";
        prevObject.refreshToken = data.data.refreshToken;
        return { ...prevObject };
      });
      navi("/");
    } else if (res.status === 204) {
      console.log("no user");
      toast.error(
        <span style={{ fontSize: "0.65em" }}>
          아이디 / 비밀번호를 확인해주세요!
        </span>,
        {
          theme: "colored",
          closeButton: false,
        }
      );
    }
  };
  useEffect(() => {
    // setState((prev) => )
    console.log("loginpage");
    return () => {};
  }, []);

  const [account, setAccount] = useState({
    id: undefined,
    pwd: undefined,
  });
  return (
    <>
      <LoginHeader>
        <div className={custom.login_box}>
          <div className={custom.tab_content}>
            <form onSubmit={handleClick}>
              <div className={custom.input_area}>
                <div style={{ marginBottom: "1em" }}>
                  <p style={{ fontSize: "0.75em", color: "gray" }}>아이디</p>
                  <input
                    type="text"
                    name="email"
                    style={{ borderRadius: "5px" }}
                    className={custom.inp_text}
                    placeholder="이메일 주소"
                    onChange={(e) =>
                      setAccount({ ...account, id: e.target.value })
                    }
                  />
                </div>
                <p style={{ fontSize: "0.75em", color: "gray" }}>비밀번호</p>
                <input
                  type="password"
                  name="password"
                  style={{ borderRadius: "5px" }}
                  className={custom.inp_text}
                  placeholder="비밀번호"
                  onChange={(e) =>
                    setAccount({ ...account, pwd: e.target.value })
                  }
                />
              </div>
              <div className={custom.btn_areaLogin + " " + custom.login}>
                <button
                  type="submit"
                  className={custom.btn + " " + custom.login}
                >
                  로그인
                </button>

                <Link to={`/signup`} className={custom.btn}>
                  회원가입
                </Link>

                <Link
                  to={"/findId"}
                  className={
                    custom.btn + " " + custom.half + " " + custom.findID
                  }
                >
                  아이디 찾기
                </Link>
                <Link
                  to={"/findpwd"}
                  className={
                    custom.btn + " " + custom.half + " " + custom.findPW
                  }
                >
                  비밀번호 찾기
                </Link>
              </div>
            </form>

            {/* <button onClick={handleRefreshTest}>api test</button> */}
          </div>
        </div>
      </LoginHeader>
      <ToastContainer
        position="bottom-center"
        theme="light"
        closeOnClick
        hideProgressBar={true}
      />
    </>
  );
};

export default SignIn;
