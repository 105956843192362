import React from "react";
import hcStyle from "../../assets/css/req2.module.scss";
import Img from "../../assets/img/common/img_freeReg01.png";

const HostCenterSection1 = () => {
  return (
    <div className={`sub_container ${hcStyle.sub_container}`}>
      <div className={`contents mypage ${hcStyle.contents} ${hcStyle.mypage}`}>
        <div className="slogan_area">
          <span>공고등록 어떻게 하나요?</span>
          <p>
            <b>3단계</b>면 충분한, 쉽고 빠른 <b>등록 자동화 시스템</b>
          </p>
        </div>
        <img className={"agentImg"} src={Img} />
      </div>
    </div>
  );
};

export default HostCenterSection1;
