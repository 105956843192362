import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import UserMyEvent from "../../../components/User/UserMyEvent";

const MyEvent = () => {
  const { id } = useParams();

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
      </div>
      <div className={`sub_container`}>
        <div className={`contents mypage`}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>내 이벤트</span>
          </h2>

          <div className={"myInfo_area"}>
            <Suspense fallback={<div></div>}>
              <UserMyEvent id={id} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEvent;
