import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { NormalReqApi } from "../../utils/ReqApi";
import Default from "../../assets/img/common/img_posterDefault_280.jpg";
import moment from "moment";
import { HiDotsHorizontal } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;

  .dcMainTitle {
    text-align: center;
    .dcTitle {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;
const Section = styled.div`
  padding: 0.5em 0.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  label {
    width: 100%;
    &.dcSubTitle {
      color: white;
      font-size: 0.75em;

      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .dcMore {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      small {
        font-weight: 200;
        font-size: 0.55em;
      }
    }
  }
  .dcThumbnail {
    /* width: 100%; */

    overflow-x: scroll;
    white-space: nowrap;
    /* align-items: stretch; */
    > li {
      width: 280px;
      display: inline-flex;
      white-space: break-spaces;
      flex-direction: column;
      padding-right: 0.5em;
      margin-right: 0.5em;

      &.dcThumbInfo {
        p {
          font-size: 0.75em;
          color: white;
          &.dcThumbTitle {
            font-size: 0.55em;
            padding: 0.25em 0;
          }
          &.dcThumbSummary {
            font-size: 0.65em;
          }
          &.dcThumbDate {
            font-size: 0.5em;
            color: rgba(255, 255, 255, 0.5);
            text-align: right;
          }
        }
      }
    }
  }
  .dcMoreAction {
    text-align: right;
    font-size: 0.65em;
    a {
      color: white;
      padding: 0.25em 0.5em;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 1em;
    }
    margin: 1em 0;
  }
  .dcTextItems {
    color: white;
    font-size: 0.75em;
    > li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      .dcTextTitle {
      }
    }
  }
`;

const DcontentIndexMobile = () => {
  const EP = process.env.REACT_APP_AWS_ENDPOINT;
  const navi = useNavigate();
  const { data: eData, isLoading: eLoading } = useQuery(
    ["emptytop5"],
    async () => await NormalReqApi("get", "/emptytime/top5"),
    { retry: true }
  );
  const { data: wData, isLoading: wLoading } = useQuery(
    ["weeklytop10"],
    async () => await NormalReqApi("get", "/weekly/top10"),
    { retry: true }
  );
  const { data: dData, isLoading: dLoading } = useQuery(
    ["depicPrev"],
    async () => await NormalReqApi("get", "/depic/top5"),
    { retry: true }
  );

  const { data: eventData, isLoading: eventLoading } = useQuery(
    ["eventPrev"],
    async () => await NormalReqApi("get", "/event/top4"),
    { retry: true }
  );
  console.log(wData);
  return (
    <Wrap>
      <div className={"dcMainTitle"}>
        <p className={"dcTitle"}>대티즌 콘텐츠</p>
      </div>

      <Section>
        <label className={"dcSubTitle"}>
          <span>
            공강시간
            <br />
            <small>대티즌이 전하는 알아두면 도움이 되는 간추린 뉴스</small>
          </span>
          <span className={"dcMore"} onClick={() => navi("/emptytime")}>
            <HiDotsHorizontal />
          </span>
        </label>
        <ul className={"dcThumbnail"}>
          {eLoading ? (
            <li>
              <img src={Default} />
            </li>
          ) : (
            eData.data.map((item, index) => (
              <li
                key={`prev_empty_${index}`}
                className={"dcThumbInfo"}
                onClick={() => navi(`/emptytime/${item._id}`)}
              >
                <img src={EP + "w_280/" + item.thumbnail.path} />
                <p className={"dcThumbTitle"}>{item.title}</p>
                <p className={"dcThumbSummary"}>{item.des}</p>
                <p className={"dcThumbDate"}>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </p>
              </li>
            ))
          )}
        </ul>
      </Section>
      <Section>
        <label className={"dcSubTitle"}>
          <span>
            위클리
            <br />
            <small>매주마다 위클리 플래너로 정리하세요!</small>
          </span>
          <span className={"dcMore"} onClick={() => navi("/weekly")}>
            <HiDotsHorizontal />
          </span>
        </label>
        <ul className={"dcTextItems"}>
          {wLoading ? (
            <li></li>
          ) : (
            wData.data.result.map((item, index) => (
              <li
                key={`prev_weekly_${index}`}
                className={"dcTextInfo"}
                onClick={() => navi(`/weekly/${item._id}`)}
              >
                <span className={"dcTextTitle"}>{item.title}</span>
              </li>
            ))
          )}
        </ul>
      </Section>
      <Section>
        <label className={"dcSubTitle"}>
          <span>
            대픽영상
            <br />
            <small>대티즌이 선택한 꿀팁 영상을 확인하세요!</small>
          </span>
          <span className={"dcMore"} onClick={() => navi("/depic")}>
            <HiDotsHorizontal />
          </span>
        </label>
        <ul className={"dcThumbnail"}>
          {dLoading ? (
            <li>
              <img src={Default} />
            </li>
          ) : (
            dData.data.map((item, index) => (
              <li
                key={`prev_depic_${index}`}
                className={"dcThumbInfo"}
                onClick={() => navi(`/depic/${item._id}`)}
              >
                <img src={EP + "w_280/" + item.thumbnail.path} />
                <p className={"dcThumbSummary"}>{item.title}</p>
                <p className={"dcThumbTitle"}>{item.des}</p>
                <p className={"dcThumbDate"}>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </p>
              </li>
            ))
          )}
        </ul>
      </Section>
      {/* <Section>
        <label className={"dcSubTitle"}>
          <span>
            이벤트
            <br />
            <small>대티즌에서 진행중인 다양한 이벤트를 확인하세요!</small>
          </span>
          <span className={"dcMore"} onClick={() => navi("/event")}>
            <HiDotsHorizontal />
          </span>
        </label>
        <ul className={"dcThumbnail"}>
          {eventLoading ? (
            <li>
              <img src={Default} />
            </li>
          ) : (
            eventData.data.map((item, index) => (
              <li
                key={`prev_depic_${index}`}
                className={"dcThumbInfo"}
                onClick={() => navi(`/event/${item._id}`)}
              >
                <img src={EP + "w_280/" + item.thumbnail.path} />
                <p className={"dcThumbTitle"}>{item.title}</p>
                <p className={"dcThumbDate"}>
                  {`~ ` + moment(item.endDate).format("YYYY-MM-DD")}
                </p>
              </li>
            ))
          )}
        </ul>
        <div className="dcMoreAction">
          <Link to="/eventresult">이벤트 당첨 확인하기</Link>
        </div>
      </Section> */}
    </Wrap>
  );
};

export default DcontentIndexMobile;
