import React from "react";
import { useRecoilState } from "recoil";
import { accountSelect } from "../recoil/AtomConfig";
import { NormalReqApi } from "./ReqApi";

export const LoadCommonInfo = async () => {
  const result = await NormalReqApi("get", "basic");

  return result;
};
