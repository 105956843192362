import React, { Suspense, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { Loading } from "../../../components/DContents/Mobile/MTemplate";
import MyChallenge from "../../../components/User/common/MyChallenge";
import MyFreeContest from "../../../components/User/common/MyFreeContest";
import {
  ContentItems,
  ContentsMenu,
} from "../../../components/User/UserTemplate";
import { accountSelect } from "../../../recoil/AtomConfig";
import { ClientReqGet } from "../../../utils/ClientAuth";

const MyContents = () => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const changeATK = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const [tab, setTab] = useState(1);

  const { data, error, isLoading, refetch } = useQuery(
    ["chgmy", account, changeATK],
    async () =>
      await ClientReqGet(
        `/chg/my`,
        account.accessToken,
        account.refreshToken,
        changeATK
      ),
    { retry: false }
  );

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
      </div>
      <div className={`sub_container`}>
        <div className={`contents mypage`}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>내가 쓴 게시물</span>
          </h2>

          <div className={"myInfo_area"}>
            <ContentsMenu index={tab} changeTab={setTab} />
            <ContentItems>
              {tab !== 0 && !isLoading ? (
                tab === 1 ? (
                  data.data.team.length > 0 ? (
                    data.data.team.map((item, index) => (
                      <MyChallenge
                        key={`my_c_t_${item._id}_${index}`}
                        link={`/teamplay/${item._id}`}
                        {...item}
                      />
                    ))
                  ) : (
                    <li className={"noCiContent"}>등록된 게시물이 없습니다</li>
                  )
                ) : tab === 2 ? (
                  data.data.after.length > 0 ? (
                    data.data.after.map((item, index) => (
                      <MyChallenge
                        key={`my_c_a_${item._id}_${index}`}
                        link={`/after/${item._id}`}
                        {...item}
                      />
                    ))
                  ) : (
                    <li className={"noCiContent"}>등록된 게시물이 없습니다</li>
                  )
                ) : tab === 3 ? (
                  data.data.free.length > 0 ? (
                    data.data.free.map((item, index) => (
                      <MyChallenge
                        key={`my_c_f_${item._id}_${index}`}
                        link={`/freeboard/${item._id}`}
                        {...item}
                      />
                    ))
                  ) : (
                    <li className={"noCiContent"}>등록된 게시물이 없습니다</li>
                  )
                ) : null
              ) : (
                <div
                  style={{
                    padding: "2em",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              )}
            </ContentItems>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyContents;
