import moment from "moment";
import React from "react";
import { HiEye } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import loadStyle from "../../../assets/css/loader.module.scss";

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;
  .dcMainTitle {
    text-align: center;
    .dcTitle {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;

export const DcView = styled.div`
  padding: 0 0.5em;

  .dcViewTop {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 0 0.5em 0;
    margin-bottom: 0.5em;
    color: white;

    .dcViewTitle {
      font-size: 0.75em;
    }
    .dcViewSubInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.55em;
    }

    .dcViewSubInfoAddition {
      padding-top: 0.5em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.55em;

      .subcate {
        padding: 0.25em 0.5em;
        margin-right: 0.25em;
        &:last-child {
          margin-right: 0;
        }
        &.reward {
          background-color: green;
        }
        &.target {
          background-color: orange;
        }
      }
    }
  }

  .dcViewEditor {
    background-color: white;
    .dcViewContents {
      padding: unset !important;
      iframe {
        width: 100% !important;
      }
    }
  }
  .dcViewBtnArea {
    margin-bottom: 5em;
    > button {
      font-size: 0.75em;
      padding: 0.25em 0.5em;
      border-radius: 5px;
      width: 100%;
      border: none;
      color: white;
      background-color: black;
    }
  }
`;

export const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0.5em 0.5em 0.5em;

  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5em;
  .dcSearchTitle {
    font-size: 0.75em;
    color: white;
    width: 100%;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
`;

const Item = styled.li`
  color: white;
  font-size: 0.65em;
  padding: 0 1em 1em 1em;
  margin: 0 0 1em 0;
  .customImgArea {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customTitle {
    padding: 0.25em 0;
  }
  .customDes {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
    padding: 0.25em 0;
  }
  .customCntAndDate {
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={loadStyle.loading_spinner}></div>
    </div>
  );
};

export const ViewHeader = ({ label, children }) => {
  return (
    <Wrap>
      <div className={"dcMainTitle"}>
        <p className={"dcTitle"}>{label}</p>
      </div>
      {children}
    </Wrap>
  );
};

export const ThumbnailList = ({
  src,

  url,
  title,
  des,
  viewCnt,
  createdAt,
}) => {
  const navi = useNavigate();
  const handleMove = () => {
    navi(url);
  };
  return (
    <Item onClick={handleMove}>
      <div className={"customImgArea"}>
        <img src={src} />
      </div>

      <p className={"customTitle"}>{title}</p>
      <p className={"customDes"}>{des}</p>
      <div className={"customCntAndDate"}>
        <span>
          <HiEye style={{ marginRight: ".25em" }} />
          {viewCnt}
        </span>
        <span>{moment(createdAt).format("YYYY-MM-DD")}</span>
      </div>
    </Item>
  );
};
