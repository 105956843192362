import React, { useRef } from "react";
import { ClientReqPatch } from "../../../utils/ClientAuth";

const passwordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const ChangePwd = ({ account, atkChange, close }) => {
  const pwdRef = useRef(null);
  const pwdChangeRef = useRef(null);
  const pwdChangeConfirmRef = useRef(null);

  const comparePwd = (origin, newValue, confirmValue) => {
    if (!origin.current || origin.current.value.length === 0) {
      alert("빈칸을 모두 입력해주시기 바랍니다.");
      return;
    }
    if (!newValue.current || newValue.current.value.length === 0) {
      alert("새 비밀번호를 입력해주시기 바랍니다.");
      return;
    }
    if (!confirmValue.current || confirmValue.current.value.length === 0) {
      alert("새 비밀번호가 일치하지 않습니다.");
      return;
    }
    if (!passwordRegExp.test(newValue.current.value)) {
      alert(
        "특수문자,영문 대소문자, 숫자를 포함한 비밀번호를 8자리 이상 입력해주시기 바랍니다"
      );
      return false;
    }
    return newValue.current.value;
  };

  const onSubmit = async () => {
    const newPwd = comparePwd(pwdRef, pwdChangeRef, pwdChangeConfirmRef);
    if (!newPwd) return;
    const changeResult = await ClientReqPatch(
      `/user/pwd`,
      { password: newPwd },
      account.accessToken,
      account.refreshToken,
      atkChange
    );
    if (changeResult.flag) {
      alert("성공적으로 변경되었습니다.");
      close();
    } else {
      alert(
        "비밀번호 변경중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
      );
    }
  };

  return (
    <>
      <div className={"slideInputArea"}>
        <div className={"slidePwd"}>
          <label htmlFor={"originPwd"}>비밀번호 입력</label>
          <input type="password" id="originPwd" ref={pwdRef} />
        </div>
        <div className={"slidePwd confirm"}>
          <label htmlFor={"changePwd"}>새로운 비밀번호 입력</label>
          <input type="password" id={"changePwd"} ref={pwdChangeRef} />
        </div>
        <div className={"slidePwd confirm"}>
          <label htmlFor={"changePwdConfirm"}>새로운 비밀번호 확인</label>
          <input
            type="password"
            id={"changePwdConfirm"}
            ref={pwdChangeConfirmRef}
          />
        </div>
      </div>
      <div className={"slideInputBtns"}>
        <button type="button" className={"slideInputSubmit"} onClick={onSubmit}>
          <span>변경</span>
        </button>
      </div>
    </>
  );
};

export default ChangePwd;
