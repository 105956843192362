import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  HiChevronLeft,
  HiDotsHorizontal,
  HiPaperAirplane,
  HiThumbDown,
  HiThumbUp,
  HiX,
} from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { accountSelect } from "../../recoil/AtomConfig";
import {
  ClientDelReq,
  ClientReqGet,
  ClientReqPatch,
  ClientReqPost,
} from "../../utils/ClientAuth";

const ReplyPop = styled.div`
  position: fixed;
  bottom: ${(props) => (props.open ? 0 : `-2000px`)};
  width: 100%;
  z-index: 1111112;
  transition: all 0.3s ease-in-out;
  .replyPopBg {
    position: fixed;
    top: 0;
    width: 100%;
    height: ${(props) => (props.open ? `100%` : 0)};
    z-index: 1111114;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .replyWrap {
    position: relative;
    z-index: 1111115;
    width: 100%;
    height: 20vh;
    .replyBody {
      height: 100%;
      width: 100%;
      background-color: white;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      padding: 1.2em 0.5em;
      > ul {
        display: block;
        > li {
          font-size: 0.75em;
          display: block;
          padding: 0.25em 0;
          border-bottom: 1px solid #efefef;
        }
      }
    }
  }
`;

const ReplyWrap = styled.div`
  z-index: 111111;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 0.25em;
  background-color: #2b2e3b;
  .cr_header {
    padding: 0.5em 0.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 0.75em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .cr_reply_area {
    padding: 0.25em;
    font-size: 0.75em;
    .cr_reply_author_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .cr_reply_author {
      color: gray;
      font-size: 0.55em;
      padding: 0.25em 0;
      margin: 0.25em 0;
    }
    .cr_reply_text_edit {
      background-color: rgba(0, 0, 0, 0.5);
      > textarea {
        resize: none;
        padding: 0.25em;
        font-size: 0.85em;
        width: 100%;
        background-color: transparent;
        color: white;
        border: none;
        &:focus {
          outline: none;
        }
      }
      .cr_reply_text_sendBtn {
        width: 95%;
        text-align: right;
        .cr_reply_text_sendBtnIcon {
          color: white;
          transform: rotateZ(90deg);
        }
      }
    }
    .cr_reply_text {
      color: white;
      font-size: 0.75em;
      font-weight: 300;
    }

    .cr_etc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75em;
      color: gray;
      border-bottom: 1px solid gray;
      .cr_btns {
        padding: 0.5em 0;
        margin: 0.5em 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .cr_thumb {
          padding-right: 0.5em;
          margin-right: 0.5em;
          display: flex;
          justify-content: center;
          align-items: center;
          > span {
            margin-left: 0.5em;
          }
        }
      }
    }
  }
  .cr_subReply {
    > ul {
      padding-left: 1em;
    }

    .cr_subReply_input {
      padding: 0 0.25em;
      /* margin: 0 0.25em; */
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      > textarea {
        width: 100%;
        resize: none;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 0.55em;
        font-weight: 300;
        padding: 1em 0.25em;
        &:focus {
          outline: none;
        }
      }
      .cr_subReply_submit {
        transform: rotateZ(90deg);
        color: white;
      }
    }
    .cr_etc {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`;

const MobileSubReplyItem = ({
  rootId,
  id,
  writer,
  text,
  createdAt,
  recommend,
  unRecommend,
  account,
  toggle,
  status,
  isOwn,
  setPop,
  pop,
  closePop,
}) => {
  const [cnt, setCnt] = useState({
    rec: 0,
    unRec: 0,
  });
  useEffect(() => {
    setCnt({
      rec: recommend,
      unRec: unRecommend,
    });
  }, []);

  const handlePopOpen = () => {
    setPop("sub", rootId, id);
  };

  const handleRecommend = async () => {
    const result = await ClientReqPatch(
      `/reply/changecnt/sub/recommend?id=${rootId}&curId=${id}`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag && result.data === "inc") {
      setCnt({ ...cnt, rec: cnt.rec + 1 });
    } else if (result.flag && result.data === "dec") {
      setCnt({ ...cnt, rec: cnt.rec - 1 });
    }
  };
  const handleUnRecommend = async () => {
    const result = await ClientReqPatch(
      `/reply/changecnt/sub/unRecommend?id=${rootId}&curId=${id}`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag && result.data === "inc") {
      setCnt({ ...cnt, unRec: cnt.unRec + 1 });
    } else if (result.flag && result.data === "dec") {
      setCnt({ ...cnt, unRec: cnt.unRec - 1 });
    }
  };

  return (
    <li>
      <div className={"cr_reply_area"}>
        <div className={"cr_reply_author_btn"}>
          <p className={"cr_reply_author"}>{writer.nickName}</p>
          {isOwn === 1 ? (
            pop.active && pop.rootId === rootId && pop.subId === id ? (
              <HiX color="white" onClick={closePop} />
            ) : status === 1 ? (
              ""
            ) : (
              <HiDotsHorizontal color={"white"} onClick={handlePopOpen} />
            )
          ) : (
            ""
          )}
        </div>
        {pop.active && pop.rootId === rootId && pop.subId === id ? (
          <div className={"cr_reply_text_edit"}>
            <textarea defaultValue={text}></textarea>
            <div className={"cr_reply_text_sendBtn"}>
              <HiPaperAirplane className={"cr_reply_text_sendBtnIcon"} />
            </div>
          </div>
        ) : (
          <p className={"cr_reply_text"}>{text}</p>
        )}

        <div className={"cr_etc"}>
          <div className={"cr_btns"}>
            {status !== 1 && (
              <>
                <div className={"cr_thumb"}>
                  <HiThumbUp color={"white"} onClick={handleRecommend} />
                  <span>{cnt.rec}</span>
                </div>
                <div className={"cr_thumb"}>
                  <HiThumbDown color={"white"} onClick={handleUnRecommend} />
                  <span>{cnt.unRec}</span>
                </div>
              </>
            )}
          </div>
          <div className={"cr_date"}>
            <span>{moment(createdAt).format("YYYY-MM-DD")}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

const MobileSubReply = ({
  id,
  subReply,
  account,
  toggle,
  setPop,
  pop,
  closePop,
}) => {
  return (
    <ul>
      {subReply.length > 0 ? (
        subReply.map((item, index) => (
          <MobileSubReplyItem
            key={`subReply_${id}_${index}`}
            account={account}
            rootId={id}
            toggle={toggle}
            setPop={setPop}
            pop={pop}
            closePop={closePop}
            {...item.subReply}
          />
        ))
      ) : (
        <li
          className={"cr_subReply_nocontent"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.75em",

            color: "white",
          }}
        >
          등록된 댓글이 없습니다
        </li>
      )}
    </ul>
  );
};

const MobileReplyPage = () => {
  const { id, type } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const [cnt, setCnt] = useState({
    rec: 0,
    unRec: 0,
  });
  const [popOpen, setPopOpen] = useState({
    open: false,
    type: "root", // root or sub (change by reply type)
    rootId: "",
    subId: "",
    active: false,
  });
  const { data, error, isLoading, refetch } = useQuery(
    ["reply", id, type],
    async () =>
      await ClientReqGet(
        `/reply/view/${id}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { suspense: false, retry: false }
  );
  useEffect(() => {
    if (!isLoading) {
      setCnt({
        rec: data.data.result.recommend,
        unRec: data.data.result.unRecommend,
      });
    }
  }, [data]);

  const textRef = useRef(null);
  const submitSubReply = async () => {
    const text = textRef.current.value;

    if (!text || text.length < 5) {
      alert("최소 6글자 이상 입력해주시기 바랍니다");
      return false;
    }
    const template = { text };
    const result = await ClientReqPost(
      `/reply/sub?id=${id}&type=${type}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      refetch();
      textRef.current.value = "";
    }
  };

  const handleRootThumbUp = async () => {
    const result = await ClientReqPatch(
      `/reply/changecnt/recommend?id=${id}`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag && result.data === "inc") {
      setCnt({
        ...cnt,
        rec: cnt.rec + 1,
      });
    } else if (result.flag && result.data === "dec") {
      setCnt({
        ...cnt,
        rec: cnt.rec - 1,
      });
    }
  };
  const handleRootThumbDown = async () => {
    const result = await ClientReqPatch(
      `/reply/changecnt/unRecommend?id=${id}`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag && result.data === "inc") {
      setCnt({
        ...cnt,
        unRec: cnt.unRec + 1,
      });
    } else if (result.flag && result.data === "dec") {
      setCnt({
        ...cnt,
        unRec: cnt.unRec - 1,
      });
    }
  };

  const handlePop = (type, rootId, subId) => {
    setPopOpen({
      open: true,
      type: type,
      rootId: rootId,
      subId: subId,
      active: false,
    });
  };

  const closePop = () => {
    setPopOpen({
      open: false,
      type: "root",
      rootId: "",
      subId: "",
      active: false,
    });
  };

  const handleEditClick = () => {
    setPopOpen({
      ...popOpen,
      open: false,
      active: true,
    });
  };

  const handleEditReply = async () => {
    console.log(popOpen);
  };

  const isRoot = (rootId, subId) => {
    return rootId && !subId;
  };
  const handleDelReply = async () => {
    console.log(popOpen);
    const isRootReply = isRoot(popOpen.rootId, popOpen.subId);
    if (isRootReply) {
      // root reply del req
    } else {
      // sub reply del req
      const result = await ClientDelReq(
        `/reply/sub?id=${popOpen.rootId}&curId=${popOpen.subId}`,
        account.accessToken,
        account.refreshToken,
        toggle
      );
      if (result.flag) {
        refetch();
      }
    }
  };

  //   const { data, error, isLoading } = useQuery(["replies",id,type],)
  //   async() => await ClientReqGet(`/reply?id=${id}&type=${type}&page=1`));

  return (
    <>
      <ReplyWrap>
        <div className={"cr_header"}>
          <HiChevronLeft color={"white"} size={30} onClick={() => navi(-1)} />
          <span>댓글</span>
          <HiChevronLeft color={"transparent"} size={30} />
        </div>
        <div className={"cr_content"}>
          {isLoading ? (
            <div className={"cr_loading"}>
              <Loading />
            </div>
          ) : (
            <>
              {
                <>
                  <div className={"cr_reply_area"}>
                    <div className={"cr_reply_author_btn"}>
                      <p className={"cr_reply_author"}>
                        {data.data.result.author.nickName}
                      </p>
                      {data.data.isOwn && (
                        <HiDotsHorizontal
                          color={"white"}
                          onClick={() => setPopOpen(true)}
                        />
                      )}
                    </div>

                    <p className={"cr_reply_text"}>{data.data.result.text}</p>
                    <div className={"cr_etc"}>
                      <div className={"cr_btns"}>
                        <div className={"cr_thumb"}>
                          <HiThumbUp
                            color={"white"}
                            onClick={handleRootThumbUp}
                          />
                          <span>{cnt.rec}</span>
                        </div>
                        <div className={"cr_thumb"}>
                          <HiThumbDown
                            color={"white"}
                            onClick={handleRootThumbDown}
                          />
                          <span>{cnt.unRec}</span>
                        </div>
                      </div>
                      <div className={"cr_date"}>
                        <span>
                          {moment(data.data.result.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={"cr_subReply sub"}>
                    <div className={"cr_subReply_input"}>
                      <textarea ref={textRef}></textarea>
                      <HiPaperAirplane
                        className={"cr_subReply_submit"}
                        size={16}
                        onClick={submitSubReply}
                      />
                    </div>
                    {!isLoading && (
                      <MobileSubReply
                        id={id}
                        account={account}
                        toggle={toggle}
                        subReply={data.data.subResult}
                        setPop={handlePop}
                        pop={popOpen}
                        closePop={closePop}
                      />
                    )}
                  </div>
                </>
              }
            </>
          )}
        </div>
      </ReplyWrap>
      <ReplyPop open={popOpen.open}>
        <div className={"replyPopBg"} onClick={closePop}></div>
        <div className={"replyWrap"}>
          <div className={"replyBody"}>
            <ul>
              <li onClick={handleEditClick}>수정</li>
              <li onClick={handleDelReply}>삭제</li>
            </ul>
          </div>
        </div>
      </ReplyPop>
    </>
  );
};

export default MobileReplyPage;
