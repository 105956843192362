import React, { useEffect } from "react";
import chStyle from "../../assets/css/header.module.scss";
import { useNavigate } from "react-router-dom";
import MainSection1 from "../../components/MainSlider/Section1";
import MainSection2 from "../../components/MainSlider/Section2";
import MainSection3 from "../../components/MainSlider/Section3";
import MainSection4 from "../../components/MainSlider/Section4";
import MainSection5 from "../../components/MainSlider/Section5";
import MainFloating from "../../components/MainSlider/Floating";
import { useRecoilState } from "recoil";
import { bgColorSelect } from "../../recoil/AtomConfig";

const MainIndex = () => {
  const [bg, setBg] = useRecoilState(bgColorSelect);
  const changeBg = () => {
    setBg((prev) => {
      const temp = { ...prev };
      temp.change = false;
      return { ...temp };
    });
  };

  useEffect(() => {
    return () => {
      changeBg();
    };
  }, []);

  return (
    <div className={`container main ${chStyle.mediaContainer}`}>
      <MainSection1
        className={`section1 ${chStyle.section1}`}
        subClassName={`listArea ${chStyle.listArea}`}
        detailClassName={`detailList ${chStyle.detailList}`}
        thumbClassName={`thumbList ${chStyle.thumbList}`}
      />
      <MainSection2
        className={`section2 ${chStyle.section2}`}
        subClassName={`listArea ${chStyle.listArea}`}
        detailClassName={`detailList ${chStyle.detailList}`}
        thumbClassName={`thumbList ${chStyle.thumbList}`}
      />
      <MainSection3 className={"section3"} />
      <MainSection4 className={`section4 new ${chStyle.section4}`} />
      <MainSection5 />
      <MainFloating />
    </div>
  );
};

export default MainIndex;
