import React from "react";
import pStyle from "../assets/css/paging.module.scss";
import Prev from "../assets/img/ico_prev.png";
import Next from "../assets/img/ico_next.png";
import First from "../assets/img/ico_first.png";
import Last from "../assets/img/ico_last.png";

const PagingWrap = ({
  firstPage,
  lastPage,
  prevBlock,
  nextBlock,
  handlePage,
  children,
}) => {
  return (
    <div className={pStyle.paging}>
      <a onClick={() => handlePage(firstPage)}>
        <img src={First} />
      </a>
      <a onClick={() => handlePage(prevBlock)}>
        <img src={Prev} />
      </a>
      {children}
      <a onClick={() => handlePage(nextBlock)}>
        <img src={Next} />
      </a>
      <a onClick={() => handlePage(lastPage)}>
        <img src={Last} />
      </a>
    </div>
  );
};

// total : 2
// totalPage : 1 (2 / 10 => 0.5 => ceil => 1)
// totalBlock :

// total : 12
// totalPage : 2 (12 /10 => 1.2 => ceil => 2)
// totalBlock : 1 ( 2 / 10 => 0.5 => ceil => 1)

const Pagination = ({ total, curPage, handlePage, scale }) => {
  const SCALE = scale ? scale * 1 : 10;
  const BLOCK_SCALE = 10;
  const totalPage = Math.ceil(total / SCALE);
  const totalBlock = Math.ceil(totalPage / BLOCK_SCALE);
  const curBlock = Math.ceil(curPage / BLOCK_SCALE);

  const firstPage = 1;
  const lastPage = totalPage;

  const prevBlock =
    (curBlock - 1) * BLOCK_SCALE - 9 > 0
      ? (curBlock - 1) * BLOCK_SCALE - 9
      : firstPage;

  const nextBlock =
    curBlock * BLOCK_SCALE + 1 > totalPage
      ? totalPage
      : curBlock * BLOCK_SCALE + 1;

  const curFirstPage = (curBlock - 1) * BLOCK_SCALE + 1;
  const curLastPage =
    curBlock * BLOCK_SCALE > totalPage ? totalPage : curBlock * BLOCK_SCALE;

  const renderPage = (fPage, ePage) => {
    let resultElement = [];
    for (let i = fPage; i <= ePage; i++) {
      resultElement.push(
        <a
          key={`page_element_${i}`}
          id={i}
          onClick={() => handlePage(`${i}`)}
          className={i === curPage ? "active" : null}
        >
          {i}
        </a>
      );
    }

    return resultElement;
  };

  return (
    <PagingWrap
      firstPage={firstPage}
      lastPage={lastPage}
      prevBlock={prevBlock}
      nextBlock={nextBlock}
      handlePage={handlePage}
    >
      {renderPage(curFirstPage, curLastPage)}
    </PagingWrap>
  );
};

export default Pagination;
