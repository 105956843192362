import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  position: fixed;
  bottom: ${(props) => (props.open ? 0 : `-2000px`)};
  width: 100%;

  z-index: 11111111;
  transition: all 0.3s ease-in-out;

  .slideBg {
    position: fixed;
    top: 0;
    width: 100%;
    height: ${(props) => (props.open ? `100%` : 0)};
    z-index: 11111112;
    background: rgba(0, 0, 0, 0.5);
  }
  .slideBody {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 11111113;
    background-color: white;

    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    .slideItems {
      padding: 0.5em 0.5em;
      .slideLabel {
        font-size: 0.75em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1em;
      }
      .slideInputArea {
        font-size: 0.55em;
        .slidePwd {
          padding: 0.25em 0;
          margin: 0.25em 0;
          > input {
            font-size: 1em;
            width: 100%;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);

            &:focus {
              outline: none;
              border-bottom: 1px solid #2b303b;
            }
          }
          .slideEmailYn {
            display: block;
            padding: 0.25em 0;
            margin: 0.25em 0;
            font-size: 1.25em;
            > label {
              margin-right: 0.25em;
            }
          }
          .slideWarning {
            color: red;
            padding-bottom: 0.25em;
          }
        }
      }
      .slideInputBtns {
        width: 100%;
        padding-bottom: 2em;
        > button {
          width: 100%;
          border: none;
          background-color: #2b303b;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          > span {
            padding: 0.25em 0;
            font-size: 0.75em;
            color: white;
          }
        }
      }
    }
  }
`;

const SlideUpFromDown = ({ open, close, children }) => {
  return (
    <Wrap open={open}>
      <div className={"slideBg"} onClick={close}></div>
      <div className={"slideBody"}>
        <div className={"slideItems"}>{children}</div>
      </div>
    </Wrap>
  );
};

export default SlideUpFromDown;
