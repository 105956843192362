import React from "react";
import { Link } from "react-router-dom";
import promoStyle from "../../../assets/css/promo.module.scss";

const PromotionSection1 = () => {
  return (
    <div className={`sub_container white ${promoStyle.sub_container}`}>
      <div
        className={`btn_area free_reg ${promoStyle.btn_area} ${promoStyle.free_reg}`}
      >
        <Link to={`/agentReq`} className={"link_btn"}>
          광고 서비스문의
        </Link>

        <a
          href={
            "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/newTizenProduct.pdf"
          }
          target={"_blank"}
          className="link_btn"
        >
          광고 상품 단가표
        </a>
      </div>
    </div>
  );
};

export default PromotionSection1;
