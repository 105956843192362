import React from "react";
import PromoStyle from "../../assets/css/promo.module.scss";
import FreeStyle from "../../assets/css/request.module.css";

export const PromotionSubVisual = () => {
  return (
    <div className={`subVisual ${PromoStyle.subVisual}`}>
      <span className="sv_title">
        광고상품
        <small className="sv_description">
          대티즌이 제공하는 최고의 광고효과를 경험하세요!
        </small>
      </span>
    </div>
  );
};

export const AgentSubVisual = () => {
  return (
    <div className={`subVisual ${PromoStyle.subVisual} ${PromoStyle.agent}`}>
      <span className="sv_title">
        공모전 &amp; 서포터즈는 대티즌이 전문가 입니다.
        <small className="sv_description">
          귀사의 성공적인 공모전을 위한 다양한 서비스를 이용하세요
        </small>
      </span>
    </div>
  );
};

export const FreeContestSubVisual = () => {
  return (
    <div className={`subVisual ${FreeStyle.subVisual}`}>
      <span className="sv_title">
        공모전·대외활동 등록
        <small className="sv_description">
          공모전, 대외활동 등록 및 관리 서비스를 편리하게 이용하세요
        </small>
        <small className="sv_guide">
          * 공모전/대외활동 등록은 24시간 이내에 게재됩니다.(주말/공휴일 제외)
        </small>
        <small className="sv_guide">
          * 게시글 수정·삭제 요청은 마이페이지 하단의 무료등록 > 해당 게시물
          선택 > 수정요청에서 작성해주세요.
        </small>
      </span>
    </div>
  );
};

export const FreeEventSubVisual = () => {
  return (
    <div className={"subVisual"}>
      <span className="sv_title">
        대티즌 무료 이벤트 등록
        <small className={"sv_description"}>
          주최하고자 하는 이벤트 정보를 작성하여 진행해주시면 됩니다.
        </small>
      </span>
    </div>
  );
};
