import moment from "moment";
import React from "react";
import { HiCalendar, HiUser } from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { accountSelect } from "../../recoil/AtomConfig";
import { MyScrapList } from "../../styles/user/myscrapStyle";
import { ClientReqGet } from "../../utils/ClientAuth";

const EP = process.env.REACT_APP_AWS_ENDPOINT;

const MyScrap = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const changeToken = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const moveDetail = (id, type) => {
    navi(`/contest/view/${type}/${id}`);
  };

  const { data, error, isLoading } = useQuery(
    ["myscrapall", account, changeToken],
    async () =>
      await ClientReqGet(
        `/user/${account.id}/scrap/all`,
        account.accessToken,
        account.refreshToken,
        changeToken
      ),
    { retry: false }
  );

  return (
    <div className={"container sub"}>
      <div className={"subVisual mypage"}>
        <span className={"sv_title"}>마이페이지</span>
      </div>
      <div className={"sub_container"}>
        <div className={"contents mypage"}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "80%" }}>내 스크랩</span>
            <small style={{ marginBottom: 0 }}>
              (총 {isLoading ? 0 : data.data.res.scrap.length} 건)
            </small>
          </h2>
          <div className={"myInfo_area"}>
            {isLoading ? (
              <Loading />
            ) : (
              <MyScrapList>
                {data.data.res.scrap.length > 0 ? (
                  data.data.res.scrap.map((item, index) => (
                    <li
                      key={`myScrap_${account.id}_${item._id}_${index}`}
                      onClick={() => moveDetail(item._id, item.type)}
                    >
                      <div className={"imgArea"}>
                        <img src={EP + "w_150/" + item.thumbnail.path} />
                      </div>
                      <div className={"contentArea"}>
                        <div>
                          <p className={"contentTitle"}>{item.title}</p>
                          <p className={"contentHost"}>{item.host?.name}</p>
                        </div>
                        <div>
                          <p className={"contentTarget"}>
                            <HiUser className="contentIcon" />
                            {item.target[0].text}
                          </p>
                          <p className={"contentDate"}>
                            <HiCalendar className={"contentIcon"} />
                            {moment(item.startDate).format("YYYY-MM-DD")} ~{" "}
                            {moment(item.endDate).format("YYYY-MM-DD")}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className={"contentEmpty"}>
                    스크랩 데이터가 존재하지 않습니다.
                  </li>
                )}
              </MyScrapList>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyScrap;
