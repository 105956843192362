export const TERMS_DATA = [
  {
    type: "normal",
    id: 1,
    label: "개인회원 이용약관 동의",
    term: `<div class="section customModify">
							<h3 class="first">
								<a name="a1">제 1 조 (목적)</a>
							</h3>
							<p>
							이 약관은 전기통신 사업법 및 동 법 시행령에 의하여 (주)대티즌(이하 "회사" 라 합니다.)이 제공하는 인터넷 홈페이지 서비스 (이하 "서비스" 라 합니다.)의 이용조건 및 절차에 관한 사항, 회사와 이용자의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.
							</p>
							<h3>
								<a name="a2">제 2조 (약관의 효력과 개정)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>이 약관은 전기통신사업법 제 31 조, 동 법 시행규칙 제 21조의 2에 따라 공시절차를 거친 후 홈페이지를 통하여 이를 공지하거나 전자우편 기타의 방법으로 이용자에게 통지함으로써 효력을 발생합니다.
								</li>
								<li>
									<span class="first_letter">2.</span>회사는 본 약관을 사전 고지 없이 개정할 수 있으며, 개정된 약관은 제9조에 정한 방법으로 공지합니다. 회원은 개정된 약관에 동의하지 아니하는 경우 본인의 회원등록을 취소(회원탈퇴)할 수 있으며, 계속 사용의 경우는 약관 개정에 대한 동의로 간주됩니다. 개정된 약관은 공지와 동시에 그 효력이 발생됩니다.
									
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 3조 (약관이외의 준칙)</a>
							</h3>
							<ol>
								<li>
								이 약관에 명시되어 있지 않은 사항은 전기통신 기본법, 전기통신 사업법, 기타 관련법령의 규정에 따릅니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 4조 (용어의 정의)<small>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</small></a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회원 : 서비스에 개인정보를 제공하여 회원등록을 한 자로서, 서비스의 정보를 지속적으로 제공받으며, 이용할 수 있는 자를 말합니다.
								</li>
								<li>
									<span class="first_letter">2.</span>이용자 : 본 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
								</li>
								<li>
									<span class="first_letter">3.</span>회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.
									
								</li>
								<li>
								<span class="first_letter">4.</span>비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합을 말합니다.
								</li>
								<li>
								<span class="first_letter">5.</span>전자우편 (E-mail) : 인터넷을 통한 우편입니다.
								</li>
								<li>
								<span class="first_letter">6.</span>해지 : 회사 또는 회원이 서비스 이용 이후 그 이용계약을 종료 시키는 의사표시를 말합니다.
								</li>
								<li>
								<span class="first_letter">7.</span>홈페이지 : 회사가 이용자에게 서비스를 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 이용자가 열람 및 이용할 수 있도록 설정한 가상의 서비스 공간을 말합니다.
								</li>
							</ol>
							<h3>
								<a name="a2">제 5조 (서비스의 제공 및 변경)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사가 제공하는 서비스는 다음과 같습니다.
									<p>1) 회사에 대한 홍보 내용</p>
									<p>2) 회사가 판매하는 제품 안내</p>
									<p>3) 기타 회사가 제공하는 각종 정보</p>
									<p>4) 고객 상담 서비스</p>
									<p>5) 회원 이용 서비스</p>
									
								</li>
								<li>
									<span class="first_letter">2.</span>회사는 필요한 경우 서비스의 내용을 추가 또는 변경하여 제공할 수 있습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 6조 (서비스의 중단)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 컴퓨터 등 정보통신설비의 보수점검/교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">2.</span>제 1항에 의한 서비스 중단의 경우에는 제 9조에 정한 방법으로 이용자에게 통지합니다.
								</li>
								<li>
									<span class="first_letter">3.</span>회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상하지 아니합니다. 단, 회사에 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 7조 (회원가입)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>이용자는 회사가 정한 가입양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
								</li>
								<li>
									<span class="first_letter">2.</span>이용자는 반드시 실명으로 회원가입을 하여야 하며, 1개의 주민등록번호에 대해 1건의 회원가입신청을 할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">3.</span>회사는 제 1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
									<p>1) 이름이 실명이 아닌 경우</p>
									<p>2) 등록 내용에 허위, 기재누락, 오기가 있는 경우</p>
									<p>3) 타인의 명의를 사용하여 신청한 경우</p>
									<p>4) 가입신청자가 이 약관 제 8조 3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우(단, 제 8조 3항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우는 예외로 합니다.)</p>
									<p>5) 만 14세 미만의 아동</p>
									<p>6) 기타 회원으로 회사 소정의 이용신청요건을 충족하지 못하는 경우</p>
								</li>
								<li>
									<span class="first_letter">4.</span>회원가입계약의 성립시기는 회사의 승낙이 이용자에게 도달한 시점으로 합니다.
								</li>
								<li>
									<span class="first_letter">5.</span>회원은 제 10조 1항에 의한 등록사항에 변경이 있는 경우 회원정보변경 항목을 통해 직접 변경사항을 수정, 등록하여야 합니다.
								</li>
							</ol>
							<h3>
								<a name="a2">제 8조 (회원탈퇴 및 자격 상실 등)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회원은 언제든지 회원의 탈퇴를 홈페이지에 요청할 수 있으며, 홈페이지는 즉시 이에 응합니다.
									
								</li>
								<li>
								<span class="first_letter">2.</span>회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
								<p>1) 가입 신청 시에 허위 내용을 등록한 경우</p>
								<p>2) 타인의 서비스 이용을 방해하거나 그 정보를 도용하는 등 서비스 운영질서를 위협하는 경우</p>
								<p>3) 서비스를 이용하여 법령과 이 약관이 금지하거나, 공서양속에 반하는 행위를 하는 경우</p>
								<p>4) 제 13조 에 명기된 회원의 의무사항을 준수하지 못할 경우</p>
								</li>
								<li>
									<span class="first_letter">3.</span>회사가 회원자격을 제한/정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실 시킬 수 있습니다.
									
								</li>
								<li>
									<span class="first_letter">4.</span>회사가 회원자격을 상실 시키는 경우 회원에게 이를 통지하고 탈퇴를 처리합니다. 이 경우 회원에게 이를 통지하고, 탈퇴 전에 소명할 기회를 부여합니다.
									
								</li>
							</ol>
							<h3>
								<a name="a2">제 9조 (이용자에 대한 통지)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사가 이용자에 대한 통지를 하는 경우, 이용자가 서비스에 제출한 전자우편 주소로 할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">2.</span>회사가 불특정 다수 이용자에 대한 통지의 경우 1주일 이상 서비스 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.
								</li>
							</ol>
							<h3>
								<a name="a2">제 10조 (개인 정보 보호)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 이용자 정보 수집 시 회사측이 필요한 최소한의 정보를 수집합니다.
									<p>다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.</p>
									<p>1) 성명</p>
									<p>2) 주민등록번호</p>
									<p>3) 희망 ID</p>
									<p>4) 비밀번호</p>
									<p>5) E-mail</p>
									<p>6) 주소</p>
									<p>7) 전화번호</p>
								</li>
								<li>
									<span class="first_letter">2.</span>회사가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
									
								</li>
								<li>
									<span class="first_letter">3.</span>제공된 개인정보는 당해 이용자의 동의 없이 제 3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다.다만 다음의 경우에는 예외로 합니다.
									<p>1) 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보</p>
									<p>(성명, 주소, 전화번호)를 알려주는 경우</p>
									<p>2) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</p>
									<p>3) 관계법령에 의하여 국가기관으로부터 요구 받은 경우</p>
									<p>4) 범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의 요청이 있는 경우</p>
									<p>5) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</p>
								</li>
								<li>
									<span class="first_letter">4.</span>이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 할 수 있습니다.
									
								</li>
								<li>
									<span class="first_letter">5.</span>회사로부터 개인정보를 제공받은 제 3자는 개인정보를 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
									
								</li>
							</ol>
							<h3>
								<a name="a2">제 11조 (회사의 의무)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다하여야만 합니다.
								</li>
								<li>
									<span class="first_letter">2.</span>회사는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지/보수하고, 장애가 발생하는 경우 지체 없이 이를 수리/복구할 수 있도록 최선의 노력을 다하여야 합니다.
								</li>
								<li>
									<span class="first_letter">3.</span>회사는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안시스템을 갖추어야 합니다.
								</li>
								<li>
									<span class="first_letter">4.</span>회사는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 12조 (회원의 ID 및 비밀번호에 대한 의무)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회원에게 부여된 아이디(ID)와 비밀번호의 관리책임은 회원에게 있으며 관리 소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
								</li>
								<li>
									<span class="first_letter">2.</span>회원이 자신의 ID 및 비밀번호를 도난당하거나 제 3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 13조 (회원의 의무)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회원은 관계법령, 본 약관의 규정, 이용안내 및 주의사항 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
								</li>
								<li>
									<span class="first_letter">2.</span>회원은 회사의 사전승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.
								</li>
								<li>
									<span class="first_letter">3.</span>회원은 서비스를 이용하여 얻은 정보를 회사의 사전승낙 없이 복사, 복제, 변경, 번역, 출판/방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.
								</li>
								<li>
									<span class="first_letter">4.</span>회원은 자기 신상정보의 변경사항 발생 시 즉각 변경하여야 합니다.
									<p>회원정보를 수정하지 않아 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.</p>
								</li>
								<li>
									<span class="first_letter">5.</span>회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하지 않아야 하며, 다음 행위를 함으로 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
									<p>1) 다른 회원의 아이디(ID)를 부정하게 사용하는 행위</p>
									<p>2) 다른 회원의 E-mail 주소를 취득하여 스팸메일을 발송하는 행위</p>
									<p>3) 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</p>
									<p>4) 선량한 풍속, 기타 사회질서를 해하는 행위</p>
									<p>5) 회사 및 타인의 명예를 훼손하거나 모욕하는 행위</p>
									<p>6) 회사 및 타인의 지적재산권 등의 권리를 침해하는 행위</p>
									<p>7) 해킹행위 또는 컴퓨터 바이러스의 유포행위</p>
									<p>8) 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위</p>
									<p>9) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</p>
									<p>10) 회사가 제공하는 서비스의 내용을 변경하는 행위</p>
									<p>11) 기타 관계법령에 위배되는 행위</p>
								</li>
							</ol>
							<h3>
								<a name="a2">제 14조 (게시물 삭제)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 이용자가 게시하거나 등록하는 서비스내의 게시물이 제 13조의 규정에 위반되거나, 다음 각 호에 해당한다고 판단되는 경우 사전통지 없이 게시물을 삭제할 수 있습니다.
									<p>1) 다른 이용자 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용</p>
									<p>2) 공공질서 또는 미풍양속에 위반되는 내용</p>
									<p>3) 범죄적 행위에 결부된다고 인정되는 내용</p>
									<p>4) 제 3자의 저작권 등 기타 권리를 침해하는 내용</p>
									<p>5) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 내용</p>
									<p>6) 근거나 확인절차 없이 회사를 비난하거나 유언비어를 유포한 내용</p>
									<p>7) 기타 관계법령에 의거하여 위반된다고 판단되는 내용</p>
									<p>단, 독자게시판의 경우 다음과 같이 예외를 둔다.</p>
									<p>용량이 큰 데이터의 경우 업로드 된 게시물의 수를 제한하며 그 수를 넘을 때에는 서버의 원활한 운영을 위해 가장 오래된 게시물부터 삭제할 수 있다.</p>
								</li>
								<li>
									<span class="first_letter">2.</span>회사는 이용자가 게시하거나 등록하는 서비스내의 게시물이 제 13조의 규정에 위반되거나 동 조 제1항 각 호에 해당한다고 판단되는 정보를 링크하고 있을 경우 사전통지 없이 게시물을 삭제할 수 있습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 15조 (게시물에 대한 권리 / 의무)</a>
							</h3>
							<ol>
								<li>
								게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 이용자에게 있습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 16조 (연결 "홈페이지"와 피연결 "홈페이지"간의 관계)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>상위 "홈페이지"와 하위 "홈페이지"가 하이퍼 링크(예:하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨) 방식 등으로 연결된 경우, 전자를 연결 "홈페이지"라고 하고 후자를 피연결 "홈페이지(웹사이트)"라고 합니다.
								</li>
								<li>
									<span class="first_letter">2.</span>연결 "홈페이지"는 피연결 "홈페이지"가 독자적으로 제공하는 재화와 용역에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 17조 (저작권의 귀속 및 이용제한)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 회사에 귀속합니다.
							</li>
							<li>
							<span class="first_letter">2.</span>이용자는 서비스를 이용함으로써 얻은 정보를 회사의 사전승낙 없이 복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
							</li>
							</ol>
							
							<h3>
								<a name="a2">제 18조 (양도금지)</a>
							</h3>
							<ol>
							<li>
							회원이 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
							</li>
							</ol>
							<h3>
								<a name="a2">제 19조 (손해배상)</a>
							</h3>
							<ol>
							<li>
							회사는 무료로 제공되는 서비스와 관련하여 이용자에게 어떠한 손해가 발생하더라도 동 손해가 회사의 중대한 과실에 의한 경우를 제외하고 이에 대하여 책임을 부여하지 아니합니다.
							</li>
							</ol>
							<h3>
								<a name="a2">제 20조 (면책 / 배상)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 이용자는 자신의 책임아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 이용자에게 있습니다.
							</li>
							<li>
							<span class="first_letter">2.</span>회사는 제 13조의 규정에 위반하여 이용자간 또는 이용자와 제 3자간에 서비스를 매개로 한 물품거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 이용자가 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.
							</li>
							<li>
							<span class="first_letter">3.</span>이용자가 제 13조, 기타 이 약관의 규정을 위반함으로 인하여 회사가 이용자 또는 제 3자에 대하여 책임을 부담하게 되고, 이로써 회사에게 손해가 발생하게 되는 경우, 이 약관을 위반한 이용자는 회사에게 발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 회사를 면책시켜야 합니다.
							</li>
							</ol>
							<h3>
								<a name="a2">제 21조 (분쟁의 해결)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>회사와 이용자는 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
							</li>
							<li>
							<span class="first_letter">2.</span>제 1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 서울지방법원을 관할로 합니다.
							</li>
							<li>
							<span class="first_letter">3.</span>동 소송에는 대한민국 법을 적용합니다.
							</li>
							</ol>
							<h3>
								<a name="a2">제 22조 (기타)</a>
							</h3>
							<ol>
							<li>
							이 약관에 명시되지 아니한 사항은 대표 연락처(02-525-2515)로 문의주십시오.
							</li>
							
							</ol>
							
							<h3>
								<a name="a2">부칙</a>
							</h3>
							<p>이 약관은 2023년 2월 1일부터 시행합니다.</p>
						</div>`,
    checked: false,
    required: true,
  },
  {
    type: "normal",
    id: 2,
    label: "개인정보보호를 위한 정책 동의",
    term: `<div class="section customModify">
							<h3 class="first">
								<a name="a1">1. 총칙</a>
							</h3>
							<ol>
								<li>
								대티즌은(이하 "회사"라 합니다)는 이용자의 개인정보를 매우 소중히 생각합니다. 회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」을 비롯한 개인정보 관련 법령을 준수하고 있습니다. 이용자께서 알려주신 개인정보는 수집, 이용 동의를 받은 범위 내에서, 단말기 정보 등에 접근한다는 것을 고지, 승인 후 수집되며, 별도의 동의없이는 결코 제 3 자에게 제공되지 않습니다. 또한 기본적 인권 침해의 우려가 있는 민감한 개인정보는 수집하지 않습니다. 본 개인정보처리방침은 개인정보를 안전하게 처리하기 위한 회사의 정책관련 자세한 사항을 알려드리기 위해 마련되었으며 대티즌 및 또는 웹사이트 (‘http://www.detizen.com’이하’www.detizen.com’)에서 언제든 확인하실 수 있습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">2. 개인정보의 처리목적</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로 이용되지 아니하며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
									<p>	• 웹사이트 또는 앱 서비스의 회원 가입 및 관리</p>
									<p>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 및 인증, 회원자격 유지와 관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지 및 통지, 고충처리 등을 목적으로 개인정보를 처리합니다.</p>
									<p>	• 재화 또는 서비스 제공</p>
									<p>물품배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제 및 정산, 포인트 적립 및 이용, 채권추심 등을 목적으로 개인정보를 처리합니다.</p>
									<p>	• 고충처리</p>
									<p>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락 및 통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.</p>
								</li>
								
							</ol>

							<h3>
								<a name="a2">3. 수집하는 개인정보의 항목</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 홈페이지, 서비스 이용, 이벤트 응모, 팩스, 우편, 전화, 고객센터 문의하기 등을 위해 아래와 같은 개인정보를 수집하고 있습니다. 
									<p>
									해당 서비스의 본질적 기능을 수행하기 위한 정보는 필수항목으로서 수집하며 이용자가 그 정보를 회사에 제공하지 않는 경우 서비스 이용에 제한이 가해질 수 있지만, 선택항목으로 수집되는 정보의 경우에는 이를 입력하지 않은 경우에도 서비스 이용제한은 없습니다. 추가항목은 휴대폰 감면권 신청, 기부금 영수증 발행 등의 경우 해당 신청자에 한해 추가적으로 수집합니다. 단, 만14세 미만 아동의 개인정보는 수집하지 않습니다.
									</p>
									
								</li>
								<li>
									<span class="first_letter">2.</span>수집항목, 이용목적, 보유기간
									<p> • 수집 항목</p>
									<p>1) 회원가입시 수집하는 항목 </p>
									<p>개인 회원 필수항목: 아이디, 패스워드, 휴대번호, 생년월일, 닉네임 및 e-메일 계정 정보 </p>
									<p>기업 회원 필수항목: 아이디, 패스워드, 담당자 이름, 휴대전화, 기업명, 사업자등록번호, 대표자, 기업기분, e-메일 </p>
									<p>2) 이벤트 경품 발송시 수집하는 항목 필수 항목: 이름, 휴대전화, 주민등록번호(근거 : 소득세법)</p>
									<p>3) 서비스 이용과정이나 사업처리 과정에서 자동 수집되는 항목 IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 광고 ID,접속 환경</p>
									<p> • 수집 및 이용목적 </p>
									<p>1) 회원관리 회원제 서비스 이용에 따른 본인확인, 본인의 의사확인, 고객문의에 대한 응답, 새로운 정보의 소개 및 고지사항 전달</p>
									<p>2) 서비스 제공에 관한 계약 이행 및 서비스 제공 부정 이용방지와 비인가 사용방지</p>
									<p>3) 서비스 개발 및 마케팅ㆍ광고 활용 맞춤 서비스 제공, 서비스 안내 및 이용권유, 서비스 개선 및 신규 서비스 개발을 위한 통계 및 접속빈도 파악, 통계학적 특성에 따른 광고, 이벤트 정보 및 참여기회 제공</p>
									<p>4) 고용 및 취업동향 파악을 위한 통계학적 분석, 서비스 고도화를 위한 데이터 분석</p>
									<p> • 보유기간 </p>
									<p>회원탈퇴 후 30일 이내 또는 법령에 따른 보존기간(단, 부정이용 확인 시 회원탈퇴 후 6 개월) 기타 거래처 뉴스레터 발송, 각종 이벤트, 마케팅, 설문조사 등을 위하여 해당 발송에 대한 수신동의를 받아 이메일을 수집하고 있으며, 각종 이벤트시 그 내용에 따라 추가 항목들을 제공받아 이용하고 있습니다. 그리고 중복가입방지, 문의사항처리, 부정 이용자 제재, 포인트 정산, 공지사항 전달, 분쟁조정을 위한 기록보존 등 회원관리와 신규 서비스 개발을 위한 통계적 분석을 위해 서비스 이용기록 등의 사항들을 저장하고 있습니다.</p>
								</li>
								<li>
									<span class="first_letter">3.</span>개인정보 수집방법
									<p> • 회사는 온라인 회원가입, 회원정보수정, 서면양식, 전화 또는 팩스, 서비스 이용, 제휴사로부터의 제공, 단말기를 통한 자동수집, 이메일과 같은 방법을 통하여 개인정보를 수집합니다.</p>
									<p> • 이용자의 사상, 신념, 과거의 병력 등 개인의 권리, 이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감정보는 수집하지 않으며, 회사는 이용자의 개인정보를 수집할 때, 사전에 해당 사실을 이용자에게 알리고 동의를 구합니다.</p>
									<p> • 다만, 이용자가 요구하는 서비스를 제공하는 과정에서 이를 이행하기 위하여 필요한 개인정보로서 경제적, 기술적 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우, 서비스 제공에 따른 요금정산을 위하여 필요한 경우, 기타 정보통신망법 또는 다른 법률에 특별한 규정이 있는 경우에는 동의를 받지 않고 이용자의 개인정보를 수집할 수 있습니다.</p>							
								</li>
							</ol>
							<h3>
								<a name="a2">4. 개인정보의 보유 및 이용기간</a>
							</h3>
							<ol>
								<li>
								필수항목/선택항목의 경우 회원가입일로부터 탈퇴 후 30일까지, 추가항목의 경우 이용 완료시까지 보유 후 파기하고 있고, 탈퇴하기 전이라도 최근 1년간 서비스를 사용하지 않은 이용자의 정보는 분리하여 파기하고 있습니다. 단, 법령에 의해 보존해야만 하는 경우 또는 불량이용자 제재와 관련 분쟁 방지를 위하여 아래와 같이 일정 기간 동안 회원정보를 보존합니다.
								<p>
								계약 또는 청약철회 등에 관한 기록 | 전자상거래 등에서의 소비자보호에 관한 법률 | 5년
								</p>
								<p>
								소비자의 불만 또는 분쟁처리에 관한 기록 | 전자상거래 등에서의 소비자보호에 관한 법률 | 5년
								</p>
								<p>
								대금결제 및 재화 등의 공급에 관한 기록 | 전자상거래 등에서의 소비자보호에 관한 법률 | 5년
								</p>
								<p>
								전자금융 거래에 관한 기록 | 전자금융거래법 | 1년 또는 5년
								</p>
								<p>
								서비스 방문기록, 접속로그, 접속아이피 | 통신비밀보호법 | 3개월
								</p>
								</li>
								
							</ol>
							<h3>
								<a name="a2">
								5. 개인정보의 파기 절차 및 방법
								</a>
							</h3>
							<ol>
								<li>
								회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
								</li>
								<li>
									<span class="first_letter">1.</span>파기절차
									<p>
									이용자가 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB 로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB 로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
									</p>
								</li>
								<li>
									<span class="first_letter">2.</span>파기기한
									<p>
									이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유 기간의 종료일로부터 30일 이내에 파기하며 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 30일 이내에 그 개인정보를 파기합니다.
									</p>
								</li>
								<li>
									<span class="first_letter">3.</span>파기방법
									<p>
									종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며,
									</p>
								</li>
								<li>
									<span class="first_letter">4.</span>장기 미이용자의 개인정보 파기
									<p>
									정보통신망법에 따라 회원가입 후 서비스 이용이 없는 고객 및 12개월 이상 로그인하지 않은 경우 해당 이용자 ID 및 개인정보를 유효기간 경과 후 즉시 파기하거나, 분리하여 보관합니다. 이러한 경우 위 12개월의 기간 도래 30일 전까지 회사는 이용자의 개인정보가 분리되어 저장, 관리되고 있다는 사실과 기간 만료일 및 해당 개인정보의 항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한 방법 중 어느 하나의 방법으로 이용자에게 알려드립니다. 명시한 기한 내에 로그인 하지 않거나 서비스 이용이 없는 경우에는 회원자격을 상실시킬 수 있습니다. 이 경우, 회원 아이디를 포함한 회원의 개인정보 및 서비스 이용 정보는 파기, 삭제됩니다.
									</p>
								</li>
							</ol>
							<h3>
								<a name="a2">6. 이용자 및 법정대리인의 권리와 그 행사방법</a>
							</h3>
							<ol>
								<li>
								회사는 이용자 및 법정대리인의 권리를 다음과 같이 보호하고 있습니다.
								</li>
								<li>
									<span class="first_letter">1.</span>언제든지 자신의 개인정보를 조회하고 수정할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">2.</span>언제든지 개인정보 제공에 관한 동의철회/회원가입 해지를 요청할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">3.</span>정확한 개인정보의 이용 및 제공을 위해 이용자가 개인정보 수정 진행 시 수정이 완료될 때까지 이용자의 개인정보는 이용되거나 제공되지 않습니다. 이미 제 3 자에게 제공된 경우에는 지체 없이 제공받은 자에게 사실을 알려 수정이 이루어질 수 있도록 하겠습니다.
								</li>
								<li>
								<p>권리 행사는 고객센터에서 할 수 있으며 서면, 전화, 전자우편, 등을 통해서도 하실 수 있습니다.</p>
								<p>서면, 전화, 전자우편, 등을 통해 요청하는 경우 개인정보보호법 시행규칙 별지 제8호 서식에 따라 개인정보(열람,정정,삭제,처리정지) 요구서를 제출하시면 이에 대해 지체없이(10 일 이내) 조치해 드릴 것 입니다. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한, 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</p>
								<p>회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "4. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>
								<p>또한 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제 11 호 서식에 따른 위임장을 제출하셔야 합니다.</p>
								</li>
							</ol>
							<h3>
								<a name="a2">
								7. 개인정보 자동수집장치의 설치, 운영 및 거부에 관한 사항
								</a>
							</h3>
							<ol>
								<li>
								회사는 이용자의 정보를 수시로 저장하고 찾아내는 "쿠키(cookie)"와 "세션"을 운용합니다. "쿠키"란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. "세션"이란 웹사이트를 운영하는데 이용되는 서버가 이용자 로그인 시간 동안에 이용자의 정보를 서버에 저장하는 것을 의미합니다. 회사는 다음과 같은 목적을 위해 쿠키와 세션을 사용합니다.
								</li>
								<li>
									<span class="first_letter">1.</span>쿠키와 세션의 사용 목적
									<p>회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 "세션(session)"을 사용합니다. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</p>
								</li>
								<li>
									<span class="first_letter">2.</span>쿠키 설정 거부 방법
									<p>
									쿠키 설정을 거부하는 방법으로는 이용자가 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
인터넷 익스플로러의 경우 : 웹 브라우저 상단의 도구 -> 인터넷 옵션 -> 개인정보 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
									</p>
								</li>
								<li>
									<span class="first_letter">3.</span>세션의 설치/운영 및 거부
									<p>이용자는 세션 설치에 대한 선택권을 가지고 있지 않습니다. 로그인이 필요한 회사의 서비스의 경우 회사 운영 서버에서 자동으로 세션이 생성됩니다.</p>
								</li>
							</ol>
							<h3>
								<a name="a2">8. 개인정보의 기술적, 관리적 보호대책</a>
							</h3>
							<ol>
								<li>
								회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
								</li>
								<li>
									<span class="first_letter">1.</span>개인정보 암호화
									<p>이용자의 개인정보(성별 등)는 암호화하여 저장/관리되고 있으며, 암호화통신(SSL) 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 비밀번호는 복호화가 불가능한 일방향 암호화되어 저장/관리되고 있습니다.</p>
								</li>
								<li>
									<span class="first_letter">2.</span>해킹 등에 대비한 대책
									<p>
									회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 또한 개인정보의 훼손에 대비해서 자료를 수시로 백업하고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신(SSL) 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려고 노력하고 있습니다.
									</p>
								</li>
								<li>
									<span class="first_letter">3.</span>처리 직원의 최소화 및 교육실시
									개인정보를 처리하는 직원을 최소화 하며, 업무용 PC 에서는 외부 인터넷 서비스를 사용할 수 없도록 차단하여 개인정보 유출에 대한 위험을 줄이고 있습니다. 또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다. 또한 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.
								</li>
							</ol>

							<h3>
								<a name="a2">
								9. 개인정보에 관한 민원서비스
								</a>
							</h3>

							<ol>
								<li>
								회사는 개인정보보호 전담 조직 운영을 통하여 개인정보 처리방침의 이행사항 및 담당자의 준수여부를 확인하고 있으며, 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
								<p>개인정보보호 책임자</p>	
								<p>책임자 : 한성일 직위 : 대표</p>	
								<p>문의 : detizen @detizen.com</p>	
								<p>또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.</p>	
								<p></p>	
								<p>개인정보침해 신고센터 (국번없이) 118 https://privacy.kisa.or.kr</p>	
								<p>대검찰청 사이버수사과(국번없이) 1301 http://www.spo.go.kr</p>	
								<p>경찰청 사이버안전국 (국번없이) 182 http://cyberbureau.police.go.kr/</p>	
								</li>
							</ol>

							<h3>
								<a name="a2">
								10. 기타 개인정보 처리에 관한 방침
								</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>부가서비스 이용에 따른 개인정보 처리방침
									<p>
									회사가 제공하는 부가서비스 중 회원가입이 필요한 서비스의 경우 개인정보 처리방침에 대한 동의를 따로 받습니다.
									</p>
									
								</li>
								<li>
									<span class="first_letter">2.</span>개인 아이디와 비밀번호 관리
									<p>
									이용자가 사용하고 계시는 ID 와 비밀번호는 원칙적으로 이용자만이 사용하도록 되어 있습니다. 회사의 고의 또는 과실이 없는 경우에, 이용자의 ID 와 비밀번호 도용 또는 기타 타인의 사용으로 인해 발생된 문제에 대하여 회사가 책임지지 않습니다. 어떠한 경우에도 비밀번호는 타인에게 알려 주지 마시고 로그온 상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록 특별한 주의를 기울여 주시기 바랍니다. 타인의 개인정보를 도용하여 회원 가입 또는 계약이 확인되었을 경우에는 이용계약이 일방적으로 해지될 수 있으며, 주민등록법에 의해 3년 이하의 징역 또는 1천만원 이하의 벌금이 부과될 수 있습니다.
									</p>
									
								</li>
							</ol>
							<h3>
								<a name="a2">
								11. 고지의무
								</a>
							</h3>
							<ol>
								<li>
								현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 시행일자 최소 7 일전부터 대티즌 웹사이트 http://www.detizen.com "공지사항" 화면을 통해 공고할 것입니다.
								<p>공고일자 : 2023년 1월 30일</p>	
								<p>시행일자 : 2023년 1월 30일</p>	
								</li>
								
							</ol>
							
						</div>`,
    checked: false,
    required: true,
  },
  {
    type: "normal",
    id: 3,
    label: "마케팅 정보 이메일/휴대폰 문자 수신동의 (선택)",
    term: `<div class="section customModify">
							<h3 class="first">
								<a name="a1">책임의 한계</a>
							</h3>
							<p>
								
								대티즌은 링크, 다운로드, 광고 등을 포함하여 본 웹 사이트에
								포함되어 있거나, 본 웹 사이트를 통해 배포, 전송되거나, 본 웹
								사이트에 포함되어 있는 서비스로부터 접근되는 정보(이하 "자료")의
								정확성이나 신뢰성에 대해 어떠한 보증도 하지 않으며, 서비스상의,
								또는 서비스와 관련된 광고, 기타 정보 또는 제안의 결과로서
								디스플레이, 구매 또는 취득하게 되는 제품 또는 기타 정보(이하
								"제품")의 질에 대해서도 어떠한 보증도 하지 않습니다.
							</p>
							<br />
							<p>
								귀하는, 자료에 대한 신뢰 여부가 전적으로 귀하의 책임임을
								인정합니다. 대티즌은 자료 및 서비스의 내용을 수정할 의무를 지지
								않으나, 필요에 따라 개선할 수는 있습니다.대티즌은 자료와
								서비스를 "있는 그대로" 제공하며, 서비스 또는 기타 자료 및 제품과
								관련하여 상품성, 특정 목적에의 적합성에 대한 보증을 포함하되
								이에 제한되지 않고 모든 명시적 또는 묵시적인 보증을 명시적으로
								부인합니다.
							</p>
							<br />
							<p>
								어떠한 경우에도 대티즌은 서비스, 자료 및 제품과 관련하여 직접,
								간접, 부수적, 징벌적, 파생적인 손해에 대해서 책임을 지지
								않습니다. 대티즌은 본 웹사이트를 통하여 인터넷을 접속함에 있어
								사용자의 개인적인 판단에 따라 하시기를 바랍니다. 본 웹사이트를
								통해 일부 사람들이 불쾌하거나 부적절 하다고 여기는 정보가
								포함되어 있는 사이트로 연결될 수 있습니다. 대티즌은 본 웹사이트
								또는 자료에 열거되어 있는 사이트의 내용을 검토하려는 노력과
								관련하여 어떠한 보증도 하지 않습니다. 또한 대티즌은 본 웹사이트
								또는 자료에 열거되어 있는 사이트상의 자료의 정확성, 저작권 준수,
								적법성 또는 도덕성에 대해 아무런 책임을 지지 않습니다.
							</p>
							<br />
							<p>
								대티즌은 지적재산권을 포함한 타인의 권리를 존중하며, 사용자들도
								마찬가지로 행동해주시기를 기대합니다. 대티즌은 필요한 경우 그
								재량에 의해 타인의 권리를 침해하거나 위반하는 사용자에 대하여
								사전 통지 없이 서비스 이용 제한 조치를 취할 수 있습니다.
							</p>
							<h3>
								<a name="a2">상표에 관한 정보</a>
							</h3>
							<p>
								본 웹사이트에 포함된 제품 및/또는 서비스의 명칭은 대티즌의 상표
								또는 등록 상표입니다.
							</p>
							<p>
								회사는 이에 대한 권리를 가지고 있으며, 다른 제품 및 회사의
								명칭은 그 해당 사용자들의 상표일 수 있습니다.
							</p>
						</div>`,
    checked: false,
    required: false,
  },
  {
    type: "company",
    id: 4,
    label: "기업회원 이용약관 동의",
    term: `<div class="section customModify">
							<h3 class="first">
								<a name="a1">제 1 조 (목적)</a>
							</h3>
							<p>
							본 약관은 대티즌(이하 "회사")이 운영하는 "서비스"를 이용함에 있어 "회사"와 기업회원간의 이용 조건 및 제반 절차, 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
							</p>
							<h3>
								<a name="a2">제 2 조 (용어의 정의)<small>이 약관에서 사용하는 용어의 정의는 아래와 같습니다. </small></a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"사이트"라 함은 회사가 서비스를 "기업회원"에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 설정한 가상의 영업장 또는 회사가 운영하는 웹사이트, 모바일웹, 앱 등의 서비스를 제공하는 모든 매체를 통칭하며, 통합된 하나의 기업회원 계정(아이디 및 비밀번호)을 이용하여 서비스를 제공받을 수 있는 아래의 사이트를 말합니다.
									<p>detizen.com</p>
								</li>
								<li>
									<span class="first_letter">2.</span>"서비스"라 함은 회사가 운영하는 사이트를 통해 기업(단체, 사업자, 교육기관)이 아웃소싱, 인재파견, 채용 등을 목적으로 등록하는 자료를 DB화하여 각각의 목적에 맞게 분류 가공, 집계하여 정보를 제공하는 서비스와 사이트에서 제공하는 모든 부대 서비스를 말합니다. 	
								</li>
								<li>
									<span class="first_letter">3.</span>"기업회원"이라 함은 기업의 필요한 인재를 고용할 것을 목적으로 약관에 동의하고 아이디를 부여받아 회사의 서비스를 이용하는 자를 말하며, 아웃소싱(도급), 인재파견, 채용대행 기업을 포함한다. 또한, 기업회원은 각 사업자 별로 아이디를 부여 받아 회사의 서비스를 이용하여야 합니다. 
								</li>
								<li>
									<span class="first_letter">4.</span>"아이디"라 함은 기업회원가입시 기업회원의 식별과 기업회원의 서비스 이용을 위하여 기업회원이 선정하고 "회사"가 부여하는 문자와 숫자의 조합 혹은 이메일주소를 말합니다. 
								</li>
								<li>
									<span class="first_letter">5.</span>"비밀번호"라 함은 위 제4항에 따라 기업회원이 기업회원가입시 아이디를 설정하면서 아이디를 부여받은 자와 동일인임을 확인하고 "기업회원"의 권익을 보호하기 위하여 "기업회원"이 선정한 문자와 숫자, 특수문자의 조합을 말합니다. 
								</li>
								<li>
									<span class="first_letter">6.</span>"비회원"이라 함은 기업회원 가입절차를 거치지 않고 "회사"가 제공하는 서비스를 이용하거나 하려는 자를 말합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 3 조 (약관의 명시와 개정) </a>
							</h3>
							<ol>
							<li>
									<span class="first_letter">1.</span>"회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 이용자에게 공지해야 합니다. 
									<p>detizen.com</p>
								</li>
								<li>
									<span class="first_letter">2.</span>"회사"는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위반하지 않는 범위에서 이 약관을 개정할 수 있습니다. 
								</li>
								<li>
									<span class="first_letter">3.</span>"회사"가 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 단 "기업회원"의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 합니다. 
								</li>
								<li>
									<span class="first_letter">4.</span>"기업회원"은 변경된 약관에 대해 거부할 권리가 있습니다. "기업회원"은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있습니다. "기업회원"이 거부하는 경우 본 서비스 제공자인 "회사"는 15일의 기간을 정하여 "기업회원"에게 사전 통지 후 당해 "기업회원"과의 계약을 해지할 수 있습니다. 만약, "기업회원"이 거부의사를 표시하지 않거나, 전항에 따라 시행일 이후에 "서비스"를 이용하는 경우에는 동의한 것으로 간주합니다.
								</li>								
							</ol>
							<h3>
								<a name="a2">제 4 조 (약관의 해석) </a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등의 관계법령에 따릅니다. 
								</li>
								<li>
									<span class="first_letter">2.</span>각 사이트 및 서비스 이용약관이 있는 경우에는 서비스 이용약관이 우선합니다. 
								</li>
								<li>
									<span class="first_letter">3.</span>"기업회원"이 "회사"와 개별 계약을 체결하여 서비스를 이용할 경우에는 개별 계약이 우선합니다.
								</li>
							</ol>
							<h3>
								<a name="a2">제 5 조 (이용계약의 성립) </a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"회사"의 서비스 이용계약(이하 "이용계약")은 서비스를 이용하고자 하는 자가 본 약관과 개인정보처리방침을 읽고 "동의" 또는 "확인" 버튼을 누른 경우 본 약관에 동의한 것으로 간주합니다. 
								</li>
								<li>
									<span class="first_letter">2.</span>서비스 이용계약 체결에 필요한 경우 "회사"는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다. "기업회원"은 본인인증에 필요한 이름, 생년월일, 연락처 등을 제공하여야 합니다. 또한 서비스 이용을 위해 회사명과 사업자등록번호를 제공하여야 하며 "회사"는 기업인증을 위해 사업자정보를 확인할 수 있는 증명서(사업자등록증)를 별도로 요구할 수 있습니다. 회사명과 사업자등록번호를 허위로 등록한 경우, 휴·폐업 사업자등록번호로 등록한 경우에 "기업회원"은 일체의 권리를 주장할 수 없습니다. 
								</li>
								<li>
									<span class="first_letter">3.</span>회사는 기업회원 약관에 동의한 "기업회원"에 웹상의 안내 및 전자메일로 통지함으로써 서비스 이용계약의 성립을 확인합니다. 
								</li>
								<li>
									<span class="first_letter">4.</span>사업자등록번호가 없는 기관이나 단체는 "회사"가 정한 별도의 절차에 따라 이용계약을 할 수 있습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 6 조 (이용신청의 승낙과 제한)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행상 또는 기술상 지장이 없는 경우에는 원칙적으로 접수순서에 따라 서비스 이용을 승낙합니다.
								</li>
								<li>
									<span class="first_letter">2.</span>"회사"는 아래 사항에 해당하는 경우에 대해서는 서비스 이용신청을 승낙하지 않습니다.
									<p>실제 회사명과 사업자등록번호가 일치하지 않거나 타사의 정보를 이용하여 신청한 경우</p>
									<p>이용계약 신청서의 내용을 허위로 기재하거나 "회사"가 제시하는 내용을 기재하지 않은 경우</p>
									<p>이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</p>
								</li>
								<li>
									<span class="first_letter">3.</span>"회사"는 아래 사항에 해당하는 경우에는 그 신청에 대하여 승낙제한 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
									<p>"회사"가 설비의 여유가 없는 경우</p>
									<p>"회사"의 기술상 지장이 있는 경우</p>
									<p>기타 "회사"의 귀책사유로 이용승낙이 곤란한 경우</p>
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 7 조 (서비스의 내용)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"회사"는 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와 같습니다.
									<p>이용자간의 교류와 소통과 관련된 서비스</p>
									<p>자료 거래에 관련된 서비스</p>
									<p>기타 "회사"가 추가 개발하거나 제휴계약 등을 통해 "기업회원"에게 제공하는 일체의 서비스</p>
								</li>
								<li>
									<span class="first_letter">2.</span>"회사"는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 단, 이 경우 "회사"는 추가 또는 변경내용을 "기업회원"에게 공지해야 합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 8 조 ("기업회원"정보, 공고 관련 정보)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"기업회원"은 공고 등록 시 기업정보를 정확히 기재하여야 합니다.
									
								</li>
								<li>
								<span class="first_letter">2.</span>"기업회원"은 아웃소싱(도급), 인재파견 또는 채용대행 공고를 등록하는 경우 구직을 희망하는 개인회원이 자사의 직접 고용 목적으로 등록한 공고와 오인·혼동 되지 않도록 하여야 합니다.
								
								</li>
								<li>
									<span class="first_letter">3.</span>"기업회원"은 직업안정법 제34조 및 동법 시행령 제 34조 거짓구인광고의 범위에 위반되지 않도록 공고를 작성하여 등록해야 하며 그 내용은 다음과 같습니다.
									<p>구인을 가장하여 물품판매, 수강생 모집, 직업소개, 부업알선, 자금모집 등을 행하는 광고</p>
									<p>거짓 구인을 목적으로 구인자의 신원(업체명 또는 성명)을 표시하지 아니하는 광고</p>
									<p>구인자가 제시한 직종, 고용형태, 근로조건 등이 응모할 때의 그것과 현저히 다른 광고</p>
									<p>기타 광고의 중요내용이 사실과 다른 광고</p>
									
									
								</li>
								<li>
									<span class="first_letter">4.</span>"기업회원"은 관련 법률에 의거하여 근로자의 모집 및 채용에 있어서 남녀, 연령을 차별하여서는 아니되며 15세 미만 채용 모집을 할 수 없습니다.
									
								</li>
								<li>
									<span class="first_letter">5.</span>제 3항, 제 4항을 위반한 경우 이에 대한 책임은 전적으로 "기업회원"에게 있습니다.
									
								</li>
								<li>
									<span class="first_letter">6.</span>"회사"는 "기업회원"이 등록한 기업정보 및 공고를 "회사"가 정한 방법에 의해 노출할 수 있습니다.
									
								</li>
								<li>
									<span class="first_letter">7.</span>"회사"는 수집되는 "기업회원" 정보를 본 서비스 이용 이외의 목적으로 사용할 수 없으며, 각 개인의 동의 없이 개인정보를 기업의 영업·마케팅의 용도로 사용하거나 제3자에게 제공 시 개인정보보호법, 정보통신망이용촉진 및 정보보호 등에 관한 법률에 의거하여 법적 책임을 질 수 있습니다.
								</li>
							</ol>
							<h3>
								<a name="a2">제 9 조 (제휴를 통한 서비스)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"회사"는 제휴 관계를 체결한 여타 인터넷 웹사이트 및 채용박람회 또는 신문, 잡지 등의 오프라인 매체를 통해 사이트에 등록한 "기업회원"의 기업정보와 공고가 열람될 수 있도록 서비스를 제공할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">2.</span>"회사"는 제휴를 통해 타 사이트 및 매체에 등록될 수 있음을 고지해야 하며, 제휴 사이트 목록을 사이트내에서 상시 열람할 수 있도록 해야 한다. 단, 등록의 형태가 "회사"가 직접 구축하지 않고, 제휴사가 xml 또는 api 형태로 "회사"로부터 제공 받아 구축한 매체 목록은 본 약관외 별도의 제휴리스트에서 열람할 수 있도록 합니다.
								</li>
								<li>
									<span class="first_letter">3.</span>"회사"는 다음 각 호의 사이트와 제휴를 통해 "기업회원"이 등록한 기업정보와 채용정보를 제공한다.
								</li>
								<li>
									<span class="first_letter">4.</span>본 조 제 3항 제휴를 통한 사이트의 변동사항 발생 시 공지사항을 통해 고지 후 진행 합니다.
								</li>

							</ol>
							<h3>
								<a name="a2">제 10 조 (서비스의 요금) </a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"기업회원" 가입과 공고 등록비용은 무료이다. 다만 개인회원 또는 사이트에 방문한 일반회원에게 자신의 정보를 보다 효과적으로 노출시키기 위한 유료옵션과 즉시등록 서비스, 유료 이력서 검색 및 기타 서비스를 이용하기 위한 별도의 서비스는 유료로 제공될 수 있습니다. 
								</li>
								<li>
									<span class="first_letter">2.</span>"회사"는 유료서비스를 제공할 경우 사이트에 요금에 대해서 공지를 하여야 합니다. 
								</li>
								<li>
									<span class="first_letter">3.</span>"회사"는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 "회사"가 예고 없이 변경할 수 있습니다. 다만, 변경 이전에 적용 또는 계약한 금액은 소급하여 적용하지 아니합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 11 조 (서비스 요금의 환불)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span> “회사”는 다음 각 호에 해당하는 경우 이용요금을 환불합니다. 단, 각 당사자의 귀책사유에 따라 환불 조건이 달라질 수 있습니다.
									<p>유료서비스 이용이 개시되지 않은 경우</p>
									<p>네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우</p>
									<p>유료서비스 신청 후 “회원”의 사정에 의해 서비스가 취소될 경우</p>
								</li>
								<li>
									<span class="first_letter">2.</span>“회사”가 본 약관 제19조에 따라 가입해지/서비스중지/자료삭제를 취한 경우, “회사”는 “기업회원”에게 이용요금을 환불하지 않으며, 별도로 “기업회원”에게 손해배상을 청구할 수 있습니다. 
								</li>
								<li>
									<span class="first_letter">3.</span>이용료를 환불받고자 하는 회원은 고객센터로 환불을 요청해야 합니다. 
								</li>
								<li>
									<span class="first_letter">4.</span>“회사”는 환불 요건에 부합하는 것으로 판단될 경우, 각 서비스 환불 안내에 따라 유료이용 계약 당시 상품의 정가 기준으로 서비스 제공된 기간에 해당하는 요금을 차감한 잔액을 환불합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 12 조 (서비스 이용시간) </a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"회사"는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공합니다. 다만, "회사"는 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 "회사"는 그 이용시간을 사전에 "기업회원"에게 공지 또는 통지하여야 합니다. 
								</li>
								<li>
									<span class="first_letter">2.</span>"회사"는 자료의 가공과 갱신을 위한 시스템 작업시간, 장애해결을 위한 보수작업 시간, 정기 PM작업, 시스템 교체작업, 회선 장애 등이 발생한 경우 일시적으로 서비스를 중단할 수 있으며 계획된 작업의 경우 공지란에 서비스 중단 시간과 작업 내용을 알려야 합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 13 조 (서비스 제공의 중지)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"회사"는 다음 각 호에 해당하는 경우 서비스의 제공을 중지할 수 있습니다.
									<p>설비의 보수 등 "회사"의 필요에 의해 사전에 "기업회원"들에게 통지한 경우</p>
									<p>기간통신사업자가 전기통신서비스 제공을 중지하는 경우</p>
									<p>기타 불가항력적인 사유에 의해 서비스 제공이 객관적으로 불가능한 경우</p>

								</li>
								<li>
									<span class="first_letter">2.</span>전항의 경우, "회사"는 기간의 정함이 있는 유료서비스 이용자들에게는 그 이용기간을 연장하는 등의 방법으로 손실을 보상하여야 합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 14 조 (정보의 제공 및 광고의 게재)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span> "회사"는 "기업회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나 서신우편을 이용한 방법으로 제공할 수 있습니다. 
								</li>
								<li>
									<span class="first_letter">2.</span>"회사"는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면, 홈페이지, 전자우편 등에 게재할 수 있으며, 광고가 게재된 전자우편을 수신한 "기업회원"은 수신거절을 "회사"에게 할 수 있습니다. 
								</li>
								<li>
									<span class="first_letter">3.</span>"회사"는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 "회원"이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않습니다.
								</li>
								<li>
									<span class="first_letter">4.</span>본 서비스의 "기업회원"은 서비스 이용 시 노출되는 광고게재에 대해 동의 하는 것으로 간주합니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">제 15 조 (자료내용의 책임과 "회사"의 정보 수정 권한)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>자료내용은 "기업회원"이 등록한 기업정보 및 공고와 사이트에 게시한 게시물을 말합니다.
						</li>
						<li>
							<span class="first_letter">2.</span>"기업회원"은 자료 내용 및 게시물을 사실에 근거하여 성실하게 작성해야 하며, 만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든 책임은 "기업회원"에게 있습니다.
						</li>
						<li>
							<span class="first_letter">3.</span>모든 자료내용의 관리와 작성은 "기업회원" 본인이 하는 것이 원칙이나 사정상 위탁 또는 대행관리를 하더라도 자료내용의 책임은 "기업회원"에게 있으며 "기업회원"은 주기적으로 자신의 자료를 확인하여 항상 정확하게 관리가 되도록 노력해야 합니다.
						</li>
						<li>
							<span class="first_letter">4.</span>"회사"는 "기업회원"이 등록한 자료 내용에 오자, 탈자 또는 사회적 통념에 어긋나는 문구와 내용이 있을 경우 이를 언제든지 수정할 수 있습니다.
						</li>
						<li>
							<span class="first_letter">5.</span>"기업회원"이 등록한 자료로 인해 타인(또는 타법인)으로부터 허위사실 및 명예훼손 등으로 삭제 요청이 접수된 경우 "회사"는 "기업회원"에게 사전 통지 없이 본 자료를 삭제할 수 있으며 삭제 후 메일 등의 방법으로 통지할 수 있습니다.
						</li>
								
							</ol>
							<h3>
								<a name="a2">제 16 조 (자료 내용의 활용 및 취급)</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>"기업회원"이 입력한 정보는 취업 및 관련 동향의 통계 자료로 활용될 수 있으며 그 자료는 매체를 통한 언론 배포 또는 제휴사에게 제공될 수 있습니다.
								</li>
							</ol>
							<h3>
								<a name="a2">제 17 조 ("회사"의 의무)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>"회사"는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다해야 합니다.
							</li>
							<li>
							<span class="first_letter">2.</span>"회사"는 서비스와 관련한 "기업회원"의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동 "기업회원"에게 통지하여야 합니다.
							</li>
							<li>
							<span class="first_letter">3.</span>"회사"는 유료 결제와 관련한 결제 사항 정보를 1년 이상 보존합니다. 다만 기업회원 자격이 없는 기업회원은 예외로 한다.
							</li>
							<li>
							<span class="first_letter">4.</span>천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을 지지 않습니다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 집니다.
							</li>
							<li>
							<span class="first_letter">5.</span>"기업회원"의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나 열람시킬 경우 반드시 "회원"의 동의를 얻어야 합니다.
							</li>
							<li>
							<span class="first_letter">6.</span>"기업회원"이 "사이트"에 등록한 기업정보, 채용공고 내용은 사이트를 방문하는 일반 대중에게 공개함을 원칙으로 합니다. 단, 회사가 운영하는 공식 SNS채널(페이스북, 인스타그램, 블로그 등)에 한해 채용공고 내용을 업로드하여 일반 대중에게 공개할 수 있습니다.
							</li>
							</ol>
							
							<h3>
								<a name="a2">제 18 조 ("회원"의 의무)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>"기업회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 됩니다.
							</li>
							<li>
							<span class="first_letter">2.</span>"기업회원"이 신청한 유료서비스는 등록 또는 신청과 동시에 "회사"와 채권, 채무 관계가 발생하며, "기업회원"은 이에 대한 요금을 지정한 기일 내에 납부하여야 합니다.
							</li>
							<li>
							<span class="first_letter">3.</span>"기업회원"이 결제 수단으로 신용카드를 사용할 경우 비밀번호 등 정보 유실 방지는 "기업회원" 스스로 관리해야 합니다. 단, "사이트"의 결함에 따른 정보유실의 발생에 대한 책임은 기업회원의 의무에 해당하지 아니합니다.
							</li>
							<li>
							<span class="first_letter">4.</span>"기업회원"은 서비스를 이용하여 얻은 정보를 "회사"의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.
							</li>
							<li>
							<span class="first_letter">5.</span>"기업회원"은 본 서비스를 건전한 직원채용 이외의 목적으로 사용해서는 안되며 이용 중 다음 각 호의행위를 해서는 안 됩니다.
							<p>다른 회원의 아이디를 부정 사용하는 행위</p>
							<p>범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</p>
							<p>타인의 명예를 훼손하거나 모욕하는 행위</p>
							<p>타인의 지적재산권 등의 권리를 침해하는 행위</p>
							<p>해킹행위 또는 바이러스의 유포 행위</p>
							<p>타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는 행위</p>
							<p>서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위</p>
							<p>사이트의 정보 및 서비스를 이용한 영리 행위</p>
							<p>그밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위</p>
							</li>
							<li>
							<span class="first_letter">6.</span>"기업회원"은 "사이트"를 통해 열람한 이력서 정보를 "회사" 및 당사자의 허락없이 재배포할 수 없으며 본 정보에 대한 출력 및 복사 등의 관리 책임은 전적으로 "기업회원"에게 있습니다.
							</li>
							</ol>
							<h3>
								<a name="a2">제 19 조 ("회원"의 가입해지/서비스중지/자료삭제)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>"기업회원"은 언제든지 회원탈퇴 또는 공고 등록을 해지하기 위해 고객센터 등을 통하여 이용계약 해지 신청을 할 수 있으며, "회사"는 관련법 등이 정하는 바에 따라 이를 처리합니다.
							</li>
							<li>
							<span class="first_letter">2.</span>다음의 사항에 해당하는 경우 "회사"는 사전 동의없이 가입해지나 서비스 중지, 공고 삭제 조치를 취할 수 있다.
							<p>기업회원의 의무를 성실하게 이행하지 않았을 때</p>
							<p>규정한 유료서비스 이용 요금을 납부하지 않았을 때</p>
							<p>불순한 의도로 유료서비스를 반복적으로 결제/취소할 때</p>
							<p>본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가 발생한 때</p>
							<p>기업회원이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때</p>
							<p>사업자등록증에 나타난 정보와 기업회원 가입시의 정보가 일치하지 않을 때</p>
							<p>타 기업의 사업자등록번호나 상호를 도용하여 허위로 기업정보를 등록하거나 휴·폐업 사업자등록번호로 기업정보를 등록했을 때</p>
							<p>지사, 지점, 영업소 등의 경우 구체적인 지사, 지점, 영업소 명칭을 사용하지 않고 기업명을 등록했거나 지사, 지점, 영업소의 사업자등록번호가 별도 있음에도 불구하고 본사의 사업자등록번호로 기업회원 가입을 했을 때</p>
							<p>회사명, 담당자 성명, 사무실 연락처 등 구인업체의 중요 정보를 정확하게 기입하지 않았을 때</p>
							<p>기업회원 가입 또는 공고 등록시 본 서비스에서 안내하는 방법으로 가입하지 않았거나 등록하지 않았을 때</p>
							<p>동일 기업회원ID로 마감일이 지나지 않은 사실상의 동일 내용의 공고를 중복 등록했을 때</p>
							<p>동일 사업자가 마감일이 지나지 않은 사실상의 동일내용의 공고를 다수 ID를 사용하여 중복등록 했을 때</p>
							<p>허위 공고를 등록했을 때</p>
							<p>작성자가 제시한 직종, 업무내용, 근로조건 등이 실제와 현저히 다를 때</p>
							<p>공고의 모집요강만으로 내용이 명확하지 않을 때</p>
							<p>모집요강에 적합한 업직종 분류를 지정하지 않았을 때(특히, 교육기관이나 이벤트업체 또는 영업대행 기관의 경우 영업, 광고/마케팅 등을 모집하고자 할 경우 반드시 직접 관련되는 해당 업종, 직종 분류만 지정해야 합니다.)</p>
							<p>헤드헌팅과 같이 인력중개를 주 목적으로 사업을 수행하는 업체인 경우</p>
							<p>공고를 등록한 회원의 정보와 실제 채용공고 상 모집하는 기업의 정보가 다른 경우</p>
							<p>아웃소싱(도급), 인재파견, 채용대행 공고임에도 불구하고 자사 공고인 것처럼 혼란을 야기하는 공고를 등록 한 때</p>
							<p>다단계 식의 모집 내용을 등록했을 때</p>
							<p>구인을 가장하여 유학알선, 물품판매, 자금 모금 등을 행하는 내용을 등록했을 때</p>
							<p>회원모집 형태의 광고 또는 카드회원 모집(수당제 광고) 내용을 등록했을 때</p>
							<p>추천인 모집 광고, 재택 쇼핑몰 분양 광고, 직업소개, 부업알선 광고를 등록했을 때</p>
							<p>취업수수료 등 취업 관련 비용을 필요로 하는 채용공고를 등록했을 때 (운송, 물류, 지입, 인력용역 등)</p>
							<p>불건전한 내용의 유흥업소 채용공고를 등록했을 때</p>
							<p>구직자의 피해사례 (채용공고를 통해 구직자가 입사 과정에서 피해가 발생한 경우 또는 재직중 "기업회원"이 관계법령을 위반하여 피해가 발생한 경우 등)가 접수된 채용공고</p>
							<p>임금체불로 근로감독원에 진정서가 접수되거나, 관계 당국에 고소·고발되어 있는 기업 (단, 기업의 임금체불이 해소가 입증되면 확인 후에 서비스 이용 가능)</p>
							<p>본 서비스와 관련하여 회사 또는 제3자의 명예를 훼손하였을 때</p>
							<p>동업자 모집, 프랜차이즈 모집, 점포개설, 창업, 사이트 홍보 등 직원 채용이 아닌 공고를 등록했을 때</p>
							<p>기타 관계법규에 위배되는 내용이거나 사이트의 원활한 운영을 위하여 운영자가 필요하다고 판단한 경우</p>
							
							</li>
							<li>
							<span class="first_letter">3.</span>"기업회원"이 유료서비스를 이용하는 중 "회사"의 책임에 의해 정상적인 서비스가 제공되지 않을 경우 "기업회원"은 본 서비스의 해지를 요청할 수 있으며 "회사"는 기간의 정함이 있는 유료서비스의 경우에는 해지일까지 이용일수를 1일 기준금액으로 계산하여 이용금액을 공제후 환급하고, 이용건수의 정함이 있는 유료서비스의 경우에는 기 사용분을 1건 기준금액으로 계산하여 이용금액을 공제후 환급한다. 단, 허위·불량공고의 경우 게재시 지불된 광고료는 일체 반환되지 않습니다.
							</li>
							<li>
							<span class="first_letter">4.</span>"회사"는 회원 가입이 해지된 경우 해당 회원 정보를 모두 삭제하여야 하며, 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
							</li>
							<li>
							<span class="first_letter">5.</span>개인정보보호를 위하여 "기업회원"이 선택한 개인정보 보유기간(1년, 3년, 회원탈퇴시) 동안 "사이트"를 이용하지 않은 경우, "아이디"를 "휴면계정"으로 분리하여 해당 계정의 이용을 중지할 수 있습니다. 이 경우 "회사"는 "휴면계정 처리 예정일"로부터 30일 이전에 해당사실을 전자메일, 서면, SMS 중 하나의 방법으로 사전통지하며 "회원"이 직접 본인확인을 거쳐, 다시 "사이트" 이용 의사표시를 한 경우에는 "사이트" 이용이 가능하다. "휴면계정"으로 분리 보관된 개인정보는 5년간 보관 후 지체없이 파기합니다. 
							
							</ol>
							<h3>
								<a name="a2">제 20 조 (허위 구인광고 경고) </a>
							</h3>
							<ol>
							<li>
							허위 구인광고로 인해 발생된 문제에 따른 법적인 책임은 모두 작성자에게 있으며, 구인광고 게재시에 지불된 광고료는 일체 반환되지 않습니다. 허위 구인광고 또는 허위 구인 조건을 제시한 자는 직업 안정법 제 47조에 의해 5년 이하의 징역 또는 2천만원 이하의 벌금형을 받게 됩니다.
							</li>
							
							</ol>
							<h3>
								<a name="a2">제 21 조 (손해배상)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>"회사"가 이 약관의 규정을 위반한 행위로 "기업회원"에게 손해를 입히거나 기타 "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임 있는 사유로 인해 "기업회원"에게 손해가 발생한 경우 "회사"는 그 손해를 배상하여야 합니다. 
							</li>
							<li>
							<span class="first_letter">2.</span>"회사"는 책임 있는 사유로 제공한 정보가 사실과 달라 "기업회원"이 손해를 입었을 경우에 "회사"는 그 손해를 배상하여야 합니다. 
							</li>
							<li>
							<span class="first_letter">3.</span>"기업회원"이 이 약관의 규정을 위반한 행위로 "회사" 및 제3자에게 손해를 입히거나 "기업회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌 경우에는 "기업회원"은 그 손해를 배상하여야 합니다.
							</li>
							<li>
							<span class="first_letter">4.</span>타 회원(개인회원, 기업회원 모두 포함)의 귀책사유로 "기업회원"의 손해가 발생한 경우 "회사"는 이에 대한 배상 책임이 없습니다.
							</li>
							</ol>
							<h3>
								<a name="a2">제 22 조 (양도 금지) </a>
							</h3>
							<ol>
							<li>
							"기업회원"의 서비스 받을 권리는 제3자에게 양도, 대여, 증여 등으로 사용할 수 없습니다.
							</li>
							
							</ol>
							<h3>
								<a name="a2">제 23 조 (이용요금 오류의 조정) </a>
							</h3>
							<ol>
							<li>
							"회사"는 이용요금과 관련하여 오류가 있는 경우에 "기업회원"의 요청, 또는 "회사"의 사전 통지에 의하여 다음에 해당하는 조치를 취합니다. 
							</li>
							<li>
							<span class="first_letter">1.</span>과다납입한 요금에 대하여는 그 금액을 반환한다. 다만, "기업회원"이 동의할 경우 다음 달에 청구할 요금에서 해당 금액만큼을 감하여 청구한다. 
							</li>
							<li>
							<span class="first_letter">2.</span>요금을 반환받아야 할 "기업회원"이 요금체납이 있는 경우에는 반환해야 할 요금에서 이를 우선 공제하고 반환한다.
							</li>
							<li>
							<span class="first_letter">3.</span>"회사"는 과소청구액에 대해서는 익월에 합산청구한다.
							</li>
							
							
							</ol>
							<h3>
								<a name="a2">제 24 조 ("회원"의 개인정보보호)</a>
							</h3>
							<ol>
							<li>
							"회사"는 "기업회원"의 개인정보보호를 위하여 노력해야 합니다. "기업회원"의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법에 따르고, "사이트"에 "개인정보처리방침"을 고지합니다.
							</li>
							
							</ol>
							<h3>
								<a name="a2">제 25 조 (신용정보의 제공 활용 동의)</a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span> "회사"가 회원가입과 관련하여 취득한 "기업회원"의 신용정보를 타인에게 제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고 해당 "기업회원"의 동의를 얻어야 합니다. 
							</li>
							<li>
							<span class="first_letter">2.</span>본 서비스와 관련하여 "회사"가 "기업회원"으로부터 신용정보의 이용 및 보호에 관한 법률에 따라 타인에게 제공 활용에 동의를 얻은 경우 "기업회원"은 "회사"가 신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 "기업회원"의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록 정보를 제공하는 데 동의한 것으로 간주합니다.
							</li>
							
							
							</ol>
							<h3>
								<a name="a2">제 26 조 (분쟁의 해결) </a>
							</h3>
							<ol>
							<li>
							<span class="first_letter">1.</span>"회사"와 "기업회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다. 
							</li>
							<li>
							<span class="first_letter">2.</span>전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 "회사"의 주소지 관할법원으로 합니다.
							</li>
							
							
							</ol>
							
							<h3>
								<a name="a2">부칙</a>
							</h3>
							<p>이 약관은 2023년 2월 1일부터 시행합니다.</p>
						</div>`,
    checked: false,
    required: true,
  },
  {
    type: "company",
    id: 5,
    label: "개인정보보호를 위한 정책 동의",
    term: `<div class="section customModify">
							<h3 class="first">
								<a name="a1">1. 총칙</a>
							</h3>
							<ol>
								<li>
								대티즌은(이하 "회사"라 합니다)는 이용자의 개인정보를 매우 소중히 생각합니다. 회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」을 비롯한 개인정보 관련 법령을 준수하고 있습니다. 이용자께서 알려주신 개인정보는 수집, 이용 동의를 받은 범위 내에서, 단말기 정보 등에 접근한다는 것을 고지, 승인 후 수집되며, 별도의 동의없이는 결코 제 3 자에게 제공되지 않습니다. 또한 기본적 인권 침해의 우려가 있는 민감한 개인정보는 수집하지 않습니다. 본 개인정보처리방침은 개인정보를 안전하게 처리하기 위한 회사의 정책관련 자세한 사항을 알려드리기 위해 마련되었으며 대티즌 및 또는 웹사이트 (‘http://www.detizen.com’이하’www.detizen.com’)에서 언제든 확인하실 수 있습니다.
								</li>
								
							</ol>
							<h3>
								<a name="a2">2. 개인정보의 처리목적</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로 이용되지 아니하며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
									<p>	• 웹사이트 또는 앱 서비스의 회원 가입 및 관리</p>
									<p>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 및 인증, 회원자격 유지와 관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지 및 통지, 고충처리 등을 목적으로 개인정보를 처리합니다.</p>
									<p>	• 재화 또는 서비스 제공</p>
									<p>물품배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제 및 정산, 포인트 적립 및 이용, 채권추심 등을 목적으로 개인정보를 처리합니다.</p>
									<p>	• 고충처리</p>
									<p>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락 및 통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.</p>
								</li>
								
							</ol>

							<h3>
								<a name="a2">3. 수집하는 개인정보의 항목</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>회사는 홈페이지, 서비스 이용, 이벤트 응모, 팩스, 우편, 전화, 고객센터 문의하기 등을 위해 아래와 같은 개인정보를 수집하고 있습니다. 
									<p>
									해당 서비스의 본질적 기능을 수행하기 위한 정보는 필수항목으로서 수집하며 이용자가 그 정보를 회사에 제공하지 않는 경우 서비스 이용에 제한이 가해질 수 있지만, 선택항목으로 수집되는 정보의 경우에는 이를 입력하지 않은 경우에도 서비스 이용제한은 없습니다. 추가항목은 휴대폰 감면권 신청, 기부금 영수증 발행 등의 경우 해당 신청자에 한해 추가적으로 수집합니다. 단, 만14세 미만 아동의 개인정보는 수집하지 않습니다.
									</p>
									
								</li>
								<li>
									<span class="first_letter">2.</span>수집항목, 이용목적, 보유기간
									<p> • 수집 항목</p>
									<p>1) 회원가입시 수집하는 항목 </p>
									<p>개인 회원 필수항목: 아이디, 패스워드, 휴대번호, 생년월일, 닉네임 및 e-메일 계정 정보 </p>
									<p>기업 회원 필수항목: 아이디, 패스워드, 담당자 이름, 휴대전화, 기업명, 사업자등록번호, 대표자, 기업기분, e-메일 </p>
									<p>2) 이벤트 경품 발송시 수집하는 항목 필수 항목: 이름, 휴대전화, 주민등록번호(근거 : 소득세법)</p>
									<p>3) 서비스 이용과정이나 사업처리 과정에서 자동 수집되는 항목 IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 광고 ID,접속 환경</p>
									<p> • 수집 및 이용목적 </p>
									<p>1) 회원관리 회원제 서비스 이용에 따른 본인확인, 본인의 의사확인, 고객문의에 대한 응답, 새로운 정보의 소개 및 고지사항 전달</p>
									<p>2) 서비스 제공에 관한 계약 이행 및 서비스 제공 부정 이용방지와 비인가 사용방지</p>
									<p>3) 서비스 개발 및 마케팅ㆍ광고 활용 맞춤 서비스 제공, 서비스 안내 및 이용권유, 서비스 개선 및 신규 서비스 개발을 위한 통계 및 접속빈도 파악, 통계학적 특성에 따른 광고, 이벤트 정보 및 참여기회 제공</p>
									<p>4) 고용 및 취업동향 파악을 위한 통계학적 분석, 서비스 고도화를 위한 데이터 분석</p>
									<p> • 보유기간 </p>
									<p>회원탈퇴 후 30일 이내 또는 법령에 따른 보존기간(단, 부정이용 확인 시 회원탈퇴 후 6 개월) 기타 거래처 뉴스레터 발송, 각종 이벤트, 마케팅, 설문조사 등을 위하여 해당 발송에 대한 수신동의를 받아 이메일을 수집하고 있으며, 각종 이벤트시 그 내용에 따라 추가 항목들을 제공받아 이용하고 있습니다. 그리고 중복가입방지, 문의사항처리, 부정 이용자 제재, 포인트 정산, 공지사항 전달, 분쟁조정을 위한 기록보존 등 회원관리와 신규 서비스 개발을 위한 통계적 분석을 위해 서비스 이용기록 등의 사항들을 저장하고 있습니다.</p>
								</li>
								<li>
									<span class="first_letter">3.</span>개인정보 수집방법
									<p> • 회사는 온라인 회원가입, 회원정보수정, 서면양식, 전화 또는 팩스, 서비스 이용, 제휴사로부터의 제공, 단말기를 통한 자동수집, 이메일과 같은 방법을 통하여 개인정보를 수집합니다.</p>
									<p> • 이용자의 사상, 신념, 과거의 병력 등 개인의 권리, 이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감정보는 수집하지 않으며, 회사는 이용자의 개인정보를 수집할 때, 사전에 해당 사실을 이용자에게 알리고 동의를 구합니다.</p>
									<p> • 다만, 이용자가 요구하는 서비스를 제공하는 과정에서 이를 이행하기 위하여 필요한 개인정보로서 경제적, 기술적 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우, 서비스 제공에 따른 요금정산을 위하여 필요한 경우, 기타 정보통신망법 또는 다른 법률에 특별한 규정이 있는 경우에는 동의를 받지 않고 이용자의 개인정보를 수집할 수 있습니다.</p>							
								</li>
							</ol>
							<h3>
								<a name="a2">4. 개인정보의 보유 및 이용기간</a>
							</h3>
							<ol>
								<li>
								필수항목/선택항목의 경우 회원가입일로부터 탈퇴 후 30일까지, 추가항목의 경우 이용 완료시까지 보유 후 파기하고 있고, 탈퇴하기 전이라도 최근 1년간 서비스를 사용하지 않은 이용자의 정보는 분리하여 파기하고 있습니다. 단, 법령에 의해 보존해야만 하는 경우 또는 불량이용자 제재와 관련 분쟁 방지를 위하여 아래와 같이 일정 기간 동안 회원정보를 보존합니다.
								<p>
								계약 또는 청약철회 등에 관한 기록 | 전자상거래 등에서의 소비자보호에 관한 법률 | 5년
								</p>
								<p>
								소비자의 불만 또는 분쟁처리에 관한 기록 | 전자상거래 등에서의 소비자보호에 관한 법률 | 5년
								</p>
								<p>
								대금결제 및 재화 등의 공급에 관한 기록 | 전자상거래 등에서의 소비자보호에 관한 법률 | 5년
								</p>
								<p>
								전자금융 거래에 관한 기록 | 전자금융거래법 | 1년 또는 5년
								</p>
								<p>
								서비스 방문기록, 접속로그, 접속아이피 | 통신비밀보호법 | 3개월
								</p>
								</li>
								
							</ol>
							<h3>
								<a name="a2">
								5. 개인정보의 파기 절차 및 방법
								</a>
							</h3>
							<ol>
								<li>
								회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
								</li>
								<li>
									<span class="first_letter">1.</span>파기절차
									<p>
									이용자가 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB 로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB 로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
									</p>
								</li>
								<li>
									<span class="first_letter">2.</span>파기기한
									<p>
									이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유 기간의 종료일로부터 30일 이내에 파기하며 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 30일 이내에 그 개인정보를 파기합니다.
									</p>
								</li>
								<li>
									<span class="first_letter">3.</span>파기방법
									<p>
									종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며,
									</p>
								</li>
								<li>
									<span class="first_letter">4.</span>장기 미이용자의 개인정보 파기
									<p>
									정보통신망법에 따라 회원가입 후 서비스 이용이 없는 고객 및 12개월 이상 로그인하지 않은 경우 해당 이용자 ID 및 개인정보를 유효기간 경과 후 즉시 파기하거나, 분리하여 보관합니다. 이러한 경우 위 12개월의 기간 도래 30일 전까지 회사는 이용자의 개인정보가 분리되어 저장, 관리되고 있다는 사실과 기간 만료일 및 해당 개인정보의 항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한 방법 중 어느 하나의 방법으로 이용자에게 알려드립니다. 명시한 기한 내에 로그인 하지 않거나 서비스 이용이 없는 경우에는 회원자격을 상실시킬 수 있습니다. 이 경우, 회원 아이디를 포함한 회원의 개인정보 및 서비스 이용 정보는 파기, 삭제됩니다.
									</p>
								</li>
							</ol>
							<h3>
								<a name="a2">6. 이용자 및 법정대리인의 권리와 그 행사방법</a>
							</h3>
							<ol>
								<li>
								회사는 이용자 및 법정대리인의 권리를 다음과 같이 보호하고 있습니다.
								</li>
								<li>
									<span class="first_letter">1.</span>언제든지 자신의 개인정보를 조회하고 수정할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">2.</span>언제든지 개인정보 제공에 관한 동의철회/회원가입 해지를 요청할 수 있습니다.
								</li>
								<li>
									<span class="first_letter">3.</span>정확한 개인정보의 이용 및 제공을 위해 이용자가 개인정보 수정 진행 시 수정이 완료될 때까지 이용자의 개인정보는 이용되거나 제공되지 않습니다. 이미 제 3 자에게 제공된 경우에는 지체 없이 제공받은 자에게 사실을 알려 수정이 이루어질 수 있도록 하겠습니다.
								</li>
								<li>
								<p>권리 행사는 고객센터에서 할 수 있으며 서면, 전화, 전자우편, 등을 통해서도 하실 수 있습니다.</p>
								<p>서면, 전화, 전자우편, 등을 통해 요청하는 경우 개인정보보호법 시행규칙 별지 제8호 서식에 따라 개인정보(열람,정정,삭제,처리정지) 요구서를 제출하시면 이에 대해 지체없이(10 일 이내) 조치해 드릴 것 입니다. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한, 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</p>
								<p>회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "4. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>
								<p>또한 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제 11 호 서식에 따른 위임장을 제출하셔야 합니다.</p>
								</li>
							</ol>
							<h3>
								<a name="a2">
								7. 개인정보 자동수집장치의 설치, 운영 및 거부에 관한 사항
								</a>
							</h3>
							<ol>
								<li>
								회사는 이용자의 정보를 수시로 저장하고 찾아내는 "쿠키(cookie)"와 "세션"을 운용합니다. "쿠키"란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. "세션"이란 웹사이트를 운영하는데 이용되는 서버가 이용자 로그인 시간 동안에 이용자의 정보를 서버에 저장하는 것을 의미합니다. 회사는 다음과 같은 목적을 위해 쿠키와 세션을 사용합니다.
								</li>
								<li>
									<span class="first_letter">1.</span>쿠키와 세션의 사용 목적
									<p>회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 "세션(session)"을 사용합니다. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</p>
								</li>
								<li>
									<span class="first_letter">2.</span>쿠키 설정 거부 방법
									<p>
									쿠키 설정을 거부하는 방법으로는 이용자가 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
인터넷 익스플로러의 경우 : 웹 브라우저 상단의 도구 -> 인터넷 옵션 -> 개인정보 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
									</p>
								</li>
								<li>
									<span class="first_letter">3.</span>세션의 설치/운영 및 거부
									<p>이용자는 세션 설치에 대한 선택권을 가지고 있지 않습니다. 로그인이 필요한 회사의 서비스의 경우 회사 운영 서버에서 자동으로 세션이 생성됩니다.</p>
								</li>
							</ol>
							<h3>
								<a name="a2">8. 개인정보의 기술적, 관리적 보호대책</a>
							</h3>
							<ol>
								<li>
								회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
								</li>
								<li>
									<span class="first_letter">1.</span>개인정보 암호화
									<p>이용자의 개인정보(성별 등)는 암호화하여 저장/관리되고 있으며, 암호화통신(SSL) 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 비밀번호는 복호화가 불가능한 일방향 암호화되어 저장/관리되고 있습니다.</p>
								</li>
								<li>
									<span class="first_letter">2.</span>해킹 등에 대비한 대책
									<p>
									회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 또한 개인정보의 훼손에 대비해서 자료를 수시로 백업하고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신(SSL) 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려고 노력하고 있습니다.
									</p>
								</li>
								<li>
									<span class="first_letter">3.</span>처리 직원의 최소화 및 교육실시
									개인정보를 처리하는 직원을 최소화 하며, 업무용 PC 에서는 외부 인터넷 서비스를 사용할 수 없도록 차단하여 개인정보 유출에 대한 위험을 줄이고 있습니다. 또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다. 또한 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.
								</li>
							</ol>

							<h3>
								<a name="a2">
								9. 개인정보에 관한 민원서비스
								</a>
							</h3>

							<ol>
								<li>
								회사는 개인정보보호 전담 조직 운영을 통하여 개인정보 처리방침의 이행사항 및 담당자의 준수여부를 확인하고 있으며, 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
								<p>개인정보보호 책임자</p>	
								<p>책임자 : 한성일 직위 : 대표</p>	
								<p>문의 : detizen @detizen.com</p>	
								<p>또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.</p>	
								<p></p>	
								<p>개인정보침해 신고센터 (국번없이) 118 https://privacy.kisa.or.kr</p>	
								<p>대검찰청 사이버수사과(국번없이) 1301 http://www.spo.go.kr</p>	
								<p>경찰청 사이버안전국 (국번없이) 182 http://cyberbureau.police.go.kr/</p>	
								</li>
							</ol>

							<h3>
								<a name="a2">
								10. 기타 개인정보 처리에 관한 방침
								</a>
							</h3>
							<ol>
								<li>
									<span class="first_letter">1.</span>부가서비스 이용에 따른 개인정보 처리방침
									<p>
									회사가 제공하는 부가서비스 중 회원가입이 필요한 서비스의 경우 개인정보 처리방침에 대한 동의를 따로 받습니다.
									</p>
									
								</li>
								<li>
									<span class="first_letter">2.</span>개인 아이디와 비밀번호 관리
									<p>
									이용자가 사용하고 계시는 ID 와 비밀번호는 원칙적으로 이용자만이 사용하도록 되어 있습니다. 회사의 고의 또는 과실이 없는 경우에, 이용자의 ID 와 비밀번호 도용 또는 기타 타인의 사용으로 인해 발생된 문제에 대하여 회사가 책임지지 않습니다. 어떠한 경우에도 비밀번호는 타인에게 알려 주지 마시고 로그온 상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록 특별한 주의를 기울여 주시기 바랍니다. 타인의 개인정보를 도용하여 회원 가입 또는 계약이 확인되었을 경우에는 이용계약이 일방적으로 해지될 수 있으며, 주민등록법에 의해 3년 이하의 징역 또는 1천만원 이하의 벌금이 부과될 수 있습니다.
									</p>
									
								</li>
							</ol>
							<h3>
								<a name="a2">
								11. 고지의무
								</a>
							</h3>
							<ol>
								<li>
								현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 시행일자 최소 7 일전부터 대티즌 웹사이트 http://www.detizen.com "공지사항" 화면을 통해 공고할 것입니다.
								<p>공고일자 : 2023년 1월 30일</p>	
								<p>시행일자 : 2023년 1월 30일</p>	
								</li>
								
							</ol>
							
						</div>`,
    checked: false,
    required: true,
  },
  {
    type: "company",
    id: 6,
    label: "마케팅 정보 이메일/휴대폰 문자 수신동의 (선택)",
    term: `<div class="section customModify">
							<h3 class="first">
								<a name="a1">책임의 한계</a>
							</h3>
							<p>
								
								대티즌은 링크, 다운로드, 광고 등을 포함하여 본 웹 사이트에
								포함되어 있거나, 본 웹 사이트를 통해 배포, 전송되거나, 본 웹
								사이트에 포함되어 있는 서비스로부터 접근되는 정보(이하 "자료")의
								정확성이나 신뢰성에 대해 어떠한 보증도 하지 않으며, 서비스상의,
								또는 서비스와 관련된 광고, 기타 정보 또는 제안의 결과로서
								디스플레이, 구매 또는 취득하게 되는 제품 또는 기타 정보(이하
								"제품")의 질에 대해서도 어떠한 보증도 하지 않습니다.
							</p>
							<br />
							<p>
								귀하는, 자료에 대한 신뢰 여부가 전적으로 귀하의 책임임을
								인정합니다. 대티즌은 자료 및 서비스의 내용을 수정할 의무를 지지
								않으나, 필요에 따라 개선할 수는 있습니다.대티즌은 자료와
								서비스를 "있는 그대로" 제공하며, 서비스 또는 기타 자료 및 제품과
								관련하여 상품성, 특정 목적에의 적합성에 대한 보증을 포함하되
								이에 제한되지 않고 모든 명시적 또는 묵시적인 보증을 명시적으로
								부인합니다.
							</p>
							<br />
							<p>
								어떠한 경우에도 대티즌은 서비스, 자료 및 제품과 관련하여 직접,
								간접, 부수적, 징벌적, 파생적인 손해에 대해서 책임을 지지
								않습니다. 대티즌은 본 웹사이트를 통하여 인터넷을 접속함에 있어
								사용자의 개인적인 판단에 따라 하시기를 바랍니다. 본 웹사이트를
								통해 일부 사람들이 불쾌하거나 부적절 하다고 여기는 정보가
								포함되어 있는 사이트로 연결될 수 있습니다. 대티즌은 본 웹사이트
								또는 자료에 열거되어 있는 사이트의 내용을 검토하려는 노력과
								관련하여 어떠한 보증도 하지 않습니다. 또한 대티즌은 본 웹사이트
								또는 자료에 열거되어 있는 사이트상의 자료의 정확성, 저작권 준수,
								적법성 또는 도덕성에 대해 아무런 책임을 지지 않습니다.
							</p>
							<br />
							<p>
								대티즌은 지적재산권을 포함한 타인의 권리를 존중하며, 사용자들도
								마찬가지로 행동해주시기를 기대합니다. 대티즌은 필요한 경우 그
								재량에 의해 타인의 권리를 침해하거나 위반하는 사용자에 대하여
								사전 통지 없이 서비스 이용 제한 조치를 취할 수 있습니다.
							</p>
							<h3>
								<a name="a2">상표에 관한 정보</a>
							</h3>
							<p>
								본 웹사이트에 포함된 제품 및/또는 서비스의 명칭은 대티즌의 상표
								또는 등록 상표입니다.
							</p>
							<p>
								회사는 이에 대한 권리를 가지고 있으며, 다른 제품 및 회사의
								명칭은 그 해당 사용자들의 상표일 수 있습니다.
							</p>
						</div>`,
    checked: false,
    required: false,
  },
];
