import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;
  position: relative;
  .cLabel {
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    > span {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }

  .cUserBasic {
    padding: 0.25em 0.5em;
    margin-bottom: 0.25em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    > ul {
      display: block;

      > li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.55em;
        color: white;
        padding: 0.25em 0;
        margin: 0.25em 0;
        > label {
          font-weight: 300;
          width: 9em;
          margin-right: 2em;
        }

        .cUserAddon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: calc((100% - 11em));
        }
      }
      .cUserBtns {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
        > button {
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          background-color: transparent;
          color: white;
          > span {
            color: gray;
            padding: 0.25em 0;
            font-size: 0.55em;
          }
        }
      }
    }
  }

  .cUserCnt {
    padding: 0.25em 0.5em;
    margin-bottom: 0.25em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    > ul {
      display: flex;
      justify-content: space-around;
      align-items: center;

      > li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.55em;
        color: gray;
        &.active {
          color: white;
          border-bottom: 5px solid #e9360c;
        }
      }
    }
  }
  .cUserItems {
    > ul {
      color: white;

      padding: 0.25em 0.25em;
      > li {
        font-size: 0.55em;
        padding: 0.5em 0;
        margin: 0.5em 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .cUserScrapTitle {
        }
        .cUserScrapHost {
          color: gray;
        }
        .cUserDeadLine {
          text-align: right;
          color: gray;
        }
        &.noContents {
          border-bottom: none;
          .cUserNoContents {
            text-align: center;
          }
        }
        &.cUserLiTab {
          padding: 0em 0;
          margin: 0em 0;
          font-size: 1rem;
          border-bottom: unset;
          display: flex;
          justify-content: space-around;
          align-items: center;
          > span {
            text-align: center;
            width: 100%;
            opacity: 0.5;
            &.active {
              opacity: 1;
            }
          }
        }
        .cUserBoardWrap {
          .cUserBoards {
            .cUserBoardTitle {
            }
            .cUserBoardEtc {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: gray;
              padding: 0.25em 0;
              margin: 0.5em 0;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              .cUserViewCntArea {
                display: flex;
                justify-content: center;
                align-items: center;
                > span {
                  margin-left: 0.5em;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MyPageHeader = ({ children }) => {
  return (
    <Wrap>
      <div className={"cLabel"}>
        <span>마이 페이지</span>
      </div>
      {children}
    </Wrap>
  );
};

export const MUserFreeContest = styled.div`
  margin: 0.25em 0;
  padding: 0.25em 0;
`;
export const MUserFreeLabelUl = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.55em;
  padding: 0.25em 0.5em;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;
export const MUserFreeLabelLi = styled.li``;
export const MUserFreeLabel = styled.span`
  color: ${(props) => (props.active ? `#ffffff` : `gray`)};
  ${(props) => props.active && `border-bottom:4px solid red`}
`;

export const MUserFreeList = styled.div``;
