import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import {
  DContentsSubMenu,
  DContentView,
} from "../../components/DContents/Common/Element";
import EventSubmitResultViewPc from "../../components/DContents/Event/EventSubmitResultViewPc";
import EventHeader from "../../components/events/header";

const EventSubmitResultView = () => {
  const { id } = useParams();

  return (
    <div className={"container sub"}>
      <EventHeader />
      <DContentView label={"이벤트 당첨 확인"}>
        <div className={"board_view qna"}>
          <Suspense fallback={<div></div>}>
            <EventSubmitResultViewPc id={id} />
          </Suspense>
        </div>
      </DContentView>
    </div>
  );
};

export default EventSubmitResultView;
