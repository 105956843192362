import React from "react";
import { Link } from "react-router-dom";
import {
  HiSearch,
  HiOutlineUser,
  HiOutlineMenu,
  HiOutlineX,
} from "react-icons/hi";
import { useRecoilState } from "recoil";
import { bgColorSelect } from "../../recoil/AtomConfig";
const MainBtn = ({ state, open, setOpen }) => {
  // console.log(state);
  // console.log("check");
  const [bg] = useRecoilState(bgColorSelect);
  return (
    <>
      {/* <Link to={`/search`}>
        <button type="button" style={{ background: "none", border: "none" }}>
          <HiSearch color="white" />
        </button>
      </Link> */}
      {state.id && state.accessToken ? (
        <>
          <Link to={`/user/${state.id}`} style={{ cursor: "pointer" }}>
            <button
              type="button"
              style={{ background: "none", border: "none" }}
            >
              <HiOutlineUser
                color={bg.change ? "black" : "white"}
                style={{ cursor: "pointer" }}
              />
            </button>
          </Link>
          <a
            onClick={() => setOpen(!open)}
            style={{ zIndex: "12", lineHeight: "1px" }}
          >
            {open ? (
              <HiOutlineX color="white" />
            ) : (
              <HiOutlineMenu color={bg.change ? "black" : "white"} />
            )}
          </a>
        </>
      ) : (
        <>
          <Link to={`/signin`} style={{ cursor: "pointer" }}>
            <button
              type="button"
              style={{ background: "none", border: "none" }}
            >
              <HiOutlineUser color="red" style={{ cursor: "pointer" }} />
            </button>
          </Link>
          <a
            onClick={() => setOpen(!open)}
            style={{ zIndex: "12", lineHeight: "1px" }}
          >
            {open ? (
              <HiOutlineX color="white" />
            ) : (
              <HiOutlineMenu color={bg.change ? "black" : "white"} />
            )}
          </a>
        </>
      )}
    </>
  );
};

export default MainBtn;
