import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import custom from "../../assets/css/header.module.scss";
import { isMobile } from "react-device-detect";

import styled, { css } from "styled-components";
import { HiArrowCircleLeft, HiArrowCircleRight } from "react-icons/hi";
import moment from "moment";
import { useRecoilState } from "recoil";
import { bgColorSelect } from "../../recoil/AtomConfig";
import ReactGA4 from "react-ga4";
import { NormalReqApi } from "../../utils/ReqApi";

const CustomArrayWrap = styled.div`
  position: absolute;
  margin-top: 1.75em;
  height: ${(props) => (props.type === "spb" ? "336px" : "150px")};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CustomArrow = styled.div`
  position: absolute;
  ${(props) =>
    props.dir === "right"
      ? css`
          right: -2em;
        `
      : css`
          left: -2em;
        `}
  cursor:pointer;
`;

const HeaderS = styled.div`
  max-width: 900px !important;
  padding: 1em 0.25em !important;
  background-color: rgba(255, 255, 255, 0.8);
`;
const TitleS = styled.span`
  width: 100% !important;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black !important;
`;
const SubText = styled.span`
  color: black !important;
`;
const SubB = styled.b`
  border: 1px solid black !important;
`;
const SubA = styled.a`
  color: black !important;
  border: 1px solid black !important;
`;

export const DetailSlider = ({
  type,
  settings,
  className,
  detailClassName,
  setSlider,
  asNavFor,
  list,
  loading,
}) => {
  const sliderRef = useRef(null);
  const navigator = useNavigate();

  const movePage = (type, id, banner_id, bannerType) => {
    ReactGA4.event("click", {
      click_text: "banner_cta_clicked",
      click_contest_type: type,
      click_contest_id: id,
      click_contest_bannerType: bannerType,
      click_banner_id: banner_id,
    });

    if (banner_id) {
      NormalReqApi(
        "post",
        `/banner/viewcnt/${
          bannerType === "top"
            ? 0
            : bannerType === "spa"
            ? 1
            : bannerType === "spb"
            ? 2
            : bannerType === "band"
            ? 3
            : ""
        }/${banner_id}`,
        {}
      );
    }

    const url = `/contest/view/${type}/${id}`;
    // const success = (e) => {
    //   console.log(e);
    // };
    // const fail = (e) => {
    //   console.log(e);
    // };
    // window.navigator.geolocation.getCurrentPosition(success, fail);
    navigator(url, {
      state: {
        isOnChain: type == 2,
      },
    });
  };

  useEffect(() => {
    setSlider(sliderRef.current);
  }, [sliderRef]);

  const dateRender = (startDate, endDate) => {
    const sDate = moment(startDate);
    const eDate = moment(endDate);
    const nDate = moment();

    if (nDate.isBefore(sDate)) {
      return `D ${nDate.diff(sDate, "days") * -1} `;
    } else if (nDate.isAfter(sDate) && nDate.isBefore(eDate)) {
      return "진행중";
    } else if (nDate.isAfter(eDate)) {
      return "마감";
    }
  };

  return (
    <Slider
      {...settings}
      className={className}
      ref={sliderRef}
      asNavFor={asNavFor}
    >
      {list.length > 0 &&
        list.map((item, index) => {
          if (index === 0 && type === "top") {
            // changeBgSet(item.changeColorYn);
          }
          console.log(item);

          // bgChange(item.changeColorYn);
          return (
            <div
              className={detailClassName + (item.changeColorYn ? " wbg" : "")}
              key={`top_detail_${index}`}
            >
              {isMobile ? (
                <>
                  <HeaderS
                    role="test"
                    className={`info_area ${custom.info_area} ${
                      type !== "top" && custom.spa
                    }`}
                  >
                    <TitleS className={`info_title ${custom.info_title}`}>
                      <small>{item.contest.hostName}</small>
                      {item.title}
                    </TitleS>
                    <SubText className={"info_date"}>
                      {moment(item.contest.startDate).format("YYYY-MM-DD") +
                        " ~ " +
                        moment(item.contest.endDate).format("YYYY-MM-DD")}
                      <SubText className={`info_Dday ${custom.info_Dday}`}>
                        <SubB>
                          {dateRender(
                            item.contest.startDate,
                            item.contest.endDate
                          )}
                        </SubB>
                      </SubText>
                    </SubText>
                    <SubText className="info_list1">{`분야 | ${item.contest.category[0]}`}</SubText>
                    <SubText
                      className={"info_list1"}
                    >{`대상 | ${item.contest.target[0]}`}</SubText>
                    <SubA
                      onClick={() =>
                        movePage(
                          item.contest.type,
                          item.contest.id,
                          item.id,
                          type
                        )
                      }
                    >
                      자세히보기
                    </SubA>
                  </HeaderS>
                  <img
                    className={`detailImg ${custom.detailImg}`}
                    src={item.background}
                  />
                </>
              ) : (
                <>
                  <div
                    className={`info_area ${custom.info_area} ${
                      type !== "top" && custom.spa
                    }`}
                  >
                    <span
                      className={`info_title ${custom.info_title} ${
                        loading ? custom.custom_loading : ""
                      }`}
                    >
                      <small>{item.contest.hostname}</small>
                      {loading ? "" : item.title}
                    </span>
                    <span
                      className={`info_date ${
                        loading && custom.custom_loading_date
                      }`}
                    >
                      {loading ? (
                        ""
                      ) : (
                        <>
                          {moment(item.contest.startDate).format("YYYY-MM-DD") +
                            " ~ " +
                            moment(item.contest.endDate).format("YYYY-MM-DD")}
                          <span className={`info_Dday ${custom.info_Dday}`}>
                            <b>
                              {dateRender(
                                item.contest.startDate,
                                item.contest.endDate
                              )}
                            </b>
                          </span>
                        </>
                      )}
                    </span>
                    <span
                      className={`info_list1 ${
                        loading && custom.custom_loading_etc
                      }`}
                    >
                      {loading ? "" : `분야 | ${item.contest.category[0]}`}
                    </span>
                    <span
                      className={`info_list1 ${
                        loading && custom.custom_loading_etc
                      }`}
                    >
                      {loading ? "" : `대상 | ${item.contest.target[0]}`}
                    </span>
                    <a
                      onClick={() =>
                        movePage(
                          item.contest.type,
                          item.contest.id,
                          item.id,
                          type
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      자세히보기
                    </a>
                  </div>
                  <img
                    className={`detailImg ${custom.detailImg}`}
                    src={item.background}
                  />
                </>
              )}
            </div>
          );
        })}

      {/* <div className={detailClassName}>
        <img src={Default280} />
      </div> */}
    </Slider>
  );
};

export const ThumbSlider = ({
  type,
  settings,
  className,
  thumbClassName,
  setSlider,
  asNavFor,
  list,
  loading,
}) => {
  const sliderRef = useRef(null);
  const [bg] = useRecoilState(bgColorSelect);

  useEffect(() => {
    setSlider(sliderRef.current);
  }, [sliderRef]);

  const [totalCnt, setTotalCnt] = useState(0);

  const handleNext = () => {
    // const curIndex = sliderRef.current.innerSlider.asNavForIndex;
    // console.log(curIndex, totalCnt);
    // if (curIndex) {
    //   if (curIndex >= totalCnt) {
    //     // sliderRef.current.slickGoTo(0);
    //     sliderRef.current.slickNext();
    //   } else {
    //     sliderRef.current.slickNext();
    //   }
    // } else {
    //   if (totalCnt > 0) {

    //   }
    // }
    sliderRef.current.slickNext();
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    if (list.length > 0) {
      setTotalCnt(list.length - 1);
    } else {
      setTotalCnt(0);
    }
  }, [list]);
  return (
    <>
      <CustomArrayWrap>
        <CustomArrow dir="left" onClick={handlePrev}>
          {type === "top" ? (
            <HiArrowCircleLeft color={bg.change ? "black" : "white"} />
          ) : (
            <HiArrowCircleLeft color={"white"} />
          )}
        </CustomArrow>
        <CustomArrow dir="right" onClick={handleNext}>
          {type === "top" ? (
            <HiArrowCircleRight color={bg.change ? "black" : "white"} />
          ) : (
            <HiArrowCircleRight color={"white"} />
          )}
        </CustomArrow>
      </CustomArrayWrap>
      <Slider
        {...settings}
        className={className}
        //   thumbClassName={thumbClassName}
        ref={sliderRef}
        asNavFor={asNavFor}
      >
        {list.length > 0 &&
          list.map((item, index) => (
            <div className={thumbClassName} key={`top_${index}`}>
              {isMobile ? (
                <img
                  src={item.thumbnail}
                  style={{ width: "100%", height: "107px" }}
                />
              ) : (
                <img
                  src={item.thumbnail}
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: loading ? "cover" : "unset",
                  }}
                />
              )}

              {!isMobile && (
                <div className={`info_area ${custom.info_area}`}>
                  <span className={`info_title ${custom.info_title}`}>
                    <small>{item.contest.hostname}</small>
                    {item.title}
                  </span>
                  <span className="info_list1">{`[분야] ${item.contest.category[0]}`}</span>
                  <span className="info_date"></span>
                </div>
              )}
            </div>
          ))}
      </Slider>
    </>
  );
};

export const BannerSlider = ({ settings, className, list }) => {
  const navi = useNavigate();
  const slider = useRef(null);

  if (isMobile) {
    settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      dots: false,
      infinite: false,
      centerMode: false,
      focusOnSelect: true,
      arrows: false,
    };
  }

  const handlePrev = () => {
    slider.current.slickPrev();
  };
  const handleNext = () => {
    slider.current.slickNext();
  };

  const ga4Controll = (cType, cId, id) => {
    ReactGA4.event("click", {
      click_text: "banner_cta_clicked",
      click_contest_type: cType,
      click_contest_id: cId,
      click_contest_bannerType: "spb",
      click_banner_id: id,
    });
    NormalReqApi("post", `/banner/viewcnt/2/${id}`);
  };
  return (
    <>
      <CustomArrayWrap type="spb">
        <CustomArrow dir="left" onClick={handlePrev}>
          <HiArrowCircleLeft color={"white"} />
        </CustomArrow>
        <CustomArrow dir="right" onClick={handleNext}>
          <HiArrowCircleRight color={"white"} />
        </CustomArrow>
      </CustomArrayWrap>
      <Slider {...settings} className={className} ref={slider}>
        {list.length > 0 &&
          list.map((item, index) => (
            <div
              key={`spb_${index}`}
              className="thumbList"
              style={{ height: isMobile ? "107px" : "150px" }}
            >
              <Link
                onClick={() =>
                  ga4Controll(item.contest.type, item.contest.id, item.id)
                }
                to={`/contest/view/${item.contest.type}/${item.contest.id}`}
                state={{ isOnChain: item.contest.type === 2 }}
              >
                <div className="th_overlay"></div>
                {isMobile ? (
                  <img
                    src={item.thumbnail}
                    style={{ width: "100%", height: "107px" }}
                  />
                ) : (
                  <img
                    src={item.thumbnail}
                    style={{ width: "100%", height: "150px" }}
                  />
                )}

                {isMobile ? (
                  <div className="info_area mo" style={{ height: "107px" }}>
                    <span className="info_title">
                      <small>{item.contest.hostname}</small>
                      {item.title}
                    </span>
                    <span
                      className={"info_list1"}
                    >{`분야 ${item.contest.category[0]}`}</span>
                  </div>
                ) : (
                  <div className="info_area">
                    <span className="info_title">
                      <small>{item.contest.hostname}</small>
                      {item.title}
                    </span>
                    <span
                      className={"info_list1"}
                    >{`분야 ${item.contest.category[0]}`}</span>
                  </div>
                )}
              </Link>
            </div>
          ))}
      </Slider>
    </>
  );
};

export const BandSlider = ({ settings, className, list, aClassName }) => {
  // console.log(list);
  const slideRef = useRef(null);
  const handleNext = () => {
    slideRef.current.slickNext();
  };
  const handlePrev = () => {
    slideRef.current.slickPrev();
  };

  const ga4Controll = (cType, cId, id) => {
    ReactGA4.event("click", {
      click_text: "banner_cta_clicked",
      click_contest_type: cType,
      click_contest_id: cId,
      click_contest_bannerType: "band",
      click_banner_id: id,
    });

    NormalReqApi("post", `/banner/viewcnt/3/${id}`);
  };

  return (
    <>
      <CustomArrayWrap>
        <CustomArrow dir="left" onClick={handlePrev}>
          <HiArrowCircleLeft color={"white"} />
        </CustomArrow>
        <CustomArrow dir="right" onClick={handleNext}>
          <HiArrowCircleRight color={"white"} />
        </CustomArrow>
      </CustomArrayWrap>
      <Slider {...settings} className={className}>
        {list.length > 0 &&
          list.map((item, index) => (
            <Link
              onClick={() =>
                ga4Controll(item.contest.type, item.contest.id, item.id)
              }
              to={`/contest/view/${item.contest.type}/${item.contest.id}`}
              state={{ isOnChain: item.contest.type == 2 }}
              key={`band_${index}`}
              className={aClassName}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={item.thumbnail} />
            </Link>
          ))}
      </Slider>
    </>
  );
};
