import React from "react";
import { Link } from "react-router-dom";
import custom from "../../assets/css/login.module.scss";
import Logo from "../../assets/img/common/logo.png";
import { HiOutlineHome } from "react-icons/hi";

export const LoginHeader = ({ children }) => {
  return (
    <div
      className={"wrap " + custom.wrap + " " + custom.login + " " + custom.pass}
    >
      <div className={custom.login_area}>
        <div
          className={custom.login_top}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: "1em",
          }}
        >
          <Link to={`/`}>
            <img src={Logo} />
          </Link>
          <Link to={`/`} className={custom.goHome}>
            <HiOutlineHome color="black" size={30} />
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};
