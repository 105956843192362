import React from "react";
import hcStyle from "../../assets/css/req2.module.scss";

const HostCenterSection2 = () => {
  return (
    <div
      className={`sub_container white ${hcStyle.sub_container} ${hcStyle.white}`}
    >
      <div
        className={`contents mypage ${hcStyle.contents} ${hcStyle.mypage} ${hcStyle.middle}`}
      >
        <div className={`freeReg_title ${hcStyle.freeReg_title}`}>
          <span>공고 등록 시 아래의 유의사항을 확인하세요!</span>
        </div>
        <ul className="warning_list">
          <li>
            <span>
              공모전 및 대외활동 정보만 게시할 수 있습니다. 동아리, 스터디, 구인
              등의 정보는 다른 커뮤니티를 이용해주세요.
            </span>
          </li>
          <li>
            <span>
              대외활동의 경우 봉사활동, 기자단, 서포터즈, 홍보대사, 마케터,
              강연에 해당하는 대외활동만 게시할 수 있습니다.
            </span>
          </li>
          <li>
            <span>다음과 같은 공모전·대외활동은 거절될 수 있습니다.</span>
            <p>
              서비스 홍보를 요구하는 공모전·대외활동 (회원가입, 설치, 작성, 공유
              등)
            </p>
            <p>
              서비스 이용을 요구하는 공모전·대외활동 (콘텐츠 제작, 멘토링,
              운영관리 등)
            </p>
            <p>병의원 관련사의 공모전·대외활동</p>
            <p>
              기관/정치인/정당/종교단체와 직간접적으로 연관되거나, 관련한
              온·오프라인 활동을 하는 공모전·대외활동
            </p>
            <p>금융/보험 관련사의 영업/실무와 연관된 공모전·대외활동</p>
            <p>교육 위탁/국비지원/국가지원사업과 연관된 공모전·대외활동</p>
            <p>인력 모집을 위한 구인/구직 목적의 공모전·대외활동</p>
            <p>비용이 발생하는 공모전·대외활동 (참가비, 후원비 등)</p>
            <p>활동 참여의 대가 및 혜택이 불분명한 공모전·대외활동</p>
            <p>
              총 상금이 100만원 이하이거나, 상금에 준하는 증권이 아닌 현물을
              제공하는 공모전·대외활동
            </p>
            <p>특정 대학교만 참여할 수 있는 공모전·대외활동</p>
          </li>
          <li>
            <span>다음과 같은 게시 요청은 게시되지 않습니다.</span>
            <p>동일한 정보가 이미 게시되어 있을 경우</p>
            <p>
              운영 주체, 모집 기간, 활동 내용 등 상세 정보가 명확하지 않거나
              불분명한 경우
            </p>
            <p>
              그 외 커뮤니티 이용 규칙에 어긋나거나, 커뮤니티 성격에
              부적합하다고 판단되는 경우
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HostCenterSection2;
