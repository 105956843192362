import React from "react";
import { NormalReqApi } from "./ReqApi";

const GetChallenge = async () => {
  const returnObject = {
    loading: false,
  };
  const tList = await NormalReqApi("get", `teamplay/top4`);

  if (tList.statusCode === 200) {
    returnObject.team = {
      loading: false,
      data: [...tList.result],
    };
  }

  const aList = await NormalReqApi("get", `after/top4`);
  if (aList.statusCode === 200) {
    returnObject.after = {
      loading: false,
      data: [...aList.result],
    };
  }

  const qList = await NormalReqApi("get", `qna/top4`);
  if (qList.statusCode === 200) {
    returnObject.qna = {
      loading: false,
      data: [...qList.result],
    };
  }

  const fList = await NormalReqApi("get", `freeboard/top4`);
  if (fList.statusCode === 200) {
    returnObject.free = {
      loading: false,
      data: [...fList.result],
    };
  }

  return returnObject;
};

export default GetChallenge;
