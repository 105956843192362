import React, { Suspense, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../../recoil/AtomConfig";
import { ClientReqGet } from "../../../utils/ClientAuth";
import styled from "styled-components";
import { Loading } from "../../../components/DContents/Mobile/MTemplate";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const FreeTab = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const FreeTabLi = styled.li`
  padding: 0.15em 0.25em 0.15em 0;
  margin: 0.15em 0.25em 0.15em 0;
  font-size: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.active ? `#000` : `#a0a0a0`)};
  ${(props) => props.active && `border-bottom:2px solid red`};
`;

const FreeUl = styled.ul``;
const Freeli = styled.li`
  font-size: 0.75em;
  padding: 0.25em 0;
  margin: 0.25em 0;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
`;
const FreeStatus = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const FreeLabel = styled.span`
  font-size: 0.8em;
  margin-right: 0.5em;
  color: #a0a0a0;
`;
const FreeCurStatus = styled.span`
  font-size: 0.7em;
  padding: 0.15em 0.3em;
  color: #ffffff;
  background-color: ${(props) => (props.confirm ? `green` : `gray`)};
  border-radius: 5px;
`;
const FreeTitle = styled.p`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
const FreeDate = styled.p`
  text-align: right;
  font-size: 0.75em;
  color: #a0a0a0;
`;

const MyFreeReq = ({ account, toggle }) => {
  const navi = useNavigate();
  const { data, error, isLoading } = useQuery(
    ["myFreeContestAll", account, toggle],
    async () =>
      await ClientReqGet(
        `/reqContest/my`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: true }
  );

  return (
    <FreeUl>
      {data.data.length > 0 ? (
        data.data.map((item, index) => (
          <Freeli
            key={`free_my_${item._id}_${index}`}
            onClick={() => navi(`/user/myfreecontestview/${item._id}`)}
          >
            <FreeStatus>
              <FreeLabel>{item.type === 0 ? "공모전" : "대외활동"}</FreeLabel>
              <FreeCurStatus confirm={item.confirm}>
                {item.confirm ? "승인완료" : "승인대기"}
              </FreeCurStatus>
            </FreeStatus>
            <FreeTitle>{item.title}</FreeTitle>
            <FreeDate>{moment(item.createdAt).format("YYYY-MM-DD")}</FreeDate>
          </Freeli>
        ))
      ) : (
        <Freeli>등록된 무료등록 건이 존재하지 않습니다</Freeli>
      )}
    </FreeUl>
  );
};

const MyFreeEditReq = ({ account, toggle }) => {
  const navi = useNavigate();
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(0);
    return () => {
      setPage(0);
    };
  }, []);
  const { data, error, isLoading } = useQuery(
    ["myFreeEditWithPage", account, toggle, page],
    async () =>
      await ClientReqGet(
        `/reqCEdit/list?page=${page}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: true }
  );

  return (
    <FreeUl>
      {data.data.count > 0 ? (
        data.data.result.map((item, index) => (
          <Freeli
            key={`my_free_req_${item._id}_${index}`}
            onClick={() => navi(`/user/myfreeeditview/${item._id}`)}
          >
            <FreeStatus>
              <FreeCurStatus confirm={item.answerYn}>
                {item.answerYn ? "답변완료" : "답변대기"}
              </FreeCurStatus>
            </FreeStatus>
            <FreeTitle>{item.content}</FreeTitle>
            <FreeDate>{moment(item.createdAt).format("YYYY-MM-DD")}</FreeDate>
          </Freeli>
        ))
      ) : (
        <Freeli>등록된 무료등록 수정건이 존재하지 않습니다.</Freeli>
      )}
    </FreeUl>
  );
};

const MyFreeContests = () => {
  const [account, setAccount] = useRecoilState(accountSelect);

  const changeATK = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const [tab, setTab] = useState(0);
  useEffect(() => {
    setTab(0);
  }, []);

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
      </div>
      <div className={`sub_container`}>
        <div className={`contents mypage`}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>무료등록</span>
          </h2>
          <div className={"myInfo_area"}>
            <FreeTab>
              <FreeTabLi active={tab === 0} onClick={() => setTab(0)}>
                등록요청
              </FreeTabLi>
              <FreeTabLi active={tab === 1} onClick={() => setTab(1)}>
                수정요청
              </FreeTabLi>
            </FreeTab>
            <Suspense fallback={<Loading />}>
              {tab === 0 ? (
                <MyFreeReq account={account} toggle={changeATK} />
              ) : (
                <MyFreeEditReq account={account} toggle={changeATK} />
              )}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFreeContests;
