import styled, { css } from "styled-components";

export const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;

  .dcMainTitle {
    text-align: center;
    .dcTitle {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;

export const Section = styled.div`
  padding: 0.25em 0.5em;
  &.customContestFilter {
    > ul {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      font-size: 0.75em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow-x: scroll;
      padding-bottom: 1em;
      > li {
        padding: 0.25em 0.5em;
        margin-right: 0.25em;
        opacity: 0.5;
        &.active {
          opacity: 1;
        }

        > label {
          font-size: 0.8em;
          background-color: gray;
          padding: 0.25em 0.75em;
          border-radius: 10px;
          color: white;
        }
      }
    }
  }
  &.customContestItems {
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: stretch;

      > li {
        width: calc((100% - 1em) / 2);
        display: flex;
        flex-direction: column;

        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .customTitleArea {
          /* padding-bottom: 0.25em; */
          p {
            color: white;
            &.customCTitle {
              word-break: break-all;
              font-size: 0.65em;
              font-weight: bold;
            }
            &.customCHost {
              text-align: left;
              padding: 0.25em 0;
              color: rgba(255, 255, 255, 0.4);
              font-size: 0.55em;
              font-weight: 300;
              white-space: normal;
              width: 100%;
              word-break: break-all;
            }
          }
        }
        .customEndDate {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 0.15em;

          p {
            font-size: 0.55em;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.4);
          }
        }
        .customTypeArea {
          padding-top: 0.25em;
          > ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow-x: scroll;
            flex-wrap: nowrap;
            white-space: nowrap;
            padding-bottom: 0.25em;
            > li {
              font-size: 0.45em;
              padding: 0.25em 0.5em;
              margin-right: 0.25em;
              border-radius: 5px;
              &.typeCategory {
                color: white;
                background-color: green;
              }
              &.typeTarget {
                color: white;
                background-color: orange;
              }
            }
          }
        }
        .customEtc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 0.5em;
          color: gray;

          font-size: 0.5em;
          .customViewCnt {
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
`;
export const SortSection = styled.div`
  padding: 0 0.25em 10px 0.25em;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const SortItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const SortText = styled.span`
  padding-right: 5px;
  font-size: 0.55em;
  color: gray;
`;

export const SearchSection = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 7;
`;
export const SearchBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000d9;
  z-index: 6;

  transform: translateY(-100vh);
  transition: transform 0.1s linear;
  ${(props) =>
    props.active &&
    css`
      transform: translateY(0);
    `}
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e1e1e11f;
  width: 100%;
  > input {
    padding: 0.25em 0.5em 0.25em 0.5em;
    width: 100%;
    font-size: 0.55em;
    color: white;
    background-color: transparent;
    border: 0px;
    &:focus {
      outline: none;
    }
  }
`;
export const SearchType = styled.div`
  padding: 0.25em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.65em;
  color: white;
`;
export const SearchIconBox = styled.div`
  position: absolute;
  right: 0.5em;
`;

export const Pop = styled.div`
  position: fixed;
  bottom: ${(props) => (props.open ? 0 : `-2000px`)};
  width: 100%;
  ${(props) =>
    props.type === "search" &&
    css`
      z-index: 7;
    `}
  transition: all 0.3s ease-in-out;

  .customPopBg {
    position: fixed;
    top: 0;
    width: 100%;
    ${(props) =>
      props.type === "search" &&
      css`
        z-index: 8;
      `}
    height: ${(props) => (props.open ? `100%` : 0)};
    z-index: ${(props) => (props.open ? 1 : -1)};

    background-color: rgba(0, 0, 0, 0.5);
  }
  .customPopBody {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-color: white;

    .customPopContents {
      .customPopBtns {
        padding: 0.25em 0.5em;
        /* border-bottom: 1px solid gray; */
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .customPopItems {
        height: 288px;
        overflow-y: scroll;
        padding-inline-start: 1em;
        padding-inline-end: 1em;
        padding-bottom: 1em;

        > li {
          font-size: 0.75em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 0.5em;
          .customPopItem {
            font-size: 0.8em;
          }
        }
      }
    }
  }
`;

export const ContestStatus = styled.span`
  background-color: ${(props) =>
    props.status === 0 ? `orange` : props.status === 1 ? `green` : `gray`};

  margin-left: 0 !important;
  padding: 0.25em 0.35em;
  border-radius: 10px;
  color: white;
`;

export const ContestThumbImg = styled.img`
  height: 100%;
  @media (max-width: 425px) {
    max-height: 250px;
  }
  @media (min-width: 426px) and (max-width: 767px) {
    max-height: 500px;
  }
`;
