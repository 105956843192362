import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";

const WeeklyPrev_suspense = () => {
  const { data, error, isLoading } = useQuery(
    ["weeklytop10"],
    async () => await NormalReqApi("get", "/weekly/top10"),
    { retry: true, suspense: true }
  );

  const render = (data) => {
    if (data.data.result.length > 0) {
      const items = data.data.result;
      return items.map((item, index) => (
        <li key={`weekly_items_${index}`}>
          <Link to={`/weekly/${item._id}`}>
            <span className={"brd_category"}>
              {item.category === 1 ? "위클리" : "포커스"}
            </span>
            <span className={"brd_title"} style={{ fontSize: "0.75em" }}>
              {item.title}
            </span>
            <p className={"brd_date"}>
              {moment(item.createdAt).format("YYYY-MM-DD")}
            </p>
          </Link>
        </li>
      ));
    } else {
      return (
        <li>
          <a>
            <span className={"brd_category"}>위클리</span>
            <span className={"brd_title"}>title</span>
            <p className={"brd_date"}>2022-01-22</p>
          </a>
        </li>
      );
    }
  };
  return <>{render(data)}</>;
};

export default WeeklyPrev_suspense;
