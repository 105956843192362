import React from "react";
import styled, { css, keyframes } from "styled-components";

const FlexStartCenter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CommonTextSize = "0.55em";
const titleTextSize = "0.55em";
const SummaryTextSize = "0.45em";
export const helpTextColor = "#ababab";
export const subTextColor = "";

export const ContestWrap = styled.div`
  position: relative;
  margin-top: 3.5em;
  background-color: rgb(83, 76, 110);
  height: 100%;
`;

export const FilterWrap = styled(FlexStartCenter)`
  position: relative;

  width: 100%;
  overflow-x: scroll;
  padding-left: 1em;
  padding-top: 1em;
  padding-bottom: 0.75em;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const FilterResultWrap = styled(FlexStartCenter)`
  position: relative;
  overflow-x: scroll;
  padding-bottom: 1em;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FilterBtn = styled.button`
  white-space: nowrap;
  margin-right: 0.5em;
  border: none;
  border-radius: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: ${(props) =>
    props.type === "result" ? `#2b303b` : `#bbbbbb`};
  height: 30px;
  color: ${(props) => (props.type === "result" ? `white` : `#2b303b`)};
`;

export const FilterText = styled.span`
  font-size: ${CommonTextSize};
  line-height: 30px;
  font-weight: 600;
  &:first-child {
    padding-right: 1em;
  }
`;

export const ContestBody = styled.div`
  border-top: 1px solid #5f5f5f;
  margin: 0 0.65em 0 0.65em;
`;

export const HelpText = styled.span`
  font-size: ${CommonTextSize};
  color: #ababab;
`;

export const ContestDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  /* align-items: censter; */
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const BodyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.3em;
`;

export const BodyMainInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const BodySubInfo = styled.div``;

export const BodyText = styled.p`
  font-size: ${titleTextSize};
  color: white;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const BodySummaryText = styled.p`
  font-size: ${SummaryTextSize};
  color: ${helpTextColor};
  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml}em;
    `}
`;

export const CurrentStatus = styled.span`
  width: fit-content;
  padding: 0.3em;
  font-size: ${SummaryTextSize};
  color: white;
  background-color: ${(props) =>
    props.status === 0 ? "orange" : props.status === 1 ? "green" : "gray"};
`;

export const BasicInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.2em;
`;

const slideUp = keyframes`
from{
  transform:translateY(200px);
}
to{
  opacity:1;
  visibility: visible;
}
`;
const slideDown = keyframes`
from{
  opacity: 1;
  visibility: visible;
  transform:translateY(0px);
}
to{
  opacity: 0;
  visibility: hidden;
  transform:translateY(200px);
}
`;
const slideUp2 = keyframes`
from{
  transform:translateY(200px);
}
to{
  opacity:1;
  visibility: visible;
  height:60%;
}
`;
const slideDown2 = keyframes`
from{
  opacity: 1;
  height:60%;
  visibility: visible;
  transform:translateY(0px);
}
to{
  opacity: 0;
  
  visibility: hidden;
  transform:translateY(200px);
}
`;

export const ReplyPop = styled.div`
  z-index: 111;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;

  background-color: black;
  border-radius: 1em 1em 0 0;
  animation-duration: 0.25s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  ${(props) =>
    props.open
      ? css`
          animation-name: ${slideUp2};
        `
      : !props.init &&
        css`
          animation-name: ${slideDown2};
        `}

  .customReplyWrap {
    height: 70%;
    overflow-y: scroll;
  }

  .customReplyTitle {
    padding: 0.5em 1em;
    display: flex;
    font-size: 0.85em;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .customReplyNoData {
    font-size: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    p {
      color: gray;
    }
  }
  .customReplyInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0.25em 1em;
    background-color: rgba(255, 255, 255, 0.1);
    input {
      background-color: transparent;
      border: none;
      color: white;
      width: 80%;
      font-size: 0.65em;
      padding: 0.25em;
      &:focus {
        outline: none;
      }
    }
  }

  .customBtn {
    margin-bottom: 2em;
    display: flex;
    justify-content: space-around;
    > button {
      border: none;
      background-color: #2b303b;
      color: white;
      border-radius: 5px;
      width: calc((100% - 2em) / 2);
      padding: 0.25em 0.5em;

      font-size: 0.75em;
    }
  }
`;
export const PopFilter = styled.div`
  z-index: 11123;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;

  background-color: ${(props) => (props.theme === "dark" ? "black" : "white")};
  border-radius: 1em 1em 0 0;
  animation-duration: 0.25s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  ${(props) =>
    props.open
      ? css`
          animation-name: ${slideUp};
        `
      : !props.init &&
        css`
          animation-name: ${slideDown};
        `}

  .customBtn {
    margin-bottom: 2em;
    display: flex;
    justify-content: space-around;
    > button {
      border: none;
      background-color: #2b303b;
      color: white;
      border-radius: 5px;
      width: calc((100% - 2em) / 2);
      padding: 0.25em 0.5em;

      font-size: 0.75em;
    }
  }
`;
export const PopListWrap = styled.ul`
  padding: 1em;

  .customReplyItems {
    font-size: 0.85em;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    .customReplyTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > label {
        font-size: 0.75em;
      }
      .customReplyBtn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btns {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.65em;
          margin-right: 1em;
          > span {
            margin-right: 0.2em;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .customReplyText {
      padding: 0.5em 0;
      color: rgba(255, 255, 255, 0.8);
      font-size: 0.65em;
    }
  }

  .customSubmit {
    font-size: 0.75em;
    .customSubmitElement {
      padding: 0.25em 0;
      > label {
        padding-left: 35px;
        display: block;
        margin-bottom: 12px;
        position: relative;

        .customSubmitCheck {
          margin-right: 1em;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        > span {
          position: absolute;
          top: 3px;
          left: 0;
          border-radius: 5em;
          width: 20px;
          height: 20px;
          background-color: #eee;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
        input:checked ~ span {
          background-color: #2b303b;
        }
        input:checked ~ span:after {
          display: block;
        }
        span:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
`;

export const PopListData = styled.li`
  font-size: ${titleTextSize};
  padding: 0.5em 0.3em;
  position: relative;
  ${(props) =>
    props.selected &&
    css`
      &::after {
        position: absolute;
        right: 1em;
        content: "선택됨";
        margin-left: 1em;
      }
    `}
`;
