import React, { Suspense } from "react";
import {
  DContentDepicSubContainer,
  DContentsSubMenu,
} from "../../components/DContents/Common/Element";
import Cmstyle from "../../assets/css/community.module.scss";
import Default280 from "../../assets/img/common/img_posterDefault_280.jpg";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import DepicDataList from "../../components/DContents/Mobile/DepicDataList";
import { DepicSuspenseList } from "../../components/DContents/Depic/getdata";
import { Empty_Depic_list } from "../../components/DContents/Depic/empty";

const Depic = () => {
  // if (isMobile) {
  //   return (
  //     <div className={"container sub"}>
  //       <DContentsSubMenu current={"depic"} />
  //       <div className={"sub_container"}>
  //         <DepicDataList />
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className={"container sub"}>
      <DContentsSubMenu current={"depic"} />
      <DContentDepicSubContainer
        label={"대픽 영상"}
        subLabel={"대단히 Pick! 하고 싶은 꿀잼 영상들을 확인하세요!"}
      >
        <Suspense fallback={<Empty_Depic_list />}>
          <DepicSuspenseList />
        </Suspense>
      </DContentDepicSubContainer>
    </div>
  );
};

export default Depic;
