import React, { Component, useEffect, useState } from "react";
import UserChangeInfo from "../../components/User/UserChangeInfo";
import UserCheckPwd from "../../components/User/UserCheckPwd";

const ChangeInfo = () => {
  const [index, setIndex] = useState(1);
  const [tab, setTab] = useState({
    index: 1,
    confirm: false,
    component: <UserCheckPwd changeTab={setIndex} />,
  });
  const Tabs = [
    { index: 1, component: <UserCheckPwd changeTab={setIndex} /> },
    { index: 2, component: <UserChangeInfo changeTab={setIndex} /> },
  ];
  useEffect(() => {
    setTab({
      index: index,
      confirm: index === 1 ? true : false,
      component: Tabs.filter((f) => f.index === index)[0].component,
    });
  }, [index]);

  useEffect(() => {
    return () => {
      setTab({
        index: 1,
        confirm: false,
        component: <UserCheckPwd changeTab={setIndex} />,
      });
    };
  }, []);

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
        <div className={`sub_container`}>{tab.component}</div>
      </div>
    </>
  );
};

export default ChangeInfo;
