import moment from "moment";
import React, { useState } from "react";
import { HiGift, HiUser } from "react-icons/hi";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientReqGet } from "../../utils/ClientAuth";
import {
  ListDataBody,
  ListDataLi,
  ListDataUl,
  RegDate,
  Status,
  SubInfoArea,
  SubInfoText,
  Title,
} from "./UserTemplate";

const UserMyEvent = (id) => {
  const [page, setPage] = useState(1);
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (atk) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = atk;
      return { ...temp };
    });
  };

  const { data } = useQuery(
    ["myeventPage", id, page],
    async () =>
      await ClientReqGet(
        `/event/my?page=${page}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: true, suspense: true }
  );

  return (
    <>
      <ListDataUl>
        {data.data.length > 0
          ? data.data.map((item, index) => {
              return item.submitUser.map((uItem, uIndex) => {
                return (
                  <Link
                    to={`/event/${item._id}`}
                    key={`s_my_e_${index}_${uIndex}`}
                  >
                    <ListDataLi center={true}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <img
                            src={
                              process.env.REACT_APP_AWS_ENDPOINT +
                              "w_280/" +
                              item.thumbnail.path
                            }
                            style={{
                              width: "175px",
                              height: "194px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                        <ListDataBody>
                          <div>
                            <Status type="done">스크랩</Status>
                            <Title>{item.title}</Title>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <SubInfoArea>
                              <HiUser size={15} />
                              <SubInfoText>{item.target}</SubInfoText>
                            </SubInfoArea>
                            <SubInfoArea bottom={true}>
                              <HiGift size={15} />
                              <SubInfoText>{item.reward}</SubInfoText>
                            </SubInfoArea>

                            <RegDate>
                              스크랩 날짜 :
                              <span style={{ marginLeft: "1em" }}>
                                {moment(uItem.insertDate).format("YYYY-MM-DD")}
                              </span>
                            </RegDate>
                          </div>
                        </ListDataBody>
                      </div>

                      <div
                        style={{
                          border: "1px solid #dbdbdb",
                          padding: "0.3em 0.5em",
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ marginBottom: "1em" }}>
                          <p style={{ color: "#9c9c9c" }}>응모 정보</p>
                        </div>

                        <ul>
                          {uItem.submit.map((sbItem, sbIndex) => (
                            <li
                              key={`my_submit_data_${index}_${uItem}_${sbIndex}`}
                            >
                              <label
                                style={{
                                  marginRight: "1em",
                                  color: "#686868",
                                  fontSize: "0.8em",
                                }}
                              >
                                <b>
                                  {sbIndex + 1 + "."} {sbItem.question.title}
                                </b>
                              </label>
                              <span style={{ fontSize: "0.75em" }}>
                                {sbItem.answer}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </ListDataLi>
                  </Link>
                );
              });
            })
          : ""}
      </ListDataUl>
    </>
  );
};

export default UserMyEvent;
