import React, { useCallback, useEffect, useRef, useState } from "react";
import { DContentsSubMenu } from "../../components/DContents/Common/Element";
import { ViewHeader } from "../../components/DContents/Mobile/MTemplate";
import styled, { keyframes } from "styled-components";
import { HiSearch } from "react-icons/hi";
import { array } from "yup";
import { NormalReqApi } from "../../utils/ReqApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;
  .dcMainTitle {
    text-align: center;
    .dcTitle {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;
const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0.5em 0.5em 0.5em;

  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5em;
  .dcSearchTitle {
    font-size: 0.75em;
    color: white;
    width: 100%;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
`;

const EventSubmitResultM = () => {
  const wordRef = useRef(null);
  const page = useRef(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const searchToggle = useRef(false);
  const [list, setList] = useState([]);
  const route = useNavigate();

  //observe ( for infinite scroll )
  const observerTargetRef = useRef(null);
  const searchReq = () => {
    NormalReqApi(
      "get",
      `/eventresult?page=${page.current}&keyType=title&keyword=${wordRef?.current?.value}`
    )
      .then((data) => {
        const {
          data: { count, result },
        } = data;
        if (count > 0) {
          setList([...result]);
          setHasNextPage(result.length === 10);
          if (result.length) {
            page.current += 1;
          }
        }
        console.log(count, result);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const dataFetch = useCallback(() => {
    console.log("test");
    NormalReqApi(
      "get",
      `/eventresult?page=${page.current}&keyType=title&keyword=${wordRef.current}`
    )
      .then((data) => {
        const {
          data: { count, result },
        } = data;
        if (count > 0) {
          setList((prev) => [...prev, ...result]);
          setHasNextPage(result.length === 10);
          if (result.length) {
            page.current += 1;
          }
        }
        console.log(count, result);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [searchToggle]);

  useEffect(() => {
    if (!observerTargetRef.current || !hasNextPage) return;

    const io = new IntersectionObserver((e, o) => {
      console.log("active");
      if (e[0].isIntersecting) {
        console.log("active2");
        dataFetch();
      }
    });
    io.observe(observerTargetRef.current);

    return () => {
      io.disconnect();
    };
  }, [dataFetch, hasNextPage]);

  const handleSearchToggle = () => {
    searchToggle.current = true;
    page.current = 1;
    searchReq();
  };

  return (
    <Wrap>
      <div className={"dcMainTitle"}>
        <p className={"dcTitle"}>이벤트 발표</p>
      </div>
      <SearchArea>
        <input
          type="text"
          ref={wordRef}
          className="dcSearchTitle"
          onBlurCapture={() => console.log("blur")}
        />
        <HiSearch
          color={"white"}
          className={"dcSearchBtn"}
          onClick={handleSearchToggle}
        />
      </SearchArea>
      <ListArea>
        <ListItems>
          {list.length === 0
            ? Array.from(new Array(3)).map((item, index) => {
                return (
                  <ListItem key={index}>
                    <EmptyTitle />
                    <EmptyDate />
                  </ListItem>
                );
              })
            : list.map((item, index) => {
                return (
                  <ListItem
                    key={`${item._id}`}
                    onClick={() => route(`/eventresult/${item._id}`)}
                  >
                    <ItemTitle>{item.title}</ItemTitle>
                    <ItemDate>
                      {moment(item.createDate).format("YYYY-MM-DD HH:mm")}
                    </ItemDate>
                  </ListItem>
                );
              })}

          <div ref={observerTargetRef} />
        </ListItems>
      </ListArea>
    </Wrap>
  );
};

const temp = () => {
  console.log("test");
};

const emptySpan = "10px";
const emptyBorderRadius = "10px";
const emptyBgColor = "#4545459e";
const blinkAM = keyframes`
0%{
    opacity: 0.3;
}
50%{
    opacity: 1;
}
100%{
    opacity: 0.3;
}

`;

const ListArea = styled.div``;
const ListItems = styled.ul``;
const ListItem = styled.li`
  display: block;
  width: 100%;
  padding: 0.5em 0.5em;
  border-bottom: 1px solid #3d3d3d;
`;
const ItemTitle = styled.span`
  font-size: 0.65em;
  width: 100%;
  display: block;
  color: white;
  text-align: left;
`;
const ItemDate = styled.span`
  width: 100%;
  font-size: 0.45em;
  display: block;
  color: gray;
  text-align: right;
`;
const EmptyTitle = styled.span`
  width: 100%;
  display: block;
  color: white;
  text-align: left;
  padding: ${emptySpan};
  border-radius: ${emptyBorderRadius};
  background-color: ${emptyBgColor};
  animation: ${blinkAM} 2s linear infinite;
`;
const EmptyDate = styled.span`
  width: 30%;
  display: block;
  margin-top: 10px;
  margin-left: auto;
  padding: ${emptySpan};
  border-radius: ${emptyBorderRadius};
  color: gray;
  background-color: ${emptyBgColor};
  font-size: 0.45em;
  animation: ${blinkAM} 2s linear infinite;
`;

export default EventSubmitResultM;
