import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";
import {
  CategoryText,
  ContentsWrap,
  CTitle,
  DCwrap,
  Section,
  SectionWrap,
  SMoreBtn,
  SSummary,
  STitle,
  SubText_P,
  ThumbItem,
  ThumbSlide,
  Wdate,
  WeeklyLi,
  WTitle,
} from "./Frame";

const WeeklyPrev = () => {
  const { data, error } = useQuery(
    ["weeklytop10"],
    async () => await NormalReqApi("get", "/weekly/top10"),
    { retry: true, suspense: true }
  );

  const navi = useNavigate();

  return (
    <Section>
      <SectionWrap>
        <STitle>위클리</STitle>
        <SSummary>매주마다 위클리 플래너로 정리하세요!</SSummary>
        <SMoreBtn type="button" onClick={() => navi("/weekly")}>
          더보기
        </SMoreBtn>
      </SectionWrap>
      <ContentsWrap>
        <div>
          <ul>
            {data.data.result.map((item, index) => (
              <WeeklyLi key={`w_p_list_${index}`}>
                <Link to={`/weekly/${item._id}`}>
                  <CategoryText>
                    {item.category === 1 ? "위클리" : "포커스"}
                  </CategoryText>
                  <WTitle>{item.title}</WTitle>
                  <Wdate>{moment(item.createdAt).format("YYYY-MM-DD")}</Wdate>
                </Link>
              </WeeklyLi>
            ))}
          </ul>
        </div>
      </ContentsWrap>
    </Section>
  );
};

export default WeeklyPrev;
