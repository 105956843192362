import React from "react";
import {
  CntBody,
  CntNumber,
  CntTitle,
  InfoLabel,
  InfoLi,
  InfoText,
  InfoTop,
  InfoUl,
  Host,
  ListDataLi,
  ListDataUl,
  RegDate,
  Status,
  SubInfoArea,
  SubInfoText,
  Title,
} from "./UserTemplate";
import { HiClock, HiGlobe, HiPencil, HiUser } from "react-icons/hi";
import Default from "../../assets/img/common/img_posterDefault_280.jpg";

export const EmptyUserBasicInfo = () => {
  return (
    <>
      <InfoTop>
        <InfoUl>
          <InfoLi>
            <InfoLabel className="mit_tit">이름</InfoLabel>
            <InfoText className="mit_text"></InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">회원유형</InfoLabel>
            <InfoText className="mit_text"></InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">닉네임</InfoLabel>
            <InfoText className="mit_text"></InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">이메일</InfoLabel>
            <InfoText className="mit_text"></InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">연락처</InfoLabel>
            <InfoText className="mit_text"></InfoText>
          </InfoLi>
          {/* <CompanyLabel>사업자 정보</CompanyLabel>
            <InfoUl type="company">
              <InfoLi>
                <InfoLabel className="mit_tit">사업자등록번호</InfoLabel>
                <InfoText className="mit_text">01051259322</InfoText>
              </InfoLi>
              <InfoLi>
                <InfoLabel className="mit_tit">기업상태</InfoLabel>
                <InfoText className="mit_text">01051259322</InfoText>
              </InfoLi>
              <InfoLi>
                <InfoLabel className="mit_tit">대표전화번호</InfoLabel>
                <InfoText className="mit_text">01051259322</InfoText>
              </InfoLi>
              <InfoLi>
                <InfoLabel className="mit_tit">대표 메일주소</InfoLabel>
                <InfoText className="mit_text">01051259322</InfoText>
              </InfoLi>
            </InfoUl> */}
        </InfoUl>
      </InfoTop>
      <div style={{ marginTop: "3em" }} role="count wrap">
        <div
          role="count header"
          style={{
            borderTop: "1px solid black",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <CntBody>
            <CntTitle>스크랩</CntTitle>
            <CntNumber></CntNumber>
          </CntBody>
          <CntBody>
            <CntTitle>참여한 이벤트</CntTitle>
            <CntNumber></CntNumber>
          </CntBody>
          <CntBody>
            <CntTitle>내가 쓴 게시물</CntTitle>
            <CntNumber></CntNumber>
          </CntBody>
          <CntBody>
            <CntTitle>댓글</CntTitle>
            <CntNumber></CntNumber>
          </CntBody>
          <CntBody>
            <CntTitle>알림</CntTitle>
            <CntNumber></CntNumber>
          </CntBody>
        </div>
      </div>
    </>
  );
};

export const EmptyUserCountInfo = () => {
  return <></>;
};

export const EmptyUserScrapTop3 = () => {
  return (
    <ListDataUl>
      {Array.from(new Array(3), (el, index) => (
        <ListDataLi key={`u_es_${index}`}>
          <div>
            <img
              src={Default}
              style={{ width: "140px", borderRadius: "5px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingLeft: "0.5em",
            }}
          >
            <div>
              <Status type="ready">Loading</Status>
              <Title></Title>
              <Host></Host>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SubInfoArea>
                <HiUser size={15} />
                <SubInfoText></SubInfoText>
              </SubInfoArea>
              <SubInfoArea>
                <HiGlobe size={15} />
                <SubInfoText>지역</SubInfoText>
              </SubInfoArea>
              <SubInfoArea>
                <HiClock size={15} />
                <SubInfoText></SubInfoText>
              </SubInfoArea>
            </div>
          </div>
        </ListDataLi>
      ))}
    </ListDataUl>
  );
};
