import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import custom from "../../../assets/css/event.module.scss";
import { accountSelect, authSelect } from "../../../recoil/AtomConfig";
import { dataList, UniList } from "../../../utils/AppleOnCampus";

import { ClientReqGet, ClientReqPost } from "../../../utils/ClientAuth";
import { NormalReqApi } from "../../../utils/ReqApi";
import { AOCBottom, UnivItems } from "./Layout";
import { ImageMap } from "@qiuz/react-image-map";

const EventViewPC = ({ id, toggleReply }) => {
  const [user, setUser] = useRecoilState(accountSelect);
  const [isOpen, setIsOpen] = useState(false);
  const [isDupl, setIsDupl] = useState(false);
  const [submitData, setSubmitData] = useState([]);
  const [submitIndexList, setSubmitIndexList] = useState([]);
  const navi = useNavigate();
  const submitTemplate = {
    title: "",
    inputType: 2,
    required: true,
  };

  const toggle = (value) => {
    setUser((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const { data, error } = useQuery(
    ["eventView", id],
    async () => await NormalReqApi("get", "/event/view/" + id),
    { retry: true, suspense: true }
  );

  const isSubmitOpen = (startDate, endDate) => {
    const nowDate = moment();
    const sDate = moment(startDate);
    const eDate = moment(endDate);

    if (nowDate.isAfter(sDate) && nowDate.isBefore(eDate)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (data.flag) {
      toggleReply(data.data.replyYn);

      setSubmitData([...data.data.submitElement]);

      setSubmitIndexList([]);
      const tempArray = [];
      data.data.submitElement.map((item, index) => {
        if (item.inputType === 2) {
          tempArray.push({ target: `i_radio_${index}`, type: 2 });
        } else {
          tempArray.push({ target: `i_text_${index}`, type: 1 });
        }
      });
      setSubmitIndexList([...tempArray]);
      setIsOpen(
        isSubmitOpen(data.data.submitStartDate, data.data.submitEndDate)
      );
      setIsDupl(data.data.dupliYn);
    }
  }, [data]);

  useEffect(() => {
    // console.log(submitData);
  }, [submitData]);

  const renderSubmitItems = (items) => {
    // setSubmitData([...items]);
    return items.map((e, index) => {
      return (
        <li key={`e_se_${index}`} className={custom.applyList}>
          <span className={`input_title w20 ${custom.input_title}`}>
            {e.title}
          </span>
          <div className={`input_area w80 select_area_0 ${custom.input_area}`}>
            <label className={"w100"}>
              {e.inputType === 2 ? (
                e.answerList.map((aItem, aIndex) => {
                  return (
                    <React.Fragment key={`r_item_${index}_${aIndex}`}>
                      <input
                        type="radio"
                        name={`i_radio_${index}`}
                        className={`apply_text`}
                        value={aItem.text}
                      />
                      <span style={{ marginRight: 10 }}>{aItem.text}</span>
                    </React.Fragment>
                  );
                })
              ) : (
                <input
                  type="text"
                  name={`i_text_${index}`}
                  className={`apply_text require ${custom.apply_text}`}
                />
              )}
            </label>
          </div>
        </li>
      );
    });
  };

  const submitEvent = async (e) => {
    e.preventDefault();
    if (!user.id || !user.accessToken) {
      alert("로그인 후 이용해주시기 바랍니다.");
      return false;
    }

    const checkResult = await ClientReqGet(
      `/reply/myReply?id=${id}`,
      user.accessToken,
      user.refreshToken,
      toggle
    );
    if (!checkResult.data) {
      alert("이벤트 댓글을 먼저 작성해주시기 바랍니다.");
      return false;
    }

    // check reply exist
    // if exist ==> pass
    // if non ==> notPass

    if (!isOpen) {
      return false;
    }

    if (!isDupl) {
      const dupliResult = await ClientReqGet(
        `/event/dupli/${id}`,
        user.accessToken,
        user.refreshToken,
        toggle
      );

      if (dupliResult.data === true) {
        alert("이미 등록된 접수건이 존재합니다. 참여해주셔서 감사합니다.");
        return false;
      }
    }

    const template = {
      submitItems: [],
    };
    submitIndexList.map((item, index) => {
      const templateSub = {
        question: submitData[index],
        answer: e.target[item.target].value,
      };
      template.submitItems.push(templateSub);
    });

    const result = await ClientReqPost(
      `/event/submit/${id}`,
      { ...template },
      user.accessToken,
      user.refreshToken,
      toggle
    );
    if (result.flag) {
      alert(
        "성공적으로 응모가 완료되었습니다. 참여해주셔서 감사합니다. 접수하신 내용은 마이페이지에서 확인할 수 있습니다."
      );
      return false;
    }
  };

  const calDDay = (startDate, endDate) => {
    const nowDate = moment();
    const sDate = moment(startDate);
    const eDate = moment(endDate);

    const nowAfterSDate = nowDate.isAfter(sDate);
    const nowBeforeEDate = nowDate.isBefore(eDate);
    if (!nowAfterSDate) {
      return "예정";
    } else {
      if (nowBeforeEDate) {
        return "진행중";
      } else {
        return "마감";
      }
    }
  };
  const univList = [...UniList];

  const univIndicator = univList.map((item) => {
    return item.label;
  });
  const c_indicator = new Set(univIndicator);
  const c_indi_array = [...c_indicator];

  // 2024.1.11 애플 aoc 메인 이미지 변경 및 imgMap 적용
  const mapArea = [
    {
      width: "13.743982494529542%",
      height: "3.0553410553410547%",
      left: "43.43544857768052%",
      top: "17.490347490347492%",
      style: { cursor: "pointer" },
      onMouseOver: () => console.log("onmouse"),
    },
  ];

  const univRef = useRef(null);

  const mapClick = (area, index) => {
    console.log("click map!");
    console.log(area, index);
    if (univRef.current != null) {
      univRef.current.scrollIntoView({ behavior: "smooth" });
      console.log(univRef.current);
      console.log(univRef.current.offsetTop);
    }
  };

  const ImageMapComponent = useMemo(
    () => (
      <ImageMap
        src={
          "https://s3.ap-northeast-2.amazonaws.com/allfetc.archive/aocImg01_240111.png"
        }
        map={mapArea}
        onMapClick={mapClick}
      />
    ),
    [mapArea]
  );

  return (
    <ul>
      <li className={`th view ${custom.th} ${custom.view}`}>
        <span className="board_title">{data.data.title}</span>
        <div className={custom.info}>
          <p className="board_user">[혜택] {data.data.reward}</p>
          <p className="board_user">[자격] {data.data.target}</p>
        </div>
        <div className={custom.info}>
          <p className={`board_user ${custom.dateTitle}`}>{"이벤트 기간"}</p>

          <p className="board_period">
            {moment(data.data.startDate).format("yyyy-MM-DD")} ~{" "}
            {moment(data.data.endDate).format("yyyy-MM-DD")}
          </p>
          <p className={`board_status standby ${custom.board_status}`}>
            {calDDay(data.data.startDate, data.data.endDate)}
          </p>
        </div>
        {/* <div className={custom.info}>
          <p className={`board_user ${custom.dateTitle}`}>{"접수 기간"}</p>
          <p className="board_period">
            {moment(data.data.submitStartDate).format("yyyy-MM-DD HH:mm")} ~{" "}
            {moment(data.data.submitEndDate).format("yyyy-MM-DD HH:mm")}
          </p>
        </div> */}
      </li>
      {id === "63e0638cf3f89879d1b0324b" ? (
        <li className="td view">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <ImageMap src={img} map={mapArea} onMapClick={mapClick} /> */}
            {ImageMapComponent}
            {/* <img
              src={
                "https://s3.ap-northeast-2.amazonaws.com/allfetc.archive/aocImg01_240111.png"
              }
            /> */}
            <ul style={{ border: "none" }} ref={univRef}>
              {c_indi_array.map((item, index) => (
                <li style={{ border: "none" }} key={`evnet_univList_${index}`}>
                  <label>{item}</label>
                  <UnivItems>
                    {univList
                      .filter((f) => f.label === item)
                      .map((aItem, aIndex) => (
                        <li key={`event_univList_item_${index}_${aIndex}`}>
                          <a href={aItem.univLink} target={"_blank"}>
                            <img src={aItem.img} alt={aItem.name} />
                          </a>
                        </li>
                      ))}
                  </UnivItems>
                </li>
              ))}
            </ul>
            <AOCBottom>
              <div className={"infoMore"}>
                <p>
                  해당 학교가 없는 대학생의 경우에는{" "}
                  <b>대티즌 교육 할인 Store </b>
                  에서도 구매 할 수 있습니다.
                </p>
                <a href={"https://apple.sjv.io/vOoPe"} target="_blank">
                  교육 할인 Store 바로가기
                </a>
              </div>
              <div className={"infoDesk"}>
                <p>AOC 문의 : 070-4648-7404</p>
              </div>
            </AOCBottom>
          </div>
        </li>
      ) : (
        <div
          className="board_content"
          dangerouslySetInnerHTML={{ __html: data.data.contents }}
        ></div>
      )}

      {data.data.submitDataYn !== false && (
        <li className={`td view ${custom.td} ${custom.eventApply}`}>
          <div className="event_input">
            <form onSubmit={submitEvent}>
              <h3>응모 기본 정보</h3>
              <ul>{renderSubmitItems(data.data.submitElement)}</ul>
              <div className={`btn_area ${custom.btn_area}`}>
                {isOpen ? (
                  <button type={"submit"} className={"btn"}>
                    응모하기
                  </button>
                ) : (
                  <button type={"button"} className={"btn"}>
                    응모기간이 아닙니다
                  </button>
                )}
              </div>
            </form>
          </div>
        </li>
      )}
    </ul>
  );
};

export default EventViewPC;
