import DOMPurify from "dompurify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  DcView,
  Loading,
  ViewHeader,
} from "../../components/DContents/Mobile/MTemplate";
import { NormalReqApi } from "../../utils/ReqApi";
// import { ClientReqGet } from "../../utils/ClientAuth";
import styled from "styled-components";
import { PopFilter, PopListWrap } from "../../components/Contest/Mobile/Frame";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import ReplyMobile2 from "../../components/Reply/ReplyMobile2";
import {
  ClientReqGet,
  ClientReqPatch,
  ClientReqPost,
} from "../../utils/ClientAuth";

const SubmitElement = styled.div`
  position: fixed;
  bottom: 0;
`;

const EventViewMobile = () => {
  const purify = DOMPurify.sanitize;
  const [account, setAccount] = useRecoilState(accountSelect);
  const { id } = useParams();
  const navi = useNavigate();
  const [rPage, setRPage] = useState(1);
  const [submitList, setSubmitList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDuply, setIsDuply] = useState(false);

  const [toggle, setToggle] = useState({
    init: true,
    open: false,
  });
  const [reply, setReply] = useState({
    init: true,
    open: false,
  });

  const reAccount = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const handleGoBack = () => {
    navi(-1);
  };

  const toggleSubmit = () => {
    setToggle({
      init: false,
      open: true,
    });
    setReply({
      ...reply,
      open: false,
    });
  };
  const unToggleSubmit = () => {
    setToggle({
      init: false,
      open: false,
    });
  };

  const toggleReply = () => {
    setToggle({
      ...toggle,
      open: false,
    });
    setReply({
      init: false,
      open: true,
    });
  };
  const unToggleReply = () => {
    setReply({
      ...reply,
      open: false,
    });
  };

  const { data, error, isLoading } = useQuery(
    ["eventView", id],
    async () => await NormalReqApi("get", `/event/view/${id}`),
    { retry: true }
  );

  // const {
  //   data: rData,
  //   error: rError,
  //   isLoading: rLoading,
  //   refetch,
  // } = useQuery(
  //   ["eventReply", id, rPage],
  //   async () =>
  //     await ClientReqGet(`/reply?id=${id}&type=event&page=${rPage * 1}`),
  //   { retry: true }
  // );

  const [element, setElement] = useState([]);
  const handleCheckElement = (array, e) => {
    let newArray = [];
    const curElName = `${e.target.name}_`;
    const curElId = e.target.id;

    const curSelectIndex = curElId.split(curElName)[1] * 1;

    if (e.target.checked) {
      const newObject = {
        question: submitList.filter((f) => f.question.title === array.title)[0]
          .question,
        answer: submitList.filter((f) => f.question.title === array.title)[0]
          .question.answerList[curSelectIndex].text,
      };
      newArray = [
        ...submitList.filter((f) => f.question.title !== array.title),
        newObject,
      ];
    } else {
      const newObject = {
        question: submitList.filter((f) => f.question.title === array.title)[0]
          .question,
        answer: null,
      };
      newArray = [
        ...submitList.filter((f) => f.question.title !== array.title),
        newObject,
      ];
    }
    setSubmitList([...newArray]);
  };
  const [replyTotal, setReplyTotal] = useState(0);

  const isSubmitOpen = (startDate, endDate) => {
    const nowDate = moment();
    const sDate = moment(startDate);
    const eDate = moment(endDate);
    if (nowDate.isAfter(sDate) && nowDate.isBefore(eDate)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const { submitElement } = data.data;

      if (submitElement && submitElement.length > 0) {
        const tempArray = [];
        submitElement.map((item, index) => {
          const tempObject = {
            question: {},
            answer: null,
          };
          tempObject.question = item;
          tempArray.push(tempObject);
        });

        setSubmitList([...tempArray]);
        setIsOpen(
          isSubmitOpen(data.data.submitStartDate, data.data.submitEndDate)
        );
        setIsDuply(data.data.dupliYn);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    console.log(submitList);
  }, [submitList]);

  const submitEvent = async () => {
    let isPass = true;
    if (!account.id || !account.accessToken) {
      alert("로그인 후 이용해주시기 바랍니다.");
      return false;
    }

    try {
      const checkResult = await ClientReqGet(
        `/reply/myReply?id=${id}`,
        account.accessToken,
        account.refreshToken,
        reAccount
      );
      console.log(checkResult);
      if (!checkResult.data) {
        alert("이벤트 댓글을 먼저 작성해주시기 바랍니다.");
        return false;
      }
    } catch (e) {
      console.log(e);
      const { statusCode, message } = e;
      if (statusCode === 401) {
        alert("로그인이 만료되었습니다. 로그인 후 다시 시도해주시기 바랍니다.");
        navi("/signin");
      }
      return false;
    }

    if (!isOpen) {
      return false;
    }
    try {
      if (!isDuply) {
        const dupliResult = await ClientReqGet(
          `/event/dupli/${id}`,
          account.accessToken,
          account.refreshToken,
          reAccount
        );
        if (dupliResult.data === true) {
          alert("이미 등록한 접수건이 존재합니다. 참여해주셔서 감사합니다.");
          return false;
        }
      }
    } catch (e) {
      console.log(e);
      return false;
    }

    const template = { submitItems: [] };
    submitList.map((item, index) => {
      if (item.question.required && !item.answer) {
        isPass = false;
      } else {
        template.submitItems.push({ ...item });
      }
    });
    if (!isPass) {
      alert("필수 항목에 체크해주시기 바랍니다");
      return false;
    }

    const result = await ClientReqPost(
      `/event/submit/${id}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      reAccount
    );
    if (result.flag) {
      alert(
        "성공적으로 응모가 완료되었습니다. 참여해주셔서 감사합니다. 접수하신 내용은 마이페이지에서 확인할 수 있습니다."
      );
      navi(-1);
      return false;
    }
  };

  return (
    <ViewHeader label={"이벤트"}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DcView>
            <section className={"dcViewTop"}>
              <p className={"dcViewTitle"}>{data.data.title}</p>
              <div className={"dcViewSubInfo"}>
                <span className={"dcViewAuthor"}>대티즌</span>
                <span className={"dcViewDate"}>
                  {moment(data.data.createdAt).format("YYYY-MM-DD")}
                </span>
              </div>
              <div className={"dcViewSubInfo"}>
                <span className={"dcViewAuthor"}>이벤트 기간</span>
                <span className={"dcViewDate"}>
                  {moment(data.data.startDate).format("YYYY-MM-DD") +
                    " - " +
                    moment(data.data.endDate).format("YYYY-MM-DD")}
                </span>
              </div>
              <div className={"dcViewSubInfoAddition"}>
                <span className={"subcate target"}>
                  [대상] {data.data.target}
                </span>
                <span className={"subcate reward"}>
                  {data.data.reward + " 혜택"}
                </span>
              </div>
            </section>
            <section className={"dcViewEditor"}>
              <div
                className={"dcViewContents"}
                dangerouslySetInnerHTML={{ __html: data.data.contents }}
              ></div>
            </section>

            <section className={"dcViewBtnArea"}>
              {data.data.replyYn && (
                <button type="button" onClick={toggleReply}>
                  댓글 ({replyTotal})
                </button>
              )}

              {data.data.submitDataYn && (
                <button type="button" onClick={toggleSubmit}>
                  접수하기
                </button>
              )}

              <button type="button" onClick={handleGoBack}>
                목록으로
              </button>
            </section>
          </DcView>

          {data.data.replyYn && (
            <ReplyMobile2
              open={reply.open}
              close={unToggleReply}
              id={id}
              type={"event"}
              account={account}
              toggle={reAccount}
              setReply={setReplyTotal}
            />

            // <ReplyMobile
            //   open={reply.open}
            //   init={reply.init}
            //   theme={"dark"}
            //   type={"event"}
            //   data={rData}
            //   error={rError}
            //   isLoading={rLoading}
            //   close={unToggleReply}
            //   refetch={refetch}
            // />
            // <ReplyPop open={reply.open} init={reply.init}  theme={"dark"}>
            //   <div className="customReplyTitle">
            //     <label>댓글</label>
            //     <span onClick={unToggleReply}>x</span>
            //   </div>
            //   <div className={"customReplyInput"}>
            //     <input type="text" />
            //     <HiPaperAirplane />
            //   </div>
            //   <PopListWrap>
            //     {!rLoading && rData.data.result.length > 0 ? (
            //       rData.data.result.map((item, index) => (
            //         <li
            //           key={`event_${id}_reply_${index}`}
            //           className={"customReplyItems"}
            //         >
            //           <div className={"customReplyTop"}>
            //             <label>{item.author.nickName}</label>
            //             <div className={"customReplyBtn"}>
            //               <div className={"btns"}>
            //                 <span>{item.recommend}</span>
            //                 <HiThumbUp />
            //               </div>
            //               <div className={"btns"}>
            //                 <span>{item.unRecommend}</span>
            //                 <HiThumbDown />
            //               </div>

            //               <HiDotsHorizontal className={"btns"} />
            //             </div>
            //           </div>
            //           <div className={"customReplyText"}>
            //             <p>{item.text}</p>
            //           </div>
            //         </li>
            //       ))
            //     ) : (
            //       <li></li>
            //     )}
            //   </PopListWrap>
            // </ReplyPop>
          )}
          {data.data.submitDataYn && (
            <PopFilter open={toggle.open} init={toggle.init}>
              <PopListWrap>
                {data.data.submitElement.map((item, index) => (
                  <li
                    key={`e_submitElement_${index}`}
                    className={"customSubmit"}
                  >
                    {item.inputType === 2 ? (
                      <div className={"customSubmitElement"}>
                        <p style={{ fontSize: "0.75em", marginBottom: ".5em" }}>
                          {item.title}
                        </p>
                        {item.answerList.map((sm, smIndex) => {
                          return (
                            <label key={`submitIndex_${index}_${smIndex}`}>
                              {sm.text}
                              <input
                                type="radio"
                                id={`e_submit_${index}_${smIndex}`}
                                name={`e_submit_${index}`}
                                className={"customSubmitCheck"}
                                onChange={(e) => handleCheckElement(item, e)}
                              />
                              <span></span>
                            </label>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        <label>{item.title}</label>
                      </>
                    )}
                  </li>
                ))}
              </PopListWrap>
              <div className={"customBtn"}>
                <button type="button" onClick={unToggleSubmit}>
                  취소
                </button>
                <button type="button" onClick={submitEvent}>
                  응모하기
                </button>
              </div>
            </PopFilter>
          )}
        </>
      )}
    </ViewHeader>
  );
};
export default EventViewMobile;
