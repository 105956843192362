import { Bootpay } from "@bootpay/client-js";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";
const APID = process.env.REACT_APP_APID;
const AUTHID = process.env.REACT_APP_AUTHID;

const FindPwd = () => {
  const navi = useNavigate();
  const idRef = useRef(null);
  const handleAuth = async () => {
    if (!idRef.current.value) {
      alert("아이디를 입력해주시기 바랍니다.");
      return false;
    }
    try {
      const response = await Bootpay.requestAuthentication({
        application_id: APID,
        pg: "다날",
        order_name: "본인인증",
        authentication_id: AUTHID,
      });
      switch (response.event) {
        case "done":
          const { receipt_id, status } = response.data;
          if (status == 12) {
            const authResult = await NormalReqApi(
              "post",
              `/bp/findpwd/${receipt_id}`,
              { id: idRef.current.value }
            );
            // console.log(authResult);
            if (authResult.flag) {
              navi(`/findpwdresult`, {
                state: {
                  number: authResult.data,
                },
              });
              // navi("/findidresult", {
              //   state: {
              //     id: authResult.data.id,
              //   },
              // });
            } else {
              alert("일치하는 회원 정보가 없습니다");
              return false;
            }
            // if (!authResult.flag) {
            //   alert("이미 가입되어있는 사용자 입니다.");
            //   return false;
            // } else {
            //   const temp = {};
            //   temp.di = authResult.data.authenticate_data.di;
            //   temp.name = authResult.data.authenticate_data.name;
            //   temp.unique = authResult.data.authenticate_data.unique;
            //   // return { ...temp };

            //   navi("/signupnormal");
            // }
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e.message);
      switch (e.event) {
        case "cancel":
          // 사용자가 결제창을 닫을때 호출
          console.log(e.message);
          break;
        case "error":
          // 결제 승인 중 오류 발생시 호출
          console.log(e.error_code);
          break;
      }
    }
  };
  return (
    <div className={"container sub"}>
      {/* <div className={"subVisual mypage"}></div> */}
      <div className={`sub_container`} style={{ marginTop: "3.5em" }}>
        <div className={`contents mypage`} style={{ height: "100%" }}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>비밀번호 찾기</span>
          </h2>
          <div style={{ padding: "1em 0" }}>
            <p style={{ marginBottom: "1em", fontSize: "0.85em" }}></p>
            <div
              style={{
                border: "1px solid #dbdbdb",
                padding: "1em",
                width: "100%",
                display: "flex",

                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div>
                <div>
                  <label style={{ marginRight: "1em", fontSize: "0.85em" }}>
                    아이디{" "}
                  </label>
                  <input
                    type="text"
                    ref={idRef}
                    style={{
                      border: "1px solid #dbdbdb",
                      borderRadius: "5px",
                      padding: "0.2em 0.5em",
                      fontSize: "0.9em",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      color: "white",
                      backgroundColor: "#263050",
                      border: "none",
                      display: "block",
                      marginBottom: "0.5em",
                      padding: "0.5em",
                      fontSize: "0.75em",
                      boxSize: "border-box",
                      cursor: "pointer",
                    }}
                    onClick={handleAuth}
                  >
                    본인 인증 (PASS)
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPwd;
