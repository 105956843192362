import React, { useState } from "react";
import _ from "lodash";
import update from "react-addons-update";

import custom from "../../assets/css/login.module.scss";

const Terms = ({ terms, setTerms }) => {
  const [allCheck, setAllCheck] = useState(false);

  const handleAllCheck = () => {
    setAllCheck(!allCheck);
  };

  const handleCheck = (index, item) => {
    let tempList = _.cloneDeep(terms);
    tempList = update(tempList, {
      [index]: {
        checked: { $set: !item.checked },
      },
    });
    setTerms(tempList);
  };

  return (
    <div className={custom.terms_list}>
      <ul className={custom.checkBox}>
        {terms.map((item, index) => (
          <li key={`terms_${index}`}>
            <div
              className={
                custom.input_area + " " + custom.customChk + " customChk"
              }
            >
              <input
                type="checkbox"
                className={
                  custom.inp_rdo + " " + (allCheck && "checkboxActive")
                }
                id={`terms_${index}`}
                defaultChecked={allCheck}
                onClick={() => handleCheck(index, item)}
              />
              <label htmlFor={`terms_${index}`}>{item.label}</label>
              <a
                href={`#modal${item.id}`}
                className={custom.btn_terms + " " + custom.terms01}
              >
                <span>자세히 보기</span>
              </a>
            </div>
            <div data-ml-modal id={`modal${item.id}`}>
              <a href="#!" className="modal-overlay"></a>
              <div className="modal-dialog modal-dialog-lg">
                <a href="#!" className="modal-close">
                  &times;
                </a>
                <h3>{item.label}</h3>
                <div
                  className="modal-content"
                  dangerouslySetInnerHTML={{ __html: item.term }}
                ></div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Terms;
