import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const bgColorSelect = atom({
  key: "bgColorSelect",
  default: {
    change: false,
  },
  // effects_UNSTABLE: [persistAtom],
});

export const compSelect = atom({
  key: "compSelect",
  default: {
    email: "",
    nickName: "",
    name: "",
    phone: "",
    password: "",
    emailYn: false,
  },
});

export const authSelect = atom({
  key: "authSelect",
  default: {
    birth: "",
    di: "",
    gender: 0,
    name: "",
    phone: "",
    unique: "",
    emailYn: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const accountSelect = atom({
  key: "accountSelect",
  default: {
    id: undefined,
    accessToken: undefined,
    role: "COMPANY",
    refreshToken: undefined,
  },
  effects_UNSTABLE: [persistAtom],
});

export const pageSelect = atom({
  key: "pageSelect",
  default: {
    curPage: "home",
  },
  effects_UNSTABLE: [persistAtom],
});

export const commonSelect = atom({
  key: "commonSelect",
  default: {
    loading: true,
    category: [],
    host: [],
    totalReward: [],
    target: [],
    region: [],
    ag: [],
  },
});

export const productSelect = atom({
  key: "productSelect",
  default: {
    product: [],
  },
});

export const challengeSelect = atom({
  key: "challengeSelect",
  default: {
    loading: true,
    team: {
      loading: true,
      data: [],
    },
    after: {
      loading: true,
      data: [],
    },
    qna: {
      loading: true,
      data: [],
    },
    free: {
      loading: true,
      data: [],
    },
  },
});
