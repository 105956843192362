export const dataList = [
  {
    label: "ㄱ",
    ordered: 1,
    name: "가톨릭대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/1.png",
    univLink: "http://red.cu.ac.kr/DCU_Portal/pages/Logon.jsp",
  },
  {
    label: "ㄱ",
    ordered: 2,
    name: "감리교신학대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/2.png",
    univLink: "http://tis.mtu.ac.kr/",
  },
  {
    label: "ㄱ",
    ordered: 3,
    name: "강남대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/3.png",
    univLink: "https://cafe.naver.com/knustudent",
  },
  {
    label: "ㄱ",
    ordered: 4,
    name: "강원대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/4.png",
    univLink: "https://www.kangwon.ac.kr/www/index.do",
  },
  {
    label: "ㄱ",
    ordered: 5,
    name: "건국대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/5.png",
    univLink: "https://portal.konkuk.ac.kr/",
  },
  {
    label: "ㄱ",
    ordered: 6,
    name: "건국대학교 글로벌캠퍼스",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/6.png",
    univLink: "https://student.kku.ac.kr/main.do",
  },
  {
    label: "ㄱ",
    ordered: 7,
    name: "경기대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/7.png",
    univLink: "https://www.kyonggi.ac.kr/",
  },
  {
    label: "ㄱ",
    ordered: 8,
    name: "대구경북과학기술원",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/8.png",
    univLink: "https://www.dgist.ac.kr/kr/",
  },
  {
    label: "ㄱ",
    ordered: 9,
    name: "경북대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/9.png",
    univLink: "https://www.knu.ac.kr/wbbs/wbbs/main/main.action",
  },
  {
    label: "ㄱ",
    ordered: 10,
    name: "경상대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/10.png",
    univLink: "http://gsnu.info/page/aoc.php",
  },
  {
    label: "ㄱ",
    ordered: 11,
    name: "경운대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/11.png",
    univLink: "https://www.ikw.ac.kr/main.tc",
  },
  {
    label: "ㄱ",
    ordered: 12,
    name: "경희대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/12.png",
    univLink: "http://www.khuplaza.com/partnership/15127526",
  },
  {
    label: "ㄱ",
    ordered: 13,
    name: "계원예술대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/13.png",
    univLink:
      "https://login.kaywon.ac.kr/?ret=http://kuad.kaywon.ac.kr/sso/login.aspx",
  },
  {
    label: "ㄱ",
    ordered: 14,
    name: "고려대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/14.png",
    univLink: "https://www.koreapas.com/bbs/aoc.php",
  },
  {
    label: "ㄱ",
    ordered: 14,
    name: "고려대학교 세종캠퍼스",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/15.png",
    univLink: "https://kuple.kr/AOC",
  },
  {
    label: "ㄱ",
    ordered: 15,
    name: "광운대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/16.png",
    univLink: "https://www.kw.ac.kr/ko/",
  },
  {
    label: "ㄱ",
    ordered: 16,
    name: "국민대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/17.png",
    univLink: "https://ktis.kookmin.ac.kr/",
  },
];

export const dataList2 = [
  {
    label: "ㄷ",
    ordered: 1,
    name: "단국대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/18.png",
    univLink: "http://www.dankook.ac.kr/web/kor",
  },
  {
    label: "ㄷ",
    ordered: 2,
    name: "대진대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/19.png",
    univLink: "http://www.daejin.ac.kr/index.do?sso=ok",
  },
  {
    label: "ㄷ",
    ordered: 3,
    name: "덕성여자대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/20.png",
    univLink: "https://sso.duksung.ac.kr/svc/tk/Auth.do?ac=Y&ifa=N&id=portal&",
  },
  {
    label: "ㄷ",
    ordered: 4,
    name: "동국대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/21.png",
    univLink: "https://www.dongguk.edu/main",
  },
  {
    label: "ㄷ",
    ordered: 5,
    name: "동덕여자대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/22.png",
    univLink: "https://www.dongduk.ac.kr/kor/main.do",
  },
  {
    label: "ㄷ",
    ordered: 6,
    name: "동아방송예술대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/23.png",
    univLink: "https://portal.dima.ac.kr/",
  },
];

export const dataList3 = [
  {
    label: "ㅁ",
    ordered: 1,
    name: "명지대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/24.png",
    univLink: "https://www.mju.ac.kr/sites/mjukr/intro/intro.html",
  },
];

export const dataList4 = [
  {
    label: "ㅂ",
    ordered: 1,
    name: "배제대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/25.png",
    univLink:
      "https://tis.pcu.ac.kr/projects/service/LaunchProject_EmulateIE9.jsp",
  },
  {
    label: "ㅂ",
    ordered: 2,
    name: "부산대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/26.png",
    univLink: "http://aistc.com/aoc",
  },
];
export const dataList5 = [
  {
    label: "ㅅ",
    ordered: 1,
    name: "삼육대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/27.png",
    univLink: "https://suwings.syu.ac.kr",
  },
  {
    label: "ㅅ",
    ordered: 2,
    name: "서강대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/28.png",
    univLink: "http://iic.sogang.ac.kr/@online/main/aoc.asp",
  },
  {
    label: "ㅅ",
    ordered: 3,
    name: "서울과학기술대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/29.png",
    univLink:
      "http://www.seoultechin.com/bbs/login.php?&url=http%3A%2F%2Fwww.seoultechin.com",
  },
  {
    label: "ㅅ",
    ordered: 4,
    name: "서울디지털대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/30.png",
    univLink: "http://stud.sdu.ac.kr/Board/Event_FreeBook/ImageList.asp",
  },
  {
    label: "ㅅ",
    ordered: 5,
    name: "서울시립대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/31.png",
    univLink: "https://portal.uos.ac.kr/user/login.face",
  },
  {
    label: "ㅅ",
    ordered: 6,
    name: "서울신학대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/32.png",
    univLink: "https://portal.stu.ac.kr/p/INTMENSDT/",
  },
  {
    label: "ㅅ",
    ordered: 7,
    name: "서울여자대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/33.png",
    univLink: "http://www.swu.ac.kr/index.do",
  },
  {
    label: "ㅅ",
    ordered: 8,
    name: "성균관대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/34.png",
    univLink: "https://www.skku.edu/skku/index.do",
  },
  {
    label: "ㅅ",
    ordered: 9,
    name: "세종대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/35.png",
    univLink: "http://cafe.naver.com/welfaresejong",
  },
  {
    label: "ㅅ",
    ordered: 10,
    name: "숙명여자대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/36.png",
    univLink: "https://snowe.sookmyung.ac.kr/bbs5/boards/smpeople/1120448",
  },
  {
    label: "ㅅ",
    ordered: 11,
    name: "숭실대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/37.png",
    univLink: "https://ssu.ac.kr/",
  },
  {
    label: "ㅅ",
    ordered: 12,
    name: "숭실사이버대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/38.png",
    univLink: "https://ssu.ac.kr/",
  },
  {
    label: "ㅅ",
    ordered: 13,
    name: "신라대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/39.png",
    univLink:
      "http://www.silla.ac.kr/ko/index.php?pCode=saleinfo&CgCode=0002&mode=view&idx=21011",
  },
];
export const dataList6 = [
  {
    label: "ㅇ",
    ordered: 1,
    name: "아주대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/40.png",
    univLink: "https://www.ajou.ac.kr/kr/index.do",
  },
  {
    label: "ㅇ",
    ordered: 2,
    name: "연세대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/41.png",
    univLink:
      "http://student.yonsei.ac.kr/?act=board&bbs_code=appleoncampus&bbs_mode=view&bbs_seq=4870",
  },
  {
    label: "ㅇ",
    ordered: 3,
    name: "영남대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/42.png",
    univLink: "https://www.yu.ac.kr/main/index.do",
  },
  {
    label: "ㅇ",
    ordered: 4,
    name: "영진전문대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/43.png",
    univLink: "http://www.yjp.ac.kr",
  },
  {
    label: "ㅇ",
    ordered: 5,
    name: "울산과학기술원",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/44.png",
    univLink: "https://portal.unist.ac.kr/irj/portal",
  },
  {
    label: "ㅇ",
    ordered: 6,
    name: "울산대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/45.png",
    univLink: "http://uwins.ulsan.ac.kr/Login.aspx",
  },
  {
    label: "ㅇ",
    ordered: 7,
    name: "인제대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/46.png",
    univLink: "https://www.inje.ac.kr/kor/main/main.asp",
  },
  {
    label: "ㅇ",
    ordered: 8,
    name: "인하대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/47.png",
    univLink: "https://portal.inha.ac.kr/",
  },
];
export const dataList7 = [
  {
    label: "ㅈ",
    ordered: 1,
    name: "전주대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/48.png",
    univLink: "https://www.jj.ac.kr/jj/index.jsp",
  },
  {
    label: "ㅈ",
    ordered: 2,
    name: "제주한라대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/49.png",
    univLink: "https://sso.chu.ac.kr",
  },
  {
    label: "ㅈ",
    ordered: 3,
    name: "중앙대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/50.png",
    univLink: "https://www.cau.ac.kr/index.do",
  },
];
export const dataList8 = [
  {
    label: "ㅊ",
    ordered: 1,
    name: "추계예술대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/51.png",
    univLink: "https://cis.chugye.ac.kr/login.do",
  },
  {
    label: "ㅊ",
    ordered: 2,
    name: "충남대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/52.png",
    univLink: "https://plus.cnu.ac.kr/html/kr/",
  },
];

export const dataList9 = [
  {
    label: "ㅋ",
    ordered: 1,
    name: "카이스트",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/53.png",
    univLink: "https://ara.kaist.ac.kr/all/515196/?page_no=1",
  },
];
export const dataList10 = [
  {
    label: "ㅍ",
    ordered: 1,
    name: "포스텍",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/54.png",
    univLink: "http://popo.postech.ac.kr/",
  },
];
export const dataList11 = [
  {
    label: "ㅎ",
    ordered: 1,
    name: "한국예술종합학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/55.png",
    univLink: "https://nuri.karts.ac.kr/nuri/login.do",
  },
  {
    label: "ㅎ",
    ordered: 2,
    name: "한국항공대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/56.png",
    univLink: "https://portal.kau.ac.kr/",
  },
  {
    label: "ㅎ",
    ordered: 3,
    name: "한동대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/57.png",
    univLink: "https://www.handong.edu/",
  },
  {
    label: "ㅎ",
    ordered: 4,
    name: "한양대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/58.png",
    univLink: "http://www.weehan.com/ad_list/6358595",
  },
  {
    label: "ㅎ",
    ordered: 5,
    name: "한양대학교 에리카",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/59.png",
    univLink: "https://www.hanyang.ac.kr/",
  },
  {
    label: "ㅎ",
    ordered: 6,
    name: "한양사이버대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/60.png",
    univLink: "https://www.hycu.ac.kr/user/index.do",
  },
  {
    label: "ㅎ",
    ordered: 7,
    name: "홍익대학교",
    img: "https://s3.ap-northeast-2.amazonaws.com/detizen.archive/contents/univ/61.png",
    univLink:
      "http://www.hongik.ac.kr/login.do?Refer=http://apps.hongik.ac.kr/aoc/check.php",
  },
];

export const UniList = [
  ...dataList,
  ...dataList2,
  ...dataList3,
  ...dataList4,
  ...dataList5,
  ...dataList6,
  ...dataList7,
  ...dataList8,
  ...dataList9,
  ...dataList10,
  ...dataList11,
];
