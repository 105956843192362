import React from "react";
import { Link } from "react-router-dom";
import headerStyle from "../../assets/css/contest.module.scss";

const HostCenterHeaderTabs = ({ current }) => {
  return (
    <div
      className={`subVisual hostcenter ${headerStyle.subVisual}`}
      style={{ flexDirection: "column" }}
    >
      <ul className={`sub_menu ${headerStyle.sub_menu}`}>
        <li className={current === "freecontest" ? "active" : null}>
          <Link to="/freeContest">
            <span>무료등록</span>
          </Link>
        </li>
        <li className={current === "promotion" ? "active" : null}>
          <Link to="/promotion">
            <span>광고 서비스</span>
          </Link>
        </li>
        <li className={current === "agent" ? "active" : null}>
          <Link to="/agent">
            <span>대행 서비스</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default HostCenterHeaderTabs;
