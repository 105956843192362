import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const EventHeader = () => {
  return (
    <HeaderWarp>
      <HeaderMenu>
        <HeaderMenuItem>
          <Link to={`/event`}>
            <span>이벤트</span>
          </Link>
        </HeaderMenuItem>
      </HeaderMenu>
    </HeaderWarp>
  );
};

export default EventHeader;

const HeaderWarp = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.5em;
  width: 100%;
  height: 14.5em;
`;

const HeaderMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 0.25em;
  width: 100%;
  max-width: 1200px;
`;

const HeaderMenuItem = styled.li`
  margin: 0.25em 0em 0.25em 0;
  width: calc((100% - 1.5em) / 4);

  a {
    display: block;
    padding: 0.5em;
    color: #323232;
    background-color: #fff;
    border: 1px solid #fff;
    font-size: 0.667em;
    text-align: center;

    span {
      position: relative;

      &:after {
        position: absolute;
        content: "";
        right: -0.65em;
        width: 0.35em;
        height: 0.35em;
        bottom: 0.15em;
        border-radius: 1em;
        background-color: #e9360c;
        opacity: 1;
        transition: 300ms;
      }
    }
  }
`;
