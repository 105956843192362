import React, { useRef, useState } from "react";
import { ClientReqPatch } from "../../../utils/ClientAuth";

const ChangeNick = ({ nickName, account, atkChange, close, refetch }) => {
  const nickRef = useRef(null);
  const [error, setError] = useState({
    flag: false,
    msg: "",
  });

  const isValid = (inputText) => {
    if (!inputText || inputText.length < 2) {
      alert("2자리 이상 입력해주시기 바랍니다");
      return false;
    } else {
      if (inputText.replaceAll(" ", "").length < 2) {
        alert("2자리 이상 입력해주시기 바랍니다");
        return false;
      }
    }
    return true;
  };

  const handleChange = async () => {
    setError({
      flag: false,
      msg: "",
    });
    const value = nickRef.current.value;
    if (isValid(value)) {
      const result = await ClientReqPatch(
        `/user/m/nickname`,
        { nickName: value },
        account.accessToken,
        account.refreshToken,
        atkChange
      );
      if (result.statusCode === 200) {
        if (result.flag) {
          alert("성공적으로 변경되었습니다");
          refetch();
          close();
        } else {
          setError({
            flag: true,
            msg: result.msg,
          });
        }
      }
    }
  };

  return (
    <>
      <div className={"slideInputArea"}>
        <div className={"slidePwd"}>
          <label htmlFor={"nickName"}>닉네임</label>
          <input
            type="text"
            id="nickName"
            ref={nickRef}
            defaultValue={nickName}
          />
          {error.flag && <p className={"slideWarning"}>{error.msg}</p>}
        </div>
      </div>
      <div className={"slideInputBtns"}>
        <button
          type="button"
          className={"slideInputSubmit"}
          onClick={handleChange}
        >
          <span>변경</span>
        </button>
      </div>
    </>
  );
};
export default ChangeNick;
