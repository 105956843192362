import React, { Suspense } from "react";
import {
  DContentDepicSubContainer,
  DContentsSubMenu,
  DContentSubContainer,
} from "../../components/DContents/Common/Element";
import Cestyle from "../../assets/css/event.module.scss";
import { Link } from "react-router-dom";
import Default280 from "../../assets/img/common/img_posterDefault_280.jpg";
import { isMobile } from "react-device-detect";
import EmptyDataList from "../../components/DContents/Mobile/EmptyDataList";
import EmptyTimeList from "../../components/DContents/EmptyTime/list";
import { EmptyList_list_noData } from "../../components/DContents/EmptyTime/empty";

const EmptyTime = () => {
  // if (isMobile) {
  //   return (
  //     <div className={"container sub"}>
  //       <DContentsSubMenu current={"emptytime"} />
  //       <div className={"sub_container"}>
  //         <Suspense fallback={<div></div>}>
  //           <EmptyDataList />
  //         </Suspense>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="container sub">
      <DContentsSubMenu current={"emptytime"} />

      <DContentSubContainer
        label={"공강시간"}
        subLabel={"대티즌이 전하는 알아두면 도움이 되는 지식"}
      >
        <div className={`list_host ${Cestyle.list_host}`}>
          {/* <form onSubmit={onSubmit}> */}
          <Suspense fallback={<EmptyList_list_noData />}>
            <EmptyTimeList />
          </Suspense>

          {/* </form> */}
        </div>
      </DContentSubContainer>
    </div>
  );
};

export default EmptyTime;
