import React from "react";
import Cmstyle from "../../../assets/css/community.module.scss";

export const Empty_Depic_list = () => {
  return (
    <>
      <div className={`board_search ${Cmstyle.board_search}`}>
        <select name="searchType">
          <option value="0">선택</option>
          <option value="title">제목</option>
        </select>
        <input type="text" name="searchWrd" />
        <button type="button">검색</button>
      </div>
      <div className="board_list video depic">
        <ul className={"thumbList depic"}>
          <li></li>
        </ul>
      </div>
    </>
  );
};
