import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ContentBody,
  ContentButton,
  ContentHeader,
  Header,
} from "../../components/Challenge/MobileTemplate/Layout";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientOptionGet } from "../../utils/ClientAuth";
import { NormalReqApi } from "../../utils/ReqApi";

const FbViewMobile = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const goBack = () => {
    navi(-1);
  };

  const { data, error, isLoading } = useQuery(
    ["fbView", id],
    async () =>
      await ClientOptionGet(
        `/freeboard/view?id=${id}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false }
  );
  console.log(data);

  return (
    <Header
      label={"자유게시판"}
      subLabel={"익명으로 우리들만의 고민을 이야기해봐요!"}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ContentHeader {...data.result.data} />
          <ContentBody {...data.result.data} />
          <ContentButton
            id={id}
            goBack={goBack}
            type={"freeboard"}
            account={account}
            toggle={toggle}
          />
        </>
      )}
    </Header>
  );
};

export default FbViewMobile;
