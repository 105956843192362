import _ from "lodash";
import moment from "moment";
import React from "react";
import { HiClock, HiGift, HiGlobe, HiPencil, HiUser } from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Default from "../../assets/img/common/img_posterDefault_280.jpg";
import { ClientReqGet, ClientReqGet2 } from "../../utils/ClientAuth";
import {
  Host,
  ListDataLi,
  ListDataUl,
  RegDate,
  Status,
  SubInfoArea,
  SubInfoText,
  Title,
  ListDataBody,
} from "./UserTemplate";

// const EventStatus = (startDate, endDate) => {
//   // const nowDate = moment();
//   // const sDate = moment(startDate);
//   // const eDate = moment(endDate);
//   // console.log(`nDate : ${nowDate}, sDate : ${sDate} , eDate:${eDate}`);
//   // console.log(`isOpen ? ${nowDate.isAfter(sDate)}`);
//   // console.log(`isClose ? ${nowDate.isAfter(eDate)}`);
//   return <Status type="open">스크랩</Status>;
// };

const UserEventTop3 = ({ id, account, toggle }) => {
  const navi = useNavigate();
  const { data } = useQuery(
    ["myeventTop3", id, account, toggle],
    async () =>
      await ClientReqGet2(
        "/event/my/top3",
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: false,
      onSuccess: (e) => {
        if (e?.statusCode && e?.statusCode === 401) {
          navi("/signin");
        }
        // console.log(e);
        if (e?.statusCode && e?.statusCode === 404) {
          navi("/signin");
        }
        if (e?.statusCode === 400) {
          navi("/signin");
        }
      },
      onError: (e) => {
        if (e?.statusCode && e?.statusCode === 404) {
          navi("/signin");
        }
      },
    }
  );

  const handleDetail = (id) => {
    // console.log(e);
    navi(`/event/${id}`);
  };

  return (
    <ListDataUl>
      {data.data?.length > 0
        ? data.data.map((item, index) => {
            const nowDate = moment();
            const sDate = moment(item.startDate);
            const eDate = moment(item.endDate);

            const eventStatus = {
              style: "ready",
              text: "예정",
            };
            if (nowDate.isBetween(sDate, eDate)) {
              //open
              eventStatus.style = "open";
              eventStatus.text = "진행중";
            } else if (nowDate.isBefore(sDate)) {
              //ready
              eventStatus.style = "ready";
              eventStatus.text = "예정";
            } else if (nowDate.isAfter(eDate)) {
              //close
              eventStatus.style = "done";
              eventStatus.text = "마감";
            }

            return item.submitUser.map((uItem, uIndex) => {
              return (
                <ListDataLi
                  key={`m_e_s_${index}_${uIndex}`}
                  onClick={() => handleDetail(item._id)}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_AWS_ENDPOINT +
                        "w_280/" +
                        item.thumbnail.path
                      }
                      style={{
                        width: "175px",
                        height: "194px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <ListDataBody>
                    <div>
                      <Status type={eventStatus.style}>
                        {eventStatus.text}
                      </Status>
                      <Title>{item.title}</Title>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <SubInfoArea>
                        <HiUser size={15} />
                        <SubInfoText>{item.target}</SubInfoText>
                      </SubInfoArea>
                      <SubInfoArea bottom={true}>
                        <HiGift size={15} />
                        <SubInfoText>{item.reward}</SubInfoText>
                      </SubInfoArea>

                      {/* <RegDate>
                        스크랩 날짜 :
                        <span style={{ marginLeft: "1em" }}>
                          {moment(uItem.insertDate).format("YYYY-MM-DD")}
                        </span>
                      </RegDate> */}
                    </div>
                  </ListDataBody>
                </ListDataLi>
              );
            });
          })
        : ""}
    </ListDataUl>
  );
};
export default UserEventTop3;
