import React from "react";
import {
  HPHeader,
  HPMiddleSection,
  HPTitle,
  HPTitleSub,
  HPTopSection,
} from "../../components/HostCenter/Mobile/MenuList";
import agentImg from "../../assets/img/common/agentImg.jpeg";

const AgentMobile = () => {
  return (
    <HPHeader current={"agent"}>
      <HPTopSection>
        <HPTitle>
          공모전 & 서포터즈는
          <br /> 대티즌이 전문가 입니다
        </HPTitle>
        <HPTitleSub style={{ marginTop: ".5em" }}>
          귀사의 성공적인 공모전을 위한 다양한 서비스를 이용하세요
        </HPTitleSub>
      </HPTopSection>
      <HPMiddleSection>
        <img src={agentImg} />
      </HPMiddleSection>
    </HPHeader>
  );
};

export default AgentMobile;
