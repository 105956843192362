import moment from "moment";
import React from "react";
import styled from "styled-components";

const ReplyLi = styled.li`
  padding: 0.25em 0em;
  margin-bottom: 0.25em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  > p {
    display: block;
    &.replyBoardTitle {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: gray;
      font-size: 0.55em;
      font-weight: 300;
    }
    &.replyText {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-size: 0.65em;
    }
    &.replyDate {
      text-align: right;
      font-size: 0.55em;
      color: gray;
    }
  }
`;

const NoData = styled.li`
  font-size: 0.75em;
  margin-top: 0.25em;
  font-weight: 500;
`;

export const UserReplyNoContent = () => {
  return <NoData>등록된 댓글이 없습니다</NoData>;
};

export const UserReplyItem = ({ title, replyes, link }) => {
  return (
    <ReplyLi onClick={link}>
      <p className={"replyBoardTitle"}>{title}</p>
      <p className={"replyText"}>{replyes.text}</p>
      <p className={"replyDate"}>
        {moment(replyes.createdAt).format("YYYY-MM-DD")}
      </p>
    </ReplyLi>
  );
};
