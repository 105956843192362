import React, { useRef, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { useQuery } from "react-query";
import styled from "styled-components";
import { MPaging2 } from "../../components/Common/PagingM";
import {
  Loading,
  SearchArea,
  ThumbnailList,
  ViewHeader,
} from "../../components/DContents/Mobile/MTemplate";
import { NormalReqApi } from "../../utils/ReqApi";

const EP = process.env.REACT_APP_AWS_ENDPOINT;
const DepicMobile = () => {
  const [page, setPage] = useState(1);
  const [sc, setSc] = useState({
    keyword: "",
    keyType: "title",
  });
  const wordRef = useRef(null);
  const handleSearch = () => {
    const keyword = wordRef.current.value;
    setSc({
      ...sc,
      keyword,
    });
  };
  const handlePage = (page) => {
    window.scrollTo(0, 0);
    setPage(page * 1);
  };
  const { data, error, isLoading } = useQuery(
    ["depicList", page, sc],
    async () =>
      await NormalReqApi(
        "get",
        `/depic/list?page=${page}&keyType=${sc.keyType}&keyword=${sc.keyword}`
      ),
    { retry: true }
  );

  return (
    <ViewHeader label={"대픽영상"}>
      <SearchArea>
        <input type="text" ref={wordRef} className={"dcSearchTitle"} />
        <HiSearch
          color={"white"}
          className={"dcSearchBtn"}
          onClick={handleSearch}
        />
      </SearchArea>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ul>
            {data.data.result.map((item, index) => (
              <ThumbnailList
                key={`dc_list_depic_${index}`}
                {...item}
                url={`/depic/${item._id}`}
                src={EP + "w_280/" + item.thumbnail.path}
              />
            ))}
          </ul>
          <MPaging2 data={data} curPage={page} toggle={handlePage} />
        </>
      )}
    </ViewHeader>
  );
};

export default DepicMobile;
