import DOMPurify from "dompurify";
import React, { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ChallengeSubHead,
  ChallengeSubMenu,
} from "../../components/Challenge/Common/Element";
import {
  AfterEdit,
  FreeBEdit,
  QnaEdit,
  TeamEdit,
} from "../../components/Challenge/Common/Form";
import { accountSelect } from "../../recoil/AtomConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ClientReqPatch } from "../../utils/ClientAuth";
import { handleFileUpload } from "../../utils/FileController";

const ChallengeEdit = () => {
  const { id, type } = useParams();
  const [account, setAccount] = useRecoilState(accountSelect);

  const sanitizer = DOMPurify.sanitize;
  const navi = useNavigate();
  const [curType, setCurType] = useState({
    submenu: "",
    kor: "",
    postUrl: "",
  });

  useEffect(() => {
    const tempType = { submenu: "", kor: "" };
    switch (type) {
      case "t":
        tempType.submenu = "teamplay";
        tempType.postUrl = "/teamplay";
        tempType.kor = "팀플레이 등록";
        break;
      case "a":
        tempType.submenu = "after";
        tempType.postUrl = "/after";
        tempType.kor = "활동후기 등록";
        break;
      case "q":
        tempType.submenu = "qna";
        tempType.postUrl = "/qna";
        tempType.kor = "활동Q&A 등록";
        break;
      case "f":
        tempType.submenu = "freeboard";
        tempType.postUrl = "/freeboard";
        tempType.kor = "자유게시판 등록";
        break;
    }
    setCurType({ ...tempType });
  }, [type]);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleEditorChange = (editor, type) => {
    setValue(type, sanitizer(editor.getHTML()));
  };

  const callback = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };

  const onSubmit = async (data) => {
    if (data.file.length > 0) {
      const dataList = [
        { file: data.file[0], type: "attachment", objectTarget: "file" },
      ];

      const cData = await handleFileUpload(dataList, data);
      if (!cData) {
        return false;
      }
      data.file = { ...cData.file };
    } else {
      data.file = {};
    }
    const res = await ClientReqPatch(
      `${curType.postUrl}/${id}`,
      { ...data },
      account.accessToken,
      account.refreshToken,
      callback
    );

    if (res.statusCode === 200) {
      alert("성공적으로 수정되었습니다.");
      navi(`${curType.postUrl}`);
    } else {
      alert("수정 중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다.");
      return false;
    }
  };

  const renderForm = (type) => {
    switch (type) {
      case "t":
        return (
          <TeamEdit
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            editorChange={handleEditorChange}
            id={id}
          />
        );
      case "a":
        return (
          <AfterEdit
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            editorChange={handleEditorChange}
            id={id}
          />
        );
      case "q":
        return (
          <QnaEdit
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            editorChange={handleEditorChange}
            id={id}
          />
        );
      case "f":
        return (
          <FreeBEdit
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            editorChange={handleEditorChange}
            id={id}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={curType.submenu} />

      <ChallengeSubHead label={curType.kor}>
        <div className={"board_view teamplay"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderForm(type)}
            <div className={"btn_area board"}>
              <button
                type="button"
                className={"btn"}
                style={{ marginRight: "1em" }}
                onClick={() => navi(curType.postUrl)}
              >
                목록으로
              </button>
              <button className={"btn"}>등록</button>
            </div>
          </form>
        </div>
      </ChallengeSubHead>
    </div>
  );
};

export default ChallengeEdit;
