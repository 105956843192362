import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { errorSelector, useRecoilState } from "recoil";
import { authSelect } from "../../recoil/AtomConfig";
import { LoginHeader } from "../../components/Login/LoginHeader";
import custom from "../../assets/css/login.module.scss";
import loadStyle from "../../assets/css/loader.module.scss";
import { HiCheckCircle } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NormalReqApi } from "../../utils/ReqApi";

const phoneRegExp = /^[0-9]{8,}$/;
const passwordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?^&])[A-Za-z\d@$!%*^#?&]{8,}$/;

const inputSchema = yup.object({
  name: yup.string().required("이름을 입력해주시기 바랍니다."),
  email: yup
    .string()
    .email("이메일 형식에 맞게 입력해주시기 바랍니다")
    .required("이메일을 입력해주시기 바랍니다."),
  nickName: yup.string().required("닉네임을 입력해주시기 바랍니다."),
  phone: yup
    .string()
    .required("휴대전화 번호를 입력해주시기 바랍니다.")
    .matches(phoneRegExp, "'-'을 제외한 숫자만 입력해주시기 바랍니다."),
  password: yup
    .string()
    .required("비밀번호를 입력해주시기 바랍니다.")
    .matches(
      passwordRegExp,
      "특수문자,영문 대소문자, 숫자를 포함한 비밀번호를 입력해주시기 바랍니다."
    ),
  passwordCheck: yup
    .string()
    .oneOf([yup.ref("password"), null], "비밀번호를 확인해주시기 바랍니다."),
});

const SignupNormal = () => {
  const [auth, setAuth] = useRecoilState(authSelect);
  const [inputData, setInputData] = useState({
    email: "",
    emailConfirm: false,
    nickName: "",
    nickNameConfirm: false,
  });
  const [checkData, setCheckData] = useState({
    email: false,
    nickName: false,
  });
  const [btnFlag, setBtnFlag] = useState(false);
  const navi = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(inputSchema) });

  // console.log(errors);

  //   아이디, 닉네임 입력 처리
  const handleChange = async (type, e) => {
    if (type === "email") {
      setInputData({
        ...inputData,
        email: e.target.value,
        emailConfirm: false,
      });
    } else {
      setInputData({
        ...inputData,
        nickName: e.target.value,
        nickNameConfirm: false,
      });
    }
  };

  const reHandleChange = async (target) => {
    if (target === "email") {
      setInputData({
        ...inputData,
        emailConfirm: false,
      });
    } else {
      setInputData({
        ...inputData,
        nickNameConfirm: false,
      });
    }
  };

  // 아이디 중복 검사
  const handleIdCheck = async () => {
    // console.log(inputData.email);
    const response = await NormalReqApi(
      "get",
      "/user/check/dupli?type=id&value=" + inputData.email
    );
    if (response.flag) {
      if (!response.data) {
        setInputData({
          ...inputData,
          emailConfirm: true,
        });
        setCheckData({
          ...checkData,
          email: true,
        });
      } else if (response.data) {
        alert("이미 사용중인 아이디 입니다.");
        setInputData({
          ...inputData,
          emailConfirm: false,
        });
        setCheckData({
          ...checkData,
          email: false,
        });
        return false;
      }
    }
    // console.log(response);
  };

  //   닉네임 중복 검사
  const handleNickCheck = async () => {
    // console.log(inputData.nickName);
    const response = await NormalReqApi(
      "get",
      "/user/check/dupli?type=nick&value=" + inputData.nickName
    );
    if (response.flag) {
      if (!response.data) {
        setInputData({
          ...inputData,
          nickNameConfirm: true,
        });
        setCheckData({
          ...checkData,
          nickName: true,
        });
      } else if (response.data) {
        alert("이미 사용중인 닉네임 입니다.");
        setInputData({
          ...inputData,
          nickNameConfirm: false,
        });
        setCheckData({
          ...checkData,
          nickName: false,
        });
        return false;
      }
    }
  };

  const onSubmit = async (data) => {
    if (checkData.email && checkData.nickName) {
      setBtnFlag(true);
      // console.log(data);
      const response = await NormalReqApi("post", "/user/signup", { ...data });
      // console.log(response);
      if (response.statusCode === 200) {
        if (response.data) {
          alert("성공적으로 가입되었습니다. 로그인 페이지로 이동합니다.");
          setAuth((prev) => {
            const temp = { ...prev };
            temp.name = "";
            temp.di = "";
            temp.birth = "";
            temp.gender = "";
            temp.unique = "";
            temp.emailYn = false;
            return { ...temp };
          });
          navi("/signin");
        } else {
          alert("가입중 문제가 발생했습니다. 다시한번 시도해주시기 바랍니다.");
          setBtnFlag(false);
          return false;
        }
      }
    } else {
      if (!checkData.email) {
        alert("아이디 중복확인을 해주시기 바랍니다.");
        return false;
      }
      if (!checkData.nickName) {
        alert("닉네임 중복확인을 해주시기 바랍니다.");
        return false;
      }
    }
  };

  useEffect(() => {
    setValue("name", auth.name);
    setValue("userType", "normal");
    setValue("di", auth.di);
    setValue("birth", auth.birth);
    setValue("gender", auth.gender * 1);
    setValue("unique", auth.unique);
    setValue("phone", auth.phone);
    setValue("emailYn", auth.emailYn);
  }, [auth]);

  return (
    <LoginHeader>
      <div className={custom.join_box}>
        <div className={custom.tab_area}>
          <ul>
            <li className={custom.active}>
              <a>개인회원</a>
            </li>
            <li>
              <Link to={`/signupcomp`}>기업회원</Link>
            </li>
          </ul>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={custom.tab_content}>
            <div className={custom.join_info}>
              <h2 className={"scdTit " + custom.scdTit}>회원 정보 입력</h2>
              <ul>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " " + "w20"}>
                      아이디(이메일)
                    </label>
                    <div className={custom.addonButton}>
                      <input
                        type="text"
                        className={custom.inp_text + " w80"}
                        disabled={inputData.emailConfirm}
                        {...register("email", {
                          onChange: (e) => handleChange("email", e),
                        })}
                      />
                      {inputData.emailConfirm ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <HiCheckCircle color="green" size={30} />
                        </div>
                      ) : (
                        <button
                          type="button"
                          className={custom.searchBtn}
                          onClick={handleIdCheck}
                        >
                          중복확인
                        </button>
                      )}
                      {checkData.email && (
                        <button
                          type="button"
                          className={custom.searchBtn}
                          onClick={() => reHandleChange("email")}
                        >
                          변경
                        </button>
                      )}
                    </div>
                  </div>
                  {errors.email && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.email.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " w20"}>이름</label>
                    <input
                      type="text"
                      className={custom.inp_text + " w80"}
                      defaultValue={auth.name}
                      disabled
                      {...register("name")}
                    />
                  </div>
                  {errors.name && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.name.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " w20"}>닉네임</label>
                    <div className={custom.addonButton}>
                      <input
                        type="text"
                        className={custom.inp_text + " w80"}
                        disabled={inputData.nickNameConfirm}
                        {...register("nickName", {
                          onChange: (e) => handleChange("nickName", e),
                        })}
                      />
                      {inputData.nickNameConfirm ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <HiCheckCircle color="green" size={30} />
                        </div>
                      ) : (
                        <button
                          type="button"
                          className={custom.searchBtn}
                          onClick={handleNickCheck}
                        >
                          중복확인
                        </button>
                      )}
                      {checkData.nickName && (
                        <button
                          type="button"
                          className={custom.searchBtn}
                          onClick={() => reHandleChange("nickName")}
                        >
                          변경
                        </button>
                      )}
                    </div>
                  </div>
                  {errors.nickName && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.nickName.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " w20"}>연락처</label>
                    <input
                      type="text"
                      className={custom.inp_text + " w80"}
                      defaultValue={auth.phone}
                      disabled={true}
                      {...register("phone")}
                    />
                  </div>
                  {errors.phone && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.phone.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " w20"}>
                      비밀번호
                    </label>
                    <input
                      type="password"
                      className={custom.inp_text + " w80"}
                      {...register("password")}
                    />
                  </div>
                  {errors.password && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.password.message}
                    </p>
                  )}
                  <div className={custom.input_area}>
                    <label className={custom.essential + " w20"}>
                      비밀번호 확인
                    </label>
                    <input
                      type="password"
                      className={custom.inp_text + " w80"}
                      {...register("passwordCheck")}
                    />
                  </div>
                  {errors.passwordCheck && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.passwordCheck.message}
                    </p>
                  )}
                </li>
              </ul>
            </div>
            <div className={custom.btn_areaLogin + " " + custom.join}>
              {btnFlag ? (
                <button
                  type="button"
                  className={custom.btn + " " + custom.join + " " + "btn join"}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className={loadStyle.loading_spinner}></div>
                  </div>
                </button>
              ) : (
                <button
                  className={custom.btn + " " + custom.join + " " + "btn join"}
                >
                  가입하기
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </LoginHeader>
  );
};

export default SignupNormal;
