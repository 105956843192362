import React, { useRef, useState } from "react";
import replyStyle from "../../assets/css/reply.module.scss";
import { useQuery } from "react-query";
import { ClientReqGet, ClientReqPost } from "../../utils/ClientAuth";
import { Reply } from "./ReplyItem";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";

const Replys = ({ id, type }) => {
  const [filter, setFilter] = useState({
    id,
    type,
    page: 1,
  });

  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const { data, refetch } = useQuery(
    ["replyList", filter],
    async () =>
      await ClientReqGet(
        `/reply/test?id=${filter.id}&type=${filter.type}&page=${filter.page}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: true, suspense: true }
  );

  const replyRef = useRef(null);

  const handleSubmit = async () => {
    if (!account.id || !account.accessToken) {
      alert("로그인 후 이용해주시기 바랍니다.");
      return false;
    }
    if (!replyRef.current.value) {
      return false;
    }
    if (replyRef.current.value.length < 5) {
      alert("최소 6자 이상 입력해주시기 바랍니다.");
      return false;
    }
    const template = {
      text: replyRef.current.value,
    };

    const result = await ClientReqPost(
      `/reply?type=${type}&id=${id}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      refetch();
    }
  };

  return (
    <>
      <div className={replyStyle.inputWrap}>
        <div className={replyStyle.labelArea}>
          <label className={replyStyle.label}>
            {type === "contest" ? "공모전 Q&A" : "댓글"}
          </label>
        </div>

        <div className={replyStyle.inputArea}>
          <textarea
            className={replyStyle.text}
            ref={replyRef}
            placeholder={
              type === "contest"
                ? "해당 공모전/대외활동에 궁금한 점을 적어주세요!"
                : type === "event"
                ? "해당 이벤트에 댓글을 적어주세요!"
                : ""
            }
          ></textarea>
          <button
            type="button"
            className={replyStyle.submit}
            onClick={handleSubmit}
          >
            등록
          </button>
        </div>
      </div>
      <div className={replyStyle.listWrap}>
        <div className={replyStyle.countArea}>
          <label>
            총 <span>{data.data.count}</span>건
          </label>
          {/* <HiChevronUp color={"#a8a8a8"} /> */}
        </div>
        <ul className={replyStyle.itemArea}>
          {data.data.result.length > 0
            ? data.data.result.map((item, index) => (
                <Reply
                  key={`reply_contest_${index}`}
                  refetch={refetch}
                  nickName={item.author.nickName}
                  {...item}
                />
              ))
            : ""}
          {/* {Array.from(new Array(5), (el, index) => (
          <li className={replyStyle.item} key={`reply_${index}`}>
            <div className={replyStyle.nameArea}>
              <label>abc123</label>
              <div className={replyStyle.btnArea}>
                <div className={replyStyle.btn}>
                  <span>추천</span>
                  <span>0</span>
                </div>
                <div className={replyStyle.btn}>
                  <span>비추천</span>
                  <span>0</span>
                </div>
                <div className={replyStyle.btn}>
                  <HiOutlineDotsVertical />
                </div>
              </div>
            </div>
            <div className={replyStyle.textArea}>
              <p>
                sdfsdfweafe weafsdefnikwne ilwaneifwnefaasdbnfkdhnfoawilefiu
                nawkufbdksufbkwajbnfaadwuf bukb sdfsdf wefwefwe sdf
              </p>
              <p className={replyStyle.date}>2023-01-12 13:44</p>
            </div>
            <div className={replyStyle.subReply}>
              <div className={replyStyle.subStatus}>
                <div className={replyStyle.subCount}>
                  <label>
                    답글 <span>0</span>
                  </label>
                  <HiChevronDown />
                </div>

                <span className={replyStyle.addSubReply}>답글 쓰기</span>
              </div>
              {Array.from(new Array(4), (el, sIndex) => (
                <div className={replyStyle.subItem} key={`reply_sub_${sIndex}`}>
                  <div className={replyStyle.nameArea}>
                    <label>
                      <HiChatAlt2 />
                      <span>abc123</span>
                    </label>
                    <div className={replyStyle.btnArea}>
                      <div className={replyStyle.btn}>
                        <span>추천</span>
                        <span>0</span>
                      </div>
                      <div className={replyStyle.btn}>
                        <span>비추천</span>
                        <span>0</span>
                      </div>
                      <div className={replyStyle.btn}>
                        <HiOutlineDotsVertical />
                      </div>
                    </div>
                  </div>
                  <div className={replyStyle.textArea}>
                    <p>
                      sdfsdfweafe weafsdefnikwne
                      ilwaneifwnefaasdbnfkdhnfoawilefiu
                      nawkufbdksufbkwajbnfaadwuf bukb sdfsdf wefwefwe sdf
                    </p>
                    <p className={replyStyle.date}>2023-01-12 13:44</p>
                  </div>
                  <div className={replyStyle.subReply}>
                    <div
                      className={replyStyle.subStatus + " " + replyStyle.nomore}
                    >
                      <span className={replyStyle.addSubReply}>답글 쓰기</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </li>
        ))} */}
        </ul>
      </div>
    </>
  );
};

export default Replys;
