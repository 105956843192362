import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ChallengeSubMenu,
  ChallengeView,
} from "../../components/Challenge/Common/Element";
import ReplyComponent from "../../components/Reply/ReplyComponent";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientDelReq } from "../../utils/ClientAuth";
import {
  AuthGetAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";

const TeamplayView = () => {
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const { id } = useParams();
  const [info, setInfo] = useState({
    loading: false,
    ownYn: false,
  });

  const handleGoBack = () => {
    navi("/teamplay");
  };

  const getTeamplayAuth = async (url, accessToken) => {
    const res = await AuthGetAxios(url, accessToken);

    if (res.status === 200) {
      if (res.data.statusCode === 204) {
        alert("삭제되었거나 존재하지 않는 데이터 입니다.");
        navi("/teamplay");
        return false;
      }
      setInfo({
        ...res.data.result.data,
        loading: false,
        ownYn: res.data.result.ownYn,
      });
    } else if (
      res.data.statusCode === 401 &&
      res.data.message === "토큰이 만료되었습니다."
    ) {
      const refreshToken = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const pObject = { ...prev };
        pObject.accessToken = refreshToken.data.data.accessToken;
        return { ...pObject };
      });

      return await ExpiredFilter(
        url,
        getTeamplayAuth,
        refreshToken.data.data.accessToken
      );
    }
  };

  useEffect(() => {
    const url = `/teamplay/view?id=${id}`;
    getTeamplayAuth(url, account.accessToken);
  }, [id]);

  const toggle = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };
  const handleEdit = () => {
    navi(`/challengeedit/t/${id}`);
  };
  const handleDel = async () => {
    const url = `/teamplay/${id}`;
    const delRes = await ClientDelReq(
      url,
      account.accessToken,
      account.refreshToken,
      toggle
    );

    if (delRes.statusCode === 200) {
      alert("성공적으로 삭제되었습니다.");
      navi("/teamplay");
    } else if (delRes.statusCode === 400) {
      alert("올바르지 않은 접근입니다.잠시 후 다시 이용해주시기 바랍니다.");
      return false;
    }
  };

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={"teamplay"} />
      {/* <Suspense fallback={<p>loading...</p>}> */}
      <ChallengeView
        label={"팀원모집"}
        title={info.title}
        category={info.category === 0 ? "공모전" : "대외활동"}
        linkYn={info.linkYn}
        linkedTitle={"제 15회 공모전"}
        author={info.author?.nickName}
        content={info.content}
        createdAt={info.createdAt}
        attachment={info.attachment}
      >
        <div className={"answer_area"}>
          <ReplyComponent id={id} type="templay" />
        </div>
        <div className={"answer_area"}>
          <div className={"btn_area board"}>
            {info.ownYn && (
              <>
                <a className={"btn"} onClick={handleEdit}>
                  수정
                </a>
                <a className={"btn"} onClick={handleDel}>
                  삭제
                </a>
              </>
            )}
            <a className={"btn"} onClick={handleGoBack}>
              목록으로
            </a>
          </div>
        </div>
      </ChallengeView>
      {/* </Suspense> */}
    </div>
  );
};

export default TeamplayView;
