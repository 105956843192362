import React from "react";
import { Link } from "react-router-dom";
import custom from "../../assets/css/header.module.scss";
import Logo from "../../assets/img/common/logo_footer.png";
import styled from "styled-components";

const CustomOL = styled.ol`
  margin-inline-start: 1em;
  padding-inline-start: 10px;
  > li {
    list-style: circle;
  }
`;

const Footer = () => {
  return (
    <div className={`footer ${custom.footer}`}>
      <div className={`footLink`} id={custom.footerLink}>
        <Link to="/hostcenter">광고 및 제휴문의</Link>
        <a href="#modal01">이용약관</a>
        <a href="#modal02">개인정보취급방침</a>
        <a href="#modal03">책임의 한계와 법적고지</a>
        <Link to="/sitemap">사이트맵</Link>
      </div>
      <footer>
        <span className={`foot_Cname ${custom.footer_Cname}`}>
          <img src={Logo} />
        </span>
        <dd>
          <dl>서울특별시 강남구 남부순환로 2736 지앤지빌딩 3층 (주)대티즌</dl>
          <dl>대표메일 : detizen@detizen.com</dl>
          <dl>대표자 : 한성일</dl>
          <dl>사업자 번호 : 220-87-05027</dl>
          <dl>copyrightⓒ 2005 Detizen Inc. All rights reserved.</dl>
        </dd>
        <div className={`csArea ${custom.csArea}`}>
          <h3>
            <span>대티즌 고객센터</span>
          </h3>
          <ul>
            <li>
              <p className="ca_tit">대표번호</p>
              <span className="number">02-529-2515</span>
              <p className="workTime">
                평일 : 09:30 ~ 18:00
                <br />
                점심시간 : 12:00 ~ 13:00
              </p>
            </li>

            <li>
              <p className="ca_tit">입금계좌 안내</p>
              <p className="bNumb">
                기업은행 - 064-084329-01-016 (예금주: (주)대티즌)
              </p>
            </li>
          </ul>
        </div>
      </footer>
      <div data-ml-modal id="modal01">
        <a href="#!" className="modal-overlay"></a>
        <div className="modal-dialog modal-dialog-lg">
          <a href="#!" className="modal-close">
            &times;
          </a>
          <h3>이용약관</h3>
          <div className="modal-content terms01">
            <div className="section">
              <h3 className="first">
                <a name="a1">제1조 (목적)</a>
              </h3>
              <p>
                {" "}
                이 약관은 (주)대티즌(이하 "회사")은 제공하는 대티즌 서비스 및
                대티즌이 제공하는 제반 서비스 및 서비스를 이용하는 사용자에
                의해서 업로드 및 다운로드 되어 표시되는 모든 정보, 텍스트,
                이미지 및 기타 자료를 이용하는 이용자(이하 "회원")의 이용과
                관련하여 "회사"와 "회원"과의 권리, 의무 및 책임사항, 기타 필요한
                사항을 규정함을 목적으로 합니다.
              </p>
              <h3>
                <a name="a2">제2조(정의)</a>
              </h3>
              <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
              <CustomOL
                style={{ marginInlineStart: "1em", paddingInlineStart: "10px" }}
              >
                <li>
                  "서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종
                  유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 대티즌
                  서비스 및 관련 제반 서비스를 의미합니다. 제반 서비스에는
                  개발자 및 서비스 제공자가 대티즌의 Open Application
                  Programming Interface 서비스와 이를 이용하여 개발한 API 응용
                  애플리케이션 또는 웹서비스도 포함됩니다.
                </li>
                <li>
                  회원"이라 함은 "회사"의 "서비스"에 접속하여 이 약관에 따라
                  "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를
                  이용하는 고객을 말합니다.
                </li>
                <li>
                  개인회원: 대티즌 이용약관에 따라, 회원으로 가입하고, 이용자
                  번호를 부여받은 개인을 말합니다.
                </li>
                <li>
                  "아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여
                  "회원"이 정하고 "회사"가 승인하는 문자와 숫자의 조합을
                  의미합니다.
                </li>
                <li>
                  "비밀번호"라 함은 회원의 개인 정보 및 확인을 위해서 회원이
                  정한 문자 또는 숫자의 조합을 의미합니다.
                </li>
                <li>
                  "비회원"이라 함은 회원에 가입하지 않고 회사가 제공하는
                  서비스를 이용하는 자를 의미합니다.
                </li>
                <li>
                  "유료서비스"라 함은 "회사"가 유료로 제공하는 각종
                  온라인디지털콘텐츠(각종 정보콘텐츠, VOD, 아이템 기타
                  유료콘텐츠를 포함) 및 제반 서비스를 의미합니다.
                </li>
                <li>
                  "게시물"이라 함은 "회원"이 "서비스"를 이용함에 있어
                  "서비스상"에 게시한 부호ᆞ문자ᆞ음성ᆞ음향ᆞ화상ᆞ동영상 등의 정보
                  형태의 글, 일정, 사진, 동영상 및 각종 파일과 링크 등을
                  의미합니다.
                </li>
                <li>
                  "캐시"란 제21조에 따라 "회원"이 일정한 행위를 마친 때에
                  시스템에 적립되는 서비스 상의 데이터입니다.
                </li>
                <li>
                  커뮤니티”라 함은 “사이트”에 가입한 “회원”이 자신의 정보를 공개
                  및 공유하고, 다른 “회원”과 의사, 생각, 지식 등을 표현 및
                  소통할 수 있도록 “회사”가 “사이트” 내에 부가적으로 만든 인터넷
                  게시판을 의미한다.
                </li>
              </CustomOL>
              <h3>
                <a name="a2">제3조(약관의 게시와 개정)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 대티즌
                  가입 과정 및 페이지 하단에 이를 게시합니다.
                </li>
                <li>
                  "회사"는 "약관의 규제에 관한 법률", "정보통신망 이용 촉진 및
                  정보 보호 등에 관한 법률(이하 "정보통신망법")" 등 관련법을
                  위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                </li>
                <li>
                  "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를
                  명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의
                  적용일자 14일 전부터 적용일자 전일까지 공지합니다. 다만,
                  "회원"에게 불리한 약관의 개정의 경우에는 30일 전부터 공지 외에
                  일정기간 서비스내 전자우편, 전자쪽지, 로그인시 동의창 등의
                  전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                </li>
                <li>
                  "회사"가 전항에 따라 개정약관을 공지 또는 통지하면서
                  "회원"에게 14일 내에 의사표시를 하지 않으면 의사표시가 표명된
                  것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 "회원"이
                  명시적으로 거부의 의사표시를 하지 아니한 경우 "회원"이
                  개정약관에 동의한 것으로 봅니다.
                </li>
                <li>
                  "회원"이 개정에 동의하지 않는다는 의사를 표시한 경우 "회사"는
                  개정된 약관을 적용할 수 없으며, "회원"은 개정 약관의 효력
                  발생일부터 서비스를 이용할 수 없습니다.
                </li>
              </CustomOL>
              <h3>
                <a name="a2">제4조(약관의 해석)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 "유료서비스" 및 개별 서비스에 대해서는 별도의
                  이용약관 및 정책(이하 "유료서비스약관 등")을 둘 수 있으며,
                  해당 내용이 이 약관과 상충할 경우에는 "유료서비스약관 등"이
                  우선하여 적용됩니다.
                </li>
                <li>
                  이 약관에서 정하지 아니한 사항이나 해석에 대해서는
                  "유료서비스약관 등" 및 관계법령 또는 상관례에 따릅니다.
                </li>
              </CustomOL>
              <h3>
                <a name="a2">제5조(이용계약 체결)</a>
              </h3>
              <CustomOL>
                <li>
                  이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 처음
                  서비스 클라이언트 실행시 약관의 내용에 대하여 동의를 한 다음
                  이용자의 아이디, 이름을 입력하는 절차까지 완료함으로써
                  "회원"가입신청을 하고 "회사"가 이러한 신청에 대하여
                  승낙함으로써 체결됩니다.
                </li>
                <li>
                  "회사"는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을
                  원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에
                  대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수
                  있습니다.
                </li>
                <li>
                  가입신청자가 이 약관에 의하여 이전에 "회원"자격을 상실한 적이
                  있는 경우, 단 "회사"의 "회원" 재가입 승낙을 얻은 경우에는
                  예외로 함.
                </li>
                <li>타인의 명의를 이용한 경우</li>
                <li>
                  허위의 정보를 기재하거나, "회사"가 제시하는 내용을 기재하지
                  않은 경우
                </li>
                <li>가입신청자가 만14세 미만의 아동인 경우</li>
                <li>
                  이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
                  제반 사항을 위반하며 신청하는 경우
                </li>
                <li>
                  제1항에 따른 신청에 있어 "회사"는 "회원"의 종류에 따라
                  전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
                </li>
                <li>
                  "회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상
                  문제가 있는 경우에는 승낙을 유보할 수 있습니다.
                </li>
                <li>
                  제2항과 제4항에 따라 "회원"가입신청의 승낙을 하지 아니하거나
                  유보한 경우, "회사"는 원칙적으로 이를 가입신청자에게 알리도록
                  합니다.
                </li>

                <li>
                  "회사"는 "회원"에 대해 "회사"정책에 따라 등급별로 구분하여
                  이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘
                  수 있습니다.
                </li>
                <li>
                  "회사"는 "회원"에 대하여 "영화 및 비디오물의 진흥에 관한 법률"
                  및 "청소년보호법"등에 따른 등급 및 연령 준수를 위해
                  이용제한이나 등급별 제한을 할 수 있습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제6조("회원"정보의 변경)</a>
              </h3>
              <CustomOL>
                <li>
                  ① "회원"은 서비스내에 제공되는 화면을 통하여 본인의 개인정보를
                  열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한
                  아이디는 수정이 불가능합니다.
                </li>
                <li>
                  ② "회원"은 "회원"가입신청 시 기재한 사항이 변경되었을 경우
                  온라인으로 수정을 하거나 전자우편 기타 방법으로 "회사"에
                  대하여 그 변경사항을 알려야 합니다.
                </li>
                <li>
                  ③ 제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에
                  대하여 "회사"는 책임지지 않습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제7조(개인정보보호 의무)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 "정보통신망법" 등 관계 법령이 정하는 바에 따라
                  "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호
                  및 사용에 대해서는 관련법 및 "회사"의 개인정보처리방침이
                  적용됩니다
                </li>
                <li>
                  회사는 서비스를 중단하거나 회원이 개인정보 제공 동의를 철회한
                  경우에는 신속하게 회원의 개인정보를 파기합니다. 단, 전자상거래
                  등에서의 소비자 보호에 관한 법률 등 관련 법률에서 정하는 바에
                  따라 일정 정보는 보관할 수 있습니다.
                </li>
                <li>
                  회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로
                  회원의 동의 하에 관계 법령에서 정하는 바에 따라 추가적인
                  개인정보를 수집할 수 있습니다.
                </li>
                <li>
                  회사는 법률에 특별한 규정이 있는 경우를 제외하고는 회원의 별도
                  동의 없이 회원의 계정정보를 포함한 일체의 개인정보를 제3자에게
                  공개하거나 제공하지 아니합니다.
                </li>
                <li>
                  회사는 향후 제공하는 서비스에서 회원의 편의를 위해서 회원의
                  계정 정보를 사용할 수 있도록 링크 및 기타 방법을 제공할 수
                  있습니다.
                </li>
                <li>
                  회사는 고객에게 좀 더 나은 서비스를 제공하기 위해서 추천
                  알고리즘 연구를 목적으로 개인정보를 사용하고 있습니다. 연구와
                  논문 발표의 참고자료로써 개인정보를 사용하고 있음을 알려드리고
                  해당 개인정보는 이름, 이메일 주소, 핸드폰번호와 같은
                  사생활침해에 우려가 있는 정보들은 일괄 삭제 후 이용합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제8조("회원"의 "아이디" 의 관리에 대한 의무)</a>
              </h3>

              <CustomOL>
                <li>
                  "회원"의 "아이디" 에 관한 관리책임은 "회원"에게 있으며, 이를
                  제3자가 이용하도록 하여서는 안 됩니다.
                </li>
                <li>
                  "회사"는 "회원"의 "아이디"가 개인정보 유출 우려가 있거나,
                  반사회적 또는 미풍양속에 어긋나거나 "회사" 및 "회사"의
                  운영자로 오인한 우려가 있는 경우, 해당 "아이디"의 이용을
                  제한할 수 있습니다.
                </li>
                <li>
                  "회원"은 "아이디"가 도용되거나 제3자가 사용하고 있음을 인지한
                  경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야
                  합니다.
                </li>
                <li>
                  제3항의 경우에 해당 "회원"이 "회사"에 그 사실을 통지하지
                  않거나, 통지한 경우에도 "회사"의 안내에 따르지 않아 발생한
                  불이익에 대하여 "회사"는 책임지지 않습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제9조("회원"에 대한 통지)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이
                  없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수
                  있습니다.
                </li>
                <li>
                  "회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의
                  "웹사이트" 또는 서비스내 "공지사항" 화면에 게시함으로써
                  제1항의 통지에 갈음할 수 있습니다. 다만, "회원" 본인의 거래와
                  관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를
                  합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제10조("회사"의 의무)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는
                  행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기
                  위하여 최선을 다하여 노력합니다.
                </li>
                <li>
                  "회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록
                  개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
                  개인정보처리방침을 공시하고 준수합니다. 회사는 정보통신망
                  이용촉진 및 정보보호에 관한 법률, 통신비밀보호법,
                  전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를
                  준수합니다
                </li>
                <li>
                  "회사"는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나
                  불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.
                  "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을
                  활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를
                  전달합니다.
                </li>
                <li>
                  회사는 회원이 안전하게 서비스를 이용할 수 있도록 현재 인터넷
                  보안기술의 발전수준과 회사가 제공하는 서비스의 성격에 적합한
                  보안시스템을 갖추고 운영해야 합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제11조("회원"의 의무)</a>
              </h3>
              <CustomOL>
                <li>"회원"은 다음 행위를 하여서는 안 됩니다.</li>
                <li>이용 신청 또는 변경 시 허위내용의 등록</li>
                <li>타인의 정보도용</li>
                <li>"회사"가 게시한 정보의 변경</li>
                <li>다른 "회원"의 개인정보 및 계정정보를 수집하는 행위</li>
                <li>
                  "회사"의 사전 동의 없이 영리 목적의 광고성 정보를 전송하기
                  위하여 이용하는 행위
                </li>
                <li>
                  리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의
                  가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는
                  행위
                </li>
                <li>
                  자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른
                  방법으로 서비스를 이용하여 "회사"의 서버에 부하를 일으켜
                  "회사"의 정상적인 서비스를 방해하는 행위
                </li>
                <li>본인 아닌 제3자에게 접속권한을 부여하는 행위</li>
                <li>"회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                <li>
                  "회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는
                  행위
                </li>
                <li>
                  외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
                  정보를 "서비스"에 공개 또는 게시하는 행위
                </li>
                <li>
                  "회사"의 동의없이 영리를 목적으로 "서비스"를 사용하는 행위
                </li>
                <li>이 약관 제19조에서 제한하는 게시물을 등록하는 행위</li>
                <li>기타 불법적이거나 부당한 행위</li>
                <li>
                  회사는 회원이 전항에서 금지한 행위를 하는 경우, 위반 행위의
                  경중에 따라 서비스의 이용정지/계약의 해지 등 서비스 이용 제한,
                  수사 기관에의 고발 조치 등 합당한 조치를 취할 수 있습니다
                </li>
                <li>
                  회원은 회사의 명시적 사전 동의가 없는 한 서비스의 이용권한 및
                  기타 이용계약상의 지위를 제3자에게 양도, 증여, 대여할 수
                  없으며 이를 담보로 제공할 수 없습니다.
                </li>
                <li>
                  "회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와
                  관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을
                  준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는
                  안 됩니다.
                </li>
                <li>
                  회원은 회사의 사전 허락 없이 회사가 정한 이용 목적과 방법에
                  반하여 영업/광고활동 등을 할 수 없고, 회원의 서비스 이용이
                  회사의 재산권, 영업권 또는 비즈니스 모델을 침해하여서는
                  안됩니다.
                </li>
                <li>
                  활동정보를 등록 및 개인회원에게 제공을 원하는 주최기관회원은
                  다음 각 목의 행위를 해서는 안됩니다.
                </li>
                <li>기업명이 아닌 개인의 이름으로 가입하는 행위</li>
                <li>사실과 다르거나 조작된 내용의 정보를 등록하는 행위</li>
                <li>허위 활동공고를 등록하는 행위</li>
                <li>
                  사업자등록증에 나타난 정보와 일치하지 않는 정보로
                  주최기관회원에 가입하는 행위
                </li>
                <li>
                  지사, 지점, 영업소 등의 경우 구체적인 지사, 지점, 영업소
                  명칭을 사용하지 않고 기업명을 등록하거나 지사, 지점, 영업소의
                  사업자등록번호가 별도 있음에도 불구하고 본사의
                  사업자등록번호로 주최기관회원 가입을 하는 행위
                </li>
                <li>
                  업체명, 담당자 성명, 사무실 연락처 등 구인 업체의 중요 정보를
                  정확하게 기입하지 않는 행위
                </li>
                <li>교육의 명목으로 개인에게 비용을 요구하는 행위</li>
                <li>동일 내용의 채용공고를 중복 등록하는 행위</li>
                <li>회사명을 수시로 변경하며 동일한 활동정보를 올리는 행위</li>
                <li>
                  임금체불, 기타 노동관계법령 위반사실이 법원이나 검찰, 노동부,
                  기타 관할기관에 접수되어 있는 기업(개인사업자 포함)이
                  활동정보를 등록하는 행위
                </li>
                <li>
                  활동정보로 보기 어려운 대리점주, 지사장, 총판 등의 사업자
                  모집을 개시하는 행위
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제12조("대티즌 서비스"의 제공 등)</a>
              </h3>
              <CustomOL>
                <li>회사는 회원에게 아래와 같은 서비스를 제공합니다.</li>
                <li>커뮤니티 서비스</li>
                <li>대외활동, 공모전, 동아리, 인턴, 채용 서비스</li>
                <li>
                  기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해
                  회원에게 제공하는 일체의 서비스
                </li>
                <li>
                  회사는 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는
                  운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로
                  중단할 수 있습니다. 이 경우 회사는 회원에게 공지사항 게시판 및
                  메일 등의 방법으로 통지합니다. 다만, 회사가 사전에 통지할 수
                  없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                </li>
                <li>
                  회사는 회원과 별도로 서면 계약을 체결하여 대티즌 서비스 및
                  제반 서비스의 브랜드 특성을 이용할 수 있는 명시적인 권리를
                  부여하지 아니하는 한, 회원에게 회사 또는 서비스의 상호, 상표,
                  서비스표, 로고, 도메인 네임 및 기타 식별력 있는 브랜드 특성을
                  이용할 수 있는 권리를 부여하지 않습니다.
                </li>
                <li>
                  회사가 제공하는 서비스의 형태와 기능, 디자인 등 필요한 경우
                  수시로 변경되거나, 중단될 수 있습니다. 회사는 이 경우 개별적인
                  변경에 대해서 회원에게 사전 통지하지 않습니다. 다만, 회원에게
                  불리한 것으로 판단되는 경우 전자우편으로 통하여 이를
                  공지합니다.
                </li>
                <li>
                  전항에 의해서 제공되는 서비스가 변경 및 중단될 경우 무료로
                  제공되는 서비스에 대해서는 회원에게 별도로 보상하지 않습니다.
                </li>
                <li>
                  회사는 서비스 운영과 관련하여 회원정보, 고객이 입력한 정보를
                  활용하여 광고를 게재할 수 있습니다. 회원은 서비스 이용 시
                  노출되는 맞춤 광고 게재에 대해 동의합니다.
                </li>
                <li>
                  회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주의
                  판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써
                  발생하는 손실과 손해에 대해 책임을 지지 않습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제13조(전자우편을 통한 정보의 제공)</a>
              </h3>
              <CustomOL>
                <li>
                  회사는 회원이 서비스 이용에 필요하다고 인정되는 다양한 정보를
                  회원이 제공한 전자우편 주소로 제공할 수 있습니다.
                </li>
                <li>
                  회사는 서비스 운영을 위해 회원정보를 활용하여 영리목적의
                  광고성 전자우편을 전송할 수 있습니다. 회원이 이를 원하지 않는
                  경우에는 언제든지 서비스 홈페이지 또는 서비스 내부 설정페이지
                  등을 통하여 수신거부를 할 수 있습니다.
                </li>
                <li>
                  회사는 다음 각호에 해당하는 경우 회원의 동의여부와 상관없이
                  전자우편으로 발송할 수 있습니다.
                </li>
                <li>
                  이용 신청에서 입력한 전자우편 주소의 소유를 확인하기 위해서
                  인증메일을 발송하는 경우
                </li>
                <li>
                  회원의 정보가 변경되어 확인하기 위해서 인증메일을 발송하는
                  경우
                </li>
                <li>
                  기타 서비스를 제공함에 있어 회원이 반드시 알아야 하는 중대한
                  정보라고 회사가 판단하는 경우
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제14조("서비스"의 변경)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성
                  악화, 기술 진보에 따른 차세대 서비스로의 전환 필요성, 서비스
                  제공과 관련한 "회사" 정책의 변경 등 기타 상당한 이유가 있는
                  경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는
                  일부 "서비스"를 변경 또는 중단할 수 있습니다.
                </li>
                <li>
                  "회사"는 무료로 제공되는 서비스의 일부 또는 전부를 "회사"의
                  정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에
                  대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을
                  하지 않습니다.
                </li>
                <li>
                  "서비스"의 내용, 이용방법, 이용시간에 대하여 변경 또는 서비스
                  중단이 있는 경우에는 변경 또는 중단될 서비스의 내용 및 사유와
                  일자 등은 그 변경 또는 중단 전에 "회사" "웹사이트" 또는
                  서비스내 "공지사항" 화면 등 "회원"이 충분이 인지할 수 있는
                  방법으로 30일의 기간을 두고 사전에 공지합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제15조("서비스"의 일시중단)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 서비스관련설비 보수점검, 교체 및 고장, 통신두절 등
                  기술상 업무상의 이유로 서비스의 제공을 일시적으로 중단할 수
                  있습니다. 이 경우 사전에 통지함을 원칙으로 하지만, 부득이한
                  사유가 있는 경우 사후에 통지할 수 있습니다.
                </li>
                <li>
                  서비스 일시 중단은 이메일 또는 서비스 내 공지사항, 서비스
                  웹사이트에 게시하는 방법으로 통지합니다.
                </li>
                <li>
                  중단으로 인하여 "회원"이 손실을 입은 경우 "회사"는 통상적으로
                  예상 가능한 범위 내에서 서비스 내 포인트 등으로 보상할 수
                  있습니다. 단, 천재지변 등 불가항력에 의한 경우에는 보상하지
                  않습니다.
                </li>
                <li>
                  회사는 천재지변이나 국가비상사태, 해결이 곤란한 기술적 결함
                  또는 서비스 운영의 심각한 변화 등 불가항력적인 경우가 발생
                  또는 발생이 예상될 때는 서비스의 전부 또는 일부를 예고 없이
                  제한하거나 중지할 수 있습니다.
                </li>
                <li>
                  서비스를 이용하게 됨으로써 서비스 영역에서 발생하는 회원
                  사이의 문제에 대해 회사는 책임을 지지 않습니다.
                </li>
                <li>
                  회원의 관리 소홀로 인하여 ID 및 비밀번호의 유출로 인해
                  회원에게 서비스 이용상의 손해가 발생하거나 제3자에 의한
                  부정이용 등으로 회원의 의무조항을 위반한 경우 ID및 해당
                  도메인의 이용이 제한될 수 있습니다.
                </li>
                <li>
                  회사가 본 약관 제11조의 위반 행위를 조사하는 과정에서 당해
                  회원 ID및 도메인이 특정 위반행위에 직접적으로 관련되어 있는
                  경우 등 다른 회원의 권익 보호 및 서비스의 질서유지를 위해
                  불가피할 경우에는 해당 ID 및 도메인의 이용을 일시적으로 정지할
                  수 있습니다. 이에 대해 회원은 서비스 홈페이지 또는 전자 우편
                  등을 통해 이의신청을 할 수 있습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제16조(정보의 제공 및 광고의 게재)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한
                  정보를 서비스내 공지사항, 전자우편 등의 방법으로 "회원"에게
                  제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련 정보
                  및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 위
                  정보제공에 대해서 수신 거절을 할 수 있습니다.
                </li>
                <li>
                  "회사"는 "서비스"의 운영과 관련하여 서비스 화면, 홈페이지 등에
                  광고를 게재할 수 있습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제17조("게시물"의 저작권)</a>
              </h3>
              <CustomOL>
                <li>
                  "회원"이 동의 하에 "회사"에 양도한 게시물의 복제권 등
                  저작재산권 일체는 "회사"에 귀속됩니다.
                </li>
                <li>
                  "회원"이 서비스 내에 작성한 텍스트, 이미지, 동영상, 링크 등의
                  기타 정보(이하 "게시물")에 대한 책임 및 권리는 게시물을 등록한
                  회원에게 있습니다.
                </li>
                <li>
                  "회사"는 "회원"의 "게시물"을 이용하고자 하는 경우에는 전화,
                  팩스, 전자우편 등을 통해 사전에 "회원"의 동의를 얻어야 합니다.
                </li>
                <li>
                  회사는 회원이 작성한 컨텐츠에 대해서 감시 및 관리할 수 없으며
                  이에 대해서 책임지지 않습니다. 회사는 회원이 등록하는 게시물의
                  신뢰성, 진실성, 정확성 등에 대해서 책임지지 않으며 보증하지
                  않습니다.
                </li>
                <li>
                  서비스에 대한 저작권 및 지적재산권, 회사가 작성한 게시물의
                  저작권은 회사에 귀속됩니다. 단, 회원이 단독 또는 공동으로
                  작성한 게시물 및 제휴계약에 따라 제공된 저작물 등은
                  제외합니다. 회원은 자신이 서비스 내에 게시한 게시물을 회사가
                  국내ㆍ외에서 다음 목적으로 사용하는 것을 허락합니다. -
                  서비스(제3자가 운영하는 사이트 또는 미디어의 일정 영역 내에
                  입점하여 서비스가 제공되는 경우를 포함합니다)내에서 게시물을
                  사용하기 위하여 게시물의 크기를 변환하거나 단순화하는 등의
                  방식으로 수정하는 것 - 회사에서 운영하는 다른 사이트 또는 다른
                  회사가 운영하는 사이트에 게시물을 복제ㆍ전송ㆍ전시하는 것 -
                  회사의 서비스를 홍보하기 위한 목적으로 미디어, 통신사 등에게
                  게시물의 내용을 보도, 방영하게 하는 것. 단, 이 경우 회사는
                  회원의 개별 동의없이 미디어, 통신사 등에게 게시물 또는
                  회원정보를 제공하지 않습니다.
                </li>
                <li>
                  회원이 회원탈퇴를 한 경우에는 본인 도메인에 기록된 저작물
                  일체는 삭제됩니다. 단, 저작물이 공동 저작을 통해 작성된
                  경우에는 공동 저작자의 도메인에 해당 게시물이 남을 수 있고,
                  제3자에 의하여 보관되거나 무단복제 등을 통하여 복제됨으로써
                  해당 저작물이 삭제되지 않고 재 게시된 경우에 대하여 회사는
                  책임을 지지 않습니다. 또한, 본 약관 및 관계 법령을 위반한
                  회원의 경우 다른 회원을 보호하고, 법원, 수사기관 또는 관련
                  기관의 요청에 따른 증거자료로 활용하기 위해 회원탈퇴 후에도
                  관계 법령이 허용하는 한도에서 회원의 아이디 및 회원정보를
                  보관할 수 있습니다.
                </li>
                <li>
                  회원의 게시물 또는 저작물이 회사 또는 제3자의 저작권 등
                  지적재산권을 침해함으로써 발생하는 민∙형사상의 책임은 전적으로
                  회원이 부담하여야 합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제19조("게시물"의 관리)</a>
              </h3>
              <CustomOL>
                <li>
                  "회원"이 비공개로 설정한 게시물에 대해서는 회사를 포함한 다른
                  사람이 열람할 수 없습니다. 단, 법원, 수사기관이나 기타
                  행정기관으로부터 정보제공을 요청 받은 경우나 기타 법률에 의해
                  요구되는 경우에는 회사를 포함한 다른 사람이 해당 게시물을
                  열람할 수 있습니다.
                </li>
                <li>
                  "회원" 혹은 권리자는 "회사"가 제공하는 게시중단 요청 서비스를
                  이용할 수 있습니다.
                </li>
                <li>
                  "회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련 법령에
                  위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한
                  절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수
                  있으며, "회사"는 관련법에 따라 조치를 취합니다.
                </li>
                <li>
                  "회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가
                  인정될 만한 사유가 있거나 기타 "회사" 정책 및 관련 법령에
                  위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 임시조치
                  등을 취할 수 있습니다.
                </li>
                <li>
                  본 항에 따른 세부절차는 "정보통신망법" 및 "저작권법"이 규정한
                  범위 내에서 "회사"가 정한 "게시중단요청서비스"에 따릅니다.
                </li>
                <li>
                  "회사"는 게시판에 공개된 "게시물"이 아래와 같은 내용을 포함할
                  경우 즉시 삭제, 블라인드 등 게시 중단 조치를 취함과 동시에 이
                  약관 제11조에 의거, 해당 게시물을 작성한 "회원"에 대한
                  서비스의 이용정지/계약의 해지 등 서비스 이용 제한, 수사
                  기관에의 고발 조치 등을 취할 수 있습니다.
                </li>
                <li>타인에 대해 직접적이고 명백한 신체적 위협을 가하는 내용</li>
                <li>타인의 자해 행위 또는 자살을 부추기거나 권장하는 내용</li>
                <li>
                  타인의 신상정보, 사생활 등 비공개 개인정보를 드러내는 내용
                </li>
                <li>타인을 지속적으로 따돌리거나 괴롭히는 내용</li>
                <li>성매매를 제안, 알선, 유인 또는 강요하는 내용</li>
                <li>공공 안전에 대해 직접적이고 심각한 위협을 가하는 내용</li>
                <li>
                  관련 법령상 금지되거나 형사처벌의 대상이 되는 행위를
                  수행하거나 이를 교사 또는 방조하는 등의 범죄 관련 직접적인
                  위험이 포함된 내용
                </li>
                <li>
                  관련 법령에서 홍보, 광고, 판매 등을 금지하고 있는 물건 또는
                  서비스를 홍보, 광고, 판매하는 내용
                </li>
                <li>
                  타인의 지식재산권 등을 침해하거나 모욕, 사생활 침해 또는
                  명예훼손 등 타인의 권리를 침해하는 내용
                </li>
                <li>
                  자극적이고 노골적인 성행위를 묘사하는 등 타인에게 성적
                  수치심을 유발시키거나 왜곡된 성 의식 등을 야기할 수 있는 내용
                </li>
                <li>
                  타인에게 잔혹감 또는 혐오감을 일으킬 수 있는 폭력적이고
                  자극적인 내용
                </li>
                <li>
                  본인 이외의 자를 사칭하거나 허위사실을 주장하는 등 타인을
                  기만하는 내용
                </li>
                <li>
                  과도한 욕설, 비속어 등을 계속하여 반복적으로 사용하여 심한
                  혐오감 또는 불쾌감을 일으키는 내용
                </li>
                <li>
                  "회원"의 별명(닉네임)이 노출되지 않는 익명으로 작성한 게시물이
                  위 조항에서 열거한 포함 금지 사유에 해당하는 경우, "회사"는
                  사전 고지 혹은 서비스 이용중지 등의 예비 조치 없이 즉각 게시물
                  삭제 및 해당 게시물을 작성한 "회원"에 대한 서비스 계약 해지 및
                  관계 기관으로의 고발 등 법적 조치를 취합니다.
                </li>
                <li>
                  "회원"은 제3항 내지 제4항에 해당하는 게시물을 "회사"가
                  제공하는 기능을 이용, 신고하여 조치할 것을 요청할 수 있으며,
                  "회사"는 "회원"으로부터 신고를 접수받은 후 영업일 기준 24시간
                  내에 필요한 조치를 취합니다.
                </li>
                <li>
                  "회사"는 제3항 내지 제4항의 유형에 해당하는 게시물로 인해
                  피해를 받은 "회원" 및 비회원, 제3자 등 피해 당사자가 고소,
                  고발 등 법적 조치를 이행할 경우 적극 협조하며, 피해 당사자의
                  권리 회복을 위해 최선을 다합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제19조(권리의 귀속)</a>
              </h3>
              <CustomOL>
                <li>
                  "서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다.
                  단, "회원"의 "게시물" 및 제휴계약에 따라 제공된 저작물 등은
                  제외합니다.
                </li>
                <li>
                  "회사"가 제공하는 서비스의 디자인, "회사"가 만든 텍스트,
                  스크립트(script), 그래픽, "회원" 상호간 공유 기능 등 "회사"가
                  제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에
                  관한 저작권 기타 지적재산권은 대한민국 및 외국의 법령에 기하여
                  "회사"가 보유하고 있거나 "회사"에게 소유권 또는 사용권이
                  있습니다.
                </li>
                <li>
                  "회원"은 본 이용약관으로 인하여 "서비스"를 소유하거나
                  "서비스"에 관한 저작권을 보유하게 되는 것이 아니라,
                  "회사"로부터 "서비스"의 이용을 허락 받게 되는바, "서비스"는
                  정보취득 또는 개인용도로만 제공되는 형태로 "회원"이 이용할 수
                  있습니다.
                </li>
                <li>
                  "회원"은 명시적으로 허락된 내용을 제외하고는 "서비스"를 통해
                  얻어지는 "회원" 상태정보를 영리 목적으로 사용, 복사, 유통하는
                  것을 포함하여 "회사"가 만든 텍스트, 스크립트, 그래픽의 "회원"
                  상호간 공유기능 등을 복사하거나 유통할 수 없습니다.
                </li>
                <li>
                  "회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한 이용조건에
                  따라 계정, "아이디", 콘텐츠 등을 이용할 수 있는 이용권만을
                  부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를
                  할 수 없습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제20조(캐시 적립 및 이용)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 "회원"에게 캐시를 적립할 수 있으며, 적립 경로는
                  다음과 같습니다.
                </li>
                <li>
                  잠금화면에서 서비스 제공자가 지정한 특정 이미지를 누르는
                  행위를 완료한 경우
                </li>
                <li>
                  '바로적립하기'에서 제공하는 광고를 시청한 후 잠금해제, 웹접속,
                  다운로드 및 실행, "회원"가입 등의 일정한 행위를 완료한 경우
                </li>
                <li>추천인의 링크를 통해 설치하여 "회원"가입을 한 경우</li>
                <li>강의평가를 하거나 시험정보를 작성한 경우</li>
                <li>그 외 "회사"가 정한 방식</li>
                <li>
                  제1항제4호의 경우 "회사"의 검수를 통과한 경우에만 캐시가
                  적립됩니다.
                </li>
                <li>
                  적립된 캐시 내역은 서비스 내 마이페이지 화면에서 확인하실 수
                  있습니다.
                </li>
                <li>
                  서버에 입력된 적립액과 클라이언트상의 적립액 간에 차이가 나는
                  경우 항상 서버의 수치를 기준으로 하며, 서버와 클라이언트의
                  비동기화로 인한 적립금 차이는 정정이 불가능합니다.
                </li>
                <li>
                  캐시 적립과 관련하여 발생하는 제세공과금은 "회원"의 부담으로
                  합니다.
                </li>
                <li>
                  캐시는 양도, 상속, 대여, 담보의 목적으로 될 수 없습니다. 다만
                  "회사"가 인정하는 경우 예외로 합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제21조(제휴 컨텐츠의 이용)</a>
              </h3>
              <CustomOL>
                <li>
                  "회원"은 적립된 캐시를 이용하여 서비스 내 제휴된 다양한
                  컨텐츠를 이용, 구매하실 수 있습니다.
                </li>
                <li>
                  제휴컨텐츠의 내용은 다른 "회사"와의 협의상황에 따라 변경될 수
                  있습니다.
                </li>
                <li>
                  제휴컨텐츠의 이용, 구매금액은 제휴처로부터의 공급금액, 지급
                  수수료, 운영비용 등을 고려하여 산정이 되므로, 기존 판매가와
                  다를 수 있습니다.
                </li>
                <li>
                  커피, 편의점등 현장에서 사용되는 제휴컨텐츠의 경우 "회사"가
                  "회원"의 모바일기기로 해당 바코드를 전송함으로써 적법하게
                  이행을 완료한 것으로 봅니다.
                </li>
                <li>
                  서비스 내에서 구매하신 쿠폰을 "회원"의 단순 변심으로 환불이
                  불가능 합니다.
                </li>
                <li>
                  제휴컨텐츠 이용과 관련한 분쟁에서 명시적인 책임소재가 제휴된
                  업체 측에 있을 경우, "회사"는 책임을 지지 않습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제22조(캐시의 정정, 취소 및 소멸)</a>
              </h3>
              <CustomOL>
                <li>
                  캐시 적립에 오류가 발생한 경우 "회원"은 오류 발생일로부터 30
                  일 이내에 "회사"에 정정요구를 할 수 있으며, "회사"는 정당한
                  요구임이 확인된 경우 정정요구일로부터 30 일 이내에 정정하여야
                  합니다.
                </li>
                <li>
                  클라이언트 변경, 해킹, 매크로 등 부정한 방법으로 광고를
                  시청하지 않거나 시청한 광고 이상으로 캐시를 적립하신 경우,
                  부정적립이 되어 그 캐시는 0 으로 정정됩니다.
                </li>
                <li>
                  "회사"와 광고주간의 거래과정에서 광고주의 파산, 부도 등
                  광고료의 지급이 불가능해진 경우 "회사"는 당해 광고에 관해
                  적립된 캐시를 취소할 수 있으며, 이에 대한 책임은 광고주에게
                  있습니다. 이 경우 "회사"는 취소된 캐시의 10% 이하의 범위에서
                  "회원"에게 보상 캐시를 제공할 수 있습니다.
                </li>
                <li>
                  적립된 날로부터 12 개월간사용되지 않은 캐시는 차례로
                  소멸됩니다.
                </li>
                <li>
                  캐시의 적립 또는 사용이 없는 비활성화 상태가 3 개월 이상
                  계속된 "회원"의 캐시는 소멸될 수 있습니다.
                </li>
                <li>
                  상품뽑기 기능에 대하여 클라이언트가 매크로, 해킹 등 부정한
                  방법으로 상품을 수령할 시 부정 수령이 되어 상품 지급이 거부 및
                  취소될 수 있습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제23조(계약해제, 해지 등)</a>
              </h3>
              <CustomOL>
                <li>
                  "회원"은 언제든지 서비스 내 "회원탈퇴" 화면을 통하여 이용계약
                  해지 신청을 할 수 있으며, "회사"는 관련법 등이 정하는 바에
                  따라 이를 즉시 처리하여야 합니다.
                </li>
                <li>
                  "회원"이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라
                  "회사"가 "회원"정보를 보유하는 경우를 제외하고는 해지 즉시
                  "회원"의 모든 데이터는 소멸됩니다.
                </li>
                <li>
                  "회원"이 계약을 해지하는 경우, "회원"이 작성한 게시물 일체는
                  삭제됩니다. 다만, 타인에 의해 담기, 스크랩 등이 된 "게시물"과
                  공용게시판(커뮤니티)에 등록된 "게시물" 및 시험정보, 강의평가
                  서비스 등 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.
                </li>
                <li>
                  회사는 회원이 본 약관 제11조의 이용자의 의무를 위반한 경우 및
                  서비스의 정상적인 운영을 방해한 경우에는 사전 통보 후 회원
                  자격을 제한, 이용계약을 해지하거나 또는 기간을 정하여 서비스의
                  이용을 중지할 수 있습니다.
                </li>
                <li>
                  회사는 전항에도 불구하고, 저작권법 및 컴퓨터프로그램보호법을
                  위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한
                  불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과
                  같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수
                  있습니다.
                </li>
                <li>
                  회사는 회원이 계속해서 3개월 이상 로그인하지 않는 경우,
                  회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수
                  있습니다.
                </li>
                <li>
                  회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라
                  이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가
                  인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제24조(이용제한 등)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의
                  정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지
                  등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.
                </li>
                <li>
                  "회사"는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및
                  결제도용, 전화번호 도용, "저작권법" 및
                  "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및
                  운영방해, "정보통신망법"을 위반한 불법통신 및 해킹,
                  악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을
                  위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에
                  따른 영구이용정지시 "서비스" 이용을 통해 획득한 혜택 등도 모두
                  소멸되며, "회사"는 이에 대해 별도로 보상하지 않습니다.
                </li>
                <li>
                  "회사"는 "회원"이 계속해서 3개월 이상 로그인하지 않는 경우,
                  "회원"정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수
                  있습니다.
                </li>
                <li>
                  본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
                  "회사"의 이용제한정책에서 정하는 바에 의합니다.
                </li>
                <li>
                  본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는
                  경우에는 "회사"는 제9조에 따라 통지합니다.
                </li>
                <li>
                  "회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에
                  따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고
                  "회사"가 인정하는 경우 "회사"는 즉시 "서비스"의 이용을
                  재개합니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제25조(책임제한)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여
                  "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이
                  면제됩니다.
                </li>
                <li>
                  "회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에
                  대하여는 책임을 지지 않습니다.
                </li>
                <li>
                  "회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료,
                  사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지
                  않습니다.
                </li>
                <li>
                  "회사"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를
                  매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
                </li>
                <li>
                  "회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에
                  특별한 규정이 없는 한 책임을 지지 않습니다.
                </li>
                <li>
                  회사는 제3자가 제공하는 오픈아이디의 인증 장애, 해결이 곤란한
                  기술적 결함 기타 불가항력으로 인하여 서비스를 제공할 수 없는
                  경우 책임이 면제됩니다.
                </li>
                <li>
                  회사는 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로
                  제공하지 아니하여 손해가 발생한 경우에는 책임이 면제됩니다.
                </li>
                <li>
                  회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검,
                  공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한
                  경우에 대하서는 책임이 면제됩니다.
                </li>
                <li>
                  회원은 자신의 결정에 의하여 회사의 서비스를 사용하여 특정
                  프로그램이나 정보 등을 다운받거나 접근함으로써 입게 되는
                  컴퓨터 시스템상의 손해나 데이터, 정보의 상실에 대한 책임을
                  집니다.
                </li>
                <li>
                  회원의 컴퓨터 오류, 신상정보 및 전자우편 주소의 부정확한 기재,
                  비밀번호 관리의 소홀 등 회원의 귀책사유로 인해 손해가 발생한
                  경우 회사는 책임을 지지 않습니다.
                </li>
                <li>
                  회사는 회원의 컴퓨터 환경이나 회사의 관리 범위에 있지 아니한
                  보안 문제로 인하여 발생하는 제반 문제 또는 현재의 보안기술
                  수준으로 방어가 곤란한 네트워크 해킹 등 회사의 귀책사유 없이
                  발생하는 문제에 대해서 책임을 지지 않습니다.
                </li>
                <li>
                  회사는 서비스가 제공한 내용에 대한 중요 정보의 정확성, 내용,
                  완전성, 적법성, 신뢰성 등에 대하여 보증하거나 책임을 지지
                  않으며, 사이트의 삭제, 저장실패, 잘못된 인도, 정보에 대한
                  제공에 대한 궁극적인 책임을 지지 않습니다. 또한, 회사는 회원이
                  서비스 내 또는 웹사이트상에 게시 또는 전송한 정보, 자료,
                  사실의 신뢰도, 정확성, 완결성, 품질 등 내용에 대해서는 책임을
                  지지 않습니다.
                </li>
                <li>
                  "회사"는 제3자가 서비스내 화면 또는 링크된 웹사이트를 통하여
                  광고한 제품 또는 서비스의 내용과 품질에 대하여 감시할 의무
                  기타 어떠한 책임도 지지 아니합니다.
                </li>
                <li>
                  "회사"는 회원 상호간 또는 회원과 제 3자 상호 간에 서비스를
                  매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한
                  손해를 배상할 책임도 없습니다.
                </li>
                <li>
                  회사는 회원이 서비스를 이용하여 기대하는 효용을 얻지 못한 것에
                  대하여 책임을 지지 않으며 서비스에 대한 취사 선택 또는
                  이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
                </li>
                <li>
                  회사는 회원의 게시물을 등록 전에 사전심사 하거나 상시적으로
                  게시물의 내용을 확인 또는 검토하여야 할 의무가 없으며, 그
                  결과에 대한 책임을 지지 않습니다.
                </li>
                <li>
                  "회사"및 "회사"의 임직원 그리고 대리인은 다음과 같은
                  사항으로부터 발생하는 손해에 대해 책임을 지지 아니합니다.
                </li>

                <li>"회원" 상태 정보의 허위 또는 부정확성에 기인하는 손해</li>
                <li>
                  그 성질과 경위를 불문하고 "서비스"에 대한 접속 및 "서비스"의
                  이용과정에서 발생하는 개인적인 손해
                </li>
                <li>
                  서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인
                  이용으로부터 발생하는 손해
                </li>
                <li>
                  서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든
                  불법적인 방해 또는 중단행위로부터 발생하는 손해
                </li>
                <li>
                  제3자가 "서비스"를 이용하여 불법적으로 전송,유포하거나 또는
                  전송,유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성
                  프로그램으로 인한 손해
                </li>
                <li>
                  전송된 데이터의 오류 및 생략, 누락, 파괴 등으로 발생되는 손해
                </li>
                <li>
                  "회원"간의 "회원" 상태정보 등록 및 "서비스" 이용 과정에서
                  발생하는 명예훼손 기타 불법행위로 인한 각종 민형사상 책임
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제26조(해외이용)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 대한민국 내에 설치된 서버를 기반으로 "서비스"를 제공,
                  관리하고 있습니다. 따라서 "회사"는 "회원"이 대한민국의 영토
                  이외의 지역에서 "서비스"를 이용하고자 하는 경우 "서비스"의
                  품질 또는 사용성을 보장하지 않습니다.
                </li>
                <li>
                  "회원"은 대한민국의 영토 이외의 지역에서 "서비스"를 이용하고자
                  하는 경우 스스로의 판단과 책임에 따라서 이용 여부를 결정하여야
                  하고, 특히 "서비스"의 이용과정에서 현지 법령을 준수할 책임은
                  "회원"에게 있습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제27조(서비스의 종료)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"는 서비스를 종료하고자 하는 날로부터 3개월 이전에 본
                  약관 제3조 3항의 방법으로 "회원"에게 알립니다.
                </li>
                <li>
                  서비스 종료통지일 현재 기 적립된 캐시는 서비스 종료일까지 본
                  약관이 정하는 바에 따라 소진하셔야 하며, 서비스 종료일
                  이후로는 자동 소멸되어 "회원"은 캐시에 관한 권리를 주장하실 수
                  없습니다.
                </li>
                <li>
                  종료 통지일로부터 서비스 종료일까지 서비스의 일부가 제한될 수
                  있습니다.
                </li>
              </CustomOL>

              <h3>
                <a name="a2">제29조(준거법 및 재판관할)</a>
              </h3>
              <CustomOL>
                <li>
                  "회사"와 "회원"간 제기된 소송은 대한민국법을 준거법으로
                  합니다.
                </li>
                <li>
                  "회사"와 "회원"간 발생한 분쟁에 관한 소송은 민사소송법 상의
                  법원을 관할법원으로 합니다.
                </li>
                <li>공고일자 : 2023년 1월 30일</li>
                <li>시행일자 : 2023년 1월 30일</li>
              </CustomOL>
            </div>
          </div>
        </div>
      </div>
      <div data-ml-modal id="modal02">
        <a href="#!" className="modal-overlay"></a>
        <div className="modal-dialog modal-dialog-lg">
          <a href="#!" className="modal-close">
            &times;
          </a>
          <h3>개인정보취급방침</h3>
          <div className="modal-content terms02">
            <div className="section">
              <h3 className="first">
                <a name="a1">제 1 조 (총직)</a>
              </h3>
              <CustomOL>
                <li>
                  대티즌은(이하 "회사"라 합니다)는 이용자의 개인정보를 매우
                  소중히 생각합니다. 회사는 「정보통신망 이용촉진 및 정보보호
                  등에 관한 법률」, 「개인정보보호법」을 비롯한 개인정보 관련
                  법령을 준수하고 있습니다. 이용자께서 알려주신 개인정보는 수집,
                  이용 동의를 받은 범위 내에서, 단말기 정보 등에 접근한다는 것을
                  고지, 승인 후 수집되며, 별도의 동의없이는 결코 제 3 자에게
                  제공되지 않습니다. 또한 기본적 인권 침해의 우려가 있는 민감한
                  개인정보는 수집하지 않습니다. 본 개인정보처리방침은 개인정보를
                  안전하게 처리하기 위한 회사의 정책관련 자세한 사항을
                  알려드리기 위해 마련되었으며 대티즌 및 또는 웹사이트
                  (‘http://www.detizen.com’이하’www.detizen.com’)에서 언제든
                  확인하실 수 있습니다.
                </li>
              </CustomOL>
              <h3 className="first">
                <a name="a1">제 2 조 (개인정보의 처리목적)</a>
              </h3>
              <CustomOL>
                <li>
                  회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
                  있는 개인정보는 다음 목적 이외의 용도로 이용되지 아니하며,
                  이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라
                  별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                  <p style={{ fontWeight: "bold" }}>
                    웹사이트 또는 앱 서비스의 회원 가입 및 관리
                  </p>
                  <CustomOL>
                    <li>
                      회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 및
                      인증, 회원자격 유지와 관리, 제한적 본인확인제 시행에 따른
                      본인확인, 서비스 부정이용 방지, 각종 고지 및 통지,
                      고충처리 등을 목적으로 개인정보를 처리합니다.
                    </li>
                  </CustomOL>
                  <p style={{ fontWeight: "bold" }}>재화 또는 서비스 제공</p>
                  <CustomOL>
                    <li>
                      물품배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공,
                      맞춤서비스 제공, 본인인증, 연령인증, 요금결제 및 정산,
                      포인트 적립 및 이용, 채권추심 등을 목적으로 개인정보를
                      처리합니다.
                    </li>
                  </CustomOL>
                  <p style={{ fontWeight: "bold" }}>고충처리</p>
                  <CustomOL>
                    <li>
                      민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락 및
                      통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.
                    </li>
                  </CustomOL>
                </li>
              </CustomOL>
              <h3>
                <a name="a2">제 3 조 (수집하는 개인정보의 항목)</a>
              </h3>
              <CustomOL>
                <li>
                  회사는 홈페이지, 서비스 이용, 이벤트 응모, 팩스, 우편, 전화,
                  고객센터 문의하기 등을 위해 아래와 같은 개인정보를 수집하고
                  있습니다. 해당 서비스의 본질적 기능을 수행하기 위한 정보는
                  필수항목으로서 수집하며 이용자가 그 정보를 회사에 제공하지
                  않는 경우 서비스 이용에 제한이 가해질 수 있지만, 선택항목으로
                  수집되는 정보의 경우에는 이를 입력하지 않은 경우에도 서비스
                  이용제한은 없습니다. 추가항목은 휴대폰 감면권 신청, 기부금
                  영수증 발행 등의 경우 해당 신청자에 한해 추가적으로
                  수집합니다. 단, 만14세 미만 아동의 개인정보는 수집하지
                  않습니다.
                </li>
                <li>
                  수집항목, 이용목적, 보유기간
                  <p style={{ fontWeight: "bold" }}>수집 항목</p>
                  <CustomOL>
                    <li>
                      1) 회원가입시 수집하는 항목
                      <CustomOL>
                        <li>
                          개인 회원 필수항목: 아이디, 패스워드, 휴대번호,
                          생년월일, 닉네임 및 e-메일 계정 정보{" "}
                        </li>
                        <li>
                          기업 회원 필수항목: 아이디, 패스워드, 담당자 이름,
                          휴대전화, 기업명, 사업자등록번호, 대표자, 기업기분,
                          e-메일{" "}
                        </li>
                      </CustomOL>
                    </li>
                    <li>
                      2) 이벤트 경품 발송시 수집하는 항목 필수 항목: 이름,
                      휴대전화, 주민등록번호(근거 : 소득세법)
                    </li>
                    <li>
                      3) 서비스 이용과정이나 사업처리 과정에서 자동 수집되는
                      항목 IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량
                      이용 기록, 광고 ID,접속 환경
                    </li>
                  </CustomOL>
                  <p style={{ fontWeight: "bold" }}>수집 및 이용목적 </p>
                  <CustomOL>
                    <li>
                      {" "}
                      회원관리 회원제 서비스 이용에 따른 본인확인, 본인의
                      의사확인, 고객문의에 대한 응답, 새로운 정보의 소개 및
                      고지사항 전달
                    </li>
                    <li>
                      서비스 제공에 관한 계약 이행 및 서비스 제공 부정
                      이용방지와 비인가 사용방지
                    </li>
                    <li>
                      서비스 개발 및 마케팅ㆍ광고 활용 맞춤 서비스 제공, 서비스
                      안내 및 이용권유, 서비스 개선 및 신규 서비스 개발을 위한
                      통계 및 접속빈도 파악, 통계학적 특성에 따른 광고, 이벤트
                      정보 및 참여기회 제공
                    </li>
                    <li>
                      고용 및 취업동향 파악을 위한 통계학적 분석, 서비스
                      고도화를 위한 데이터 분석
                    </li>
                  </CustomOL>
                  <p style={{ fontWeight: "bold" }}>보유기간</p>
                  <CustomOL>
                    <li>
                      회원탈퇴 후 30일 이내 또는 법령에 따른 보존기간(단,
                      부정이용 확인 시 회원탈퇴 후 6 개월) 기타 거래처 뉴스레터
                      발송, 각종 이벤트, 마케팅, 설문조사 등을 위하여 해당
                      발송에 대한 수신동의를 받아 이메일을 수집하고 있으며, 각종
                      이벤트시 그 내용에 따라 추가 항목들을 제공받아 이용하고
                      있습니다. 그리고 중복가입방지, 문의사항처리, 부정 이용자
                      제재, 포인트 정산, 공지사항 전달, 분쟁조정을 위한 기록보존
                      등 회원관리와 신규 서비스 개발을 위한 통계적 분석을 위해
                      서비스 이용기록 등의 사항들을 저장하고 있습니다.
                    </li>
                  </CustomOL>
                </li>
                <li>
                  개인정보 수집방법
                  <CustomOL>
                    <li>
                      회사는 온라인 회원가입, 회원정보수정, 서면양식, 전화 또는
                      팩스, 서비스 이용, 제휴사로부터의 제공, 단말기를 통한
                      자동수집, 이메일과 같은 방법을 통하여 개인정보를
                      수집합니다.
                    </li>

                    <li>
                      이용자의 사상, 신념, 과거의 병력 등 개인의 권리, 이익이나
                      사생활을 뚜렷하게 침해할 우려가 있는 민감정보는 수집하지
                      않으며, 회사는 이용자의 개인정보를 수집할 때, 사전에 해당
                      사실을 이용자에게 알리고 동의를 구합니다.
                    </li>

                    <li>
                      다만, 이용자가 요구하는 서비스를 제공하는 과정에서 이를
                      이행하기 위하여 필요한 개인정보로서 경제적, 기술적 사유로
                      통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우, 서비스
                      제공에 따른 요금정산을 위하여 필요한 경우, 기타
                      정보통신망법 또는 다른 법률에 특별한 규정이 있는 경우에는
                      동의를 받지 않고 이용자의 개인정보를 수집할 수 있습니다.
                    </li>
                  </CustomOL>
                </li>
                <li>
                  <span className="first_letter">3.</span>(주)대티즌은
                  개인정보보호정책을 홈페이지 첫 화면에 공개함으로써 귀하께서
                  언제나 용이하게 보실 수 있도록 조치하고 있습니다.
                </li>
                <li>
                  <span className="first_letter">4.</span> (주)대티즌은
                  개인정보보호정책의 지속적인 개선을 위하여 개인정보보호정책을
                  개정하는데 필요한 절차를 정하고 있습니다. 그리고
                  개인정보보호정책을 개정하는 경우 버전번호 등을 부여하여 개정된
                  사항을 귀하께서 쉽게 알아볼 수 있도록 하고 있습니다 . 개인정보
                  수집에 대한 동의 (주)대티즌은 귀하께서 (주)대티즌의
                  개인정보보호방침 또는 이용약관의 내용에 대해「동의함」버튼
                  또는「동의안함」버튼을 클릭할 수 있는 절차를
                  마련하여,「동의함」버튼을 클릭하면 개인정보 수집에 대해 동의한
                  것으로 봅니다.
                </li>
              </CustomOL>
              <h3>
                <a name="a2">제 4 조 (개인정보의 보유 및 이용기간)</a>
              </h3>
              <CustomOL>
                <li>
                  필수항목/선택항목의 경우 회원가입일로부터 탈퇴 후 30일까지,
                  추가항목의 경우 이용 완료시까지 보유 후 파기하고 있고,
                  탈퇴하기 전이라도 최근 1년간 서비스를 사용하지 않은 이용자의
                  정보는 분리하여 파기하고 있습니다. 단, 법령에 의해 보존해야만
                  하는 경우 또는 불량이용자 제재와 관련 분쟁 방지를 위하여
                  아래와 같이 일정 기간 동안 회원정보를 보존합니다.
                  <CustomOL>
                    <li>
                      계약 또는 청약철회 등에 관한 기록 | 전자상거래 등에서의
                      소비자보호에 관한 법률 | 5년
                    </li>
                    <li>
                      소비자의 불만 또는 분쟁처리에 관한 기록 | 전자상거래
                      등에서의 소비자보호에 관한 법률 | 5년
                    </li>
                    <li>
                      대금결제 및 재화 등의 공급에 관한 기록 | 전자상거래
                      등에서의 소비자보호에 관한 법률 | 5년
                    </li>
                    <li>
                      전자금융 거래에 관한 기록 | 전자금융거래법 | 1년 또는 5년
                    </li>
                    <li>
                      서비스 방문기록, 접속로그, 접속아이피 | 통신비밀보호법 |
                      3개월
                    </li>
                  </CustomOL>
                </li>
              </CustomOL>
              <h3>
                <a name="a2">제 5 조 개인정보의 파기 절차 및 방법</a>
              </h3>
              <CustomOL>
                <li>
                  회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는
                  해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과
                  같습니다.
                  <CustomOL>
                    <li>
                      <p>
                        <b>파기절차</b>
                      </p>
                      <p>
                        이용자가 회원가입 등을 위해 입력하신 정보는 목적이
                        달성된 후 별도의 DB 로 옮겨져(종이의 경우 별도의 서류함)
                        내부 방침 및 기타 관련 법령에 의한 정보보호 사유에
                        따라(보유 및 이용기간 참조) 일정 기간 저장된 후
                        파기됩니다. 별도 DB 로 옮겨진 개인정보는 법률에 의한
                        경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지
                        않습니다.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>파기기한</b>
                      </p>
                      <p>
                        이용자의 개인정보는 개인정보의 보유기간이 경과된
                        경우에는 보유 기간의 종료일로부터 30일 이내에 파기하며
                        개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의
                        종료 등 그 개인정보가 불필요하게 되었을 때에는
                        개인정보의 처리가 불필요한 것으로 인정되는 날로부터 30일
                        이내에 그 개인정보를 파기합니다.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>파기방법</b>
                      </p>
                      <p>
                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
                        통하여 파기하며,
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>장기 미이용자의 개인정보 파기</b>
                      </p>
                      <p>
                        정보통신망법에 따라 회원가입 후 서비스 이용이 없는 고객
                        및 12개월 이상 로그인하지 않은 경우 해당 이용자 ID 및
                        개인정보를 유효기간 경과 후 즉시 파기하거나, 분리하여
                        보관합니다. 이러한 경우 위 12개월의 기간 도래 30일
                        전까지 회사는 이용자의 개인정보가 분리되어 저장,
                        관리되고 있다는 사실과 기간 만료일 및 해당 개인정보의
                        항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한
                        방법 중 어느 하나의 방법으로 이용자에게 알려드립니다.
                        명시한 기한 내에 로그인 하지 않거나 서비스 이용이 없는
                        경우에는 회원자격을 상실시킬 수 있습니다. 이 경우, 회원
                        아이디를 포함한 회원의 개인정보 및 서비스 이용 정보는
                        파기, 삭제됩니다
                      </p>
                    </li>
                  </CustomOL>
                </li>
              </CustomOL>
              <h3>
                <a name="a2">
                  제 6 조 {"("}이용자 및 법정대리인의 권리와 그 행사방법{")"}
                </a>
              </h3>
              <CustomOL>
                <li>
                  <p>
                    회사는 이용자 및 법정대리인의 권리를 다음과 같이 보호하고
                    있습니다.
                  </p>
                  <CustomOL>
                    <li>
                      가. 언제든지 자신의 개인정보를 조회하고 수정할 수
                      있습니다.
                    </li>
                    <li>
                      나. 언제든지 개인정보 제공에 관한 동의철회/회원가입 해지를
                      요청할 수 있습니다.
                    </li>
                    <li>
                      다. 정확한 개인정보의 이용 및 제공을 위해 이용자가
                      개인정보 수정 진행 시 수정이 완료될 때까지 이용자의
                      개인정보는 이용되거나 제공되지 않습니다. 이미 제 3 자에게
                      제공된 경우에는 지체 없이 제공받은 자에게 사실을 알려
                      수정이 이루어질 수 있도록 하겠습니다.
                    </li>
                    <li>
                      권리 행사는 고객센터에서 할 수 있으며 서면, 전화,
                      전자우편, 등을 통해서도 하실 수 있습니다.
                    </li>
                    <li>
                      서면, 전화, 전자우편, 등을 통해 요청하는 경우
                      개인정보보호법 시행규칙 별지 제8호 서식에 따라
                      개인정보(열람,정정,삭제,처리정지) 요구서를 제출하시면 이에
                      대해 지체없이(10 일 이내) 조치해 드릴 것 입니다. 이용자가
                      개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
                      완료하기 전까지 당해 개인정보를 이용 또는 제공하지
                      않습니다. 또한, 잘못된 개인정보를 제3자에게 이미 제공한
                      경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여
                      정정이 이루어지도록 하겠습니다.
                    </li>
                    <li>
                      회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는
                      삭제된 개인정보는 "4. 개인정보의 보유 및 이용기간"에
                      명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할
                      수 없도록 처리하고 있습니다.
                    </li>
                    <li>
                      또한 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
                      통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙
                      별지 제 11 호 서식에 따른 위임장을 제출하셔야 합니다.
                    </li>
                  </CustomOL>
                </li>
              </CustomOL>
              <h3>
                <a name="a2">
                  제 7 조 {"("}개인정보 자동수집장치의 설치, 운영 및 거부에 관한
                  사항{")"}
                </a>
              </h3>
              <CustomOL>
                <li>
                  <p>
                    회사는 이용자의 정보를 수시로 저장하고 찾아내는
                    "쿠키(cookie)"와 "세션"을 운용합니다. "쿠키"란 회사의
                    웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에
                    보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터
                    하드디스크에 저장됩니다. "세션"이란 웹사이트를 운영하는데
                    이용되는 서버가 이용자 로그인 시간 동안에 이용자의 정보를
                    서버에 저장하는 것을 의미합니다. 회사는 다음과 같은 목적을
                    위해 쿠키와 세션을 사용합니다.
                  </p>
                </li>
                <li>
                  <p>
                    <b>쿠키와 세션의 사용 목적</b>
                  </p>
                  <p>
                    회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서
                    이용자들의 정보를 저장하고 수시로 불러오는 "세션(session)"을
                    사용합니다. 이용자는 쿠키 설치에 대한 선택권을 가지고
                    있습니다. 따라서, 이용자는 웹브라우저에서 옵션을
                    설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다
                    확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
                    있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    <b>쿠키 설정 거부 방법</b>
                  </p>
                  <p>
                    쿠키 설정을 거부하는 방법으로는 이용자가 사용하시는 웹
                    브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
                    저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수
                    있습니다. 인터넷 익스플로러의 경우 : 웹 브라우저 상단의 도구
                    -> 인터넷 옵션 -> 개인정보 단, 이용자께서 쿠키 설치를
                    거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    <b>세션의 설치/운영 및 거부</b>
                  </p>
                  <p>
                    이용자는 세션 설치에 대한 선택권을 가지고 있지 않습니다.
                    로그인이 필요한 회사의 서비스의 경우 회사 운영 서버에서
                    자동으로 세션이 생성됩니다.
                  </p>
                </li>
              </CustomOL>
              <h3>
                <a name="a2">
                  제 8 조 {"("}개인정보의 기술적, 관리적 보호대책{")"}
                </a>
              </h3>
              <CustomOL>
                <li>
                  회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실,
                  도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                  다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
                </li>
                <li>
                  <p>
                    <b>개인정보 암호화</b>
                  </p>
                  <p>
                    이용자의 개인정보(성별 등)는 암호화하여 저장/관리되고
                    있으며, 암호화통신(SSL) 등을 통하여 네트워크상에서
                    개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
                    비밀번호는 복호화가 불가능한 일방향 암호화되어 저장/관리되고
                    있습니다
                  </p>
                </li>
                <li>
                  <p>
                    <b>해킹 등에 대비한 대책</b>
                  </p>
                  <p>
                    회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의
                    개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터
                    접근이 통제된 구역에 시스템을 설치하고 있습니다. 또한
                    개인정보의 훼손에 대비해서 자료를 수시로 백업하고, 최신
                    백신프로그램을 이용하여 이용자들의 개인정보나 자료가
                    유출되거나 손상되지 않도록 방지하고 있으며, 암호화통신(SSL)
                    등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수
                    있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여
                    외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로
                    보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려고
                    노력하고 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    <b>처리 직원의 최소화 및 교육실시</b>
                  </p>
                  <p>
                    개인정보를 처리하는 직원을 최소화 하며, 업무용 PC 에서는
                    외부 인터넷 서비스를 사용할 수 없도록 차단하여 개인정보
                    유출에 대한 위험을 줄이고 있습니다. 또한 개인정보를 보관하는
                    데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한
                    비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한
                    체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
                    또한 개인정보를 처리하는 모든 임직원들을 대상으로
                    개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을
                    실시하고 있습니다.
                  </p>
                </li>
              </CustomOL>
              <h3>
                <a name="a2">
                  제 9 조 {"("}개인정보에 관한 민원서비스{")"}
                </a>
              </h3>
              <CustomOL>
                <li>
                  회사는 개인정보보호 전담 조직 운영을 통하여 개인정보
                  처리방침의 이행사항 및 담당자의 준수여부를 확인하고 있으며,
                  이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
                  위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고
                  있습니다.
                </li>
                <li>
                  <p>
                    <b>개인정보보호 책임자</b>
                  </p>
                  <p>책임자 : 한성일</p>
                  <p>직위 : 대표</p>
                  <p>문의 : detizen @detizen.com</p>
                  <p>
                    {" "}
                    또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신
                    경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
                  </p>
                </li>
                <li>
                  개인정보침해 신고센터 (국번없이) 118
                  https://privacy.kisa.or.kr
                </li>
                <li>
                  대검찰청 사이버수사과(국번없이) 1301 http://www.spo.go.kr
                </li>
                <li>
                  경찰청 사이버안전국 (국번없이) 182
                  http://cyberbureau.police.go.kr/
                </li>
              </CustomOL>
              <h3>
                <a name="a2">
                  제 10 조 {"("}기타 개인정보 처리에 관한 방침{")"}
                </a>
              </h3>
              <CustomOL>
                <li>
                  <p>
                    <b>부가서비스 이용에 따른 개인정보 처리방침</b>
                  </p>
                  <p>
                    회사가 제공하는 부가서비스 중 회원가입이 필요한 서비스의
                    경우 개인정보 처리방침에 대한 동의를 따로 받습니다.
                  </p>
                </li>
                <li>
                  <p>
                    <b>개인 아이디와 비밀번호 관리</b>
                  </p>
                  <p>
                    이용자가 사용하고 계시는 ID 와 비밀번호는 원칙적으로
                    이용자만이 사용하도록 되어 있습니다. 회사의 고의 또는 과실이
                    없는 경우에, 이용자의 ID 와 비밀번호 도용 또는 기타 타인의
                    사용으로 인해 발생된 문제에 대하여 회사가 책임지지 않습니다.
                    어떠한 경우에도 비밀번호는 타인에게 알려 주지 마시고 로그온
                    상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록
                    특별한 주의를 기울여 주시기 바랍니다. 타인의 개인정보를
                    도용하여 회원 가입 또는 계약이 확인되었을 경우에는
                    이용계약이 일방적으로 해지될 수 있으며, 주민등록법에 의해
                    3년 이하의 징역 또는 1천만원 이하의 벌금이 부과될 수
                    있습니다.
                  </p>
                </li>
              </CustomOL>
              <h3>
                <a name="a2">
                  제 11 조 {"("}고지의무{")"}
                </a>
              </h3>
              <CustomOL>
                <li>
                  현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는
                  시행일자 최소 7 일전부터 대티즌 웹사이트
                  http://www.detizen.com "공지사항" 화면을 통해 공고할 것입니다.
                </li>
                <li>공고일자 : 2023년 1월 30일</li>
                <li>시행일자 : 2023년 1월 3.0일</li>
              </CustomOL>
            </div>
          </div>
        </div>
      </div>
      <div data-ml-modal id="modal03">
        <a href="#!" className="modal-overlay"></a>
        <div className="modal-dialog modal-dialog-lg">
          <a href="#!" className="modal-close">
            &times;
          </a>
          <h3>책임의 한계와 법적고지</h3>
          <div className="modal-content terms03">
            <div className="section">
              <h3 className="first">
                <a name="a1">책임의 한계</a>
              </h3>
              <p>
                {" "}
                대티즌은 링크, 다운로드, 광고 등을 포함하여 본 웹 사이트에
                포함되어 있거나, 본 웹 사이트를 통해 배포, 전송되거나, 본 웹
                사이트에 포함되어 있는 서비스로부터 접근되는 정보(이하 "자료")의
                정확성이나 신뢰성에 대해 어떠한 보증도 하지 않으며, 서비스상의,
                또는 서비스와 관련된 광고, 기타 정보 또는 제안의 결과로서
                디스플레이, 구매 또는 취득하게 되는 제품 또는 기타 정보(이하
                "제품")의 질에 대해서도 어떠한 보증도 하지 않습니다.
              </p>
              <br />
              <p>
                귀하는, 자료에 대한 신뢰 여부가 전적으로 귀하의 책임임을
                인정합니다. 대티즌은 자료 및 서비스의 내용을 수정할 의무를 지지
                않으나, 필요에 따라 개선할 수는 있습니다.대티즌은 자료와
                서비스를 "있는 그대로" 제공하며, 서비스 또는 기타 자료 및 제품과
                관련하여 상품성, 특정 목적에의 적합성에 대한 보증을 포함하되
                이에 제한되지 않고 모든 명시적 또는 묵시적인 보증을 명시적으로
                부인합니다.
              </p>
              <br />
              <p>
                어떠한 경우에도 대티즌은 서비스, 자료 및 제품과 관련하여 직접,
                간접, 부수적, 징벌적, 파생적인 손해에 대해서 책임을 지지
                않습니다. 대티즌은 본 웹사이트를 통하여 인터넷을 접속함에 있어
                사용자의 개인적인 판단에 따라 하시기를 바랍니다. 본 웹사이트를
                통해 일부 사람들이 불쾌하거나 부적절 하다고 여기는 정보가
                포함되어 있는 사이트로 연결될 수 있습니다. 대티즌은 본 웹사이트
                또는 자료에 열거되어 있는 사이트의 내용을 검토하려는 노력과
                관련하여 어떠한 보증도 하지 않습니다. 또한 대티즌은 본 웹사이트
                또는 자료에 열거되어 있는 사이트상의 자료의 정확성, 저작권 준수,
                적법성 또는 도덕성에 대해 아무런 책임을 지지 않습니다.
              </p>
              <br />
              <p>
                대티즌은 지적재산권을 포함한 타인의 권리를 존중하며, 사용자들도
                마찬가지로 행동해주시기를 기대합니다. 대티즌은 필요한 경우 그
                재량에 의해 타인의 권리를 침해하거나 위반하는 사용자에 대하여
                사전 통지 없이 서비스 이용 제한 조치를 취할 수 있습니다.
              </p>
              <h3>
                <a name="a2">상표에 관한 정보</a>
              </h3>
              <p>
                본 웹사이트에 포함된 제품 및/또는 서비스의 명칭은 대티즌의 상표
                또는 등록 상표입니다.
              </p>
              <p>
                회사는 이에 대한 권리를 가지고 있으며, 다른 제품 및 회사의
                명칭은 그 해당 사용자들의 상표일 수 있습니다.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
