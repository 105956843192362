import React from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "react-query";
import { NormalReqApi } from "../../utils/ReqApi";
import custom from "../../assets/css/Band.module.scss";
import { BandSlider } from "../MainSlider/DetailSlider";

const SETTINGS = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 6000,
  Infinity: true,
  centerMode: false,

  // infinite: true,
  focusOnSelect: true,
};
const BandBanner = () => {
  const { data, error } = useQuery(
    ["bandB"],
    async () =>
      await NormalReqApi("get", `/banner/3/${isMobile ? "phone" : "pc"}`),
    { retry: true, suspense: true }
  );

  console.log(data);
  // console.log("banerB", data);

  return (
    <BandSlider
      settings={SETTINGS}
      list={data.data}
      className={custom.band}
      aClassName={custom.a}
    />
  );
};

export default BandBanner;
