import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DContentsSubMenu,
  DContentSubContainer,
  DContentView,
  DContentViewInfo,
} from "../../components/DContents/Common/Element";
import { WeeklyView as WeeklyViewSuspense } from "../../components/DContents/Weekly/list";

const WeeklyView = () => {
  const navi = useNavigate();
  const { id } = useParams();
  const handleGoBack = () => {
    navi("/weekly");
  };

  return (
    <div className={"container sub"}>
      <DContentsSubMenu current={"weekly"} />
      <Suspense fallback={<DContentView label={"위클리"} />}>
        <WeeklyViewSuspense id={id} handleGoBack={handleGoBack} />
      </Suspense>
    </div>
  );
};

export default WeeklyView;
