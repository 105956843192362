import React, { useEffect, useRef, useState } from "react";
import { LabelInputElement } from "../Free/FreeElement";
import ProStyle from "../../../assets/css/promo.module.scss";
import {
  PromoLabelInput,
  PromoLiNormalLabel,
  PromoTextArea,
} from "./PromoElement";
import { useRecoilState } from "recoil";
import { commonSelect, productSelect } from "../../../recoil/AtomConfig";
import { NormalReqApi } from "../../../utils/ReqApi";
import { LoadCommonInfo } from "../../../utils/loadCommon";

export const PromoManager = ({ origin, onChange }) => {
  const [manager, setManager] = useState({
    cName: "",
    name: "",
    phone: "",
    email: "",
    emailYn: true,
  });

  const handleChange = (e, target) => {
    switch (target) {
      case "cName":
        setManager({ ...manager, cName: e.target.value });
        break;
      case "name":
        setManager({ ...manager, name: e.target.value });
        break;
      case "phone":
        setManager({ ...manager, phone: e.target.value });
        break;
      case "email":
        setManager({ ...manager, email: e.target.value });
        break;
      case "emailYn":
        setManager({ ...manager, emailYn: e });
        // setManager({ ...manager, emailYn: e.target.value });
        break;
    }
  };
  useEffect(() => {
    onChange({ ...origin, manager });
  }, [manager]);
  return (
    <>
      <h2 className={"sub_title"}>
        <span>담당자 정보</span>
      </h2>
      <ul>
        <li>
          <LabelInputElement label={"업체명"}>
            <input
              type="text"
              className="input_text w100"
              onChange={(e) => handleChange(e, "cName")}
            />
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement label={"이름"}>
            <input
              type="text"
              className={"input_text w100"}
              onChange={(e) => handleChange(e, "name")}
            />
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement label={"연락처"}>
            <input
              type="text"
              className={"input_text w100"}
              onChange={(e) => handleChange(e, "phone")}
            />
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement label={"이메일"}>
            <input
              type="text"
              className={"input_text w100"}
              onChange={(e) => handleChange(e, "email")}
            />
            <ul className={"agreement"}>
              <li className="customChk">
                <span className={"agree_title"}>이메일 수신여부</span>
                <input
                  type="radio"
                  name="agree"
                  id="agree01_ok"
                  defaultChecked
                  onClick={(e) => handleChange(true, "emailYn")}
                />
                <label htmlFor="agree01_ok">동의</label>
                <input
                  type="radio"
                  name="agree"
                  id="agree01_no"
                  onClick={(e) => handleChange(false, "emailYn")}
                />
                <label htmlFor="agree01_no">거부</label>
              </li>
            </ul>
          </LabelInputElement>
        </li>
      </ul>
    </>
  );
};

export const PromoBasicIndex = ({ origin, onChange, children }) => {
  const [curTab, setCurTab] = useState("a");

  useEffect(() => {
    onChange({ ...origin, reqType: curTab });
  }, [curTab]);

  return (
    <ul>
      <li>
        <PromoTypeChange change={setCurTab} />
      </li>

      {curTab === "a" ? (
        <AgentForm bannerList={[]} origin={origin} onChange={onChange} />
      ) : curTab === "b" ? (
        <ContestForm origin={origin} onChange={onChange} />
      ) : (
        <SupportersForm origin={origin} onChange={onChange} />
      )}
    </ul>
  );
};

export const PromoTypeChange = ({ change }) => {
  const handleChange = (type) => {
    change(type);
  };
  return (
    <>
      <label className={`input_title w20 ${ProStyle.input_title}`}>구분</label>
      <div
        className={`input_area w80 customChk ${ProStyle.input_area} ${ProStyle.agentType}`}
      >
        <input
          type="radio"
          name="contestType"
          id="contestType"
          value="a"
          defaultChecked
          onClick={() => handleChange("a")}
        />
        <label style={{ width: "auto" }} htmlFor="contestType">
          광고 상품
        </label>
        <input
          type="radio"
          name="contestType"
          id="contestType2"
          value="b"
          onClick={() => handleChange("b")}
        />
        <label style={{ width: "auto" }} htmlFor="contestType2">
          공모전 대행
        </label>
        <input
          type="radio"
          name="contestType"
          id="contestType3"
          value="c"
          onClick={() => handleChange("c")}
        />
        <label style={{ width: "auto" }} htmlFor="contestType3">
          서포터즈 대행
        </label>
      </div>
    </>
  );
};

export const AgentForm = ({ bannerList, origin, onChange }) => {
  const [fileCnt, setFileCnt] = useState(1);
  const [product, setProduct] = useRecoilState(productSelect);
  const [curProduct, setCurProduct] = useState({
    loading: true,
  });
  const [options, setOptions] = useState({
    loading: true,
  });
  const [submitData, setSubmitData] = useState({
    productId: null,
    customPeriod: "",
    period: null,
    price: null,
    title: null,
    des: null,
    attachment: [],
  });

  useEffect(() => {
    async function getProduct() {
      const res = await NormalReqApi("get", "/product");
      setProduct((prev) => {
        const pObject = { ...prev };
        pObject.product = [...res.data];
        return { ...pObject };
      });
    }

    if (product.product.length > 0) {
      console.log("data is exist");
    } else {
      getProduct();
      console.log("data load required");
    }
  }, []);

  useEffect(() => {
    onChange({ ...origin, submitData });
  }, [submitData]);

  const handleChangeProduct = (type) => {
    if (type.target.value !== "init") {
      setCurProduct({
        ...product.product.filter((f) => f._id === type.target.value)[0],
        loading: false,
      });
      setSubmitData({
        ...submitData,
        productId: product.product.filter((f) => f._id === type.target.value)[0]
          ._id,
      });
    }
  };

  const handlePrice = (period, price) => {
    console.log(period, price);
    if (period === 0 && price === 0) {
      setCustom(true);
      setOptions({
        loading: false,
        period: period === 0 ? 999 : period,
        price: period === 0 ? 999 : price,
      });
      setSubmitData({
        ...submitData,
        period: 999,
        price: 999,
      });
    } else {
      setOptions({
        loading: false,
        period: period === 0 ? 999 : period,
        price: period === 0 ? 999 : price,
      });
      setCustom(false);
      setSubmitData({
        ...submitData,
        period,
        price,
      });
    }
  };

  const handleCustomInput = (days) => {
    if (days.target.value) {
      setOptions({
        ...options,
        loading: false,
        period: 999,
        price: 999,
      });
      setSubmitData({
        ...submitData,
        customPeriod: days.target.value,
        period: 999,
        price: 999,
      });
    } else {
      setSubmitData({
        ...submitData,
        customPeriod: days.target.value,
        period: 999,
        price: 999,
      });
    }
  };

  const handleTitleChange = (e) => {
    setSubmitData({
      ...submitData,
      title: e.target.value,
    });
  };

  const handleTextareaChange = (e) => {
    setSubmitData({
      ...submitData,
      des: e.target.value,
    });
  };

  const handleFileChange = (e, subInfo) => {
    const tempFile = curFile;
    tempFile[subInfo].file = e.target.files;
    setCurFile([...tempFile]);
  };
  const [curFile, setCurFile] = useState([{ file: null }]);
  useEffect(() => {
    setSubmitData({ ...submitData, attachment: [...curFile] });
  }, [curFile]);
  const increaseFile = () => {
    const cnt = fileCnt;
    setCurFile([...curFile, { file: null }]);
    setFileCnt(cnt + 1);
  };
  const decreaseFile = (e) => {
    setCurFile([...curFile.filter((f, i) => i !== e)]);
    const cnt = fileCnt;
    setFileCnt(cnt - 1);
  };

  const [custom, setCustom] = useState(false);
  const customRef = useRef(null);

  return (
    <>
      <li>
        <PromoLabelInput label={"광고 상품"}>
          <select
            className={`slt_inCharge w30 ${ProStyle.select}`}
            style={{ margin: 0 }}
            onChange={(e) => handleChangeProduct(e)}
          >
            <option value="init">광고상품 선택</option>
            {product.product.length > 0 &&
              product.product.map((item, index) => (
                <option value={item._id} key={item._id}>
                  {item.title}
                </option>
              ))}
          </select>
        </PromoLabelInput>
      </li>
      <li>
        <PromoLabelInput label={"기간"}>
          <div className="input_area w80 customChk">
            {!curProduct.loading &&
              curProduct.period.map((item, index) => (
                <React.Fragment key={item.ordered}>
                  <input
                    type="radio"
                    name="period"
                    id={`period${item.period}`}
                    value={item.period}
                    onClick={() => handlePrice(item.period, item.price)}
                  />
                  <label
                    style={{ width: "auto" }}
                    htmlFor={`period${item.period}`}
                  >
                    {item.period === 0 ? "직접 입력" : item.period + "일"}
                  </label>
                </React.Fragment>
              ))}
          </div>
          {custom && (
            <>
              <input
                type="text"
                ref={customRef}
                className={"input_text addon w90"}
                style={{ display: "block" }}
                placeholder="예) 1월 1일부터 1월 31일까지"
                // min={0}
                onChange={(e) => handleCustomInput(e)}
              />
              <span style={{ fontSize: "0.85em" }}></span>
            </>
          )}
        </PromoLabelInput>
      </li>
      <li>
        <PromoLabelInput
          label={"상품 가격"}
          subStyle={{
            backgroundColor: "#fff",
            border: "1px solid rgba(0,0,0,.1)",
          }}
        >
          {!options.loading && (
            <>
              <p style={{ padding: "0 0.75em", fontSize: "0.85em" }}>
                {options.period === 999
                  ? "협의 후 책정"
                  : options.price.toLocaleString(undefined, {
                      maximumFractionDigits: 3,
                    })}
                {console.log(options.period)}
              </p>
              {options.period !== 0 && <small>원 (VAT 별도)</small>}
            </>
          )}
        </PromoLabelInput>
      </li>
      <li>
        <PromoLabelInput label={"공모전 명"}>
          <input
            type="text"
            className={"input_text w100"}
            onChange={(e) => handleTitleChange(e)}
          />
        </PromoLabelInput>
      </li>
      <li>
        <PromoTextArea label={"요청사항"} onChange={handleTextareaChange} />
      </li>
      <li>
        <PromoLabelInput label={"첨부파일"}>
          <div
            className={"w100"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              className={"input_text"}
              style={{ border: "none" }}
              accept={".jpg,.png"}
              onChange={(e) => handleFileChange(e, 0)}
            />
            <button
              type={"button"}
              style={{
                background: "#263050",
                border: "none",
                color: "white",
                cursor: "pointer",
                padding: "0.2em 0.5em",
                fontSize: "0.75em",
              }}
              onClick={increaseFile}
            >
              추가
            </button>
          </div>
          {fileCnt > 1 &&
            Array.from(new Array(fileCnt), (el, index) => {
              return (
                index != 0 && (
                  <div
                    key={`f_c_i_${index}`}
                    className={"w100"}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="file"
                      className="input_text w75"
                      style={{ border: "none" }}
                      accept=".jpg"
                      onChange={(e) => handleFileChange(e, index)}
                    />
                    <button
                      type={"button"}
                      style={{
                        background: "red",
                        border: "none",
                        color: "white",
                        cursor: "pointer",
                        padding: "0.2em 0.5em",
                        fontSize: "0.75em",
                      }}
                      onClick={() => decreaseFile(index)}
                    >
                      삭제
                    </button>
                  </div>
                )
              );
            })}
          <span
            className="help-block"
            style={{
              display: "block",
              marginTop: "5px",
              color: "#AAB2BD",
              fontSize: "17px",
            }}
          >
            ※ 대행 견적을 위한 참고자료(기획안, 포스터 이미지 등)를
            첨부해주세요.
          </span>
        </PromoLabelInput>
      </li>
    </>
  );
};

export const ContestForm = ({ origin, onChange }) => {
  const [basic, setBasic] = useRecoilState(commonSelect);
  const [fileCnt, setFileCnt] = useState(1);

  useEffect(() => {
    async function reqCommon() {
      const { data } = await LoadCommonInfo();
      setBasic((prev) => {
        const prevObject = { ...prev };
        prevObject.loading = false;
        prevObject.category = [...data.category];
        prevObject.host = [...data.host];
        prevObject.target = [...data.target];
        prevObject.totalReward = [...data.totalReward];
        prevObject.region = [...data.region];
        prevObject.ag = [...data.ag];
        return { ...prevObject };
      });
    }
    console.log(basic);
    if (basic.ag.length === 0) {
      reqCommon();
    }
  }, []);

  const [type, setType] = useState(1);
  const [submitData, setSubmitData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    category: [],
    target: [],
    totalReward: null,
    request: "",
    attachment: [],
    agentRange: [],
  });

  const [curCategory, setCurCategory] = useState([]);
  useEffect(() => {
    setSubmitData({ ...submitData, category: [...curCategory] });
  }, [curCategory]);

  const [curTarget, setCurTarget] = useState([]);
  useEffect(() => {
    setSubmitData({ ...submitData, target: [...curTarget] });
  }, [curTarget]);

  const [curArange, setCurArange] = useState([]);
  useEffect(() => {
    setSubmitData({ ...submitData, agentRange: [...curArange] });
  }, [curArange]);

  const [curFile, setCurFile] = useState([{ file: null }]);
  useEffect(() => {
    setSubmitData({ ...submitData, attachment: [...curFile] });
  }, [curFile]);
  const increaseFile = () => {
    const cnt = fileCnt;
    setCurFile([...curFile, { file: null }]);
    setFileCnt(cnt + 1);
  };
  const decreaseFile = (e) => {
    setCurFile([...curFile.filter((f, i) => i !== e)]);
    const cnt = fileCnt;
    setFileCnt(cnt - 1);
  };

  const handleChange = (e, type, subInfo) => {
    switch (type) {
      case "title":
        setSubmitData({ ...submitData, title: e.target.value });
        break;
      case "startDate":
        setSubmitData({ ...submitData, startDate: e.target.value });
        break;
      case "endDate":
        setSubmitData({ ...submitData, endDate: e.target.value });
        break;
      case "category":
        const containedC = curCategory.filter((f) => f.code === e.target.value);
        const { code: cCode, sub: cText } = basic.category.filter(
          (f) => f.code === e.target.value
        )[0];
        if (containedC.length !== 0) {
          setCurCategory([
            ...curCategory.filter((f) => f.code !== e.target.value),
          ]);
        } else {
          setCurCategory([...curCategory, { code: cCode, text: cText }]);
        }
        break;
      case "target":
        const containedT = curTarget.filter((f) => f.code === e.target.value);
        const { code: tCode, text: tText } = basic.target.filter(
          (f) => f.code === e.target.value
        )[0];
        if (containedT.length !== 0) {
          setCurTarget([...curTarget.filter((f) => f.code !== e.target.value)]);
        } else {
          setCurTarget([...curTarget, { code: tCode, text: tText }]);
        }
        break;
      case "agentRange":
        const containedAR = curArange.filter((f) => f.code === e.target.value);
        const { code: arCode, text: arText } = basic.ag.filter(
          (f) => f.code === e.target.value
        )[0];
        if (containedAR.length !== 0) {
          setCurArange([...curArange.filter((f) => f.code !== e.target.value)]);
        } else {
          setCurArange([...curArange, { code: arCode, text: arText }]);
        }
        break;

      case "ttr":
        const { code: ttCode, text: ttText } = basic.totalReward.filter(
          (f) => f.code === e.target.value
        )[0];
        setSubmitData({
          ...submitData,
          totalReward: { code: ttCode, text: ttText },
        });
        break;
      case "request":
        setSubmitData({ ...submitData, request: e.target.value });
        break;
      case "attachment":
        const tempFile = curFile;
        tempFile[subInfo].file = e.target.files;
        setCurFile([...tempFile]);

        // setSubmitData({ ...submitData, attachment: e.target.files });
        break;
    }
  };

  useEffect(() => {
    onChange({ ...origin, submitData });
  }, [submitData]);

  return (
    <>
      <li>
        <PromoLabelInput label={"공모전 명"}>
          <input
            type="text"
            className="input_text w100"
            placeholder="예시 ) 00 0000 공모전"
            onChange={(e) => handleChange(e, "title")}
          />
        </PromoLabelInput>
      </li>

      <li className="b c">
        <PromoLabelInput label={"접수 기간"}>
          <input
            type="datetime-local"
            className="input_text w30"
            // placeholder="예시 ) 2023. 01. 01. 오전 09:00"
            onChange={(e) => handleChange(e, "startDate")}
          />
          <span className="dash">-</span>
          <input
            type="datetime-local"
            className="input_text w30"
            onChange={(e) => handleChange(e, "endDate")}
          />
        </PromoLabelInput>
      </li>
      {/* <li>
        <label className={`input_title w20 ${ProStyle.input_title}`}>
          구분
        </label>
        <div
          className={`input_area w80 customChk ${ProStyle.input_area} ${ProStyle.agentType}`}
        >
          <input
            type="radio"
            name="type"
            id="cType"
            value="1"
            defaultChecked
            onClick={() => setType(1)}
          />
          <label style={{ width: "auto" }} htmlFor="cType">
            공모전
          </label>
          <input
            type="radio"
            name="type"
            id="aType"
            value="2"
            onClick={() => setType(2)}
          />
          <label style={{ width: "auto" }} htmlFor="aType">
            대외활동
          </label>
        </div>
      </li> */}
      <li>
        <label
          className={`input_title w20 ${ProStyle.input_title} ${ProStyle.inputType}`}
        >
          분야
        </label>
        <div className="input_area multiLine w80">
          <ul className="agreement typeSlt">
            <li className="customChk">
              <div className="type_checkList w100">
                {!basic.loading &&
                  basic.category
                    .filter((f) => f.type === 1)
                    .map((item, index) => (
                      <React.Fragment key={`c_category_${index}`}>
                        <input
                          type="checkbox"
                          className={"categoryList"}
                          id={`c_${index}`}
                          value={item.code}
                          onChange={(e) => handleChange(e, "category")}
                        />
                        <label htmlFor={`c_${index}`}>{item.sub}</label>
                      </React.Fragment>
                    ))}
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li className="b c">
        <label
          className={`input_title w20 ${ProStyle.input_title} ${ProStyle.inputType}`}
        >
          대상
        </label>
        <div className="input_area w80 customChk">
          <div className="type_checkList">
            {!basic.loading &&
              basic.target.map((item, index) => (
                <React.Fragment key={`c_target_${index}`}>
                  <input
                    type="checkbox"
                    id={`t_${index}`}
                    value={item.code}
                    onChange={(e) => handleChange(e, "target")}
                  />
                  <label htmlFor={`t_${index}`}>{item.text}</label>
                </React.Fragment>
              ))}
          </div>
        </div>
      </li>

      <li className="b c">
        <label
          className={`input_title w20 ${ProStyle.input_title} ${ProStyle.inputType}`}
        >
          예산 범위
        </label>
        <div className="input_area w80">
          <select
            className="slt_inCharge w30"
            style={{ margin: 0 }}
            onChange={(e) => handleChange(e, "ttr")}
            // onChange={(e) => handleChange(e, "assets")}
          >
            <option value="0">예산 선택 범위</option>
            {!basic.loading &&
              basic.totalReward
                .filter((f) => f.type === 3)
                .map((item, index) => (
                  <option key={`ttr_${index}`} value={item.code}>
                    {item.text}
                  </option>
                ))}
          </select>
        </div>
      </li>
      <li className="b c">
        <label
          className={`input_title w20 ${ProStyle.input_title} ${ProStyle.inputType}`}
        >
          대행 범위
          <br />
          (전체/부분 대행)
        </label>
        <div className="input_area w80 customChk">
          <div className="type_checkList">
            {!basic.loading &&
              basic.ag.map((item, index) => (
                <React.Fragment key={`c_target_${index}`}>
                  <input
                    type="checkbox"
                    id={`ar_${index}`}
                    value={item.code}
                    onChange={(e) => handleChange(e, "agentRange")}
                  />
                  <label htmlFor={`ar_${index}`}>{item.text}</label>
                </React.Fragment>
              ))}
          </div>
        </div>
      </li>
      <li>
        <LabelInputElement label={"요청사항"}>
          <textarea
            className="input_area w80"
            onChange={(e) => handleChange(e, "request")}
          />
        </LabelInputElement>
      </li>
      <li>
        <LabelInputElement label={"첨부파일"}>
          <div
            className={"w100"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              className="input_text"
              style={{ border: "none" }}
              accept=".jpg"
              onChange={(e) => handleChange(e, "attachment", 0)}
            />
            <button
              type={"button"}
              style={{
                background: "#263050",
                border: "none",
                color: "white",
                cursor: "pointer",
                padding: "0.2em 0.5em",
                fontSize: "0.75em",
              }}
              onClick={increaseFile}
            >
              추가
            </button>
          </div>
          {fileCnt > 1 &&
            Array.from(new Array(fileCnt), (el, index) => {
              return (
                index != 0 && (
                  <div
                    key={`f_c_i_${index}`}
                    className={"w100"}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="file"
                      className="input_text w75"
                      style={{ border: "none" }}
                      accept=".jpg"
                      onChange={(e) => handleChange(e, "attachment", index)}
                    />
                    <button
                      type={"button"}
                      style={{
                        background: "red",
                        border: "none",
                        color: "white",
                        cursor: "pointer",
                        padding: "0.2em 0.5em",
                        fontSize: "0.75em",
                      }}
                      onClick={() => decreaseFile(index)}
                    >
                      삭제
                    </button>
                  </div>
                )
              );
            })}

          <span
            className="help-block"
            style={{
              display: "block",
              marginTop: "5px",
              color: "#AAB2BD",
              fontSize: "17px",
            }}
          >
            ※ 대행 견적을 위한 참고자료(기획안, 포스터 이미지 등)를
            첨부해주세요.
          </span>
        </LabelInputElement>
      </li>
    </>
  );
};

export const SupportersForm = ({ origin, onChange }) => {
  const [basic, setBasic] = useRecoilState(commonSelect);
  const [fileCnt, setFileCnt] = useState(1);
  useEffect(() => {
    async function reqCommon() {
      const { data } = await LoadCommonInfo();
      setBasic((prev) => {
        const prevObject = { ...prev };
        prevObject.loading = false;
        prevObject.category = [...data.category];
        prevObject.host = [...data.host];
        prevObject.target = [...data.target];
        prevObject.totalReward = [...data.totalReward];
        prevObject.region = [...data.region];
        prevObject.ag = [...data.ag];
        return { ...prevObject };
      });
    }
    if (basic.ag.length === 0) {
      reqCommon();
    }
  }, []);

  const [type, setType] = useState(1);
  const [submitData, setSubmitData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    scale: 0,
    region: {},
    category: [],
    target: [],
    totalReward: {},
    request: "",
    attachment: [],
    agentRange: [],
    file: {},
  });

  const [curCategory, setCurCategory] = useState([]);
  useEffect(() => {
    setSubmitData({ ...submitData, category: [...curCategory] });
  }, [curCategory]);

  const [curTarget, setCurTarget] = useState([]);
  useEffect(() => {
    setSubmitData({ ...submitData, target: [...curTarget] });
  }, [curTarget]);

  const [curArange, setCurArange] = useState([]);
  useEffect(() => {
    setSubmitData({ ...submitData, agentRange: [...curArange] });
  }, [curArange]);

  const [curFile, setCurFile] = useState([{ file: null }]);
  useEffect(() => {
    setSubmitData({ ...submitData, attachment: [...curFile] });
  }, [curFile]);
  const increaseFile = () => {
    const cnt = fileCnt;
    setCurFile([...curFile, { file: null }]);
    setFileCnt(cnt + 1);
  };
  const decreaseFile = (e) => {
    setCurFile([...curFile.filter((f, i) => i !== e)]);
    const cnt = fileCnt;
    setFileCnt(cnt - 1);
  };

  const handleChange = (e, type, subInfo) => {
    switch (type) {
      case "title":
        setSubmitData({ ...submitData, title: e.target.value });
        break;
      case "startDate":
        setSubmitData({ ...submitData, startDate: e.target.value });
        break;
      case "endDate":
        setSubmitData({ ...submitData, endDate: e.target.value });
        break;
      case "scale":
        setSubmitData({ ...submitData, scale: e.target.value * 1 });
        break;
      case "category":
        const containedC = curCategory.filter((f) => f.code === e.target.value);
        const { code: cCode, sub: cText } = basic.category.filter(
          (f) => f.code === e.target.value
        )[0];
        if (containedC.length !== 0) {
          setCurCategory([
            ...curCategory.filter((f) => f.code !== e.target.value),
          ]);
        } else {
          setCurCategory([...curCategory, { code: cCode, text: cText }]);
        }
        break;
      case "target":
        const containedT = curTarget.filter((f) => f.code === e.target.value);
        const { code: tCode, text: tText } = basic.target.filter(
          (f) => f.code === e.target.value
        )[0];
        if (containedT.length !== 0) {
          setCurTarget([...curTarget.filter((f) => f.code !== e.target.value)]);
        } else {
          setCurTarget([...curTarget, { code: tCode, text: tText }]);
        }
        break;
      case "region":
        const { code: rCode, text: rText } = basic.region.filter(
          (f) => f.code === e.target.value
        )[0];
        setSubmitData({ ...submitData, region: { code: rCode, text: rText } });

        break;
      case "agentRange":
        const containedAR = curArange.filter((f) => f.code === e.target.value);
        const { code: arCode, text: arText } = basic.ag.filter(
          (f) => f.code === e.target.value
        )[0];
        if (containedAR.length !== 0) {
          setCurArange([...curArange.filter((f) => f.code !== e.target.value)]);
        } else {
          setCurArange([...curArange, { code: arCode, text: arText }]);
        }
        break;
      case "ttr":
        const { code: ttCode, text: ttText } = basic.totalReward.filter(
          (f) => f.code === e.target.value
        )[0];
        setSubmitData({
          ...submitData,
          totalReward: { code: ttCode, text: ttText },
        });
        break;
      case "request":
        setSubmitData({ ...submitData, request: e.target.value });
        break;
      case "attachment":
        const tempFile = curFile;
        tempFile[subInfo].file = e.target.files;
        setCurFile([...tempFile]);
        // setSubmitData({ ...submitData, attachment: e.target.files });
        break;
    }
  };
  useEffect(() => {
    onChange({ ...origin, submitData });
  }, [submitData]);

  return (
    <>
      <PromoLiNormalLabel label={"모집명"}>
        <input
          type="text"
          className={"input_text w100"}
          placeholder={"예시 ) 000 0000 0기 서포터즈 모집"}
          onChange={(e) => handleChange(e, "title")}
        />
      </PromoLiNormalLabel>
      <PromoLiNormalLabel label={"운영 규모"}>
        <input
          type="number"
          className={"input_text w30"}
          onChange={(e) => handleChange(e, "scale")}
        />
        <span className="dash">명</span>
      </PromoLiNormalLabel>

      <PromoLiNormalLabel label={"운영 기간"} liClass={"b c"}>
        <input
          type="datetime-local"
          className="input_text w30"
          onChange={(e) => handleChange(e, "startDate")}
        />
        <span className="dash">-</span>
        <input
          type="datetime-local"
          className="input_text w30"
          onChange={(e) => handleChange(e, "endDate")}
        />
      </PromoLiNormalLabel>
      {/* <PromoLiNormalLabel
        label={"구분"}
        bodyClass={`input_area w80 customChk ${ProStyle.input_area} ${ProStyle.agentType}`}
      >
        <input
          type="radio"
          name="type"
          id="cType"
          value="1"
          defaultChecked
          onClick={() => setType(1)}
        />
        <label style={{ width: "auto" }} htmlFor="cType">
          공모전
        </label>
        <input
          type="radio"
          name="type"
          id="aType"
          value="2"
          onClick={() => setType(2)}
        />
        <label style={{ width: "auto" }} htmlFor="aType">
          대외활동
        </label>
      </PromoLiNormalLabel> */}
      <PromoLiNormalLabel
        label={"분야"}
        liClassName={"b c"}
        bodyClass={"input_area multiLine w80"}
      >
        <ul className="agreement typeSlt">
          <li className="customChk">
            <div className="type_checkList w100">
              {!basic.loading &&
                basic.category
                  .filter((f) => f.type === 2)
                  .map((item, index) => (
                    <React.Fragment key={`c_category_${index}`}>
                      <input
                        type="checkbox"
                        className={"categoryList"}
                        id={`c_${index}`}
                        value={item.code}
                        onChange={(e) => handleChange(e, "category")}
                      />
                      <label htmlFor={`c_${index}`}>{item.sub}</label>
                    </React.Fragment>
                  ))}
            </div>
          </li>
        </ul>
      </PromoLiNormalLabel>
      <PromoLiNormalLabel
        label={"대상"}
        liClass={"b c"}
        bodyClass={"input_area w80 customChk"}
      >
        <div className="type_checkList">
          {!basic.loading &&
            basic.target.map((item, index) => (
              <React.Fragment key={`c_target_${index}`}>
                <input
                  type="checkbox"
                  id={`t_${index}`}
                  value={item.code}
                  onChange={(e) => handleChange(e, "target")}
                />
                <label htmlFor={`t_${index}`}>{item.text}</label>
              </React.Fragment>
            ))}
        </div>
      </PromoLiNormalLabel>
      <PromoLiNormalLabel label={"예산 범위"} liClass={"b c"}>
        <select
          className="slt_inCharge w30"
          style={{ margin: 0 }}
          onChange={(e) => handleChange(e, "ttr")}
          // onChange={(e) => handleChange(e, "assets")}
        >
          <option value="0">예산 선택 범위</option>
          {!basic.loading &&
            basic.totalReward
              .filter((f) => f.type == 3)
              .map((item, index) => (
                <option key={`ttr_${index}`} value={item.code}>
                  {item.text}
                </option>
              ))}
        </select>
      </PromoLiNormalLabel>
      <PromoLiNormalLabel label={"지역"} liClass={"c"}>
        <select
          className={"slt_inCharge w30"}
          style={{ margin: 0 }}
          onChange={(e) => handleChange(e, "region")}
        >
          <option value="0">지역 선택</option>
          {!basic.loading &&
            basic.region.map((item, index) => (
              <option key={`regions_${index}`} value={item.code}>
                {item.text}
              </option>
            ))}
        </select>
      </PromoLiNormalLabel>
      <PromoLiNormalLabel
        label={`대행 범위 (전체/부분 대행)`}
        liClass={"b c"}
        bodyClass={"input_area w80 customChk"}
      >
        <div className="type_checkList">
          {!basic.loading &&
            basic.ag.map((item, index) => (
              <React.Fragment key={`c_a_range_${index}`}>
                <input
                  type="checkbox"
                  id={`a_range_${index}`}
                  value={item.code}
                  onChange={(e) => handleChange(e, "agentRange")}
                />
                <label htmlFor={`a_range_${index}`}>{item.text}</label>
              </React.Fragment>
            ))}
        </div>
      </PromoLiNormalLabel>
      <PromoLiNormalLabel label={"요청사항"}>
        <textarea
          className="input_area w100"
          placeholder="추가 정보를 입력해주세요. &#13;&#10;* 참고 URL&#13;&#10;* 기타 서포터즈 관련 정보 등"
          onChange={(e) => handleChange(e, "request")}
        />
      </PromoLiNormalLabel>
      <PromoLiNormalLabel label={"첨부파일"}>
        <div
          className={"w100"}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <input
            type="file"
            className="input_text"
            style={{ border: "none" }}
            accept=".jpg"
            onChange={(e) => handleChange(e, "attachment", 0)}
          />
          <button
            type={"button"}
            style={{
              background: "#263050",
              border: "none",
              color: "white",
              cursor: "pointer",
              padding: "0.2em 0.5em",
              fontSize: "0.75em",
            }}
            onClick={increaseFile}
          >
            추가
          </button>
        </div>
        {fileCnt > 1 &&
          Array.from(new Array(fileCnt), (el, index) => {
            return (
              index != 0 && (
                <div
                  key={`f_c_i_${index}`}
                  className={"w100"}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    className="input_text w75"
                    style={{ border: "none" }}
                    accept=".jpg"
                    onChange={(e) => handleChange(e, "attachment", index)}
                  />
                  <button
                    type={"button"}
                    style={{
                      background: "red",
                      border: "none",
                      color: "white",
                      cursor: "pointer",
                      padding: "0.2em 0.5em",
                      fontSize: "0.75em",
                    }}
                    onClick={() => decreaseFile(index)}
                  >
                    삭제
                  </button>
                </div>
              )
            );
          })}

        <span
          className="help-block"
          style={{
            display: "block",
            marginTop: "5px",
            color: "#AAB2BD",
            fontSize: "17px",
          }}
        >
          ※ 대행 견적을 위한 참고자료(기획안, 포스터 이미지 등)를 첨부해주세요.
        </span>
      </PromoLiNormalLabel>
    </>
  );
};
