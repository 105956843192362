import { createBrowserHistory } from "@remix-run/router";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Btn = styled.button`
  font-size: 0.75em;
  border: none;
  padding: 0.5em 1em;
  background-color: #222530;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  &:first-child {
    margin-right: 1em;
  }
`;

const FindIdResult = () => {
  const navi = useNavigate();

  const goLogin = () => {
    navi("/signIn");
  };
  const goFindPwd = () => {
    navi("/findpwd");
  };

  //   const history = useHistory
  const [email, setEmail] = useState("");
  const [leave, setLeave] = useState(false);
  //   console.log(location);
  const location = useLocation();
  useEffect(() => {
    console.log(location.state);
    if (location.state === null) {
      alert("아이디 찾기 페이지로 이동합니다.");
      navi("/findId");
    } else {
      setEmail(location.state?.id);
      navi(location.pathname, { replace: true });
    }

    return () => {
      setEmail("");
    };
  }, []);

  return (
    <div className={"container sub"}>
      {/* <div className={"subVisual mypage"}></div> */}
      <div className={`sub_container`} style={{ marginTop: "3.5em" }}>
        <div className={`contents mypage`} style={{ height: "100%" }}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>아이디 찾기 결과</span>
          </h2>
          <div style={{ padding: "1em 0" }}>
            <p style={{ fontSize: "0.85em" }}></p>
            <div
              style={{
                padding: "2em",
                border: "1px solid #dbdbdb",
                textAlign: "center",
              }}
            >
              <p>
                아이디 : <span style={{ fontSize: "0.85em" }}>{email}</span>
              </p>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <Btn type={"button"} onClick={goFindPwd}>
              비밀번호 찾기
            </Btn>
            <Btn type={"button"} onClick={goLogin}>
              로그인 페이지
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindIdResult;
