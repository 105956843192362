import React, { Suspense } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Loading } from "../../../components/DContents/Mobile/MTemplate";
import { accountSelect } from "../../../recoil/AtomConfig";
import { ClientReqGet } from "../../../utils/ClientAuth";
import styled from "styled-components";

const ReqWrap = styled.div`
  font-size: 0.75em;
`;
const ReqItems = styled.div`
  padding: 0.25em 0;
  margin: 0.25em 0;
  border-bottom: 1px solid #efefef;
  &:last-child {
    border-bottom: 0;
  }
`;

const ReqLabel = styled.label`
  font-weight: bold;
`;
const ReqText = styled.p``;
const ReqAnswer = styled.p`
  padding: 1em 0.5em;
  font-size: 0.9em;
  border: 1px solid #efefef;
`;
const ReqContestStat = styled.span`
  padding: 0.15em 0.25em;
  font-size: 0.75em;
  color: #ffffff;
  margin-right: 0.5em;
  background-color: ${(props) => (props.active ? `green` : `gray`)};
  border-radius: 5px;
`;
const ReqBtns = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
`;
const ReqBtnItem = styled.button`
  border: none;
  padding: 0.25em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.95em;
  background-color: #2b303b;
  color: #ffffff;
  cursor: pointer;
`;

const EP = process.env.REACT_APP_AWS_ENDPOINT;
const MyFreeData = ({ id, account, toggle }) => {
  const navi = useNavigate();
  const { data, error, isLoading } = useQuery(
    ["myFreeEditView", id, account, toggle],
    async () =>
      await ClientReqGet(
        `/reqCEdit/view/${id}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: true }
  );
  return (
    <ReqWrap>
      <ReqItems>
        <ReqLabel>공모전 정보</ReqLabel>
        <ReqText>
          <ReqContestStat active={data.data.reqId.status == 1}>
            {data.data.reqId.status === 1 ? "승인완료" : "승인대기"}
          </ReqContestStat>
          {data.data.reqId.title}
        </ReqText>
      </ReqItems>
      <ReqItems>
        <ReqLabel>요청내용</ReqLabel>
        <ReqText>{data.data.content}</ReqText>
      </ReqItems>
      <ReqItems>
        <ReqLabel>포스터</ReqLabel>
        <ReqText>
          {data.data.thumbnail ? (
            <a href={EP + "contents/" + data.data.thumbnail.path}>
              {data.data.thumbnail.origin}
            </a>
          ) : (
            "없음"
          )}
        </ReqText>
      </ReqItems>
      <ReqItems>
        <ReqLabel>첨부파일</ReqLabel>
        <ReqText>
          {data.data.others?.length > 0 ? (
            <a href={EP + "contents/" + data.data.others[0].path}>
              {data.data.others[0].origin}
            </a>
          ) : (
            "없음"
          )}
        </ReqText>
      </ReqItems>
      <ReqItems>
        <ReqLabel>답변</ReqLabel>
        {data.data.answerYn ? (
          <ReqAnswer>{data.data.answer}</ReqAnswer>
        ) : (
          <ReqAnswer>답변이 작성되지 않았습니다</ReqAnswer>
        )}
      </ReqItems>
      <ReqBtns>
        <ReqBtnItem type="button" onClick={() => navi(-1)}>
          뒤로가기
        </ReqBtnItem>
      </ReqBtns>
    </ReqWrap>
  );
};

const MyFreeEditReqDetail = () => {
  const { id } = useParams();
  const [account, setAccount] = useRecoilState(accountSelect);
  const changeATK = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
      </div>
      <div className={`sub_container`}>
        <div className={`contents mypage`}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>무료등록 요청 상세</span>
          </h2>

          <div className={"myInfo_area"}>
            <Suspense fallback={<Loading />}>
              <MyFreeData id={id} account={account} toggle={changeATK} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFreeEditReqDetail;
