import React from "react";
import promotionStyle from "../../assets/css/promo.module.scss";
import HostCenterHeaderTabs from "../../components/HostCenter/HeaderTabs";
import PromotionSection1 from "../../components/HostCenter/Promotion/PSection1";
import PromotionSection2 from "../../components/HostCenter/Promotion/PSection2";
import { PromotionSubVisual } from "../../components/HostCenter/SubVisual";

const Promotions = () => {
  return (
    <div className={"container sub"}>
      <HostCenterHeaderTabs current={"promotion"} />
      <PromotionSubVisual />
      <PromotionSection1 />
      <PromotionSection2 />
    </div>
  );
};

export default Promotions;
