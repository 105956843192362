import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ClientReqGet } from "../../../utils/ClientAuth";
import { Loading } from "../../DContents/Mobile/MTemplate";

const UserContents = ({ id, account, toggle }) => {
  const { data, error, isLoading } = useQuery(
    ["scrap", id, account, toggle],
    async () =>
      await ClientReqGet(
        `/user/${id}/scrap/all`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false }
  );
  const navi = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  return data.data.res.scrap && data.data.res.scrap.length > 0 ? (
    data.data.res.scrap.map((item, index) => (
      <li
        key={`mp_scrap_${id}_${index}`}
        onClick={() => navi(`/contest/view/${item.type}/${item._id}`)}
      >
        <p className={"cUserScrapTitle"}>{item.title}</p>
        <p className={"cUserScrapHost"}>{item.host.name}</p>
        <p className={"cUserDeadLine"}>
          {moment(item.startDate).format("YYYY-MM-DD")} ~{" "}
          {moment(item.endDate).format("YYYY-MM-DD")}
        </p>
      </li>
    ))
  ) : (
    <li>
      <p className={"cUserNoContent"}>데이터가 존재하지 않습니다</p>
    </li>
  );
};

export default UserContents;
