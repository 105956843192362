import React, { useRef } from "react";
import { ClientReqPatch } from "../../../utils/ClientAuth";

const ChangeEmailYn = ({ emailYn, account, atkChange, close, refetch }) => {
  const yRef = useRef(null);
  const nRef = useRef(null);
  const handleChange = async () => {
    const result = await ClientReqPatch(
      `/user/emailYn/` + yRef.current.checked,
      {},
      account.accessToken,
      account.refreshToken,
      atkChange
    );
    if (result.flag) {
      refetch();
      close();
    }
  };
  return (
    <>
      <div className={"slideInputArea"}>
        <div className={"slidePwd"}>
          <div className={"slideEmailYn"}>
            <label htmlFor={"emailY"}>수신</label>
            <input
              type="radio"
              id="emailY"
              name="emailYn"
              ref={yRef}
              defaultChecked={emailYn}
            />
          </div>
          <div className={"slideEmailYn"}>
            <label htmlFor={"emailN"}>거부</label>
            <input
              type="radio"
              id="emailN"
              name="emailYn"
              ref={nRef}
              defaultChecked={!emailYn}
            />
          </div>
        </div>
      </div>
      <div className={"slideInputBtns"}>
        <button
          type="button"
          className={"slideInputSubmit"}
          onClick={handleChange}
        >
          <span>변경</span>
        </button>
      </div>
    </>
  );
};

export default ChangeEmailYn;
