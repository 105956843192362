import React, { useEffect, useState } from "react";
import styled from "styled-components";
export const ErrorText = styled.p`
  font-size: 0.75em;
  color: #ff8e8e;
`;

export const LabelElement = ({ label, require, children }) => {
  return (
    <>
      <label className={"input_title w20 " + (require ? "customRequire" : "")}>
        {label}
      </label>
      {children}
    </>
  );
};

export const LabelInputElement = ({
  label,
  error,
  subClassName,
  require,
  children,
}) => {
  return (
    <>
      <label className={"input_title w20 " + (require ? "customRequire" : "")}>
        {label}
      </label>
      <div className={subClassName ? subClassName : "input_area w80"}>
        {children}
        {error && <ErrorText>{error.message}</ErrorText>}
      </div>
    </>
  );
};

export const LabelTextElement = ({
  label,
  error,
  className,
  placeholder,
  formName,
  register,
  require,
  children,
}) => {
  return (
    <>
      <label className={"input_title w20 " + (require ? "customRequire" : "")}>
        {label}
      </label>
      <div className={"input_area w80"}>
        <input
          type="text"
          className={className}
          placeholder={placeholder}
          {...register(formName)}
        />
        {error && <ErrorText>{error.message}</ErrorText>}
      </div>
    </>
  );
};

export const LabelSelectElement = ({
  label,
  error,
  require,
  className,
  loading,
  formName,
  register,
  onChange,
  options,
}) => {
  const defaultChange = () => {};
  return (
    <>
      <label className={"input_title w20 " + (require ? "customRequire" : "")}>
        {label}
      </label>
      <div className={"input_area w80"}>
        {loading ? (
          <p>loading</p>
        ) : (
          <select
            className={className}
            style={{ marginLeft: "0" }}
            {...register(formName, {
              onChange: (e) => (onChange ? onChange(e) : defaultChange),
            })}
          >
            <option value={"unselected"}>항목을 선택해주세요</option>
            {options.map((item, index) => (
              <option key={`${formName}_${index}`} value={item.code}>
                {item.text}
              </option>
            ))}
            {/* {options} */}
          </select>
        )}

        {error && <ErrorText>{error.message}</ErrorText>}
      </div>
    </>
  );
};

export const LabelSelectObjectElement = ({
  label,
  className,
  setValue,
  formName,
  type,
  require,
  loading,
  options,
}) => {
  const [curObject, setCurObject] = useState({
    code: undefined,
    text: undefined,
  });

  useEffect(() => {
    setValue(formName, curObject);
  }, [curObject]);

  const handleClick = (objectItem) => {
    const objectInfo = options.filter(
      (f) => f.code === objectItem.target.value
    )[0];
    const objectData = {
      code: objectInfo.code,
      text: objectInfo.text,
    };
    objectData[`${type}Id`] = objectInfo._id;

    setCurObject({ ...objectData });
  };

  return (
    <>
      <label className={"input_title w20 " + (require ? "customRequire" : "")}>
        {label}
      </label>
      <div className={"input_area w80"}>
        {loading ? (
          <p>loading</p>
        ) : (
          <select
            className={className}
            style={{ marginLeft: "0" }}
            onChange={handleClick}
          >
            <option value="unselected">항목을 선택해주세요</option>
            {options.map((item, index) => (
              <option key={`host_${index}`} value={item.code}>
                {item.text}
              </option>
            ))}
          </select>
        )}
      </div>
    </>
  );
};

export const LabelRegionsElement = ({
  label,
  register,
  loading,
  formName,
  options,
}) => {
  const handleChange = (region) => {
    const selected = options.filter((f) => f.code === region.target.value)[0];
  };
  return (
    <>
      <label className={"input_title w20"}>{label}</label>
      <div className={"input_area w80"}>
        {loading ? (
          <p>loading</p>
        ) : (
          <select
            className={"slt_inCharge w30"}
            style={{ marginLeft: "0" }}
            onChange={(e) => handleChange(e)}
          >
            <option value={"init"}>지역을 선택해주세요</option>
            {options.length > 0 &&
              options.map((item, index) => (
                <option key={`regions_${index}`} value={item.code}>
                  {item.text}
                </option>
              ))}
          </select>
        )}
      </div>
    </>
  );
};

export const LabelCategory = ({
  label,
  categoryType,
  setValue,
  loading,
  require,
  category,
}) => {
  const [curCategory, setCurCategory] = useState([]);

  const handleClick = (categoryItem) => {
    const isChecked =
      curCategory.filter((f) => f.code === categoryItem.code).length > 0;

    if (isChecked) {
      setCurCategory(curCategory.filter((f) => f.code !== categoryItem.code));
    } else {
      const newCategory = {
        categoryId: categoryItem._id,
        code: categoryItem.code,
        text: categoryItem.sub,
      };
      setCurCategory([...curCategory, newCategory]);
    }
  };

  useEffect(() => {
    setValue("info.category", curCategory);
  }, [curCategory]);

  useEffect(() => {
    setCurCategory([]);
  }, [categoryType]);

  return loading ? (
    <p>loading</p>
  ) : (
    <>
      <label className={"input_title w20 " + (require ? "customRequire" : "")}>
        {label}
      </label>
      <div className={"input_area multiLine w80"}>
        <ul className={"agreement typeSlt"}>
          <li>
            <span className={"agree_title"}>
              {categoryType === 0 ? "공모전" : "대외활동"}
            </span>
            <div className={"type_checkList custom"}>
              {category.length > 0 &&
                category
                  .filter((f) => f.type === categoryType + 1)
                  .map((item, index) => (
                    <label
                      key={`category_${index}`}
                      data-val={item.code}
                      onClick={() => handleClick(item)}
                      className={
                        curCategory.filter((f) => f.code === item.code).length >
                        0
                          ? "active"
                          : null
                      }
                    >
                      {item.sub}
                    </label>
                  ))}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export const TargetControl = ({ loading, target, setValue }) => {
  const [curTarget, setCurTarget] = useState([]);

  const handleClick = (targetItem) => {
    const isChecked =
      curTarget.filter((f) => f.code === targetItem.code).length > 0;

    if (isChecked) {
      setCurTarget(curTarget.filter((f) => f.code !== targetItem.code));
    } else {
      const newTarget = {
        targetId: targetItem._id,
        code: targetItem.code,
        text: targetItem.text,
      };
      setCurTarget([...curTarget, newTarget]);
    }
  };

  useEffect(() => {
    setValue("info.target", curTarget);
  }, [curTarget]);

  return (
    <div className={"type_checkList custom"}>
      {loading ? (
        <p>loading</p>
      ) : (
        target?.length > 0 &&
        target.map((item, index) => (
          <label
            key={`target_${index}`}
            data-val={item.code}
            onClick={() => handleClick(item)}
            className={
              curTarget.filter((f) => f.code === item.code).length > 0
                ? "active"
                : null
            }
          >
            {item.text}
          </label>
        ))
      )}
    </div>
  );
};
const DelControl = styled.button`
  padding: 0 1em;
  border: none;
  background: none;
  cursor: pointer;
`;

export const EventOptionsElement = ({ index, ordered, toggle, untoggle }) => {
  const [data, setData] = useState({
    requiredYn: true,
    ordered: ordered,
    title: "",
    inputType: 0,
    content: [],
  });
  // console.log(ordered);
  const [open, setOpen] = useState(false);
  // const handleDecrease = () => {};
  const handleChange = (e, type) => {
    switch (type) {
      case "requiredYn":
        setData({ ...data, requiredYn: e.target.value === true });
        break;
      case "title":
        setData({ ...data, title: e.target.value });
        break;
      case "inputType":
        setData({ ...data, inputType: e.target.value * 1 });
        break;
      // case "content"
    }
  };

  // useEffect(() => {
  //   toggle(ordered, data);
  // }, [data]);

  const handleToggle = () => {
    // console.log(data);
    toggle(ordered, data);
  };

  useEffect(() => {
    setData({
      requiredYn: true,
      ordered: ordered,
      title: "",
      inputType: 0,
      content: [],
    });
  }, [ordered]);

  return (
    <ul style={{ marginTop: "1.5em" }}>
      <li>
        <label className="input_title w20">항목 #{index}</label>
      </li>
      <li>
        <LabelInputElement label="필수여부">
          <select
            className="slt_inCharge w30"
            onChange={(e) => handleChange(e, "requiredYn")}
            defaultValue={true}
          >
            <option value={true}>필수</option>
            <option value={false}>필수 아님</option>
          </select>
        </LabelInputElement>
      </li>
      <li>
        <LabelInputElement label="제목">
          <input
            type="text"
            className="input_text w100 addTitle"
            onChange={(e) => handleChange(e, "title")}
          />
        </LabelInputElement>
      </li>
      <li>
        <LabelInputElement label="입력형태">
          <select
            className="slt_inCharge w30 input_answer_type"
            onChange={(e) => handleChange(e, "inputType")}
            defaultValue={0}
          >
            <option value={0}>입력창</option>
            <option value={1}>라디오 버튼</option>
          </select>
        </LabelInputElement>
      </li>
      {open && (
        <li>
          <label className="input_title w20">내용</label>
          <div
            className={"input_area w80"}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <input
              type="text"
              className={"input_text w80 addTitle"}
              style={{ marginTop: "0.5em" }}
            />
            <DelControl>+</DelControl>
            <DelControl>-</DelControl>
            {/* <button type="button"  */}
          </div>
        </li>
      )}
      <li>
        <div
          className={"input_area w100"}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            type="button"
            style={{
              fontSize: "0.9em",
              border: "none",
              padding: "0.3em 0.5em",
              cursor: "pointer",
            }}
            onClick={handleToggle}
          >
            추가
          </button>
        </div>
      </li>
    </ul>
  );
};
