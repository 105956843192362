import React from "react";
import { PageHeader } from "../../components/Challenge/MobileTemplate/Layout";
import styled from "styled-components";
//img
import IndexImg from "../../assets/img/common/img_freeReg01.png";
import UpgrandeImg from "../../assets/img/common/img_upgrade_service.png";
//slider
import Slider from "react-slick";
import { HPHeader } from "../../components/HostCenter/Mobile/MenuList";

/**
 * created at 2023.04.05 by jiwan
 * HelpDesk ( hostCenter )
 */

const settings = {
  dots: true,
  slideToShow: 1,
  adaptiveHeight: true,
};

const IndexMobile = () => {
  return (
    <HPHeader current={"root"}>
      <ContentWrap>
        <TopSection>
          <Title>공고등록 어떻게 하나요?</Title>
          <TitleSub>
            <b>3단계</b>면 충분한, 쉽고 빠른 <b>등록 자동화 시스템</b>
          </TitleSub>
          <TitleImg src={IndexImg} />
        </TopSection>
        <MiddleSection>
          <MiddleTitle>
            공고 등록시
            <br />
            아래의 유의사항을 확인하세요!
          </MiddleTitle>
          <ul className="hpSlider">
            <Slider {...settings}>
              <MiddleItem>
                <ItemTitle>
                  공모전 및 대외활동 정보만
                  <br /> 게시할 수 있습니다.
                </ItemTitle>
                <ItemSub>
                  동아리, 스터디, 구인 등의 정보는 다른 커뮤니티를 이용해주세요.
                </ItemSub>
              </MiddleItem>
              <MiddleItem>
                <ItemTitle>
                  대외활동의 경우 봉사활동, 기자단, 서포터즈, 홍보대사, 마케터,
                  강연에 해당하는 대외활동만 게시할 수 있습니다.
                </ItemTitle>
              </MiddleItem>
              <MiddleItem>
                <ItemTitle>
                  다음과 같은 공모전·대외활동은 거절될 수 있습니다.
                </ItemTitle>
                <ItemListElement>
                  서비스 홍보를 요구하는 공모전·대외활동 (회원가입, 설치, 작성,
                  공유 등)
                </ItemListElement>
                <ItemListElement>
                  서비스 이용을 요구하는 공모전·대외활동 (콘텐츠 제작, 멘토링,
                  운영관리 등)
                </ItemListElement>
                <ItemListElement>
                  병의원 관련사의 공모전·대외활동
                </ItemListElement>
                <ItemListElement>
                  기관/정치인/정당/종교단체와 직간접적으로 연관되거나, 관련한
                  온·오프라인 활동을 하는 공모전·대외활동
                </ItemListElement>
                <ItemListElement>
                  금융/보험 관련사의 영업/실무와 연관된 공모전·대외활동
                </ItemListElement>
                <ItemListElement>
                  교육 위탁/국비지원/국가지원사업과 연관된 공모전·대외활동
                </ItemListElement>
                <ItemListElement>
                  인력 모집을 위한 구인/구직 목적의 공모전·대외활동
                </ItemListElement>
                <ItemListElement>
                  비용이 발생하는 공모전·대외활동 (참가비, 후원비 등)
                </ItemListElement>
                <ItemListElement>
                  활동 참여의 대가 및 혜택이 불분명한 공모전·대외활동
                </ItemListElement>
                <ItemListElement>
                  총 상금이 100만원 이하이거나, 상금에 준하는 증권이 아닌 현물을
                  제공하는 공모전·대외활동
                </ItemListElement>
                <ItemListElement>
                  특정 대학교만 참여할 수 있는 공모전·대외활동
                </ItemListElement>
              </MiddleItem>
              <MiddleItem>
                <ItemTitle>
                  다음과 같은 게시 요청은 게시되지 않습니다.
                </ItemTitle>
                <ItemListElement>
                  동일한 정보가 이미 게시되어 있을 경우
                </ItemListElement>
                <ItemListElement>
                  운영 주체, 모집 기간, 활동 내용 등 상세 정보가 명확하지 않거나
                  불분명한 경우
                </ItemListElement>
                <ItemListElement>
                  그 외 커뮤니티 이용 규칙에 어긋나거나, 커뮤니티 성격에
                  부적합하다고 판단하는 경우
                </ItemListElement>
              </MiddleItem>
            </Slider>
          </ul>
        </MiddleSection>
        <BottomSection>
          <img src={UpgrandeImg} />

          <BottomTitle>
            다양한 플랫폼을 보유한
            <br /> 국내 최초 공모전 전문 사이트
            <br />
            <b>대티즌의 업그레이드 된 서비스</b>가 궁금하신가요?
          </BottomTitle>
          <BottomItems>
            <BottomLink>
              <BottomLinkTitle>유료 상품도 궁금해요!</BottomLinkTitle>
              <BottomLinkItems>
                <span>배너광고 상품</span>
                <span>SNS광고 상품</span>
              </BottomLinkItems>
            </BottomLink>
            <BottomLink>
              <BottomLinkTitle>공모 대행 서비스도 궁금해요!</BottomLinkTitle>
              <BottomLinkItems>
                <span>공모전 대행 서비스</span>
                <span>서포터즈 / 기자단 대행 서비스</span>
              </BottomLinkItems>
            </BottomLink>
          </BottomItems>
        </BottomSection>
      </ContentWrap>
    </HPHeader>
  );
};

const ContentWrap = styled.div`
  padding: 0.5em 0;
  background-color: #ffffff;
`;
const TopSection = styled.div`
  padding: 0.5em 0;
`;
const MiddleSection = styled.div`
  > .hpSlider {
    padding: 1em 0 3em 0;
    .slick-slider {
      .slick-dots {
        li {
          button:before {
            color: black !important;
          }
        }
      }
    }
  }
`;
const BottomSection = styled.div`
  padding: 30px 0 1em 0;
`;
const Title = styled.p`
  padding: 0 0 0.25em 0;
  text-align: center;
  font-size: 0.75em;
  font-weight: bold;
`;
const TitleSub = styled.span`
  padding: 0.25em 0;
  margin: 0 0 0.5em 0;
  display: block;
  text-align: center;
  font-size: 0.55em;
  background-color: #fdd10c;
`;
const TitleImg = styled.img`
  padding: 1em 0;
`;

const MiddleTitle = styled.p`
  padding: 0.75em 0;
  border-top: 2px solid #fdd10c;
  border-bottom: 2px solid #fdd10c;

  font-size: 0.95em;
  font-weight: bold;
  text-align: center;
`;
const MiddleItem = styled.li``;

const ItemTitle = styled.p`
  padding: 0.5em 0.25em;
  font-size: 0.75em;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;
`;
const ItemSub = styled.span`
  display: block;
  font-size: 0.55em;
  text-align: center;
  color: red;
`;
const ItemListElement = styled.p`
  position: relative;
  padding: 0.5em 1.5em;
  font-size: 0.55em;
  &:before {
    content: "-";
    position: absolute;
    left: 1em;
  }
`;

const BottomTitle = styled.p`
  padding: 0.5em 0;
  font-size: 0.75em;
  font-weight: 500;
  text-align: center;
  > b {
    font-weight: 800;
    color: #fdd10c;
  }
`;
const BottomItems = styled.div`
  display: block;
`;
const BottomLink = styled.div`
  padding: 0.5em 0.25em;
  width: 100%;
`;
const BottomLinkTitle = styled.p`
  font-size: 0.75em;
  font-weight: bold;
  position: relative;
  &:before {
    content: "▶ ";
    font-size: 0.75em;
  }
`;
const BottomLinkItems = styled.div`
  display: block;
  padding: 0 0;
  position: relative;
  > span {
    padding: 0.25em 0em;
    display: block;
    font-size: 0.6em;

    &:before {
      content: "- ";
      width: 10px;
      height: 10px;
    }
  }
`;

export default IndexMobile;
