import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { accountSelect } from "../../recoil/AtomConfig";

const Wrap = styled.div`
  position: fixed;
  z-index: 1111111;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #2b2e3b;
`;

const LogoutMobile = () => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [change, setChange] = useState(false);
  const navi = useNavigate();

  useEffect(() => {
    const logout = () => {
      setAccount((prev) => {
        const temp = { ...prev };
        temp.id = undefined;
        temp.accessToken = undefined;
        temp.role = undefined;
        temp.refreshToken = undefined;
        return { ...temp };
      });
      setChange(true);
    };
    logout();
  }, []);

  useEffect(() => {
    if (change) {
      navi("/");
    }
  }, [change]);
  return (
    <Wrap>
      <Loading />
    </Wrap>
  );
};

export default LogoutMobile;
