import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { compSelect } from "../../recoil/AtomConfig";
import { LoginHeader } from "../../components/Login/LoginHeader";
import custom from "../../assets/css/login.module.scss";
import loadStyle from "../../assets/css/loader.module.scss";
import { HiCheckCircle } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NormalReqApi } from "../../utils/ReqApi";
import Terms from "../../components/Login/Terms";
import { TERMS_DATA } from "../../utils/TermsData";
const phoneRegExp = /^[0-9]{8,}$/;
const passwordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const inputSchema = yup.object({
  name: yup.string().required("이름을 입력해주시기 바랍니다."),
  email: yup
    .string()
    .email("이메일 형식에 맞게 입력해주시기 바랍니다")
    .required("이메일을 입력해주시기 바랍니다."),
  nickName: yup.string().required("닉네임을 입력해주시기 바랍니다."),
  phone: yup
    .string()
    .required("휴대전화 번호를 입력해주시기 바랍니다.")
    .matches(phoneRegExp, "'-'을 제외한 숫자만 입력해주시기 바랍니다."),
  password: yup
    .string()
    .required("비밀번호를 입력해주시기 바랍니다.")
    .matches(
      passwordRegExp,
      "특수문자,영문 대소문자, 숫자를 포함한 비밀번호를 입력해주시기 바랍니다."
    ),
  passwordCheck: yup
    .string()
    .oneOf([yup.ref("password"), null], "비밀번호를 확인해주시기 바랍니다."),
});

const SignupComp = () => {
  const navi = useNavigate();
  const [comp, setComp] = useRecoilState(compSelect);
  const [terms, setTerms] = useState([]);
  const [infoError, setInfoError] = useState({
    email: { flag: false, confirm: false, message: "" },
    nickName: { flag: false, confirm: false, message: "" },
  });
  const [iData, setIData] = useState({
    email: "",
    nickName: "",
    name: "",
    phone: "",
    password: "",
    passwordCheck: "",
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(inputSchema) });

  useEffect(() => {
    setTerms([...TERMS_DATA.filter((f) => f.type === "company")]);
  }, []);

  const onSubmit = async (data) => {
    console.log(data);
    console.log(terms);
    let termsPass = true;
    let emailYn = false;
    terms.map((item, index) => {
      if (item.required && !item.checked) {
        termsPass = false;
      }
      if (item.id === 3) {
        emailYn = item.checked;
      }
    });
    if (!termsPass) {
      alert("필수 약관에 동의해주시기 바랍니다.");
      return false;
    }
    if (!infoError.email.confirm) {
      alert("아이디 중복확인을 진행해주시기 바랍니다.");
      return false;
    }
    if (!infoError.nickName.confirm) {
      alert("닉네임 중복확인을 진행해주시기 바랍니다.");
      return false;
    }

    setComp((prev) => {
      const temp = { ...prev };
      temp.email = data.email;
      temp.nickName = data.nickName;
      temp.name = data.name;
      temp.phone = data.phone;
      temp.password = data.password;
      temp.passwordCheck = data.passwordCheck;
      temp.emailYn = emailYn;
      return { ...temp };
    });
    navi("/signupcomp2");
  };
  const onChangeHandler = (type, e) => {
    switch (type) {
      case "email":
        setIData({
          ...iData,
          email: e.target.value,
        });
        break;
      case "nickName":
        setIData({
          ...iData,
          nickName: e.target.value,
        });
        break;
    }
  };
  const handleIdDupli = async () => {
    const curValue = iData.email;

    if (curValue.length > 2) {
      const response = await NormalReqApi(
        "get",
        "/user/check/dupli?type=id&value=" + curValue
      );
      if (response.statusCode === 200 && !response.data) {
        setInfoError({
          ...infoError,
          email: { flag: false, confirm: true, message: "" },
        });

        // setValue("email", curValue);
      } else {
        setInfoError({
          ...infoError,
          email: { flag: true, confirm: false, message: "이미 사용중입니다." },
        });
        // setValue("email", "");
      }
    }
  };

  const handleNickDupli = async () => {
    const curValue = iData.nickName;
    if (curValue.length > 2) {
      const response = await NormalReqApi(
        "get",
        "/user/check/dupli?type=nick&value=" + curValue
      );
      if (response.statusCode === 200 && !response.data) {
        setInfoError({
          ...infoError,
          nickName: { flag: false, confirm: true, message: "" },
        });

        // setValue("nickNameConfirm", curValue);
      } else {
        setInfoError({
          ...infoError,
          nickName: {
            flag: true,
            confirm: false,
            message: "이미 사용중입니다.",
          },
        });
        // setValue("nickName", "");
      }
    }
  };

  return (
    <LoginHeader>
      <div className={custom.join_box}>
        <div className={custom.tab_area}>
          <ul>
            <li>
              <Link to={`/signup`}>개인회원</Link>
            </li>
            <li className={custom.active}>
              <a>기업회원</a>
            </li>
          </ul>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className={custom.tab_content}>
            <div className={custom.terms_area}>
              <h2>대티즌 회원 약관동의</h2>
              <Terms terms={terms} setTerms={setTerms} />
            </div>
            <div className={custom.join_info + " " + custom.indiviual}>
              <h2 className={"scdTit " + custom.scdTit}>회원 정보 입력</h2>
              <ul>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " " + "w20"}>
                      이메일(아이디)
                    </label>
                    <div className={custom.addonButton}>
                      <input
                        className={custom.inp_text + " " + "w80"}
                        type="text"
                        disabled={infoError.email.confirm}
                        style={{
                          border: "none",
                          background: "none",
                          fontSize: "1em",
                        }}
                        {...register("email", {
                          onChange: (e) => onChangeHandler("email", e),
                        })}
                      />

                      {infoError.email.confirm ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <HiCheckCircle color="green" size={30} />
                        </div>
                      ) : (
                        <button
                          className={custom.searchBtn}
                          type="button"
                          onClick={handleIdDupli}
                        >
                          중복확인
                        </button>
                      )}
                    </div>
                    {infoError.email.flag && (
                      <p className={custom.warning}>
                        {infoError.email.message}
                      </p>
                    )}
                  </div>
                  {errors.email && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.email.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " " + "w20"}>
                      닉네임
                    </label>
                    <div className={custom.addonButton}>
                      <input
                        className={custom.inp_text + " " + "w80"}
                        type="text"
                        disabled={infoError.nickName.confirm}
                        {...register("nickName", {
                          onChange: (e) => onChangeHandler("nickName", e),
                        })}
                      />
                      {/* <ul>
												<li>
													<div
														className={
															custom.input_area + " " + custom.subComp
														}
													>
														<input
															className={custom.inp_text + " " + "w80"}
															type="text"
															disabled={infoError.nickName.confirm}
															{...register("nickName", {
																onChange: (e) => onChangeHandler("nickName", e),
															})}
														/>
													</div>
												</li>
											</ul> */}

                      {infoError.nickName.confirm ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <HiCheckCircle color="green" size={30} />
                        </div>
                      ) : (
                        <button
                          className={custom.searchBtn}
                          type="button"
                          onClick={handleNickDupli}
                        >
                          중복확인
                        </button>
                      )}
                    </div>
                    {infoError.nickName?.message && (
                      <p className={custom.warning}>
                        {infoError.nickName.message}
                      </p>
                    )}
                  </div>
                  {errors.nickName && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.nickName.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " " + "w20"}>
                      담당자 이름
                    </label>
                    <input
                      className={custom.inp_text + " " + "w80"}
                      type="text"
                      {...register("name")}
                    />
                  </div>
                  {errors.name && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.name.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " " + "w20"}>
                      담당자 전화번호
                    </label>
                    <input
                      className={custom.inp_text + " " + "w80"}
                      type="text"
                      autoComplete="off"
                      {...register("phone")}
                    />
                  </div>
                  {errors.phone && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.phone.message}
                    </p>
                  )}
                </li>
                <li>
                  <div className={custom.input_area}>
                    <label className={custom.essential + " " + "w20"}>
                      비밀번호
                    </label>
                    <input
                      className={custom.inp_text + " " + "w80"}
                      type="password"
                      autoComplete="off"
                      {...register("password")}
                    />
                  </div>
                  {errors.password && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.password.message}
                    </p>
                  )}
                  <div className={custom.input_area}>
                    <label className={custom.essential + " " + "w20"}>
                      비밀번호 확인
                    </label>
                    <input
                      className={custom.inp_text + " " + "w80"}
                      type="password"
                      autoComplete="off"
                      {...register("passwordCheck")}
                    />
                  </div>
                  {errors.passwordCheck && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "0.7em",
                        color: "red",
                      }}
                    >
                      {errors.passwordCheck.message}
                    </p>
                  )}
                </li>
              </ul>
            </div>
            <div className={custom.btn_areaLogin + " " + custom.join}>
              <button
                className={custom.btn + " " + custom.join + " " + "btn join"}
              >
                기업 상세 정보 입력
              </button>
            </div>
          </div>
        </form>
      </div>
    </LoginHeader>
  );
};

export default SignupComp;
