import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const FindPwdResult = () => {
  const navi = useNavigate();
  const location = useLocation();
  const moveLogin = () => {
    navi("/signIn");
  };
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (location.state?.number) {
      setNumber(location.state.number);
    } else {
      alert("올바르지 않은 접근입니다.");
      // navi("/");
    }
  }, []);
  return (
    <div className={"container sub"}>
      {/* <div className={"subVisual mypage"}></div> */}
      <div className={`sub_container`} style={{ marginTop: "3.5em" }}>
        <div className={`contents mypage`} style={{ height: "100%" }}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>비밀번호 찾기</span>
          </h2>
          <div style={{ padding: "1em 0" }}>
            <p style={{ marginBottom: "1em", fontSize: "0.85em" }}></p>
            <div
              style={{
                border: "1px solid #dbdbdb",
                padding: "1em",
                width: "100%",
                display: "flex",

                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div>
                <div>
                  <label style={{ marginRight: "1em", fontSize: "0.85em" }}>
                    등록된 번호로 임시 비밀번호가 발송되었습니다. 확인 후 로그인
                    해주시기 바랍니다.
                  </label>
                </div>
                <div
                  style={{
                    marginTop: "1em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      color: "white",
                      backgroundColor: "#263050",
                      border: "none",
                      display: "block",
                      marginBottom: "0.5em",
                      padding: "0.5em",
                      fontSize: "0.75em",
                      boxSize: "border-box",
                      cursor: "pointer",
                    }}
                    onClick={moveLogin}
                  >
                    로그인 페이지로 이동
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPwdResult;
