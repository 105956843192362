import React, { Suspense, useRef, useState } from "react";
import {
  DContentsSubMenu,
  DContentSubContainer,
} from "../../components/DContents/Common/Element";
import Default350 from "../../assets/img/common/img_posterDefault_350.jpg";
import Cestyle from "../../assets/css/event.module.scss";
import { Link } from "react-router-dom";
import EventListPC from "../../components/DContents/Event/EventListPC";
import pStyle from "../../assets/css/paging.module.scss";
import Pagination from "../../utils/Pagination";
import { isMobile } from "react-device-detect";
import EventSList from "../../components/DContents/Mobile/EventSList";
import EventHeader from "../../components/events/header";

const Event = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [ft, setFt] = useState({
    keyType: "",
    keyword: "",
  });

  const keyRef = useRef(null);
  const keywordRef = useRef(null);

  const handleSearch = () => {
    const keyType = keyRef.current.value;
    const keyword = keywordRef.current.value;
    setFt({
      keyType,
      keyword,
    });
  };
  const handleEnterKey = (e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handlePage = (page) => {
    setPage(page * 1);
  };
  // if (isMobile) {
  //   return (
  //     <div className={"container sub"}>
  //       <DContentsSubMenu current={"event"} />
  //       <div className={"sub_container"}>
  //         <Suspense fallback={<div></div>}>
  //           <EventSList />
  //         </Suspense>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className={"container sub"}>
      <EventHeader />
      <DContentSubContainer
        label={"이벤트"}
        subLabel={"대티즌에서 진행중인 다양한 이벤트에 참여하세요!"}
        rightBtn={
          <div>
            <Link
              style={{
                padding: "0.3em 0.5em",
                backgroundColor: "#2b303b",
                color: "white",
                borderRadius: "1em",
              }}
              to={`/event/submitResult`}
            >
              이벤트 당첨 확인하기
            </Link>
          </div>
        }
      >
        <div className="board_search">
          <select name="searchType" ref={keyRef}>
            <option value="0">선택</option>
            <option value="title">제목</option>
            {/* <option value="host">주최사</option>
											<option value="content">내용</option> */}
          </select>
          <input
            type="text"
            name="searchWrd"
            ref={keywordRef}
            onKeyDown={(e) => handleEnterKey(e)}
          />
          <button type="button" onClick={handleSearch}>
            검색
          </button>
        </div>
        <ul className={`thumbList ${Cestyle.thumbList}`}>
          <Suspense fallback={<div></div>}>
            <EventListPC
              pageSet={page}
              filterSet={ft}
              pageChange={handlePage}
              setTotal={setTotal}
            />
          </Suspense>
        </ul>
        <div className={`paging_area ${pStyle.paging_area}`}>
          <div className={`paging_area ${pStyle.paging_area}`}>
            <Pagination total={total} curPage={page} handlePage={handlePage} />
          </div>
        </div>
      </DContentSubContainer>
    </div>
  );
};

export default Event;
