import { Route, Routes } from "react-router-dom";
import MainIndex from "./container/main/Index";
import Header from "./container/1common/Header";
import { RecoilRoot } from "recoil";
import ContestActivityIndex from "./container/contestActivity";
import SignIn from "./container/login/Signin";
import SignUp from "./container/login/Signup";
import HostCetner from "./container/company";
import Promotions from "./container/company/Promotions";
import Agent from "./container/company/Agent";
import FreeContest from "./container/company/FreeContest";
import FreeEvent from "./container/company/FreeEvent";
import PromotionDetail from "./container/company/PromotionDetail";
import AgentReq from "./container/company/AgentReq";
import DcontentsIndex from "./container/dContents";
import Challenge from "./container/challenge";
import EmptyTime from "./container/dContents/EmptyTime";
import Weekly from "./container/dContents/Weekly";
import Depic from "./container/dContents/Depic";
import Event from "./container/dContents/Event";
import DepicView from "./container/dContents/DepicView";
import EmptyTimeView from "./container/dContents/EmptyTimeView";
import Teamplay from "./container/challenge/teamplay";
import After from "./container/challenge/After";
import Qna from "./container/challenge/Qna";
import FreeBoard from "./container/challenge/FreeBoard";
import TeamplayView from "./container/challenge/TeamplayView";
import AfterView from "./container/challenge/AfterView";
import QnaView from "./container/challenge/QnaView";
import FreeBoardView from "./container/challenge/FreeBoardView";
import ChallengeForm from "./container/challenge/ChallengeForm";
import ChallengeEdit from "./container/challenge/ChallengeEdit";
import { QueryClient, QueryClientProvider } from "react-query";
import ContestDetail from "./container/contestActivity/Detail";
import UserIndex from "./container/user";
import SignupNormal from "./container/login/SignupNormal";
import SignupComp from "./container/login/SignupComp";
import SignupComp2 from "./container/login/SignupComp2";
import WeeklyView from "./container/dContents/WeeklyView";
import NoticeView from "./container/notice/view";
import EventView from "./container/dContents/EventView";
import MyEvent from "./container/user/normal/myEvent";
import EventSubmitResult from "./container/dContents/EventSubmitResult";
import EventSubmitResultView from "./container/dContents/EventSubmitResultView";
import ChangeInfo from "./container/user/ChangeInfo";
import FindId from "./container/user/normal/findId";
import FindPwd from "./container/user/normal/findpwd";
import FindPwd2 from "./container/user/normal/findpwd2";
import { isMobile } from "react-device-detect";
import MobileIndex from "./container/main/MobileIndex";
import MobileHeader2 from "./container/1common/MobildHeader";
import FindIdResult from "./container/user/normal/findidresult";
import FindPwdResult from "./container/user/normal/findpwdResult";
import ContestDetailMobile from "./container/contestActivity/DetailMobile";
import DcontentIndexMobile from "./container/dContents/indexMobile";
import ChallengeIndexMobile from "./container/challenge/IndexMobile";
import EmptyMobile from "./container/dContents/EmptyMobile";
import EmptyViewMobile from "./container/dContents/EmptyViewMobile";
import SiteMap from "./container/1common/Sitemap";
import DepicMobile from "./container/dContents/DepicMobile";
import DepicViewMobile from "./container/dContents/DepicViewMobile";
import EventMobile from "./container/dContents/EventMobile";
import EventViewMobile from "./container/dContents/EventViewMobile";
import NotFound from "./container/error/NotFound";
import SignInMobile from "./container/login/SignInMobile";
import SignUpMobile from "./container/login/SignupMobile";
import SignupMobile2 from "./container/login/SignupMobile2";
import RouteChangeTracker from "./components/Common/RouteChangeTracker";
import ContestIndexMobile from "./container/contestActivity/indexMobile";
import FreeBoardMobile from "./container/challenge/FreeboardMobile";
import FbViewMobile from "./container/challenge/FreeBoardViewMobile";
import TeamplayMobile from "./container/challenge/teamplayMobile";
import TeamplayViewMobile from "./container/challenge/teamplayViewMobile";
import AfterMobile from "./container/challenge/AfterMobile";
import AfterViewMobile from "./container/challenge/AfterViewMobile";
import UserIndexMobile from "./container/user/indexMobile";
import LogoutMobile from "./container/user/LogoutMobile";
import MobileReplyPage from "./container/reply";
import MyScrap from "./container/user/Scrap";
import CompanyUserFindId from "./container/user/comp/findId";
import MyContents from "./container/user/comon/myContents";
import MyFreeContestDetail from "./container/user/comon/myFreeContestDetail";
import MyFreeContests from "./container/user/comon/myFreeContest";
import MyFreeEditReqDetail from "./container/user/comon/myFreeEditReqDetail";
import EventSubmitResultM from "./container/dContents/EventSubmitResultM";
import EventSubmitREsultViewM from "./container/dContents/EventSubmitResultViewM";
import IndexMobile from "./container/company/indexMobile";
import PromotionMobile from "./container/company/promotionMobile";
import AgentMobile from "./container/company/AgentMobile";
import FreeReqMobile from "./container/company/FreeReqMobile";
import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import ContestFake from "./container/contestActivity/indexFake";
import LoginTest from "./container/login/LoginTest";
import { BeforeLeaveOut } from "./container/1common/RouterPage";
import BlockChainIndex from "./container/blockchain";
import BlockChainMobildIndex from "./container/blockchain/indexMobile";
import PointHistoryPage from "./container/user/point";

const queryClient = new QueryClient();
queryClient.invalidateQueries({
  queryKey: [
    "userInfo",
    "userSubmitEventCnt",
    "myeventTop3",
    "scrap3",
    "userFreeContest",
    "myreqEdit",
  ],
});
function App() {
  RouteChangeTracker();

  useEffect(() => {
    console.log("test22");
    return () => {
      console.log("test");
    };
  }, []);
  // useEffect(() => {
  //   console.log("test");
  // }, []);
  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <Routes>
            <Route element={<MobileHeader2 />}>
              <Route path="/m" element={<MobileIndex />} />
              <Route path="/mlogin" element={<SignInMobile />} />
              <Route path="/msignup" element={<SignUpMobile />} />
              <Route path="/msignup/:type" element={<SignupMobile2 />} />
              <Route path="/mDetail/:id" element={<ContestDetailMobile />} />

              <Route path="/mdcontent/event" element={<EventMobile />} />
              <Route
                path="/mdcontent/event/:id"
                element={<EventViewMobile />}
              />
              <Route path="/muser" element={<UserIndexMobile />} />
            </Route>
            <Route element={isMobile ? <MobileHeader2 /> : <Header />}>
              <Route path="/logout" element={<LogoutMobile />} />
              <Route
                path="/"
                element={isMobile ? <MobileIndex /> : <MainIndex />}
              />
              <Route path="/sitemap" element={<SiteMap />} />
              <Route path="/reply/:id" element={<MobileReplyPage />} />

              {/* contest & activity */}
              <Route
                path="/contest/:type"
                element={isMobile ? <ContestIndexMobile /> : <ContestFake />}
              />
              {/* fake contest */}
              <Route path="/contest/test/:type" element={<ContestFake />} />
              <Route
                path="/contest/view/:type/:id"
                element={isMobile ? <ContestDetailMobile /> : <ContestDetail />}
              />

              {/* login */}
              <Route
                path="/signin"
                element={isMobile ? <SignInMobile /> : <LoginTest />}
              />
              <Route path="/loginTest" element={<LoginTest />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signupnormal" element={<SignupNormal />} />
              <Route path="/signupcomp" element={<SignupComp />} />
              <Route path="/signupcomp2" element={<SignupComp2 />} />
              <Route path="/findId" element={<FindId />} />
              <Route path="/findpwd" element={<FindPwd />} />
              <Route path="/findidresult" element={<FindIdResult />} />
              <Route path="/findpwdresult" element={<FindPwdResult />} />
              <Route path="/changepwd" element={<FindPwd2 />} />
              <Route path="/findIdComp" element={<CompanyUserFindId />} />

              {/* detizen contents */}
              <Route
                exact
                path="/dcontents"
                element={
                  isMobile ? <DcontentIndexMobile /> : <DcontentsIndex />
                }
              />
              <Route
                exact
                path="/emptytime"
                element={isMobile ? <EmptyMobile /> : <EmptyTime />}
              />
              <Route
                path="/emptytime/:id"
                element={isMobile ? <EmptyViewMobile /> : <EmptyTimeView />}
              />
              <Route exact path="/weekly" element={<Weekly />} />
              <Route path="/weekly/:id" element={<WeeklyView />} />
              <Route
                exact
                path="/depic"
                element={isMobile ? <DepicMobile /> : <Depic />}
              />
              <Route
                path="/depic/:id"
                element={isMobile ? <DepicViewMobile /> : <DepicView />}
              />
              <Route
                exact
                path="/event"
                element={isMobile ? <EventMobile /> : <Event />}
              />
              <Route
                exact
                path="/event/submitResult"
                element={<EventSubmitResult />}
              />
              <Route
                path="/event/submitresult/view/:id"
                element={<EventSubmitResultView />}
              />
              <Route
                path="/event/:id"
                element={isMobile ? <EventViewMobile /> : <EventView />}
              />
              <Route path="/eventresult" element={<EventSubmitResultM />} />
              <Route
                path="/eventresult/:id"
                element={<EventSubmitREsultViewM />}
              />

              {/* contest challenge */}
              <Route
                path="/challenge"
                element={isMobile ? <ChallengeIndexMobile /> : <Challenge />}
              />
              <Route
                exact
                path="/teamplay"
                element={isMobile ? <TeamplayMobile /> : <Teamplay />}
              />
              <Route
                path="/teamplay/:id"
                element={isMobile ? <TeamplayViewMobile /> : <TeamplayView />}
              />
              <Route
                exact
                path="/after"
                element={isMobile ? <AfterMobile /> : <After />}
              />
              <Route
                path="/after/:id"
                element={isMobile ? <AfterViewMobile /> : <AfterView />}
              />
              <Route exact path="/qna" element={<Qna />} />
              <Route path="/qna/:id" element={<QnaView />} />
              <Route
                exact
                path="/freeboard"
                element={isMobile ? <FreeBoardMobile /> : <FreeBoard />}
              />
              <Route
                exact
                path="/freeboard/:id"
                element={isMobile ? <FbViewMobile /> : <FreeBoardView />}
              />
              <Route path="/challengeform/:type" element={<ChallengeForm />} />
              <Route
                path="/challengeedit/:type/:id"
                element={<ChallengeEdit />}
              />

              <Route
                path="/challenge/:type/notice/:id"
                element={<NoticeView />}
              />

              {/* user */}
              <Route
                path="/user/:id"
                element={isMobile ? <UserIndexMobile /> : <UserIndex />}
              />
              <Route path="/user/changeinfo/:id" element={<ChangeInfo />} />
              <Route path="/user/myevent/:id" element={<MyEvent />} />
              <Route path="/user/myscrap" element={<MyScrap />} />
              <Route path="/user/mycontents" element={<MyContents />} />
              <Route path="/user/myFreeContest" element={<MyFreeContests />} />
              <Route
                path="/user/myfreecontestview/:id"
                element={<MyFreeContestDetail />}
              />
              <Route
                path="/user/myfreeeditview/:id"
                element={<MyFreeEditReqDetail />}
              />

              {/* company */}
              <Route
                path="/hostcenter"
                element={isMobile ? <IndexMobile /> : <HostCetner />}
              />

              <Route
                path="/freeContest"
                element={isMobile ? <FreeReqMobile /> : <FreeContest />}
              />

              <Route path="/freeEvent" element={<FreeEvent />} />
              <Route
                exact
                path="/promotion"
                element={isMobile ? <PromotionMobile /> : <Promotions />}
              />
              <Route path="/promotion/:id" element={<PromotionDetail />} />
              <Route
                path="/agent"
                element={isMobile ? <AgentMobile /> : <Agent />}
              />
              <Route path="/agentReq" element={<AgentReq />} />
              <Route path="/prepare" element={<BeforeLeaveOut />} />
              {/* -- block chain contest */}
              <Route
                path="/blockchain"
                element={
                  isMobile ? <BlockChainMobildIndex /> : <BlockChainIndex />
                }
              />
              {/* etc */}
              {/* -- point */}
              <Route
                path="/user/point/history"
                element={<PointHistoryPage />}
              />
              <Route path="/*" element={<NotFound />} />
            </Route>
          </Routes>
        </RecoilRoot>
      </QueryClientProvider>
    </CookiesProvider>
  );
}

export default App;
