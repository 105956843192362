import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrap = styled.div`
  height: 100%;

  .customMenu {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    margin-bottom: 3em;
    a {
      cursor: pointer;
      color: white;
    }
    .customMenuMain {
      .customMenuMainLabel {
        font-weight: bold;
        font-size: 1.25em;
        position: relative;

        &:after {
          position: absolute;
          content: "";
          bottom: 6px;
          right: -0.5em;
          width: 6px;
          height: 6px;
          border-radius: 50%;

          background-color: #e9360c;
        }
      }

      .customMenuSub {
        padding: 0.5em 0;
        font-size: 0.65em;
        .customMenuSubLabel {
          color: gray;
          &:hover {
            color: white;
            transition: all 100ms ease-in;
          }
        }
      }
    }
  }
`;

const MenuList = [
  {
    id: 1,
    label: "공모전",
    link: "/contest/contest",
    children: [
      "기획/아이디어",
      "IT/SW/공학",
      "디자인",
      "게임/웹툰/캐릭터",
      "광고/마케팅",
      "문학/수기/시나리오",
      "금융/경제/경영",
      "연구/학술/논문/정책",
      "창업/스타트업",
      "체험기/사용기",
      "브랜드/네이밍",
      "사진",
      "영상(UCC)",
      "기타",
    ],
  },
  {
    id: 2,
    label: "대외활동",
    link: "/contest/acitivity",
    children: [
      "강연/교육/멘토링",
      "서포터즈/마케터",
      "동아리/소모임/단체",
      "해외탐방/봉사",
      "행사/전시페스티벌",
      "국내봉사",
      "기자단",
      "체험단/모니터링",
      "취업/인턴",
      "기타",
    ],
  },
  {
    id: 3,
    label: "대티즌 콘텐츠",
    link: "/dcontents",
    children: [
      { text: "공강시간", link: "/emptytime" },
      { text: "위클리", link: "/weekly" },
      { text: "대픽영상", link: "/depic" },
      // { text: "이벤트", link: "/event" },
    ],
  },
  {
    id: 4,
    label: "이벤트",
    link: "/event",
    children: [],
  },
  {
    id: 5,
    label: "고객센터",
    link: "/hostcenter",
    children: [
      { text: "무료등록", link: "/freeContest" },
      { text: "광고 서비스", link: "/promotion" },
      { text: "대행 서비스", link: "/agent" },
    ],
  },
];

const SiteMap = () => {
  return (
    <Wrap>
      <div className={`subVisual channel`}>
        <ul className={`sub_menu `}>
          <li className="active">
            <Link to={`/sitemap`}>
              <span>사이트맵</span>
            </Link>
          </li>
        </ul>
      </div>

      <ul className={"customMenu"}>
        {MenuList.map((item, index) => (
          <li key={`menu_list_${index}`} className={"customMenuMain"}>
            <Link className={"customMenuMainLabel"} to={item.link}>
              <span>{item.label}</span>
            </Link>
            <ul>
              {item.children.map((mItem, mIndex) => (
                <li
                  key={`menu_sub_list_${index}_${mIndex}`}
                  className={"customMenuSub"}
                >
                  {item.id === 1 || item.id === 2 ? (
                    <Link className={"customMenuSubLabel"} to={item.link}>
                      <span>{mItem}</span>
                    </Link>
                  ) : (
                    <Link className={"customMenuSubLabel"} to={mItem.link}>
                      <span>{mItem.text}</span>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </Wrap>
  );
};

export default SiteMap;
