import React, { lazy, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import cStyle from "../../assets/css/contest.module.scss";
import { accountSelect, commonSelect } from "../../recoil/AtomConfig";
import {
  ClientDelReq,
  ClientOptionGet,
  ClientReqPost,
} from "../../utils/ClientAuth";

const ContestDetailTop = lazy(() => import("./ContestDetailTop"));
const ContestDetailBottom = lazy(() => import("./ContestDetailBottom"));
const ContestDetailEditor = lazy(() => import("./ContestDetailEditor"));

const ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT + "w_350/";

const ContestInfo = ({ id, goBackPage, qnaToggle, isOnChain = false }) => {
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const [isScrap, setIsScrap] = useState(false);
  const [common, setCommon] = useRecoilState(commonSelect);

  const toggle = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };

  const { data, error, isLoading } = useQuery(
    ["contestView", id, account, toggle],
    async () =>
      await ClientOptionGet(
        `/contest/view/${id}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: false,
    }
  );

  // console.log(data);

  useEffect(() => {
    setIsScrap(data.isScrap);
  }, [data.isScrap]);

  useEffect(() => {
    if (!isLoading) {
      if (!data.result) {
        alert("해당 공모전이 삭제되었거나 존재하지 않습니다.");
        navi(-1);
      }
    }
  }, [isLoading]);

  if (!data) {
    return null;
  }
  if (!data.result) {
    return null;
  }

  const isSelected = (type, code) => {
    switch (type) {
      case "category":
        const curCategory = data.result.category;
        if (curCategory.filter((f) => f.code === code).length > 0) {
          return true;
        } else {
          return false;
        }

      case "target":
        const curTarget = data.result.target;
        if (curTarget.filter((f) => f.code === code).length > 0) {
          return true;
        }
        return false;

      case "host":
        const curHost = data.result.host;
        if (curHost.code === code) {
          return true;
        }
        return false;

      case "totalReward":
        const curTTr = data.result.totalReward;
        if (curTTr.code === code) {
          return true;
        }
        return false;
    }
    return false;
  };

  const handleScrap = async () => {
    if (account.accessToken && account.id) {
      if (isScrap) {
        const res = await ClientDelReq(
          `/contest/scrap/${id}`,
          account.accessToken,
          account.refreshToken,
          toggle
        );

        if (res.statusCode === 200) {
          setIsScrap(false);
        }
      } else {
        const res = await ClientReqPost(
          "/contest/scrap",
          { id },
          account.accessToken,
          account.refreshToken,
          toggle
        );
        if (res.statusCode === 200) {
          setIsScrap(true);
        }
      }
    } else {
      console.log("login required");
    }
  };

  return (
    <>
      <div
        className={`sub_container ${cStyle.sub_container} `}
        style={{
          backgroundColor: "#2b303b",
        }}
      >
        <div
          className={`custom_search ${cStyle.custom_search} ${cStyle.hide}`}
          style={{
            color: "white",
            backgroundColor: "unset",
            maxWidth: "1200px",
            width: "100%",
            margin: "0 auto",
          }}
        >
          <div
            className={`cs_wrap ${cStyle.cs_wrap}`}
            style={{ margin: 0, maxWidth: "100%" }}
          >
            <div className={cStyle.customFilter} style={{ border: "unset" }}>
              <ul className={cStyle.filterCg}>
                <li className={`${cStyle.cgGroup}`}>공모분야</li>
                <li>
                  <ul className={cStyle.indicateCategory}>
                    {!common.loading &&
                      common.category
                        .filter((f) =>
                          f.type === isOnChain ? 1 : data.result.type * 1 + 1
                        )
                        .map((item, index) => (
                          <li
                            key={`f_cg_${index}`}
                            className={`${cStyle.filterItem} ${
                              isSelected("category", item.code)
                                ? cStyle.checked
                                : ""
                            }`}
                          >
                            {item.sub}
                          </li>
                        ))}
                  </ul>
                </li>
              </ul>
              <ul className={cStyle.filterCg}>
                <li className={`${cStyle.cgGroup}`}>응모대상</li>
                <li>
                  <ul className={cStyle.indicateCategory}>
                    {!common.loading &&
                      common.target.map((item, index) => (
                        <li
                          key={`f_ta_${index}`}
                          className={`${cStyle.filterItem} ${
                            isSelected("target", item.code)
                              ? cStyle.checked
                              : ""
                          }`}
                        >
                          {item.text}
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
              <ul className={cStyle.filterCg}>
                <li className={`${cStyle.cgGroup}`}>주최사</li>
                <li>
                  <ul className={cStyle.indicateCategory}>
                    {!common.loading &&
                      common.host.map((item, index) => (
                        <li
                          key={`h_ta_${index}`}
                          className={`${cStyle.filterItem} ${
                            isSelected("host", item.code) ? cStyle.checked : ""
                          }`}
                        >
                          {item.text}
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
              <ul className={cStyle.filterCg}>
                <li className={`${cStyle.cgGroup}`}>시상내역</li>
                <li>
                  <ul className={cStyle.indicateCategory}>
                    {!common.loading &&
                      common.totalReward.map((item, index) => (
                        <li
                          key={`f_tl_${index}`}
                          className={`${cStyle.filterItem} ${
                            isSelected("totalReward", item.code)
                              ? cStyle.checked
                              : ""
                          }`}
                        >
                          {item.text}
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={`contents contest_area ${cStyle.contents}`}>
        <div className={`contest_view ${cStyle.contest_view}`}>
          <div
            className={`contest_detail top ${cStyle.contest_detail} ${cStyle.top}`}
          >
            {/* img area */}
            {isMobile ? (
              <div className={cStyle.contest_thumb}>
                <img
                  src={ENDPOINT + data.result.thumbnail.path}
                  className={"contest_detail"}
                  style={{ marginRight: "unset" }}
                />
              </div>
            ) : (
              <div className={cStyle.contest_thumb}>
                <img
                  src={ENDPOINT + data.result.thumbnail.path}
                  className={"contest_detail"}
                />
              </div>
            )}

            {/* basic info div */}
            <div className={`contestTop_text ${cStyle.contestTop_text}`}>
              <ContestDetailTop
                {...data.result}
                qnaToggle={qnaToggle}
                toggleScrap={handleScrap}
                scrap={isScrap}
                isOnChain={isOnChain}
              />

              <ContestDetailBottom {...data.result} />
              <div
                role="attachment"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  padding: "0.5em 0",
                }}
              >
                <label
                  style={{
                    fontSize: "0.75em",
                    color: "#4285f4",
                    marginRight: "2em",
                  }}
                >
                  첨부파일
                </label>
                <ul>
                  {data.result.attachment?.length > 0 ? (
                    data.result.attachment.map((item, index) => (
                      <li
                        key={`contest_afile_${index}`}
                        style={{ fontSize: "0.75em" }}
                      >
                        <a
                          href={
                            process.env.REACT_APP_AWS_ENDPOINT +
                            "contents/" +
                            item.path
                          }
                          target={"_blank"}
                        >
                          <span>{item.origin}</span>
                        </a>
                      </li>
                    ))
                  ) : (
                    <li style={{ fontSize: "0.75em" }}>없음</li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <ContestDetailEditor content={data.result.content} />
        </div>
      </div>
    </>
  );
};

export default ContestInfo;
