const managerValid = [
  { target: "cName", type: "string", msg: "업체명을 입력해주시기 바랍니다." },
  {
    target: "name",
    type: "string",
    msg: "담당자 성함을 입력해주시기 바랍니다.",
  },
  {
    target: "phone",
    type: "string",
    msg: "담당자 연락처를 입력해주시기 바랍니다.",
  },
  {
    target: "email",
    type: "string",
    msg: "담당자 이메일을 입력해주시기 바랍니다.",
  },
];

const agentValid = [
  {
    target: "productId",
    type: "string",
    msg: "광고 상품을 선택해주시기 바랍니다.",
  },
  {
    target: "title",
    type: "string",
    msg: "공모전 명을 입력해주시기 바랍니다.",
  },
  {
    target: "des",
    type: "string",
    msg: "요청사항을 간략하게 입력해주시기 바랍니다.",
  },
  {
    target: "price",
    type: "number",
    msg: "상품 가격을 선택해주시기 바랍니다.",
  },
  {
    target: "period",
    type: "number",
    msg: "광고 기간을 선택해주시기 바랍니다.",
  },
  {
    target: "attachment",
    type: "fileArray",
    msg: "참고자료를 선택해주시기 바랍니다.",
  },
];

const contestValid = [
  { target: "title", type: "string", msg: "공모명을 입력해주시기 바랍니다." },
  {
    target: "startDate",
    type: "string",
    msg: "공모 시작일을 선택해주시기 바랍니다.",
  },
  {
    target: "endDate",
    type: "string",
    msg: "공모 종료일을 선택해주시기 바랍니다.",
  },
  {
    target: "category",
    type: "array",
    msg: "공모 분야를 1개 이상 선택해주시기 바랍니다.",
  },
  {
    target: "target",
    type: "array",
    msg: "대상을 1건 이상 선택해주시기 바랍니다.",
  },
  {
    target: "totalReward",
    type: "object",
    msg: "예산범위를 선택해주시기 바랍니다.",
  },
  {
    target: "request",
    type: "string",
    msg: "요청사항을 간략히 기재해주시기 바랍니다.",
  },
  {
    target: "attachment",
    type: "fileArray",
    msg: "참고자료를 선택해주시기 바랍니다.",
  },
];

const suppoertsValid = [
  { target: "title", type: "string", msg: "공모명을 입력해주시기 바랍니다." },
  {
    target: "scale",
    type: "number",
    msg: "운영 규묘를 입력해주시기 바랍니다.",
  },
  {
    target: "startDate",
    type: "string",
    msg: "공모 시작일을 선택해주시기 바랍니다.",
  },
  {
    target: "endDate",
    type: "string",
    msg: "공모 종료일을 선택해주시기 바랍니다.",
  },
  {
    target: "category",
    type: "array",
    msg: "공모 분야를 1개 이상 선택해주시기 바랍니다.",
  },
  {
    target: "target",
    type: "array",
    msg: "대상을 1건 이상 선택해주시기 바랍니다.",
  },
  {
    target: "agentRange",
    type: "array",
    msg: "대행범위를 1건 이상 선택해주시기 바랍니다.",
  },
  {
    target: "totalReward",
    type: "object",
    msg: "예산범위를 선택해주시기 바랍니다.",
  },
  {
    target: "region",
    type: "object",
    msg: "지역을 선택해주시기 바랍니다.",
  },
  {
    target: "request",
    type: "string",
    msg: "요청사항을 간략히 기재해주시기 바랍니다.",
  },
  {
    target: "attachment",
    type: "fileArray",
    msg: "참고자료를 선택해주시기 바랍니다.",
  },
];

const validType = [
  { reqType: "a", vType: agentValid, mTtype: managerValid },
  { reqType: "b", vType: contestValid, mTtype: managerValid },
  { reqType: "c", vType: suppoertsValid, mTtype: managerValid },
];

const validForm = (type, value) => {
  switch (type) {
    case "string":
      return value !== "" && value !== "init";

    case "number":
      return value !== 0;

    case "object":
      return Object.values(value).length > 0;

    case "array":
      return value.length > 0;

    case "file":
      if (value) {
        return value.length > 0;
      } else {
        return false;
      }
    case "fileArray":
      console.log("fileArray!");
      let flag = true;
      value.map((item, index) => {
        if (item.file === null || item.file?.length == 0) {
          flag = false;
        }
      });
      return flag;
  }
};

export const reqFormValid = ({ reqType, submitData, manager }) => {
  const curValid = validType.filter((f) => f.reqType === reqType);
  if (curValid.length === 0) {
    return { flag: false, msg: "올바르지 않은 접근입니다." };
  }
  const { vType, mTtype } = curValid[0];

  let isManagerPass = true;
  let isReqPass = true;

  if (!manager) {
    alert("담당자 정보를 입력해주시기 바랍니다.");
    return false;
  }

  if (!submitData) {
    alert("문의사항을 기재해주시기 바랍니다.");
    return false;
  }

  for (const element of mTtype) {
    const isValid =
      Object.prototype.hasOwnProperty.call(manager, element.target) &&
      validForm(element.type, manager[element.target]);
    if (!isValid) {
      alert(element.msg);
      isManagerPass = false;
      break;
    }
  }
  if (isManagerPass) {
    for (const element of vType) {
      const isValid =
        Object.prototype.hasOwnProperty.call(submitData, element.target) &&
        validForm(element.type, submitData[element.target]);
      if (!isValid) {
        alert(element.msg);
        isReqPass = false;
        break;
      }
    }

    if (isReqPass) {
      console.log("success");
      return true;
    } else {
      console.log("fail");
      return false;
    }
  }
};
