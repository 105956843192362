import React, { useEffect, useRef, useState } from "react";
import replyStyle from "../../assets/css/reply.module.scss";
import {
  HiChatAlt2,
  HiChevronDown,
  HiChevronUp,
  HiOutlineDotsVertical,
} from "react-icons/hi";
import moment from "moment";
import { ClientReqPatch, ClientReqPost } from "../../utils/ClientAuth";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import AnimateHeight from "react-animate-height";
import { ReplySubBtn, ReplyTopBtn } from "./ReplyBtn";

const isOwn = (accountId, userId) => {
  const template = {
    isLogin: false,
    isOwn: false,
  };

  if (accountId) {
    template.isLogin = true;
  }

  if (accountId === userId) {
    template.isOwn = true;
  }
  return { ...template };
};

export const Reply = ({
  nickName,
  refetch,
  author,
  recommend,
  _id,
  unRecommend,
  type,
  text,
  status,
  createdAt,
  subReply,
}) => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [own, setOwn] = useState({
    isLogin: false,
    isOwn: false,
  });
  useEffect(() => {
    setOwn(isOwn(account.id, author._id));
  }, [account]);
  const atkChange = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const subReplyRef = useRef(null);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handleEditToggle = (toggle) => {
    setEdit(toggle);
    setToggle(toggle);
  };

  const handleSubReply = async () => {
    const text = subReplyRef.current.value;
    if (!text) {
      alert("내용을 입력해주시기 바랍니다.");
      return false;
    }
    if (text.length < 5) {
      alert("최소 6글자 이상 입력해주시기 바랍니다.");
      return false;
    }

    const template = {
      text,
    };
    const result = await ClientReqPost(
      `/reply/sub?id=${_id}&type=${type}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      atkChange
    );
    if (result.flag) {
      refetch();
      setToggle(false);
    }
  };

  const handleEditSubReply = async () => {
    const text = subReplyRef.current.value;

    if (!text || text.length < 5) {
      alert("최소 5글자 이상 입력해주시기 바랍니다.");
      return false;
    }

    const template = {
      text,
    };
    const result = await ClientReqPatch(
      `/reply?id=${_id}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      atkChange
    );
    if (result.flag) {
      handleEditToggle(false);
      refetch();
      // subReplyRef.current.reset();
    }
  };

  return (
    <li className={replyStyle.item}>
      <ReplyTopBtn
        id={_id}
        own={own}
        isRoot={true}
        nickName={nickName}
        rCnt={recommend}
        urCnt={unRecommend}
        atkChange={atkChange}
        refetch={refetch}
        toggle={handleEditToggle}
      />

      <div className={replyStyle.textArea}>
        <p>{status === 0 ? text : "삭제된 댓글입니다"}</p>
        <p className={replyStyle.date}>
          {moment(createdAt).format("YYYY-MM-DD HH:mm")}
        </p>
      </div>
      <div className={replyStyle.subReply}>
        <div className={replyStyle.subStatus}>
          {toggle ? (
            <div className={replyStyle.subInputWrap}>
              <div className={replyStyle.writeArea}>
                <textarea
                  className={replyStyle.subInput}
                  ref={subReplyRef}
                ></textarea>
                <button
                  type={"button"}
                  className={replyStyle.subInputBtn}
                  onClick={edit ? handleEditSubReply : handleSubReply}
                >
                  {edit ? "수정" : "등록"}
                </button>
              </div>
              <div className={replyStyle.btnArea}>
                {edit ? (
                  <span onClick={() => handleEditToggle(false)}>취소</span>
                ) : (
                  <span onClick={handleToggle}>취소</span>
                )}
              </div>
            </div>
          ) : (
            <>
              {type !== 1 && type !== 4 && (
                <>
                  <div className={replyStyle.subCount}>
                    <label>
                      답글 <span>{subReply.length}</span>
                    </label>
                    {open ? (
                      <HiChevronDown onClick={() => setOpen(false)} />
                    ) : (
                      <HiChevronUp onClick={() => setOpen(true)} />
                    )}
                  </div>

                  {own.isLogin && (
                    <span
                      className={replyStyle.addSubReply}
                      onClick={handleToggle}
                    >
                      답글 쓰기
                    </span>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <AnimateHeight duration={500} height={open ? "auto" : 0}>
          {subReply.length > 0 &&
            subReply
              .sort((a, b) => (b.id > a.id ? 1 : -1))
              .map((item, index) => (
                <ChildItem
                  key={`child_reply_${index}`}
                  refetch={refetch}
                  _id={_id}
                  own={own}
                  toggle={atkChange}
                  account={account}
                  rootId={_id}
                  {...item}
                />
              ))}
        </AnimateHeight>
      </div>
    </li>
  );
};

const ChildItem = ({
  text,
  refetch,
  writer,
  account,
  _id,
  toggle,
  createdAt,
  rootId,
  id,
  recommend,
  unRecommend,
  own,
  updatedAt,
  isTargetRoot,
  target,
  status,
}) => {
  const [wMode, setWMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const handleWMode = () => {
    setWMode(!wMode);
  };

  const subTextRef = useRef(null);
  const submitSubReply = async () => {
    const subText = subTextRef.current.value;
    if (!subText || subText.length < 5) {
      alert("최소 6자 이상 입력해주시기 바랍니다.");
      return false;
    }

    const template = {
      text: subText,
      targetUserId: writer._id,
      curId: id,
    };
    const url = `/reply/subRe?id=${rootId}`;

    const result = await ClientReqPost(
      `/reply/subRe?id=${_id}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      setWMode(false);
      refetch();
    }
  };

  const handleEditMode = () => {
    setEditMode(true);
    setWMode(true);
  };

  const handleCancelEditMode = () => {
    setEditMode(false);
    setWMode(false);
  };

  const handleEditSubmit = async () => {
    // setEditMode(true);
    const text = subTextRef.current.value;
    if (!text || text.length < 5) {
      alert("최소 6자 이상 입력해주시기 바랍니다");
    }

    const template = {
      text,
    };

    const result = await ClientReqPatch(
      `/reply/sub/text?id=${rootId}&curId=${id}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      handleCancelEditMode();
      refetch();
    }
  };

  return (
    <div className={replyStyle.subItem}>
      <ReplySubBtn
        wId={writer._id}
        nickName={writer.nickName}
        id={rootId}
        own={own}
        curId={id}
        isRoot={false}
        rCnt={recommend}
        urCnt={unRecommend}
        atkChange={toggle}
        refetch={refetch}
        editToggle={handleEditMode}
      />

      <div className={replyStyle.textArea}>
        {status === 0 ? (
          <>
            <p>
              {!isTargetRoot && (
                <span className={replyStyle.annotation}>
                  @{target.nickName}
                </span>
              )}
              {text}
            </p>
            <p className={replyStyle.date}>
              {moment(createdAt).format("YYYY-MM-DD HH:mm")}
            </p>
          </>
        ) : status === 1 ? (
          <>
            <p>삭제된 게시글 입니다.</p>
          </>
        ) : (
          ""
        )}
      </div>
      <div className={replyStyle.subReply}>
        {wMode ? (
          <div className={replyStyle.subStatus}>
            <div className={replyStyle.subInputWrap}>
              <div className={replyStyle.writeArea}>
                <textarea className={replyStyle.subInput} ref={subTextRef}>
                  {editMode ? text : ""}
                </textarea>
                <button
                  type={"button"}
                  className={replyStyle.subInputBtn + " " + replyStyle.submit}
                  onClick={editMode ? handleEditSubmit : submitSubReply}
                >
                  {editMode ? "수정" : "등록"}
                </button>
              </div>
              <div className={replyStyle.btnArea}>
                <span onClick={editMode ? handleCancelEditMode : handleWMode}>
                  취소
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className={replyStyle.subStatus + " " + replyStyle.nomore}>
            {own.isLogin && (
              <span className={replyStyle.addSubReply} onClick={handleWMode}>
                답글 쓰기
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
