import styled from "styled-components";

export const MyScrapList = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  > li {
    &.contentEmpty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    cursor: pointer;
    width: calc((100% - 2em) / 2);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0.5em 1em;
    margin: 0.5em 0;

    border-right: 1px solid #efefef;
    &:nth-child(2n) {
      border-right: none;
    }

    .imgArea {
      margin-right: 1em;
      > img {
        border-radius: 5px;
      }
    }
    .contentArea {
      width: calc(100% - 150px);
      font-size: 0.75em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .contentHost {
        font-weight: 400;
        font-size: 0.9em;
        color: #a0a0a0;
      }
      .contentTarget {
        font-size: 0.75em;
        color: #a0a0a0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .contentIcon {
          margin-right: 0.5em;
        }
      }
      .contentDate {
        font-size: 0.75em;
        color: #a0a0a0;
      }
    }
  }
`;
