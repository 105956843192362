import React, { useState } from "react";
import HostCenterHeaderTabs from "../../components/HostCenter/HeaderTabs";
import { AgentSubVisual } from "../../components/HostCenter/SubVisual";
import promoStyle from "../../assets/css/promo.module.scss";

import AgentImg from "../../assets/img/common/img_agent.png";
import { Link } from "react-router-dom";
import s1 from "../../assets/img/common/ico_1.png";
import s2 from "../../assets/img/common/ico_2.png";
import s3 from "../../assets/img/common/ico_3.png";
import s4 from "../../assets/img/common/ico_4.png";
import s5 from "../../assets/img/common/ico_5.png";
import s6 from "../../assets/img/common/ico_6.png";
import {
  Section1Ul,
  Section2,
  Section3,
  SectionBody,
  SectionBodyLi,
  SectionBodyUl,
  SectionHeader,
  SectionSince,
  SectionSpecial,
  SectionSubTitle,
  SectionText,
  Setion1Li,
} from "../../components/HostCenter/Agent/SectionStyle";
import {
  SectionCard,
  SectionContent,
} from "../../components/HostCenter/Agent/SectionItems";
import Tab1 from "../../components/HostCenter/Agent/Tab1";
import Tab2 from "../../components/HostCenter/Agent/Tab2";
import Tab3 from "../../components/HostCenter/Agent/Tab3";
import Tab4 from "../../components/HostCenter/Agent/Tab4";
import Tab5 from "../../components/HostCenter/Agent/Tab5";
import Tab6 from "../../components/HostCenter/Agent/Tab6";
import agentImg from "../../assets/img/common/agentImg.jpeg";
const TabArray = [
  { index: 1, component: <Tab1 /> },
  { index: 2, component: <Tab2 /> },
  { index: 3, component: <Tab3 /> },
  { index: 4, component: <Tab4 /> },
  { index: 5, component: <Tab5 /> },
  { index: 6, component: <Tab6 /> },
];
const Agent = () => {
  const [tab, setTab] = useState({
    curIndex: 1,
    component: <Tab1 />,
  });

  const handleTabChange = (index) => {
    setTab({
      curIndex: index,
      component: TabArray.filter((f) => f.index === index)[0].component,
    });
  };

  return (
    <div className={`container sub`}>
      <HostCenterHeaderTabs current={"agent"} />
      <AgentSubVisual />
      <div
        className={`sub_container ${promoStyle.sub_container}`}
        style={{ background: "#ffffff" }}
      >
        <div
          className={`contents mypage ${promoStyle.contents} ${promoStyle.mypage}`}
        >
          <div className="btn_area agent">
            <Link to={`/agentReq`} className={"btn"}>
              대행 서비스 문의
            </Link>
          </div>
          {/* <div className="slogan_area">
            <span>
              <small>주최사의 작은 걱정부터 </small>큰 고민까지 함께하는{" "}
              <b>믿음의 파트너</b>입니다.
            </span>
            <p>
              <b>
                대한민국 최초의 공모전 전문회사의 경험과 자부심으로 여러분을
                만나고 있습니다.
              </b>
            </p>
          </div> */}
          <img src={agentImg} style={{ marginBottom: "1em" }} />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={agentImg} style={{ marginBottom: "1em" }} /> */}
            {/* <Section1Ul>
              <Setion1Li>
                <SectionCard thumb={s1} label={"기획 & 설계"}>
                  <ul
                    style={{
                      paddingTop: "20px",
                    }}
                  >
                    <SectionContent
                      title={"컨설팅"}
                      content={
                        "다양한 공모전, 서포터즈 사업수행 데이터를 토대로 효과적인 컨설팅"
                      }
                    />
                    <SectionContent
                      title={"맞춤설계"}
                      content={"요청사의 특성에 맞게 맞춤설계"}
                    />
                    <SectionContent title={"최적의 소요예산 기획"} />
                  </ul>
                </SectionCard>
              </Setion1Li>

              <Setion1Li>
                <SectionCard thumb={s2} label={"홍보물 & 홈페이지 제작"}>
                  <ul
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <SectionContent
                      title={"온라인 홍보물 제작"}
                      content={"배너, SNS 카드뉴스 등"}
                    />
                    <SectionContent
                      title={"실물 제작"}
                      content={"포스터, 리플랫, 작품집, 기념품 등 기획 및 제작"}
                    />
                    <SectionContent title={"키비주얼 디자인"} />
                    <SectionContent title={"접수 홈페이지 기획 및 제작"} />
                  </ul>
                </SectionCard>
              </Setion1Li>

              <Setion1Li>
                <SectionCard thumb={s3} label={"온·오프라인 홍보"}>
                  <ul
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <SectionContent
                      title={"대티즌 채널 활용"}
                      content={"대티즌 사이트 및 대티즌 SNS 채널 활용"}
                    />
                    <SectionContent title={"제휴사 온라인 배너 광고"} />
                    <SectionContent
                      title={"인기 커뮤니티 및 SNS 채널을 활용한 광고홍보"}
                    />
                    <SectionContent
                      title={"기관 및 단체 홍보"}
                      content={
                        "수행 DB를 토대로 관련 기관 및 단체에 포스터+협조 공문 우편 발송"
                      }
                    />
                  </ul>
                </SectionCard>
              </Setion1Li>
            </Section1Ul>
            <Section1Ul>
              <Setion1Li>
                <SectionCard thumb={s4} label={"운영사무국"}>
                  <ul
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <SectionContent
                      title={"실시간 응대"}
                      content={"전화상담 및 Q&A 게시판 실시간 응대"}
                    />
                    <SectionContent title={"접수 관리 및 현황 보고"} />
                    <SectionContent
                      title={"관심도 확인"}
                      content={
                        "주기적인 모니터링을 통해 캠페인 관심도 및 반응 확인"
                      }
                    />
                  </ul>
                </SectionCard>
              </Setion1Li>
              <Setion1Li>
                <SectionCard thumb={s5} label={"심사"}>
                  <ul
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <SectionContent
                      title={"심사 기준 마련"}
                      content={"공정하고 투명한 심사기준 마련 및 선정"}
                    />
                    <SectionContent
                      title={"분야별 심사위원 섭외 및 내·외부 심사 진행"}
                    />
                    <SectionContent title={"심사 물품 준비 및 장소 대관"} />
                    <SectionContent
                      title={"심사 전 사전 협의"}
                      content={
                        "주기적인 모니터링을 통해 캠페인 관심도 및 반응 확인"
                      }
                    />
                  </ul>
                </SectionCard>
              </Setion1Li>
              <Setion1Li>
                <SectionCard thumb={s6} label={"시상 및 전시"}>
                  <ul
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <SectionContent
                      title={"시상식 개최 사전 준비 및 장소섭외"}
                    />
                    <SectionContent title={"홍보물/제작물 세팅 및 설치"} />
                    <SectionContent title={"주요 내외빈 의전"} />
                    <SectionContent title={"행사 진행 및 정리"} />
                    <SectionContent
                      title={"수상작 온·오프라인 전시 기획 및 운영"}
                    />
                  </ul>
                </SectionCard>
              </Setion1Li>
            </Section1Ul>
          </div>
          <Section2>
            <SectionHeader>
              <SectionSince>SINCE 2004</SectionSince>
              <SectionSubTitle>국내 최대 공모전 사이트이자</SectionSubTitle>
              <SectionText>
                수많은 공모전&서포터즈 프로젝트 대행으로 쌓은{" "}
                <b>
                  노하우를 겸비한 <SectionSpecial>대</SectionSpecial>
                  <SectionSpecial>티</SectionSpecial>
                  <SectionSpecial>즌</SectionSpecial>
                </b>
              </SectionText>
            </SectionHeader>
            <SectionBody style={{ marginTop: "100px" }}>
              <SectionBodyUl>
                <SectionBodyLi type="cont">
                  <label style={{ fontSize: "42px" }}>
                    공모전 대행 주요 사업
                  </label>
                  <div className={"content"}>
                    <div className={"contentItem"}>
                      <label>2022</label>
                      <p>LG소셜캠퍼스 제 5회 1분1초 영화제</p>
                      <p>KB Pay 아이디어 공모전</p>
                      <p>오뚜기 제 2회 푸드 에세이 공모전</p>
                      <p>세종학당 문화강좌 우수사례 공모전</p>
                      <p>2022 국민참여정책소통 공모전</p>
                      <p>하나금융그룹 100호 어린이 이용 수기 공모전</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2021</label>
                      <p>2021 K-water 물사랑공모전</p>
                      <p>2021 두근두근 화성시 SNS 공모전</p>
                      <p>오뚜기 제 1회 푸드 에세이 공모전</p>
                      <p>2021 국민참여정책소통 공모전</p>
                      <p>국립중앙도서관 캐릭터 공모전</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2020</label>
                      <p>2020 제주여행소셜콘텐츠 공모전</p>
                      <p>경기 MICE 대학생 창업 아이디어 공모전</p>
                      <p>당신만의 글로벌 서울을 보여주세요 공모전</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2019</label>
                      <p>2019 제주여행 소셜 콘텐츠 공모전</p>
                      <p>제 27회 KEB하나은행 자연사랑 어린이 미술대회</p>
                      <p>2019 서울시 좋은간판 공모전</p>
                      <p>제 10회 LH국토개발기술대전</p>
                      <p>2019 공공디자인 시민공모전</p>
                      <p>제 10회 관광벤처사업 공모전</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2018</label>
                      <p>2018 한국관광공사 관광기념품 공모전</p>
                      <p>2018 초록우산 어린이재단 감사편지쓰기 공모전</p>
                      <p>2017 THE MORE 스타트업 공모전</p>
                      <p>제 3회 중견기업 인식개선 공모전</p>
                      <p>2018 공공디자인 시민공모전</p>
                      <p>2018 서울시 좋은간판 공모전</p>
                      <p>제 10회 대학생 광고공모전 행사</p>
                      <p>2018 나라사랑 콘테스트 공모전</p>
                    </div>
                  </div>
                </SectionBodyLi>
                <SectionBodyLi type="sup">
                  <label>서포터즈 대행 주요 사업</label>
                  <div className={"content"}>
                    <div className={"contentItem"}>
                      <label>2022</label>
                      <p>농협 콕 서포터즈 1기</p>
                      <p>광동제약 음료마스터 2기</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2021</label>
                      <p>K-water 대학생 서포터즈 15기</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2020</label>
                      <p>2020 삼성전자 DS부문 희망공부방</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2019</label>
                      <p>KOICA 국민 서포터즈 1기</p>
                      <p>KEPCO 대학생 서포터즈 7기</p>
                      <p>K-water 대학생 서포터즈 13기</p>
                      <p>하나금융그룹 SMART 홍보대사 12기</p>
                      <p>2019 삼성전자 DS부문 희망공부방</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2018</label>
                      <p>KEPCO 대학생 서포터즈 6기</p>
                      <p>K-water 대학생 서포터즈 12기</p>
                      <p>하나금융그룹 SMART 홍보대사 10기, 11기</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2017</label>
                      <p>한국장학재단 지구별 꿈 도전단 6기</p>
                      <p>외국인 유학생 한국문화탐방단 '아우르기 3기'</p>
                    </div>
                    <div className={"contentItem"}>
                      <label>2016</label>
                      <p>하나금융그룹 SMART 홍보대사 7기,8기</p>
                      <p>K-water 대학생 서포터즈 10기</p>
                      <p>(재)한국방문위원회 대학생 미소국가대표 14,15기</p>
                      <p>이베이코리아 지마켓&옥션 SNS 투어단 3기</p>
                      <p>한국장학재단 지구별 꿈 도전단 5기</p>
                      <p>외국인 유학생 한국문화탐방단 '아우르기 2기'</p>
                    </div>
                  </div>
                </SectionBodyLi>
              </SectionBodyUl>
            </SectionBody>
          </Section2>
          <Section3>
            <ul>
              <li
                className={tab.curIndex === 1 ? "active" : ""}
                onClick={() => handleTabChange(1)}
              >
                자체 SNS 계정 운영
              </li>
              <li
                className={tab.curIndex === 2 ? "active" : ""}
                onClick={() => handleTabChange(2)}
              >
                온/오프라인 홍보물 제작
              </li>
              <li
                className={tab.curIndex === 3 ? "active" : ""}
                onClick={() => handleTabChange(3)}
              >
                홈페이지 구축
              </li>

              <li
                className={tab.curIndex === 4 ? "active" : ""}
                onClick={() => handleTabChange(4)}
              >
                심사대행
              </li>
              <li
                className={tab.curIndex === 5 ? "active" : ""}
                onClick={() => handleTabChange(5)}
              >
                시상식
              </li>
              <li
                className={tab.curIndex === 6 ? "active" : ""}
                onClick={() => handleTabChange(6)}
              >
                전시
              </li>
            </ul>
            {tab.component}
          </Section3> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agent;
