import React, { useEffect, useState } from "react";
import FcStyle from "../../assets/css/request.module.css";
import SpinnerStyle from "../../assets/css/loader.module.scss";
import {
  FreeBasicInfo,
  FreeEditorInfo,
  ManagerController,
} from "../../components/HostCenter/Free/FreeForm";
import { SubContainer } from "../../components/HostCenter/Free/Template";
import { FreeContestSubVisual } from "../../components/HostCenter/SubVisual";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadCommonInfo } from "../../utils/loadCommon";
import { useRecoilState } from "recoil";
import { accountSelect, commonSelect } from "../../recoil/AtomConfig";
import {
  AuthPostAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";
import styled from "styled-components";
import { handleFileUpload } from "../../utils/FileController";
import { LoadingLabel } from "../../components/Common/SubmitLoading";

const inputSchema = yup.object({
  info: yup.object({
    title: yup.string().required("공모명을 입력해주시기 바랍니다."),
    type: yup.number(),
    hostname: yup.string().required("주최사를 입력해주시기 바랍니다."),
    startDate: yup.string(),
    endDate: yup.string(),
    reward: yup.string().default(""),
    homepage: yup.string().default(""),
    tags: yup.string().default(""),
  }),
  manager: yup.object({
    mName: yup.string().required("담당자 이름을 입력해주시기 바랍니다."),
    mPhone: yup.string().required("담당자 연락처를 입력해주시기 바랍니다."),
    mEmail: yup.string().required("담당자 이메일을 입력해주시기 바랍니다."),
  }),
  editor: yup.object({
    cSubject: yup.string().required("주제를 입력해주시기 바랍니다."),
    cPeriod: yup.string().required("기간 및 일정을 입력해주시기 바랍니다."),
    cTarget: yup.string().required("참가대상을 입력해주시기 바랍니다."),
    cReward: yup.string().required("시상내역(혜택)을 입력해주시기 바랍니다."),
    cContents: yup.string(),
    cMethod: yup.string().required("참가방법을 입력해주시기 바랍니다."),
    cStandard: yup.string(),
    cDesk: yup.string().required("문의처를 입력해주시기 바랍니다."),
  }),
  file: yup.object({
    thumb: yup
      .mixed()
      .test("fileSize", "파일을 선택해주시기 바랍니다", (value) => {
        if (!value.length) return false;
        return value[0].size > 0;
      }),
    attachment: yup.mixed(),
    // .test("fileSize", "파일을 선택해주시기 바랍니다.", (value) => {
    //   if (!value.length) return false;
    //   return value[0].size > 0;
    // }),
  }),
});

const FreeContest = () => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [, setCommon] = useRecoilState(commonSelect);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inputSchema),
  });

  const authReq = async (url, data, accessToken) => {
    const postRes = await AuthPostAxios(url, data, accessToken);

    const { data: postData } = postRes;

    if (
      postData.statusCode === 401 &&
      postData.message === "토큰이 만료되었습니다."
    ) {
      const refreshTokenRes = await getRefreshToken(account.refreshToken);

      setAccount((prev) => {
        const prevObject = { ...prev };
        prevObject.accessToken = refreshTokenRes.data.data.accessToken;
        return { ...prevObject };
      });

      return await ExpiredFilter(
        url,
        authReq,
        data,
        refreshTokenRes.data.data.accessToken
      );
    } else {
      return postData;
    }
  };

  const onSubmit = async (data) => {
    if (!account.accessToken || !account.id) {
      alert("로그인 후 이용할 수 있습니다.");
      return false;
    }
    setSubmitLoading(true);

    //1. file upload and return file Result
    const dataList = [
      { file: data.file.thumb[0], type: "thumb", objectTarget: "file.thumb" },
    ];
    if (data.file.attachment.length !== 0) {
      dataList.push({
        file: data.file.attachment[0],
        type: "attachment",
        objectTarget: "file.attachment",
      });
    }

    const convertedData = await handleFileUpload(dataList, data);
    if (!convertedData) {
      return false;
    }

    const reqRes = await authReq(
      "reqContest",
      { ...convertedData },
      account.accessToken
    );

    if (reqRes.statusCode === 200) {
      alert(
        "성공적으로 요청되었습니다. 요청 내용은 [마이페이지] -> [무료등록] 에서 확인하실 수 있습니다."
      );

      setSubmitLoading(false);
    } else {
      // console.log(reqRes);
      alert("저장중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다.");
      setSubmitLoading(false);
    }

    //2. submit using the file Result
  };

  useEffect(() => {
    async function getCommon() {
      const { data } = await LoadCommonInfo();
      if (data) {
        setCommon((prev) => {
          const prevObject = { ...prev };
          prevObject.loading = false;
          prevObject.category = [...data.category];
          prevObject.host = [...data.host];
          prevObject.target = [...data.target];
          prevObject.totalReward = [...data.totalReward];
          prevObject.region = [...data.region];
          return { ...prevObject };
        });
      }
    }
    getCommon();
  }, []);

  return (
    <div className={`container sub ${FcStyle.hc}`}>
      <FreeContestSubVisual />
      <SubContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ManagerController
            register={register}
            errors={errors}
            setValue={setValue}
          />

          <FreeBasicInfo
            register={register}
            errors={errors}
            setValue={setValue}
          />

          <FreeEditorInfo setValue={setValue} watch={getValues} />

          <div className="btn_area regist">
            {loading ? (
              <>
                {/* <SpinnerLoader /> */}
                <button className="btn regist" disabled>
                  <div className={SpinnerStyle.loading_spinner} />
                </button>
              </>
            ) : (
              <button className="btn regist">등록하기</button>
            )}
          </div>
        </form>
      </SubContainer>
      {submitLoading && <LoadingLabel label={"loading ..."} />}
    </div>
  );
};

export default FreeContest;
