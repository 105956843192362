import React, { Suspense, useEffect, useRef, useState } from "react";

import {
  AfterData,
  ChallengeFilter,
  ChallengeSubContainer,
  ChallengeSubMenu,
} from "../../components/Challenge/Common/Element";
import Cmstyle from "../../assets/css/community.module.scss";
import { Link } from "react-router-dom";
import { NormalReqApi } from "../../utils/ReqApi";
import pStyle from "../../assets/css/paging.module.scss";
import Pagination from "../../utils/Pagination";
import NoticeList from "../../components/Notice/NoticeList";

const FreeBoard = () => {
  const [list, setList] = useState({
    loading: true,
    data: [],
    total: 0,
  });
  const [page, setPage] = useState(1);
  const typeRef = useRef(null);
  const keywordRef = useRef(null);

  const getFB = async (page, type, keyword) => {
    const res = await NormalReqApi(
      "get",
      `freeboard?page=${page}&type=${type}&keyword=${keyword}`
    );
    if (res.statusCode === 200) {
      setList({
        loading: false,
        data: [...res.result.list],
        total: res.result.total,
      });
    }
  };

  useEffect(() => {
    getFB();
  }, []);

  const handlePage = (pageNumber) => {
    setPage(pageNumber);
    getFB(pageNumber, typeRef.current.value, keywordRef.current.value);
  };

  const handleSearch = () => {
    const type = typeRef.current.value;
    const keyword = keywordRef.current.value;
    setPage(1);
    getFB(1, type, keyword);
  };

  const handleEnterKey = (e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={"freeboard"} />
      <ChallengeSubContainer
        label={"자유게시판"}
        subLabel={"익명으로 우리들만의 고민을 이야기해봐요!"}
      >
        <ChallengeFilter
          type={typeRef}
          keyword={keywordRef}
          toggle={handleSearch}
          handleEnterKey={handleEnterKey}
          options={[
            { value: "all", label: "선택" },
            { value: "title", label: "제목" },
            { value: "content", label: "내용" },
          ]}
        />
        <div className={`board_list myInfo_wirte ${Cmstyle.board_list}`}>
          <ul>
            <li className={`th ${Cmstyle.th}`}>
              <span className={`w70`}>제목</span>
              {/* <span className={`w15`}>회원명</span> */}
              <span className={`w30`}>작성일</span>
            </li>
            <Suspense fallback={<div></div>}>
              <NoticeList type="freeboard" />
            </Suspense>
            {!list.loading ? (
              list.data.length > 0 ? (
                list.data.map((item, index) => (
                  <AfterData
                    target={"freeboard"}
                    key={`freeboard_data_${index}`}
                    {...item}
                  />
                ))
              ) : (
                <li>
                  <a>
                    <span className="brd_title">
                      등록된 자유게시판이 존재하지 않습니다.
                    </span>
                  </a>
                </li>
              )
            ) : null}
          </ul>
          <div className={"btn_area board"}>
            <Link to={`/challengeform/f`} className={"goToLogin"}>
              글쓰기
            </Link>
          </div>
          <div className={`paging_area ${pStyle.paging_area}`}>
            <Pagination
              total={list.total}
              curPage={page}
              handlePage={handlePage}
            />
          </div>
        </div>
      </ChallengeSubContainer>
    </div>
  );
};

export default FreeBoard;
