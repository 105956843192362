import React, { useEffect, useState } from "react";
import { DetailSlider, ThumbSlider } from "./DetailSlider";
import { NormalReqApi } from "../../utils/ReqApi";
import { TempListData } from "./TempData";
import { isMobile } from "react-device-detect";
import { useRecoilState } from "recoil";
import { bgColorSelect } from "../../recoil/AtomConfig";

const TEMP_LIST = TempListData;

const MainSection1 = ({
  className,
  subClassName,
  detailClassName,
  thumbClassName,
}) => {
  const [curDetail, setCurDetail] = useState(null);
  const [curSlider, setCurSlider] = useState(null);
  const [curList, setCurList] = useState({
    loading: true,
    list: [],
  });
  const [bgColor, setBgColor] = useRecoilState(bgColorSelect);
  const changeBg = (toggle) => {
    setBgColor((prev) => {
      const temp = { ...prev };
      temp.change = toggle;
      return { ...temp };
    });
  };

  const getData = async () => {
    const res = await NormalReqApi(
      "get",
      `banner/0/${isMobile ? "phone" : "pc"}`
    );

    setCurList({
      loading: false,
      // loading: true,
      list: [...res.data],
    });

    changeBg(res?.data[0]?.changeColorYn);
  };

  useEffect(() => {
    getData();
  }, []);

  const SETTING_DETAIL = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    beforeChange: (current, next) => getColorChangeYn(next),
  };

  const getColorChangeYn = (index) => {
    changeBg(curList?.list[index]?.changeColorYn);
  };

  const SETTINGS_THUMB = {
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    dots: false,
    infinite: true,
    arrows: false,
    centerMode: false,

    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  return (
    <section className={className}>
      <div className={`topBannerArea ${isMobile ? "mo" : ""}`}>
        <DetailSlider
          type={"top"}
          setSlider={setCurDetail}
          settings={SETTING_DETAIL}
          className={"bannerDetail"}
          detailClassName={detailClassName}
          asNavFor={curSlider}
          list={curList.loading ? TEMP_LIST : curList.list}
          loading={curList.loading}
        />
        <div className={subClassName}>
          <h2 className="sectionTit">
            {bgColor.change ? (
              <>
                <span style={{ color: "#323232" }}>Top 배너</span>
                <small style={{ color: "#323232" }}>
                  지금 이시간, 꼭 봐야할 공모전/대외활동!
                </small>
              </>
            ) : (
              <>
                <span>Top 배너</span>
                <small>지금 이시간, 꼭 봐야할 공모전/대외활동!</small>
              </>
            )}
          </h2>

          <ThumbSlider
            type="top"
            settings={SETTINGS_THUMB}
            className={`center bannerList ${isMobile ? "mo" : ""}`}
            thumbClassName={thumbClassName}
            setSlider={setCurSlider}
            asNavFor={curDetail}
            list={curList.loading ? TEMP_LIST : curList.list}
            loading={curList.loading}
          />
        </div>
      </div>
    </section>
  );
};

export default MainSection1;
