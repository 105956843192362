import React from "react";
import Slider from "react-slick";
import C2 from "../../assets/img/common/img_client02.png";
import C3 from "../../assets/img/common/img_client03.png";
import C4 from "../../assets/img/common/img_client04.png";
import C5 from "../../assets/img/common/img_client05.png";
import C6 from "../../assets/img/common/img_client06.png";
import C7 from "../../assets/img/common/img_client07.png";
import C8 from "../../assets/img/common/img_client08.png";

const Config = {
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  centerMode: true,
  focusOnSelect: true,
};

const C_LIST = [C2, C3, C4, C5, C6, C7, C8];

const MainSection5 = () => {
  return (
    <>
      <section className="section4"></section>
      <section className={`section6`}>
        {/* <Slider {...Config} className={`client`}>
          {C_LIST.map((item, index) => (
            <a key={`sponer_${index}`}>
              <img src={item} />
            </a>
          ))}
        </Slider> */}
      </section>
    </>
  );
};

export default MainSection5;
