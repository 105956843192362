import React from "react";
import { Link } from "react-router-dom";
import FB from "../../assets/img/common/btn_facebook.png";
import Insta from "../../assets/img/common/btn_instagram.png";
import Naver from "../../assets/img/common/btn_naver.png";
import NaverB from "../../assets/img/common/btn_naverblog.png";

import { HiDotsVertical } from "react-icons/hi";

const FixedMenu = ({ className, hide, setHide }) => {
  return (
    <div className={className}>
      <div
        className="snb_cont"
        style={{
          flexDirection: "column-reverse",
        }}
      >
        <div
          className="sns_wrap"
          style={{
            flexDirection: "column",
          }}
        >
          <a href={"https://www.facebook.com/Detizen?ref=hl"} target={"_blank"}>
            <img src={FB} alt="blankLinkImg" />
          </a>

          <a
            target={"_blank"}
            href={"https://www.instagram.com/gonggang_time/"}
          >
            <img src={Insta} alt="blankLinkImg" />
          </a>
          <a target={"_blank"} href={"https://post.naver.com/detizen_1"}>
            <img src={Naver} alt="blankLinkImg" />
          </a>
          <a target={"_blank"} href={"https://blog.naver.com/detizen_1"}>
            <img src={NaverB} alt="blankLinkImg" />
          </a>
          {/* <a
            target={"_blank"}
            href={"http://alltizen.com"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "1em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                backgroundColor: "#2b303b",
                fontSize: "15px",
              }}
            >
              D
            </span>
          </a> */}
        </div>
        {/* <a
    className="btn_coincenter"
    style={{
      color: "white",
    }}
  >
    코인센터
  </a> */}
        <div onClick={() => setHide(!hide)}>
          <HiDotsVertical color="white" />
        </div>
      </div>
    </div>
  );
};

export default FixedMenu;
