import React, { createRef, forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";

export const Datepicker = ({ parseDateToString, minDate, type }) => {
  const [curDate, setCurDate] = useState(new Date());
  const ref = createRef();

  const convertDateToString = (date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      " " +
      (type === "start" ? "00:00:00" : "23:59:59")
    );
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      ref={ref}
      onClick={onClick}
      className={"input_text w100"}
    >
      {value}
    </button>
  ));

  useEffect(() => {
    if (curDate) {
      parseDateToString(convertDateToString(curDate));
    }
  }, [curDate]);

  return (
    <DatePicker
      locale={ko}
      selected={curDate}
      minDate={minDate ? new Date(minDate) : null}
      onChange={(date) => setCurDate(date)}
      customInput={<CustomInput />}
      disabled={false}
      dateFormat="yyyy-MM-dd"
    />
  );
};
