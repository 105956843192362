import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { NormalReqApi } from "../../utils/ReqApi";
import { AfterViewComponent, ChallengeView } from "../Challenge/Common/Element";

const NoticeDetail = ({ type, id }) => {
  const navi = useNavigate();
  const { data, error } = useQuery(
    ["noticeView", id],
    async () => await NormalReqApi("get", `/notice/view/${id}`),
    { retry: false, suspense: true }
  );
  const handleGoBack = () => {
    navi(-1);
  };

  const render = (type, data) => {
    switch (type) {
      case "teamplay":
        return (
          <ChallengeView
            label={"팀원모집 공지사항"}
            {...data.data}
            author={"대티즌"}
            content={data.data.contents}
          >
            <div className={"answer_area"}>
              <div className={"btn_area board"}>
                <a className={"btn"} onClick={handleGoBack}>
                  목록으로
                </a>
              </div>
            </div>
          </ChallengeView>
        );
      case "after":
        return (
          <AfterViewComponent
            label={"활동후기 공지사항"}
            {...data.data}
            author={"대티즌"}
            content={data.data.contents}
          >
            <div className={"answer_area"}>
              <div className={"btn_area board"}>
                <a className={"btn"} onClick={handleGoBack}>
                  목록으로
                </a>
              </div>
            </div>
          </AfterViewComponent>
        );
      case "qna":
        return (
          <AfterViewComponent
            label={"활동Q&A 공지사항"}
            {...data.data}
            author={"대티즌"}
            content={data.data.contents}
          >
            <div className={"answer_area"}>
              <div className={"btn_area board"}>
                <a className={"btn"} onClick={handleGoBack}>
                  목록으로
                </a>
              </div>
            </div>
          </AfterViewComponent>
        );
      case "freeboard":
        return (
          <AfterViewComponent
            label={"자유게시판 공지사항"}
            {...data.data}
            author={"대티즌"}
            content={data.data.contents}
          >
            <div className={"answer_area"}>
              <div className={"btn_area board"}>
                <a className={"btn"} onClick={handleGoBack}>
                  목록으로
                </a>
              </div>
            </div>
          </AfterViewComponent>
        );
    }
  };
  return <>{render(type, data)}</>;
};

export default NoticeDetail;
