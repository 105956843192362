import React from "react";
import styled from "styled-components";

export const BtnArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const Btn = styled.button`
  margin: 0 0.5em;
  border-radius: 0.5em;
  background-color: #263050;
  padding: 0.3em 0.5em;
  color: white;
  border: none;
  cursor: pointer;
`;

export const PromotionSubWrap = ({ children }) => {
  return (
    <div className={"sub_container"}>
      <div className={"contents products"}>
        <div className="prod_list view">{children}</div>
      </div>
    </div>
  );
};

export const PromotionCharacter = ({ label, children }) => {
  return (
    <>
      <div className="cd_title">
        <span>{label}</span>
      </div>
      <div className="cd_text">{children}</div>
    </>
  );
};
