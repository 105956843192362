import React, { lazy, Suspense } from "react";
import {
  DContentDataList,
  DContentsSection,
  DContentsSubMenu,
  DContentsWrap,
} from "../../components/DContents/Common/Element";
import Chstyle from "../../assets/css/channel.module.scss";
import Default280 from "../../assets/img/common/img_posterDefault_280.jpg";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  ContentsWrap,
  CTitle,
  DCwrap,
  Section,
  SectionWrap,
  SMoreBtn,
  SSummary,
  STitle,
  SubText_P,
  ThumbItem,
  ThumbSlide,
} from "../../components/DContents/Mobile/Frame";
import EmptyPrev from "../../components/DContents/Mobile/EmptyPrev";
import WeeklyPrev from "../../components/DContents/Mobile/WeeklyPrev";

import { EmptyList_noData } from "../../components/DContents/EmptyTime/empty";
import EmptyPrev_suspense from "../../components/DContents/EmptyTime/prev";
import WeeklyPrev_suspense from "../../components/DContents/Weekly/prev";
import { DepicSuspensePrev } from "../../components/DContents/Depic/getdata";
import EventPrevPC from "../../components/DContents/Event/EventPrevPC";

const DepicPrev = lazy(() =>
  import("../../components/DContents/Mobile/DepicPrev")
);
const EventPrev = lazy(() =>
  import("../../components/DContents/Mobile/EventPrev")
);

const DcontentsIndex = () => {
  return (
    <div className={"container sub"}>
      <DContentsSubMenu current={""} />
      <DContentsWrap label={"대티즌 콘텐츠"}>
        {isMobile ? (
          <DCwrap>
            <Suspense fallback={<div></div>}>
              <EmptyPrev />
            </Suspense>
            <Suspense fallback={<div></div>}>
              <WeeklyPrev />
            </Suspense>

            <Suspense fallback={<p>loading...</p>}>
              <DepicPrev />
            </Suspense>
            <Suspense fallback={<p>loading...</p>}>
              <EventPrev />
            </Suspense>
          </DCwrap>
        ) : (
          <>
            <DContentsSection
              className={`section boardList ${Chstyle.section} ${Chstyle.boardList}`}
            >
              <DContentDataList
                className={`boardItem idx_interview host ${Chstyle.idx_interview} ${Chstyle.host}`}
                label={"공강시간"}
                subLabel={"대티즌이 전하는 알아두면 도움이 되는 지식"}
                target={"/emptytime"}
              >
                <ul className={`thumbList ${Chstyle.thumbList}`}>
                  <Suspense fallback={<EmptyList_noData />}>
                    <EmptyPrev_suspense />
                  </Suspense>
                </ul>
              </DContentDataList>
              <DContentDataList
                className={`boardItem idx_board weekly ${Chstyle.idx_board} ${Chstyle.weekly}`}
                label={"위클리"}
                subLabel={
                  "여러분의 소중한 시간을 위해 최신 공모전을 알짜배기로 정리해 드립니다!"
                }
                target={"/weekly"}
              >
                <ul className={"boardList index"}>
                  <Suspense fallback={<div></div>}>
                    <WeeklyPrev_suspense />
                  </Suspense>
                </ul>
              </DContentDataList>
            </DContentsSection>
            <DContentsSection
              className={`section interview ${Chstyle.section} ${Chstyle.interview}`}
            >
              <DContentDataList
                className={`idx_interview winner ch_index ${Chstyle.idx_interview} ${Chstyle.ch_index}`}
                label={"대픽 영상"}
                subLabel={"대단히 Pick! 하고 싶은 꿀잼 영상들을 확인하세요!"}
                target={"/depic"}
              >
                <ul className={`thumbList ${Chstyle.thumbList}`}>
                  <Suspense fallback={<div></div>}>
                    <DepicSuspensePrev />
                  </Suspense>
                </ul>
              </DContentDataList>
            </DContentsSection>
            {/* <DContentsSection
              className={`section interview ${Chstyle.section} ${Chstyle.interview}`}
            >
              <DContentDataList
                className={`idx_interview winner ch_index ${Chstyle.idx_interview} ${Chstyle.ch_index}`}
                label={"이벤트"}
                subLabel={"대티즌에서 진행중인 다양한 이벤트에 참여하세요!"}
                target={"/event"}
              >
                <ul className={`thumbList ${Chstyle.thumbList}`}>
                  <Suspense fallback={<div></div>}>
                    <EventPrevPC />
                  </Suspense>
                </ul>
                <div style={{ marginTop: "1em", textAlign: "right" }}>
                  <Link
                    style={{
                      padding: "0.3em 0.5em",
                      backgroundColor: "#2b303b",
                      color: "white",
                      borderRadius: "1em",
                      fontSize: "0.75em",
                    }}
                    to={`/event/submitResult`}
                  >
                    이벤트 당첨 확인하기
                  </Link>
                </div>
              </DContentDataList>
            </DContentsSection> */}
          </>
        )}
      </DContentsWrap>
    </div>
  );
};

export default DcontentsIndex;
