import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  ChallengeFilter,
  ChallengeSubContainer,
  ChallengeSubMenu,
  QnaData,
} from "../../components/Challenge/Common/Element";
import Cmstyle from "../../assets/css/community.module.scss";
import { Link } from "react-router-dom";
import { NormalReqApi } from "../../utils/ReqApi";
import pStyle from "../../assets/css/paging.module.scss";
import Pagination from "../../utils/Pagination";
import NoticeList from "../../components/Notice/NoticeList";

const Qna = () => {
  const [list, setList] = useState({
    loading: true,
    data: [],
    total: 0,
  });
  const [page, setPage] = useState(1);

  const typeRef = useRef(null);
  const keywordRef = useRef(null);

  const getQna = async (page, type, keyword) => {
    const res = await NormalReqApi(
      "get",
      `qna?page=${page}&type=${type}&keyword=${keyword}`
    );
    if (res.statusCode === 200) {
      setList({
        loading: false,
        data: [...res.result.list],
        total: res.result.total,
      });
    }
  };

  useEffect(() => {
    getQna(page);
  }, []);

  const handlePage = (pageNumber) => {
    setPage(pageNumber);
    getQna(pageNumber, typeRef.current.value, keywordRef.current.value);
  };
  const handleSearch = () => {
    const type = typeRef.current.value;
    const keyword = keywordRef.current.value;

    getQna(page, type, keyword);
  };

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={"qna"} />
      <ChallengeSubContainer
        label={"활동Q&A"}
        subLabel={"공모전, 대외활동에 도전하고 싶은데 궁금한게 있다면?"}
      >
        <ChallengeFilter
          type={typeRef}
          keyword={keywordRef}
          toggle={handleSearch}
          options={[
            { value: "all", label: "선택" },
            { value: "title", label: "제목" },
            { value: "content", label: "내용" },
          ]}
        />
        <div className={`board_list myInfo_wirte ${Cmstyle.board_list}`}>
          <ul>
            <li className={`th ${Cmstyle.th}`}>
              <span className="w10">카테고리</span>
              <span className="w55">제목</span>
              <span className="w10">이름</span>
              <span className="w15">작성일</span>
              <span className="w10">답변여부</span>
            </li>
            <Suspense fallback={<div></div>}>
              <NoticeList type={"qna"} />
            </Suspense>
            {!list.loading
              ? list.data.length > 0
                ? list.data.map((item, index) => (
                    <QnaData
                      key={`qna_data_${index}`}
                      id={item._id}
                      {...item}
                    />
                  ))
                : null
              : null}
          </ul>
          <div className={"btn_area board"}>
            <Link to={`/challengeForm/q`} className={"goToLogin"}>
              글쓰기
            </Link>
          </div>
          <div className={`paging_area ${pStyle.paging_area}`}>
            <Pagination
              total={list.total}
              curPage={page}
              handlePage={handlePage}
            />
          </div>
        </div>
      </ChallengeSubContainer>
    </div>
  );
};

export default Qna;
