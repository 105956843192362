import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";

const MainGnb = ({ chStyle, movePage, open }) => {
  const [account] = useRecoilState(accountSelect);
  return (
    <div
      className={`gnb ${chStyle.gnb} ` + (open && `active ${chStyle.active}`)}
    >
      <nav className="menu">
        <div className={`nav-category ${chStyle.nav_category}`}>
          <ul className={`accordion gnbBox ${chStyle.gnbBox}`}>
            <li>
              <>
                <a href="#" onClick={() => movePage("/contest")}>
                  <span>공모전</span>
                </a>

                <ul className={`submenu ${chStyle.submenu}`}>
                  <li>
                    <a>
                      <span>기획/아이디어</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>IT/SW/공학</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>디자인</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>게임/웹툰/캐릭터</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>광고/마케팅</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>문학/수기/시나리오</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>금융/경제/경영</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>연구/학술/논문/정책</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>창업/스타트업</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>체험기/사용기</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>브랜드/네이밍</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>사진</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>영상(UCC)</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>기타</span>
                    </a>
                  </li>
                </ul>
              </>
            </li>
            <li>
              <>
                <a onClick={() => movePage("/activity")}>
                  <span>대외활동</span>
                </a>
                <ul className={`submenu ${chStyle.submenu}`}>
                  <li>
                    <a>
                      <span>강연/교육/멘토링</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>서포터즈/마케터</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>동아리/소모임/단체</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>해외탐방/봉사</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>행사/전시페스티벌</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>국내봉사</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>기자단</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>체험단/모니터링</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>취업/인턴</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span>기타</span>
                    </a>
                  </li>
                </ul>
              </>
            </li>
            <li>
              <a onClick={() => movePage("/dcontents", "dcontents")}>
                <span>대티즌 콘텐츠</span>
              </a>
              <ul className={`submenu ${chStyle.submenu}`}>
                <li>
                  <a onClick={() => movePage("/emptytime", "dcontents")}>
                    <span>공강시간</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => movePage("/weekly", "dcontents")}>
                    <span>위클리</span>
                  </a>
                </li>

                <li>
                  <a onClick={() => movePage("/depic", "dcontents")}>
                    <span>대픽 영상</span>
                  </a>
                </li>
                {/* <li>
                  <a onClick={() => movePage("/event", "dcontents")}>
                    <span>이벤트</span>
                  </a>
                </li> */}
              </ul>
            </li>
            <li>
              <a onClick={() => movePage("/event", "event")}>
                <span>이벤트</span>
              </a>
            </li>
            {/* <li>
              <a onClick={() => movePage("/challenge", "challenge")}>
                <span>공모전 도전하기</span>
              </a>
              <ul className={`submenu ${chStyle.submenu}`}>
                <li>
                  <a onClick={() => movePage("/teamplay", "challenge")}>
                    <span>팀플레이</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => movePage("/after", "challenge")}>
                    <span>활동후기</span>
                  </a>
                </li>

                <li>
                  <a onClick={() => movePage("/freeboard", "challenge")}>
                    <span>자유게시판</span>
                  </a>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to={"/request/contest/contestActivity"}>
                <span>무료등록</span>
              </Link>
            </li> */}
            <li>
              <a onClick={() => movePage("/hostcenter", "hostcenter")}>
                <span>고객센터</span>
              </a>
              <ul className={`submenu ${chStyle.submenu}`}>
                <li>
                  <a onClick={() => movePage("/freeContest", "hostcenter")}>
                    <span>무료 공모전/대외활동 등록</span>
                  </a>
                </li>

                <li>
                  <a onClick={() => movePage("/promotion", "hostcenter")}>
                    <span>광고 서비스</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => movePage("/agent", "hostcenter")}>
                    <span>대행 서비스</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MainGnb;
