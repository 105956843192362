import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const PagingWrap = styled.div`
  padding-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PagingItem = styled.button`
  text-decoration: none;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 0.7em;
  border: none;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border: ${(props) => (props.disabled ? "none" : "1px solid white")};
  background-color: ${(props) => (props.active ? "white" : "transparent")};
  color: ${(props) => (props.active ? "black" : "white")};
`;

export const MPaging2 = ({ data, curPage, toggle }) => {
  const {
    data: { count },
  } = data;
  const scale = 12;
  const firstPage = 1;
  const lastPage = Math.ceil(count / 12);

  const movePage = (page) => {
    toggle(page);
  };

  const render = (total, curPage) => {
    let pageList = [];
    if (curPage * 1 === 1) {
      for (let i = curPage * 1; i <= curPage * 1 + 2; i++) {
        if (i <= lastPage) {
          pageList.push(i);
        }
      }
    } else {
      for (let i = curPage * 1 - 1; i < curPage * 1 + 2; i++) {
        if (i <= lastPage) {
          pageList.push(i);
        }
      }
    }

    return pageList.map((item, index) => {
      return (
        <PagingItem
          key={`p_i_${index}`}
          onClick={() => movePage(item)}
          active={item === curPage * 1}
        >
          {item}
        </PagingItem>
      );
    });
  };

  return (
    <PagingWrap>
      <PagingItem onClick={() => movePage(firstPage)}>{"<"}</PagingItem>
      {curPage * 1 !== 1 && curPage * 1 !== 2 && (
        <PagingItem onClick={() => movePage()} disabled>
          ...
        </PagingItem>
      )}
      {render(count, curPage)}
      {curPage * 1 !== lastPage &&
        curPage * 1 !== lastPage - 1 &&
        curPage * 1 !== lastPage - 2 && (
          <PagingItem onClick={() => movePage()} disabled>
            ...
          </PagingItem>
        )}

      <PagingItem onClick={() => movePage(lastPage)}>{">"}</PagingItem>
    </PagingWrap>
  );
};

export const MPaging = ({ result, toggle }) => {
  const { total, curPage } = result;
  const scale = 12;
  const firstPage = 1;
  const lastPage = Math.ceil(total / 12);

  const movePage = (page) => {
    toggle(page);
  };

  const render = (total, curPage) => {
    let pageList = [];
    if (curPage * 1 === 1) {
      for (let i = curPage * 1; i <= curPage * 1 + 2; i++) {
        if (i <= lastPage) {
          pageList.push(i);
        }
      }
    } else {
      for (let i = curPage * 1 - 1; i < curPage * 1 + 2; i++) {
        if (i <= lastPage) {
          pageList.push(i);
        }
      }
    }

    return pageList.map((item, index) => {
      return (
        <PagingItem
          key={`p_i_${index}`}
          onClick={() => movePage(item)}
          active={item === curPage * 1}
        >
          {item}
        </PagingItem>
      );
    });
  };

  return (
    <PagingWrap>
      <PagingItem onClick={() => movePage(firstPage)}>{"<"}</PagingItem>
      {curPage * 1 !== 1 && curPage * 1 !== 2 && (
        <PagingItem onClick={() => movePage()} disabled>
          ...
        </PagingItem>
      )}
      {render(total, curPage)}
      {curPage * 1 !== lastPage &&
        curPage * 1 !== lastPage - 1 &&
        curPage * 1 !== lastPage - 2 && (
          <PagingItem onClick={() => movePage()} disabled>
            ...
          </PagingItem>
        )}

      <PagingItem onClick={() => movePage(lastPage)}>{">"}</PagingItem>
    </PagingWrap>
  );
};
