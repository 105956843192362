import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HostCenterHeaderTabs from "../../components/HostCenter/HeaderTabs";
import {
  Btn,
  BtnArea,
  PromotionCharacter,
  PromotionSubWrap,
} from "../../components/HostCenter/Promotion/PViewCommon";
import { PromotionSubVisual } from "../../components/HostCenter/SubVisual";
import { NormalReqApi } from "../../utils/ReqApi";
const ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT;
const ETC = process.env.REACT_APP_AWS_STORAGE_ETC;

const PromotionDetail = () => {
  const { id } = useParams();
  const navi = useNavigate();

  const [info, setInfo] = useState({
    loading: true,
  });

  useEffect(() => {
    async function getProductById(id) {
      const res = await NormalReqApi("get", `/product/${id}`);

      setInfo({ ...res.data, loading: false });
    }
    if (id) {
      getProductById(id);
    }
  }, [id]);

  const handleGoBack = () => {
    navi(-1);
  };
  const handleAgent = () => {
    navi("/agentReq");
  };
  const handlePerchase = () => {};
  return (
    <div className={"container sub"}>
      <HostCenterHeaderTabs current="promotion" />
      <PromotionSubVisual />
      <PromotionSubWrap>
        {!info.loading && (
          <ul>
            {/* img 영역 */}
            <li>
              <span>
                <b className={"num"}>{info.title}</b>
                <img src={ENDPOINT + ETC + info.thumbImage.path} />
              </span>
            </li>
            {/* 기타 정보 영역  */}
            <li className={"products_info"}>
              <div className="contest_detail middle">
                <ul>
                  <li className="character">
                    <PromotionCharacter label={"특징"}>
                      {info.des}
                    </PromotionCharacter>
                  </li>
                  <li className="cost">
                    <PromotionCharacter label={"기간 및 단가"}>
                      <ul style={{ paddingLeft: 0 }}>
                        {info.period
                          .filter((f) => f.isShow)
                          .map((item, index) => (
                            <li
                              key={`promotion_period_${index}`}
                              style={{ paddingLeft: 0 }}
                            >
                              <span>{item.period} 일</span>
                              {/* <p className={"origin"}>
                                {item.price.toLocaleString("ko")}
                              </p> */}
                              <p className={"discount"}>
                                {item.price.toLocaleString("ko-KR")}
                              </p>
                              <p>(vat 별도)</p>
                            </li>
                          ))}
                      </ul>
                    </PromotionCharacter>
                  </li>
                  <li className="size">
                    <PromotionCharacter label={"사이즈"}>
                      <div style={{ display: "block" }}>
                        {info.backSize.flag && (
                          <p>
                            배경 이미지 : {info.backSize.width} x{" "}
                            {info.backSize.height} px
                          </p>
                        )}
                        {info.thumbSize.flag && (
                          <p>
                            썸네일 이미지 : {info.thumbSize.width} x{" "}
                            {info.thumbSize.height} px
                          </p>
                        )}
                      </div>
                    </PromotionCharacter>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        )}
        <BtnArea>
          <Btn type="button" onClick={handleGoBack}>
            목록으로
          </Btn>
          <Btn type="button" onClick={handleAgent}>
            문의하기
          </Btn>
          {/* <Btn type="button" onClick={handlePerchase}>
            구매하기
          </Btn> */}
        </BtnArea>
      </PromotionSubWrap>
    </div>
  );
};

export default PromotionDetail;
