import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import promoStyle from "../../../assets/css/promo.module.scss";
import { NormalReqApi } from "../../../utils/ReqApi";

const ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT;
const ETC = process.env.REACT_APP_AWS_STORAGE_ETC;

const PromotionSection2 = () => {
  const [pList, setPList] = useState([]);

  useEffect(() => {
    async function getProduct() {
      const res = await NormalReqApi("get", "product");
      const { statusCode, message, data } = res;
      if (statusCode === 200) {
        setPList([...data]);
      } else {
        alert(
          "광고상품 조회 중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
        );
      }
    }

    getProduct();
  }, []);

  return (
    <div className={`sub_container ${promoStyle.sub_container}`}>
      <div
        className={`contents products ${promoStyle.contents} ${promoStyle.products}`}
      >
        <div className="prod_list">
          <ul>
            {pList.length > 0 &&
              pList
                .sort((a, b) => a.type - b.type)
                .map((item, index) => (
                  <li key={`product_${index}`}>
                    <span>
                      <b className={"num"}>0{index + 1}</b>
                      {item.title}
                    </span>
                    <Link to={`/promotion/${item._id}`}>상품보기</Link>
                    <p>{item.des}</p>
                    <img src={ENDPOINT + ETC + item.thumbImage.path} />
                  </li>
                ))}
            {/* {pList.map((item, index) => (
              <li key={item._id}>
                <span>
                  <b className="num">01</b>
                  {item.title}
                </span>

                <Link to={`/promotion/view/${item._id}`}>
                  <a href="#">상품 보기</a>
                </Link>

                <p>{item.des}</p>
                <Image
                  src={item.thumbImage.path}
                  width={1020}
                  height={440}
                  priority
                />
              </li>
            ))} */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PromotionSection2;
