import React, { useEffect } from "react";
import { HiArrowCircleUp } from "react-icons/hi";
import { useLocation } from "react-router-dom";

const ScrollTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleMoveTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={"moveTopBtnArea"} role="moveTop">
      <HiArrowCircleUp color={"gray"} size={50} onClick={handleMoveTop} />
    </div>
  );
};

export default ScrollTop;
