import React, { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useNavigate, useParams } from "react-router-dom";
import { SignupWrap } from "../../components/Login/LoginTemplateM";
import { TERMS_DATA } from "../../utils/TermsData";
import { Bootpay } from "@bootpay/client-js";
import { useRecoilState } from "recoil";
import { authSelect } from "../../recoil/AtomConfig";
import { NormalReqApi } from "../../utils/ReqApi";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
import {
  HiCheck,
  HiCheckCircle,
  HiOutlineRefresh,
  HiRefresh,
} from "react-icons/hi";

const phoneRegExp = /^[0-9]{8,}$/;
const passwordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
const emailRegExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const APID = process.env.REACT_APP_APID;
const AUTHID = process.env.REACT_APP_AUTHID;

const SignupMobile2 = () => {
  const { type } = useParams();

  const navi = useNavigate();
  useEffect(() => {
    if (type * 1 === 2) {
      navi(-1);
      //   navi("/signupcomp");
    }
  }, [type]);

  const temrs = TERMS_DATA.filter((f) => f.type === "normal");
  const [step2, setStep2] = useState(false);
  const [ts, setTs] = useState({
    index: 0,
    open: false,
  });
  const [auth, setAuth] = useRecoilState(authSelect);
  const [check, setCheck] = useState({
    id: false,
    nick: false,
  });
  const [selectTerm, setSelectTerm] = useState([]);

  const idRef = useRef(null);
  const nameRef = useRef(null);
  const nickNameRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);

  const toggleTs = (index) => {
    setTs({
      index: index,
      open: !ts.open,
    });
  };

  const handleTerms = (array, index, e) => {
    const checkArray = array.filter((f, i) => i === index)[0];
    if (e.target.checked) {
      const template = {
        id: checkArray.id,
        label: checkArray.label,
      };
      setSelectTerm([...selectTerm, { ...template }]);
    } else {
      setSelectTerm([...selectTerm.filter((f) => f.id !== checkArray.id)]);
    }
  };

  const handleValid = async () => {
    let termPass = true;
    temrs.map((item, index) => {
      if (item.required) {
        const temp = selectTerm.filter((f) => f.id === item.id);
        if (temp.length === 0) {
          termPass = false;
        }
      }
    });
    if (!termPass) {
      alert("필수 약관에 동의해주시기 바랍니다.");
      return false;
    }
    const name = nameRef.current.value;
    const phone = phoneRef.current.value;
    if (!name || !phone) {
      alert("이름과 연락처를 입력해주시기 바랍니다.");
      return false;
    }

    try {
      const response = await Bootpay.requestAuthentication({
        application_id: APID,
        pg: "다날",
        order_name: "본인인증",
        authentication_id: AUTHID,
        user: {
          username: name,
          phone: phone,
        },
      });

      switch (response.event) {
        case "done":
          const { receipt_id, status } = response.data;
          if (status == 12) {
            const authResult = await NormalReqApi(
              "get",
              `/bp/auth/${receipt_id}/${phone}`
            );
            if (!authResult.flag) {
              alert("이미 가입되어있는 사용자 입니다.");
              return false;
            } else {
              setAuth((prev) => {
                const temp = { ...prev };
                temp.birth = authResult.data.birth;
                temp.di = authResult.data.di;
                temp.gender = authResult.data.gender;
                temp.name = authResult.data.name;
                temp.phone = phone;
                temp.unique = authResult.data.unique;
                return { ...temp };
              });
              setStep2(true);
            }
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e.message);
      switch (e.event) {
        case "cancel":
          // 사용자가 결제창을 닫을때 호출
          console.log(e.message);
          break;
        case "error":
          // 결제 승인 중 오류 발생시 호출
          console.log(e.error_code);
          break;
      }
    }
  };

  const checkId = async () => {
    const email = idRef.current.value;
    if (!email) {
      alert("아이디를 입력해주시기 바랍니다");
      return false;
    }
    const result = await NormalReqApi(
      "get",
      `/user/check/dupli?type=id&value=${email}`
    );
    if (result.flag) {
      setCheck({
        ...check,
        id: true,
      });
    } else {
      alert("이미 사용중인 아이디 입니다");
      setCheck({
        ...check,
        id: false,
      });
      return false;
    }
  };

  const checkNick = async () => {
    const nick = nickNameRef.current.value;
    if (!nick) {
      alert("닉네임을 입력해주시기 바랍니다.");
      return false;
    }
    const result = await NormalReqApi(
      "get",
      `/user/check/dupli?type=nick&value=${nick}`
    );
    if (result.flag) {
      setCheck({
        ...check,
        nick: true,
      });
    } else {
      setCheck({
        ...check,
        nick: false,
      });
    }
  };

  const checkValid = (email, name, nickName, phone, password) => {
    let resultFlag = true;
    if (!phoneRegExp.test(phone)) {
      alert("연락처를 올바르게 입력해주시기 바랍니다");
      resultFlag = false;
      return resultFlag;
    }
    if (!passwordRegExp.test(password)) {
      alert(
        "비밀번호는 특수문자,영문자,숫자를 혼합하여 8자리 이상 입력해주시기 바랍니다"
      );
      resultFlag = false;
      return resultFlag;
    }
    if (!email) {
      alert("아이디를 기재해주시기 바랍니다.");
      resultFlag = false;
      return resultFlag;
    }
    if (!emailRegExp.test(email)) {
      alert(
        "입력하신 이메일 주소를 확인해주시기 바랍니다.(띄어쓰기 및 이메일에 사용할 수 없는 특수문자 금지)"
      );
      resultFlag = false;
      return resultFlag;
    }
    if (!name) {
      alert("올바르지 않은 접근입니다. 새고로침 후 다시 이용해주시기 바랍니다");
      resultFlag = false;
      return resultFlag;
    }
    if (!nickName) {
      alert("닉네임을 입력해주시기 바랍니다.");
      resultFlag = false;
      return resultFlag;
    }
    return resultFlag;
  };

  const handleSubmit = async () => {
    if (!check.id || !check.nick) {
      alert("아이디 및 닉네임 중복검사를 해주시기 바랍니다");
      return false;
    }
    const email = idRef.current.value;
    const name = nameRef.current.value;
    const nickName = nickNameRef.current.value;
    const phone = phoneRef.current.value;
    const password = passwordRef.current.value;

    const isPass = checkValid(email, name, nickName, phone, password);
    if (!isPass) {
      return false;
    }

    const template = {
      email,
      name,
      nickName,
      phone,
      password,
      userType: "normal",
      birth: auth.birth,
      gender: auth.gender,
      di: auth.di,
      unique: auth.unique,
    };

    const response = await NormalReqApi("post", "/user/signup", {
      ...template,
    });
    if (response.statusCode === 200) {
      if (response.data) {
        alert("성공적으로 가입되었습니다. 로그인 페이지로 이동합니다.");
        setAuth((prev) => {
          const temp = { ...prev };
          temp.name = "";
          temp.di = "";
          temp.birth = "";
          temp.gender = "";
          temp.unique = "";
          return { ...temp };
        });
        navi("/mlogin");
      } else {
        alert("가입 중 문제가 발생했습니다. 다시한번 시도해주시기 바랍니다.");
        return false;
      }
    }
  };

  return (
    <SignupWrap>
      <div className={"indexLabel"}>
        <label>{type * 1 === 1 ? "개인회원 가입" : "기업회원 가입"}</label>
      </div>
      <AnimateHeight duration={300} height={step2 ? 0 : "auto"}>
        <>
          <section className={"section sectionTerms"}>
            <h4>대티즌 회원 약관 동의</h4>
            <ul className={"customTerms"}>
              {temrs.map((item, index) => (
                <li key={`terms_item_${index}`} className={"customTermsItem"}>
                  <div className={"customTermsTitle"}>
                    <span onClick={() => toggleTs(index)}>{item.label}</span>
                    <label className={"termsWrap"}>
                      <input
                        type="checkbox"
                        onChange={(e) => handleTerms(temrs, index, e)}
                      ></input>
                      <span className={"termsCheck"}></span>
                    </label>
                  </div>

                  <AnimateHeight
                    duration={300}
                    height={ts.open && ts.index === index ? "200px" : 0}
                  >
                    <div
                      className={"customTermsText"}
                      dangerouslySetInnerHTML={{ __html: temrs[ts.index].term }}
                    ></div>
                  </AnimateHeight>
                </li>
              ))}
            </ul>
          </section>
          <section className="section sectionPreInput">
            <div className={"customInputs"}>
              <label>이름</label>
              <div className={"customAddOn"}>
                <input type="text" ref={nameRef} className={"ww100"} />
              </div>
            </div>
            <div className={"customInputs"}>
              <label>연락처</label>
              <div className={"customAddOn"}>
                <input type="text" ref={phoneRef} className={"ww100"} />
              </div>
            </div>
          </section>
          <section className={"section sectionBootPay"}>
            <button type="button" onClick={handleValid}>
              본인인증 (PASS)
            </button>
          </section>
        </>
      </AnimateHeight>
      <AnimateHeight duration={500} height={step2 ? "auto" : 0}>
        <>
          <section className={"section sectionSignUpFinal"}>
            <div className={"indexLabel"}>
              <label>정보입력</label>
            </div>
            <div className={"customInputGroup"}>
              <div className={"customInputs"}>
                <label>
                  아이디<small>(이메일)</small>
                </label>
                <div className={"customAddOn"}>
                  <input
                    type="text"
                    className={"ww80"}
                    ref={idRef}
                    disabled={check.id}
                  />
                  <button type="button">
                    {check.id ? (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <HiCheckCircle
                          color="green"
                          size={20}
                          style={{ marginRight: ".1em" }}
                        />
                        <HiOutlineRefresh
                          color="orange"
                          size={20}
                          onClick={() => setCheck({ ...check, id: false })}
                        />
                      </span>
                    ) : (
                      <span onClick={checkId}>중복확인</span>
                    )}
                  </button>
                </div>
              </div>
              <div className={"customInputs"}>
                <label>이름</label>
                <div className={"customAddOn"}>
                  {step2 && (
                    <input
                      type="text"
                      defaultValue={auth.name}
                      className={"ww100"}
                    />
                  )}
                </div>
              </div>
              <div className={"customInputs"}>
                <label>닉네임</label>
                <div className={"customAddOn"}>
                  <input
                    type="text"
                    className={"ww80"}
                    ref={nickNameRef}
                    disabled={check.nick}
                  />
                  <button type="button">
                    {check.nick ? (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <HiCheckCircle
                          color="green"
                          size={20}
                          style={{ marginRight: ".1em" }}
                        />
                        <HiOutlineRefresh
                          color="orange"
                          size={20}
                          onClick={() => setCheck({ ...check, nick: false })}
                        />
                      </span>
                    ) : (
                      <span onClick={checkNick}>중복확인</span>
                    )}
                  </button>
                </div>
              </div>
              <div className={"customInputs"}>
                <label>연락처</label>
                <div className={"customAddOn"}>
                  {step2 && (
                    <input
                      type="text"
                      defaultValue={phoneRef.current.value}
                      disabled={true}
                      className={"ww100"}
                    />
                  )}
                </div>
              </div>
              <div className={"customInputs"}>
                <label>비밀번호</label>
                <div className={"customAddOn"}>
                  <input
                    type="password"
                    ref={passwordRef}
                    className={"ww100"}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className={"section customSubmit"}>
            <button type="button" onClick={handleSubmit}>
              <span>가입하기</span>
            </button>
          </section>
        </>
      </AnimateHeight>
    </SignupWrap>
  );
};

export default SignupMobile2;
