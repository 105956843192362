import React, { useEffect, useState } from "react";
import {
  ErrorText,
  EventOptionsElement,
  LabelCategory,
  LabelElement,
  LabelInputElement,
  LabelSelectElement,
  LabelSelectObjectElement,
  LabelTextElement,
  TargetControl,
} from "./FreeElement";
import styled from "styled-components";
import { Datepicker } from "../../Common/Datepicker";
import Editor from "../../Common/Editor";
import { useRecoilState } from "recoil";
import { commonSelect } from "../../../recoil/AtomConfig";

const CheckBoxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckBox = styled.div`
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border: ${(props) => (props.checked ? "none" : "solid 0.1rem #dddddd")};
  background: ${(props) => (props.checked ? "black" : "white")};
  border-radius: 0.4rem;
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const HiddenCheckBox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
export const ManagerController = ({ register, errors, setValue }) => {
  const [check, setCheck] = useState(false);
  useEffect(() => {
    setValue("manager.emailYn", check);
  }, [check]);
  return (
    <div className={"regist_area contest"}>
      <h2 className={"sub_title"}>
        <span>담당자 정보</span>
      </h2>
      <ul>
        <li>
          <LabelInputElement
            label="이름"
            error={errors.manager?.mName}
            require={true}
          >
            <input
              type="text"
              className={"input_text w100"}
              {...register("manager.mName")}
            />
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement
            label="연락처"
            error={errors.manager?.mPhone}
            require={true}
          >
            <input
              type="text"
              className={"input_text w100"}
              {...register("manager.mPhone")}
            />
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement
            label="이메일"
            error={errors.manager?.mEmail}
            require={true}
          >
            <input
              type="text"
              className={"input_text w100"}
              {...register("manager.mEmail")}
            />
            <ul
              className="agreement"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <li>
                <CheckBoxContainer>
                  <HiddenCheckBox
                    type={"radio"}
                    checked={check}
                    onChange={() => setCheck(!check)}
                    {...register("manager.emailYn")}
                  />
                  <StyledCheckBox
                    checked={check}
                    onClick={() => setCheck(!check)}
                  >
                    <Icon viewBox="0 0 24 24">
                      <polyline points="19 7 10 17 5 12" />
                    </Icon>
                  </StyledCheckBox>
                </CheckBoxContainer>
                <span className="agree_title" style={{ marginLeft: "0.3em" }}>
                  이메일 수신 동의
                </span>
              </li>
            </ul>
          </LabelInputElement>
        </li>
      </ul>
    </div>
  );
};

export const FreeBasicInfo = ({ register, errors, setValue }) => {
  const [common] = useRecoilState(commonSelect);
  const [curDate, setCurDate] = useState();
  const [curEndDate, setCurEndDate] = useState();
  const [categoryType, setCategoryType] = useState(0);

  const handleTypeChange = (e) => {
    setCategoryType(e.target.value * 1);
  };

  useEffect(() => {
    if (curDate) {
      setValue("info.startDate", curDate);
    }
    if (curEndDate) {
      setValue("info.endDate", curEndDate);
    }
  }, [curDate, curEndDate]);

  return (
    <div className={"regist_area contest"}>
      <h2 className="sub_title">
        <span>공모전·대외활동 정보</span>
      </h2>
      <ul>
        <li>
          <LabelTextElement
            label="공모명"
            require={true}
            error={errors.info?.title}
            className="input_text w100"
            placeholder={""}
            formName={"info.title"}
            register={register}
          />
        </li>
        <li>
          <LabelSelectElement
            label="구분"
            require={true}
            className="slt_inCharge w30"
            formName="info.type"
            register={register}
            onChange={handleTypeChange}
            options={[
              { code: 0, text: "공모전" },
              { code: 1, text: "대외활동" },
            ]}
          />
        </li>
        <li>
          <LabelCategory
            require={true}
            label="분야"
            setValue={setValue}
            categoryType={categoryType}
            data={common.category}
            loading={common.loading}
            category={common.category}
          />
        </li>
        <li>
          <LabelInputElement
            require={true}
            label="접수기간"
            subClassName={"input_area customdp w80"}
          >
            <Datepicker parseDateToString={setCurDate} type={"start"} />
            <span className="dash">-</span>
            <Datepicker
              minDate={curDate}
              parseDateToString={setCurEndDate}
              type={"end"}
            />
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement label="대상" require={true}>
            <TargetControl
              loading={common.loading}
              target={common.target}
              setValue={setValue}
            />
          </LabelInputElement>
        </li>
        <li>
          <LabelSelectObjectElement
            require={true}
            label="총 시상금 범위"
            className={"slt_inCharge w30"}
            setValue={setValue}
            formName={"info.totalReward"}
            type="totalReward"
            loading={common.loading}
            options={common.totalReward
              .filter((f) => f.type === categoryType + 1)
              .sort((a, b) => (a.text > b.text ? 1 : -1))}
          />
        </li>

        <li>
          <LabelSelectObjectElement
            require={true}
            label="주최사 구분"
            className={"slt_inCharge w30"}
            setValue={setValue}
            formName={"info.host"}
            type={"host"}
            loading={common.loading}
            options={common.host}
          />
        </li>
        <li>
          <LabelTextElement
            label="주최사"
            require={true}
            className={"input_text w100"}
            placeholder="제주특별자치도 관광협회"
            register={register}
            error={errors.info?.hostname}
            formName={"info.hostname"}
          />
        </li>
        <li>
          <LabelSelectElement
            require={true}
            label="지역"
            className={"slt_inCharge w30"}
            formName={"info.regions"}
            register={register}
            loading={common.loading}
            options={common.region}
          />
        </li>

        <li>
          <LabelTextElement
            label="시상내역"
            className={"input_text w100"}
            placeholder="우수상 (2명)상금 300만원, 장려상(3명) 상금 50만원"
            formName={"info.reward"}
            register={register}
          />
        </li>
        <li>
          <LabelTextElement
            label="홈페이지"
            className={"input_text w100"}
            placeholder="https:// 반드시 포함"
            formName="info.homepage"
            register={register}
          />
        </li>
        <li>
          <LabelInputElement
            label="포스터"
            error={errors.file?.thumb}
            require={true}
          >
            <input
              type="file"
              className="input_text w100"
              accept=".jpg,.png"
              {...register("file.thumb")}
            />
            <p style={{ fontSize: "0.65em" }}>
              (사이즈 700 * 1000 px / jpg,png 형식만 가능)
            </p>
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement label="첨부파일" error={errors.file?.attachment}>
            <input
              type="file"
              className="input_text w100"
              accept=".zip,.rar,.egg,.arg"
              {...register("file.attachment")}
            />
            <p style={{ fontSize: "0.65em" }}>
              (5MB 이내 / zip,rar,egg,arg 파일만 가능)
            </p>
          </LabelInputElement>
        </li>
        <li>
          <LabelInputElement label="태그" error={errors.info?.tags}>
            <input
              type="text"
              className="input_text w100"
              placeholder=",(쉼표) 로 구분"
              accept=".jpg"
              {...register("info.tags")}
            />
          </LabelInputElement>
        </li>
      </ul>
    </div>
  );
};

export const FreeEditorInfo = ({ setValue, watch }) => {
  const handleChange = (editor, type) => {
    const temp = editor.getText().replaceAll(" ", "").replaceAll("\n", "");
    if (temp.length !== 0) {
      setValue(type, editor.getHTML());
    }
  };

  //   console.log(watch("cSubject"));

  return (
    <div className={"regist_area contest"}>
      <h2 className={"sub_title"}>
        <span>공모전·대외활동 요강</span>
      </h2>
      <ul>
        <li>
          <LabelElement label="주제" require={true}>
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cSubject"
              value={watch("editor.cSubject")}
            />
          </LabelElement>
        </li>

        <li>
          <LabelElement label="기간 및 일정" require={true}>
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cPeriod"
              value={watch("editor.cPeriod")}
            />
          </LabelElement>
        </li>
        <li>
          <LabelElement label="참가 대상" require={true}>
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cTarget"
              value={watch("editor.cTarget")}
            />
          </LabelElement>
        </li>
        <li>
          <LabelElement label="시상내역(혜택)" require={true}>
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cReward"
              value={watch("editor.cReward")}
            />
          </LabelElement>
        </li>
        <li>
          <LabelElement label="활동내용">
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cContents"
              value={watch("editor.cContents")}
            />
          </LabelElement>
        </li>
        <li>
          <LabelElement label="참가방법" require={true}>
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cMethod"
              value={watch("editor.cMethod")}
            />
          </LabelElement>
        </li>
        <li>
          <LabelElement label="작품규격">
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cStandard"
              value={watch("editor.cStandard")}
            />
          </LabelElement>
        </li>
        <li>
          <LabelElement label="문의처" require={true}>
            <Editor
              className={"w80"}
              onChange={handleChange}
              type="editor.cDesk"
              value={watch("editor.cDesk")}
            />
          </LabelElement>
        </li>
      </ul>
    </div>
  );
};

export const FreeEventInfo = ({ register, errors, setValue }) => {
  const [curDate, setCurDate] = useState();
  const [curEndDate, setCurEndDate] = useState();
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setValue("info.dupliYn", check);
  }, [check]);

  useEffect(() => {
    if (curDate) {
      setValue("date.startDate", curDate);
    }
    if (curEndDate) {
      setValue("date.endDate", curEndDate);
    }
  }, [curDate, curEndDate]);

  return (
    <ul>
      <li>
        <LabelTextElement
          label={"제목"}
          error={errors.info?.title}
          className={"input_text w100"}
          placeholder="이벤트 제목을 입력해주세요"
          formName={"info.title"}
          register={register}
        />
      </li>
      <li>
        <LabelInputElement
          label={"이벤트 기간"}
          subClassName={"input_area customdp w80"}
        >
          <Datepicker parseDateToString={setCurDate} type="start" />
          <span className="dash">-</span>
          <Datepicker
            minDate={curDate}
            parseDateToString={setCurEndDate}
            type={"end"}
          />
        </LabelInputElement>
      </li>
      <li>
        <LabelTextElement
          label="혜택"
          error={errors.info?.reward}
          className={"input_text w100"}
          placeholder={"이벤트 혜택을 입력해주시기 바랍니다."}
          formName={"info.reward"}
          register={register}
        />
      </li>
      <li>
        <LabelTextElement
          label="참가자격"
          error={errors.info?.target}
          className={"input_text w100"}
          placeholder={"참가자격을 입력해주시기 바랍니다."}
          formName={"info.target"}
          register={register}
        />
      </li>
      <li>
        <LabelInputElement label="접수기간">
          <input
            type="datetime-local"
            className={"input_text w30"}
            {...register("date.submitStartDate")}
          />
          <span className={"dash"}>-</span>
          <input
            type="datetime-local"
            className="input_text w30"
            {...register("date.submitEndDate")}
          />
        </LabelInputElement>
      </li>
      <li>
        <LabelInputElement label={"중복접수 허용"}>
          <CheckBoxContainer>
            <HiddenCheckBox
              type={"radio"}
              checked={check}
              onChange={() => setCheck(!check)}
              {...register("info.dupliYn")}
            />
            <StyledCheckBox checked={check} onClick={() => setCheck(!check)}>
              <Icon viewBox="0 0 24 24">
                <polyline points="19 7 10 17 5 12" />
              </Icon>
            </StyledCheckBox>
          </CheckBoxContainer>
        </LabelInputElement>
      </li>
      <li>
        <LabelInputElement label="썸네일 이미지" error={errors.file?.thumb}>
          <input
            type="file"
            className="input_text w100"
            accept=".jpg,.png"
            {...register("file.thumb")}
          />
        </LabelInputElement>
      </li>
    </ul>
  );
};

export const FreeEventEditor = ({ setValue, watch }) => {
  const handleChange = (editor, type) => {
    setValue(type, editor.getHTML());
  };
  return (
    <ul>
      <li className="text_editor">
        <label className="input_title w20">내용</label>
        <Editor
          onChange={handleChange}
          type="editor.content"
          value={watch("editor.content")}
        />
      </li>
    </ul>
  );
};

const PrevSubmit = styled.ul`
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
`;
const PrevSubmitLi = styled.li`
  position: relative;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  margin: 1em 1em 1em 0em;
  padding: 1em;
`;
const PrevClose = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  color: red;
  cursor: pointer;
`;

export const FreeEventReq = ({ setValue, errors }) => {
  const TEMPLATE = {
    requiredYn: true,
    ordered: 0,
    title: "",
    inputType: 0,
    content: [{ ordered: 0, text: "" }],
  };
  const [sData, setSData] = useState([]);
  // const handleDecrease = (index) => {
  //   console.log(index);
  //   console.log(sData.filter((f) => f.ordered !== index));
  //   setSData([...sData.filter((f) => f.ordered !== index)]);
  // };

  // useEffect(() => {
  //   if (sData.length > 0) {
  //     setValue("submitInfo", sData);
  //   }
  //   console.log(sData);
  // }, [sData]);
  const handleSDataChange = (ordered, data) => {
    if (sData.length > 0) {
      const originData = sData.filter((f) => f.ordered !== ordered);
      const nArray = [...originData, data];

      setSData([...nArray.sort((a, b) => a.ordered - b.ordered)]);
    } else {
      setSData([data]);
    }
  };

  const handleDecrease = (ordered) => {
    setSData([...sData.filter((f) => f.ordered !== ordered)]);
  };

  useEffect(() => {
    setValue("submitInfo", sData);
  }, [sData]);

  return (
    <ul>
      <li>
        <label className={"input_title w20"}>접수선택</label>
        <div className={"form_area w80"}>
          <div>
            <PrevSubmit>
              {sData.length > 0 &&
                sData.map((item, index) => (
                  <PrevSubmitLi key={`prev_index_${index}`}>
                    <PrevClose onClick={() => handleDecrease(item.ordered)}>
                      x
                    </PrevClose>
                    <p>항목 #{index + 1} </p>
                    <p>
                      필수여부 :{" "}
                      {item.requiredYn === true ? "필수" : "필수 아님"}
                    </p>
                    <p>제목 : {item.title}</p>
                    <p>
                      입력형태 :{" "}
                      {item.inputType === 0 ? "텍스트 입력" : "라디오 버튼"}
                    </p>
                  </PrevSubmitLi>
                ))}
            </PrevSubmit>
          </div>
          <div className={"form-group"}>
            <EventOptionsElement
              ordered={
                sData.length > 0 ? sData[sData.length - 1].ordered + 1 : 0
              }
              index={sData.length + 1}
              toggle={handleSDataChange}
            />
            {/* {sData.map((item, index) => (
              
            ))} */}
          </div>
          {errors.submitInfo && (
            <ErrorText>{errors.submitInfo?.message}</ErrorText>
          )}
        </div>
      </li>
    </ul>
  );
};
