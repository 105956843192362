// listTemplate
// [
//     {file:Object, type:String, objectTarget:String}
// ]

import { uploadFile } from "./AwsS3Upload";

export const handleFileUpload = async (list, submitDataObject) => {
  if (list.length > 0) {
    for (const item of list) {
      const uploadResult = await uploadFile(item.file, item.type);
      if (uploadResult.success) {
        const { origin, path, size } = uploadResult;

        const targetSplit = item.objectTarget.split(".");
        if (targetSplit.length > 1) {
          submitDataObject[targetSplit[0]][targetSplit[1]] = {
            origin,
            path,
            size,
          };
        } else {
          submitDataObject[targetSplit] = {
            origin,
            path,
            size,
          };
        }
      } else {
        return false;
      }
    }
    return submitDataObject;
  } else {
    return false;
  }
};

export const handleFileUploadList = async (fileList) => {
  const templateArray = [];
  for (const file of fileList) {
    const uploadResult = await uploadFile(file.file[0], "contents");
    if (uploadResult.success) {
      const { origin, path, size } = uploadResult;
      const newPath = path.replace("contents/", "");
      templateArray.push({ origin, path: newPath, size });
    }
  }
  return templateArray;
};
