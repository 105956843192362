import styled from "styled-components";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { createBrowserHistory } from "history";
import history from "../../components/DContents/Common/history";

export const BeforeLeaveOut = () => {
  const router = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const [locationKeys, setLocationKeys] = useState([]);

  // useEffect(() => {
  //   return history.listen((location) => {
  //     if (history.action === "PUSH") {
  //       console.log("history ==== push");
  //       setLocationKeys([location.key]);
  //     }
  //     if (history.action === "POP") {
  //       console.log("history ==== pop");
  //       if (locationKeys[1] === location.key) {
  //         setLocationKeys(([_, ...keys]) => keys);
  //       } else {
  //         setLocationKeys((keys) => [location.key, ...keys]);
  //         history.push("/signin");
  //       }
  //     }
  //   });
  // }, [locationKeys, history]);
  useEffect(() => {
    console.log("page in==========");
    const processLeaveOut = () => {
      setAccount((prev) => {
        const temp = { ...prev };
        temp.id = undefined;
        temp.accessToken = null;
        temp.role = undefined;
        temp.refreshToken = null;
        return { ...temp };
      });
      router("/");
    };
    const timeout = setTimeout(processLeaveOut, 2000);

    return () => {
      console.log("page out==========");
      clearTimeout(timeout);
      console.log(history);
      history.listen(({ action, location }) => {
        console.log(location);
        if (location.pathname === "/prepare" && action === "POP") {
          router("/signin");
        }
      });
    };
  }, []);
  return (
    <PrePareLayout>
      <PrepareSection>
        <PrePareMessage>대티즌을 이용해 주셔서 감사드립니다</PrePareMessage>
      </PrepareSection>
      <PrepareSection>
        <PrePareMessageSub>메인 페이지로 이동합니다</PrePareMessageSub>
        <Loading />
      </PrepareSection>
    </PrePareLayout>
  );
};

const PrePareLayout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PrepareSection = styled.div`
  padding: 0.5em 0;
  text-align: center;
`;

const PrePareMessage = styled.p`
  color: white;
`;
const PrePareMessageSub = styled.p`
  color: white;
  font-size: 0.55em;
  padding: 0.5em 0;
`;
