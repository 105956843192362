import React, { Suspense, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountSelect, commonSelect } from "../../recoil/AtomConfig";
import contestStyle from "../../assets/css/contest.module.scss";
import Default350 from "../../assets/img/common/img_posterDefault_350.jpg";
import ContestInfo from "../../components/Contest/ContestInfo";
import ErrorBoundary from "../../components/Common/ErrorBoundary";
import { NormalReqApi } from "../../utils/ReqApi";
import replyStyle from "../../assets/css/reply.module.scss";
import ReplyComponent from "../../components/Reply/ReplyComponent";

const MoveLoginPage = () => {
  const navi = useNavigate();
  useEffect(() => {
    alert("로그인이 만료되었습니다. 다시 로그인 해주시기 바랍니다.");
    const timer = setTimeout(() => navi("/signin"), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return <div>로그인 페이지로 이동합니다.</div>;
};

const ContestDetail = () => {
  const { id, type } = useParams();
  const location = useLocation();

  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const [common, setCommon] = useRecoilState(commonSelect);

  const getCommon = async () => {
    if (common.loading) {
      const res = await NormalReqApi("get", "/basic");

      if (res && res.statusCode === 200) {
        setCommon((prev) => {
          const temp = { ...prev };
          temp.loading = false;
          temp.category = [...res.data.category];
          temp.host = [...res.data.host];
          temp.totalReward = [...res.data.totalReward];
          temp.target = [...res.data.target];
          temp.region = [...res.data.region];
          return { ...temp };
        });
      }
    }
  };
  console.log(type);

  useEffect(() => {
    getCommon();
    return () => {
      getCommon();
    };
  }, [id]);

  const goLoginPage = () => {
    navi("/signin");
  };
  const goBackPage = () => {
    navi(-1);
  };

  const qnaRef = useRef(null);
  const handleCheckRef = () => {
    qnaRef.current.scrollIntoView({ behavior: "smooth" });
    // window.scrollTo(0, qnaRef.current.clientHeight);
  };

  return (
    <div className={"container sub"}>
      <div
        className={`subVisual contestTop ${contestStyle.subVisual}`}
        style={{ height: "3.5em" }}
      ></div>

      <div className={`sub_container ${contestStyle.sub_container}`}>
        {/* detail wrap  */}

        <Suspense
          fallback={
            <div style={{ width: "100%", height: "100vh" }}>loading...</div>
          }
        >
          <ErrorBoundary fallback={<MoveLoginPage />}>
            <ContestInfo
              id={id}
              goBackPage={goBackPage}
              qnaToggle={handleCheckRef}
              isOnChain={type === "2"}
            />
          </ErrorBoundary>
        </Suspense>
        <div className={`contents contest_area ${contestStyle.contents}`}>
          <div
            className={`contest_view ${contestStyle.contest_view}`}
            ref={qnaRef}
          >
            <ReplyComponent id={id} type="contest" />

            <div className={"btn_area"}>
              <a className={"btn go_back"} onClick={goBackPage}>
                목록으로
              </a>
            </div>
          </div>
        </div>

        {/* reply wrap */}

        {/* button area */}
      </div>
    </div>
  );
};

export default ContestDetail;

// <div className={replyStyle.wrap}>
//             <div className={replyStyle.inputWrap}>
//               <div className={replyStyle.labelArea}>
//                 <label className={replyStyle.label}>공모전 Q&A</label>
//               </div>

//               <div className={replyStyle.inputArea}>
//                 <textarea
//                   className={replyStyle.text}
//                   placeholder={"해당 공모전/대외활동에 궁금한 점을 적어주세요!"}
//                 ></textarea>
//                 <button type="button" className={replyStyle.submit}>
//                   등록
//                 </button>
//               </div>
//             </div>
//             <div className={replyStyle.listWrap}>
//               <div className={replyStyle.countArea}>
//                 <label>
//                   총 <span>0</span>건
//                 </label>
//                 <HiChevronUp color={"#a8a8a8"} />
//               </div>
//               <ul className={replyStyle.itemArea}>
//                 {Array.from(new Array(5), (el, index) => (
//                   <li className={replyStyle.item} key={`reply_${index}`}>
//                     <div className={replyStyle.nameArea}>
//                       <label>abc123</label>
//                       <div className={replyStyle.btnArea}>
//                         <div className={replyStyle.btn}>
//                           <span>추천</span>
//                           <span>0</span>
//                         </div>
//                         <div className={replyStyle.btn}>
//                           <span>비추천</span>
//                           <span>0</span>
//                         </div>
//                         <div className={replyStyle.btn}>
//                           <HiOutlineDotsVertical />
//                         </div>
//                       </div>
//                     </div>
//                     <div className={replyStyle.textArea}>
//                       <p>
//                         sdfsdfweafe weafsdefnikwne
//                         ilwaneifwnefaasdbnfkdhnfoawilefiu
//                         nawkufbdksufbkwajbnfaadwuf bukb sdfsdf wefwefwe sdf
//                       </p>
//                       <p className={replyStyle.date}>2023-01-12 13:44</p>
//                     </div>
//                     <div className={replyStyle.subReply}>
//                       <div className={replyStyle.subStatus}>
//                         <div className={replyStyle.subCount}>
//                           <label>
//                             답글 <span>0</span>
//                           </label>
//                           <HiChevronDown />
//                         </div>

//                         <span className={replyStyle.addSubReply}>
//                           답글 쓰기
//                         </span>
//                       </div>
//                       {Array.from(new Array(4), (el, sIndex) => (
//                         <div
//                           className={replyStyle.subItem}
//                           key={`reply_sub_${sIndex}`}
//                         >
//                           <div className={replyStyle.nameArea}>
//                             <label>
//                               <HiChatAlt2 />
//                               <span>abc123</span>
//                             </label>
//                             <div className={replyStyle.btnArea}>
//                               <div className={replyStyle.btn}>
//                                 <span>추천</span>
//                                 <span>0</span>
//                               </div>
//                               <div className={replyStyle.btn}>
//                                 <span>비추천</span>
//                                 <span>0</span>
//                               </div>
//                               <div className={replyStyle.btn}>
//                                 <HiOutlineDotsVertical />
//                               </div>
//                             </div>
//                           </div>
//                           <div className={replyStyle.textArea}>
//                             <p>
//                               sdfsdfweafe weafsdefnikwne
//                               ilwaneifwnefaasdbnfkdhnfoawilefiu
//                               nawkufbdksufbkwajbnfaadwuf bukb sdfsdf wefwefwe
//                               sdf
//                             </p>
//                             <p className={replyStyle.date}>2023-01-12 13:44</p>
//                           </div>
//                           <div className={replyStyle.subReply}>
//                             <div
//                               className={
//                                 replyStyle.subStatus + " " + replyStyle.nomore
//                               }
//                             >
//                               <span className={replyStyle.addSubReply}>
//                                 답글 쓰기
//                               </span>
//                             </div>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//           <div className={"btn_area"}>
//             <a className={"btn go_back"} onClick={goBackPage}>
//               목록으로
//             </a>
//           </div>
