import React, { Suspense, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DContentsSubMenu } from "../../components/DContents/Common/Element";
import custom from "../../assets/css/event.module.scss";
import EventViewPC from "../../components/DContents/Event/EventViewPC";
import ReplyComponent from "../../components/Reply/ReplyComponent";
import EventHeader from "../../components/events/header";

const EventView = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [replyYn, setReplyYn] = useState(false);

  return (
    <div className={"container sub"}>
      <EventHeader />
      <div
        className={`sub_container channel event ${custom.sub_container} ${custom.event}`}
      >
        <h2 className={`index_title ${custom.index_title}`}>
          <span>이벤트</span>
        </h2>
        <section className={`section list ${custom.section} ${custom.list}`}>
          <div className={`event_list ${custom.event_list}`}>
            <div
              className={`board_view event ${custom.board_view} ${custom.event}`}
            >
              <Suspense fallback={<div></div>}>
                <EventViewPC id={id} toggleReply={setReplyYn} />
              </Suspense>

              <div className={`answer_area`}>
                {/* <ReplyComponents
                  replyResultList={replyList}
                  session={session}
                  data={data}
                  pageType={pageType}
                /> */}

                <div className="btn_area board">
                  <a className="btn" onClick={() => navi(-1)}>
                    목록으로
                  </a>
                </div>
              </div>
            </div>
            {replyYn && <ReplyComponent id={id} type={"event"} />}
          </div>
        </section>
      </div>
    </div>
  );
};

export default EventView;
