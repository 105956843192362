import React, { useRef } from "react";
import custom from "../../assets/css/modal.module.scss";
import { NormalReqApi } from "../../utils/ReqApi";
import DaumPostcode from "react-daum-postcode";

export const standard = {
  "01": "부가가치세 일반과세자",
  "02": "부가가치세 간이과세자",
  "03": "부가가치세 과세특례자",
  "04": "부가가치세 면세사업자",
  "05": "수익사업을 영위하지 않는 비영리법인이거나 고유번호가 부여된 단체,국가기관 등",
  "06": "고유번호가 부여된 단체",
  "07": "부가가치세 간이과세자(세금계산서 발급사업자)",
};

export const CompModal = ({ title, toggle, setToggle, callBack }) => {
  const bizRef = useRef();
  const bizName = useRef();
  const bizDate = useRef();
  const loadBizInfo = async (e) => {
    const reqUrl = `https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=${process.env.REACT_APP_OPEN_API_KEY}`;
    const sendData = {
      url: reqUrl,
      b_no: bizRef.current.value,
      p_nm: bizName.current.value,
      start_dt: bizDate.current.value,
    };

    const result = await NormalReqApi("post", "/corp/validate", {
      ...sendData,
    });
    if (result.statusCode === 200) {
      if (result.data.flag) {
        if (result.data.data[0].valid == "01") {
          const validResult = {
            p_nm: sendData.p_nm,
            b_no: sendData.b_no,
            start_dt: sendData.start_dt,
            b_stt_cd:
              result.data.data[0].status.b_stt_cd === "01"
                ? "정상영업"
                : "휴폐업",
            tax_type_cd: standard[result.data.data[0].status.tax_type_cd],
          };
          callBack(validResult);
          setToggle(false);
        } else {
          alert(
            "일치하는 사업자 정보가 없습니다. 입력한 정보를 다시 확인해주시기 바랍니다."
          );
        }
      } else {
        alert("이미 등록된 사용자입니다.");
        return false;
      }
    } else {
    }
  };
  return (
    <div className={custom.customModalWrap + " " + (toggle && custom.active)}>
      <div className={custom.customModalBody}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".5em",
            backgroundColor: "#263050",
          }}
        >
          <span style={{ color: "white" }}>사업자정보 조회</span>
          <button
            type="button"
            style={{
              border: "none",
              color: "white",
              backgroundColor: "unset",
              cursor: "pointer",
            }}
            onClick={() => setToggle(false)}
          >
            x
          </button>
        </div>
        <div className={custom.modalContentBody}>
          <ul>
            <li>
              <div className={custom.contentWrap}>
                <label>법인사업자 번호 (숫자)</label>
                <input
                  type="text"
                  placeholder="'-'을 제외하고 입력해주시기 바랍니다.'"
                  ref={bizRef}
                />
              </div>
            </li>
            <li>
              <div className={custom.contentWrap}>
                <label>대표자 명</label>
                <input
                  type="text"
                  placeholder="사업자등록증에 기재된 대표자명을 기입해주시기 바랍니다."
                  ref={bizName}
                />
              </div>
            </li>
            <li>
              <div className={custom.contentWrap}>
                <label>개업일자(yyyymmdd)</label>
                <input
                  type="text"
                  placeholder="사업자등록증에 기재된 날짜를 기입해주시기 바랍니다."
                  ref={bizDate}
                />
              </div>
            </li>
          </ul>
          <button
            type="button"
            className={custom.searchBtn}
            onClick={loadBizInfo}
          >
            조회
          </button>
        </div>
      </div>
    </div>
  );
};

export const AddressModal = ({ toggle, setToggle, callBack }) => {
  return (
    <div className={custom.customModalWrap + " " + (toggle && custom.active)}>
      <div className={custom.customModalBody}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".5em",
            backgroundColor: "#263050",
          }}
        >
          <span style={{ color: "white" }}>주소검색</span>
          <button
            type="button"
            style={{
              border: "none",
              color: "white",
              backgroundColor: "unset",
              cursor: "pointer",
            }}
            onClick={() => setToggle(false)}
          >
            x
          </button>
        </div>
        <div className={[custom.modalContentBody, custom.address].join(" ")}>
          <AddressComponent
            toggle={toggle}
            setToggle={setToggle}
            callback={callBack}
          />
        </div>
      </div>
    </div>
  );
};

export const AddressComponent = ({ toggle, setToggle, callback }) => {
  const handleComplete = (data) => {
    const result = {
      postCode: data.zonecode,
      address1: data.roadAddress,
      sido: data.sido,
      sigungu: data.sigungu,
    };
    callback(result);
    setToggle(false);
  };

  return <DaumPostcode onComplete={handleComplete} style={{ width: "100%" }} />;
};
