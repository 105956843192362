import moment from "moment";
import React from "react";
import { HiEye } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ContentItem } from "../UserTemplate";

const MyChallenge = ({ link, title, createdAt, viewCnt }) => {
  return (
    <ContentItem>
      <Link to={link}>
        <p className={"ciTitle"}>{title}</p>
        <div className={"ciCntAndDate"}>
          <span className={"ciCnt"}>
            <HiEye /> {viewCnt}
          </span>
          <span className={"ciDate"}>
            {moment(createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
      </Link>
    </ContentItem>
  );
};

export default MyChallenge;
