import React, { Suspense } from "react";

import {
  DContentsSubMenu,
  DContentWeeklySubContainer,
} from "../../components/DContents/Common/Element";
import EventSubmitResultPc from "../../components/DContents/Event/EventSubmitResultPc";
import EventHeader from "../../components/events/header";

const EventSubmitResult = () => {
  return (
    <div className={"container sub"}>
      <EventHeader />
      <DContentWeeklySubContainer label={"이벤트 발표"}>
        <Suspense fallback={<div>test</div>}>
          <EventSubmitResultPc />
        </Suspense>
      </DContentWeeklySubContainer>
    </div>
  );
};

export default EventSubmitResult;
