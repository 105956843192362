import React, { Suspense, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { LoginWrap } from "../../components/Login/LoginTemplateM";
import { accountSelect } from "../../recoil/AtomConfig";
import { LoginPostApi, NormalReqApi } from "../../utils/ReqApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "react-query";
import Slider from "react-slick";
import styled from "styled-components";
import Logo from "../../assets/img/common/logo_w.png";
import { tempGet } from "../../utils/ReqApi";
import { useEffect } from "react";

const SignInMobile = () => {
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const idRef = useRef(null);
  const pwdRef = useRef(null);

  const handleLogin = async () => {
    const id = idRef.current.value;
    const pwd = pwdRef.current.value;
    if (!id || !pwd) {
      alert("아이디와 비밀번호를 입력해주시기 바랍니다");
      return false;
    }

    const template = {
      email: id,
      password: pwd,
    };

    const result = await LoginPostApi("/auth/login", template);
    if (result.status === 200) {
      const { data } = result;
      setAccount((prev) => {
        const temp = { ...prev };
        temp.id = data.data.id;
        temp.accessToken = data.data.accessToken;
        temp.role = data.data.userType === 2 ? "COMPANY" : "NORMAL";
        temp.refreshToken = data.data.refreshToken;
        return { ...temp };
      });
      navi("/");
    } else if (result.status === 204) {
      toast.error(
        <span style={{ fontSize: ".65em" }}>
          아이디/비밀번호를 확인해주세요!
        </span>,
        {
          theme: "colored",
          closeButton: false,
        }
      );
    }
  };
  useEffect(() => {
    // setState((prev) => )
    console.log("loginpage");
    setAccount((prev) => {
      const temp = { ...prev };
      temp.id = undefined;
      temp.accessToken = null;
      temp.role = "";
      temp.refreshToken = null;
      return { ...temp };
    });
    return () => {};
  }, []);

  return (
    <>
      <LoginWrap>
        <div className={"inputInfo"}>
          <div className={"idPwd"}>
            <label>아이디</label>
            <input type="text" ref={idRef} />
          </div>
          <div className={"idPwd"}>
            <label>비밀번호</label>
            <input type="password" ref={pwdRef} />
          </div>
        </div>
        <div className={"inputBtns"}>
          <button
            type="button"
            className={"submitBtns"}
            onClick={() => navi("/msignup")}
          >
            회원가입
          </button>
          <button type="button" className={"submitBtns"} onClick={handleLogin}>
            로그인
          </button>
        </div>
        <div className={"findArea"}>
          <p onClick={() => navi("/findid")}>아이디 찾기</p>
          <p onClick={() => navi("/findpwd")}>비밀번호 찾기</p>
        </div>
        <SlideDiv>
          <Suspense
            fallback={
              <LoginEmptyBox>
                <img src={Logo} />
              </LoginEmptyBox>
            }
          >
            <LoginBannerItems />
          </Suspense>
        </SlideDiv>
      </LoginWrap>
      <ToastContainer
        position="bottom-center"
        theme="light"
        closeOnClick
        hideProgressBar={true}
      />
    </>
  );
};

const LoginBannerItems = () => {
  const route = useNavigate();
  const { data } = useQuery(
    ["loginBanner"],
    async () => await NormalReqApi("get", "/banner/login"),
    // async () => await tempGet("/banner/login"),
    {
      retry: true,
      suspense: true,
    }
  );

  const onBannerClick = (id, type) => {
    route(`/contest/view/${type}/${id}`);
  };

  const slideSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    Infinity: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return data.statusCode === 200 && data.data.length > 0 ? (
    <Slider {...slideSettings}>
      {data.data
        .sort(() => Math.random() - 0.5)
        .map((item, index) => (
          <div
            key={`lb_${index}`}
            onClick={() => onBannerClick(item.contest._id, item.contest.type)}
          >
            <img src={item.thumbnail.path} />
          </div>
        ))}
    </Slider>
  ) : (
    <LoginEmptyBox>
      <img src={Logo} />
    </LoginEmptyBox>
  );
};

const SlideDiv = styled.div`
  margin-top: 20px;
  display: block;
  max-width: 400px;
  width: 100%;

  .slick-slider {
    /* width: 400px; */
    outline: none;

    .slick-slide {
      outline: none !important;

      div {
        outline: none;
      }
    }
    /* width: 300px; */
  }
`;

const LoginEmptyBox = styled.div`
  max-width: 400px;
  width: 100%;
  max-height: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoginBanner = styled.div``;

export default SignInMobile;
