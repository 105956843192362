import DOMPurify from "dompurify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  HiBookmark,
  HiChatAlt2,
  HiCube,
  HiEye,
  HiOutlineBookmark,
  HiUserAdd,
} from "react-icons/hi";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import ReplyMobile2 from "../../components/Reply/ReplyMobile2";
import { accountSelect } from "../../recoil/AtomConfig";
import {
  ClientDelReq,
  ClientReqGet2,
  ClientReqPost,
} from "../../utils/ClientAuth";
import useContestInfo from "../../components/Contest/Mobile/useReq";

const ContestDetailMobile = () => {
  const protect = DOMPurify.sanitize;
  const { id } = useParams();
  const [popOpen, setPopOpen] = useState(false);
  const [cntReply, setCntReply] = useState(0);
  const [account, setAccount] = useRecoilState(accountSelect);
  const navi = useNavigate();
  const location = useLocation();
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const { data, error, isLoading, isError } = useContestInfo(id);
  const [tab, setTab] = useState(0);
  const [scrap, setScrap] = useState(false);
  useEffect(() => {
    // todo : 로그인 여부 확인 후 포인트 적립 필요
    if (account.accessToken == null) {
      console.log("login required!");
    } else {
      console.log("already login!");
      console.log(account);
    }
  }, [account]);
  useEffect(() => {
    if (!isLoading) {
      setTab(0);
      if (data.isScrap) {
        setScrap(true);
      }
    }
    return () => {
      setTab(0);
    };
  }, [isLoading]);
  // console.log(isError);

  if (isLoading) {
    console.log("loading");
    return "";
  }

  const renderTab = (tab, data) => {
    switch (tab) {
      case 0:
        return (
          <>
            <ul>
              <li>
                <label className={"cdetailTabItemTitle"}>주최</label>
                <span>{data.result.host.text}</span>
              </li>
              <li>
                <label className={"cdetailTabItemTitle"}>기간</label>
                <span>
                  {moment(data.result.startDate).format("YYYY-MM-DD") +
                    " - " +
                    moment(data.result.endDate).format("YYYY-MM-DD")}
                </span>
              </li>

              <li>
                <label className={"cdetailTabItemTitle"}>응모대상</label>
                {data.result.target.map((item, index) => (
                  <span className={"tabCate"} key={`c_detail_target_${index}`}>
                    {item.text}
                  </span>
                ))}
              </li>

              <li>
                <label className={"cdetailTabItemTitle"}>총 시상금</label>
                <span>{data.result.totalReward.text}</span>
              </li>
              <li>
                <label className={"cdetailTabItemTitle"}>시상내역</label>
                <span>{data.result.reward}</span>
              </li>
              <li>
                <label className={"cdetailTabItemTitle"}>홈페이지</label>
                <span>
                  <a target={"_blank"} href={data.result.homepage}>
                    {data.result.homepage}
                  </a>
                </span>
              </li>
              <li>
                <label className={"cdetailTabItemTitle"}></label>
              </li>
            </ul>
          </>
        );
      case 1:
        return (
          <>
            <ul className={`cdetailEditor`}>
              {data.result.content.map((item, index) => (
                <li key={`c_detail_contest_editor_${index}`}>
                  <label>{item.label}</label>
                  <div
                    className={`cdetailEditorText`}
                    dangerouslySetInnerHTML={{ __html: protect(item.text) }}
                  ></div>
                </li>
              ))}
            </ul>
          </>
        );
    }
  };

  const handleScrap = async () => {
    if (!account.id || !account.accessToken) {
      alert("로그인 후 이용해주시기 바랍니다");
      return false;
    }
    const scrapResult = await ClientReqPost(
      "/contest/scrap",
      { id },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    console.log(scrapResult);
    if (scrapResult.statusCode === 200) {
      setScrap(true);
    }
  };
  const handleUnScrap = async () => {
    if (!account.id || !account.accessToken) {
      alert("로그인 후 이용해주시기 바랍니다");
      return false;
    }
    const scrapDelResult = await ClientDelReq(
      `/contest/scrap/${id}`,
      account.accessToken,
      account.refreshToken,
      toggle
    );
    console.log(scrapDelResult);
    if (scrapDelResult.statusCode === 200) {
      setScrap(false);
    }
  };

  const tempMyPoint = async () => {
    if (!account.id || !account.accessToken) {
      alert("로그인 후 이용해주시기 바랍니다.");
      console.log(account);
      return false;
    }
    navi(`/user/${account.id}`);
    // const result = await ClientReqGet2(
    //   `/point/my`,
    //   account.accessToken,
    //   account.refreshToken,
    //   toggle
    // );
    // const pointResult = await ClientReqPost(
    //   `/point/coincontest`,
    //   { cid: id },
    //   account.accessToken,
    //   account.refreshToken,
    //   toggle
    // );

    // console.log(pointResult);

    // console.log(result);
  };

  return (
    <>
      <Wrap>
        <div>
          <div className={"cdetailThumb"}>
            <img
              src={
                process.env.REACT_APP_AWS_ENDPOINT +
                "w_380/" +
                data.result.thumbnail.path
              }
            />
          </div>
          <div className={"cdetailInfo"}>
            <label>
              <small>{data.result.host.name}</small>
            </label>
            <label>{data.result.title}</label>
            <ul
              className={"cdetailCate"}
              style={{ flexWrap: "wrap", gap: "10px 0" }}
            >
              {data.result.category.map((item, index) => (
                <li key={`c_detail_category_${index}`}>{item.text}</li>
              ))}
            </ul>
            <div className={"cdetailDateAndCnt"}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HiEye style={{ marginRight: ".5em" }} />
                {data.result.viewCnt}
              </span>
              <span>{moment(data.result.createdAt).format("YYYY-MM-DD")}</span>
            </div>
            <div className={"cdetailBtn"}>
              {data.result.type === 2 ? (
                <div className={"btnItem"}>
                  <HiCube onClick={tempMyPoint} />

                  <p>나의 지갑 보기</p>
                </div>
              ) : (
                <div className={"btnItem"}>
                  {scrap ? (
                    <HiBookmark color={"yellow"} onClick={handleUnScrap} />
                  ) : (
                    <HiOutlineBookmark onClick={handleScrap} />
                  )}

                  <p>스크랩</p>
                </div>
              )}

              <div className={"btnItem"} onClick={() => setPopOpen(true)}>
                <HiChatAlt2 />
                <p>문의하기 ({cntReply})</p>
              </div>
              <div className={"btnItem"} onClick={() => navi(`/teamplay`)}>
                <HiUserAdd />
                <p>팀원모집</p>
              </div>
            </div>
            <div className={"cdetailTab"}>
              <ul>
                <li
                  className={tab === 0 ? "active" : ""}
                  onClick={() => setTab(0)}
                >
                  <span className={tab === 0 ? "active" : ""}>기본정보</span>
                </li>
                <li
                  className={tab === 1 ? "active" : ""}
                  onClick={() => setTab(1)}
                >
                  <span className={tab === 1 ? "active" : ""}>공모요강</span>
                </li>
              </ul>
              <div className={"cdetailTabItem"}>{renderTab(tab, data)}</div>
            </div>
          </div>
        </div>
      </Wrap>
      <ReplyMobile2
        open={popOpen}
        close={() => setPopOpen(false)}
        id={id}
        type={"contest"}
        account={account}
        tkRefresh={toggle}
        setReply={setCntReply}
      />
    </>
  );
};

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;

  .cdetailThumb {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cdetailInfo {
    padding: 0 0.5em;
    color: white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    > label {
      font-size: 0.75em;
      > small {
        font-weight: 300;
        color: #dbdbdb;
      }
    }
    .cdetailDateAndCnt {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 0.55em;
      > span {
        color: gray;
        margin-right: 1em;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .cdetailBtn {
      padding: 0.5em 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .btnItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > p {
          font-size: 0.55em;
        }
      }
    }

    .cdetailCate {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.5em;
      font-size: 0.55em;
      > li {
        margin-right: 0.5em;

        padding: 0.2em 0.5em;
        background-color: green;
      }
    }
    .cdetailTab {
      > ul {
        margin-top: 1em;
        font-size: 0.75em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        > li {
          position: relative;
          width: 50%;
          text-align: center;
          padding: 0.5em 0;
          &.active {
            &:after {
              content: "";
              bottom: 0;
              left: 0;
              position: absolute;
              width: 100%;
              height: 1px;
              background-color: red;
            }
          }

          > span {
            position: relative;
          }
        }
      }
      .cdetailTabItem {
        a {
          color: white;
        }
        font-size: 0.65em;
        > ul {
          &.cdetailEditor {
            > li {
              > label {
                display: block;
                width: 100%;
                position: relative;
                padding: 0.25em 0;
                &:after {
                  position: absolute;
                  content: "";
                  width: 100%;
                  bottom: 0;
                  left: 0;
                  background: rgba(255, 255, 255, 0.1);
                  height: 1px;
                }
              }
              .cdetailEditorText {
                padding: 0.5em 0;
                font-weight: 200;
              }
            }
          }
          > li {
            padding: 0.5em 0;
            .cdetailTabItemTitle {
              margin-right: 1em;
              font-weight: 200;
            }
            .tabCate {
              margin-right: 1em;
            }
          }
        }
      }
    }
  }
`;

export default ContestDetailMobile;
