import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import ChangeMy from "./Suspense/ChangeMy";
import { InfoLabel, InfoLi, InfoText, InfoTop, InfoUl } from "./UserTemplate";

const UserChangeInfo = ({ changeTab }) => {
  const { id } = useParams();

  return (
    <div className={`contents mypage`}>
      <h2
        className={"sub_title"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ width: "100%" }}>내 정보 수정</span>
      </h2>
      <div className={"regist_area"}>
        <Suspense fallback={<div></div>}>
          <ChangeMy id={id} />
        </Suspense>
      </div>
    </div>
  );
};

export default UserChangeInfo;
