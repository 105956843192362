import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";

const EventListPC = ({ filterSet, pageSet, pageChange, setTotal }) => {
  const [filter, setFilter] = useState({
    keyType: "",
    keyword: "",
    page: 1,
  });
  const { data, error } = useQuery(
    ["eventList", filter],
    async () =>
      await NormalReqApi(
        "get",
        `/event/list?page=${filter.page}&keyType=${filter.keyType}&keyword=${filter.keyword}`
      ),
    { retry: true, suspense: true }
  );

  useEffect(() => {
    setFilter({
      ...filter,
      keyType: filterSet.keyType,
      keyword: filterSet.keyword,
      page: pageSet,
    });
  }, [filterSet, pageSet]);

  useEffect(() => {
    if (data.data.result.length > 0) {
      setTotal(data.data.count);
    } else {
      setTotal(0);
    }
  }, [data]);

  const render = (data) => {
    if (data.data.result.length > 0) {
      return data.data.result.map((item, index) => (
        <li key={`e_l_${index}`}>
          <Link to={`/event/${item._id}`}>
            <img
              src={
                process.env.REACT_APP_AWS_ENDPOINT +
                "w_380/" +
                item.thumbnail.path
              }
              style={{ height: "421px" }}
            />
            <span className={"thumb_title"}>{item.title}</span>
            <p className={"thumb_date"}>
              ~ {moment(item.endDate).format("YYYY-MM-DD")}
            </p>
          </Link>
        </li>
      ));
    } else {
      return <li></li>;
    }
  };
  return <>{render(data)}</>;
};

export default EventListPC;
