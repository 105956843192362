import React from "react";
import styled from "styled-components";

export const SignupWrap = styled.div`
  margin-top: 3.5em;
  height: 100%;
  padding: 0.25em 0.5em;
  position: relative;
  color: white;
  .indexLabel {
    width: 100%;

    border-bottom: 1px solid red;
    padding-bottom: 0.25em;
    margin-bottom: 0.25em;
  }
  .section {
    font-size: 0.75em;
    h4 {
    }
    &.sectionTerms {
      .customTerms {
        padding: 0.25em 0;
        margin: 0.25em 0;
        .customTermsItem {
          padding: 0.5em 0.85em;
          border-radius: 10px;
          margin-bottom: 0.8em;
          font-size: 0.75em;
          /* background-color: rgba(255, 255, 255, 0.1); */
          box-shadow: 0px 0px 3px 0px white;

          .customTermsText {
            margin-top: 1em;
            height: 200px;
            overflow-y: scroll;
            a {
              color: white;
            }
          }
          .customTermsTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .termsWrap {
              input {
                position: absolute;
                opacity: 0;
                right: 0;
                top: 0;
                width: 0;
                height: 0;
              }

              .termsCheck {
                position: absolute;
                border-radius: 5em;
                width: 25px;
                height: 25px;
                top: -2px;
                right: 0;
                background-color: #eee;
                &:after {
                  content: "";
                  position: absolute;
                }
              }
              input:checked ~ span {
                background-color: #2b303b;
              }
              input:checked ~ span:after {
                display: block;
              }
              span:after {
                left: 9px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }

    &.sectionPreInput {
      .customInputs {
        padding: 0.25em 0;
        > label {
          display: block;
          font-size: 0.75em;
          color: gray;
        }

        input {
          color: white;
          font-size: 0.9em;
          padding: 0.5em 0.5em;
          &.ww80 {
            width: 80% !important;
          }
          &.ww100 {
            width: 100% !important;
          }
          background-color: transparent;
          border: none;
          &:focus {
            outline: 1px solid gray;
          }
        }
        .customAddOn {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          background-color: rgba(0, 0, 0, 0.1);
          margin: 0.25em 0;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: transparent;
            color: white;

            > span {
              font-size: 0.55em;
            }
          }
        }
      }
    }

    &.sectionBootPay {
      margin-top: 2em;
      > button {
        border: none;
        font-size: 0.9em;
        padding: 0.5em 0.5em;
        border-radius: 5px;
        background-color: transparent;
        color: white;
        width: 100%;
        box-shadow: 0px 0px 3px 0px white;
      }
    }

    &.sectionSignUpFinal {
      margin-top: 2em;

      .customInputGroup {
        margin: 1em 0;

        .customInputs {
          padding: 0.25em 0;
          > label {
            display: block;
            font-size: 0.75em;
            color: gray;
          }

          input {
            color: white;
            font-size: 0.9em;
            padding: 0.5em 0.5em;
            &.ww80 {
              width: 80% !important;
            }
            &.ww100 {
              width: 100% !important;
            }
            background-color: transparent;
            border: none;
            &:focus {
              outline: 1px solid gray;
            }
          }
          .customAddOn {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            background-color: rgba(0, 0, 0, 0.1);
            margin: 0.25em 0;

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              background-color: transparent;
              color: white;

              > span {
                font-size: 0.55em;
              }
            }
          }
        }
      }
    }

    &.customSubmit {
      margin-bottom: 5em;
      > button {
        border: none;
        border-radius: 5px;

        width: 100%;
        background-color: transparent;
        box-shadow: 0px 0px 3px 0px white;
        > span {
          color: white;
          font-size: 0.75em;
        }
      }
    }
  }
`;

export const LoginWrap = styled.div`
  margin-top: 7em;
  height: calc(100% + 6em);
  min-height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 0.75em;
  margin-top: 6em;

  .inputInfo {
    display: flex;
    flex-direction: column;
    width: 90%;

    margin: 1em auto;
    .idPwd {
      > label {
        display: block;
        padding-bottom: 0.25em;
        font-size: 0.75em;
      }
      > input {
        border: none;
        background-color: rgba(255, 255, 255, 0.1);
        margin-bottom: 1em;
        width: 100%;
        font-size: 0.85em;
        color: white;
        padding: 0.5em 0.5em;
        border-radius: 5px;
        display: block;
        &:focus {
          outline: 1px solid gray;
        }
      }
    }
  }
  .inputBtns {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > button {
      border: none;
      background-color: #2b2e3b;
      color: white;
      font-size: 1em;
      font-weight: 400;

      &.submitBtns {
        background-color: gray;
        padding: 0.1em 0.5em;
        border-radius: 5px;
        margin-right: 1em;
        line-height: 25px;
        &:last-child {
          margin-right: 0;
        }
        width: 100%;
      }
    }
  }
  .findArea {
    margin-top: 1em;
    font-size: 0.75em;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: rgba(255, 255, 255, 0.2);
    > p {
      margin-bottom: 0.25em;
      padding-bottom: 0.25em;
    }
  }

  .customSignUpType {
    > div {
      padding: 1em 1em;
      margin-bottom: 1em;
      > label {
        width: 100%;
        display: block;
        padding-bottom: 0.25em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
`;
