import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ClientReqGet } from "../../../utils/ClientAuth";
import styled from "styled-components";
import moment from "moment";

const FreeWrapUl = styled.div`
  margin: 0.25em 0;
  padding: 0.25em 0.25em;
`;
const FreeLi = styled.li`
  color: #ffffff;
  font-size: 0.55em;
  padding: 0.25em 0;
  margin: 0.25em 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;
const FreeMore = styled.li`
  color: #ffffff;
  font-size: 0.55em;
  padding: 0.25em 0;
  margin: 0.25em 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FreeText = styled.p`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
const FreeStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25em 0;
  margin: 0.25em 0;
`;
const FreeCurStatus = styled.span`
  padding: 0.15em 0.25em;
  background-color: ${(props) => (props.confirm ? `green` : `gray`)};
  border-radius: 5px;
`;
const FreeDate = styled.span``;

const FreeBody = ({ account, toggle }) => {
  const { data, error, isLoading, refetch } = useQuery(
    ["userFreeContest", account, toggle],
    async () =>
      await ClientReqGet(
        `/reqContest/my`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: true }
  );

  return (
    <FreeWrapUl>
      {data.data.length > 0 ? (
        <>
          {data.data.map((item, index) => (
            <FreeLi key={`mobile_free_${item._id}_${index}`}>
              <FreeText>{item.title}</FreeText>
              <FreeStatus>
                <FreeCurStatus confirm={item.confirm}>
                  {item.confirm ? "승인완료" : "승인대기"}
                </FreeCurStatus>
                <FreeDate>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </FreeDate>
              </FreeStatus>
            </FreeLi>
          ))}

          <FreeMore>더보기</FreeMore>
        </>
      ) : (
        <FreeLi>
          <FreeText>등록된 데이터가 존재하지 않습니다</FreeText>
        </FreeLi>
      )}
    </FreeWrapUl>
  );
};

const FreeEdit = ({ account, toggle }) => {
  const [page, setPage] = useState(0);
  const { data, error, isLoading } = useQuery(
    ["myFreeEditWithPage", page, account, toggle],
    async () =>
      await ClientReqGet(
        `/reqCEdit/list?page=${page}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: true }
  );

  return (
    <FreeWrapUl>
      {data.data.count > 0 ? (
        <>
          {data.data.result.map((item, index) => (
            <FreeLi key={`mobile_free_${item._id}_${index}`}>
              <FreeText>{item.content}</FreeText>
              <FreeStatus>
                <FreeCurStatus confirm={item.answerYn}>
                  {item.answerYn ? "답변완료" : "답변대기"}
                </FreeCurStatus>
                <FreeDate>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </FreeDate>
              </FreeStatus>
            </FreeLi>
          ))}
        </>
      ) : (
        <FreeLi>
          <FreeText>등록된 데이터가 존재하지 않습니다</FreeText>
        </FreeLi>
      )}
    </FreeWrapUl>
  );
};

const UserFreePrev = ({ tab, account, toggle }) => {
  if (tab === 0) {
    return <FreeBody account={account} toggle={toggle} />;
  } else {
    return <FreeEdit account={account} toggle={toggle} />;
  }
};

export default UserFreePrev;
