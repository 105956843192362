import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientReqPost } from "../../utils/ClientAuth";

const UserCheckPwd = ({ changeTab }) => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accountToken = value;
      return { ...temp };
    });
  };
  const pwdRef = useRef(null);
  const checkPwd = async () => {
    const pwd = pwdRef.current.value;
    if (!pwd || pwd.length === 0) {
      alert("비밀번호를 입력해주시기 바랍니다.");
      return false;
    }
    const result = await ClientReqPost(
      `/user/check/pwd`,
      { pwd },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag && result.data) {
      changeTab(2);
    }
  };

  const handleKeyEnter = (e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Enter") {
      checkPwd();
    }
  };
  return (
    <div className={`contents mypage`}>
      <h2
        className={"sub_title"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ width: "100%" }}>비밀번호 입력</span>
      </h2>
      <div className={"regist_area"}>
        <div className={"form"}>
          <label>본인 확인을 위해 비밀번호를 입력해주시기 바랍니다.</label>
          <div
            className={"input_area"}
            style={{
              marginTop: "1em",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              type="password"
              ref={pwdRef}
              onKeyDown={(e) => handleKeyEnter(e)}
              className={"input_text w75"}
              style={{ borderRadius: "5px" }}
            />
            <button
              type="button"
              className={"btn regist w20"}
              style={{
                fontSize: "1.25em",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#263050",
                color: "white",
                cursor: "pointer",
              }}
              onClick={checkPwd}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCheckPwd;
