import React from "react";
import styled from "styled-components";

const MainDiv = styled.div`
  margin: 20px 0;
`;
const MainLabel = styled.label`
  font-size: 42px;
  font-weight: bold;
`;

const SubLi = styled.li`
  list-style: disc;
  margin-left: 30px;
  padding-bottom: 20px;
`;
const SubLabel = styled.label`
  font-weight: bold;
`;
const SubP = styled.p`
  font-weight: 300;
`;

export const SectionCard = ({ thumb, label, children }) => {
  return (
    <>
      <div>
        <img src={thumb} />
      </div>
      <MainDiv>
        <MainLabel>{label}</MainLabel>
        {children}
      </MainDiv>
    </>
  );
};

export const SectionContent = ({ title, content }) => {
  return (
    <SubLi>
      <SubLabel>{title}</SubLabel>
      <SubP>{content}</SubP>
    </SubLi>
  );
};
