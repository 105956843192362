import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import styled, { css } from "styled-components";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientReqGet, ClientReqGet2 } from "../../utils/ClientAuth";
import {
  InfoLabel,
  InfoLi,
  InfoText,
  InfoTop,
  InfoUl,
  CntBody,
  CntNumber,
  CntTitle,
} from "./UserTemplate";
import { useNavigate } from "react-router-dom";

const UserBasicInfo = ({ id, account, toggle }) => {
  const navi = useNavigate();
  // const queryClient = useQueryClient();
  // queryClient.invalidateQueries({ queryKey: ["userInfo"] });
  const { data } = useQuery(
    ["userInfo", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/user`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: false,
      onSuccess: (e) => {
        if (e?.statusCode && e?.statusCode === 401) {
          alert("로그인이 만료되었습니다. 로그인 페이지로 이동합니다.");
          navi("/signin");
        }
        if (e?.statusCode === 400) {
          navi("/signin");
        }
      },
      onError: (e) => {
        console.log(e);
        if (e?.statusCode === 404) {
          navi("/signin");
        }
      },
    }
  );

  const { data: cntData, error: cntError } = useQuery(
    ["userSubmitEventCnt", id, account],
    async () =>
      await ClientReqGet2(
        "/event/my/submit/count",
        account.accessToken,
        // 123123123123,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: false,
      onSuccess: (e) => {
        // console.log(e);
        if (e?.statusCode && e?.statusCode === 401) {
          navi("/signin");
        }
        if (e?.statusCode && e?.statusCode === 404) {
          navi("/signin");
        }
      },
      onError: (e) => {
        console.log(e);
        if (e?.statusCode && e?.statusCode === 404) {
          navi("/signin");
        }
      },
    }
  );

  return (
    <>
      <InfoTop>
        <InfoUl>
          <InfoLi>
            <InfoLabel className="mit_tit">이름</InfoLabel>
            <InfoText className="mit_text">{data.data?.name}</InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">회원유형</InfoLabel>
            <InfoText className="mit_text">
              {data.data?.userType === 2 ? "기업회원" : "일반회원"}
            </InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">닉네임</InfoLabel>
            <InfoText className="mit_text">{data.data?.nickName}</InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">이메일</InfoLabel>
            <InfoText className="mit_text">{data.data?.email}</InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">연락처</InfoLabel>
            <InfoText className="mit_text">{data.data?.phone}</InfoText>
          </InfoLi>
          <InfoLi>
            <InfoLabel className="mit_tit">이메일 수신 여부</InfoLabel>
            <InfoText className="mit_text">
              {data.data?.emailYn ? "수신" : "거부"}
            </InfoText>
          </InfoLi>
          {/* <CompanyLabel>사업자 정보</CompanyLabel>
              <InfoUl type="company">
                <InfoLi>
                  <InfoLabel className="mit_tit">사업자등록번호</InfoLabel>
                  <InfoText className="mit_text">01051259322</InfoText>
                </InfoLi>
                <InfoLi>
                  <InfoLabel className="mit_tit">기업상태</InfoLabel>
                  <InfoText className="mit_text">01051259322</InfoText>
                </InfoLi>
                <InfoLi>
                  <InfoLabel className="mit_tit">대표전화번호</InfoLabel>
                  <InfoText className="mit_text">01051259322</InfoText>
                </InfoLi>
                <InfoLi>
                  <InfoLabel className="mit_tit">대표 메일주소</InfoLabel>
                  <InfoText className="mit_text">01051259322</InfoText>
                </InfoLi>
              </InfoUl> */}
        </InfoUl>
      </InfoTop>
      <div style={{ marginTop: "3em" }} role="count wrap">
        <div
          role="count header"
          style={{
            borderTop: "1px solid black",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <CntBody>
            <CntTitle>스크랩</CntTitle>
            <CntNumber>
              {data.data?.scrap ? data.data?.scrap.length : 0}
            </CntNumber>
          </CntBody>
          <CntBody>
            <CntTitle>참여한 이벤트</CntTitle>
            <CntNumber>{cntData.data}</CntNumber>
          </CntBody>
          {/* <CntBody>
            <CntTitle>내가 쓴 게시물</CntTitle>
            <CntNumber>{getBoardCnt(data.data.board)}</CntNumber>
          </CntBody> */}
          {/* <CntBody>
            <CntTitle>댓글</CntTitle>
            <CntNumber>2</CntNumber>
          </CntBody>
          <CntBody>
            <CntTitle>알림</CntTitle>
            <CntNumber>2</CntNumber>
          </CntBody> */}
        </div>
      </div>
    </>
  );
};

export default UserBasicInfo;
