import React, { Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  AfterViewComponent,
  ChallengeSubMenu,
} from "../../components/Challenge/Common/Element";
import ReplyComponent from "../../components/Reply/ReplyComponent";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientDelReq } from "../../utils/ClientAuth";
import {
  AuthGetAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";

const AfterView = () => {
  const navi = useNavigate();
  const { id } = useParams();
  const [account, setAccount] = useRecoilState(accountSelect);
  const [info, setInfo] = useState({
    loading: false,
    ownYn: false,
  });
  const atkChange = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const handleGoBack = () => {
    navi("/after");
  };
  const handleEdit = () => {
    navi(`/challengeedit/a/${id}`);
  };
  const handleDel = async () => {
    const delRes = await ClientDelReq(
      `/after/${id}`,
      account.accessToken,
      account.refreshToken,
      atkChange
    );
    if (delRes.statusCode === 200) {
      alert("성공적으로 삭제되었습니다");
      navi(`/after`);
    }
  };

  const getAfterAuth = async (url, accessToken) => {
    const res = await AuthGetAxios(url, accessToken);
    if (res.status === 200) {
      setInfo({
        ...res.data.result.data,
        loading: false,
        ownYn: res.data.result.ownYn,
      });
    } else if (
      res.data.statusCode === 401 &&
      res.data.message === "토큰이 만료되었습니다."
    ) {
      const refreshToken = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const pObject = { ...prev };
        pObject.accessToken = refreshToken.data.data.accessToken;
        return { ...pObject };
      });

      return await ExpiredFilter(
        url,
        getAfterAuth,
        refreshToken.data.data.accessToken
      );
    }
  };

  useEffect(() => {
    const url = `/after/view?id=${id}`;
    getAfterAuth(url, account.accessToken);
  }, [id]);

  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={"after"} />

      <AfterViewComponent
        label={"활동후기"}
        title={info.title}
        author={info.author?.nickName}
        createdAt={info.createdAt}
        content={info.content}
        attachment={info.attachment}
      >
        <div className={"answer_area"}>
          <ReplyComponent id={id} type="after" />
        </div>
        <div className={"answer_area"}>
          <div className={"btn_area board"}>
            {info.ownYn && (
              <>
                <a className={"btn"} onClick={handleEdit}>
                  수정
                </a>
                <a className={"btn"} onClick={handleDel}>
                  삭제
                </a>
              </>
            )}
            <a className={"btn"} onClick={handleGoBack}>
              목록으로
            </a>
          </div>
        </div>
      </AfterViewComponent>
    </div>
  );
};

export default AfterView;
