import React from "react";
import { Link } from "react-router-dom";
import hcStyle from "../../assets/css/req2.module.scss";
import UpgradeImg from "../../assets/img/common/img_upgrade_service.png";

const HostCenterSection3 = () => {
  return (
    <div className={`sub_container ${hcStyle.sub_container}`}>
      <div
        className={`contents mypage ${hcStyle.contents} ${hcStyle.mypage}  ${hcStyle.bottom}`}
      >
        <div className={`upgrade_service ${hcStyle.upgrade_service}`}>
          <div className={`text_area ${hcStyle.text_area}`}>
            <span>
              다양한 플랫폼을 보유한 국내 최초 공모전 전문 사이트,
              <br />
              <b>대티즌의 업그레이드 된 서비스</b>가 궁금하신가요?
            </span>
            <ul className="us_list">
              <li>
                <span>유료 상품도 궁금해요!</span>
                <p>배너광고 상품</p>
                <p>SNS광고 상품</p>
              </li>
              <li>
                <span>공모 대행 서비스도 궁금해요!</span>
                <p>공모전 대행 서비스</p>
                <p>서포터즈 / 기자단 대행 서비스</p>
              </li>
            </ul>
            <div
              className={`btn_area us_area ${hcStyle.btn_area} ${hcStyle.us_area}`}
            >
              <Link to={`/promotion`} className="us btn">
                유료상품 보러가기
              </Link>
              <Link to={`/agent`} className={"us btn"}>
                공모대행 서비스 보러가기
              </Link>
            </div>
          </div>
          <div className={`img_area ${hcStyle.img_area}`}>
            <img src={UpgradeImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostCenterSection3;
