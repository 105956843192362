import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DContentsSubMenu,
  DContentView,
  DContentViewInfo,
} from "../../components/DContents/Common/Element";
import EmptytimeSView from "../../components/DContents/EmptyTime/view";

const EmptyTimeView = () => {
  const navi = useNavigate();
  const { id } = useParams();

  const handleGoBack = () => {
    navi("/emptytime");
  };

  return (
    <div className={"container sub"}>
      <DContentsSubMenu current={"emptytime"} />
      <Suspense fallback={<DContentView label={"공강시간"} />}>
        <EmptytimeSView id={id} handleGoBack={handleGoBack} />
      </Suspense>
    </div>
  );
};

export default EmptyTimeView;
