import { Link } from "react-router-dom";
import styled, { createGlobalStyle, css } from "styled-components";
import { PageHeader } from "../../Challenge/MobileTemplate/Layout";

const pages = [
  {
    index: 1,
    type: "req",
    label: "무료등록",
    isMobile: false,
    url: "/freeContest",
    active: false,
  },
  {
    index: 2,
    type: "pro",
    label: "광고 서비스",
    isMobile: true,
    url: "/promotion",
    active: false,
  },
  {
    index: 3,
    type: "agent",
    label: "대행 서비스",
    isMobile: true,
    url: "/agent",
    active: false,
  },
];

const makePageList = (activeTarget) => {
  const resultList = [];
  let activeExist = false;
  pages.map((item, index) => {
    item.active = item.type === activeTarget;
    if (activeExist) {
      if (resultList.length === 1) {
        resultList.splice(0, 0, item);
      } else {
        resultList.push(item);
      }
    } else {
      activeExist = item.type === activeTarget;
      if (activeExist) {
        resultList.splice(1, 0, item);
      } else {
        resultList.splice(0, 0, item);
      }
    }
  });

  return resultList;
};

export const HPHeader = ({ current, children }) => {
  return (
    <PageHeader>
      <div className={"cLabel"}>
        <ul className={"labelSlider"}>
          {makePageList(current).map((item, index) => (
            <li key={`hostcenter_menu_label_${index}`}>
              <Link to={item.url}>
                <span className={item.active ? "active" : ""}>
                  {item.label}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {children}
    </PageHeader>
  );
};

//top
export const HPTopSection = styled.div`
  padding: 0.5em 0;
  color: white;
  text-align: center;
`;
export const HPTitle = styled.p`
  font-size: 0.95em;
  font-weight: bold;
`;
export const HPTitleSub = styled.p`
  font-size: 0.55em;
  font-weight: 500;
  letter-spacing: -0.55px;
`;
// middle
export const HPMiddleSection = styled.div`
  padding: 0.5em 0;
`;

export const HPProItems = styled.ul`
  position: relative;
  padding: 0.5em 0;
`;
export const HPProItem = styled.li`
  color: white;
  margin-bottom: 3em;
`;
export const HPProTitle = styled.p`
  padding: 0 0.5em;
  font-weight: 600;
  font-size: 0.75em;
  margin-bottom: 0.5em;
`;
export const HPProNumber = styled.span`
  padding: 0.15em 0.5em;
  margin-right: 0.5em;
  background: white;
  color: black;
`;
export const HPProSub = styled.p`
  padding: 0 0.5em;
  font-size: 0.55em;
  font-weight: 300;
  letter-spacing: -0.05em;
  margin-bottom: 1em;
`;

export const HPPop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0000001f;
  z-index: 111;
  transform: translateY(100vh);
  transition: transform 0.3s ease-in-out;
  ${(props) =>
    props.open &&
    css`
      transform: translateY(0);
    `}
`;

export const HPPopBody = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  padding: 0.5em;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const HPPopHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const HPPopContents = styled.div``;
export const HPPopCTitle = styled.p`
  padding: 0 0 0.5em 0;
  font-size: 0.75em;
  font-weight: bold;
`;
export const HPPopCDesc = styled.p`
  font-size: 0.65em;
`;
export const PriceItem = styled.li`
  padding: 0 0 0.5em 0;
  font-size: 0.65em;
`;
export const PricePeriod = styled.span`
  margin-right: 0.5em;
  font-weight: 500;
`;
export const PriceAmount = styled.span`
  margin-right: 0.5em;
  font-weight: 500;
`;

export const HPNotAccesable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;
export const HPNAText = styled.p`
  text-align: center;
  color: white;
  font-size: 0.75em;
  font-weight: bold;
`;

const Card = styled.div`
  padding: 0.5em 0;
`;
const CardTitle = styled.p`
  font-size: 0.65em;
  font-weight: 500;
  padding-bottom: 0.25em;
  margin-bottom: 0.25em;
  border-bottom: 1px solid gray;
`;
const CardText = styled.span`
  display: block;
  font-weight: 400;
  font-size: 0.55em;
`;

export const HPPopCard = ({ label, mutiple, data, children }) => {
  return (
    <Card>
      <CardTitle>{label}</CardTitle>
      {mutiple ? children : <CardText>{data}</CardText>}
    </Card>
  );
};
