import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CFormElement,
  ChallengeSubHead,
  ChallengeSubMenu,
} from "../../components/Challenge/Common/Element";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  AfterForm,
  FreeBForm,
  QnaForm,
  TeamForm,
} from "../../components/Challenge/Common/Form";
import dompurify from "dompurify";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import {
  AuthPostAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../utils/ReqApi";
import { handleFileUpload } from "../../utils/FileController";

const DATA_TYPE = yup.object({
  category: yup.number(),
  title: yup.string().required("제목을 입력해주시기 바랍니다."),
  content: yup.string().required("내용을 입력해주시기 바랍니다."),
  file: yup.mixed(),
});

const ChallengeForm = () => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const sanitizer = dompurify.sanitize;
  const { type } = useParams();
  const navi = useNavigate();
  const [curType, setCurType] = useState({
    submenu: "",
    kor: "",
    postUrl: "",
  });

  useEffect(() => {
    const tempType = { submenu: "", kor: "" };
    switch (type) {
      case "t":
        tempType.submenu = "teamplay";
        tempType.postUrl = "/teamplay";
        tempType.kor = "팀플레이 등록";
        break;
      case "a":
        tempType.submenu = "after";
        tempType.postUrl = "/after";
        tempType.kor = "활동후기 등록";
        break;
      case "q":
        tempType.submenu = "qna";
        tempType.postUrl = "/qna";
        tempType.kor = "활동Q&A 등록";
        break;
      case "f":
        tempType.submenu = "freeboard";
        tempType.postUrl = "/freeboard";
        tempType.kor = "자유게시판 등록";
        break;
    }
    setCurType({ ...tempType });
  }, [type]);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(DATA_TYPE) });

  const handleEditorChange = (editor, type) => {
    setValue(type, sanitizer(editor.getHTML()));
  };

  const renderForm = (type) => {
    switch (type) {
      case "t":
        return (
          <TeamForm
            register={register}
            errors={errors}
            watch={watch}
            editorChange={handleEditorChange}
          />
        );
      case "a":
        return (
          <AfterForm
            register={register}
            errors={errors}
            watch={watch}
            editorChange={handleEditorChange}
          />
        );
      case "q":
        return (
          <QnaForm
            register={register}
            errors={errors}
            watch={watch}
            editorChange={handleEditorChange}
          />
        );
      case "f":
        return (
          <FreeBForm
            register={register}
            errors={errors}
            watch={watch}
            editorChange={handleEditorChange}
          />
        );
      default:
        return null;
    }
  };

  const authReq = async (url, data, accessToken) => {
    const postRes = await AuthPostAxios(url, data, accessToken);
    const { data: postData } = postRes;
    if (
      postData.statusCode === 401 &&
      postData.message === "토큰이 만료되었습니다."
    ) {
      const rtkRes = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const pObject = { ...prev };
        pObject.accessToken = rtkRes.data.data.accessToken;
        return { ...pObject };
      });

      return await ExpiredFilter(
        url,
        authReq,
        data,
        rtkRes.data.data.accessToken
      );
    } else {
      return postData;
    }
  };

  const onSubmit = async (data) => {
    if (data.file.length > 0) {
      const dataList = [
        {
          file: data.file[0],
          type: "attachment",
          objectTarget: "file",
        },
      ];
      const cData = await handleFileUpload(dataList, data);
      if (!cData) {
        return false;
      }

      data.file = { ...cData.file };
    } else {
      data.file = {};
    }

    const res = await authReq(
      `${curType.postUrl}`,
      { ...data },
      account.accessToken
    );

    if (res.statusCode === 200) {
      alert("성공적으로 저장되었습니다.");
      navi(`${curType.postUrl}`);
    } else if (
      res.statusCode === 400 &&
      res.message === "토큰이 올바르지 않습니다."
    ) {
      alert("로그인 후 이용해주시기 바랍니다.");
    } else {
      alert("저장중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다.");
    }
  };

  return (
    <div className="container sub">
      <ChallengeSubMenu current={curType.submenu} />
      <ChallengeSubHead label={curType.kor}>
        <div className={"board_view teamplay"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderForm(type)}

            <div className={"btn_area board"}>
              <button
                className="btn"
                type="button"
                style={{ marginRight: "1em" }}
                onClick={() => navi(-1)}
              >
                목록으로
              </button>
              <button className={"btn"}>등록</button>
            </div>
          </form>
        </div>
      </ChallengeSubHead>
    </div>
  );
};

export default ChallengeForm;
