import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  HiSearch,
  HiOutlineUser,
  HiOutlineX,
  HiOutlineMenu,
} from "react-icons/hi";
import styled, { css } from "styled-components";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 0.25em;
  width: 100%;
  height: 3.5em;
`;
const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-top: 0.75em;
`;
const Btn = styled.button`
  padding: 0.3em 0.5em;
  background: none;
  border: none;
`;

const NaviList = styled.div``;
const NaviUl = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 1em !important;
`;
const NaviSubUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const NaviSubA = styled.a`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25em 0;
  color: white;
  font-weight: 300;
  text-align: left;
  transition: 0.3s;
  ${(props) =>
    props.type !== "title" &&
    css`
      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 0.2em;
        height: 0.2em;
        background-color: rgba(255, 255, 255, 0.3);
        transform: translateY(-50%);
      }
    `}
`;

const Nav = styled.nav`
  position: relative;
  display: block;
  padding-top: 3.5em;
  width: 100vw;
  height: unset !important;
  background-color: rgba(43, 47, 59, 0.85);
  padding-bottom: 3.5em;
`;

const NaviTitle = styled.span`
  font-weight: 500;
`;

const NaviText = styled.span`
  display: block;
  font-size: 0.7em;
  font-weight: 300;
  z-index: 1;
`;

const LogoSize = 40;
const BtnSize = 24;

const MobileHeader = ({
  pageChange,
  LogoImg,
  LogoDark,
  bg,
  chStyle,
  movePage,
}) => {
  const [open, setOpen] = useState(false);
  const [account] = useRecoilState(accountSelect);

  const changePage = (url) => {
    movePage(url);
    setOpen(false);
  };

  return (
    <>
      <HeaderWrap>
        <Link to="/" onClick={() => pageChange("home")}>
          <div className={"logo"}>
            <img
              src={bg.change ? LogoDark : LogoImg}
              width={142}
              height={LogoSize}
            />
          </div>
        </Link>
        <BtnWrap>
          {/* <Link to={`/search`}>
            <Btn type={"button"}>
              <HiSearch color="white" size={BtnSize} />
            </Btn>
          </Link> */}
          {!account.id || !account.accessToken ? (
            <Link to={`/signin`}>
              <Btn type="button">
                <HiOutlineUser color={"red"} size={BtnSize} />
              </Btn>
            </Link>
          ) : (
            <Link to={`/user/${account.id}`}>
              <Btn type="button">
                <HiOutlineUser
                  color={bg.change ? "black" : "white"}
                  size={BtnSize}
                />
              </Btn>
            </Link>
          )}

          <a style={{ zIndex: "12" }}>
            <Btn type="button">
              {open ? (
                <HiOutlineX
                  color={"white"}
                  size={BtnSize}
                  onClick={() => setOpen(false)}
                />
              ) : (
                <HiOutlineMenu
                  color={bg.change ? "black" : "white"}
                  size={BtnSize}
                  onClick={() => setOpen(true)}
                />
              )}
            </Btn>
          </a>
        </BtnWrap>
        <div
          className={`gnb ${open ? "active" : ""}`}
          style={{ position: "fixed", overflowY: "scroll" }}
        >
          <Nav className={"menu"}>
            <NaviList>
              <NaviUl className={"accordion gnbBox"}>
                <li>
                  <NaviSubA
                    onClick={() => changePage("/contest/coin")}
                    type="title"
                  >
                    <NaviTitle>코인 공모전</NaviTitle>
                  </NaviSubA>
                </li>
                <li>
                  <NaviSubA
                    onClick={() => changePage("/contest/contest")}
                    type="title"
                  >
                    <NaviTitle>공모전</NaviTitle>
                  </NaviSubA>
                </li>
                <li>
                  <a href="#" onClick={() => changePage("/contest/activity")}>
                    <span>대외활동</span>
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => changePage("/dcontents")}>
                    <span>대티즌 콘텐츠</span>
                  </a>
                  <ul className={`submenu`}>
                    <li>
                      <a onClick={() => changePage("/emptytime")}>
                        <span>공강시간</span>
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>위클리</span>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => changePage("/depic")}>
                        <span>대픽 영상</span>
                      </a>
                    </li>
                    {/* <li>
                      <a onClick={() => changePage("/event")}>
                        <span>이벤트</span>
                      </a>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <a href="#" onClick={() => changePage("/event")}>
                    <span>이벤트</span>
                  </a>
                </li>
                {/* <li>
                  <a href="#" onClick={() => changePage("/challenge")}>
                    <span>공모전 도전하기</span>
                  </a>
                  <ul className={`submenu`}>
                    <li>
                      <a onClick={() => changePage("/teamplay")}>
                        <span>팀원모집</span>
                      </a>
                    </li>
                    <li>
                      <a onClick={() => changePage("/after")}>
                        <span>활동후기</span>
                      </a>
                    </li>

                    <li>
                      <a onClick={() => changePage("/freeboard")}>
                        <span>자유게시판</span>
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <a href="#" onClick={() => changePage("/hostcenter")}>
                    <span>고객센터</span>
                  </a>
                  <ul className={`submenu`}>
                    <li>
                      <a>
                        <span>무료 공모전/대외활동 등록</span>
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>광고 서비스</span>
                      </a>
                    </li>

                    <li>
                      <a>
                        <span>대행 서비스</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </NaviUl>
            </NaviList>
          </Nav>
        </div>
      </HeaderWrap>
    </>
  );
};

export default MobileHeader;
