import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const FORMAT = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "video",
];

const Editor = ({ className, onChange, type, value }) => {
  const ref = useRef();

  const handleImg = () => {
    console.log("img handler!");
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ align: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],

          ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: handleImg,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <ReactQuill
      modules={modules}
      formats={FORMAT}
      theme="snow"
      onChange={(content, delta, source, editor) => onChange(editor, type)}
      forwardedRef={ref}
      className={className}
      value={value || ""}
    />
  );
};

export default Editor;
