import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import {
  HiChevronDown,
  HiChevronUp,
  HiCube,
  HiEye,
  HiSearch,
} from "react-icons/hi";
import { useQuery } from "react-query";
import moment from "moment";
import { useRecoilState } from "recoil";
import { commonSelect } from "../../recoil/AtomConfig";
import { NormalReqApi } from "../../utils/ReqApi";
import logo from "../../assets/img/common/logo_w.png";

const ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT + "w_280/";
const NOWDATE = new Date();
const PROCESS_LIST = [
  { id: 1, value: "all", label: "전체", realValue: -1 },
  { id: 2, value: "ready", label: "진행예정", realValue: 0 },
  { id: 3, value: "onair", label: "진행중", realValue: 1 },
  { id: 4, value: "done", label: "마감", realValue: 2 },
];
const ORDERED_LIST = [
  { id: 1, value: "desc", label: "등록일 순", realValue: 0 },
  { id: 2, value: "view", label: "조회수 순", realValue: 1 },
  { id: 3, value: "scrap", label: "스크랩 순", realValue: 2 },
];

const ContestFake = () => {
  const { type } = useParams();

  const [info, setInfo] = useState({
    contestType: 1,
    kor: "공모전",
    en: type,
  });
  const [params, setParams] = useState({
    order: 0,
    keyword: "",
    process: -1,
    page: 1,
    pageToggle: false,
    filter: {
      category: [],
      target: [],
      host: [],
      totalReward: [],
    },
  });
  const [preventReload, setPreventReload] = useState(false);

  useEffect(() => {
    // setPreventReload(false);
    setInfo({
      contestType:
        type === "contest"
          ? 0
          : type === "activity"
          ? 1
          : type === "coin"
          ? 0
          : 0,
      kor:
        type === "contest"
          ? "공모전"
          : type === "activity"
          ? "대외활동"
          : type === "coin"
          ? "코인 공모전"
          : "공모전",
      en: type,
    });
    resetParam();

    // return () => {
    //   setPreventReload(true);
    //   setParams({
    //     order: 0,
    //     keyword: "",
    //     process: -1,
    //     page: 1,
    //     pageToggle: false,
    //     filter: {
    //       category: [],
    //       target: [],
    //       host: [],
    //       totalReward: [],
    //     },
    //   });
    // };
  }, [type]);

  // useEffect(() => {
  //   if (preventReload) return;
  //   console.log("param change");
  // }, [params]);

  const [dropOpen, setDropOpen] = useState({
    open: false,
    type: "process",
  });

  const onDropToggle = (toggle, type) => {
    setDropOpen({ open: toggle, type: type });
  };

  const changeFilter = (list) => {
    setParams({
      ...params,
      filter: { ...list },
      page: 1,
      pageToggle: false,
    });
  };

  const changeProcess = (value) => {
    setParams({
      ...params,
      process: value,
      page: 1,
      pageToggle: false,
    });
  };

  const changeOrdered = (value) => {
    setParams({
      ...params,
      order: value,
      page: 1,
      pageToggle: false,
    });
  };

  const searchKeyword = (value) => {
    setParams({
      ...params,
      keyword: value,
      page: 1,
      pageToggle: false,
    });
  };
  const changePage = (page) => {
    setParams({
      ...params,
      page: page,
      pageToggle: true,
    });
  };
  const resetParam = () => {
    setParams({
      order: 0,
      keyword: "",
      process: -1,
      page: 1,
      pageToggle: false,
      filter: {
        category: [],
        target: [],
        host: [],
        totalReward: [],
      },
    });
  };

  return (
    <div className={"container sub"}>
      <ContestSubviual>
        <ContestRecItems>
          <ChoiceContests type={info.en} isOnChain={type === "coin"} />
        </ContestRecItems>
      </ContestSubviual>
      <ContestContainer>
        <FilterItems
          label={info.kor}
          contestType={info.contestType}
          toggle={onDropToggle}
          current={dropOpen.type}
          open={dropOpen.open}
          changeFilter={changeFilter}
          changeProcess={changeProcess}
          changeOrder={changeOrdered}
          searchKeyword={searchKeyword}
          reset={resetParam}
          isOnChain={type === "coin"}
        />

        <SuspenseContest
          param={params}
          type={type}
          changePage={changePage}
          isOnChain={type === "coin"}
        />
      </ContestContainer>
    </div>
  );
};

export const ChoiceContests = ({ type, isOnChain = false }) => {
  const { data, isLoading } = useQuery(
    ["choiceContest", type],
    async () => NormalReqApi("get", `/choice/${type}`),
    { retry: true }
  );

  const router = useNavigate();

  const onDetailClick = ({ contestId }) => {
    router(`/contest/view/${type === "contest" ? 0 : 1}/${contestId}`, {
      state: {
        isOnChain: isOnChain,
      },
    });
  };

  if (isLoading) {
    return Array.from(new Array(4), (el, index) => (
      <ContestRecItem
        key={`ct_${index}`}
        className={"recContest"}
      ></ContestRecItem>
    ));
  }

  return (
    <>
      {data.result.map((item, index) => (
        <ContestRecItem
          key={`ct_data_${type}_${item._id}`}
          className={"recContest"}
          isExist={true}
          onClick={() => onDetailClick(item)}
        >
          <ChoiceContest {...item} />
        </ContestRecItem>
      ))}
      {4 - data.result.length > 0 &&
        Array.from(new Array(4 - data.result.length), (el, index) => (
          <ContestRecItem
            key={`ct_extra_${type}_${index}`}
            className={"recContest"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logo} />
          </ContestRecItem>
        ))}
    </>
  );
};

const ChoiceContest = ({
  title,
  thumbnailPath,
  contestEndDate,
  contestHost,
}) => {
  return (
    <>
      <img src={thumbnailPath} style={{ width: "280px", height: "400px" }} />
      <ContestRecItemInfo>
        <p className="cc_title">{title}</p>
        <p className="cc_etc cc_host">{contestHost}</p>
        <p className="cc_etc cc_date">{`~ ${moment(contestEndDate).format(
          "YYYY-MM-DD"
        )}`}</p>
      </ContestRecItemInfo>
    </>
  );
};

export const FilterItems = ({
  label,
  contestType,
  toggle,
  current,
  open,
  changeFilter,
  changeProcess,
  changeOrder,
  searchKeyword,
  reset,
  onChain,
}) => {
  const [common, setCommon] = useRecoilState(commonSelect);
  const getCommon = async () => {
    if (common.loading) {
      const res = await NormalReqApi("get", "/basic");
      if (res && res.statusCode === 200) {
        setCommon((prev) => {
          const temp = { ...prev };
          temp.loading = false;
          temp.category = [...res.data.category];
          temp.host = [...res.data.host];
          temp.totalReward = [...res.data.totalReward];
          temp.target = [...res.data.target];
          temp.region = [...res.data.region];
          return { ...temp };
        });
      }
    }
  };
  useEffect(() => {
    getCommon();
  }, []);

  useEffect(() => {
    return () => {
      setFilter("category");
      setSelectFilter({
        category: [],
        target: [],
        host: [],
        totalReward: [],
      });
      reset();
    };
  }, [contestType]);

  const [filter, setFilter] = useState("category");
  const [selectFilter, setSelectFilter] = useState({
    category: [],
    target: [],
    host: [],
    totalReward: [],
  });
  const [process, setProcess] = useState({ realValue: -1, label: "전체" });
  const [ordered, setOrdered] = useState({ realValue: 0, label: "등록일 순" });
  const keywordRef = useRef(null);

  // find my contest ---start---
  const onFilterLabelClick = (type) => {
    setFilter(type);
  };
  const onFilterItemClick = (type, id) => {
    const filterList = { ...selectFilter };
    const isNew = ![...filterList[type]].includes(id);
    const newArray = isNew
      ? [...filterList[type], id]
      : [...filterList[type]].filter((f) => f !== id);
    filterList[type] = [...newArray];
    setSelectFilter(filterList);
    changeFilter({ ...filterList });
  };

  // useEffect(() => {
  //   changeFilter({ ...selectFilter });
  // }, [selectFilter]);

  const renderFilterItems = (type, contestType, selectFilter) => {
    const list = common[type];

    if (type === "category" || type === "totalReward") {
      return list
        .filter((f) => f.type === contestType + 1)
        .map((item, index) => (
          <ContestFilterItem
            key={`cf_${type}_${item._id}`}
            onClick={() => onFilterItemClick(type, item.code)}
            active={[...selectFilter[type]].includes(item.code)}
          >
            {type === "category" ? item.sub : item.text}
          </ContestFilterItem>
        ));
    } else {
      return list.map((item, index) => (
        <ContestFilterItem
          key={`cf_${type}_${item._id}`}
          onClick={() => onFilterItemClick(type, item.code)}
          active={[...selectFilter[type]].includes(item.code)}
        >
          {item.text}
        </ContestFilterItem>
      ));
    }
  };

  // find my contest ---end ----

  // select process ---start---
  const onProcessClick = ({ label, realValue }) => {
    setProcess({ label, realValue });
    changeProcess(realValue);
    toggle(!open, "process");
  };
  // useEffect(() => {

  // }, [process]);
  // select process ---end---

  // select ordered ---start---
  const onOrderClick = ({ label, realValue }) => {
    setOrdered({ label, realValue });
    changeOrder(realValue);
    toggle(!open, "ordered");
  };

  // select ordered ---end---

  // keyword search ---start---
  const onTextSearchClick = () => {
    const keyword = keywordRef.current.value;
    // if (!keyword) return;
    searchKeyword(keyword);
  };
  // keyword search ---end---

  const [toggleFilter, setToggleFilter] = useState(false);
  const onFitlerToggle = () => {
    setToggleFilter(!toggleFilter);
  };

  return (
    <ContestFilters className={toggleFilter ? "active" : "hidden"}>
      <ContestFilter type={"block"}>
        <h2 onClick={onFitlerToggle}>
          나에게 맞는 {onChain ? "코인 공모전" : label} 찾기
          {toggleFilter ? (
            <HiChevronUp size={24} color={"white"} />
          ) : (
            <HiChevronDown size={24} color={"white"} />
          )}
        </h2>
        <ContestFilterTabs>
          <ContestFilterLabel
            active={filter === "category"}
            onClick={() => onFilterLabelClick("category")}
          >
            공모분야{" "}
            {selectFilter["category"].length > 0 &&
              `(${selectFilter["category"].length})`}
          </ContestFilterLabel>
          <ContestFilterLabel
            active={filter === "target"}
            onClick={() => onFilterLabelClick("target")}
          >
            응모대상{" "}
            {selectFilter["target"].length > 0 &&
              `(${selectFilter["target"].length})`}
          </ContestFilterLabel>
          <ContestFilterLabel
            active={filter === "host"}
            onClick={() => onFilterLabelClick("host")}
          >
            주최사{" "}
            {selectFilter["host"].length > 0 &&
              `(${selectFilter["host"].length})`}
          </ContestFilterLabel>
          <ContestFilterLabel
            active={filter === "totalReward"}
            onClick={() => onFilterLabelClick("totalReward")}
          >
            시상내역{" "}
            {selectFilter["totalReward"].length > 0 &&
              `(${selectFilter["totalReward"].length})`}
          </ContestFilterLabel>
        </ContestFilterTabs>
        <ContestFilterTabItems>
          {renderFilterItems(filter, contestType, selectFilter)}
        </ContestFilterTabItems>
      </ContestFilter>
      <ContestFilter>
        <h2>진행여부</h2>
        <ContestSearch>
          <ContestSearchLabel>{process.label}</ContestSearchLabel>

          <HiChevronDown
            size={20}
            color="#fff"
            onClick={() => toggle(!open, "process")}
          />
          <ContestSearchDropBox open={current === "process" && open}>
            {PROCESS_LIST.map((item, index) => (
              <ContestSearchDropItem
                key={`pr_${index}`}
                value={item.value}
                onClick={() => onProcessClick(item)}
                active={process.realValue === item.realValue}
              >
                {item.label}
              </ContestSearchDropItem>
            ))}
          </ContestSearchDropBox>
        </ContestSearch>
      </ContestFilter>
      <ContestFilter>
        <h2>노출순서</h2>
        <ContestSearch>
          <ContestSearchLabel>{ordered.label}</ContestSearchLabel>
          <HiChevronDown
            size={20}
            color="#fff"
            onClick={() => toggle(!open, "order")}
          />
          <ContestSearchDropBox open={current === "order" && open}>
            {ORDERED_LIST.map((item, index) => (
              <ContestSearchDropItem
                key={`or_${index}`}
                onClick={() => onOrderClick(item)}
                active={ordered.realValue === item.realValue}
              >
                {item.label}
              </ContestSearchDropItem>
            ))}
          </ContestSearchDropBox>
        </ContestSearch>
      </ContestFilter>
      <ContestFilter type="search">
        <h2>검색</h2>
        <ContestSearch type="search">
          <ContestSearchPrefix>
            <span>제목</span>
          </ContestSearchPrefix>
          <input type="text" ref={keywordRef} />
          <HiSearch size={20} color="#fff" onClick={onTextSearchClick} />
        </ContestSearch>
      </ContestFilter>
    </ContestFilters>
  );
};

export const SuspenseContest = ({
  type,
  param,
  changePage,
  isOnChain = false,
}) => {
  const contestType =
    type === "contest" ? 0 : type === "activity" ? 1 : type === "coin" ? 2 : 0;
  const nextLoadCatcherRef = useRef(null);
  const [list, setList] = useState([]);
  const listClear = useRef(true);
  const router = useNavigate();

  const { data, isLoading } = useQuery(
    ["contest", contestType, param],
    async () => {
      if (param.pageToggle) {
        listClear.current = false;
      } else {
        listClear.current = true;
      }
      return fetch(
        `${
          process.env.REACT_APP_AWS_API
        }/contest?contestType=${contestType}&page=${param.page}&progress=${
          param.process
        }&sort=${param.order}${
          param.filter.category.length > 0
            ? `&category=${param.filter.category?.toString()}`
            : ""
        }${
          param.filter.target.length > 0
            ? `&target=${param.filter.target?.toString()}`
            : ""
        }${
          param.filter.host.length > 0
            ? `&host=${param.filter.host?.toString()}`
            : ""
        }${
          param.filter.totalReward.length > 0
            ? `&totalReward=${param.filter.totalReward?.toString()}`
            : ""
        }${param.keyword ? `&type=title&keyword=${param.keyword}` : ""}`
      ).then((r) => r.json());
    },
    { retry: true }
  );

  useEffect(() => {
    const checkDupli = (elements, target) => {
      if (elements._id === target._id) {
        return true;
      }
    };
    if (isLoading) return;

    if (data?.result?.list?.length > 0) {
      if (param.pageToggle) {
        // listClear.current = false;
        // 다음 페이지 (배열 추가)
        const new2List = data.result.list.filter(
          (f) => !list.some((elements) => checkDupli(elements, f))
        );

        if (new2List.length > 0) {
          setList([...list, ...new2List]);
        }
      } else {
        // listClear.current = true;
        // 신규 페이지 (배열 초기화)
        setList([...data.result.list]);
      }
    } else {
      if (!param.pageToggle) {
        setList([]);
      }
    }
    console.log(param);
    console.log(contestType);
    console.log("page change----");

    // console.log(data);
    if (!nextLoadCatcherRef.current) return;
    const io = new IntersectionObserver((e, o) => {
      if (e[0].isIntersecting && data?.result?.list?.length === 12) {
        // setPage(page + 1);
        changePage(param.page + 1);
      }
    });
    io.observe(nextLoadCatcherRef.current);

    return () => {
      io.disconnect();
    };
  }, [data, isLoading]);

  // useEffect(() => {
  //   console.log("contest in");
  //   setList([]);
  //   return () => {
  //     console.log("contest out");
  //     // listClear.current = true;
  //   };
  // }, []);

  const onDetailClick = (id) => {
    router(`/contest/view/${contestType}/${id}`, {
      state: {
        isOnChain: isOnChain,
      },
    });
  };

  if (isLoading && listClear.current) {
    return (
      <ContestItemsWrap>
        <ContestItems>
          {Array.from(new Array(20), (el, index) => {
            return <ContestItem key={index}></ContestItem>;
          })}
        </ContestItems>
      </ContestItemsWrap>
    );
  }

  return (
    <ContestItemsWrap>
      <ContestItems>
        {list.length > 0 ? (
          <>
            {list.map((item, index) => (
              <ContestCard
                key={`c_normal_${type}_${index}_${item._id}`}
                detailMove={onDetailClick}
                isOnChain={isOnChain}
                {...item}
              />
            ))}
          </>
        ) : (
          <ContestItem className="emptyContest">
            데이터가 존재하지 않습니다
          </ContestItem>
        )}
      </ContestItems>
      <div
        ref={nextLoadCatcherRef}
        style={{ position: "absolute", bottom: "550px" }}
      />
    </ContestItemsWrap>
  );
};

const ContestCard = ({
  _id,
  thumbnail,
  title,
  host,
  startDate,
  endDate,
  viewCnt,
  detailMove,
  isOnChain,
}) => {
  const parseCurStatus = (startDate, endDate) => {
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    const result = {
      text: "",
      status: "",
    };

    if (NOWDATE < sDate) {
      result.text = "예정";
      result.status = "NOTYET";
    } else if (sDate <= NOWDATE && NOWDATE < eDate) {
      result.text = "진행중";
      result.status = "ONAIR";
    } else if (eDate <= NOWDATE) {
      result.text = "마감";
      result.status = "DONE";
    }

    return result;
  };
  const curStatus = parseCurStatus(startDate, endDate);

  return (
    <ContestItem
      className={"dataLi"}
      onClick={() => detailMove(_id)}
      style={{ cursor: "pointer" }}
    >
      <img src={`${ENDPOINT}${thumbnail.path}`} />

      {/* {isOnChain && (
        <div className={"blockchainMarkWrap"}>
          <div className={"blockchainMark"}>
            <span style={{ color: "white" }}>OnChain</span>
            <HiCube color="yellow" size={20} />
          </div>
        </div>
      )} */}

      <ContestInfo>
        <ContestInfoTitle>
          <span className={"contestTitle"}>{title}</span>
          <span className={"hostName"}>{host.name}</span>
        </ContestInfoTitle>
        <ContestInfoEtc>
          <div className={"etcMulti"}>
            <span className={`curStatus ${curStatus.status}`}>
              {curStatus.text}
            </span>
            <span>{`~ ${moment(endDate).format("YYYY-MM-DD")}`}</span>
          </div>
          <div className={"viewCnt"}>
            <HiEye size="20" color="#fff" />
            {viewCnt}
          </div>
        </ContestInfoEtc>
      </ContestInfo>
    </ContestItem>
  );
};

export default ContestFake;

export const ContestSubviual = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.5em;
  height: calc(500px + 3.5em);
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.5) 0px 13px 10px -5px;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
    justify-content: start;

    background-color: rgba(255, 255, 255, 0.1);
    &::-webkit-scrollbar {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }

    /* -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    } */
  }
`;

export const ContestContainer = styled.div`
  position: relative;
  background-color: rgb(83, 76, 110);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 20px;

  @media screen and (max-width: 1879px) {
    flex-direction: column;
  }
`;
export const ContestFilters = styled.div`
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-922px);
  display: block;
  width: 322px;
  background-color: transparent;

  @media screen and (max-width: 1879px) {
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 60px;
    overflow: hidden;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 0 auto;
    /* transform: translateY(100%); */
    transition: height 300ms ease-in-out;

    &.active {
      height: auto;
    }
  }
`;
export const ContestFilter = styled.div`
  width: 100%;
  padding: 10px 10px;

  /* display: flex; */
  h2 {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    border-bottom: 1px solid #ebebeb;
    color: #fff;

    > svg {
      display: none;
    }
  }

  @media screen and (max-width: 1879px) {
    ${(props) =>
      props.type === "block"
        ? css`
            display: block;
            h2 {
              display: flex;
              /* display: none; */
              justify-content: start;
              align-items: center;
              cursor: pointer;

              svg {
                display: block;
                cursor: pointer;
              }
            }
          `
        : css`
            display: flex;
            justify-content: ${(props) =>
              props.type === "search" ? `space-between` : `center`};
            align-items: center;
            h2 {
              padding-bottom: unset;
              margin-bottom: unset;
              font-size: 0.8rem;
              border-bottom: unset;
              color: #fff;
            }
          `}
  }
`;
export const ContestFilterTabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
`;
export const ContestFilterLabel = styled.span`
  font-size: 0.8rem;
  padding: 5px 10px 5px 0px;
  margin-right: 10px;
  white-space: nowrap;

  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
      border-bottom: 1px solid red;
    `}
`;
export const ContestFilterTabItems = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
`;
export const ContestFilterItem = styled.li`
  padding: 5px 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.8rem;
  border-radius: 20px;
  ${(props) =>
    props.active &&
    css`
      background-color: white;
      color: black;
    `}

  @media screen and (max-width:1879px) {
    &:hover {
      background-color: #bcbcbc;
      color: black;
    }
  }
`;
export const ContestSearch = styled.div`
  position: relative;
  width: 100%;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);

  input {
    background-color: transparent;
    border: none;
    color: #fff;
    width: 100%;
    padding-left: 60px;
    padding-right: 5px;
    font-size: 0.8em;
    &:focus {
      color: #fff;
      outline: none;
    }
  }

  svg {
    cursor: pointer;
  }
  @media screen and (max-width: 1879px) {
    ${(props) =>
      props.type !== "search"
        ? css`
            > span {
              display: none;
            }
            > svg {
              display: none;
            }
            background-color: transparent;
          `
        : css`
            margin-left: 2em;
          `}
  }
`;
export const ContestSearchDropBox = styled.ul`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  font-size: 0.5em;
  overflow: hidden;
  height: ${(props) => (props.open ? `auto` : 0)};

  transition: height 300ms ease-in-out;
  z-index: 111;

  @media screen and (max-width: 1879px) {
    position: relative;
    top: unset;
    left: unset;
    height: auto;
    display: flex;
    justify-content: start;
    align-items: center;
    width: unset;
    background-color: transparent;
    border-radius: unset;
    font-size: unset;
  }
`;
const ContestSearchDropItem = styled.li`
  position: relative;
  padding: 10px 20px;
  z-index: 11;
  border-bottom: 1px solid #efefef;
  &:last-child {
    border-bottom: unset;
  }
  &:hover {
    background-color: #bcbcbc;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: #bcbcbc;
    `}

  @media screen and (max-width: 1879px) {
    border-bottom: unset;
    color: white;
    border-radius: 20px;
    padding: 5px 20px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-right: 20px;
    font-size: 0.8rem;

    ${(props) =>
      props.active &&
      css`
        background-color: white;
        color: black;
      `}
  }
`;
export const ContestSearchPrefix = styled.div`
  position: absolute;
  width: 50px;
  left: 10px;
  color: #fff;
  font-size: 0.7em;
`;
export const ContestSearchLabel = styled.span`
  width: 100%;
  font-size: 0.7em;
  color: #fff;
`;
export const BlinkAnimation = keyframes`
0%{
  
  background-position:0% 50%;
}
50%{
  
  background-position:100% 50%;
}
100%{
  
  background-position:0% 50%;
}
`;

export const ShowUpAnimation = keyframes`
from{
  opacity: 0.2;
}
to{
  opacity: 1;
}
`;

export const ContestItemsWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
`;

export const ContestItems = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  @media screen and (max-width: 1200px) {
    justify-content: center;
  }
`;
export const ContestItem = styled.li`
  position: relative;
  width: 280px;
  height: 550px;
  margin: 10px;
  opacity: 0.2;
  background: linear-gradient(137deg, #fff6b7, #f77c99, #6578f2, #c2ffd8);
  background-size: 400% 400%;
  overflow: hidden;
  animation: ${BlinkAnimation} 8s ease infinite;
  transition: opacity 300ms ease-in-out;

  &.recContest {
    height: 400px;
  }

  &.emptyContest {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 6.5em);
    margin: 0;
    opacity: 1;
    color: #fff;
    animation: unset;
    background: unset;
  }

  &.dataLi {
    display: flex;
    flex-direction: column;
    /* align-items: stretch; */
    animation: ${ShowUpAnimation} 300ms ease-in-out;
    opacity: 1;
    background: none;

    background-size: unset;

    .blockchainMarkWrap {
      position: absolute;
      right: 5px;
      top: 5px;

      .blockchainMark {
        display: flex;
        justify-content: start;
        align-items: center;
        /* background-color: #ffffff; */
        background: linear-gradient(
          130deg,
          rgb(18, 194, 233),
          rgb(196, 113, 237),
          rgb(246, 79, 89)
        );
        border-radius: 1em;
        padding: 2px 5px;
        box-shadow: 0px 0px 10px #ffffffff;

        > span {
          font-size: 0.5em;
          font-weight: bold;
          /* color: white; */
        }
      }
    }
    img {
      width: 100%;
      height: 400px;
    }
  }
`;

export const ContestRecItems = styled(ContestItems)`
  position: relative;
  margin: 0 auto;
  @media screen and (max-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

export const ContestRecItem = styled(ContestItem)`
  cursor: pointer;
  ${(props) =>
    props.isExist &&
    css`
      animation: unset;
      background: unset;
      opacity: 1;
      &:after {
        position: absolute;
        top: 0;
        right: 5px;
        content: "📌";
        font-size: 1.5em;
      }
    `}
  >img {
    transition: opacity 300ms ease-in-out;
  }
  &:hover {
    > img {
      opacity: 0.5;
    }
  }
`;
export const ContestRecItemInfo = styled.div`
  position: absolute;
  /* bottom: -400px; */
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  padding: 0.5em 0.75em;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px 1px;
  font-size: 0.75em;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  transform: translateY(400px);
  transition: transform 300ms ease-in-out;

  ${ContestRecItem}:hover & {
    /* bottom: 0px; */
    transform: translateY(0px);
  }

  > p {
    font-size: 1em;
    &.cc_title {
      font-weight: bold;
      padding-bottom: 0.5em;
    }
    &.cc_etc {
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.75em;
    }
  }
`;

export const ContestInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;

export const ContestInfoTitle = styled.div`
  display: block;

  > span {
    text-align: left;
    display: block;
    width: 100%;
    padding: 5px 0px;
    color: #fff;
    font-size: 1rem;

    &.contestTitle {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: keep-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.hostName {
      width: 270px;
      padding: 0 0px;
      font-size: 0.9rem;
      color: #bcbcbc;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
export const ContestInfoEtc = styled.div`
  padding: 5px 0 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  width: 100%;

  .etcMulti {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > span {
      padding: 0 0px 0px 0;
      font-size: 0.9rem;
      color: #bcbcbc;

      &.curStatus {
        padding: 5px 10px;
        border-radius: 20px;
        color: #fff;

        &.NOTYET {
          background-color: #ff3c00;
        }
        &.ONAIR {
          background-color: #0dbf76;
        }
        &.DONE {
          background-color: #bcbcbc;
        }
      }
    }
  }
  .viewCnt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #bcbcbc;
    font-size: 0.9rem;

    svg {
      color: #bcbcbc !important;
      margin-right: 5px;
    }
  }
`;
