import moment from "moment";
import React, { Suspense, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Chstyle from "../../../assets/css/channel.module.scss";
import pStyle from "../../../assets/css/paging.module.scss";
import Pagination from "../../../utils/Pagination";
import { NormalReqApi } from "../../../utils/ReqApi";
import { DContentView, DContentViewInfo } from "../Common/Element";

export const WeeklyBody = () => {
  const [page, setPage] = useState(1);
  const [sc, setSc] = useState({
    keyType: "",
    keyword: "",
  });
  const keyRef = useRef(null);
  const keywordRef = useRef(null);

  const { data, error, isLoading } = useQuery(
    ["weeklyList", page, sc],
    async () =>
      await NormalReqApi(
        "get",
        `/weekly/list?page=${page}&keyType=${sc.keyType}&keyword=${sc.keyword}`
      ),
    { retry: true, suspense: true }
  );

  const handleSearch = () => {
    const keyType = keyRef.current.value;
    const keyword = keywordRef.current.value;
    setSc({
      keyType,
      keyword,
    });
  };

  const handleEnterKey = (e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handlePage = (page) => {
    setPage(page * 1);
  };

  const render = (data) => {
    const items = data.data.result;
    if (items.length > 0) {
      return items.map((item, index) => (
        <li key={`weekly_data_${index}`} className={`td ${Chstyle.td}`}>
          <Link to={`/weekly/${item._id}`}>
            <span
              className={`brd_cate w15 ${
                item.category === 1 ? "weekly" : "focus"
              }`}
            >
              {item.category === 1 ? "위클리" : "포커스"}
            </span>
            <span className={"brd_title w70"}>{item.title}</span>
            <span className={"brd_info w15 brd_date"}>
              {moment(item.createdAt).format("YYYY-MM-DD")}
            </span>
          </Link>
        </li>
      ));
    } else {
      return <li></li>;
    }
  };
  return (
    <>
      <div className={`board_search ${Chstyle.board_search}`}>
        <select name="searchType" ref={keyRef}>
          <option value="0">선택</option>
          <option value="title">제목</option>
          <option value="contents">내용</option>
        </select>
        <input
          type="text"
          name="searchWrd"
          ref={keywordRef}
          onKeyDown={(e) => handleEnterKey(e)}
        />
        <button type="submit" onClick={handleSearch}>
          검색
        </button>
      </div>
      <div className={`board_list myuInfo_write ${Chstyle.board_list}`}>
        <ul>
          <li className={`th ${Chstyle.th}`}>
            <span className={"w15"}>카테고리</span>
            <span className={"w70"}>제목</span>
            <span className={`w15 ${Chstyle.regDate}`}>등록일</span>
          </li>
          {render(data)}
        </ul>
        <div className={`paging_area ${pStyle.paging_area}`}>
          <Pagination
            total={data.data.count}
            curPage={page}
            handlePage={handlePage}
          />
        </div>
      </div>
    </>
  );
};

export const WeeklyView = ({ id, handleGoBack }) => {
  const { data, error, isLoading } = useQuery(
    ["weeklyInfo", id],
    async () => await NormalReqApi("get", `/weekly/view/${id}`),
    { retry: false, suspense: true }
  );

  return (
    <DContentView label={"위클리"}>
      <div className={"board_view qna"}>
        <DContentViewInfo
          title={data.data.title}
          createdAt={moment(data.data.createdAt).format("YYYY-MM-DD")}
          content={data.data.contents}
        />
        <div className={"answer_area"}>
          <div className={"btn_area board"}>
            <a
              className={"btn"}
              style={{ cursor: "pointer" }}
              onClick={handleGoBack}
            >
              목록으로
            </a>
          </div>
        </div>
      </div>
    </DContentView>
  );
};
