import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  ChallengeFilter,
  ChallengeSubContainer,
  ChallengeSubMenu,
  TeamplayData,
} from "../../components/Challenge/Common/Element";
import Cmstyle from "../../assets/css/community.module.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { NormalReqApi } from "../../utils/ReqApi";
import pStyle from "../../assets/css/paging.module.scss";
import Pagination from "../../utils/Pagination";
import NoticeList from "../../components/Notice/NoticeList";

const Teamplay = () => {
  const [list, setList] = useState({
    loading: true,
    data: [],
    total: 0,
  });
  const [page, setPage] = useState({
    page: 1,
  });
  useEffect(() => {
    const getTeamplay = async () => {
      const res = await NormalReqApi("get", `teamplay`);

      if (res.statusCode === 200) {
        setList({
          loading: false,
          data: [...res.result.list],
          total: res.result.total,
        });
      }
    };
    getTeamplay();
  }, []);

  const getTeamplayWithOptions = async (type, keyword, page) => {
    const res = await NormalReqApi(
      "get",
      `teamplay?type=${type}&keyword=${keyword}&page=${page}`
    );

    if (res.statusCode === 200) {
      setList({
        loading: false,
        data: [...res.result.list],
        total: res.result.total,
      });
    }
  };

  const typeRef = useRef(null);
  const keywordRef = useRef(null);

  const handlePage = (pageNumber) => {
    setPage({ page: pageNumber });
    getTeamplayWithOptions(
      typeRef.current.value,
      keywordRef.current.value,
      pageNumber
    );
  };
  const handleSearch = () => {
    const type = typeRef.current.value;
    const keyword = keywordRef.current.value;

    getTeamplayWithOptions(type, keyword, page.page);
  };

  const handleEnterKey = (e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div className={"container sub"}>
      <ChallengeSubMenu current={"teamplay"} />
      <ChallengeSubContainer
        label={"팀원모집"}
        subLabel={"프로젝트를 팀플레이로 진행하고 싶다면?"}
      >
        <ChallengeFilter
          type={typeRef}
          keyword={keywordRef}
          toggle={handleSearch}
          handleEnterKey={handleEnterKey}
          options={[
            { value: "all", label: "선택" },
            { value: "title", label: "제목" },
            { value: "content", label: "내용" },
          ]}
        />

        <div className={`board_list myInfo_wirte ${Cmstyle.board_list}`}>
          <ul>
            <li className={`th ${Cmstyle.th}`}>
              {/* <span className={"w15"}>분야</span> */}
              <span className={"w70"}>제목</span>
              <span className={"w15"}>회원명</span>
              <span className={"w15"}>작성일</span>
            </li>
            <Suspense fallback={<div></div>}>
              <NoticeList type="teamplay" />
            </Suspense>

            {!list.loading && list.data.length > 0 ? (
              list.data.map((item, index) => (
                <TeamplayData key={`teamplay_data_${index}`} {...item} />
              ))
            ) : (
              <li className={`td ${Cmstyle.td}`}>
                <span>팀원모집 데이터가 존재하지 않습니다.</span>
              </li>
            )}
          </ul>
          <div className={"btn_area board"}>
            <Link to={`/challengeform/t`} className={"goToLogin"}>
              글쓰기
            </Link>
          </div>
          <div className={`paging_area ${pStyle.paging_area}`}>
            <Pagination
              total={list.total}
              curPage={page.page}
              handlePage={handlePage}
            />
          </div>
        </div>
      </ChallengeSubContainer>
    </div>
  );
};

export default Teamplay;
