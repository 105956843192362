import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { createFileName } from "./NameConvert";

const ACCESSKEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRETKEY = process.env.REACT_APP_AWS_SECRET_KEY;
const BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const STORAGE = process.env.REACT_APP_AWS_STORAGE;
const STORAGE_ETC = process.env.REACT_APP_AWS_STORAGE_ETC;

const s3 = new S3Client({
  credentials: {
    accessKeyId: ACCESSKEY,
    secretAccessKey: SECRETKEY,
  },
  region: REGION,
});

export async function uploadFile(file, type) {
  const convertDir = type === "thumb" ? `${STORAGE}` : `${STORAGE_ETC}`;
  const fileName = createFileName(file.name, convertDir);

  const uploadParams = {
    ACL: "public-read",
    Bucket: BUCKET,
    Key: fileName,
    Body: file,
    ContentDisposition: "attachment; filename=" + encodeURIComponent(file.name),
  };

  const returnObject = {
    success: false,
    origin: file.name,
    path: fileName,
    size: file.size,
  };

  try {
    const results = await s3.send(new PutObjectCommand(uploadParams));
    console.log("successfull uploaded");
    if (results.$metadata.httpStatusCode === 200) {
      returnObject.success = true;
    }
    return returnObject;
  } catch (e) {
    console.log(e);
    return returnObject;
  }
}
