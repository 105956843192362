import React, { Suspense, useRef, useState } from "react";
import replyStyle from "../../assets/css/reply.module.scss";
import {
  HiChatAlt2,
  HiChevronDown,
  HiChevronUp,
  HiOutlineDotsVertical,
} from "react-icons/hi";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientReqPost } from "../../utils/ClientAuth";
import Replys from "./Replys";

const ReplyComponent = ({ id, type }) => {
  //   const [tg, setTg] = useState(false);
  return (
    <div className={replyStyle.wrap}>
      <Suspense fallback={<div></div>}>
        <Replys id={id} type={type} />
      </Suspense>
    </div>
  );
};
export default ReplyComponent;
