import React from "react";
import ProStyle from "../../../assets/css/promo.module.scss";

export const PromoLabelInput = ({ label, subStyle, children }) => {
  return (
    <>
      <label className={`input_title w20 ${ProStyle.input_title}`}>
        {label}
      </label>
      <div
        className={`input_area w80 ${ProStyle.input_area}`}
        style={{ ...subStyle }}
      >
        {children}
      </div>
    </>
  );
};

export const PromoTextArea = ({ label, onChange }) => {
  return (
    <>
      <label className={`input_title w20 ${ProStyle.input_title}`}>
        {label}
      </label>
      <textarea
        className={`input_area w80 ${ProStyle.input_area} ${ProStyle.textarea}`}
        onChange={(e) => onChange(e)}
        // onChange={(e) => handleChange(e, "request")}
      />
    </>
  );
};

export const PromoLiNormalLabel = ({
  liClass,
  label,
  subStyle,
  bodyClass,
  children,
}) => {
  return (
    <li className={liClass ? liClass : null}>
      <label className={`input_title w20 ${ProStyle.input_title}`}>
        {label}
      </label>
      <div
        className={
          bodyClass ? bodyClass : `input_area w80 ${ProStyle.input_area}`
        }
        style={{ ...subStyle }}
      >
        {children}
      </div>
    </li>
  );
};
