import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ContentBody,
  ContentButton,
  ContentHeader,
  Header,
} from "../../components/Challenge/MobileTemplate/Layout";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientOptionGet } from "../../utils/ClientAuth";

const AfterViewMobile = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const goBack = () => {
    navi(-1);
  };
  const { data, error, isLoading } = useQuery(
    ["afterView", id],
    async () =>
      await ClientOptionGet(
        `/after/view?id=${id}`,
        account.accessTokne,
        account.refreshToken,
        toggle
      ),
    { retry: false }
  );

  return (
    <Header
      label={"활동후기"}
      subLabel={"공모전·대외활동 수상후기 및 합격후기를 보고 싶다면?"}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ContentHeader {...data.result.data} />
          <ContentBody {...data.result.data} />
          <ContentButton
            id={id}
            goBack={goBack}
            type={"after"}
            account={account}
            toggle={toggle}
          />
        </>
      )}
    </Header>
  );
};

export default AfterViewMobile;
