import moment from "moment";
import React, { Suspense, useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { QueryCache, QueryClient, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import SlideUpFromDown from "../../components/Common/PopOver";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import ChangeEmailYn from "../../components/User/Mobile/ChangeEmailYn";
import ChangeNick from "../../components/User/Mobile/ChangeNick";
import ChangePwd from "../../components/User/Mobile/ChangePwd";
import UserContents from "../../components/User/Mobile/UserContents";
import UserContentsBoard from "../../components/User/Mobile/UserContentsBoard";
import UserContentEvent from "../../components/User/Mobile/UserContentsEvent";
import UserFreePrev from "../../components/User/Mobile/UserFreePrev";
import {
  MUserFreeContest,
  MUserFreeLabel,
  MUserFreeLabelLi,
  MUserFreeLabelUl,
  MUserFreeList,
  MyPageHeader,
} from "../../components/User/UserMobile";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientReqGet, ClientReqPost } from "../../utils/ClientAuth";
import useUserInfos from "./hook/useUserInfos";
import styled from "styled-components";
import { MyPoint } from "../../components/User/UserPoint";

const UserIndexMobile = () => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const navi = useNavigate();
  const [more, setMore] = useState(false);
  const [tab, setTab] = useState(0);
  const isUser = useRef(true);
  const [freeTab, setFreeTab] = useState(0);
  const [popOver, setPopOver] = useState({
    open: false,
    type: "",
  });
  const [leaveToggle, setLeaveToggle] = useState(false);

  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  useEffect(() => {
    if (!account.id || !account.accessToken) {
      navi(`/signIn`);
    }
  }, [account]);

  const { data, isError, error, isLoading, refetch, customError } =
    useUserInfos(() => {
      console.info("testsfdfsfsdfsdfsdfd");
    });

  const handleChange = (type) => {
    setPopOver({
      open: true,
      type: type,
    });
  };
  const handleLeaveToggleOn = () => setLeaveToggle(true);
  const handleLeaveToggleOff = () => setLeaveToggle(false);
  const handleLeaveOut = async () => {
    const result = await ClientReqPost(
      `/user/leaveout`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      alert("정상적으로 처리되었습니다.");
      navi("/prepare");
    }
  };
  // console.log(data, isError, isLoading);

  // useEffect(() => {
  //   console.log(isError);
  // }, [isError, isLoading]);

  return (
    <>
      <MyPageHeader>
        <div className={"cUserBasic"}>
          <ul>
            {isLoading ? (
              <Loading />
            ) : isError ? (
              <li style={{ marginRight: "unset" }}>
                <label
                  style={{ width: "100%", textAlign: "center", marginRight: 0 }}
                >
                  로그인이 만료되었습니다. 로그인 후 이용해주시기 바랍니다.
                </label>
              </li>
            ) : (
              <>
                <li>
                  <label>아이디</label>
                  <span>{data.data.email}</span>
                </li>
                <li>
                  <label>이름</label>
                  <span>{data.data.name}</span>
                </li>
                <li>
                  <label>닉네임</label>
                  <span>{data.data.nickName}</span>
                </li>
                <li>
                  <label>회원타입</label>
                  <span>
                    {data.data.userType === 1 ? "일반회원" : "기업회원"}
                  </span>
                </li>
                <li>
                  <label>이메일 수신 여부</label>
                  <span>{data.data.emailYn ? "수신" : "거부"}</span>
                </li>
                <li>
                  <label>내 포인트</label>
                  <Suspense fallback={<></>}>
                    <MyPoint
                      id={account.id}
                      account={account}
                      toggle={toggle}
                    />
                  </Suspense>
                </li>
                <li>
                  <label>가입일자</label>
                  <div className={"cUserAddon"}>
                    <span>
                      {moment(data.data.createdAt).format("YYYY-MM-DD")}
                    </span>
                    {more ? (
                      <HiChevronUp
                        color={"white"}
                        onClick={() => setMore(false)}
                      />
                    ) : (
                      <HiChevronDown
                        color={"white"}
                        onClick={() => setMore(true)}
                      />
                    )}
                  </div>
                </li>
                <AnimateHeight duration={300} height={more ? "auto" : 0}>
                  <li className={"cUserBtns"}>
                    <button
                      type="button"
                      className={"change"}
                      onClick={() => handleChange("password")}
                    >
                      <span>비밀번호 변경</span>
                    </button>
                    <button
                      type="button"
                      className={"change"}
                      onClick={() => handleChange("nickName")}
                    >
                      <span>닉네임 변경</span>
                    </button>
                    <button
                      type="button"
                      className={"change"}
                      onClick={() => handleChange("emailYn")}
                    >
                      <span>이메일 수신 여부 변경</span>
                    </button>
                    <button
                      type="button"
                      className={"change"}
                      onClick={() => navi("/logout")}
                    >
                      <span>로그아웃</span>
                    </button>
                    <button
                      type={"button"}
                      className={"change"}
                      onClick={handleLeaveToggleOn}
                    >
                      <span>회원탈퇴</span>
                    </button>
                  </li>
                </AnimateHeight>
              </>
            )}
          </ul>
        </div>
        {!isError && (
          <div className={"cUserCnt"}>
            <ul>
              <li
                className={tab === 0 ? "active" : ""}
                onClick={() => setTab(0)}
              >
                <label>스크랩</label>
                <span>{isLoading ? 0 : data.data.scrap?.length}</span>
              </li>
              <li
                className={tab === 1 ? "active" : ""}
                onClick={() => setTab(1)}
              >
                <label>이벤트</label>
                <span>0</span>
              </li>
              {/* <li
                className={tab === 2 ? "active" : ""}
                onClick={() => setTab(2)}
              >
                <label>게시글</label>
                <span>3</span>
              </li> */}
            </ul>
          </div>
        )}
        {!isError && (
          <div className={"cUserItems"}>
            {!isLoading && (
              <ul>
                {tab === 0 ? (
                  <UserContents
                    id={account.id}
                    account={account}
                    toggle={toggle}
                  />
                ) : tab === 1 ? (
                  <UserContentEvent account={account} toggle={toggle} />
                ) : (
                  <UserContentsBoard account={account} toggle={toggle} />
                )}
              </ul>
            )}
          </div>
        )}
        {!isError && (
          <MUserFreeContest>
            <MUserFreeLabelUl>
              <MUserFreeLabelLi onClick={() => setFreeTab(0)}>
                <MUserFreeLabel active={freeTab === 0}>무료등록</MUserFreeLabel>
              </MUserFreeLabelLi>
              <MUserFreeLabelLi onClick={() => setFreeTab(1)}>
                <MUserFreeLabel active={freeTab === 1}>수정요청</MUserFreeLabel>
              </MUserFreeLabelLi>
            </MUserFreeLabelUl>
            <Suspense fallback={<Loading />}>
              <UserFreePrev tab={freeTab} account={account} toggle={toggle} />
            </Suspense>
          </MUserFreeContest>
        )}
      </MyPageHeader>
      {!isLoading && (
        <SlideUpFromDown
          open={popOver.open}
          close={() => setPopOver({ ...popOver, open: false })}
        >
          <div className={"slideLabel"}>
            <label>
              {popOver.type === "password"
                ? "비밀번호"
                : popOver.type === "nickName"
                ? "닉네임"
                : popOver.type === "emailYn"
                ? "이메일 수신 여부"
                : ""}{" "}
              변경
            </label>
          </div>
          {popOver.type === "password" ? (
            <ChangePwd
              account={account}
              atkChange={toggle}
              refetch={refetch}
              close={() => setPopOver({ ...popOver, open: false })}
            />
          ) : popOver.type === "nickName" ? (
            <ChangeNick
              close={() => setPopOver({ ...popOver, open: false })}
              refetch={refetch}
              account={account}
              atkChange={toggle}
              nickName={data.data.nickName}
            />
          ) : popOver.type === "emailYn" ? (
            <ChangeEmailYn
              close={() => setPopOver({ ...popOver, open: false })}
              account={account}
              atkChange={toggle}
              refetch={refetch}
              emailYn={data.data.emailYn}
            />
          ) : (
            ""
          )}
        </SlideUpFromDown>
      )}
      <CustomPop
        open={leaveToggle}
        close={handleLeaveToggleOff}
        action={handleLeaveOut}
      />
    </>
  );
};
const CustomPop = ({ open, close, action }) => {
  const [loading, setLoading] = useState(false);
  const handleActive = async () => {
    setLoading(true);
    setTimeout(action, 1000);
  };
  return (
    <PopLayer open={open}>
      <PopBody>
        <PopSection dir="vertical" align="center">
          <p>탈퇴 요청은 철회할 수 없으며</p>
          <p style={{ color: "red" }}>
            재가입 시 동일한 아이디를 <br />
            사용할 수 없습니다
          </p>
          <br />
          <p>
            <b>
              상기 내용을 숙지하였으며
              <br />
              탈퇴를 진행하시겠습니까?
            </b>
          </p>
        </PopSection>

        <PopSection dir="horizontal" align="around">
          {loading ? (
            <Loading />
          ) : (
            <>
              <PopBtn type="button" className={"leave_cancel"} onClick={close}>
                취소
              </PopBtn>
              <PopBtn
                type="button"
                className={"leave_ok"}
                onClick={handleActive}
              >
                탈퇴
              </PopBtn>
            </>
          )}
        </PopSection>
      </PopBody>
    </PopLayer>
  );
};
export default UserIndexMobile;

const PopLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11111;
  padding: 0 1em;
`;

const PopBody = styled.div`
  width: 100%;
  padding: 10px 15px;
  background-color: white;
  border-radius: 10px;
  font-size: 0.65em;
`;
const PopSection = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.dir === "horizontal" ? "row" : "column")};
  justify-content: ${(props) =>
    props.align === "start"
      ? "start"
      : props.align === "center"
      ? "center"
      : "space-around"};
  padding: 0.5em 0;
  > p {
    text-align: center;
  }
`;
const PopBtn = styled.button`
  border: none;
  background-color: transparent;
  font-size: 0.9em;
  margin-top: 1em;
  &.leave_cancel {
    color: red;
  }
  &.leave_ok {
    color: #2bcea2;
  }
`;
