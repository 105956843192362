import React from "react";
import {
  HPHeader,
  HPNAText,
  HPNotAccesable,
} from "../../components/HostCenter/Mobile/MenuList";

const FreeReqMobile = () => {
  return (
    <HPHeader current={"req"}>
      <HPNotAccesable>
        <HPNAText>
          무료등록은 <br />
          PC 버전에서 이용할 수 있습니다.
        </HPNAText>
      </HPNotAccesable>
    </HPHeader>
  );
};

export default FreeReqMobile;
