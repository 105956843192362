import React, { Suspense } from "react";
import {
  DContentsSubMenu,
  DContentWeeklySubContainer,
} from "../../components/DContents/Common/Element";
import Chstyle from "../../assets/css/channel.module.scss";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import WeeklyDataList from "../../components/DContents/Mobile/WeeklyDataList";
import { WeeklyBody } from "../../components/DContents/Weekly/list";

const Weekly = () => {
  // if (isMobile) {
  //   return (
  //     <div className={"container sub"}>
  //       <DContentsSubMenu current={"weekly"} />
  //       <div className={"sub_container"}>
  //         <Suspense fallback={<div></div>}>
  //           <WeeklyDataList />
  //         </Suspense>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="container sub">
      <DContentsSubMenu current={"weekly"} />
      <DContentWeeklySubContainer
        label={"위클리"}
        subLabel={
          "여러분의 소중한 시간을 위해 최신 공모전을 알짜배기로 정리해 드립니다!"
        }
      >
        <Suspense fallback={<div>test</div>}>
          <WeeklyBody />
        </Suspense>
      </DContentWeeklySubContainer>
    </div>
  );
};

export default Weekly;
