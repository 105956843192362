import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { NormalReqApi } from "../../utils/ReqApi";
import loadStyle from "../../assets/css/loader.module.scss";
import { ThumbnailList } from "../../components/DContents/Mobile/MTemplate";
import { HiSearch } from "react-icons/hi";
import { MPaging2 } from "../../components/Common/PagingM";

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;
  .dcMainTitle {
    text-align: center;
    .dcTitle {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;

const Items = styled.ul``;

const SearchArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0.5em 0.5em 0.5em;

  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5em;
  .dcSearchTitle {
    font-size: 0.75em;
    color: white;
    width: 100%;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
`;

const loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div className={loadStyle.loading_spinner}></div>
  </div>
);

const EP = process.env.REACT_APP_AWS_ENDPOINT;
const EmptyMobile = () => {
  const [page, setPage] = useState(1);
  const [sc, setSc] = useState({
    keyword: "",
    keyType: "title",
  });
  const typeRef = useRef(null);
  const wordRef = useRef(null);

  const handleSearch = () => {
    const keyword = wordRef.current.value;
    setSc({
      ...sc,
      keyword,
    });
  };
  const handlePage = (page) => {
    setPage(page * 1);
    window.scrollTo(0, 0);
  };

  const { data, error, isLoading } = useQuery(
    ["emptylist", sc, page],
    async () =>
      await NormalReqApi(
        "get",
        `/emptytime/list?keyType=${sc.keyType}&keyword=${sc.keyword}&page=${page}`
      ),
    { retry: true }
  );

  const handleTextClick = (e) => {
    console.log("click");
  };

  return (
    <Wrap>
      <div className={"dcMainTitle"}>
        <p className={"dcTitle"}>공강시간</p>
      </div>
      <SearchArea>
        <input
          type="text"
          ref={wordRef}
          className="dcSearchTitle"
          onBlurCapture={() => console.log("blur")}
        />
        <HiSearch
          color={"white"}
          className={"dcSearchBtn"}
          onClick={handleSearch}
        />
      </SearchArea>

      {isLoading ? (
        loading
      ) : (
        <>
          <Items>
            {data.data.result.map((item, index) => (
              <ThumbnailList
                key={`dc_list_empty_${index}`}
                {...item}
                url={`/emptytime/${item._id}`}
                src={EP + "w_280/" + item.thumbnail.path}
              />
            ))}
          </Items>
          <MPaging2 data={data} curPage={page} toggle={handlePage} />
        </>
      )}
    </Wrap>
  );
};

export default EmptyMobile;
