import { useQuery } from "react-query";
import { CntClickableNumber, CntNumber } from "./UserTemplate";
import { ClientReqGet2 } from "../../utils/ClientAuth";
import { useNavigate } from "react-router-dom";
import useUserState from "../hook/accountHook";
import moment from "moment";
import { DirectConnect } from "aws-sdk";

// 마이페이지 내 현재 포인트 조회
export const MyPoint = ({ id, account, toggle }) => {
  // 내 포인트 숫자 클릭시 포인트 히스토리 페이지로 이동하기 위한 네비게이터
  const navi = useNavigate();

  // 내 현재 포인트 조회 (히스토리 조회)
  const { data } = useQuery(
    ["userPoint", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/user/point/cur`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: true,
    }
  );

  // 포인트 숫자 클릭시 로직
  const onClickMyPoint = () => {
    console.log("click!");
    navi("/user/point/history", {
      state: {
        id: id,
      },
    });
  };

  // 포인트 히스토리가 없을시, 포인트가 적립된 적이 없기 때문에 0으로 화면 표시
  if (!data.data) {
    return <CntNumber>0</CntNumber>;
  }

  return (
    <CntClickableNumber onClick={onClickMyPoint}>
      {data.data.total + " p"}
    </CntClickableNumber>
  );
};

export const MyPointHistoryMobile = () => {
  const { id, account, toggle } = useUserState(); // custom user hook
  const { data } = useQuery(
    ["userPointHistory", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/user/point/history`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: true,
    }
  );

  const onClickTransferPoint = () => {
    alert("준비중입니다.");
  };
  if (!data.data) {
    return (
      <div>
        <p>포인트 히스토리가 존재하지 않습니다.</p>
      </div>
    );
  }

  if (data.data.history.length == 0) {
    return (
      <div>
        <p>포인트 히스토리가 존재하지 않습니다.</p>
      </div>
    );
  }
  return (
    <ul>
      {data.data.history.map((e) => (
        <li
          key={e.pointRef}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",

            padding: ".5em .7em",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <p style={{ fontSize: ".45em", color: "#999999" }}>
            {moment(e.insertDate).format("yyyy-MM-DD HH:mm")}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <div style={{ lineHeight: "1" }}>
              <span style={{ fontSize: ".65em" }}>{e.name}</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "end",
                fontSize: ".7em",
              }}
            >
              <span style={{ fontWeight: "bold" }}>{e.amount + " p"}</span>
              <span style={{ fontSize: ".7em", color: "#999999" }}>
                {e.curTotalPoint + " p"}
              </span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const CurPointMobile = () => {
  const { id, account, toggle } = useUserState();
  const { data } = useQuery(
    ["userPoint", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/user/point/cur`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: true,
    }
  );
  if (!data.data) {
    return <span>0</span>;
  }

  return (
    <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
      {data.data.total + " p"}
    </span>
  );
};

export const MyPointHistory = () => {
  const { id, account, toggle } = useUserState(); // custom user hook
  const { data } = useQuery(
    ["userPointHistory", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/user/point/history`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: true,
    }
  );

  const onClickTransferPoint = () => {
    alert("준비중입니다.");
  };

  if (!data.data) {
    return (
      <div>
        <p>포인트 히스토리가 존재하지 않습니다.</p>
      </div>
    );
  }

  if (data.data.history.length == 0) {
    return (
      <div>
        <p>포인트 히스토리가 존재하지 않습니다.</p>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          marginBottom: ".5em",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "0 1em",
        }}
      >
        <div
          style={{
            flex: 2,
            padding: "5px 1em",
            borderRadius: "1em",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "1em",
            backgroundColor: "white",
            boxShadow: "0px 0px 10px #00000029",
            fontWeight: "bold",
          }}
        >
          <p>현재 내 포인트</p>
          <span>{data.data.history[0].curTotalPoint + " point"}</span>
        </div>
        <div
          style={{
            padding: "5px 1em",
            borderRadius: "1em",
            background: "linear-gradient(130deg, #12c2e9, #c471ed, #f64f59)",
            boxShadow: "0px 0px 10px #00000029",
            fontSize: "0.8em",
            color: "white",
            cursor: "pointer",
          }}
          onClick={onClickTransferPoint}
        >
          <p>코인으로 전환하기</p>
        </div>
      </div>
      <div
        style={{
          padding: ".7em 1em",
          backgroundColor: "white",
          borderRadius: "1em",
        }}
      >
        <ul>
          {data.data.history.map((e) => (
            <li
              key={e.pointRef}
              style={{
                borderBottom: "1px solid #00000029",
                padding: ".5em 0",
              }}
            >
              <p style={{ fontSize: "0.7em", color: "#999999" }}>
                {moment(e.insertDate).format("yyyy-MM-DD HH:mm")}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <div style={{ fontSize: "0.85em" }}>{e.name}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <span
                    role="amount"
                    style={{ fontSize: "0.85em", fontWeight: "bold" }}
                  >
                    {e.amount + " point"}
                  </span>
                  <span
                    role="totalAmount"
                    style={{ color: "#999999", fontSize: "0.7em" }}
                  >
                    {"잔여 : " + e.curTotalPoint + " point"}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
