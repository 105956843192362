import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import {
  DContentViewInfo,
  DContentView,
} from "../../../components/DContents/Common/Element";
import { NormalReqApi } from "../../../utils/ReqApi";

const EmptytimeSView = ({ id, handleGoBack }) => {
  const { data, error, isLoading } = useQuery(
    ["emptyview", id],
    async () => await NormalReqApi("get", `/emptytime/view/${id}`),
    { retry: true, suspense: true }
  );

  return (
    <DContentView label={"공강시간"}>
      <div className={"board_view qna"}>
        <DContentViewInfo
          title={data.data.title}
          createdAt={moment(data.data.createdAt).format("YYYY-MM-DD")}
          content={data.data.contents}
        />
        <div className={"answer_area"}>
          <div className={"btn_area board"}>
            <a
              className={"btn"}
              style={{ cursor: "pointer" }}
              onClick={handleGoBack}
            >
              목록으로
            </a>
          </div>
        </div>
      </div>
    </DContentView>
  );
};

export default EmptytimeSView;
