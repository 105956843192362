import React, { useEffect, useState } from "react";

import replyStyle from "../../assets/css/reply.module.scss";
import {
  HiChatAlt2,
  HiChevronDown,
  HiChevronUp,
  HiOutlineDotsVertical,
} from "react-icons/hi";
import AnimateHeight from "react-animate-height";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientDelReq, ClientReqPatch } from "../../utils/ClientAuth";

const isOwn = (accountId, userId) => {
  const template = {
    isLogin: false,
    isOwn: false,
  };

  if (accountId) {
    template.isLogin = true;
  }

  if (accountId === userId) {
    template.isOwn = true;
  }
  return { ...template };
};

export const ReplyTopBtn = ({
  nickName,
  id,
  rCnt,
  urCnt,
  toggle,
  own,
  atkChange,
  refetch,
  isRoot,

  isRecommend,
  isUnRecommend,
}) => {
  // const [account]
  const [account, setAccount] = useRecoilState(accountSelect);
  const refrshToken = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const [cnt, setCnt] = useState({
    recommend: 0,
    unRecommend: 0,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCnt({
      recommend: rCnt,
      unRecommend: urCnt,
    });
  }, [rCnt, urCnt]);

  const handleRecommend = async () => {
    const url = `/reply/changecnt/recommend?id=${id}`;

    const result = await ClientReqPatch(
      url,
      {},
      account.accessToken,
      account.refreshToken,
      refrshToken
    );
    // console.log(result);

    if (result.flag && result.data === "inc") {
      setCnt({ ...cnt, recommend: cnt.recommend + 1 });
    } else if (result.flag && result.data === "dec") {
      setCnt({ ...cnt, recommend: cnt.recommend - 1 });
    } else {
      if (result.flag === false) {
        console.log("error");
        console.log(result.msg);
      }
    }
  };

  const handleUnRecommend = async () => {
    const url = `/reply/changecnt/unRecommend?id=${id}`;
    const result = await ClientReqPatch(
      url,
      {},
      account.accessToken,
      account.refreshToken,
      refrshToken
    );
    if (result.flag && result.data === "inc") {
      setCnt({ ...cnt, unRecommend: cnt.unRecommend + 1 });
    } else if (result.flag && result.data === "dec") {
      setCnt({ ...cnt, unRecommend: cnt.unRecommend - 1 });
    } else {
      if (result.flag === false) {
        console.log("error");
        console.log(result.msg);
      }
    }
  };

  const handleDelReply = async () => {
    const url = `/reply?id=${id}`;
    const result = await ClientDelReq(
      url,
      account.accessToken,
      account.refreshToken,
      refrshToken
    );
    if (result.flag) {
      refetch();
    }
  };

  const handleEdit = () => {
    toggle(true);
    setOpen(false);
  };

  return (
    <>
      <div className={replyStyle.nameArea}>
        <label>{nickName}</label>
        {own.isLogin && (
          <div className={replyStyle.btnArea}>
            <div className={replyStyle.btn} onClick={handleRecommend}>
              <span>추천</span>
              <span>{cnt.recommend}</span>
            </div>
            <div className={replyStyle.btn} onClick={handleUnRecommend}>
              <span>비추천</span>
              <span>{cnt.unRecommend}</span>
            </div>
            {own.isOwn && (
              <div className={replyStyle.btn} onClick={() => setOpen(!open)}>
                <HiOutlineDotsVertical />
              </div>
            )}
          </div>
        )}
      </div>
      <AnimateHeight duration={500} height={open ? "auto" : 0}>
        <div className={replyStyle.otherBtn}>
          <ul>
            <li onClick={handleEdit}>수정</li>
            <li onClick={handleDelReply}>삭제</li>
          </ul>
        </div>
      </AnimateHeight>
    </>
  );
};

export const ReplySubBtn = ({
  nickName,
  curId,
  id,
  rCnt,
  wId,
  urCnt,
  refetch,
  editToggle,
}) => {
  // const [account]
  const [account, setAccount] = useRecoilState(accountSelect);
  const [own, setOwn] = useState({
    isLogin: false,
    isOwn: false,
  });

  useEffect(() => {
    setOwn(isOwn(account.id, wId));
  }, [account]);

  const tempChange = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const [cnt, setCnt] = useState({
    recommend: 0,
    unRecommend: 0,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCnt({
      recommend: rCnt,
      unRecommend: urCnt,
    });
  }, [rCnt, urCnt]);

  const handleRecommend = async () => {
    const url = `/reply/changecnt/sub/recommend?id=${id}&curId=${curId}`;

    const result = await ClientReqPatch(
      url,
      {},
      account.accessToken,
      account.refreshToken,
      tempChange
    );
    // console.log(result);

    if (result.flag && result.data === "inc") {
      setCnt({ ...cnt, recommend: cnt.recommend + 1 });
    } else if (result.flag && result.data === "dec") {
      setCnt({ ...cnt, recommend: cnt.recommend - 1 });
    } else {
      if (result.flag === false) {
        console.log("error");
        console.log(result.msg);
      }
    }
  };

  const handleUnRecommend = async () => {
    const url = `/reply/changecnt/sub/unRecommend?id=${id}&curId=${curId}`;
    const result = await ClientReqPatch(
      url,
      {},
      account.accessToken,
      account.refreshToken,
      tempChange
    );
    if (result.flag && result.data === "inc") {
      setCnt({ ...cnt, unRecommend: cnt.unRecommend + 1 });
    } else if (result.flag && result.data === "dec") {
      setCnt({ ...cnt, unRecommend: cnt.unRecommend - 1 });
    } else {
      if (result.flag === false) {
        console.log("error");
        console.log(result.msg);
      }
    }
  };

  const handleDelReply = async () => {
    const url = `/reply/sub?id=${id}&curId=${curId}`;
    const result = await ClientDelReq(
      url,
      account.accessToken,
      account.refreshToken,
      tempChange
    );
    if (result.flag) {
      refetch();
    }
  };

  const handleEdit = () => {
    editToggle(true);
    setOpen(false);
  };

  return (
    <>
      <div className={replyStyle.nameArea}>
        <label>
          <HiChatAlt2 />
          <span>{nickName}</span>
        </label>
        {own.isLogin && (
          <div className={replyStyle.btnArea}>
            <div className={replyStyle.btn} onClick={handleRecommend}>
              <span>추천</span>
              <span>{cnt.recommend}</span>
            </div>
            <div className={replyStyle.btn} onClick={handleUnRecommend}>
              <span>비추천</span>
              <span>{cnt.unRecommend}</span>
            </div>

            {own.isOwn && (
              <div className={replyStyle.btn} onClick={() => setOpen(!open)}>
                <HiOutlineDotsVertical />
              </div>
            )}
          </div>
        )}
      </div>
      <AnimateHeight duration={500} height={open ? "auto" : 0}>
        <div className={replyStyle.otherBtn}>
          <ul>
            <li onClick={handleEdit}>수정</li>
            <li onClick={handleDelReply}>삭제</li>
          </ul>
        </div>
      </AnimateHeight>
    </>
  );
};
