import React, { useEffect } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { challengeSelect } from "../../recoil/AtomConfig";
import GetChallenge from "../../utils/getChallenge";
import loadStyle from "../../assets/css/loader.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;
  .dcMainTitle {
    text-align: center;
    .dcTitle {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;

const Section = styled.div`
  padding: 0.5em 0.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  label {
    width: 100%;
    &.dcSubTitle {
      color: white;
      font-size: 0.75em;

      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .dcMore {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      small {
        font-weight: 200;
        font-size: 0.55em;
      }
    }
  }
`;

const Items = styled.ul`
  color: white;
  font-size: 0.75em;

  > li {
    padding: 0.25em 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    font-size: 0.85em;
    margin-bottom: 0.25em;
    .chgTitle {
    }
    .chgDate {
      color: gray;
      font-size: 0.75em;
      text-align: right;
    }
  }
`;

const loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div className={loadStyle.loading_spinner}></div>
  </div>
);

const ChallengeIndexMobile = () => {
  const { data, error, isLoading } = useQuery(
    ["challengePrev"],
    async () => await GetChallenge(),
    { retry: true }
  );
  const navi = useNavigate();

  return (
    <Wrap>
      <div className={"dcMainTitle"}>
        <p className={"dcTitle"}>공모전 도전하기</p>
      </div>
      <Section>
        <label className={"dcSubTitle"}>
          <span>
            팀원모집
            <br />
            <small>프로젝트를 팀플레이로 진행하고 싶다면?</small>
          </span>
          <span className={"dcMore"} onClick={() => navi(`/teamplay`)}>
            <HiDotsHorizontal />
          </span>
        </label>
        {isLoading ? (
          loading
        ) : (
          <Items>
            {data.team.data.length > 0 ? (
              data.team.data.map((item, index) => (
                <li
                  key={`chg_prev_t_${index}`}
                  onClick={() => navi(`/teamplay/${item._id}`)}
                >
                  <p className={"chgTitle"}>{item.title}</p>
                  <p className={"chgDate"}>
                    {moment(item.createdAt).format("YYYY-MM-DD")}
                  </p>
                </li>
              ))
            ) : (
              <li>
                <span>등록된 게시물이 없습니다</span>
              </li>
            )}
          </Items>
        )}
      </Section>
      <Section>
        <label className={"dcSubTitle"}>
          <span>
            활동후기
            <br />
            <small>공모전·대외활동 수상후기 및 합격후기를 보고 싶다면?</small>
          </span>
          <span className={"dcMore"} onClick={() => navi(`/after`)}>
            <HiDotsHorizontal />
          </span>
        </label>
        {isLoading ? (
          loading
        ) : (
          <Items>
            {data.after.data.length > 0 ? (
              data.after.data.map((item, index) => (
                <li
                  key={`chg_prev_a_${index}`}
                  onClick={() => navi(`/after/${item._id}`)}
                >
                  <p className={"chgTitle"}>{item.title}</p>
                  <p className={"chgDate"}>
                    {moment(item.createdAt).format("YYYY-MM-DD")}
                  </p>
                </li>
              ))
            ) : (
              <li>
                <span>등록된 게시물이 없습니다</span>
              </li>
            )}
          </Items>
        )}
      </Section>
      <Section>
        <label className={"dcSubTitle"}>
          <span>
            자유게시판
            <br />
            <small>익명으로 우리들만의 고민을 이야기해봐요!</small>
          </span>
          <span className={"dcMore"} onClick={() => navi("/freeboard")}>
            <HiDotsHorizontal />
          </span>
        </label>
        {isLoading ? (
          loading
        ) : (
          <Items>
            {data.free.data.length > 0 ? (
              data.free.data.map((item, index) => (
                <li
                  key={`chg_prev_f_${index}`}
                  onClick={() => navi(`/freeboard/${item._id}`)}
                >
                  <p className={"chgTitle"}>{item.title}</p>
                  <p className={"chgDate"}>
                    {moment(item.createdAt).format("YYYY-MM-DD")}
                  </p>
                </li>
              ))
            ) : (
              <li>
                <span>등록된 게시물이 없습니다</span>
              </li>
            )}
          </Items>
        )}
      </Section>
    </Wrap>
  );
};

export default ChallengeIndexMobile;
