import axios from "axios";
import { ExpiredFilter2, getRefreshToken } from "./ReqApi";
const API = process.env.REACT_APP_AWS_API;
export const ClientReqPost = async (
  url,
  inputData,
  accessToken,
  refreshToken,
  setAccessToken
) => {
  const res = await axios.post(
    API + url,
    { ...inputData },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  const { data } = res;
  if (data.statusCode === 200) {
    return data;
  } else if (
    data.statusCode === 401 &&
    data.message === "토큰이 만료되었습니다."
  ) {
    const rTk = await getRefreshToken(refreshToken);
    if (rTk.data.statusCode === 401) {
      throw rTk.data;
    }
    setAccessToken(rTk.data.data.accessToken);

    return await ExpiredFilter2(
      url,
      ClientReqPost,
      inputData,
      rTk.data.data.accessToken,
      refreshToken,
      setAccessToken
    );
  }
};

export const ClientReqPatch = async (
  url,
  inputData,
  accessToken,
  refreshToken,
  setAccessToken
) => {
  const res = await axios.patch(
    API + url,
    { ...inputData },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );

  const { data } = res;

  if (data.statusCode === 200) {
    return data;
  } else if (data.statusCode === 400) {
    return data;
  } else if (
    data.statusCode === 401 &&
    data.message === "토큰이 만료되었습니다."
  ) {
    const rTk = await getRefreshToken(refreshToken);
    setAccessToken(rTk.data.data.accessToken);

    return await ExpiredFilter2(
      url,
      ClientReqPatch,
      inputData,
      rTk.data.data.accessToken,
      refreshToken,
      setAccessToken
    );
  }
};

export const ClientReqGet = async (
  url,
  accessToken,
  refreshToken,
  setAccessToken
) => {
  const res = await axios.get(API + url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: function (status) {
      return status < 500;
    },
  });
  const { data } = res;
  if (data.statusCode === 200) {
    return data;
  } else if (
    data.statusCode === 401 &&
    data.message === "토큰이 만료되었습니다."
  ) {
    const rTk = await getRefreshToken(refreshToken);
    if (rTk.data.statusCode === 401) {
      console.log("refreshToken error");
      throw rTk.data;
    }
    setAccessToken(rTk.data.data.accessToken);

    return await ExpiredFilter2(
      url,
      ClientReqGet,
      {},
      rTk.data.data.accessToken,
      refreshToken,
      setAccessToken
    );
  }
};
export const ClientReqGet2 = async (
  url,
  accessToken,
  refreshToken,
  setAccessToken
) => {
  const res = await axios.get(API + url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: function (status) {
      return status < 500;
    },
  });
  const { data } = res;
  console.log(data);
  if (data.statusCode === 200) {
    return data;
  } else if (
    data.statusCode === 401 &&
    data.message === "토큰이 만료되었습니다."
  ) {
    const rTk = await getRefreshToken(refreshToken);
    if (rTk.data.statusCode === 401) {
      console.log("refreshToken error");
      return rTk.data;
    }
    setAccessToken(rTk.data.data.accessToken);

    return await ExpiredFilter2(
      url,
      ClientReqGet,
      {},
      rTk.data.data.accessToken,
      refreshToken,
      setAccessToken
    );
  } else if (data.statusCode === 404) {
    throw data;
  } else if (data.statusCode === 400) {
    return data;
  }
};

export const ClientOptionGet = async (
  url,
  accessToken,
  refreshToken,
  setAccountToken
) => {
  const res = await axios.get(
    API + url,
    accessToken && {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  const { data } = res;
  if (data.statusCode === 200) {
    return data;
  } else if (
    data.statusCode === 401 &&
    data.message === "토큰이 만료되었습니다."
  ) {
    const rTk = await getRefreshToken(refreshToken);
    // console.log(rTk);
    if (rTk.data.statusCode === 401) {
      // return rTk.data;
      throw rTk.data;
    }

    setAccountToken(rTk.data.data.accessToken);

    return await ExpiredFilter2(
      url,
      ClientOptionGet,
      rTk.data.data.accessToken,
      refreshToken,
      setAccountToken
    );
  }
};

export const ClientOptionGet2 = async (
  url,
  accessToken,
  refreshToken,
  setAccountToken
) => {
  const res = await axios.get(
    API + url,
    accessToken && {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  const { data } = res;
  if (data.statusCode === 200) {
    return data;
  } else if (
    data.statusCode === 401 &&
    data.message === "토큰이 만료되었습니다."
  ) {
    const rTk = await getRefreshToken(refreshToken);
    // console.log(rTk);
    if (rTk.data.statusCode === 401) {
      return rTk.data;
      // throw rTk.data;
    }

    setAccountToken(rTk.data.data.accessToken);

    return await ExpiredFilter2(
      url,
      ClientOptionGet,
      rTk.data.data.accessToken,
      refreshToken,
      setAccountToken
    );
  }
};

export const ClientDelReq = async (
  url,
  accessToken,
  refreshToken,
  setAccessToken
) => {
  const res = await axios.delete(API + url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: function (status) {
      return status < 500;
    },
  });

  const { data } = res;

  if (data.statusCode === 200) {
    return data;
  } else if (
    data.statusCode === 401 &&
    data.message === "토큰이 만료되었습니다."
  ) {
    const rTk = await getRefreshToken(refreshToken);
    if (rTk.data.statusCode === 401) {
      throw rTk.data;
    }
    setAccessToken(rTk.data.data.accessToken);
    return await ExpiredFilter2(
      url,
      ClientDelReq,
      {},
      rTk.data.data.accessToken,
      refreshToken,
      setAccessToken
    );
  } else {
    return data;
  }
};
