import { Link } from "react-router-dom";
import Chstyle from "../../../assets/css/community.module.scss";
import dompurify from "dompurify";
import { ErrorText } from "../../HostCenter/Free/FreeElement";
import moment from "moment";
import ReplyComponent from "../../Reply/ReplyComponent";
const URL = process.env.REACT_APP_AWS_ENDPOINT;

export const ChallengeSubMenu = ({ current }) => {
  return (
    <div className={`subVisual channel ${Chstyle.subVisual}`}>
      <ul className={`sub_menu ${Chstyle.sub_menu}`}>
        <li className={current === "teamplay" ? "active" : null}>
          <Link to={`/teamplay`}>
            <span>팀원모집</span>
          </Link>
        </li>
        <li className={current === "after" ? "active" : null}>
          <Link to={`/after`}>
            <span>활동후기</span>
          </Link>
        </li>
        {/* <li className={current === "qna" ? "active" : null}>
          <Link to={`/qna`}>
            <span>활동Q&A</span>
          </Link>
        </li> */}
        <li className={current === "freeboard" ? "active" : null}>
          <Link to={`/freeboard`}>
            <span>자유게시판</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export const ChallengeWrap = ({ label, children }) => {
  return (
    <div
      className={`sub_container channel ${Chstyle.sub_container} ${Chstyle.channel}`}
    >
      <h2 className={"index_title"}>
        <span>{label}</span>
      </h2>
      {children}
    </div>
  );
};

export const ChallengeSection = ({ className, children }) => {
  return <section className={className}>{children}</section>;
};

export const ChallengePrevList = ({ target, type, title, createdAt }) => {
  return (
    <li>
      <Link to={target}>
        {/* {type && <span className={"brd_category"}>{type}</span>} */}
        <span className={`brd_title ${Chstyle.brd_title}`}>{title}</span>
        <p className={"brd_date"}>{moment(createdAt).format("yyyy-MM-DD")}</p>
      </Link>
    </li>
  );
};

export const ChallengeSubContainer = ({ label, subLabel, children }) => {
  return (
    <div className={`sub_container ${Chstyle.sub_container}`}>
      <div className={`contents mypage ${Chstyle.contents}`}>
        <h2 className="sub_title">
          <span>{label}</span>
          <small>{subLabel}</small>
        </h2>
        {children}
      </div>
    </div>
  );
};

export const ChallengeFilter = ({
  type,
  keyword,
  options,
  toggle,
  handleEnterKey,
}) => {
  return (
    <div className="board_search">
      <select name="searchType" ref={type}>
        {options.map((item, index) => (
          <option key={`options_${index}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <input
        type="text"
        name="searchWrd"
        ref={keyword}
        onKeyDown={(e) => handleEnterKey(e)}
      />
      <button type="submit" onClick={toggle}>
        검색
      </button>
    </div>
  );
};

export const TeamplayData = ({
  _id,
  title,
  category,
  linkYn,
  author,
  createdAt,
}) => {
  return (
    <li className={`td ${Chstyle.td}`}>
      {/* <span className={"w15"}>
        <a href="#" className={"go_contest"}>
          {category === 0 ? "공모전" : category === 1 ? "대외활동" : ""}
        </a>
        <br />
        {linkYn && <a className={"go_contest"}>제 13회 ...</a>}
      </span> */}
      <span className={`brd_title w70 ${Chstyle.brd_title}`}>
        <Link to={`/teamplay/${_id}`}>{title}</Link>
      </span>
      <span className={`brd_name w15 ${Chstyle.brd_name}`}>
        {author.nickName}
      </span>
      <span
        className={`brd_info brd_date w15 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
      >
        {moment(createdAt).format("yyyy-MM-DD HH:mm")}
      </span>
    </li>
  );
};

export const AfterData = ({ target, _id, title, createdAt, author }) => {
  return (
    <li className={`td ${Chstyle.td}`}>
      {/* <span className={"w15"}>
        <a className="go_contest">활동후기</a>
      </span> */}
      <span className={`brd_title w70 ${Chstyle.brd_title}`}>
        <Link to={`/${target}/${_id}`}>{title}</Link>
      </span>
      {target === "freeboard" ? (
        <>
          <span
            className={`brd_info brd_date w30 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
          >
            {moment(createdAt).format("yyyy-MM-DD HH:mm")}
          </span>
        </>
      ) : (
        <>
          <span className={`brd_name w15 ${Chstyle.brd_name}`}>
            {author.nickName}
          </span>
          <span
            className={`brd_info brd_date w15 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
          >
            {moment(createdAt).format("yyyy-MM-DD HH:mm")}
          </span>
        </>
      )}
    </li>
  );
};

export const QnaData = ({
  id,
  title,
  createdAt,
  author,
  category,
  answerYn,
}) => {
  return (
    <li className={`td ${Chstyle.td}`}>
      <Link to={`/qna/${id}`}>
        <span className={`brd_cate w10 ${Chstyle.brd_cate}`}>
          {category === 0
            ? "공모전"
            : category === 1
            ? "대외활동"
            : category === 2
            ? "이벤트"
            : ""}
          {id % 2 === 0 && (
            <>
              <br />제 14회 공모...
            </>
          )}
        </span>
        <span className={`brd_title w55 ${Chstyle.brd_title}`}>{title}</span>
        <span className={`brd_name w10 ${Chstyle.brd_name}`}>
          {author.nickName}
        </span>
        <span
          className={`brd_info brd_date w15 ${Chstyle.brd_info} ${Chstyle.brd_date}`}
        >
          {moment(createdAt).format("yyyy-MM-DD HH:mm")}
        </span>
        <span
          className={`brd_answer w10 ${id % 2 === 0 ? "done" : "ready"} ${
            Chstyle.brd_answer
          }`}
        >
          {answerYn ? "답변 완료" : "답변대기"}
        </span>
      </Link>
    </li>
  );
};

export const ChallengeView = ({
  label,
  title,
  category,
  linkYn,
  linkedTitle,
  author,
  createdAt,
  content,
  attachment,
  children,
}) => {
  const sanitizer = dompurify.sanitize;
  return (
    <div className={"sub_container"}>
      <div className={"contents mypage"}>
        <h2 className={"sub_title"}>
          <span>{label}</span>
        </h2>
        <div className={"board_view teamplay"}>
          <ul>
            <li className="th view">
              <span className={"board_title"}>{title}</span>
              {/* <br />
              <div className={{ marginTop: "0.5em" }}>
                <span
                  className={"category focus"}
                  style={{ marginRight: "0.3em" }}
                >
                  {category}
                </span>
                {linkYn && <span>{linkedTitle}</span>}
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <p className={"board_user"}>{author}</p>
                <p className={"board_date"}>
                  {moment(createdAt).format("yyyy-MM-DD HH:mm")}
                </p>
              </div>
            </li>
            <li className={"td view"}>
              <div
                className={"board_content"}
                dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
              ></div>
            </li>
            {attachment && (
              <li className={"td view"}>
                <span>첨부파일 </span>
                <a href={URL + attachment.path}>{attachment.originName}</a>
              </li>
            )}
            {children}
          </ul>
        </div>
        <div className={"board_view teamplay"}></div>
      </div>
    </div>
  );
};

export const AfterViewComponent = ({
  label,
  title,
  author,
  createdAt,
  content,
  attachment,
  active,
  type,
  children,
}) => {
  const sanitizer = dompurify.sanitize;
  return (
    <div className={"sub_container"}>
      <div className={"contents mypage"}>
        <h2 className={"sub_title"}>
          <span>{label}</span>
        </h2>
        <div className={"board_view teamplay"}>
          <ul>
            <li className={"th view"}>
              <span className={"board_title"}>{title}</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {type !== "freeboard" && (
                  <p className={"board_user"}>{author}</p>
                )}

                <p className={"board_date"}>
                  {moment(createdAt).format("yyyy-MM-DD HH:mm")}
                </p>
              </div>
            </li>
            <li className={"td view"}>
              <div
                className={"board_content"}
                dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
              ></div>
            </li>
            {attachment && (
              <li className={"td view"}>
                <span>첨부파일 </span>
                {label === "자유게시판" ? (
                  <a href={URL + attachment.path}>{attachment.origin}</a>
                ) : (
                  <a href={URL + attachment.path}>{attachment.originName}</a>
                )}
              </li>
            )}
            {children}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const ChallengeSubHead = ({ label, children }) => {
  return (
    <div className="sub_container">
      <div className={"contents mypage"}>
        <h2 className={"sub_title"}>{label}</h2>
        {children}
      </div>
    </div>
  );
};

export const ChallengeDataHead = ({
  title,
  linked,
  linkedTitle,
  category,
  author,
  createdAt,
  answerYn,
}) => {
  return (
    <li className={"th view"}>
      <span className={"qna_category"} style={{ marginRight: "1em" }}>
        {category}
      </span>
      <span className={"board_title"}>{title}</span>
      <div style={{ display: "flex" }}>
        {linked && (
          <Link to={`/contest/1`}>
            <p className={"board_user"} style={{ cursor: "pointer" }}>
              {linkedTitle}
            </p>
          </Link>
        )}
        <p className={"board_user"}>{author}</p>
        <p className={"board_date"}>
          {moment(createdAt).format("yyyy-MM-DD HH:mm")}
        </p>
        <p className={`board_status ${answerYn && "standby"}`}>
          {answerYn ? "답변완료" : "답변대기"}
        </p>
      </div>
    </li>
  );
};

export const ChallengeHTML = ({ content, answer }) => {
  const sanitizer = dompurify.sanitize;
  return (
    <>
      <li className={"td view"}>
        <div
          className={"board_content"}
          dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
        ></div>
      </li>
      <li className={"td view"}>
        <div
          className={"board_content"}
          dangerouslySetInnerHTML={{ __html: sanitizer(answer) }}
        ></div>
      </li>
    </>
  );
};

export const ChallengeFile = ({ attachment }) => {
  return (
    attachment && (
      <li className={"td view"}>
        <span>첨부파일 </span>
        <a href={URL + attachment.path}>{attachment.originName}</a>
      </li>
    )
  );
};

export const CFormElement = ({ label, className, children, error }) => {
  return (
    <div className={"input_area"}>
      <label className={className ? className : "board_title w10"}>
        {label}
      </label>
      {children}
      {error && (
        <ErrorText style={{ marginLeft: "10%" }}>{error.message}</ErrorText>
      )}
    </div>
  );
};
// export const
