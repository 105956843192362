import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";
import { DContentViewInfoWithFile } from "../Common/Element";

const EventSubmitResultViewPc = ({ id }) => {
  const navi = useNavigate();

  const { data, error } = useQuery(
    ["eResultView", id],
    async () => await NormalReqApi("get", `/eventresult/view?id=${id}`),
    { retry: true, suspense: true }
  );

  const handleGoBack = () => {
    navi(-1);
  };
  return (
    <>
      <DContentViewInfoWithFile
        {...data.data}
        // title={data.data.title}
        // createdAt={moment(data.data.createdAt).format("YYYY-MM-DD")}
        // content={data.data.content}
      />
      <div className={"answer_area"}>
        <div className={"btn_area board"}>
          <a
            className="btn"
            style={{ cursor: "pointer" }}
            onClick={handleGoBack}
          >
            목록으로
          </a>
        </div>
      </div>
    </>
  );
};

export default EventSubmitResultViewPc;
