import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import custom from "../../assets/css/header.module.scss";
import Default280 from "../../assets/img/common/img_posterDefault_280.jpg";
import { NormalReqApi } from "../../utils/ReqApi";

const setting = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  infinite: false,
  centerMode: false,
  focusOnSelect: true,
  arrows: false,
};

const MainSection4 = ({ className, list }) => {
  const [curList, setCurList] = useState({
    loading: true,
    list: [],
  });

  const getData = async () => {
    const res = await NormalReqApi("get", "contest/new");
    if (res && res.statusCode === 200) {
      setCurList({
        loading: false,
        list: [...res.data],
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className={className}>
      <h2 className={`sectionTit ${custom.sectionTit}`}>
        <span>신규 공모전</span>
        <small>대티즌에 등록된 최신 공모전을 확인하세요!</small>
      </h2>
      <div className={`banner_list new ${custom.banner_list}`}>
        {isMobile ? (
          <Slider {...setting}>
            {!curList.loading &&
              curList.list.length > 0 &&
              curList.list.map((item, index) => (
                <Link
                  key={`m_n_c_${index}`}
                  to={`/contest/view/${item.type}/${item.id}`}
                >
                  <img src={item.thumbnail} />
                </Link>
              ))}
          </Slider>
        ) : (
          <ul className={`ul_banner_section4New`}>
            {!curList.loading &&
              curList.list.length > 0 &&
              curList.list.map((item, index) => (
                <li key={`fc_${index}`}>
                  <Link
                    to={`/contest/view/${item.type}/${item.id}`}
                    state={{ isOnChain: item.type === 2 }}
                  >
                    <img src={item.thumbnail} />
                  </Link>
                </li>
              ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default MainSection4;
