import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { NormalReqApi } from "../../utils/ReqApi";
import { BannerSlider, DetailSlider, ThumbSlider } from "./DetailSlider";

import { TempListData } from "./TempData";
const TEMP_LIST = TempListData;

const SETTINGS_THUMB = {
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
  dots: false,
  infinite: true,
  centerMode: false,
  focusOnSelect: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 6000,
};

const SETTINGS_SPB = {
  rows: 2,
  // slidesPerRow: 1,
  slidesToShow: 4,
  slidesPerRow: 1,

  // slidesToScroll: 1,
  variableWidth: true,
  dots: false,
  centerMode: false,
  infinite: true,
  focusOnSelect: true,
  autoplay: true,
  autoplaySpeed: 6000,
  // responsive: [
  //   {
  //     breakpoint: 425,
  //     settings: {
  //       rows: 1,
  //       slidesToShow: 1,
  //       slidesPerRow: 1,
  //     },
  //   },
  // ],
};

const MainSection2 = ({
  className,
  subClassName,
  detailClassName,
  thumbClassName,
}) => {
  const SETTING_DETAIL = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    fade: true,
  };
  const [curDetail, setCurDetail] = useState(null);
  const [curSlider, setCurSlider] = useState(null);

  const [curList, setCurList] = useState({
    loading: true,
    list: [],
  });
  const [curList2, setCurList2] = useState({
    loading: true,
    list: [],
  });

  const getData = async () => {
    const res = await NormalReqApi(
      "get",
      `banner/1/${isMobile ? "phone" : "pc"}`
    );
    if (res && res.statusCode === 200) {
      setCurList({
        loading: false,
        list: [...res.data],
      });
    }
  };

  const getSpbData = async () => {
    const res = await NormalReqApi(
      "get",
      `banner/2/${isMobile ? "phone" : "pc"}`
    );
    if (res && res.statusCode === 200) {
      setCurList2({
        loading: false,
        list: [...res.data],
      });
    }
  };

  useEffect(() => {
    getData();
    getSpbData();
  }, []);

  return (
    <section className={className}>
      <div className={"bannerArea specialA"}>
        <div className={subClassName}>
          <h2 className={"sectionTit"}>
            <span>Special 배너 A</span>
            <small>대티즌이 Pick한 다양한 공모전/대외활동을 확인하세요!</small>
          </h2>
          <ThumbSlider
            className={`center bannerList ${isMobile ? "mobile" : ""}`}
            settings={SETTINGS_THUMB}
            setSlider={setCurSlider}
            asNavFor={curDetail}
            list={curList.loading ? TEMP_LIST : curList.list}
          />
          <DetailSlider
            type="spa"
            className="bannerDetail"
            settings={SETTING_DETAIL}
            detailClassName={detailClassName}
            setSlider={setCurDetail}
            asNavFor={curSlider}
            list={curList.loading ? TEMP_LIST : curList.list}
          />
        </div>
      </div>
      <div className={"bannerArea specialB"}>
        <div className={subClassName}>
          <h2 className={"sectionTit"}>
            <span>Special 배너 B</span>
            <small>대티즌이 pick 한 다양한 공모전/대외활동을 확인하세요!</small>
          </h2>
          <BannerSlider
            settings={SETTINGS_SPB}
            className={"center bannerList " + (isMobile ? "mo" : "")}
            list={curList2.loading ? TEMP_LIST : curList2.list}
          />
        </div>
      </div>
    </section>
  );
};

export default MainSection2;
