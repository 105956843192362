import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  HiCheck,
  HiChevronDown,
  HiEye,
  HiRefresh,
  HiSearch,
} from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled, { css } from "styled-components";
import { MPaging } from "../../components/Common/PagingM";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { commonSelect } from "../../recoil/AtomConfig";
import { NormalReqApi, tempGet } from "../../utils/ReqApi";

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;

  .dcMainTitle {
    text-align: center;
    .dcTitle {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;

const Section = styled.div`
  padding: 0.25em 0.5em;
  &.customContestFilter {
    > ul {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      font-size: 0.75em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow-x: scroll;
      padding-bottom: 1em;
      > li {
        padding: 0.25em 0.5em;
        margin-right: 0.25em;
        opacity: 0.5;
        &.active {
          opacity: 1;
        }

        > label {
          font-size: 0.8em;
          background-color: gray;
          padding: 0.25em 0.75em;
          border-radius: 10px;
          color: white;
        }
      }
    }
  }
  &.customContestItems {
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: stretch;

      > li {
        width: calc((100% - 1em) / 2);
        display: flex;
        flex-direction: column;

        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .customTitleArea {
          /* padding-bottom: 0.25em; */
          p {
            color: white;
            &.customCTitle {
              word-break: break-all;
              font-size: 0.65em;
              font-weight: bold;
            }
            &.customCHost {
              text-align: left;
              padding: 0.25em 0;
              color: rgba(255, 255, 255, 0.4);
              font-size: 0.55em;
              font-weight: 300;
              white-space: normal;
              width: 100%;
              word-break: break-all;
            }
          }
        }
        .customEndDate {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 0.15em;

          p {
            font-size: 0.55em;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.4);
          }
        }
        .customTypeArea {
          padding-top: 0.25em;
          > ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow-x: scroll;
            flex-wrap: nowrap;
            white-space: nowrap;
            padding-bottom: 0.25em;
            > li {
              font-size: 0.45em;
              padding: 0.25em 0.5em;
              margin-right: 0.25em;
              border-radius: 5px;
              &.typeCategory {
                color: white;
                background-color: green;
              }
              &.typeTarget {
                color: white;
                background-color: orange;
              }
            }
          }
        }
        .customEtc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 0.5em;
          color: gray;

          font-size: 0.5em;
          .customViewCnt {
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
`;
const SortSection = styled.div`
  padding: 0 0.25em 10px 0.25em;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const SortItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SortText = styled.span`
  padding-right: 5px;
  font-size: 0.55em;
  color: gray;
`;

const SearchSection = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 7;
`;
const SearchBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000d9;
  z-index: 6;

  transform: translateY(-100vh);
  transition: transform 0.1s linear;
  ${(props) =>
    props.active &&
    css`
      transform: translateY(0);
    `}
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e1e1e11f;
  width: 100%;
  > input {
    padding: 0.25em 0.5em 0.25em 0.5em;
    width: 100%;
    font-size: 0.55em;
    color: white;
    background-color: transparent;
    border: 0px;
    &:focus {
      outline: none;
    }
  }
`;
const SearchType = styled.div`
  padding: 0.25em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.65em;
  color: white;
`;
const SearchIconBox = styled.div`
  position: absolute;
  right: 0.5em;
`;

const Pop = styled.div`
  position: fixed;
  bottom: ${(props) => (props.open ? 0 : `-2000px`)};
  width: 100%;
  ${(props) =>
    props.type === "search" &&
    css`
      z-index: 7;
    `}
  transition: all 0.3s ease-in-out;

  .customPopBg {
    position: fixed;
    top: 0;
    width: 100%;
    ${(props) =>
      props.type === "search" &&
      css`
        z-index: 8;
      `}
    height: ${(props) => (props.open ? `100%` : 0)};
    z-index: ${(props) => (props.open ? 1 : -1)};

    background-color: rgba(0, 0, 0, 0.5);
  }
  .customPopBody {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;

    background-color: white;

    .customPopContents {
      .customPopBtns {
        padding: 0.25em 0.5em;
        /* border-bottom: 1px solid gray; */
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .customPopItems {
        height: 288px;
        overflow-y: scroll;
        padding-inline-start: 1em;
        padding-inline-end: 1em;
        padding-bottom: 1em;

        > li {
          font-size: 0.75em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 0.5em;
          .customPopItem {
            font-size: 0.8em;
          }
        }
      }
    }
  }
`;

const ContestStatus = styled.span`
  background-color: ${(props) =>
    props.status === 0 ? `orange` : props.status === 1 ? `green` : `gray`};

  margin-left: 0 !important;
  padding: 0.25em 0.35em;
  border-radius: 10px;
  color: white;
`;

const ContestThumbImg = styled.img`
  height: 100%;
  @media (max-width: 425px) {
    max-height: 250px;
  }
  @media (min-width: 426px) and (max-width: 767px) {
    max-height: 500px;
  }
`;

const EP = process.env.REACT_APP_AWS_ENDPOINT;

const ContestIndexMobile = () => {
  const navi = useNavigate();
  const { type } = useParams();
  const [filter, setFilter] = useState({
    open: false,
    type: "",
    list: [],
  });
  const [curFilter, setCurFilter] = useState({
    category: [],
    target: [],
    host: [],
    totalReward: [],
  });
  const [searchState, setSearchState] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchType, setSearchType] = useState({
    open: false,
    type: "공모명",
    typeId: "title",
    keyword: undefined,
  });
  const [sortOpen, setSortOpen] = useState(false);
  const [sortState, setSortState] = useState({
    sortType: 0,
    label: "최신순",
  });

  const [common, setCommon] = useRecoilState(commonSelect);

  const getCommon = async () => {
    if (common.loading) {
      const res = await NormalReqApi("get", "/basic");
      if (res && res.statusCode === 200) {
        setCommon((prev) => {
          const temp = { ...prev };
          temp.loading = false;
          temp.category = [...res.data.category];
          temp.host = [...res.data.host];
          temp.totalReward = [...res.data.totalReward];
          temp.target = [...res.data.target];
          temp.region = [...res.data.region];
          return { ...temp };
        });
      }
    }
  };

  useEffect(() => {
    getCommon();
  }, []);

  const handleFilter = (fType) => {
    let list = [];
    const typeIndex = type === "activity" ? 2 : 1;

    if (fType === "category" || fType === "totalReward") {
      list = [...common[fType].filter((f) => f.type === typeIndex)];
    } else {
      list = [...common[fType]];
    }
    setFilter({
      open: true,
      type: fType,
      list: list,
    });
  };
  const handleUnFitler = () => {
    setFilter({
      open: false,
      type: "",
      list: [],
    });
  };

  const handleSelFilter = (type, code) => {
    const isExist = curFilter[type].filter((f) => f === code).length > 0;
    if (isExist) {
      switch (type) {
        case "category":
          setCurFilter({
            ...curFilter,
            category: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
        case "target":
          setCurFilter({
            ...curFilter,
            target: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
        case "host":
          setCurFilter({
            ...curFilter,
            host: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
        case "totalReward":
          setCurFilter({
            ...curFilter,
            totalReward: [...curFilter[type].filter((f) => f !== code)],
          });
          break;
      }
    } else {
      switch (type) {
        case "category":
          setCurFilter({
            ...curFilter,
            category: [...curFilter[type], code],
          });
          break;
        case "target":
          setCurFilter({
            ...curFilter,
            target: [...curFilter[type], code],
          });
          break;
        case "host":
          setCurFilter({
            ...curFilter,
            host: [...curFilter[type], code],
          });
          break;
        case "totalReward":
          setCurFilter({
            ...curFilter,
            totalReward: [...curFilter[type], code],
          });
          break;
      }
    }
  };

  const [sc, setSc] = useState({
    page: 1,
    scale: 12,
  });
  const keywordRef = useRef(null);
  //   useEffect(() => {
  //     console.log(curFilter.category.flat(1).toString());
  //   }, [curFilter]);
  const { data, error, isLoading } = useQuery(
    ["contestM", type, curFilter, sc, searchType, sortState],
    async () =>
      await NormalReqApi(
        "get",
        `/contest?page=${sc.page}&contestType=${
          type === "contest" ? 0 : type === "activity" ? 1 : 2
        }&type=${searchType.typeId}${
          searchType.keyword ? `&keyword=${searchType.keyword}` : ""
        }&sort=${sortState.sortType}&category=${curFilter.category
          .flat(1)
          .toString()}&target=${curFilter.target
          .flat(1)
          .toString()}&host=${curFilter.host
          .flat(1)
          .toString()}&totalReward=${curFilter.totalReward.flat(1).toString()}`
      ),
    { retry: false }
  );

  const changePage = (page) => {
    setSc({
      ...sc,
      page: page * 1,
    });
  };

  const calcCurrentStatus = (startDate, endDate) => {
    const nowDate = moment();
    const sDate = moment(startDate);
    const eDate = moment(endDate);

    const resultTemplate = {
      status: 0,
      text: "",
    };
    if (nowDate.isBefore(sDate)) {
      resultTemplate.status = 0;
      resultTemplate.text = "예정";
    } else if (nowDate.isAfter(sDate) && nowDate.isBefore(eDate)) {
      resultTemplate.status = 1;
      resultTemplate.text = "진행중";
    } else if (nowDate.isAfter(eDate)) {
      resultTemplate.status = 2;
      resultTemplate.text = "마감";
    } else {
      resultTemplate.status = 0;
      resultTemplate.text = "예정";
    }

    return (
      <ContestStatus status={resultTemplate.status}>
        {resultTemplate.text}
      </ContestStatus>
    );
  };

  const handleBgClose = (e) => {
    setSearchState(false);
  };
  const handleSTypeClose = () => {
    setSearchOpen(false);
  };
  const handleSearchTypeChange = ({ type, typeId }) => {
    setSearchType({
      ...searchType,
      type,
      typeId,
    });
  };
  const handleSearchToggle = () => {
    const keyword = keywordRef?.current?.value;
    if (keyword.length > 0) {
      setSearchType({
        ...searchType,
        keyword,
      });
    }
  };
  const handleRefresh = () => {
    setSearchType({
      ...searchType,
      keyword: "",
    });
  };
  const handleSortClose = () => {
    setSortOpen(false);
  };

  return (
    <Wrap>
      <div className={"dcMainTitle"}>
        <p className={"dcTitle"}>
          {type === "contest"
            ? "공모전"
            : type === "activity"
            ? "대외활동"
            : "코인 공모전"}
        </p>
      </div>
      <Section role={"filter"} className={"customContestFilter"}>
        <ul>
          <li className={curFilter.category.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("category")}>공모분야</label>
          </li>
          <li className={curFilter.target.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("target")}>응모대상</label>
          </li>
          <li className={curFilter.host.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("host")}>주최사</label>
          </li>
          <li className={curFilter.totalReward.length > 0 ? "active" : ""}>
            <label onClick={() => handleFilter("totalReward")}>시상내역</label>
          </li>
        </ul>
      </Section>

      <SearchBg active={searchState} onClick={handleBgClose} />
      <SearchSection onClick={() => setSearchState(true)}>
        <SearchBox>
          <SearchType onClick={() => setSearchOpen(true)}>
            <span>{searchType.type}</span>
            <HiChevronDown size={15} color={"white"} />
          </SearchType>
          <input type="text" ref={keywordRef} />
        </SearchBox>
        <SearchIconBox>
          <HiSearch size={20} color={"white"} onClick={handleSearchToggle} />
          <HiRefresh size={20} color={"white"} onClick={handleRefresh} />
        </SearchIconBox>
      </SearchSection>
      <SortSection>
        <SortItems onClick={() => setSortOpen(true)}>
          <SortText>{sortState.label}</SortText>
          <HiChevronDown size={15} color={"gray"} />
        </SortItems>
      </SortSection>
      <Section role={"contents"} className={"customContestItems"}>
        {isLoading ? (
          <Loading />
        ) : (
          <ul>
            {data.result.list.map((item, index) => (
              <li
                key={`contest_${type}_${item._id}_${index}`}
                onClick={() => navi(`/mDetail/${item._id}`)}
              >
                <ContestThumbImg src={EP + "w_350/" + item.thumbnail.path} />
                <div className={"customTitleArea"}>
                  <p className={"customCTitle"}>{item.title}</p>
                  <p className={"customCHost"}>{item.host.name}</p>
                </div>

                <div className={"customEtc"}>
                  <div className={"customViewCnt"}>
                    {calcCurrentStatus(item.startDate, item.endDate)}
                  </div>
                  <p className={"customCreatedAt"}>
                    ~ {moment(item.endDate).format("YYYY-MM-DD")}
                  </p>
                </div>
                <div
                  className={"customEtc"}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className={"customViewCnt"}>
                    <HiEye />
                    <span>{item.viewCnt}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Section>
      <Section role={"page"} className={"customCPage"}>
        {!isLoading && <MPaging {...data} toggle={changePage} />}
      </Section>
      <Pop open={filter.open}>
        <div className={"customPopBg"} onClick={handleUnFitler}></div>
        <div className={"customPopBody"}>
          <div className={"customPopContents"}>
            <div className={"customPopBtns"}>
              <label></label>
              <span onClick={handleUnFitler}>x</span>
            </div>
            <ul className={"customPopItems"}>
              {filter.open &&
                filter.list.map((item, index) => (
                  <li key={`pop_filter_${filter.type}_${index}`}>
                    <span
                      className={"customPopItem"}
                      onClick={() => handleSelFilter(filter.type, item.code)}
                    >
                      {filter.type === "category" ? item.sub : item.text}
                    </span>
                    <span className={"customPopStatus"}>
                      {curFilter[filter.type].filter((f) => f === item.code)
                        .length > 0 && <HiCheck color={"green"} />}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Pop>
      <Pop open={searchOpen} type="search">
        <div className={"customPopBg"} onClick={handleSTypeClose}></div>
        <div className={"customPopBody"}>
          <div className={"customPopContents"}>
            <div className={"customPopBtns"}>
              <label></label>
              <span onClick={handleSTypeClose}>x</span>
            </div>
            <ul className={"customPopItems"}>
              {[
                { typeId: "title", label: "공모명" },
                { typeId: "host.name", label: "주최사명" },
              ].map((item, index) => (
                <li key={`pop_filter_${item.typeId}_${index}`}>
                  <span
                    className={"customPopItem"}
                    onClick={() =>
                      handleSearchTypeChange({
                        type: item.label,
                        typeId: item.typeId,
                      })
                    }
                  >
                    {item.label}
                  </span>
                  <span className={"customPopStatus"}>
                    {searchType.typeId === item.typeId && (
                      <HiCheck color={"green"} />
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Pop>
      <Pop open={sortOpen} type="search">
        <div className={"customPopBg"} onClick={handleSortClose}></div>
        <div className={"customPopBody"}>
          <div className={"customPopContents"}>
            <div className={"customPopBtns"}>
              <label></label>
              <span onClick={handleSortClose}>x</span>
            </div>
            <ul className={"customPopItems"}>
              {[
                { sortType: 0, label: "최신순" },
                { sortType: 1, label: "조회순" },
                { sortType: 2, label: "마감일순" },
              ].map((item, index) => (
                <li key={`pop_filter_${item.sortType}_${index}`}>
                  <span
                    className={"customPopItem"}
                    onClick={() =>
                      setSortState({
                        label: item.label,
                        sortType: item.sortType,
                      })
                    }
                  >
                    {item.label}
                  </span>
                  <span className={"customPopStatus"}>
                    {sortState.sortType === item.sortType && (
                      <HiCheck color={"green"} />
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Pop>
    </Wrap>
  );
};

export default ContestIndexMobile;
