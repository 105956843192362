import moment from "moment";
import React, { useState } from "react";
import { HiEye } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styled from "styled-components";
import ReplyMobile2 from "../../Reply/ReplyMobile2";

export const PageHeader = styled.div`
  margin-top: 3.5em;
  height: 100%;
  .cLabel {
    text-align: center;
    .labelSlider {
      display: flex;
      justify-content: center;
      align-items: center;
      > li {
        padding-right: 1em;
        &:last-child {
          padding-right: 0;
        }
        span {
          text-align: center;
          color: gray;
          font-size: 0.55em;
          display: inline-block;
          &.active {
            color: white;
            font-size: 1em;
            border-bottom: 5px solid #e9360c;
          }

          margin-bottom: 1em;
        }
      }
    }

    > p {
      color: white;
      font-size: 0.55em;
    }
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export const CreateBtn = styled.button`
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  color: gray;
  /* box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.1); */

  margin-bottom: 0.25em;
  font-size: 0.55em;
  > span {
  }
`;

export const ChgUl = styled.ul`
  padding: 0.25em 0.5em;
  color: white;
  > li {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding-bottom: 0.25em;
    margin-bottom: 0.25em;
    &.cNoContents {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75em;
    }
    .cTitle {
      font-size: 0.75em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
    .cUser {
      font-size: 0.55em;
      color: gray;
    }
    .cDate {
      font-size: 0.55em;
    }
    .cDateCnt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cCnt {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        > span {
          font-size: 14px;
          margin-left: 0.25em;
        }
      }
    }
  }
`;

const CHeader = styled.div`
  padding: 0 0.5em;
  .chead_title {
    .cTitle {
      white-space: normal;
      overflow-wrap: break-word;
      font-size: 0.75em;
      color: white;
      padding-bottom: 0.25em;
    }
    .chead_dateArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .chead_cntArea {
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
          font-size: 15px;
          margin-left: 0.5em;
          color: gray;
        }
      }

      .cDate {
        text-align: right;
        font-size: 0.55em;
        color: gray;
      }
    }
  }
`;

const CContent = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  margin-top: 0.5em;
  padding: 0.5em;
  font-size: 0.75em;
`;
const CButtonArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5em 0;
  > button {
    width: calc((100% - 1em) / 2);
    border: none;
    background-color: #232731;
    color: white;
    box-shadow: 0px 0px 1px 0px white;
    > span {
      font-size: 0.65em;
    }
  }
`;

export const CreateButton = ({ label, target }) => {
  return (
    <CreateBtn>
      <span>{label}</span>
    </CreateBtn>
  );
};

export const ContentHeader = ({ title, viewCnt, createdAt }) => {
  return (
    <CHeader>
      <div className={"chead_title"}>
        <p className={"cTitle"}>{title}</p>
        <div className={"chead_dateArea"}>
          <div className={"chead_cntArea"}>
            <HiEye color={"gray"} size={13} />
            <span>{!viewCnt ? 0 : viewCnt}</span>
          </div>
          <p className={"cDate"}>{moment(createdAt).format("YYYY-MM-DD")}</p>
        </div>
      </div>
    </CHeader>
  );
};

export const ContentBody = ({ content }) => {
  return (
    <CContent>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </CContent>
  );
};

export const ContentButton = ({ id, type, account, toggle, goBack }) => {
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const [replyTotal, setReplyTotal] = useState(0);

  return (
    <>
      <CButtonArea>
        <button
          type="button"
          className={"cReply"}
          onClick={() => setOpen(true)}
        >
          <span>댓글</span>
          <span className={"cReplyCnt"}>({replyTotal})</span>
        </button>
        <button type="button" className={"cBack"} onClick={goBack}>
          <span>뒤로가기</span>
        </button>
      </CButtonArea>
      <ReplyMobile2
        open={open}
        close={close}
        id={id}
        type={type}
        account={account}
        toggle={toggle}
        setReply={setReplyTotal}
      />
    </>
  );
};

export const Header = ({ label, subLabel, children }) => {
  const navi = useNavigate();

  return (
    <PageHeader>
      <div className={"cLabel"}>
        <ul className={"labelSlider"}>
          {label === "팀원모집" ? (
            <>
              <li>
                <span onClick={() => navi(`/after`)}>활동후기</span>
              </li>
              <li>
                <span className={"active"}>팀원모집 </span>
              </li>
              <li>
                <span onClick={() => navi(`/freeboard`)}>자유게시판</span>
              </li>
            </>
          ) : label === "활동후기" ? (
            <>
              <li>
                <span onClick={() => navi(`/teamplay`)}>팀원모집</span>
              </li>
              <li>
                <span className={label === "활동후기" ? "active" : ""}>
                  활동후기
                </span>
              </li>
              <li>
                <span onClick={() => navi(`/freeboard`)}>자유게시판</span>
              </li>
            </>
          ) : (
            <>
              <li>
                <span onClick={() => navi(`/teamplay`)}>팀원모집</span>
              </li>
              <li>
                <span className={"active"}>자유게시판</span>
              </li>
              <li>
                <span onClick={() => navi(`/after`)}>활동후기</span>
              </li>
            </>
          )}
        </ul>
        <p>{subLabel}</p>
      </div>
      {children}
    </PageHeader>
  );
};

export const ChgLiItems = ({
  isPrivate,
  _id,
  title,
  viewCnt,
  createdAt,
  author,
  linkTo,
}) => {
  return (
    <li
      // key={`tp_${item._id}_${index}`}
      onClick={linkTo}
    >
      {!isPrivate && <p className={"cUser"}>{author.nickName}</p>}

      <p className={"cTitle"}>{title}</p>

      <div className={"cDateCnt"}>
        <div className={"cCnt"}>
          <HiEye color={"white"} size={14} />
          <span>{viewCnt}</span>
        </div>
        <span className={"cDate"}>{createdAt}</span>
      </div>
    </li>
  );
};
