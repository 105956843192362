import React, { Suspense } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ChallengeSubMenu } from "../../components/Challenge/Common/Element";
import NoticeDetail from "../../components/Notice/NoticeDetail";
import { NormalReqApi } from "../../utils/ReqApi";

const NoticeView = () => {
  const { id, type } = useParams();
  const navi = useNavigate();

  return (
    <div className="container sub">
      <ChallengeSubMenu current={type} />
      <Suspense fallback={<div></div>}>
        <NoticeDetail type={type} id={id} />
      </Suspense>
    </div>
  );
};

export default NoticeView;
