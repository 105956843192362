import { useRecoilState } from "recoil";
import { accountSelect } from "../../../recoil/AtomConfig";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  ClientOptionGet,
  ClientOptionGet2,
  ClientReqGet,
} from "../../../utils/ClientAuth";

export default function useContestInfo(id) {
  const [account, setAccount] = useRecoilState(accountSelect);
  const navi = useNavigate();
  const refrechAccount = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  const query = useQuery(
    ["contestView", id, account, refrechAccount],
    async () =>
      await ClientOptionGet2(
        `/contest/view/${id}`,
        account.accessToken,
        account.refreshToken,
        // 44354345345,
        refrechAccount
      ),
    {
      retry: true,
      onSuccess: (e) => {
        console.log("succdess-=========");
        if (e.statusCode && e.statusCode === 401) {
          alert("로그인이 만료되었습니다. 로그인 페이지로 이동합니다.");
          navi("/signin");
        }
      },
      onError: (e) => {
        console.log(e);
        if (e.statusCode === 401) {
          alert("로그인이 만료되었습니다. 로그인 페이지로 이동합니다.");
          navi("/signin");
        }
      },
    }
  );

  console.log(query);

  return query;
}
