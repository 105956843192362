import moment from "moment";
import React, { Suspense, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ClientReqGet, ClientReqGet2 } from "../../utils/ClientAuth";
import { Loading } from "../DContents/Mobile/MTemplate";

const FreeUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const FreeLi = styled.li`
  padding: 0.15em 0.3em;
  margin: 0.15em 0.5em 0.15em 0;
  font-size: 0.7em;
  font-weight: 400;

  ${(props) => props.active && `border-bottom:2px solid red`};
`;

const FreeDataUl = styled.ul``;
const FreeDataLi = styled.li`
  font-size: 0.75em;
  padding: 0.25em 0;
  margin: 0.25em 0;
  border-bottom: 1px solid #efefef;
`;
const FreeDataStatus = styled.div`
  margin-top: 0.25em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const FreeDataType = styled.span`
  color: #a0a0a0;
  font-size: 0.9em;
  padding: 0 0.25em 0 0;
`;
const FreeDataCurStatus = styled.span`
  padding: 0.15em 0.35em;
  border-radius: 5px;
  font-size: 0.7em;
  font-weight: 400;
  color: #ffffff;
  background-color: ${(props) => (props.confirm ? `green` : `gray`)};
`;

const FreeDataTitle = styled.p`
  font-size: 0.9em;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
const FreeDataDate = styled.p`
  color: #a0a0a0;
  text-align: right;
  font-size: 0.75em;
`;

// 무료등록 요청 top3
const FreeReq = ({ id, account, toggle }) => {
  const navi = useNavigate();
  const { data, error, isLoading } = useQuery(
    ["userFreeContest", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/reqcontest/my/top3`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      onSuccess: (e) => {
        if (e?.statusCode && e?.statusCode === 401) {
          navi("/signin");
        }
        if (e?.statusCode === 400) {
          navi("/signin");
        }
      },
      onError: (e) => {
        if (e?.statusCode === 404) {
          navi("/signin");
        }
      },
    }
  );
  return (
    <FreeDataUl>
      {data.data?.length > 0 ? (
        data.data.map((item, index) => (
          <FreeDataLi key={`myFreeTop3_${item._id}_${index}`}>
            <FreeDataStatus>
              <FreeDataType>
                {item.type === 0 ? "공모전" : "대외활동"}
              </FreeDataType>
              <FreeDataCurStatus confirm={item.confirm}>
                {item.confirm ? "승인완료" : "승인대기"}
              </FreeDataCurStatus>
            </FreeDataStatus>
            <FreeDataTitle>{item.title}</FreeDataTitle>
            <FreeDataDate>
              {moment(item.createdAt).format("YYYY-MM-DD")}
            </FreeDataDate>
          </FreeDataLi>
        ))
      ) : (
        <FreeDataLi>
          <FreeDataTitle>등록된 무료등록건이 존재하지 않습니다</FreeDataTitle>
        </FreeDataLi>
      )}
    </FreeDataUl>
  );
};

// 무료등록 수정 요청 top3
const FreeEditReq = ({ id, account, toggle }) => {
  const navi = useNavigate();
  const { data, error, isLoading } = useQuery(
    ["myreqEdit", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/reqCEdit`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      onSuccess: (e) => {
        if (e?.statusCode && e?.statusCode === 401) {
          navi("/signin");
        }
        if (e?.statusCode === 400) {
          navi("/signin");
        }
      },
      onError: (e) => {
        if (e.statusCode === 404) {
          navi("/signin");
        }
      },
    }
  );

  return (
    <FreeDataUl>
      {data.data?.length > 0 ? (
        data.data.map((item, index) => (
          <FreeDataLi
            key={`myFreeEditTop3_${item._id}_${index}`}
            onClick={() => navi(`/user/myfreeeditview/${item._id}`)}
          >
            <FreeDataStatus>
              <FreeDataCurStatus confirm={item.answerYn}>
                {item.answerYn ? "답변완료" : "답변대기"}
              </FreeDataCurStatus>
            </FreeDataStatus>
            <FreeDataTitle>{item.content}</FreeDataTitle>
            <FreeDataDate>
              {moment(item.createdAt).format("YYYY-MM-DD")}
            </FreeDataDate>
          </FreeDataLi>
        ))
      ) : (
        <FreeDataLi>등록된 수정요청건이 존재하지 않습니다</FreeDataLi>
      )}
    </FreeDataUl>
  );
};

const UserFreeTop3 = ({ id, account, toggle }) => {
  const [tab, setTab] = useState(0);
  useEffect(() => {
    setTab(0);
    return () => {
      setTab(0);
    };
  }, []);

  const render = (tab) => {
    return (
      <Suspense fallback={<Loading />}>
        {tab === 0 ? (
          <FreeReq id={id} account={account} toggle={toggle} />
        ) : (
          <FreeEditReq id={id} account={account} toggle={toggle} />
        )}
      </Suspense>
    );
  };

  return (
    <>
      <FreeUl>
        <FreeLi active={tab === 0} onClick={() => setTab(0)}>
          등록요청
        </FreeLi>
        <FreeLi active={tab === 1} onClick={() => setTab(1)}>
          수정요청
        </FreeLi>
      </FreeUl>
      {render(tab)}
    </>
  );
};

export default UserFreeTop3;
