import React from "react";
import Insta from "../../../assets/img/common/d_insta.png";
import Fb from "../../../assets/img/common/d_fb.png";
import Blog from "../../../assets/img/common/d_blog.png";

const Tab1 = () => {
  return (
    <div className={"tabWrap"}>
      <label>
        대외활동 주 참가층으로 구성된 팔로워 2만명 이상 보유 및 홍보 카드뉴스 및
        이벤트 소식 기재
      </label>
      <div className={"tabBody"}>
        <div>
          <img src={Insta} />
          <p>대티즌 인스타그램</p>
        </div>
        <div>
          <img src={Fb} />
          <p>대티즌 페이스북</p>
        </div>
        <div>
          <img src={Blog} />
          <p>대티즌 블로그</p>
        </div>
      </div>
    </div>
  );
};

export default Tab1;
