import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../../recoil/AtomConfig";
import {
  ClientReqGet,
  ClientReqPatch,
  ClientReqPost,
} from "../../../utils/ClientAuth";
import {
  BtnArea,
  InfoLabel,
  InfoLi,
  InfoText,
  InfoTop,
  InfoUl,
  UserSection,
  UserSectionNonBorderBtn,
} from "../UserTemplate";
import styled, { css } from "styled-components";
import { HiX } from "react-icons/hi";
import { Loading } from "../../DContents/Mobile/MTemplate";
import { useNavigate } from "react-router-dom";

const passwordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const ChangeMy = ({ id }) => {
  const router = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const emailYRef = useRef(null);
  const [pwToggle, setPwToggle] = useState(false);
  const [leaveToggle, setLeaveToggle] = useState(false);

  const { data, refetch } = useQuery(
    ["userInfo", id, account, toggle],
    async () =>
      await ClientReqGet(
        `/user`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: false,
    }
  );

  const nickRef = useRef(null);
  const handleChangeNickName = async () => {
    const nickName = nickRef.current.value;

    if (!nickName || nickName.length === 0) {
      alert("변경할 닉네임을 입력해주시기 바랍니다.");
      return false;
    }
    const result = await ClientReqPatch(
      `/user/nickname`,
      { nickName },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      alert("성공적으로 수정되었습니다.");
      refetch();
    } else {
      alert(
        "수정도중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
      );
    }
  };

  const handleChangeEmailYn = async () => {
    const emailYn = emailYRef.current.checked.toString();
    console.log(emailYn);
    const result = await ClientReqPatch(
      `/user/emailYn/${emailYn}`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      alert("성공적으로 변경했습니다.");
      refetch();
    }
  };

  const handleUserLeaveOut = async () => {
    console.log("leave out start ===========");
    const result = await ClientReqPost(
      `/user/leaveout`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    console.log(result);
    if (result.flag) {
      alert("정상적으로 처리되었습니다.");
      router("/prepare");
    }

    console.log("leave out end ===========");
  };

  const handleLeaveToggleOn = () => setLeaveToggle(true);
  const handleLeaveToggleOff = () => setLeaveToggle(false);

  return (
    <InfoTop>
      <InfoUl>
        <InfoLi>
          <InfoLabel className="mit_tit">이름</InfoLabel>
          <InfoText className="mit_text">{data.data.name}</InfoText>
        </InfoLi>
        <InfoLi>
          <InfoLabel className="mit_tit">회원유형</InfoLabel>
          <InfoText className="mit_text">
            {data.data.userType === 2 ? "기업회원" : "일반회원"}
          </InfoText>
        </InfoLi>

        <InfoLi>
          <InfoLabel className="mit_tit">이메일(아이디)</InfoLabel>
          <InfoText className="mit_text">{data.data.email}</InfoText>
        </InfoLi>
        <InfoLi>
          <InfoLabel className="mit_tit">이메일 수신 여부</InfoLabel>
          <div className={"addOnRadio"}>
            <div>
              <input
                type="radio"
                name="emailYn"
                id="emailY"
                ref={emailYRef}
                defaultChecked={data.data.emailYn}
                onClick={handleChangeEmailYn}
              />
              <label htmlFor={"emailY"}>수신</label>
            </div>
            <div>
              <input
                type="radio"
                name="emailYn"
                id="emailN"
                defaultChecked={!data.data.emailYn}
                onClick={handleChangeEmailYn}
              />
              <label htmlFor={"emailN"}>거부</label>
            </div>
          </div>
        </InfoLi>
        <InfoLi>
          <InfoLabel className="mit_tit">닉네임</InfoLabel>
          <div className={"addOn"}>
            <input
              type="text"
              ref={nickRef}
              className={"changeInput_text"}
              defaultValue={data.data.nickName}
            />
            <button
              type="button"
              className={"btn sm"}
              onClick={handleChangeNickName}
            >
              변경
            </button>
          </div>
        </InfoLi>
        <InfoLi>
          <InfoLabel className="mit_tit">연락처</InfoLabel>
          <div className={"addOn"}>
            <InfoText className="mit_text" style={{ marginRight: "1em" }}>
              {data.data.phone}
            </InfoText>
            <button type="button" className={"btn sm"}>
              변경
            </button>
          </div>
        </InfoLi>
        <InfoLi>
          <InfoLabel className="mit_tit">비밀번호</InfoLabel>
          <div className={"addOn"}>
            <button
              type="button"
              className={"btn sm"}
              onClick={() => {
                setPwToggle(true);
              }}
            >
              비밀번호 변경
            </button>
          </div>
        </InfoLi>
      </InfoUl>
      <UserSection>
        <UserSectionNonBorderBtn type="button" onClick={handleLeaveToggleOn}>
          회원탈퇴
        </UserSectionNonBorderBtn>
      </UserSection>
      <PwdPop
        open={pwToggle}
        close={() => {
          setPwToggle(false);
        }}
      ></PwdPop>
      <LeavePop
        open={leaveToggle}
        close={handleLeaveToggleOff}
        action={handleUserLeaveOut}
      />
    </InfoTop>
  );
};

const PwdPop = ({ open, close }) => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const curPwd = useRef(null);
  const newPwd = useRef(null);
  const newPwdConfirm = useRef(null);

  const isValid = (value) => {
    const status = {
      msg: "변경할 비밀번호를 입력해주시기 바랍니다.",
      data: "",
      flag: false,
    };
    if (!value || value.length === 0) return status;
    if (passwordRegExp.test(value)) {
      status.msg = "";
      status.flag = true;
      status.data = value;
    } else {
      status.msg =
        "특수문자,영문 대소문자, 숫자를 포함한 비밀번호를 8자리 이상 입력해주시기 바랍니다";
      status.flag = false;
    }

    return status;
  };
  const isValidConfirm = (newRef, confirmValue) => {
    if (!confirmValue) return;
    if (newRef.current?.value === confirmValue) {
      return true;
    } else {
      return;
    }
  };

  const isCurValid = (value) => {
    if (!value || value.length === 0) return;
    return value;
  };

  const changeApiReq = async (data) => {
    return await ClientReqPatch(
      `/user/pwd`,
      { ...data },
      account.accessToken,
      account.refreshToken,
      toggle
    );
  };

  const handleChange = async () => {
    const newPwdText = isValid(newPwd.current?.value);
    const newPwdConfirmText = isValidConfirm(
      newPwd,
      newPwdConfirm.current?.value
    );

    if (!newPwdText.flag || !newPwdConfirmText) {
      if (!newPwdConfirmText) {
        alert("비밀번호를 확인해주시기 바랍니다.");
        return false;
      }
      alert(newPwdText.msg);
      return false;
    }

    const changeResult = await changeApiReq({ password: newPwdText.data });
    if (changeResult.flag) {
      alert("성공적으로 변경되었습니다.");
      close();
    } else {
      alert(
        "비밀번호 변경도중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
      );
    }
    // 비밀번호 유효성 검사
    // user token,
    // submit
    // close pop
  };

  return (
    <PwPop open={open}>
      <PwBody>
        <PwHead>
          <span>비밀번호 변경</span>
          <HiX size={20} onClick={close} />
        </PwHead>
        <PwForm>
          <PwItem>
            <label>새 비밀번호</label>
            <input type="password" ref={newPwd} />
          </PwItem>
          <PwItem>
            <label>새 비밀번호 확인</label>
            <input type="password" ref={newPwdConfirm} />
          </PwItem>
        </PwForm>
        <PwButtons>
          <button type="button" onClick={handleChange}>
            변경
          </button>
        </PwButtons>
      </PwBody>
    </PwPop>
  );
};

const LeavePop = ({ open, close, action }) => {
  const [loading, setLoading] = useState(false);
  const handleActive = async () => {
    setLoading(true);
    setTimeout(action, 1000);
  };
  return (
    <PwPop open={open}>
      <PwBody>
        <PwHead>
          <span>회원탈퇴</span>
        </PwHead>
        {loading ? (
          <>
            <p style={{ padding: "1em 0 0 0" }}></p>
            <Loading />
          </>
        ) : (
          <>
            <PwMessageBox>
              <PwMessage style={{ color: "red" }}>
                탈퇴 요청은 철회할 수 없으며
                <br />
                재가입 시 동일한 아이디를 사용할 수 없습니다
              </PwMessage>
              <br />
              <PwMessage>
                <b>
                  상기 내용을 숙지하였으며 <br />
                  회원탈퇴를 진행하시겠습니까?
                </b>
              </PwMessage>
            </PwMessageBox>
            <PwBtnArea>
              <PwBtn type="button" className={"leave_cancel"} onClick={close}>
                취소
              </PwBtn>
              <PwBtn
                type="button"
                className={"leave_ok"}
                onClick={handleActive}
              >
                탈퇴
              </PwBtn>
            </PwBtnArea>
          </>
        )}
      </PwBody>
    </PwPop>
  );
};

const PwPop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1111111;
  ${(props) =>
    !props.open &&
    css`
      display: none;
    `}
`;
const PwBody = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  /* height: 200px; */
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  transform: translate(-50%, -50%);
`;
const PwHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #efefee;
  span {
    font-size: 0.75em;
    font-weight: 600;
  }
`;
const PwForm = styled.div``;
const PwMessageBox = styled.div`
  padding: 0.5em 0;
`;
const PwMessage = styled.p`
  text-align: center;
  font-size: 0.75em;
  > b {
    font-size: 1.25em;
  }
`;
const PwBtnArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1em 0 0 0;
`;
const PwBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 0.75em;
  &.leave_cancel {
    color: red;
  }
  &.leave_ok {
    color: #2bcea2;
  }
`;
const PwItem = styled.div`
  display: block;
  padding: 10px 0;
  label {
    font-size: 0.65em;
    display: block;
  }
  input {
    width: 100%;
    font-size: 0.75em;
    padding: 5px 10px;
    border: none;
    border-bottom: 1px solid #dbdbdb;
    &:focus {
      outline: none;
      border-bottom: 1px solid #272727;
    }
  }
`;

const PwButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > button {
    font-size: 0.75em;
    padding: 0.25em 0.5em;
    border: none;
    background-color: #13151b;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.5);
  }
`;

export default ChangeMy;
