import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";
import styled from "styled-components";

const CompInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  padding: 1em 2em;
  background-color: #efefef;
  @media screen and (max-width: 766px) {
    flex-direction: column;
    padding: 1em 0.25em;
    justify-content: flex-start;
    align-items: flex-start;
    > div {
      display: block !important;
      > label {
        display: block;
      }
      > input {
        /* width: 100% !important; */
        display: block;
      }
    }
  }
`;

const CompanyUserFindId = () => {
  const navi = useNavigate();
  const phoneRef = useRef(null);
  const nameRef = useRef(null);

  const handleFindId = async () => {
    const phone = phoneRef.current.value;
    const name = nameRef.current.value;
    if (!name) {
      alert("이름을 입력해주시기 바랍니다");
      return false;
    }
    if (!phone) {
      alert("연락처를 입력해주시기 바랍니다");
      return false;
    }
    const cPhone = phone.replaceAll("-", "");

    const result = await NormalReqApi(
      "get",
      `/user/company/findId?name=${name}&phone=${cPhone}`
    );
    if (result.flag) {
      if (result.data.flag) {
        navi("/findidresult", {
          state: {
            id: result.data.id,
          },
        });
      } else {
        alert("일치하는 회원정보가 없습니다.");
        return false;
      }
    }
  };

  return (
    <div className={"container sub"}>
      <div className={"sub_container"} style={{ marginTop: "3.5em" }}>
        <div className={"contents mypage"} style={{ height: "100%" }}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>아이디 찾기</span>

            <small style={{ marginBottom: 0 }}>
              <Link to={`/findId`}>일반회원 아이디 찾기</Link>
            </small>
          </h2>
          <div style={{ padding: "1em 0" }}>
            <p style={{ marginBottom: "1em", fontSize: "0.85em" }}>
              아이디 확인을 위해 가입시 기재하셨던 성함 및 연락처를 기입해주시기
              바랍니다
            </p>
            <CompInput>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "flex-end",
                  marginRight: "1em",
                }}
              >
                <label
                  htmlFor="userName"
                  style={{ marginRight: ".5em", fontSize: ".75em" }}
                >
                  이름
                </label>
                <input
                  id="userName"
                  ref={nameRef}
                  type="text"
                  style={{
                    border: "none",
                    borderRadius: "10px",
                    padding: ".25em .5em",
                    fontSize: ".9em",
                  }}
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "flex-end",
                }}
              >
                <label
                  htmlFor="userPhone"
                  style={{ marginRight: ".5em", fontSize: ".75em" }}
                >
                  휴대전화 번호
                </label>
                <input
                  id="userPhone"
                  ref={phoneRef}
                  type="text"
                  style={{
                    border: "none",
                    borderRadius: "10px",
                    padding: ".25em .5em",
                    fontSize: ".9em",
                  }}
                ></input>
              </div>
            </CompInput>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <button
                type="button"
                style={{
                  color: "white",
                  backgroundColor: "#263050",
                  border: "none",
                  display: "block",
                  marginBottom: "0.5em",
                  padding: "0.5em",
                  fontSize: "0.75em",
                  boxSize: "border-box",
                  cursor: "pointer",
                }}
                onClick={handleFindId}
              >
                아이디 확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyUserFindId;
