import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import chStyle from "../../assets/css/header.module.scss";
import { HiDotsVertical } from "react-icons/hi";
import FixedMenu from "../../components/Header/FixedMenu";
import MobileHeader from "../../components/Header/MobileHeader";
import { useRecoilState } from "recoil";
import { bgColorSelect, pageSelect } from "../../recoil/AtomConfig";
import Logoimg from "../../assets/img/common/logo_w.png";
import LogoimgDark from "../../assets/img/common/logo.png";
import MobileFooter from "./MobileFooter";

const MobileHeader2 = () => {
  const navigator = useNavigate();
  const [hide, setHide] = useState(false);
  const [page, setPage] = useRecoilState(pageSelect);
  const [open, setOpen] = useState(false);
  const [bg] = useRecoilState(bgColorSelect);
  const movePage = (pageUrl, pageType) => {
    navigator(pageUrl);
    setOpen(false);
    if (pageType) {
      handleChangePage(pageType);
    }
  };

  const handleChangePage = (page) => {
    setPage((prev) => {
      const prevObject = { ...prev };
      prevObject.curPage = page;

      return { ...prevObject };
    });
  };
  return (
    <>
      <div className={"wrap main"}>
        <header className={`header main ${chStyle.mediaHeader}`}>
          <div
            className={`snb_wrap ${chStyle.snb_wrap} ${chStyle.indicator} ${
              hide ? chStyle.hide : chStyle.open
            }`}
          >
            <div className="snb_cont">
              <div className="sns_wrap">
                <div onClick={() => setHide(false)}>
                  <HiDotsVertical color={"white"} />
                </div>
              </div>
            </div>
          </div>
          <FixedMenu
            className={`snb_wrap ${chStyle.snb_wrap} ${
              hide ? chStyle.hide : chStyle.open
            }`}
            hide={hide}
            setHide={setHide}
          />
          <MobileHeader
            pageChange={handleChangePage}
            LogoDark={LogoimgDark}
            bg={bg}
            LogoImg={Logoimg}
            chStyle={chStyle}
            movePage={movePage}
          />
        </header>

        <Outlet />
        <MobileFooter />
      </div>
    </>
  );
};

export default MobileHeader2;
