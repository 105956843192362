import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";

export default function useUserState() {
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };

  return { id: account.id, account: account, toggle: toggle };
}
