import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../../recoil/AtomConfig";
import { ClientReqGet } from "../../../utils/ClientAuth";
import {
  AuthGetAxios,
  ExpiredFilter,
  getRefreshToken,
} from "../../../utils/ReqApi";
import Editor from "../../Common/Editor";
import { ErrorText } from "../../HostCenter/Free/FreeElement";
import { CFormElement } from "./Element";

export const TeamEdit = ({
  id,
  register,
  errors,
  setValue,
  watch,
  editorChange,
}) => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [info, setInfo] = useState({
    loading: true,
    ownYn: false,
  });
  const getTeam = async (url, accessToken) => {
    const { data } = await AuthGetAxios(url, accessToken);
    if (data.statusCode === 200) {
      setInfo({
        ...data.result.data,
        loading: false,
        ownYn: data.result.ownYn,
      });
      setValue("category", data.result.data.category);
    } else if (
      data.statusCode === 401 &&
      data.message === "토큰이 만료되었습니다."
    ) {
      const rToken = await getRefreshToken(account.refreshToken);
      setAccount((prev) => {
        const pObject = { ...prev };
        pObject.accessToken = rToken.data.data.accessToken;
        return { ...pObject };
      });
      return await ExpiredFilter(url, getTeam, rToken.data.data.accessToken);
    }
  };

  useEffect(() => {
    const url = `/teamplay/view?id=${id}`;
    getTeam(url, account.accessToken);
  }, [id]);

  const handleCategoryChange = (e) => {
    setInfo({ ...info, category: e.target.value });
  };

  return (
    <ul>
      <li className={"th view"}>
        <CFormElement
          label={"카테고리"}
          className={"teamplay_category w10"}
          error={errors.category}
        >
          <select
            className={"w90"}
            {...register("category", {
              onChange: (e) => handleCategoryChange(e),
            })}
            value={info.category}
          >
            <option value={0}>공모전</option>
            <option value={1}>대외활동</option>
          </select>
        </CFormElement>
        <CFormElement label={"제목"} error={errors.title}>
          <input
            type="text"
            className={"input_text w90"}
            defaultValue={info.title}
            {...register("title")}
          />
        </CFormElement>
      </li>
      <li className={"td write"}>
        <Editor onChange={editorChange} type="content" value={info.content} />
        {errors.content && <ErrorText>{errors.content.message}</ErrorText>}
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"} error={errors.file}>
          <input type="file" className={"w90"} {...register("file")} />
        </CFormElement>
      </li>
    </ul>
  );
};

export const TeamForm = ({ id, register, errors, watch, editorChange }) => {
  return (
    <ul>
      <li className={"th view"}>
        <CFormElement
          label={"카테고리"}
          className={"teamplay_category w10"}
          error={errors.category}
        >
          <select className={"w90"} {...register("category")}>
            <option value={0}>공모전</option>
            <option value={1}>대외활동</option>
          </select>
        </CFormElement>
        <CFormElement label={"제목"} error={errors.title}>
          <input
            type="text"
            className={"input_text w90"}
            {...register("title")}
          />
        </CFormElement>
      </li>
      <li className={"td write"}>
        <Editor
          onChange={editorChange}
          type="content"
          value={watch("content")}
        />
        {errors.content && <ErrorText>{errors.content.message}</ErrorText>}
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"} error={errors.file}>
          <input type="file" className={"w90"} {...register("file")} />
        </CFormElement>
      </li>
    </ul>
  );
};

export const AfterEdit = ({
  id,
  register,
  errors,
  watch,
  setValue,
  editorChange,
}) => {
  // const []

  const [account, setAccount] = useRecoilState(accountSelect);
  const [info, setInfo] = useState({
    loading: true,
    ownYn: false,
  });
  const callback = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };
  const getAfter = async (url) => {
    const res = await ClientReqGet(
      url,
      account.accessToken,
      account.refreshToken,
      callback
    );
    if (res.statusCode === 200) {
      setInfo({ ...res.result.data, loading: false, ownYn: res.result.ownYn });
      setValue("secretYn", res.result.data.secretYn);
    }
  };

  useEffect(() => {
    const url = `/after/view?id=${id}`;
    getAfter(url);
  }, [id]);

  return (
    <ul>
      <li className={"th view"}>
        <CFormElement label={"제목"} error={errors.title}>
          <input
            type="text"
            className={"input_text w90"}
            defaultValue={info.title}
            {...register("title")}
          />
        </CFormElement>
        <CFormElement label={"비밀글"}>
          <input
            type="checkbox"
            className={"inp_rdo"}
            defaultChecked={info.secretYn}
            {...register("secretYn")}
          />
        </CFormElement>
      </li>
      <li className={"td write"}>
        <Editor onChange={editorChange} type={"content"} value={info.content} />
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"}>
          {info.attachment ? (
            <div>
              <span>{info.attachment.originName}</span>
            </div>
          ) : (
            <input type="file" className={"w90"} {...register("file")} />
          )}
        </CFormElement>
      </li>
    </ul>
  );
};

export const AfterForm = ({ register, errors, watch, editorChange }) => {
  return (
    <ul>
      <li className={"th view"}>
        <CFormElement label={"제목"}>
          <input
            type="text"
            className={"input_text w90"}
            {...register("title")}
          />
        </CFormElement>
        {/* <CFormElement label={"비밀글"}>
          <input
            type="checkbox"
            className={"inp_rdo"}
            {...register("secretYn")}
          />
        </CFormElement> */}
      </li>
      <li className={"td write"}>
        <Editor
          onChange={editorChange}
          type={"content"}
          value={watch("content")}
        />
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"}>
          <input type="file" className={"w90"} {...register("file")} />
        </CFormElement>
      </li>
    </ul>
  );
};

export const QnaEdit = ({
  id,
  setValue,
  register,
  errors,
  watch,
  editorChange,
}) => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [info, setInfo] = useState({
    loading: true,
    ownYn: false,
  });

  const callback = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };

  const getQna = async (url) => {
    const res = await ClientReqGet(
      url,
      account.accessToken,
      account.refreshToken,
      callback
    );
    if (res.statusCode === 200) {
      setInfo({ ...res.result.data, loading: false, ownYn: res.result.ownYn });
      setValue("category", res.result.data.category);
    }
  };

  useEffect(() => {
    const url = `/qna/view?id=${id}`;
    getQna(url);
  }, [id]);

  const handleCategoryChange = (e) => {
    setInfo({ ...info, category: e.target.value });
  };
  return (
    <ul>
      <li className={"th view"}>
        <CFormElement label={"카테고리"} className={"teamplay_category w10"}>
          <select
            className={"w90"}
            {...register("category", {
              onChange: (e) => handleCategoryChange(e),
            })}
            value={info.category}
          >
            <option value={0}>공모전</option>
            <option value={1}>대외활동</option>
            <option value={2}>이벤트</option>
          </select>
        </CFormElement>
        <CFormElement label={"제목"} error={errors.title}>
          <input
            type="text"
            className={"input_text w90"}
            defaultValue={info.title}
            {...register("title")}
          />
        </CFormElement>
      </li>
      <li className={"td write"}>
        <Editor onChange={editorChange} type="content" value={info.content} />
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"}>
          {info.attachment ? (
            <div>
              <span>{info.attachment.originName}</span>
            </div>
          ) : (
            <input type="file" className={"w90"} {...register("file")} />
          )}
        </CFormElement>
      </li>
    </ul>
  );
};

export const QnaForm = ({ register, errors, watch, editorChange }) => {
  return (
    <ul>
      <li className={"th view"}>
        <CFormElement label={"카테고리"} className={"teamplay_category w10"}>
          <select className={"w90"} {...register("category")}>
            <option value={0}>공모전</option>
            <option value={1}>대외활동</option>
            <option value={2}>이벤트</option>
          </select>
        </CFormElement>
        <CFormElement label={"제목"} error={errors.title}>
          <input
            type="text"
            className={"input_text w90"}
            {...register("title")}
          />
        </CFormElement>
      </li>
      <li className={"td write"}>
        <Editor
          onChange={editorChange}
          type="content"
          value={watch("content")}
        />
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"}>
          <input type="file" className={"w90"} {...register("file")} />
        </CFormElement>
      </li>
    </ul>
  );
};

export const FreeBEdit = ({
  id,
  setValue,
  register,
  errors,
  watch,
  editorChange,
}) => {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [info, setInfo] = useState({
    loading: true,
    ownYn: false,
  });
  const callback = (value) => {
    setAccount((prev) => {
      const pObject = { ...prev };
      pObject.accessToken = value;
      return { ...pObject };
    });
  };
  const getFb = async (url) => {
    const res = await ClientReqGet(
      url,
      account.accessToken,
      account.refreshToken,
      callback
    );
    if (res.statusCode === 200) {
      setInfo({ ...res.result.data, loading: false, ownYn: res.result.ownYn });
    }
  };
  useEffect(() => {
    const url = `/freeboard/view?id=${id}`;
    getFb(url);
  }, [id]);
  return (
    <ul>
      <li className={"th view"}>
        <CFormElement label={"제목"} error={errors.title}>
          <input
            type="text"
            className={"input_text w90"}
            defaultValue={info.title}
            {...register("title")}
          />
        </CFormElement>
      </li>
      <li className={"td write"}>
        <Editor onChange={editorChange} type="content" value={info.content} />
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"}>
          {info.attachment ? (
            <div>
              <span>{info.attachment.origin}</span>
            </div>
          ) : (
            <input type="file" className={"w90"} {...register("file")} />
          )}
        </CFormElement>
      </li>
    </ul>
  );
};

export const FreeBForm = ({ register, errors, watch, editorChange }) => {
  return (
    <ul>
      <li className={"th view"}>
        <CFormElement label={"제목"} error={errors.title}>
          <input
            type="text"
            className={"input_text w90"}
            {...register("title")}
          />
        </CFormElement>
      </li>
      <li className={"td write"}>
        <Editor
          onChange={editorChange}
          type="content"
          value={watch("content")}
        />
      </li>
      <li className={"td file"}>
        <CFormElement label={"첨부파일"}>
          <input type="file" className={"w90"} {...register("file")} />
        </CFormElement>
      </li>
    </ul>
  );
};
