import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Chstyle from "../../../assets/css/channel.module.scss";
import pStyle from "../../../assets/css/paging.module.scss";
import Pagination from "../../../utils/Pagination";
import { NormalReqApi } from "../../../utils/ReqApi";

const EventSubmitResultPc = () => {
  const [filter, setFilter] = useState({
    keyType: "",
    keyword: "",
    page: 1,
  });
  const kRef = useRef(null);
  const kwRef = useRef(null);

  const { data, error } = useQuery(
    ["eventresultList", filter],
    async () =>
      await NormalReqApi(
        "get",
        `/eventresult?page=${filter.page}&keyType=${filter.keyType}&keyword=${filter.keyword}`
      ),
    { retry: true, suspense: true }
  );

  const handlePage = (page) => {
    setFilter({
      ...filter,
      page: page * 1,
    });
  };
  const handleSearch = () => {
    const keyType = kRef.current.value;
    const keyword = kwRef.current.value;
    setFilter({
      keyType,
      keyword,
      page: 1,
    });
  };

  const render = (data) => {
    if (data) {
      const item = data.data.result;
      if (item.length > 0) {
        return item.map((item, index) => (
          <li key={`er_item_${index}`} className={`td ${Chstyle.td}`}>
            <Link to={`/event/submitresult/view/${item._id}`}>
              <div className={`w85`}>
                <span className={`brd_title w100`}>{item.title}</span>
              </div>
              <span className={`brd_info w15 brd_date`}>
                {moment(item.createdAt).format("YYYY-MM-DD")}
              </span>
            </Link>
          </li>
        ));
      } else {
        return <li></li>;
      }
    } else {
      return <li></li>;
    }
  };

  return (
    <>
      <div className={`board_search ${Chstyle.board_search}`}>
        <select name="searchType" ref={kRef}>
          <option value="0">선택</option>
          <option value="title">제목</option>
          <option value="contents">내용</option>
        </select>
        <input type="text" name="searchWrd" ref={kwRef} />
        <button type="submit" onClick={handleSearch}>
          검색
        </button>
      </div>
      <div className={`board_list myuInfo_write ${Chstyle.board_list}`}>
        <ul>
          <li className={`th ${Chstyle.th}`}>
            <span className={"w85"}>제목</span>
            <span className={`w15 ${Chstyle.regDate}`}>등록일</span>
          </li>
          {render(data)}
        </ul>
        <div className={`paging_area ${pStyle.paging_area}`}>
          <Pagination
            total={data.data.count}
            curPage={filter.page}
            handlePage={handlePage}
          />
        </div>
      </div>
    </>
  );
};

export default EventSubmitResultPc;
