import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ContentBody,
  ContentButton,
  ContentHeader,
  Header,
} from "../../components/Challenge/MobileTemplate/Layout";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { accountSelect } from "../../recoil/AtomConfig";
import { ClientOptionGet } from "../../utils/ClientAuth";
import { NormalReqApi } from "../../utils/ReqApi";

const TeamplayViewMobile = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const goBack = () => {
    navi(-1);
  };
  const { data, error, isLoading } = useQuery(
    ["teamView", id],
    async () =>
      await ClientOptionGet(
        `/teamplay/view?id=${id}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false }
  );
  return (
    <Header
      label={"팀원모집"}
      subLabel={"프로젝트를 팀플레이로 진행하고 싶다면?"}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ContentHeader {...data.result.data} />
          <ContentBody {...data.result.data} />
          <ContentButton
            id={id}
            goBack={goBack}
            type={"teamplay"}
            account={account}
            toggle={toggle}
          />
        </>
      )}
    </Header>
  );
};

export default TeamplayViewMobile;
