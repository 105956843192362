import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginWrap } from "../../components/Login/LoginTemplateM";

const SignUpMobile = () => {
  const navi = useNavigate();
  const handleMove = (index) => {
    navi("/msignup/" + index);
  };
  return (
    <LoginWrap>
      <div style={{ marginBottom: "5em" }}>
        <label>회원 유형 선택</label>
      </div>
      <div className={"customSignUpType"}>
        <div onClick={() => handleMove(1)} style={{ textAlign: "center" }}>
          <label>DETIZEN 개인회원</label>
        </div>
        <div onClick={() => handleMove(2)} style={{ textAlign: "center" }}>
          <label>
            DETIZEN 기업회원
            <br />
            <small style={{ color: "gray" }}>
              (기업회원은 PC 로 회원가입을 해주세요!)
            </small>
          </label>
        </div>
      </div>
    </LoginWrap>
  );
};

export default SignUpMobile;
