import React from "react";
import { Link } from "react-router-dom";
import Cstyle from "../../../assets/css/contest.module.scss";
import Chstyle from "../../../assets/css/channel.module.scss";
import Cestyle from "../../../assets/css/event.module.scss";
import moment from "moment";

export const DContentsSubMenu = ({ current }) => {
  return (
    <div
      className={`subVisual channel ${Cstyle.subVisual}`}
      style={{ boxShadow: "0px 0px 10px 1px" }}
    >
      <ul className={`sub_menu ${Cstyle.sub_menu}`}>
        <li className={current === "emptytime" ? "active" : null}>
          <Link to={`/emptytime`}>
            <span>공강시간</span>
          </Link>
        </li>
        <li className={current === "weekly" ? "active" : null}>
          <Link to={`/weekly`}>
            <span>위클리</span>
          </Link>
        </li>
        <li className={current === "depic" ? "active" : null}>
          <Link to={`/depic`}>
            <span>대픽 영상</span>
          </Link>
        </li>
        {/* <li className={current === "event" ? "active" : null}>
          <Link to={`/event`}>
            <span>이벤트</span>
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export const DContentsWrap = ({ label, children }) => {
  return (
    <div
      className={`sub_container channel ${Chstyle.sub_container} ${Chstyle.channel}`}
    >
      <h2 className={"index_title"}>
        <span>{label}</span>
      </h2>
      {children}
    </div>
  );
};

export const DContentsSection = ({ className, children }) => {
  return <section className={className}>{children}</section>;
};

export const DContentDataList = ({
  className,
  label,
  subLabel,
  target,
  children,
}) => {
  return (
    <div className={className}>
      <h2 className={"sectionTit"}>
        <span>{label}</span>
        <small>{subLabel}</small>
        <Link to={target} className={"more"}>
          ...
        </Link>
      </h2>
      {children}
    </div>
  );
};

export const DContentSubContainer = ({
  label,
  subLabel,
  rightBtn,
  children,
}) => {
  return (
    <div className={"sub_container"}>
      <div
        className={`sub_container channel customEvent ${Cestyle.sub_container} ${Cestyle.event}`}
      >
        <section className={"section list"}>
          <div className={`event_list ${Cestyle.event_list}`}>
            <div
              className={`list_detizen ${Cestyle.list_detizen}`}
              style={{ marginBottom: "0" }}
            >
              <h3 className="sectionTit customEvent_sub">
                <span>{label}</span>
                {rightBtn ? (
                  <small
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>{subLabel}</div>
                    {rightBtn}
                  </small>
                ) : (
                  <small>{subLabel}</small>
                )}
              </h3>
            </div>
            {children}
          </div>
        </section>
      </div>
    </div>
  );
};

export const DContentWeeklySubContainer = ({ label, subLabel, children }) => {
  return (
    <div className={`sub_container channel ${Chstyle.sub_container}`}>
      <div className={`contents mypage ${Chstyle.contents}`}>
        <h3
          className="sectionTit customEvent_sub"
          style={{
            margin: "0 0 1em",
            padding: "0 0 1em",
            color: "#282c36",
            borderBottom: "1px solid #afaeae",
          }}
        >
          <span>{label}</span>
          <small>{subLabel}</small>
        </h3>
        {children}
      </div>
    </div>
  );
};

export const DContentDepicSubContainer = ({ label, subLabel, children }) => {
  return (
    <div className={`sub_container channel ${Chstyle.sub_container}`}>
      <div className={`contents mypage ${Chstyle.contents} ${Chstyle.mypage}`}>
        <h3
          className="sectionTit customEvent_sub"
          style={{
            margin: "0 0 1em",
            padding: "0 0 1em",
            color: "#282c36",
            borderBottom: "1px solid #afaeae",
          }}
        >
          <span>{label}</span>
          <small>{subLabel}</small>
        </h3>
        {children}
      </div>
    </div>
  );
};

export const DContentView = ({ label, children }) => {
  return (
    <div className={"sub_container"}>
      <div className={"contents mypage"}>
        <h2 className={"sub_title"}>
          <span>{label}</span>
        </h2>
        {children}
      </div>
    </div>
  );
};

export const DContentViewInfo = ({ title, createdAt, content }) => {
  return (
    <ul>
      <li className={"th view"}>
        <span className={"board_title"}>{title}</span>
        <p className={"board_date"}>{createdAt}</p>
      </li>
      <li className={"td view"}>
        <div
          className={"board_content ql-editor"}
          style={{ whiteSpace: "normal" }}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </li>
    </ul>
  );
};

export const DContentViewInfoWithFile = ({
  title,
  createdAt,
  content,
  attachment,
}) => {
  return (
    <ul>
      <li className={"th view"}>
        <span className={"board_title"}>{title}</span>
        <p className={"board_date"}>{moment(createdAt).format("YYYY-MM-DD")}</p>
      </li>
      <li className={"td view"}>
        <div
          className={"board_content ql-editor"}
          style={{ whiteSpace: "normal" }}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </li>
      {attachment && (
        <li className={"td view"}>
          {attachment.map((item, index) => (
            <span
              key={`attachment_result_${index}`}
              style={{ display: "block" }}
            >
              <span style={{ marginRight: "1em" }}>첨부 #{index + 1}</span>
              <a
                href={
                  process.env.REACT_APP_AWS_ENDPOINT + "contents/" + item.path
                }
                target={"_blank"}
              >
                {item.origin}
              </a>
            </span>
          ))}
        </li>
      )}
    </ul>
  );
};
