import DOMPurify from "dompurify";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  DcView,
  Loading,
  ViewHeader,
} from "../../components/DContents/Mobile/MTemplate";
import { NormalReqApi } from "../../utils/ReqApi";

const DepicViewMobile = () => {
  const purify = DOMPurify.sanitize;
  const { id } = useParams();
  const navi = useNavigate();
  const handleGoBack = () => {
    navi(-1);
  };
  const { data, error, isLoading } = useQuery(
    ["depicView", id],
    async () => await NormalReqApi("get", `/depic/view/${id}`),
    { retry: true }
  );
  return (
    <ViewHeader label={"대픽영상"}>
      {isLoading ? (
        <Loading />
      ) : (
        <DcView>
          <section className={"dcViewTop"}>
            <p className={"dcViewTitle"}>{data.data.title}</p>
            <div className={"dcViewSubInfo"}>
              <span className={"dcViewAuthro"}>대티즌</span>
              <span className={"dcViewDate"}>
                {moment(data.data.createdAt).format("YYYY-MM-DD")}
              </span>
            </div>
          </section>
          <section className={"dcViewEditor"}>
            <div
              className="dcViewContents ql-editor"
              dangerouslySetInnerHTML={{ __html: data.data.contents }}
            ></div>
          </section>
          <section className={"dcViewBtnArea"}>
            <button type="button" onClick={handleGoBack}>
              목록으로
            </button>
          </section>
        </DcView>
      )}
    </ViewHeader>
  );
};

export default DepicViewMobile;
