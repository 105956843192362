import React from "react";
import styled, { css } from "styled-components";

const Size_SubTitle = "0.75em";
const Size_SubInfo = "0.9em";
const Size_Title = "1em";
const TitleColor = "#282c36";
const HelpColor = "#a0a0a0";

export const InfoTop = styled.div`
  display: inline-block;
  width: 100%;
`;
export const InfoUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ${(props) =>
    props.type === "company" &&
    css`
      border-top: 1px solid;
    `}
`;
export const InfoLi = styled.li`
  width: calc(100% / 2 - 3em);
  display: flex;
  justify-content: space-between;
  padding-right: 3em;
  padding: 0.25em 3em 0.25em 0em;
  font-size: 0.8em;
  .addOnRadio {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > div {
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
      > input {
        margin-right: 0.25em;
      }
    }
  }

  .addOn {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fbd202;
      color: white;
      border-radius: 5px;
      font-size: 1em;

      border: none;
      padding: 0.25em 0.5em;
      cursor: pointer;
      &.sm {
      }
    }
    .changeInput_text {
      border-radius: 5px;
      margin-right: 1em;
      width: 80%;
      border: 1px solid #ebebeb;
      font-size: 1em;
      padding: 0.25em 0.25em;
    }
  }
`;
export const InfoLabel = styled.span`
  font-weight: 500;
`;
export const CompanyLabel = styled.p`
  font-size: 0.75em;
  margin-top: 1em;
  color: #a0a0a0;
`;

export const InfoText = styled.p`
  font-weight: 400;
`;

export const CntBody = styled.div`
  text-align: center;
  margin-top: 0.5em;
  width: 100%;
`;
export const CntTitle = styled.span`
  display: block;
  font-size: 0.75em;
  color: #a0a0a0;
`;
export const CntNumber = styled.span`
  font-size: 0.95em;
  font-weight: 600;
`;
export const CntClickableNumber = styled(CntNumber)`
  cursor: pointer;
`;

export const ListDataUl = styled.ul`
  display: block;
  padding-top: 0.5em;
`;
export const ListDataLi = styled.li`
  padding: 0.25em 0.5em;
  border-radius: 10px;
  margin: 0.7em 0;
  font-size: 0.75em;
  display: flex;
  justify-content: ${(props) =>
    props.center ? css` space-between` : css`flex-start`};
`;

export const Status = styled.span`
  display: block;
  font-size: ${Size_SubTitle};
  width: fit-content;
  padding: 0.25em 0.5em;
  ${(props) =>
    props.type === "ready"
      ? css`
          background-color: orange;
          color: white;
        `
      : props.type === "open"
      ? css`
          background-color: green;
          color: white;
        `
      : props.type === "done"
      ? css`
          background-color: gray;
          color: white;
        `
      : ``}
`;
export const Title = styled.span`
  display: block;
  font-size: ${Size_Title};
  width: 100%;
  color: ${TitleColor};
`;
export const Host = styled.span`
  display: block;
  font-size: ${Size_SubTitle};
  font-weight: 400;
  color: ${HelpColor};
`;

export const SubInfoArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${HelpColor};
  ${(props) =>
    props.bottom &&
    css`
      margin-bottom: 1em;
    `}
`;

export const SubInfoText = styled.span`
  margin-left: 0.5em;
  font-size: ${Size_SubInfo};
`;

export const RegDate = styled.span`
  font-size: ${Size_SubTitle};
  color: ${HelpColor};
`;

export const ListDataBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0.5em;
`;

export const ListUl = styled.ul`
  margin-top: 0.5em;
`;
export const ListLi = styled.li`
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const ListLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  justify-content: flex-start;
  font-size: 0.75em;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
export const ListItemCg = styled.span`
  color: ${HelpColor};
  padding-right: 1em;
`;
export const ListItemTitle = styled.span`
  font-weight: 500;
`;

export const ListDate = styled.span`
  width: 100%;
  text-align: right;
  font-size: 0.55em;
  color: ${HelpColor};
`;

export const ListDateAndStatus = styled.div`
  width: 100%;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ListStatus = styled.span`
  font-size: 0.55em;

  color: ${(props) => (props.status ? "green" : HelpColor)};
`;

export const BtnArea = styled.div`
  margin-top: 1em;
  text-align: right;

  > button {
    background-color: #2b303b;
    color: white;
    border-radius: 5px;
    font-size: 1em;

    border: none;
    padding: 0.25em 0.5em;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const UserSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 1em 0;
`;
export const UserSectionNonBorderBtn = styled.div`
  border: none;
  font-size: 0.75em;
  font-weight: 400;
  color: red;

  cursor: pointer;
`;

// mycontents

const ContentsMenuUl = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > li {
    padding: 0.25em 0.5em;
    margin-right: 0.5em;
    font-size: 0.75em;
    &.active {
      > span {
        border-bottom: 2px solid red;
      }
    }
  }
`;
export const ContentsMenu = ({ index, changeTab }) => {
  return (
    <ContentsMenuUl>
      <li className={index === 1 ? "active" : ""} onClick={() => changeTab(1)}>
        <span>팀원모집</span>
      </li>
      <li className={index === 2 ? "active" : ""} onClick={() => changeTab(2)}>
        <span>활동후기</span>
      </li>
      <li className={index === 3 ? "active" : ""} onClick={() => changeTab(3)}>
        <span>자유게시판</span>
      </li>
    </ContentsMenuUl>
  );
};

export const ContentItems = styled.ul`
  > li {
    &.noCiContent {
      font-size: 0.75em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContentItem = styled.li`
  padding: 0.25em 0;
  margin: 0.25em 0;
  border-bottom: 1px solid #efefef;
  .ciTitle {
    font-size: 0.85em;
  }
  .ciCntAndDate {
    padding-top: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.55em;
    color: gray;
    .ciCnt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > svg {
        margin-right: 0.5em;
      }
    }
  }
  .ciStatusArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .ciStatusDate {
      margin-left: 1em;
      font-size: 0.55em;
    }
  }
`;
export const CiStatus = styled.span`
  font-size: 0.55em;
  padding: 3px;
  background-color: ${(props) => (props.status ? `green` : `gray`)};
  color: white;
`;

export const FreeContestWrap = styled.div`
  .fcInfos {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid #efefef;
  }
  .fcFileArea {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid #efefef;
  }
  .fcContentsArea {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid #efefef;
  }
  .fcBtns {
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    > button {
      border: none;
      background-color: #222530;
      padding: 0.25em 0.5em;
      border-radius: 5px;
      margin-right: 1em;

      > span {
        font-size: 0.75em;
        color: white;
      }
    }
  }
  .fcInfo {
    display: inline-block;
    width: calc(100% / 2);
    max-width: 1188px;
    min-width: 564px;
    font-size: 0.75em;
    > div {
      > label {
        font-weight: bold;
        margin-right: 0.5em;
      }
      > span {
        font-weight: 300;
      }
      &.fcEditorText {
        font-weight: 300;
      }
    }
  }
`;

const BtuPop = styled.div`
  position: fixed;
  bottom: ${(props) => (props.open ? 0 : `-2000px`)};
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .BtnpopBg {
    position: fixed;
    top: 0;
    width: 100%;
    height: ${(props) => (props.open ? `100%` : 0)};
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }
  .BtnpopWrap {
    position: relative;
    width: 50%;
    height: 50%;
    display: block;
    z-index: 3;

    padding: 0.25em;
    background-color: #eaeaea;
    .popBox {
      height: 100%;
      section {
        &.textBody {
          height: 50%;
          > label {
            display: block;
            font-size: 0.75em;
          }
          > textarea {
            display: block;
            width: 100%;
            height: 80%;
            padding: 0.5em;
            font-size: 0.75em;
            border: none;
            border-radius: 5px;
            resize: none;

            &:focus {
              outline: none;
            }
          }
        }
        &.fileBody {
          height: 30%;
          .fileThumb {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 0.25em;
            margin-bottom: 0.25em;
            font-size: 0.75em;
            > label {
              margin-right: 0.5em;
            }
            > input {
              font-size: 0.75em;
            }
          }
        }
        &.btnBody {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 20%;
          > button {
            border: none;
            background-color: #181a22;
            padding: 0.25em 0.5em;
            margin-right: 1em;
            > span {
              font-size: 0.75em;
              color: white;
            }
          }
        }
      }
    }
  }
`;

const PopFrame = ({ open, close, children }) => {
  return (
    <BtuPop open={open}>
      <div className={"BtnpopBg"} onClick={() => close(false)}></div>
      <div className={"BtnpopWrap"}>{children}</div>
    </BtuPop>
  );
};

export const BottomUpPop = ({ open, close, children }) => {
  return (
    <PopFrame open={open} close={close}>
      {children}
    </PopFrame>
  );
};
