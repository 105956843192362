import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styled, { css } from "styled-components";
import { NormalReqApi, tempGet } from "../../utils/ReqApi";
import ReactGA4 from "react-ga4";

const MobileIndex = () => {
  const navi = useNavigate();
  const [list, setList] = useState([]);
  const [listA, setListA] = useState([]);
  const [listB, setListB] = useState([]);
  const [listBand, setListBand] = useState([]);
  const [contests, setContests] = useState([]);
  const [index, setIndex] = useState(0);
  const [indexA, setIndexA] = useState(0);
  const [indexB, setIndexB] = useState(0);

  const getData = async () => {
    // const result = await tempGet(`/banner/0/mobile`);
    const result = await NormalReqApi("get", `/banner/0/mobile`);
    setList([...result.data]);
    // alert(result);
  };

  const getSpaData = async () => {
    // const result = await tempGet(`/banner/1/mobile`);
    const result = await NormalReqApi("get", `/banner/1/mobile`);
    setListA([...result.data]);
  };

  const getSpbData = async () => {
    // const result = await tempGet(`/banner/2/mobile`);
    const result = await NormalReqApi("get", `/banner/2/mobile`);
    setListB([...result.data]);
  };
  const getBandData = async () => {
    // const result = await tempGet(`/banner/3/mobile`);
    const result = await NormalReqApi("get", `/banner/3/mobile`);
    setListBand([...result.data]);
  };
  const getContest = async () => {
    // const result = await tempGet(`/contest/new`);
    const result = await NormalReqApi("get", `/contest/new`);
    setContests([...result.data]);
  };

  useEffect(() => {
    getData();
    getSpaData();
    getSpbData();
    getBandData();
    getContest();
  }, []);

  const pageHandler = (type, id, bannerId, bannerType) => {
    ReactGA4.event("click", {
      click_text: "banner_cta_clicked",
      click_contest_type: type,
      click_contest_id: id,
      click_contest_bannerType: bannerType,
      click_banner_id: bannerId,
    });

    NormalReqApi(
      "post",
      `/banner/viewcnt/${
        bannerType === "top"
          ? 0
          : bannerType === "spa"
          ? 1
          : bannerType == "spb"
          ? 2
          : bannerType === "band"
          ? 3
          : 4
      }/${bannerId}`,
      {}
    );

    navi(`/contest/view/${type}/${id}`);
  };

  //   const { data, error, isLoading } = useQuery(
  //     ["mainbanner"],
  //     async () => await NormalReqApi("get", `/banner/0/mobile`)
  //   );
  //   console.log(data, error, isLoading);

  //   if (isLoading) {
  //     return <Wrap>...</Wrap>;
  //   }
  const render = (list) => {
    const settings = {
      className: "topSlide mobileSlide",
      dots: false,
      arrows: false,
      Infinity: true,
      slidesToShow: 1,

      autoplay: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1,
      focusOnSelect: true,
      // beforeChange: (current, next) => {
      //   setIndex(next);
      // },
    };
    const checkContestSTatus = (startDate, endDate) => {
      const nowDate = moment();
      const sDate = moment(startDate);
      const eDate = moment(endDate);
      // console.log(nowDate);
      // console.log(sDate, eDate);
      console.log("@@@@");
      console.log(`nowDate is before sDate ${nowDate.isBefore(sDate)}`);
      console.log(`nowDate is after sDate ${nowDate.isAfter(sDate)}`);
      console.log(`nowDate is before eDate ${nowDate.isBefore(eDate)}`);
      console.log("###");
      const resultObject = {
        status: 0, // 0 : ready, 1 : open , 2: done
        text: "", // 예정, 진행중, 마감
      };
      if (nowDate.isBefore(sDate)) {
        resultObject.status = 0;
        resultObject.text = "예정";
      } else if (nowDate.isAfter(sDate) && nowDate.isBefore(eDate)) {
        resultObject.status = 1;
        resultObject.text = "진행중";
      } else if (nowDate.isAfter(eDate)) {
        resultObject.status = 2;
        resultObject.text = "마감";
      }
      return resultObject.text;
    };

    return (
      <Slider {...settings}>
        {list?.map((item, index) => (
          <div
            key={`top_bg_${index}`}
            onClick={() =>
              pageHandler(item.contest.type, item.contest.id, item.id, "top")
            }
            className={"mBgDiv"}
          >
            <div className={"mBgBack"}>
              <BgImg src={item.background} />
            </div>

            <TopInfo className={"mBgInfoArea"} isWhite={item.changeColorYn}>
              <div>
                {/* <p className={"mBgCatInfo"}>
                <CateText className={"mBgSubText"}>
                  분야 - {item.contest.category[0]}
                </CateText>
                <CateText className={"mBgSubText"}>
                  대상 - {item.contest.target[0]}
                </CateText>
              </p> */}
                <p className={"mBgTitle"}>{item.title}</p>
                {/* <p className={"mBgSubText"}>{item.contest.hostName}</p> */}
                <div className={"mBgSubTextArea"}>
                  <div>
                    <span className={"mBgSubText"}>
                      {moment(item.contest.startDate).format("YYYY-MM-DD")} -{" "}
                      {moment(item.contest.endDate).format("YYYY-MM-DD")}
                    </span>
                    <span className={"mBgSubStatus"}>
                      {checkContestSTatus(
                        item.contest.startDate,
                        item.contest.endDate
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </TopInfo>
          </div>
        ))}
      </Slider>
    );
  };
  const renderList = (list, index) => {
    const settings = {
      className: "topSlide mobileSlide",
      dots: false,
      arrows: false,
      Infinity: true,
      slidesToShow: 3,

      slidesToScroll: 1,
      focusOnSelect: true,
      beforeChange: (current, next) => {
        setIndex(next);
      },
    };
    console.log(list);
    return (
      <Slider {...settings}>
        {list.map((item, index) => (
          <ThumbItemDiv
            key={`main_thumb_${index}`}
            type="tspa"
            onClick={() => setIndex(index)}
          >
            <Thumb src={item.thumbnail} />
            {/* <BannerTextArea>
              <p>{item.contest.title}</p>
            </BannerTextArea> */}
          </ThumbItemDiv>
        ))}
      </Slider>
    );
  };
  const spaUlRef = useRef(null);
  const handleSelect = (index) => {
    console.log(spaUlRef);
    setIndexA(index);
  };
  const renderListA = (list, index) => {
    const settings = {
      className: "spaSlider  mobileSlide",
      dots: false,
      arrows: false,
      Infinity: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      focusOnSelect: true,
      afterChange: (current) => {
        setIndexA(current);
      },
    };
    return (
      <Slider {...settings}>
        {list.map((item, index) => (
          <ThumbItemDiv
            key={`main_thumbA_${index}`}
            type="tspa"
            onClick={() => handleSelect(index)}
          >
            <Thumb src={item.thumbnail} />
          </ThumbItemDiv>
        ))}
      </Slider>
    );
  };
  const renderListB = (list, index) => {
    const settings = {
      className: "spbSlider mobileSlide",
      dots: false,
      arrows: false,
      Infinity: true,
      autoplay: true,
      rows: 2,
      slidesToShow: 3,
      slidesPerRow: 1,
      autoplaySpeed: 3000,
      focusOnSelect: true,
    };
    return (
      <Slider {...settings}>
        {list.map((item, index) => (
          <ThumbItemDiv
            key={`main_thumbB_${index}`}
            onClick={() =>
              pageHandler(item.contest.type, item.contest.id, item.id, "spb")
            }
          >
            <Thumb src={item.thumbnail} type="spb" />
          </ThumbItemDiv>
        ))}
      </Slider>
    );
  };
  const renderListBand = (list, index) => {
    const settings = {
      className: "spbSlider mobileSlide",
      dots: false,
      arrows: false,
      Infinity: true,
      autoplay: true,
      slidesToShow: 1,
      autoplaySpeed: 3000,
      focusOnSelect: true,
    };
    return (
      <Slider {...settings}>
        {list.map((item, index) => (
          <ThumbItemDiv
            key={`main_thumbBand_${index}`}
            type={"band"}
            onClick={() =>
              pageHandler(item.contest.type, item.contest.id, item.id, "band")
            }
          >
            <Thumb type={"band"} src={item.thumbnail} />
          </ThumbItemDiv>
        ))}
      </Slider>
    );
  };
  const renderListContest = (list, index) => {
    const settings = {
      className: "contestSlider mobileSlide",
      dots: false,
      arrows: false,
      Infinity: false,
      slidesToShow: 2,
      centerMode: true,
      centerPadding: "40px",
      // adaptiveHeight: true,
      focusOnSelect: true,
    };
    return (
      <Slider {...settings}>
        {list.map((item, index) => (
          <ThumbItemDiv
            key={`main_thumbContest_${index}`}
            onClick={() => navi(`/contest/view/${item.type}/${item.id}`)}
          >
            <Thumb type={"contest"} src={item.thumbnail} height="190" />
          </ThumbItemDiv>
        ))}
      </Slider>
    );
  };
  const renderDetailA = (list) => {
    const settings = {
      className: "spaSlider  mobileSlide",
      dots: false,
      arrows: false,
      Infinity: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
      autoplay: true,
      autoplaySpeed: 3000,
    };
    return (
      <Slider {...settings}>
        {list?.map((item, index) => (
          <SpaWrap
            key={`main_spa_detail`}
            onClick={() =>
              pageHandler(item.contest.type, item.contest.id, item.id, "spa")
            }
          >
            <BgImg src={item.background} style={{ opacity: 0.1 }} />
            <SpaContents>
              <div>
                <img src={item.thumbnail} style={{ width: "150px" }} />
                <div className={"customSpa"}>
                  <p className="customSpaTitle">{item.title}</p>
                  <p className={"customSpaInfo"}>{item.contest?.hostName}</p>
                  <p className={"customSpaInfo date"}>
                    {moment(item.contest?.startDate).format("YYYY-MM-DD")} ~{" "}
                    {moment(item.contest?.endDate).format("YYYY-MM-DD")}
                  </p>
                </div>
              </div>
            </SpaContents>
          </SpaWrap>

          // <ThumbItem key={`main_thumbA_${index}`} onClick={() => setIndexA(index)}>
          //   <Thumb src={item.thumbnail} />
          // </ThumbItem>
        ))}
      </Slider>
    );
  };

  return (
    <Wrap>
      <div className="mBgTp">
        <p>Top 배너</p>
        {render(list, index)}
        {/* <div className={"mBgList"}>
          <ThumbBodyDiv>{renderList(list)}</ThumbBodyDiv>
        </div> */}
      </div>

      <div className={"mBgSp"}>
        <p>Special 배너 A</p>

        <div className={"mBgDetail"}>{renderDetailA(listA, indexA)}</div>
      </div>
      <div className={"mBgSp"}>
        <p>Special 배너 B</p>
        <div className={"mBgList"}>
          <ThumbBodyDiv>{renderListB(listB)}</ThumbBodyDiv>
        </div>
      </div>
      <div className={"mBgSp"}>
        <div className={"mBgList"}>
          <ThumbBodyDiv>{renderListBand(listBand)}</ThumbBodyDiv>
        </div>
      </div>
      <div className={"mBgSp"}>
        <p>신규 공모전</p>
        <div className={"mBgList"}>
          <ThumbBodyDiv>{renderListContest(contests)}</ThumbBodyDiv>
        </div>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  margin-top: 3.5em;
  height: 100%;

  .mobileSlide:not(.topSlide, .spaSlider) {
    .slick-list {
      .slick-slide {
        opacity: 0.5;
        &.slick-active {
          &.slick-current {
            opacity: 1;
          }
        }
      }
    }
    &.topSlide,
    &.spaSlider {
      .slick-list {
        .slick-slide {
          &.slick-active {
            &.slick-current {
              > div {
                > div {
                  /* padding-left: 0 !important; */
                }
              }
            }
          }
        }
      }
    }
    &.contestSlider {
      .slick-list {
        .slick-slide {
          opacity: 1;
          &.slick-active {
            &.slick-current {
            }
          }
        }
      }
    }
  }

  .mBgList {
    overflow-x: scroll;
  }
  .mBgSp {
    overflow-x: scroll;
  }

  .mBgDiv {
    position: relative;
  }

  .mBgBack {
    /* box-shadow: #2b303b 0px 0px 20px 0px inset; */
    > img {
      position: relative;
      z-index: -1;
    }
  }

  .mBgTp {
    padding-top: 0.5em;
    > p {
      position: relative;
      top: -12px;
      font-size: 0.65em;
      z-index: -1;
      color: #ffffff;
      padding-left: 0.25em;
      letter-spacing: -0.7px;
      &::after {
        position: absolute;
        /* right: 0; */
        bottom: 0.2em;
        margin-left: 0.25em;
        content: "";
        width: 5px;
        height: 5px;
        background-color: red;
        border-radius: 50%;
      }
    }
  }

  .mBgSp {
    /* margin-top: 2em;
    padding-top: 1em; */
    padding-top: 0.5em;
    position: relative;
    > p {
      position: relative;
      /* top: -12px; */
      font-size: 0.65em;
      z-index: -1;
      color: #ffffff;
      padding-left: 0.25em;
      letter-spacing: -0.7px;
      &::after {
        position: absolute;
        bottom: 0.2em;
        margin-left: 0.25em;
        /* right: 0; */
        content: "";
        width: 5px;
        height: 5px;
        background-color: red;
        border-radius: 50%;
      }
    }

    .mBgDetail {
      padding: 0.25em 0;
    }
  }
`;

const CateText = styled.span`
  padding: 0.1em 0.3em;
  margin-right: 1em;
  border-radius: 5px;
  background-color: green;
  color: white !important;
`;

const SpaWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;
const BgImg = styled.img`
  position: relative;
  width: 100%;
`;
const SpaContents = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* margin: auto 0; */
  top: 0;
  left: 0;
  display: flex;
  font-size: 0.55em;
  justify-content: flex-start;
  align-items: center;
  padding-inline-start: 10px;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    .customSpa {
      padding-inline-start: 10px;
      display: flex;
      /* height: 100%;s */
      margin: 0 auto;
      flex-direction: column;
      justify-content: space-between;
      color: white;
      align-items: flex-start;

      .customSpaTitle {
        letter-spacing: -0.9px;
      }
      .customSpaInfo {
        color: gray;
        letter-spacing: -0.9px;
        font-size: 0.75em;
        &.date {
        }
      }
    }
  }
`;
const ThumbBodyDiv = styled.div`
  /* display: flex; */
  /* flex-wrap: nowrap; */
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  /* overflow-x: scroll; */
  margin-top: 0.25em;
  width: 100%;
`;
const ThumbBody = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;
  margin-top: 1em;
  width: fit-content;
`;
const ThumbItemDiv = styled.div`
  /* width: calc(170px) !important; */
  /* margin-left: 0.5em; */
  padding-left: 0.5em;
  /* ${(props) => props.type === "tspa" && `margin-left:0.5em`}; */
  /* margin-left: 0.5em; */
  &:last-child {
    /* margin-right: 0.5em; */
    padding-left: 0.5em;
  }
  ${(props) =>
    props.type === "band" &&
    css`
      padding-left: 0 !important;
    `}
`;
const ThumbItem = styled.li`
  width: 150px;
  margin-left: 0.5em;
  &:last-child {
    margin-right: 0.5em;
  }
`;
const Thumb = styled.img`
  width: 100%;
  padding-right: ${(props) =>
    props.type === "contest" || props.type === "spb" ? `0` : `20px`};
  ${(props) =>
    props.type === "band" &&
    css`
      padding-right: 0;
    `};
`;

const TopInfo = styled.div`
  @media (min-width: 425px) and (max-width: 766px) {
    font-size: 24px;
  }
  @media screen and (max-width: 425px) {
    font-size: 0.65em;
  }
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
  padding: 0.2em 0.5em;

  .mBgTitle {
    font-size: 0.75em;
    letter-spacing: -0.9px;
    font-weight: bold;
    /* font-size: 0.55em; */
    color: ${(props) => (props.isWhite ? `#000` : `#fff`)};
  }
  .mBgCatInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .mBgSubTextArea {
    line-height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mBgSubText {
    color: ${(props) => (props.isWhite ? `#00000080` : `#ffffff80`)};
    font-size: 0.55em;
    letter-spacing: -0.7px;
  }
  .mBgSubStatus {
    display: inline-block;
    color: ${(props) => (props.isWhite ? `#00000080` : `#ffffff80`)};
    font-size: 0.55em;
    letter-spacing: -0.7px;
    padding: 0.15em;
    margin-left: 0.5em;
    font-weight: bold;
    border: ${(props) =>
      props.isWhite ? `1px solid #00000080` : `1px solid #ffffff80`};
  }
`;

const BannerTextArea = styled.div``;

export default MobileIndex;
