import React, { Suspense, useEffect, useState } from "react";
import FilterContest from "../../components/Contest/FilterContest";
import { useParams } from "react-router-dom";
import contestStyle from "../../assets/css/contest.module.scss";
import SortContest from "../../components/Contest/SortContest";
import EmptyContestList from "../../components/Contest/EmptyContestList";
import ContestList from "../../components/Contest/ContestList";
import {
  ChoiceContests,
  ContestContainer,
  ContestRecItem,
  ContestRecItems,
  ContestSubviual,
  FilterItems,
  SuspenseContest,
} from "../contestActivity/indexFake";

// 코인 공모전 인덱스 페이지 (초기페이지)
const BlockChainIndex = () => {
  const { type } = useParams();

  const [info, setInfo] = useState({
    contestType: 1,
    kor: "공모전",
    en: type,
  });
  const [params, setParams] = useState({
    order: 0,
    keyword: "",
    process: -1,
    page: 1,
    pageToggle: false,
    filter: {
      category: [],
      target: [],
      host: [],
      totalReward: [],
    },
  });
  const [preventReload, setPreventReload] = useState(false);

  useEffect(() => {
    // setPreventReload(false);
    setInfo({
      contestType: 0,
      kor: "공모전",
      en: type,
    });

    // return () => {
    //   setPreventReload(true);
    //   setParams({
    //     order: 0,
    //     keyword: "",
    //     process: -1,
    //     page: 1,
    //     pageToggle: false,
    //     filter: {
    //       category: [],
    //       target: [],
    //       host: [],
    //       totalReward: [],
    //     },
    //   });
    // };
  }, [type]);

  // useEffect(() => {
  //   if (preventReload) return;
  //   console.log("param change");
  // }, [params]);

  const [dropOpen, setDropOpen] = useState({
    open: false,
    type: "process",
  });

  const onDropToggle = (toggle, type) => {
    setDropOpen({ open: toggle, type: type });
  };

  const changeFilter = (list) => {
    setParams({
      ...params,
      filter: { ...list },
      page: 1,
      pageToggle: false,
    });
  };

  const changeProcess = (value) => {
    setParams({
      ...params,
      process: value,
      page: 1,
      pageToggle: false,
    });
  };

  const changeOrdered = (value) => {
    setParams({
      ...params,
      order: value,
      page: 1,
      pageToggle: false,
    });
  };

  const searchKeyword = (value) => {
    setParams({
      ...params,
      keyword: value,
      page: 1,
      pageToggle: false,
    });
  };
  const changePage = (page) => {
    setParams({
      ...params,
      page: page,
      pageToggle: true,
    });
  };
  const resetParam = () => {
    setParams({
      order: 0,
      keyword: "",
      process: -1,
      page: 1,
      pageToggle: false,
      filter: {
        category: [],
        target: [],
        host: [],
        totalReward: [],
      },
    });
  };

  return (
    <div className={"container sub"}>
      <ContestSubviual>
        <ContestRecItems>
          <ChoiceContests type={info.en} isOnChain={true} />
        </ContestRecItems>
      </ContestSubviual>
      <ContestContainer>
        <FilterItems
          label={info.kor}
          contestType={info.contestType}
          toggle={onDropToggle}
          current={dropOpen.type}
          open={dropOpen.open}
          changeFilter={changeFilter}
          changeProcess={changeProcess}
          changeOrder={changeOrdered}
          searchKeyword={searchKeyword}
          reset={resetParam}
          onChain={true}
        />
        <SuspenseContest
          param={params}
          type={"coin"}
          changePage={changePage}
          isOnChain={true}
        />
      </ContestContainer>
    </div>
  );
};

export default BlockChainIndex;
