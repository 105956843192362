import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Btn } from "../../../components/HostCenter/Promotion/PViewCommon";
import { NormalReqApi } from "../../../utils/ReqApi";

const passwordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const FindPwd2 = () => {
  const location = useLocation();
  const navi = useNavigate();
  const [email, setEmail] = useState("");
  //   const [errors, setErrors] = useState({
  //     pwd:{
  //         pass:false,

  //     }
  //   });
  const pwdRef = useRef(null);
  const pwdCheckRef = useRef(null);
  const [valid, setValid] = useState({
    pwd: {
      msg: "",
      flag: true,
    },
    pwdCheck: {
      msg: "",
      flag: true,
    },
  });
  const [error, setError] = useState(false);
  console.log(email);

  useEffect(() => {
    if (location.state) {
      setEmail(location.state.id);
    } else {
      alert("올바르지 않은 접근입니다. 페이지를 이동합니다.");
      navi("/findpwd");
    }

    // navi(location.pathname, { replace: {} });
  }, []);

  const isValidPwd = (pwd, pwdCheck) => {
    const resultObject = {
      pwd: false,
      pwdCheck: false,
      pwdMsg: "",
      pwdCheckMsg: "",
    };

    if (passwordRegExp.test(pwd)) {
      resultObject.pwd = true;
      resultObject.pwdMsg = "";
    } else {
      resultObject.pwd = false;
      resultObject.pwdMsg =
        "특수문자,영문 대소문자, 숫자를 포함한 비밀번호를 입력해주시기 바랍니다";
    }
    if (pwd !== pwdCheck) {
      resultObject.pwdCheck = false;
      resultObject.pwdCheckMsg = "비밀번호가 일치하지 않습니다.";
    } else {
      resultObject.pwdCheck = true;
      resultObject.pwdCheckMsg = "";
    }
    setValid({
      pwd: {
        flag: resultObject.pwd,
        msg: resultObject.pwdMsg,
      },
      pwdCheck: {
        flag: resultObject.pwdCheck,
        msg: resultObject.pwdCheckMsg,
      },
    });

    return resultObject;
  };

  const changePwd = async () => {
    // const result = NormalReqApi("patch", `/user/`);
  };

  const handleChangePwd = () => {
    setError(false);
    const pwd = pwdRef.current.value;
    const pwdCheck = pwdCheckRef.current.value;
    const { pwd: pwdResult, pwdCheck: pwdCheckResult } = isValidPwd(
      pwd,
      pwdCheck
    );
    if (pwdResult && pwdCheckResult) {
      console.log("change allow");
    } else {
      setError(true);
      console.log(pwdResult, pwdCheckResult);
    }
  };

  return (
    <div className={"container sub"}>
      {/* <div className={"subVisual mypage"}></div> */}
      <div className={`sub_container`} style={{ marginTop: "3.5em" }}>
        <div className={`contents mypage`} style={{ height: "100%" }}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>비밀번호 변경</span>
          </h2>
          {error && (
            <>
              {!valid.pwd.flag && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "0.65em",
                    padding: "0.1em",
                    marginTop: "0.5em",
                    backgroundColor: "#ff000024",
                    color: "red",
                  }}
                >
                  <p>{valid.pwd.msg}</p>
                </div>
              )}

              {!valid.pwdCheck.flag && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "0.65em",
                    padding: "0.1em",
                    marginTop: "0.5em",
                    backgroundColor: "#ff000024",
                    color: "red",
                  }}
                >
                  <p>{valid.pwdCheck.msg}</p>
                </div>
              )}
            </>
          )}

          <div
            style={{
              padding: "1em 0",
            }}
          >
            <div
              style={{
                padding: "2em",
                border: "1px solid #dbdbdb",

                display: "flex",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "0.5em",
                  }}
                >
                  <label style={{ marginRight: "1em", fontSize: "0.85em" }}>
                    변경할 비밀번호{" "}
                  </label>
                  <input
                    type="password"
                    style={{
                      border: "1px solid #dbdbdb",
                      borderRadius: "5px",
                      fontSize: "0.75em",
                      padding: "0.25em",
                    }}
                    ref={pwdRef}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label style={{ marginRight: "1em", fontSize: "0.85em" }}>
                    비밀번호 확인{" "}
                  </label>
                  <input
                    type="password"
                    style={{
                      border: "1px solid #dbdbdb",
                      borderRadius: "5px",
                      fontSize: "0.75em",
                      padding: "0.25em",
                    }}
                    ref={pwdCheckRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <Btn
              type={"button"}
              onClick={handleChangePwd}
              style={{ fontSize: "0.85em" }}
            >
              비밀번호 수정
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPwd2;
