import React from "react";
import Default from "../../../assets/img/common/img_posterDefault_280.jpg";
import Cestyle from "../../../assets/css/event.module.scss";
export const EmptyList_noData = () => {
  return (
    <>
      <li>
        <a>
          <img src={Default} style={{ width: "280px", height: "210px" }} />
          <span className={"thumb_title"}>{"deitzen"}</span>
          <p className={"thumb_date"}></p>
        </a>
      </li>
    </>
  );
};

export const EmptyList_list_noData = () => {
  return (
    <>
      <div className="board_search">
        <select name="searchType">
          <option value="0">선택</option>
          <option value="title">제목</option>
          {/* <option value="host">주최사</option>
                                      <option value="content">내용</option> */}
        </select>
        <input type="text" name="searchWrd" />
        <button type="submit">검색</button>
      </div>
      <ul className={`thumbList newThumb ${Cestyle.thumbList}`}>
        <li></li>
      </ul>
    </>
  );
};
