import React, { useState } from "react";
import { useQuery } from "react-query";
import { ClientReqGet } from "../../../utils/ClientAuth";
import { Loading } from "../../DContents/Mobile/MTemplate";

const UserContentEvent = ({ account, toggle }) => {
  const [page, setPage] = useState(1);
  const { data, error, isLoading } = useQuery(
    ["myeventTop3", account, toggle],
    async () =>
      await ClientReqGet(
        `/event/my?page=${page}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false }
  );
  if (isLoading) {
    return <Loading />;
  }

  return data.data.length > 0 ? (
    <li></li>
  ) : (
    <li className={"noContents"}>
      <p className={"cUserNoContents"}>데이터가 존재하지 않습니다</p>
    </li>
  );
};
export default UserContentEvent;
