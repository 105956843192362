import moment from "moment";
import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  HiChevronLeft,
  HiChevronRight,
  HiDotsHorizontal,
  HiPaperAirplane,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ClientDelReq,
  ClientReqGet,
  ClientReqPatch,
  ClientReqPost,
} from "../../utils/ClientAuth";
import { Loading } from "../DContents/Mobile/MTemplate";

const RPop = styled.div`
  position: fixed;
  bottom: ${(props) => (props.open ? 0 : `-2000px`)};
  width: 100%;
  /* height: 100%; */
  z-index: 111111;
  transition: all 0.3s ease-in-out;

  .rPopBg {
    position: fixed;
    top: 0;
    width: 100%;
    height: ${(props) => (props.open ? `100%` : 0)};
    z-index: 111112;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .rPopBody {
    position: relative;
    width: 100%;
    height: 80vh;
    bottom: 0;
    background-color: black;
    z-index: 111113;
    border-top-left-radius: 0.75em;
    border-top-right-radius: 0.75em;
    padding-top: 0.95em;

    .rPopContents {
      color: white;
      font-size: 0.75em;
      .rPopNonAuth {
      }
      .rPopInputArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.2);
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-bottom: 10px;
        .rPopInput {
          font-size: 14px;
          font-weight: 300;
          color: white;
          width: 90%;
          padding: 0.25em 0.5em;
          background: transparent;
          resize: none;
          border: none;
          &:focus {
            outline: none;
          }
        }
        .rPopBtn {
          transform: rotateZ(90deg);
        }
      }
      .rPopContentArea {
        padding-left: 0.5em;
        padding-right: 0.5em;
        position: relative;
        width: 100%;
        height: 100%;
        .rPopNoContent {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          p {
          }
        }
        > ul {
          overflow-y: scroll;
          height: 80vh;
          padding-bottom: 5em;
          > li {
            padding: 0.5em 0;
            margin: 0.5em 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            font-size: 0.75em;
            .rPopReplyHeadArea {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .rPopReplyCustom {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                > span {
                  margin-left: 1.5em;
                }
              }
            }
            .rPopReplyContentArea {
              > p {
                padding: 0.25em 0;
                margin: 0.25em 0;
                color: gray;
              }
              .rPopEditSend {
                display: block;
                text-align: right;
                .rPopEditEndBtn {
                  transform: rotateZ(90deg);
                }
              }
              > textarea {
                padding: 0.5em 0.25em;
                margin: 0.25em 0;
                color: gray;
                font-size: 1em;
                width: 100%;
                background: transparent;
                border: 1px solid rgba(255, 255, 255, 0.1);
                resize: none;
                &:focus {
                  color: rgba(255, 255, 255, 0.9);
                  outline: 1px solid rgba(255, 255, 255, 0.3);
                }
              }
              .rPopReplyEtc {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .rPopReplyThumbs {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .rPopReplyThumb {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-right: 0.5em;
                    margin-right: 0.5em;
                    > span {
                      margin-left: 0.5em;
                    }
                  }
                }
                .rPopReplyDate {
                  font-size: 0.75em;
                  color: gray;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ReplyContent = ({
  isOwn,
  author,
  text,
  recommend,
  unRecommend,
  createdAt,
  editSubmit,
  delReply,
  subReply,
  link,
  status,
  account,
  toggle,
  _id,
}) => {
  const [cnt, setCnt] = useState({
    rec: 0,
    unRec: 0,
  });
  // console.log(isOwn);

  useEffect(() => {
    setCnt({
      rec: recommend,
      unRec: unRecommend,
    });
  }, [_id]);
  const [etcOpen, setEtcOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const closeEtc = () => {
    setEdit(false);
    setEtcOpen(false);
  };
  const editRef = useRef(null);
  const editText = () => {
    const editText = editRef.current.value;
    if (
      !editText ||
      editText.length < 5 ||
      editText.replaceAll(" ", "").length < 5
    ) {
      alert("5자 이상 입력해주시기 바랍니다");
      return false;
    }
    editSubmit(editText, _id);
    closeEtc();
  };

  const delText = () => {
    delReply(_id);
  };

  const handleThumbup = async () => {
    console.log(cnt.rec);
    const result = await ClientReqPatch(
      `/reply/changecnt/recommend?id=${_id}`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag && result.data === "inc") {
      console.log("inc");
      setCnt({ ...cnt, rec: cnt.rec + 1 });
    } else if (result.flag && result.data === "dec") {
      console.log("dec");
      setCnt({ ...cnt, rec: cnt.rec - 1 });
    }
  };
  const handleThumbDown = async () => {
    const result = await ClientReqPatch(
      `/reply/changecnt/unRecommend?id=${_id}`,
      {},
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag && result.data === "inc") {
      setCnt({ ...cnt, unRec: cnt.unRec + 1 });
    } else if (result.flag && result.data === "dec") {
      setCnt({ ...cnt, unRec: cnt.unRec - 1 });
    }
  };

  return (
    <li>
      <div className={"rPopReplyHeadArea"}>
        <span>{author.nickName}</span>

        {isOwn &&
          (etcOpen ? (
            <div className={"rPopReplyCustom"}>
              <span onClick={delText}>삭제</span>
              <span onClick={() => setEdit(true)}>수정</span>
              <span onClick={closeEtc}>취소</span>
            </div>
          ) : (
            <span onClick={() => setEtcOpen(true)}>
              <HiDotsHorizontal />
            </span>
          ))}
      </div>
      <div className={"rPopReplyContentArea"}>
        {edit ? (
          <>
            <textarea defaultValue={text} ref={editRef}></textarea>
            <div className={"rPopEditSend"}>
              <HiPaperAirplane
                className={"rPopEditEndBtn"}
                onClick={editText}
              />
            </div>
          </>
        ) : (
          <p>{status === 0 ? text : "삭제된 댓글입니다."}</p>
        )}

        <div className={"rPopReplyEtc"}>
          <div className={"rPopReplyThumbs"}>
            {!account.accessToken || !account.id ? (
              <></>
            ) : (
              <>
                <div className={"rPopReplyThumb up"}>
                  <HiThumbUp color={"white"} onClick={handleThumbup} />
                  <span>{cnt.rec}</span>
                </div>
                <div className={"rPopReplyThumb down"}>
                  <HiThumbDown color={"white"} onClick={handleThumbDown} />
                  <span>{cnt.unRec}</span>
                </div>
              </>
            )}
          </div>
          <span className={"rPopReplyDate"}>
            {moment(createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
        {account.accessToken && account.id && (
          <p>
            <Link to={`/reply/${_id}`} style={{ color: "gray" }}>
              댓글 ({subReply.length}){" "}
            </Link>
          </p>
        )}
      </div>
    </li>
  );
};

const ReplyPaging = () => {
  return (
    <>
      <HiChevronLeft color={"white"} />
      <HiChevronRight color={"white"} />
    </>
  );
};

const ReplyContents = ({ id, type, account, toggle, setReply }) => {
  const [page, setPage] = useState(1);

  const textRef = useRef(null);

  const { data, error, isLoading, refetch } = useQuery(
    ["contestReply", id, page, type],
    async () =>
      await ClientReqGet(
        `/reply?id=${id}&type=${type}&page=${page * 1}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: true }
  );
  useEffect(() => {
    if (!isLoading) {
      setReply(data.data.count);
    }
  }, [data]);

  const handleSubmit = async () => {
    const text = textRef.current.value;
    if (!text || text.length < 5) {
      alert("5자 이상 입력해주시기 바랍니다.");
      return false;
    }
    const template = { text };
    const result = await ClientReqPost(
      `/reply?type=${type}&id=${id}`,
      {
        ...template,
      },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      refetch();
    }
  };

  const handleEditSubmit = async (editText, replyId) => {
    const template = { text: editText };
    const result = await ClientReqPatch(
      `/reply?id=${replyId}`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      refetch();
    }
  };

  const handleDelReply = async (replyId) => {
    const result = await ClientDelReq(
      `/reply?id=${replyId}`,
      account.accessToken,
      account.refreshToken,
      toggle
    );
    if (result.flag) {
      refetch();
    }
  };

  const isWriter = (id, userId) => {
    return id === userId;
  };

  const navi = useNavigate();
  const link = (replyId) => {
    navi(`/reply/${replyId}`);
  };

  return (
    <>
      <div className={"rPopInputArea"}>
        {!account.id || !account.accessToken ? (
          <>
            <textarea
              className="rPopInput"
              ref={textRef}
              placeholder="로그인 후 이용해주시기 바랍니다"
              style={{
                fontSize: "1em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: "1em",
                opacity: 1,
                textAlign: "center",
                padding: "0",
                width: "100%",
                height: "100%",
              }}
            ></textarea>
          </>
        ) : (
          <>
            <textarea className="rPopInput" ref={textRef}></textarea>
            <HiPaperAirplane
              className={"rPopBtn"}
              color={"white"}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>
      <div className={"rPopContentArea"}>
        {data.data.count === 0 ? (
          <div className={"rPopNoContent"}>
            <p>등록된 내용이 없습니다</p>
          </div>
        ) : (
          <ul>
            {data.data.result.map((item, index) => (
              <ReplyContent
                key={`rq_${type}_${id}_${item._id}_${index}`}
                isOwn={isWriter(item.author._id, account.id)}
                editSubmit={handleEditSubmit}
                link={() => link(item._id)}
                delReply={handleDelReply}
                toggle={toggle}
                account={account}
                {...item}
              />
            ))}
          </ul>
        )}
      </div>
      <div className={"rPopContentPaging"}>
        <ReplyPaging />
      </div>
    </>
  );
};

const ReplyMobile2 = ({ open, close, id, type, account, toggle, setReply }) => {
  return (
    <RPop open={open}>
      <div className={"rPopBg"} onClick={close}></div>
      <div className={"rPopBody"}>
        <div className={"rPopContents"}>
          <Suspense fallback={<Loading />}>
            <ReplyContents
              id={id}
              type={type}
              account={account}
              toggle={toggle}
              setReply={setReply}
            />
          </Suspense>
          {/* {!account.id || !account.accessToken ? (
            <div className={"rPopNonAuth"}>
              <p>로그인 후 이용해주시기 바랍니다</p>
            </div>
          ) : (
            <Suspense fallback={<Loading />}>
              <ReplyContents
                id={id}
                type={type}
                account={account}
                toggle={toggle}
                setReply={setReply}
              />
            </Suspense>
          )} */}
        </div>
      </div>
    </RPop>
  );
};

export default ReplyMobile2;
