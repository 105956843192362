import moment from "moment";
import React, { useState } from "react";
import { HiEye } from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ClientReqGet } from "../../../utils/ClientAuth";
import { Loading } from "../../DContents/Mobile/MTemplate";

const UserContentsBoard = ({ account, toggle }) => {
  const { data, error, isLoading } = useQuery(
    ["chgList", account, toggle],
    async () =>
      await ClientReqGet(
        `/chg/my`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false }
  );
  const [tab, setTab] = useState(0);
  const navi = useNavigate();

  return (
    <>
      <li className={"cUserLiTab"}>
        <span className={tab === 0 ? "active" : ""} onClick={() => setTab(0)}>
          팀원모집
        </span>
        <span className={tab === 1 ? "active" : ""} onClick={() => setTab(1)}>
          활동후기
        </span>
        <span className={tab === 2 ? "active" : ""} onClick={() => setTab(2)}>
          자유게시판
        </span>
      </li>
      <li>
        {isLoading ? (
          <Loading />
        ) : (
          <ul className={"cUserBoardWrap"}>
            {tab === 0 ? (
              data.data.team.length > 0 ? (
                data.data.team.map((item, index) => (
                  <li
                    key={`my_board_${tab}_${index}`}
                    className={"cUserBoards"}
                    onClick={() => navi(`/teamplay/${item._id}`)}
                  >
                    <p className={"cUserBoardTitle"}>{item.title}</p>
                    <div className={"cUserBoardEtc"}>
                      <div className={"cUserViewCntArea"}>
                        <HiEye size={13} color={"gray"} />
                        <span>{item.viewCnt}</span>
                      </div>
                      <span className={"cUserDate"}>
                        {moment(item.createdAt).format("YYYY-MM-DD")}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li className={"noContents"}>
                  <p>데이터가 존재하지 않습니다</p>
                </li>
              )
            ) : tab === 1 ? (
              data.data.after.length > 0 ? (
                data.data.after.map((item, index) => (
                  <li
                    key={`my_board_${tab}_${index}`}
                    className={"cUserBoards"}
                    onClick={() => navi(`/after/${item._id}`)}
                  >
                    <p className={"cUserBoardTitle"}>{item.title}</p>
                    <div className={"cUserBoardEtc"}>
                      <div className={"cUserViewCntArea"}>
                        <HiEye size={13} color={"gray"} />
                        <span>{item.viewCnt}</span>
                      </div>
                      <span className={"cUserDate"}>
                        {moment(item.createdAt).format("YYYY-MM-DD")}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li className={"noContents"}>
                  <p>데이터가 존재하지 않습니다</p>
                </li>
              )
            ) : data.data.free.length > 0 ? (
              data.data.free.map((item, index) => (
                <li
                  key={`my_board_${tab}_${index}`}
                  className={"cUserBoards"}
                  onClick={() => navi(`/freeboard/${item._id}`)}
                >
                  <p className={"cUserBoardTitle"}>{item.title}</p>
                  <div className={"cUserBoardEtc"}>
                    <div className={"cUserViewCntArea"}>
                      <HiEye size={13} color={"gray"} />
                      <span>{item.viewCnt}</span>
                    </div>
                    <span className={"cUserDate"}>
                      {moment(item.createdAt).format("YYYY-MM-DD")}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className={"noContents"}>
                <p>데이터가 존재하지 않습니다</p>
              </li>
            )}
          </ul>
        )}
      </li>
    </>
  );
};

export default UserContentsBoard;
