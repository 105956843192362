import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/globals.css";
import "./assets/css/common.css";
import "./assets/css/reset.css";
import "./assets/css/layout.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "./components/Common/ScrollTop";
import ReactGA4 from "react-ga4";
const TId = process.env.REACT_APP_GA_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA4.initialize(TId);
root.render(
  <BrowserRouter>
    <ScrollTop />
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
