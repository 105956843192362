import React, { Suspense } from "react";
import { BandSlider } from "./DetailSlider";
import Band1 from "../../assets/img/banner/band1.jpeg";
import Band2 from "../../assets/img/banner/band2.jpeg";
import custom from "../../assets/css/Band.module.scss";
import BandBanner from "../Banner/BandBanner";

const TEMP_LIST = [
  { _id: 1, thumbnail: Band1 },
  { _id: 2, thumbnail: Band2 },
];

const MainSection3 = () => {
  return (
    <div className={"midBanner"} style={{ paddingBottom: "1em" }}>
      <Suspense fallback={<div></div>}>
        <BandBanner />
      </Suspense>
    </div>
  );
};

export default MainSection3;
