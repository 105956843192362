import DOMPurify from "dompurify";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  DcView,
  Loading,
  ViewHeader,
} from "../../components/DContents/Mobile/MTemplate";
import { NormalReqApi } from "../../utils/ReqApi";
import styled from "styled-components";

const EmptyViewMobile = () => {
  const purify = DOMPurify.sanitize;
  const { id } = useParams();
  const navi = useNavigate();

  const handleGoBack = () => {
    navi(-1);
  };

  const { data, error, isLoading } = useQuery(
    ["emptyview", id],
    async () => await NormalReqApi("get", `/emptytime/view/${id}`),
    { retry: true }
  );

  return (
    <ViewHeader label={"공강시간"}>
      {isLoading ? (
        <Loading />
      ) : (
        <DcView>
          <section role="header" className={"dcViewTop"}>
            <p className={"dcViewTitle"}>{data.data.title}</p>
            <div className={"dcViewSubInfo"}>
              <span className={"dcViewAuthor"}>대티즌</span>
              <span className={"dcViewDate"}>
                {moment(data.data.createdAt).format("YYYY-MM-DD")}
              </span>
            </div>
          </section>
          <section role="content" className="dcViewEditor">
            <ContentBox
              className="dcViewContents ql-editor"
              dangerouslySetInnerHTML={{ __html: data.data.contents }}
            ></ContentBox>
          </section>
          <section role="button" className={"dcViewBtnArea"}>
            <button type="button" onClick={handleGoBack}>
              목록으로
            </button>
          </section>
        </DcView>
      )}
    </ViewHeader>
  );
};

export default EmptyViewMobile;

export const ContentBox = styled.div`
  &.dcViewContents {
    p {
      font-size: 15px;
    }
    img {
      display: unset;
      width: 100% !important;
    }
  }
`;
