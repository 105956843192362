import moment from "moment";
import React, { useRef, useState } from "react";
import { HiEye } from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  ChgLiItems,
  ChgUl,
  CreateBtn,
  CreateButton,
  Header,
  PageHeader,
} from "../../components/Challenge/MobileTemplate/Layout";
import { MPaging } from "../../components/Common/PagingM";
import { Loading } from "../../components/DContents/Mobile/MTemplate";
import { NormalReqApi } from "../../utils/ReqApi";

const FreeBoardMobile = () => {
  const [page, setPage] = useState(1);
  const [sc, setSc] = useState({
    keyword: "",
  });
  const keyRef = useRef(null);
  const navi = useNavigate();

  const { data, error, isLoading } = useQuery(
    ["freeboardList", page, sc],
    async () =>
      await NormalReqApi(
        "get",
        `/freeboard?page=${page}&type=title&keyword=${sc.keyword}`
      ),
    { retry: false }
  );
  const handlePage = (page) => {
    setPage(page * 1);
  };
  //data result
  // result.curPage , result.list , result.total

  return (
    <Header
      label={"자유게시판"}
      subLabel={"익명으로 우리들만의 고민을 이야기해봐요!"}
    >
      <CreateButton label={"글쓰기는 PC 버전으로 이용해주시기 바랍니다"} />
      <ChgUl>
        {isLoading ? (
          <Loading />
        ) : data.result.total > 0 ? (
          data.result.list.map((item, index) => (
            <ChgLiItems
              key={`fb_${item._id}_${index}`}
              linkTo={() => navi(`/freeboard/${item._id}`)}
              {...item}
              isPrivate={true}
              createdAt={moment(item.createdAt).format("YYYY-MM-DD")}
            />
          ))
        ) : (
          <li className={"cNoContents"}>
            <span>등록된 자유게시글이 존재하지 않습니다</span>
          </li>
        )}
      </ChgUl>
      {!isLoading && <MPaging {...data} toggle={handlePage} />}
    </Header>
  );
};

export default FreeBoardMobile;
