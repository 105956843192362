import styled from "styled-components";

const LoadingWrap = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
`;
const LoadingBody = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: center;
  z-index: 11;
`;

export const LoadingLabel = ({ label }) => {
  return (
    <LoadingWrap>
      <LoadingOverlay></LoadingOverlay>
      <LoadingBody>
        <p>{label}</p>
      </LoadingBody>
    </LoadingWrap>
  );
};
