import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";

const EmptyPrev_suspense = () => {
  const { data, error, isLoading } = useQuery(
    ["emptytop5"],
    async () => await NormalReqApi("get", "/emptytime/top5"),
    { retry: true, suspense: true }
  );

  return (
    <>
      {data.data.map((item, index) => {
        if (index < 2) {
          return (
            <li key={`et_prev_${index}`}>
              <Link to={`/emptytime/${item._id}`}>
                <img
                  src={`${process.env.REACT_APP_AWS_ENDPOINT}w_280/${item.thumbnail.path}`}
                  style={{ width: "280px", height: "280px" }}
                />
                <span className={"thumb_title"}>{item.title}</span>
                <span className={"thumb_title"}>{item.des}</span>
                <p className={"thumb_date"}>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </p>
              </Link>
            </li>
          );
        }
      })}
    </>
  );
};

export default EmptyPrev_suspense;
