import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import cStyle from "../../assets/css/newContest.module.scss";
import Default280 from "../../assets/img/common/img_posterDefault_280.jpg";
import { HiEye } from "react-icons/hi";
import { imgOnError } from "../../utils/EmptyImg";

const nowDate = new Date();

const ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT + "w_280/";

const ContestData = ({
  _id,
  title,
  type,
  startDate,
  endDate,
  viewCnt,
  host,
  thumbnail,
}) => {
  const renderStatus = (startDate, endDate) => {
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    const returnObject = {
      text: "",
      status: 0,
      style: null,
    };
    if (nowDate < sDate) {
      returnObject.text = "예정";
      returnObject.status = 0;
      returnObject.style = cStyle.ready;
    } else if (sDate <= nowDate && nowDate < eDate) {
      returnObject.text = "진행중";
      returnObject.status = 1;
      returnObject.style = cStyle.onAir;
    } else if (eDate <= nowDate) {
      returnObject.text = "마감";
      returnObject.status = 2;
      returnObject.style = cStyle.closeAir;
    }

    return (
      <span className={cStyle.statusBox + " " + returnObject.style}>
        {returnObject.text}
      </span>
    );
  };

  return (
    <li className={cStyle.newConAndAct}>
      <Link to={`/contest/view/${type}/${_id}`}>
        <img
          src={ENDPOINT + thumbnail.path}
          className={cStyle.mainThumb}
          onError={imgOnError}
        />
        <div className={cStyle.blockContent}>
          <p className={cStyle.title}>{title}</p>
          <p className={cStyle.hostName}>{host.name}</p>
          <div className={cStyle.status}>
            {renderStatus(startDate, endDate)}
            <span className={cStyle.endDate}>
              ~ {moment(endDate).format("yyyy-MM-DD")}
            </span>
          </div>
          <div className={cStyle.viewCnt}>
            <HiEye color="#bcbcbc" />
            <span>{viewCnt}</span>
          </div>
        </div>
        {/* <div className={hover.flag ? cStyle.open : cStyle.hover}>
          <div className={cStyle.hoverContent}>
            <div className={cStyle.titleAndHost}>
              <span className={cStyle.title}>{title}</span>
              <p className={cStyle.hostName}>{host.name}</p>
            </div>
            <div className={cStyle.minThumb}>
              <img
                src={Default280}
                style={{ width: "150px", height: "195px" }}
              />
            </div>
            <div className={cStyle.dateAndViewCnt}>
              <div className={cStyle.etcInfo}>
                <span className={cStyle.date + " " + cStyle.ready}>test</span>
                <div className={cStyle.viewCnt}>
                  <span className={cStyle.cnt}>{viewCnt}</span>
                </div>
              </div>
              <p className={cStyle.deadLine}>
                {moment(endDate).format("yyyy-MM-DD")}{" "}
              </p>
            </div>
          </div>
        </div> */}
      </Link>
    </li>
  );
};

export default ContestData;
