import React from "react";

export const ParseCurrentState = (startDate, endDate) => {
  const nowDate = new Date();
  const sDate = new Date(startDate);
  const eDate = new Date(endDate);

  const returnObject = {
    text: "",
    status: "",
  };
  if (nowDate < sDate) {
    returnObject.text = "예정";
    returnObject.status = "ready";
  } else if (sDate <= nowDate && nowDate <= eDate) {
    returnObject.text = "진행중";
    returnObject.status = "open";
  } else if (eDate <= nowDate) {
    returnObject.text = "마감";
    returnObject.status = "done";
  }
  return returnObject;
};
