import moment from "moment";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";
import Cestyle from "../../../assets/css/event.module.scss";
import pStyle from "../../../assets/css/paging.module.scss";
import Pagination from "../../../utils/Pagination";

const EmptyTimeList = () => {
  const [page, setPage] = useState(1);
  const [sc, setSc] = useState({
    keyType: "",
    keyword: "",
  });
  const typeRef = useRef(null);
  const keywordRef = useRef(null);

  const { data, error, isLoading } = useQuery(
    ["emptylist", sc, page],
    async () =>
      await NormalReqApi(
        "get",
        `/emptytime/list?keyType=${sc.keyType}&keyword=${sc.keyword}&page=${page}`
      ),
    { retry: true, suspense: true }
  );

  const handleSearch = () => {
    const keyType = typeRef.current.value;
    const keyword = keywordRef.current.value;
    setSc({
      keyType,
      keyword,
    });
  };

  const handlePage = (page) => {
    setPage(page * 1);
  };

  const handleEnterKey = (e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <div className="board_search">
        <select name="searchType" ref={typeRef}>
          <option value="0">선택</option>
          <option value="title">제목</option>
          {/* <option value="host">주최사</option>
											<option value="content">내용</option> */}
        </select>
        <input
          type="text"
          name="searchWrd"
          ref={keywordRef}
          onKeyDown={(e) => handleEnterKey(e)}
        />
        <button type="submit" onClick={handleSearch}>
          검색
        </button>
      </div>
      <ul className={`thumbList newThumb ${Cestyle.thumbList}`}>
        {data.data.result.length > 0 ? (
          data.data.result.map((item, index) => (
            <li key={`et_items_${index}`}>
              <Link to={`/emptytime/${item._id}`}>
                <img
                  src={`${process.env.REACT_APP_AWS_ENDPOINT}w_280/${item.thumbnail.path}`}
                  style={{ width: "280px", height: "280px" }}
                />
                <span className={"thumb_title"}>{item.title}</span>
                <p className={Cestyle.desc}>{item.des}</p>
                <p className={"thumb_date"}>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </p>
              </Link>
            </li>
          ))
        ) : (
          <li>null</li>
        )}
      </ul>
      <div className={`paging_area ${pStyle.paging_area}`}>
        <Pagination
          total={data.data.count}
          curPage={page}
          handlePage={handlePage}
          scale={12}
        />
      </div>
    </>
  );
};

export default EmptyTimeList;
