import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginHeader } from "../../components/Login/LoginHeader";
import custom from "../../assets/css/login.module.scss";
import { HiCheckCircle } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NormalReqApi } from "../../utils/ReqApi";
import { useRecoilState } from "recoil";
import { compSelect } from "../../recoil/AtomConfig";
import { AddressModal, CompModal } from "../../components/User/UserModal";

const phoneRegExp = /^[0-9]{8,}$/;
const passwordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const inputSchema = yup.object({
  compName: yup.string().required("법인정보조회를 진행해주시기 바랍니다."),
  compNumber: yup.string().required("법인정보조회를 진행해주시기 바랍니다."),
  compType: yup.string().required("법인정보조회를 진행해주시기 바랍니다."),
  compStatus: yup.string().required("법인정보조회를 진행해주시기 바랍니다."),
  headerName: yup.string().required("법인정보조회를 진행해주시기 바랍니다."),
  compPostNumber: yup.string().required("주소검색을 진행해주시기 바랍니다."),
  compAddr1: yup.string().required("주소검색을 진행해주시기 바랍니다."),
  compAddr2: yup.string().required("상세주소를 입력해주시기 바랍니다."),
  headerEmail: yup
    .string()
    .email("이메일 형식에 맞게 입력해주시기 바랍니다")
    .required("이메일을 입력해주시기 바랍니다."),
  headerPhone: yup
    .string()
    .required("대표전화번호를 입력해주시기 바랍니다.")
    .matches(phoneRegExp, "'-'을 제외한 숫자만 입력해주시기 바랍니다."),
  name: yup
    .string()
    .required(
      "올바르지 않은 접근입니다. 이전단계로 이동후 회원가입을 다시 시도해주시기 바랍니다."
    ),
  email: yup
    .string()
    .required(
      "올바르지 않은 접근입니다. 이전단계로 이동후 회원가입을 다시 시도해주시기 바랍니다."
    ),
  password: yup
    .string()
    .required(
      "올바르지 않은 접근입니다. 이전단계로 이동후 회원가입을 다시 시도해주시기 바랍니다."
    ),
  phone: yup
    .string()
    .required(
      "올바르지 않은 접근입니다. 이전단계로 이동후 회원가입을 다시 시도해주시기 바랍니다."
    ),
  nickName: yup
    .string()
    .required(
      "올바르지 않은 접근입니다. 이전단계로 이동후 회원가입을 다시 시도해주시기 바랍니다."
    ),
});

const SignupComp2 = () => {
  const navi = useNavigate();
  const [comp, setComp] = useRecoilState(compSelect);
  const [toggle, setToggle] = useState(false);
  const [aOpen, setAOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(inputSchema) });

  const onSubmit = async (data) => {
    const template = {
      comp: {
        compAddr1: data.compAddr1,
        compAddr2: data.compAddr2,
        compName: data.compName,
        compNumber: data.compNumber,
        compPostNumber: data.compPostNumber,
        compStatus: data.compStatus,
        compEmail: data.headerEmail,
        compType: data.compType,
        headerName: data.headerName,
        sido: data.sido,
        sigungu: data.sigungu,
        user: "",
      },
      user: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password,
        nickName: data.nickName,
        emailYn: data.emailYn,
      },
    };

    // console.log(template);
    // return false;

    const response = await NormalReqApi("post", "/user/signupcomp", {
      ...template.user,
    });
    if (response.statusCode === 200 && response.flag) {
      template.comp.user = response.data;
      const compInsert = await NormalReqApi("post", "/corp", {
        ...template.comp,
      });
      // console.log(compInsert);
      if (compInsert.statusCode === 200) {
        if (compInsert.flag) {
          alert("성공적으로 가입되었습니다. 로그인페이지로 이동합니다.");
          setComp((prev) => {
            const temp = { ...prev };
            temp.email = "";
            temp.nickName = "";
            temp.name = "";
            temp.phone = "";
            temp.password = "";
            temp.emailYn = false;
            return { ...temp };
          });
          navi("/signin");
        } else {
          alert(
            "가입도중 문제가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
          );
          return false;
        }
      }
    } else {
      alert(
        "가입도중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
      );
      return false;
    }
  };

  useEffect(() => {
    setValue("name", comp.name);
    setValue("email", comp.email);
    setValue("phone", comp.phone);
    setValue("password", comp.password);
    setValue("nickName", comp.nickName);
    setValue("emailYn", comp.emailYn);
    // terms: terms,
  }, []);
  const callBack = (data) => {
    setValue("compName", data.p_nm);
    setValue("headerName", data.p_nm);
    setValue("compNumber", data.b_no);
    setValue("compType", data.b_stt_cd);
    setValue("compStatus", data.tax_type_cd);
  };
  const addressCallback = ({ postCode, address1, sido, sigungu }) => {
    setValue("compPostNumber", postCode);
    setValue("compAddr1", address1);
    setValue("sido", sido);
    setValue("sigungu", sigungu);
  };

  return (
    <>
      <CompModal
        title={"법인사업자 정보 조회"}
        toggle={toggle}
        setToggle={setToggle}
        callBack={callBack}
      />
      {aOpen && (
        <AddressModal
          toggle={aOpen}
          setToggle={setAOpen}
          callBack={addressCallback}
        />
      )}
      <LoginHeader>
        <div className={custom.join_box}>
          <div className={custom.tab_area}>
            <ul>
              <li>
                <Link to={`/signup`}>개인회원</Link>
              </li>
              <li className={custom.active}>
                <a>기업회원</a>
              </li>
            </ul>
          </div>
          {/* body */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={custom.tab_content}>
              <div className={custom.join_info + " " + custom.indiviual}>
                <h2 className={"scdTit " + custom.scdTit}>
                  기업 회원 상세정보
                </h2>
                <ul>
                  <li>
                    <div className={custom.input_area}>
                      <label className={custom.essential + " " + "w20"}>
                        사업자 정보
                      </label>
                      <div className={custom.addonButton}>
                        <ul style={{ width: "100%" }}>
                          <li>
                            <div
                              className={
                                custom.input_area + " " + custom.subComp
                              }
                            >
                              <input
                                className={custom.inp_text + " " + "w80"}
                                type="text"
                                placeholder="상호명"
                                disabled
                                {...register("compName")}
                              />
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                custom.input_area + " " + custom.subComp
                              }
                            >
                              <input
                                className={custom.inp_text + " " + "w80"}
                                type="text"
                                placeholder="사업자번호"
                                disabled
                                {...register("compNumber")}
                              />
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                custom.input_area + " " + custom.subComp
                              }
                            >
                              <input
                                className={custom.inp_text + " " + "w80"}
                                type="text"
                                placeholder="사업자구분"
                                disabled
                                {...register("compType")}
                              />
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                custom.input_area + " " + custom.subComp
                              }
                            >
                              <input
                                className={custom.inp_text + " " + "w80"}
                                type="text"
                                placeholder="사업자상태"
                                disabled
                                {...register("compStatus")}
                              />
                            </div>
                          </li>
                        </ul>
                        <button
                          className={custom.searchBtn}
                          type="button"
                          onClick={setToggle}
                        >
                          법인정보조회
                        </button>
                      </div>
                    </div>
                    {(errors.compName ||
                      errors.compNumber ||
                      errors.compType ||
                      errors.compStaus) && (
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "0.7em",
                          color: "red",
                        }}
                      >
                        {errors.compName.message}
                      </p>
                    )}
                  </li>
                  <li>
                    <div className={custom.input_area}>
                      <label className={custom.essential + " " + "w20"}>
                        대표자명
                      </label>
                      <input
                        className={custom.inp_text + " " + "w80"}
                        type="text"
                        placeholder="대표자명을 입력해주세요"
                        {...register("headerName")}
                      />
                    </div>
                    {errors.headerName && (
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "0.7em",
                          color: "red",
                        }}
                      >
                        {errors.headerName.message}
                      </p>
                    )}
                  </li>
                  <li>
                    <div className={custom.input_area}>
                      <label className={custom.essential + " " + "w20"}>
                        소재지
                      </label>
                      <div className={custom.addonButton}>
                        <ul>
                          <li>
                            <div
                              className={
                                custom.input_area + " " + custom.subComp
                              }
                            >
                              <input
                                className={custom.inp_text + " " + "w80"}
                                type="text"
                                placeholder="우편번호"
                                disabled
                                {...register("compPostNumber")}
                              />
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                custom.input_area + " " + custom.subComp
                              }
                            >
                              <input
                                className={custom.inp_text + " " + "w80"}
                                type="text"
                                placeholder="일반주소"
                                disabled
                                {...register("compAddr1")}
                              />
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                custom.input_area + " " + custom.subComp
                              }
                            >
                              <input
                                className={custom.inp_text + " " + "w80"}
                                type="text"
                                placeholder="상세주소 (직접입력)"
                                {...register("compAddr2")}
                              />
                            </div>
                          </li>
                        </ul>
                        <button
                          className={custom.searchBtn}
                          type="button"
                          onClick={() => setAOpen(true)}
                        >
                          주소검색
                        </button>
                      </div>
                    </div>
                    {(errors.compPostNumber ||
                      errors.compAddr1 ||
                      errors.compAddr2) && (
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "0.7em",
                          color: "red",
                        }}
                      >
                        {errors.compAddr1?.message}
                      </p>
                    )}
                    {!errors.compPostNumber &&
                      !errors.compAddr1 &&
                      errors.compAddr2 && (
                        <p
                          style={{
                            textAlign: "right",
                            fontSize: "0.7em",
                            color: "red",
                          }}
                        >
                          {errors.compAddr2?.message}
                        </p>
                      )}
                  </li>
                  <li>
                    <div className={custom.input_area}>
                      <label className={custom.essential + " " + "w20"}>
                        대표 전화번호
                      </label>
                      <input
                        className={custom.inp_text + " " + "w80"}
                        type="text"
                        placeholder="대표 전화번호를 입력해주세요"
                        {...register("headerPhone")}
                      />
                    </div>
                    {errors.headerPhone && (
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "0.7em",
                          color: "red",
                        }}
                      >
                        {errors.headerPhone.message}
                      </p>
                    )}
                  </li>
                  <li>
                    <div className={custom.input_area}>
                      <label className={custom.essential + " " + "w20"}>
                        대표 메일주소
                      </label>
                      <input
                        className={custom.inp_text + " " + "w80"}
                        type="text"
                        placeholder="대표 메일주소를 입력해주세요"
                        {...register("headerEmail")}
                      />
                    </div>
                    {errors.headerEmail && (
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "0.7em",
                          color: "red",
                        }}
                      >
                        {errors.headerEmail.message}
                      </p>
                    )}
                  </li>
                </ul>

                {(errors.name ||
                  errors.email ||
                  errors.phone ||
                  errors.password ||
                  errors.nickName ||
                  errors.terms) && (
                  <>
                    <br />
                    <ul>
                      <li>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "0.7em",
                            color: "red",
                          }}
                        >
                          {errors.name.message}
                        </p>
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div
                className={custom.btn_areaLogin + " " + custom.join}
                style={{ justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  className={custom.btn + " " + custom.join + " " + "btn join"}
                  style={{ width: "45%" }}
                >
                  <Link to={`/signupcomp`} style={{ color: "white" }}>
                    이전 단계로
                  </Link>
                </button>

                <button
                  className={custom.btn + " " + custom.join + " " + "btn join"}
                  style={{ width: "45%" }}
                >
                  가입하기
                </button>
              </div>
            </div>
          </form>
        </div>
      </LoginHeader>
    </>
  );
};

export default SignupComp2;
