import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Loading } from "../../../components/DContents/Mobile/MTemplate";
import {
  BottomUpPop,
  FreeContestWrap,
} from "../../../components/User/UserTemplate";
import { accountSelect } from "../../../recoil/AtomConfig";
import { uploadFile } from "../../../utils/AwsS3Upload";
import { ClientReqGet, ClientReqPost } from "../../../utils/ClientAuth";
import { handleFileUploadList } from "../../../utils/FileController";

const EP = process.env.REACT_APP_AWS_ENDPOINT;

const ReqBox = ({
  title,
  host,
  category,
  totalReward,
  target,
  regions,
  reward,
  homepage,
  tags,
  attachment,
  content,
  goBack,
  openPop,
}) => {
  return (
    <>
      <div className={"fcInfos"}>
        <div className={"fcInfo"}>
          <div>
            <label>공모명</label>
            <span>{title}</span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>주최</label>
            <span>{host?.name}</span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>분야</label>
            {category?.map((item, index) => (
              <span key={`cur_${item._id}_${index}`}>{item.text}</span>
            ))}
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>대상</label>
            {target?.map((item, index) => (
              <span key={`cur_${item._code}_${index}`}>{item.text}</span>
            ))}
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>주최사 구분</label>
            <span>{host?.text}</span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>총 시상금 범위</label>
            <span>{totalReward[0].text}</span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>지역</label>
            <span>{regions}</span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>시상내역</label>
            <span>{reward}</span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>홈페이지</label>
            <span>{homepage}</span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>태그</label>
            <span>{tags}</span>
          </div>
        </div>
      </div>
      <div className={"fcFileArea"}>
        <div className={"fcInfo"}>
          <div>
            <label>포스터</label>
            <span>
              <a
                href={EP + attachment.thumb.path}
                target={"_blank"}
                download={attachment.thumb.origin}
              >
                {attachment.thumb.origin}
              </a>
            </span>
          </div>
        </div>
        <div className={"fcInfo"}>
          <div>
            <label>첨부파일</label>
            {attachment.other.length > 0 ? (
              attachment.other[0].origin ? (
                attachment.other.map((item, index) => (
                  <span key={`crMy_${title}_${index}`}>{item.origin}</span>
                ))
              ) : (
                <span>없음</span>
              )
            ) : (
              <span>없음</span>
            )}
          </div>
        </div>
      </div>
      <div className={"fcContentsArea"}>
        {content.length > 0 &&
          content.map((item, index) => (
            <div className={"fcInfo"}>
              <div className={"fcEditorText"}>
                <label>{item.title}</label>
                <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
              </div>
            </div>
          ))}
      </div>
      <div className={"fcBtns"}>
        <button type="button" className={"back"} onClick={goBack}>
          <span>뒤로가기</span>
        </button>
        <button type="button" className={"edit"} onClick={() => openPop(true)}>
          <span>수정요청</span>
        </button>
      </div>
    </>
  );
};

const MyFreeContestDetail = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const changeATK = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const editRef = useRef(null);
  const thumbRef = useRef(null);
  const otherRef = useRef(null);

  const goBack = () => {
    navi(-1);
  };

  const { data, error, isLoading } = useQuery(
    ["getFreeContest", id, account, changeATK],
    async () =>
      await ClientReqGet(
        `/reqContest/${id}`,
        account.accessToken,
        account.refreshToken,
        changeATK
      ),
    { retry: false }
  );

  const [editOpen, setEditOpen] = useState(false);

  const handleEdit = async () => {
    const text = editRef.current.value;
    const thumb = thumbRef.current.files;
    const other = otherRef.current.files;
    console.log(text, thumb, other);
    if (!text || text.length < 5) {
      alert("수정 요청사항을 최소 6글자 이상 입력 해주시기 바랍니다");
      return false;
    }
    const template = {
      reqId: id,
      content: text,
    };
    if (thumb.length > 0) {
      try {
        const thumbResult = await uploadFile(thumb[0], "contents");
        template.thumb = {
          origin: thumbResult.origin,
          path: thumbResult.path.replace("contents/", ""),
          size: thumbResult.size,
        };
      } catch (e) {
        console.log(e.message);
        alert(
          "포스터 파일을 업로드하는 도중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
        );
        return false;
      }
    }
    if (other.length > 0) {
      try {
        const otherResult = await uploadFile(other[0], "contents");
        template.others = [
          {
            origin: otherResult.origin,
            path: otherResult.path.replace("contents/", ""),
            size: otherResult.size,
          },
        ];
      } catch (e) {
        console.log(e.message);
        alert(
          "첨부파일을 업로드하는 도중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
        );
        return false;
      }
    }

    const result = await ClientReqPost(
      `/reqCEdit`,
      { ...template },
      account.accessToken,
      account.refreshToken,
      changeATK
    );
    if (result.statusCode === 200 && result.flag) {
      alert(
        "성공적으로 요청되었습니다. 수정요청 내역은 [마이페이지] -> [무료등록] 탭에서 확인하실 수 있습니다."
      );
      setEditOpen(false);
      return false;
    } else {
      alert("등록중 오류가 발생했습니다. 잠시 후 다시 시도해주시기 바랍니다");
      setEditOpen(false);
      return false;
    }
  };

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
      </div>
      <div className={`sub_container`}>
        <div className={`contents mypage`}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>무료등록 요청 상세</span>
          </h2>

          <div className={"myInfo_area"}>
            <FreeContestWrap>
              {isLoading && <Loading />}
              {!isLoading && (
                <ReqBox {...data.data} goBack={goBack} openPop={setEditOpen} />
              )}
            </FreeContestWrap>
          </div>
        </div>
      </div>
      <BottomUpPop open={editOpen} close={setEditOpen}>
        <div className={"popBox"}>
          <section className={"textBody"}>
            <label>수정 내용</label>
            <textarea
              ref={editRef}
              placeholder="수정할 내용을 간략히 입력해주시기 바랍니다"
            ></textarea>
          </section>
          <section className={"fileBody"}>
            <div className={"fileThumb"}>
              <label>포스터</label>
              <input
                ref={thumbRef}
                type="file"
                className={"fileThumbInput"}
                accept=".jpg, .png"
              />
            </div>
            <div className={"fileThumb"}>
              <label>첨부파일</label>
              <input ref={otherRef} type="file" className={"fileThumbInput"} />
            </div>
          </section>
          <section className={"btnBody"}>
            <button
              type="button"
              className={"cancel"}
              onClick={() => setEditOpen(false)}
            >
              <span>취소</span>
            </button>
            <button type="button" className={"done"} onClick={handleEdit}>
              <span>요청</span>
            </button>
          </section>
        </div>
      </BottomUpPop>
    </>
  );
};

export default MyFreeContestDetail;
