import moment from "moment";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { NormalReqApi } from "../../../utils/ReqApi";
import Cmstyle from "../../../assets/css/community.module.scss";
import pStyle from "../../../assets/css/paging.module.scss";
import { DContentViewInfo } from "../Common/Element";
import Pagination from "../../../utils/Pagination";

export const DepicSuspensePrev = () => {
  const { data, error, isLoading } = useQuery(
    ["depicPrev"],
    async () => await NormalReqApi("get", "/depic/top5"),
    { retry: true, suspense: true }
  );

  const render = (data) => {
    const items = data.data;
    if (items.length > 0) {
      return items.map((item, index) => {
        if (index < 4) {
          return (
            <li key={`depic_prev_${index}`} style={{ width: "280px" }}>
              <Link to={`/depic/${item._id}`}>
                <img
                  src={
                    process.env.REACT_APP_AWS_ENDPOINT +
                    "w_280/" +
                    item.thumbnail.path
                  }
                  style={{ width: "280px", height: "158px" }}
                />
                <span className={"thumb_title"} style={{ fontSize: "0.6em" }}>
                  {item.title}
                </span>

                <p className={"thumb_date"} style={{ fontSize: "0.6em" }}>
                  {moment(item.createdAt).format("YYYY-MM-DD")}
                </p>
              </Link>
            </li>
          );
        }
      });
    } else {
      return <li></li>;
    }
  };
  return <>{render(data)}</>;
};

export const DepicSuspenseList = () => {
  const [page, setPage] = useState(1);
  const [sc, setSc] = useState({
    keyType: "",
    keyword: "",
  });
  const keyRef = useRef(null);
  const keywordRef = useRef(null);

  const { data, error, isLoading } = useQuery(
    ["depicList", page, sc],
    async () =>
      await NormalReqApi(
        "get",
        `/depic/list?page=${page}&keyType=${sc.keyType}&keyword=${sc.keyword}`
      ),
    { retry: true, suspense: true }
  );

  const handleSearch = () => {
    const keyType = keyRef.current.value;
    const keyword = keywordRef.current.value;
    setSc({
      keyType,
      keyword,
    });
  };

  const handlePage = (page) => {
    setPage(page * 1);
  };
  const handleEnterKey = (e) => {
    if (e.nativeEvent.isComposing) {
      return;
    }
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const render = (data) => {
    const items = data.data.result;
    if (items.length > 0) {
      return items.map((item, index) => (
        <li key={`depic_data_${index}`}>
          <Link to={`/depic/${item._id}`}>
            <img
              src={
                process.env.REACT_APP_AWS_ENDPOINT +
                "w_280/" +
                item.thumbnail.path
              }
              style={{ width: "280px", height: "158px" }}
            />
            <span className="thumb_title">{item.title}</span>
            <span className="thumb_title">{item.des}</span>

            <p
              className={"thumb_date"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>view :{item.viewCnt}</span>
              <span>{moment(item.createdAt).format("YYYY-MM-DD")}</span>
            </p>
          </Link>
        </li>
      ));
    } else {
      return <li></li>;
    }
  };
  return (
    <>
      <div className={`board_search ${Cmstyle.board_search}`}>
        <select name="searchType" ref={keyRef}>
          <option value="0">선택</option>
          <option value="title">제목</option>
        </select>
        <input
          type="text"
          name="searchWrd"
          ref={keywordRef}
          onKeyDown={(e) => handleEnterKey(e)}
        />
        <button type="button" onClick={handleSearch}>
          검색
        </button>
      </div>
      <div className="board_list video depic">
        <ul className={"thumbList depic"}>{render(data)}</ul>
        <div className={`paging_area ${pStyle.paging_area}`}>
          <Pagination
            total={data.data.count}
            curPage={page}
            handlePage={handlePage}
            scale={12}
          />
        </div>
      </div>
    </>
  );
};

export const DepicSuspenseView = ({ id, handleGoBack }) => {
  const { data, error, isLoading } = useQuery(
    ["depicView", id],
    async () => await NormalReqApi("get", "/depic/view/" + id),
    { retry: true, suspense: true }
  );
  return (
    <>
      <DContentViewInfo
        title={data.data.title}
        createdAt={moment(data.data.createdAt).format("YYYY-MM-DD")}
        content={data.data.contents}
      />
      <div className={"answer_area"}>
        <div className={"btn_area board"}>
          <a
            className="btn"
            style={{ cursor: "pointer" }}
            onClick={handleGoBack}
          >
            목록으로
          </a>
        </div>
      </div>
    </>
  );
};
